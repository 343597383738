import React from "react";
import { Tooltip } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    boxShadow: theme.shadows[1],
    fontSize: 10,        
    fontStyle:'normal',
    border: "1px solid #000",
    borderRadius:0,
    textAlign:"center",
    marginTop:0    
  },
 
}))(Tooltip);

function useEventListener(eventName, handler, element = window) {
  const savedHandler = React.useRef();

  React.useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  React.useEffect(() => {
    const isSupported = element && element.addEventListener;
    if (!isSupported) return;

    const eventListener = (event) =>  {
        if( savedHandler &&  savedHandler.current) 
          { 
             //  savedHandler.current(event);
          }
    }

    element.addEventListener(eventName, eventListener);

    return () => {
      element.removeEventListener(eventName, eventListener);
    };
  }, [eventName, element]);
}

const AccessibleTooltip = (props) => {
  const { text, ...rest } = props;
  const [open, setOpen] = React.useState(false);
  const onOpen = () => setOpen(true);
  const onClose = () => setOpen(false);  
  const handler = React.useCallback((e) => {
    console.log("keydown", e.key);
    if (e.key === "Escape") {
      onClose();
    }
  }, []);

  useEventListener("keydown", handler);

  return (
    <LightTooltip 
      open={open}
      onOpen={onOpen}
      onClose={onClose}
      interactive="true"
      arrow={false}
      title={text}
      aria-label={text}
      {...rest}
    />
  );
};

export default AccessibleTooltip;
