import {
  carClubDriverSlice,
  carClubRentalSlice,
  carClubVehicleSlice,
} from "../../reducers/reservation/carclubReducer";

export const { updateCarClubReservation, resetCarClubReservation } =
  carClubRentalSlice.actions;

export const { carClubDriverDetails, resetCarClubDriverDetails } =
  carClubDriverSlice.actions;

export const { carClubVehicleAvailability, resetCarClubVehicleAvailability } =
  carClubVehicleSlice.actions;
