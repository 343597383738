let takoMessageMapping = new Map<string, string>([
  [
    "YOU CAN ONLY MAKE ONE RESERVATION DURING A GIVEN TIME PERIOD.",
    "TAKOError500002",
  ],
]);

export const translateTako = (term: string, t): string => {
  let messageCode = takoMessageMapping.get(term.trim().toUpperCase());

  if (messageCode) {
    return t(messageCode);
  } else {
    return term;
  }
};
