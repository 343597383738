import moment from "moment";

const arrayOfCountriesForRentalManagement = ['republic of ireland', 'united kingdom' ]
const maxDaysJourneyEditableAfterCurrentEndDate = 30;
export function isCountryInRentalManagementList(countryName){
  var result = arrayOfCountriesForRentalManagement.some(countryInList => countryName.toLowerCase() === countryInList);
  return result;
  }



export function isJourneyEndDateStillWithinRentalMgmtWindow(journeyEndDate){
  var targetCutoffFutureDate = moment(journeyEndDate).add(maxDaysJourneyEditableAfterCurrentEndDate, 'days').format("YYYY-MM-DD");
  var todaysDate = moment().format("YYYY-MM-DD");
  var isCutoffLaterThanToday = moment(targetCutoffFutureDate).isAfter(todaysDate, 'day');
  var isCutoffToday = moment(targetCutoffFutureDate).isSame(todaysDate, 'day');
  var result = isCutoffLaterThanToday || isCutoffToday;
  return result;
}

export function daysInPastCalculator(journeyStartDateTime){
  let currentDateTime = moment().startOf('day');
  let earliestDateTime = moment(journeyStartDateTime).startOf('day');
  let result = Math.abs(currentDateTime.diff(earliestDateTime, 'days'));
  return result;
}

export function isJourneyStartDateToday(journeyStartDateTime){
  let todaysDate = moment().format("YYYY-MM-DD");
  let startDate = moment(journeyStartDateTime).format("YYYY-MM-DD");
  var result = moment(startDate).isSame(todaysDate, 'day');
  return result;
}

export function isJourneyOver(journeyEndDateTime){
  let todaysDateTime = moment();
  let endDateTime = moment(journeyEndDateTime);
  var result = moment(todaysDateTime).isAfter(endDateTime);
  return result;
}

