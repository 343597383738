import React, { useEffect } from "react";
import { Grid } from "@mui/material";

import { appInsights } from "../../helpers/appInsights";

import { useMsal } from "@azure/msal-react";
import { SeverityLevel } from "@microsoft/applicationinsights-web";

export default function Logout() {
  const { instance } = useMsal();
  appInsights.trackEvent({
    name: "Logout Component",
    properties: { test: "logout" },
  });

  useEffect(() => {
    const logoutRequest = {
      idTokenHint: sessionStorage.idToken,
    };
    sessionStorage.clear();
    instance.logoutRedirect(logoutRequest).catch((err) => {
      appInsights.trackException({
        properties: {
          method: "Logout: logoutRedirect",
          Component: "Login",
        },
        exception: err,
        severityLevel: SeverityLevel.Error,
      });
    });
  }, []);

  return <Grid container spacing={2}></Grid>;
}
