import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { useNavigate } from "react-router-dom";
import { Backdrop, CircularProgress } from "@mui/material";
import { protectedResources } from "services/ssoAuthConfig"; //LoginADRequest
import { useTranslation } from "react-i18next";
import { useMsal } from "@azure/msal-react";
import { appInsights } from "helpers/appInsights";
import { SeverityLevel } from "@microsoft/applicationinsights-common";

import { useLocation } from "react-router-dom";

const useStyles = makeStyles({
  backdrop: {
    zIndex: 99999,
    color: "#fff",
  },
});

export default function SsoLogin() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const history = useNavigate();
  const { t } = useTranslation("home");
  const classes = useStyles();
  const { instance } = useMsal();

  const handleSSOLogin = (domainHint) => {
    const ssoLoginRequest = {
      scopes: protectedResources.apiHello.scopes,
      extraQueryParameters: { domain_hint: domainHint },
    };

    instance.loginRedirect(ssoLoginRequest).catch((err) => {
      console.log("Error in SSO logging in: ", err);
      appInsights.trackException({
        properties: {
          method: "SSOLogin: handlSSOLogin",
          Component: "SSOLogin",
        },
        exception: err,
        severityLevel: SeverityLevel.Error,
      });
    });
  };

  useEffect(() => {
    document.title = t("basePageTitle") + t("loginPageTitle");
    if (queryParams && queryParams.get("domain")) {
      handleSSOLogin(queryParams.get("domain"));
    } else history("/");
  }, []);

  return (
    <Grid container spacing={2}>
      <Backdrop className={classes.backdrop} open={false}>
        <CircularProgress />
      </Backdrop>
      <div
        style={{
          textAlign: "center",
          opacity: 0.75,
          width: "100%",
        }}
      >
        <h2>{t("loginWaitMessage")}</h2>
      </div>
    </Grid>
  );
}
