import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "components/store/reducers/rootReducer";
import { ReservationProps } from "../../reservationType";
import BusyIndicator from "controls/busyIndicator/busyIndicator";
import { appInsights } from "helpers/appInsights";
import { authService } from "services/authService";
import { SeverityLevel } from "@microsoft/applicationinsights-common";
import {
  clearAlertMessage,
  showErrorAlertMessage,
  showSuccessAlertMessage,
} from "components/store/actions/shared/alertActions";

import {
  Backdrop,
  Typography,
  Button,
  Box,
  CircularProgress,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

import { ReservationAdditionalInformationFieldTypeCode } from "./../../../../views/reservation/ReservationViewType";
import { ReservationAdditionalInformationFieldViewModel } from "../AdditionalInformation/additionalInformationType";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { broadcastAnnouncement } from "components/store/actions/shared/announcementActions";
import moment from "moment";
import { updateSpecialityRequest } from "components/store/actions/reservation/specialityActions";
import { SaveSpecialityRequest } from "services/SpecialityService";
import {
  SpecialityRequestState,
  SpecialityVehicleType,
} from "../SpecialityRequest/specialityRequestType";
import { getEnumKeyByEnumValue } from "helpers/enumMapping";
//import dummyData from "./ReservationSummaryViewData.json";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
    paddingRight: 30,
    paddingTop: 15,
    paddingBottom: 15,
  },
  title: {
    fontSize: "1.4em",
    paddingLeft: 30,
    paddingTop: 10,
  },
  subtitle: {
    fontSize: "1.2em",
    marginTop: 10,
    marginBottom: 10,
    fontWeight: "normal",
    wordBreak: "break-word",
  },
  tableHeader0: {
    fontWeight: "bold",
    padding: 4,
    borderBottom: "none",
    fontSize: "1.1em",
    overflow: "hidden",
    textOverflow: "ellipsis",
    wordWrap: "break-word",
    //wordBreak: "break-all",
    width: "30%",
  },
  tableBody0: {
    fontSize: "1.1em",
    padding: 4,
    borderBottom: "none",
    overflow: "hidden",
    textOverflow: "ellipsis",
    wordBreak: "break-all",
    wordWrap: "break-word",
    width: "70%",
  },
  tableHeader: {
    fontWeight: "bold",
    padding: 4,
    borderBottom: "none",
    fontSize: "1.1em",
    whiteSpace: "nowrap",
  },
  tableBody: {
    fontSize: "1.1em",
    padding: 4,
    borderBottom: "none",
    wordBreak: "break-word",
  },
  detail: {
    padding: 0,
    whiteSpace: "normal",
    wordWrap: "break-word",
  },
  rootcell: {
    "& .MuiTableCell-root": {
      verticalAlign: "top",
    },
  },
  divider: {
    backgroundColor: "#000",
    height: "0.8px",
    marginRight: 30,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  gridItem: {
    verticalAlign: "center",
    padding: "10px",
    "& .MuiTypography-body2": {
      fontWeight: "bold",
    },
  },
  heading: {
    fontSize: 16,
    fontWeight: "bold",
  },
  labelText: {
    color: "#000",
    fontFamily: "Arial, Roboto, Segoe UI",
    fontSize: 14,
    fontWeight: "bold",
    verticalAlign: "middle",
  },
  errorWarning: {
    color: "#DF0000",
    fontSize: 12,
    marginLeft: "15px",
  },
  disclaimer: {
    fontWeight: "bold",
    padding: 4,
    borderBottom: "none",
    fontSize: "1.1em",
  },
}));
const ReviewSpecialityRequest: React.FC<ReservationProps> = (props) => {
  const [open, setOpen] = React.useState(false);

  const classes = useStyles();
  const locale = authService.getUserLocale();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);
  const { request } = useSelector((state: AppState) => state.speciality);
  const titleRef = React.useRef<HTMLElement>(null);

  React.useEffect(() => {
    dispatch(clearAlertMessage());
    if (titleRef.current !== null) titleRef.current.focus();
    console.log("Review specialityRequest onload", request);
  }, []);

  const handleToggle = async () => {
    setLoading(true);
    dispatch(clearAlertMessage());
    appInsights.trackTrace({
      message: "saving SV request",
      properties: { object: request, Component: "SV Reservation" },
      severityLevel: SeverityLevel.Information,
    });
    if (request) {
      var svResponse = (await SaveSpecialityRequest(
        request
      )) as SpecialityRequestState;
      setLoading(false);
      if (svResponse && !svResponse.hasError) {
        dispatch(updateSpecialityRequest(svResponse));
        history("/home");
        setTimeout(() => {
          dispatch(
            showSuccessAlertMessage(
              t("SV.svJourneyCreated", {
                reservationNumber: request?.reservation.reservationNumber,
              })
            )
          ); // page redirect or do nothing here
          dispatch(
            broadcastAnnouncement(
              t("SV.svJourneyCreated", {
                reservationNumber: request?.reservation.reservationNumber,
              })
            )
          );
        }, 3000);
      } else {
        if (svResponse && svResponse.errors)
          dispatch(showErrorAlertMessage(svResponse.errors));
        else dispatch(showErrorAlertMessage(t("CustomErrorGenericErrorMsg")));
      }
      window.scrollTo(0, 0);
    }
  };

  function handleBack() {
    if (props.handleBack) props.handleBack(0, 0);
  }

  function getBookingReference(
    input: ReservationAdditionalInformationFieldViewModel
  ) {
    var singleReference;
    switch (input.fieldTypeCode) {
      case ReservationAdditionalInformationFieldTypeCode.Date:
        singleReference =
          input.fieldName +
          " : " +
          (input.fieldValueDateString === ""
            ? ""
            : new Date(
                parseInt(input.fieldValueDateString.substr(0, 4)),
                parseInt(input.fieldValueDateString.substr(4, 2)) - 1,
                parseInt(input.fieldValueDateString.substr(6, 2))
              ).toLocaleString(locale.toString() === "en-GB" ? "en-GB" : "de-DE", {
                year: "numeric",
                month: "numeric",
                day: "numeric",
              }));
        break;
      default:
        singleReference =
          input.fieldName + " : " + (input.fieldValue ? input.fieldValue : "");
    }
    return singleReference;
  }

  let history = useNavigate();
  const primaryDriver = request?.reservation.drivers?.find(
    (x) => x.isPrimaryDriver === true
  );
  const additionalDrivers = request?.reservation.drivers?.filter(
    (x) => !!x.isPrimaryDriver === false
  );

  return (
    <>
      <Box data-testid="reviewSpecialityRequest" boxShadow={3} m={3}>
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress />
        </Backdrop>
        <Grid className={classes.rootcell} container spacing={3}>
          <Grid id="titleHeader" item xs={12} md={7}>
            <Typography
              ref={titleRef}
              tabIndex={-1}
              className={classes.title}
              variant="h1"
            >
              {t("SV.submitYourSvRequest")}
            </Typography>
            <div style={{ paddingLeft: 30 }}>
              <Typography className={classes.subtitle} variant="h3">
                {t("SV.summaryOfYourSvRequest")}
              </Typography>
              {/* <Divider className={classes.divider} />{" "} */}
            </div>
          </Grid>
          <Grid item xs={12} md={6} className={classes.root}>
            <Typography className={classes.title} variant="h3">
              {t("SV.reservationRequestDetails")}
            </Typography>
            <Box style={{ paddingLeft: 30 }}>
              <Divider
                className={classes.divider}
                style={{ marginTop: 10, marginLeft: 0, marginBottom: 20 }}
              />
              <TableContainer id="detailsTable" className={classes.detail}>
                <Table aria-label="journey table">
                  <TableBody>
                    <TableRow>
                      <TableCell className={classes.tableHeader}>
                        {t("StartEndLocation")}
                      </TableCell>
                      <TableCell
                        id="startAddressDiv"
                        className={classes.tableBody}
                      >
                        <strong>{t("Start")}:</strong>{" "}
                        {request?.reservation.startLocation}{" "}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.tableHeader}></TableCell>
                      <TableCell
                        id="endAddressDiv"
                        className={classes.tableBody}
                      >
                        <strong>{t("End")}: </strong>{" "}
                        {request?.reservation.endLocation}{" "}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        style={{ whiteSpace: "pre-wrap" }}
                        className={classes.tableHeader}
                      >
                        {t("Res.StartEndDateTime")}
                      </TableCell>
                      <TableCell
                        id="startDateTimeDiv"
                        className={classes.tableBody}
                      >
                        <strong>{t("Start")}:</strong>
                        {request?.reservation.startDateTime === null
                          ? ""
                          : moment(request?.reservation.startDateTime).format(
                              t("DateTimeFormat")
                            )}{" "}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.tableBody}></TableCell>
                      <TableCell
                        id="endDateTimeDiv"
                        className={classes.tableBody}
                      >
                        <strong>{t("End")}: </strong>{" "}
                        {request?.reservation.endDateTime === null
                          ? ""
                          : moment(request?.reservation.endDateTime).format(
                              t("DateTimeFormat")
                            )}{" "}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell width="40%" className={classes.tableHeader}>
                        {t("Res.booker")}
                      </TableCell>
                      <TableCell
                        id="reservationRequestBookerDiv"
                        className={classes.tableBody}
                      >
                        {request?.reservation.bookerName}
                      </TableCell>
                    </TableRow>
                    {request?.reservation.additionalInformation != null &&
                      request?.reservation.additionalInformation
                        .additionalInformationFields.length > 0 && (
                        <TableRow>
                          <TableCell className={classes.tableHeader}>
                            {t("Res.BookingReferences")}{" "}
                          </TableCell>
                          <TableCell
                            id="reservationBookingReferenceDiv"
                            className={classes.tableBody}
                          >
                            {request?.reservation.additionalInformation.additionalInformationFields?.map(
                              (additionalInformation, index) => {
                                return (
                                  <Typography
                                    key={"additionalInformation" + index}
                                  >
                                    {" "}
                                    {getBookingReference(
                                      additionalInformation
                                    )}{" "}
                                    <br />{" "}
                                  </Typography>
                                );
                              }
                            )}
                          </TableCell>
                        </TableRow>
                      )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography className={classes.title} variant="h3">
              {t("Res.Driver") + " & " + t("Res.VehicleDetails")}
            </Typography>
            <Box style={{ paddingLeft: 30 }}>
              <Divider
                className={classes.divider}
                style={{ marginTop: 10, marginLeft: 0, marginBottom: 20 }}
              />
              <TableContainer
                id="driverDetailsTable"
                className={classes.detail}
              >
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell width="40%" className={classes.tableHeader}>
                        {t("SV.labelVehicleCategory")}
                      </TableCell>
                      <TableCell
                        id="VehicleCategorydiv"
                        className={classes.tableBody}
                      >
                        {request?.selectedSpecialityVehicleValue
                          ? t(
                              "SV.vehicleCategory." +
                                getEnumKeyByEnumValue(
                                  SpecialityVehicleType,
                                  request?.selectedSpecialityVehicleValue
                                )
                            )
                          : ""}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell width="40%" className={classes.tableHeader}>
                        {t("SV.labelNotesSvType")}
                      </TableCell>
                      <TableCell
                        id="notesSvTypediv"
                        className={classes.tableBody}
                      >
                        {request?.vehicleTypeNotes}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell width="40%" className={classes.tableHeader}>
                        {t(
                          "ReservationWizard.reservationPage.primaryDriverLabel"
                        )}
                      </TableCell>
                      <TableCell
                        id="driversNamediv"
                        className={classes.tableBody}
                      >
                        {primaryDriver?.firstName +
                          " " +
                          primaryDriver?.lastName}
                      </TableCell>
                    </TableRow>
                    {additionalDrivers != null &&
                      additionalDrivers.length > 0 && (
                        <TableRow>
                          <TableCell className={classes.tableHeader}>
                            {t("AdditionalDriver")}{" "}
                          </TableCell>
                          <TableCell className={classes.tableBody}>
                            {additionalDrivers?.map((driver, index) => {
                              return (
                                <Typography key={"additionalDriver" + index}>
                                  {" "}
                                  {driver.firstName + " " + driver.lastName}
                                  <br />{" "}
                                </Typography>
                              );
                            })}
                          </TableCell>
                        </TableRow>
                      )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <BusyIndicator isloading={open} text={"Please Wait"} />
      <Grid item xs={12}>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "1em",
            marginRight: "0.7em",
          }}
        >
          <Button
            variant="contained"
            id="EdtAction"
            style={{
              marginRight: "1em",
            }}
            onClick={handleBack}
          >
            {t("Res.editReservation")}
          </Button>
          <Button
            variant="contained"
            color="primary"
            id="nextReservationButton"
            onClick={handleToggle}
          >
            {t("Res.confirmBooking")}
          </Button>
        </div>
      </Grid>
    </>
  );
};

export default ReviewSpecialityRequest;
