import * as Yup from "yup";

const validateSelectField = (value) => {
    if (
      typeof value["selectedValue"] === "undefined" ||
      value["selectedValue"] === ""
    )
      return false;
    else return true;
  }

export const driverDetailsValidationSchema = (isPhoneNumberOptional: boolean, isEmailAddressMaskToggleEnabled: boolean ) => { 
  
    return Yup.object({ 
      userTitle: Yup.object()
      .notRequired()
      .nullable(),
      // .test(
      //   "Select Field",
      //   "Users.Errors.userTitleRequired",
      //   (value) => {
      //     if (value?.items) {
      //       return validateSelectField(value)
      //     } else return true;
      //   }
      // ),
      firstName: Yup.string()
        .required("Users.Errors.firtNameRequired")
        .max(20, "Users.Errors.firstNameMaxLength")
        .nullable(),
      lastName: Yup.string()
        .required("Users.Errors.lastNameRequired")
        .max(30, "Users.Errors.lastNameMaxLength")
        .nullable(),
      emailAddress: Yup.string()
        .required("Users.Errors.emailRequired")
        .max(100, "Users.Errors.emailMaxLength")
        .nullable()
        .test(
          "emailAddress",
          "Users.Errors.invalidEmail",
          (value) => {
            if(isEmailAddressMaskToggleEnabled)
            {
              return true
            }
            else{
              if(value){
                const pattern = /^[A-Za-z0-9_!#$%&'*+\/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/gm; 
                return value.match(pattern) !==null
              }
            }
            return false;
          }
         ),
      contactPhoneNumber: Yup.object().shape(
        {
            selectedInternationalDiallingCode: Yup.string()
            .notRequired()
            .nullable()
            .test(
             "selectedInternationalDiallingCode",
             "Users.Errors.preferredCountryCodeRequired",
             (value, number) => {
               if(isPhoneNumberOptional)
               {
                if(!number.parent.number){
                  return true;
                }
               }
               if (value) {
                 return value.length > 0 && value !== "+";
               } 
               else return false;
             }
            ),
            number: Yup.string()
            .ensure()
            .when(["selectedInternationalDiallingCode"], {
              is: (selectedInternationalDiallingCode, isPhoneNumberOptional) =>
                { return ((selectedInternationalDiallingCode &&
                selectedInternationalDiallingCode.length > 0 &&
                selectedInternationalDiallingCode !== "+") || !isPhoneNumberOptional )},
              then: Yup.string()
                .notRequired()
                .nullable()
                .test(
                  "number",
                  "Users.Errors.preferredPhoneRequired",
                  (value) => {
                    let exp = /^[0-9]*$/;
                    if (value) {
                      return exp.test(value);
                    } else return false;
                  }
                )
                .min(2, "Users.Errors.preferPhoneMaxLength")
                .max(17, "Users.Errors.preferPhoneMaxLength"),
            }),
        },
        [["selectedInternationalDiallingCode", "number"]]
        ),
      alternatePhoneNumber: Yup.object().shape(
        {
          selectedInternationalDiallingCode: Yup.string()
            .ensure()
            .when(["number"], {
              is: (number) => number,
              then: Yup.string()
                .notRequired()
                .nullable()
                .test(
                  "selectedInternationalDiallingCode",
                  "Users.Errors.alternateCountryCodeRequired",
                  (value) => {
                    if (value) {
                      return value.length > 0 && value !== "+";
                    } else return false;
                  }
                ),
            }),
          number: Yup.string()
            .ensure()
            .when(["selectedInternationalDiallingCode"], {
              is: (selectedInternationalDiallingCode) =>
                selectedInternationalDiallingCode &&
                selectedInternationalDiallingCode.length > 0 &&
                selectedInternationalDiallingCode !== "+",
              then: Yup.string()
                .notRequired()
                .nullable()
                .test(
                  "number",
                  "Users.Errors.alternatePhoneRequired",
                  (value) => {
                    let exp = /^[0-9]*$/;
                    if (value) {
                      return exp.test(value);
                    } else return false;
                  }
                )
                .min(2, "Users.Errors.preferPhoneMaxLength")
                .max(17, "Users.Errors.preferPhoneMaxLength"),
            }),
        },
        [["selectedInternationalDiallingCode", "number"]]
      ),
    });
  }