import { Accordion, AccordionDetails, Backdrop, Box, Button, CircularProgress, Grid } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { Field, Form, Formik } from "formik";
import React, { useEffect } from "react";
import { ReservationProps } from "../../reservationType";
import CompactJourneyDetailsView from "../Shared/compactJourneyDetailsView";
import CarClubLegend from "./carClubLegend";
import EtdDatePickerV2 from "controls/formik/EtdDatePickerV2";
import EtdTimePicker from "../../../../controls/formik/EtdTimePicker";
import CarClubCar from "./carClubCar";
import axiosInstance from "helpers/axiosInstance";
import { useTranslation } from "react-i18next";
import { broadcastAnnouncement } from "components/store/actions/shared/announcementActions";
import { authService } from "services/authService";
import CarClubLogo from "Assets/Images/CarClubLogo.png";
import CarShareLogo from "Assets/Images/CarShareLogo.png";
import { minutesBetweenTwoDates } from "../../../shared/booking-time-range-slider/functions/DateTime.functions";
import { useDispatch } from "react-redux";
import {
  carClubVehicleAvailability,
  updateCarClubReservation,
} from "components/store/actions/reservation/carclubActions";
import moment from "moment";
import {
  showErrorAlertMessage,
  showInfoAlertMessage,
  clearAlertMessage,
} from "components/store/actions/shared/alertActions";
import { red } from "@mui/material/colors";
import { getIntlDateFormatForLocale } from "helpers/localization";
import {
  useCarClubDriverDetails,
  useCarClubVehicleAvailability,
} from "components/store/reducers/reservation/carclubSelector";
import { CarClubReservation } from "./carClubFormType";
import { driverDetails } from "../Driver/carClubDriverType";
import { useCo2Dict } from "controls/hooks/useCo2Dict";
import "./carClubSelection.css";

const ccInitialValue = {
  availabilityList: [],
  clientCurrentDateTime: "",
  companyCountry: "United Kingdom",
  companyUId: "",
  driverAuthCodeExpiry: "",
  endDateTime: "00:00",
  journeyDetails: null,
  journeyEndUnixTime: "",
  journeyStartUnixTime: "",
  pager: { currentPage: 1, totalPages: 1, startPage: 1, endPage: 1 },
  sliderKeyboardStep: "",
  startDateTime: "00:00",
  uId: "",
};
const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  gridItem: {
    verticalAlign: "center",
    padding: "10px",
    "& .MuiTypography-body2": {
      fontWeight: "bold",
    },
  },
  heading: {
    fontSize: 16,
    fontWeight: "bold",
  },
  labelText: {
    color: "#000",
    fontFamily: "Arial, Roboto, Segoe UI",
    fontSize: 14,
    fontWeight: "bold",
    verticalAlign: "middle",
  },
  backdrop: {
    zIndex: 99999,
    color: "#fff",
  },
  errorWarning: {
    color: "#DF0000",
    fontSize: 12,
    marginLeft: "15px",
  },
  textarea: {
    resize: "both",
  },
  square: {
    border: "5px solid red",
    width: "20px",
    height: "20px",
  },
  dialog: {
    borderRadius: 0,
  },
  button: {
    borderRadius: 0,
    textTransform: "none",
    padding: 5,
  },
  logout: {
    color: "#fff",
    opacity: "1",
    backgroundColor: "#dd0000",
    "&:hover": {
      backgroundColor: "#dd0000",
    },
  },
  countdown: {
    color: red[700],
  },
  tileDiv: {
    marginTop: "1em",
    "&:hover,&:focus": {
      border: "solid 1px green",
      outline: 0,
    },
  },
  topScrollPaper: {
    alignItems: "flex-start",
  },
  topPaperScrollBody: {
    verticalAlign: "top",
  },
}));

const getTimeValue = (dt) => {
  let result = "0";
  if (dt) {
    let date = new Date(dt);
    var hours = ("0" + date.getHours()).slice(-2);
    var mins = ("0" + date.getMinutes()).slice(-2);
    var secs = "00";
    result = hours + ":" + mins + ":" + secs;
  } else result = dt;

  return result;
};

const CarClubSelectionForm: React.FC<ReservationProps> = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const carClubAvailability = useCarClubVehicleAvailability() ?? ccInitialValue;
  const carClubDrivers = useCarClubDriverDetails();
  const selectedDriver: driverDetails | null =
    carClubDrivers.find((x) => x.isSelected == true) ?? null;
  const [loading, setLoading] = React.useState(false);
  const [carClubVehicles, setCarClubVehicles] =
    React.useState<any>(carClubAvailability);
  const [startDate, setStartDate] = React.useState<Date | null>(
    new Date(carClubAvailability.startDateTime)
  );
  const [endDate, setEndDate] = React.useState<Date | null>(
    new Date(carClubAvailability.endDateTime)
  );
  const [startTime, setStartTime] = React.useState<string>(
    getTimeValue(carClubAvailability.startDateTime)
  );
  const [endTime, setEndTime] = React.useState<string>(
    getTimeValue(carClubAvailability.endDateTime)
  );
  const locale = authService.getUserLocale();
  const [currentPage, setCurrentPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10);
  const [startDateTime, setStartDateTime] = React.useState<string>(
    carClubAvailability.startDateTime
  );
  const [endDateTime, setEndDateTime] = React.useState<string>(
    carClubAvailability.endDateTime
  );
  const indexOfLastCar = currentPage * pageSize;
  const indexOfFirstCar = indexOfLastCar - pageSize;
  const totalPages =
    carClubVehicles && carClubVehicles.availabilityList?.length > 0
      ? Math.ceil(carClubVehicles.availabilityList?.length / pageSize)
      : 0;
  const co2rate = useCo2Dict();

  const takoApiEnv = authService.getTakoApiEnv();
  const isCarShareReservation = String(takoApiEnv).toUpperCase().includes("CS");

  const getCurrentCarList = () => {
    return carClubVehicles && carClubVehicles.availabilityList
      ? carClubVehicles.availabilityList?.slice(indexOfFirstCar, indexOfLastCar)
      : null;
  };
  const localeDateTimeFormat = getIntlDateFormatForLocale(locale);
  console.log("carClubAvailability", carClubAvailability);
  const formRef = React.useRef<any>(null);

  const handleCarClassSelect = async (car, key) => {
    let model = {
      JourneyProfileUId: props.journeyUId,
      VehicleLocation: car.lotAddress,
      VehicleDetails: car.vehicleDetails,
      StartDateTime: formRef.current.values.startDateTime,
      EndDateTime: formRef.current.values.endDateTime,
      ImageUrl: car.imageUrl,
      StackId: car.stackId,
      CompanyUId: formRef.current.values.companyUId,
      additionalDriver: authService.getDriverInfo().additionalDriver,
    };
    console.log("Car Club Selected: ", model);
    setLoading(true);
    await axiosInstance
      .post("carclub/review", model)
      .then((result) => {
        console.log("Result: ", result.data);
        const reviewData: CarClubReservation = result.data.data;
        reviewData.firstName = selectedDriver?.firstName;
        reviewData.lastName = selectedDriver?.lastName;
        reviewData.email = selectedDriver?.email;
        reviewData.carClubId = selectedDriver?.id;
        reviewData.userId = authService.getUserAppSecId();
        reviewData.isPrimaryDriver = true;
        reviewData.driverDeclarationId =
          authService.getDriverInfo().primaryDriver?.driverDeclarationId;

        if (result.data.success) {
          dispatch(
            updateCarClubReservation({
              reservation: result.data.data,
              hasError: false,
              errors: [],
            })
          );
          if (props.handleNext) props.handleNext(0, 0);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);

        window.scrollTo(0, 0);
      });
  };

  const isValid = () => {
    let valid = false;
    let error = "";
    error =
      startDate === null
        ? t("DateTime.msgStartDateRequired")
        : endDate === null
        ? t("DateTime.msgEndDateRequired")
        : startTime === "0"
        ? t("DateTime.msgStartTimeRequired")
        : endTime === "0"
        ? t("DateTime.msgEndTimeRequired")
        : "";
    if (error !== "") {
      return { valid, error };
    }

    if (!moment(startDate).isValid()) {
      error = t("DateTime.msgInvalidStartDate");
      return { valid, error };
    }
    if (!moment(endDate).isValid()) {
      error = t("DateTime.msgInvalidEndDate");
      return { valid, error };
    }
    let strDate = startDate;
    strDate?.setHours(Number(startTime.split(":")[0]));
    strDate?.setMinutes(Number(startTime.split(":")[1]));
    let entDate = endDate;
    entDate?.setHours(Number(endTime.split(":")[0]));
    entDate?.setMinutes(Number(endTime.split(":")[1]));
    const totalMins = minutesBetweenTwoDates(
      strDate ?? new Date(),
      entDate ?? new Date()
    );
    if (totalMins > 7 * 24 * 60) {
      error = t("ReservationWizard.carClubPage.msgMaxCarClubResDays");
      return { valid, error };
    } else if (totalMins < 0) {
      error = t("ERR20014");
      return { valid, error };
    } else if (totalMins < 30) {
      error = t("ReservationWizard.carClubPage.msgMinCarClubResMins");
      return { valid, error };
    } else if (
      startDate &&
      new Date(startDate).getTime() <= new Date().getTime()
    ) {
      error = t("ERR20020");
      return { valid, error };
    } else {
      valid = true;
      return { valid, error };
    }
  };
  const getUpdatedCarClubVehicles = async (id) => {
    dispatch(clearAlertMessage());
    window.scrollTo(0, 0);
    setLoading(true);
    let result = isValid();
    if (result.valid) {
      let strDate = startDate;
      strDate?.setHours(Number(startTime.split(":")[0]));
      strDate?.setMinutes(Number(startTime.split(":")[1]));
      let entDate = endDate;
      entDate?.setHours(Number(endTime.split(":")[0]));
      entDate?.setMinutes(Number(endTime.split(":")[1]));
      let model = {
        UId: id,
        ClientCurrentDateTime: new Date(),
        StartDateTime: moment(strDate).format("YYYY-MM-DD[T]HH:mm:SS"),
        EndDateTime: moment(entDate).format("YYYY-MM-DD[T]HH:mm:SS"),
      };
      console.log(model);
      let baseURL = `availability/updatedvehicles`;
      if (
        selectedDriver &&
        selectedDriver.id !== authService.getUserCarClubId()
      )
        baseURL = baseURL + "?primaryCarClubDriverId=" + selectedDriver.id;
      await axiosInstance
        .post(baseURL, model)
        .then((response) => {
          console.log("updated carClub vehicles: ", response.data.data);
          if (response.data.success) {
            const vehicles = {
              vehicles: response.data.data,
              errors: [],
              hasError: false,
            };
            dispatch(carClubVehicleAvailability(vehicles));
            setStartDate(new Date(response.data.data.startDateTime));
            setStartDateTime(response.data.data.startDateTime);
            setStartTime(getTimeValue(response.data.data.startDateTime));
            setEndDate(new Date(response.data.data.endDateTime));
            setEndDateTime(response.data.data.endDateTime);
            setEndTime(getTimeValue(response.data.data.endDateTime));
            setCarClubVehicles(response.data.data);
            setCurrentPage(1);
            setLoading(false);
            if (response.data.data.availabilityList?.length === 0) {
              dispatch(
                showInfoAlertMessage(
                  t(
                    "ReservationWizard.carClubPage.msgCarClubVehicleNotAvailable"
                  )
                )
              );
              dispatch(
                broadcastAnnouncement(
                  t(
                    "ReservationWizard.carClubPage.msgCarClubVehicleNotAvailable"
                  )
                )
              );
            }
          } else {
            console.log("Errors: ", response.data);
            dispatch(showErrorAlertMessage(t(response.data.messages)));
            dispatch(broadcastAnnouncement(t(response.data.messages)));
            setLoading(false);
          }
        })
        .catch((error) => {
          console.error("There was an error!", error);
          setCarClubVehicles(null);
          const vehicles = {
            vehicles: undefined,
            errors: [],
            hasError: true,
          };
          dispatch(carClubVehicleAvailability(vehicles));
          setLoading(false);
          dispatch(
            showInfoAlertMessage(
              "An error has occured. Please contact support team."
            )
          );
          dispatch(
            broadcastAnnouncement(
              "An error has occured. Please contact support team."
            )
          );
        });
    } else {
      dispatch(showErrorAlertMessage(result.error));
      setCarClubVehicles(null);
      setLoading(false);
    }
  };

  useEffect(() => {}, [carClubVehicles, carClubDrivers]);

  useEffect(() => {
    window.scrollTo(600, 0);
    var firstTile = document.getElementById("carClubTileId0") as HTMLDivElement;
    if (firstTile) {
      firstTile.focus();
    }
  }, [currentPage]);

  return (
    <Formik
      initialValues={carClubVehicles}
      validateOnChange={true}
      validateOnBlur={false}
      enableReinitialize={true}
      innerRef={formRef}
      // validationSchema={reservationValidationSchema}
      onSubmit={async (values, { setFieldTouched, setSubmitting }) => {
        console.log(values);
      }}
    >
      {({
        values,
        isValid,
        dirty,
        setFieldValue,
        setFieldTouched,
        touched,
        handleChange,
        errors,
        isSubmitting,
      }) => (
        <Box
          boxShadow={3}
          p={3}
          bgcolor="background.paper"
          style={{ width: "100%" }}
        >
          <Grid container>
            <Backdrop className={classes.backdrop} open={loading}>
              <CircularProgress />
            </Backdrop>
            <h1>
              <img src={isCarShareReservation ? CarShareLogo : CarClubLogo} alt="Enterprise Car Club" height={60} />
            </h1>
            <CompactJourneyDetailsView
              journeyUId={"00-00"}
              journeyDetails={props.journeyDetails}
            />
            {!loading && (
              <Grid item xs={12}>
                {/* <p>Car club Token expired in : {carClubVehicles?.driverAuthCodeExpiry? ((new Date(carClubVehicles?.driverAuthCodeExpiry)).getTime() - (currentDate.valueOf()))/1000:""}</p>
                  <p>{carClubVehicles?.driverAuthCodeExpiry} </p> */}
                <Form style={{ width: "100%", marginTop: "15px" }}>
                  <Accordion
                    style={{ width: "100%" }}
                    id="sectionStartEndDates"
                    defaultExpanded
                  >
                    <AccordionDetails>
                      <Grid container>
                        <Grid container direction="row">
                          <Grid
                            item
                            xs={12}
                            md={2}
                            className={classes.gridItem}
                          >
                            <label
                              htmlFor={"startDateTime"}
                              className={classes.labelText}
                            >
                              {t(
                                "ReservationWizard.reservationPage.labelStartDate"
                              )}{" "}
                              *
                            </label>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={3}
                            className={classes.gridItem}
                          >
                            <Field
                              component={EtdDatePickerV2}                      
                              name="startDateTime"
                              id="startDateTime"
                              localeDateTimeFormat={localeDateTimeFormat}
                              value={
                                startDate ? new Date(startDate) : null
                              }
                              handleChange={(e) => {
                                let newValue = e;
                                const format =
                                  localeDateTimeFormat.toUpperCase();
                                let parsedDate = moment(newValue, format, true);
                                if (parsedDate.isValid()) {
                                  newValue = moment(parsedDate).toDate();
                                } else {
                                  newValue = null;
                                }
                                setStartDate(newValue);
                              }}
                              handleBlur={(e) => {}}
                            />
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            md={2}
                            className={classes.gridItem}
                          ></Grid>
                          <Grid
                            item
                            xs={12}
                            md={2}
                            className={classes.gridItem}
                          >
                            <label
                              htmlFor={"startTime"}
                              className={classes.labelText}
                            >
                              {t(
                                "ReservationWizard.reservationPage.labelStartTime"
                              )}{" "}
                              *
                            </label>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={3}
                            className={classes.gridItem}
                          >
                            <Field
                              id="startTime"
                              component={EtdTimePicker}
                              format = {t("TimeFormat")}
                              name="startTime"
                              defaultValue={startTime}
                              onChange={(e) => {
                                setStartTime(e.target.value);
                              }}
                            />
                          </Grid>
                        </Grid>
                        <Grid container direction="row">
                          <Grid
                            item
                            xs={12}
                            md={2}
                            className={classes.gridItem}
                          >
                            <label
                              htmlFor={"endDateTime"}
                              className={classes.labelText}
                            >
                              {t(
                                "ReservationWizard.reservationPage.labelEndDate"
                              )}{" "}
                              *
                            </label>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={3}
                            className={classes.gridItem}
                          >
                            <Field
                              component={EtdDatePickerV2}                      
                              name="endDateTime"
                              id="endDateTime"
                              value={endDate ? new Date(endDate) : null}
                              localeDateTimeFormat={localeDateTimeFormat}                              
                              handleChange={(e) => {
                                let newValue = e;
                                const format =
                                  localeDateTimeFormat.toUpperCase();
                                let parsedDate = moment(newValue, format, true);

                                if (parsedDate.isValid()) {
                                  newValue = moment(parsedDate).toDate();
                                } else {
                                  newValue = null;
                                }
                                setEndDate(newValue);
                              }}
                              handleBlur={(e) => {}}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={2}
                            className={classes.gridItem}
                          ></Grid>
                          <Grid
                            item
                            xs={12}
                            md={2}
                            className={classes.gridItem}
                          >
                            <label
                              htmlFor={"endTime"}
                              className={classes.labelText}
                            >
                              {t(
                                "ReservationWizard.reservationPage.labelEndTime"
                              )}{" "}
                              *
                            </label>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={3}
                            className={classes.gridItem}
                          >
                            <Field
                              component={EtdTimePicker}
                              id="endTime"
                              name="endTime"
                              format = {t("TimeFormat")}
                              defaultValue={endTime}
                              onChange={(e) => {
                                setEndTime(e.target.value);
                              }}
                              placeholder="End time"
                            />
                          </Grid>
                        </Grid>
                        <Grid container direction="row">
                          <Grid
                            item
                            xs={12}
                            md={12}
                            className={classes.gridItem}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent:"space-between",
                                marginTop: "1em",
                              }}
                            >
                              <span style={{ fontWeight: "bold", color: "#000000" }}>
                              {t("lblRequiedFields")}  {t("lblRequiedCO2Fields")}
                              </span>
                              <Button
                                variant="contained"
                                id="updateButton"
                                color="primary"
                                onClick={() =>
                                  getUpdatedCarClubVehicles(props.journeyUId)
                                }
                              >
                                {t("ReservationWizard.carClubPage.btnUpdate")}
                              </Button>
                            </div>
                          </Grid>
                        </Grid>

                        <Grid container direction="row">
                          <Grid
                            item
                            xs={12}
                            md={12}
                            className={classes.gridItem}
                          >
                            <section
                              aria-label={t(
                                "ReservationWizard.carClubPage.lblVehicleToChoose"
                              )}
                            >
                              <CarClubLegend />

                              <div
                                role="feed"
                                aria-label={"Vehicles to choose from"}
                                style={{
                                  alignItems: "center",
                                  verticalAlign: "middle",
                                  width: "100%",
                                  textAlign: "center",
                                }}
                              >
                                {getCurrentCarList() &&
                                getCurrentCarList().length > 0 ? (
                                  getCurrentCarList().map((car, i) => (
                                    <div
                                      role="article"
                                      // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
                                      tabIndex={0}
                                      key={i}
                                      id={"carClubTileId" + i}
                                      //style={{ marginTop: "1em",}}
                                      className={classes.tileDiv}
                                    >
                                      <Field
                                        component={CarClubCar}
                                        name={"vehicles" + i}
                                        carInfo={car}
                                        startDate={new Date(startDateTime)}
                                        endDate={new Date(endDateTime)}
                                        locale={locale}
                                        showEmission={true}
                                        handleCarSelect={handleCarClassSelect}
                                        carKey={i}
                                      />
                                    </div>
                                  ))
                                ) : (
                                  <div
                                    role="article"
                                    style={{
                                      height: "20px",
                                      width: "100%",
                                      marginTop: "5px",
                                      marginBottom: "5px",
                                    }}
                                  >
                                    <hr />
                                    <span id="noResultsMsgId">
                                      {t(
                                        "ReservationWizard.carClubPage.msgNoResultsFound"
                                      )}
                                    </span>
                                    <hr />
                                  </div>
                                )}
                              </div>
                            </section>
                            <Grid
                              item
                              xs={12}
                              md={12}
                              lg={12}
                              className={classes.gridItem}
                            >
                              <Grid container>
                                <Grid item xs={12} md={9}>
                                <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "flex-start",
                                      marginTop: "1em",
                                    }}
                                  >
                                  <Button
                                    id="btnBack"
                                    color="primary"
                                    //style={{marginTop: "1em"}}
                                    aria-label={t("lblBackToPreviousStep")}
                                    variant="contained"
                                    onClick={() => {
                                      if (props.handleBack)
                                        props.handleBack(0, 0);
                                    }}
                                  >
                                    {t("btnBack")}
                                  </Button>
                                  </div>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "flex-end",
                                      marginTop: "1em",
                                    }}
                                  >
                                    <Button
                                      id="btnPrevious"
                                      color="primary"
                                      variant="contained"
                                      aria-label={t(
                                        "ReservationWizard.carClubPage.labelAriaPreviousButton"
                                      )}
                                      aria-disabled={currentPage === 1}
                                      disabled={currentPage === 1}
                                      onClick={() =>
                                        setCurrentPage(currentPage - 1)
                                      }
                                    >
                                      {t(
                                        "ReservationWizard.carClubPage.btnPreviousPage"
                                      )}
                                    </Button>
                                    <Button
                                      style={{ marginLeft: 10 }}
                                      id="btnNext"
                                      aria-label={t(
                                        "ReservationWizard.carClubPage.labelAriaNextButton"
                                      )}
                                      color="primary"
                                      variant="contained"
                                      aria-disabled={currentPage >= totalPages}
                                      disabled={currentPage >= totalPages}
                                      onClick={() =>
                                        setCurrentPage(currentPage + 1)
                                      }
                                    >
                                      {t(
                                        "ReservationWizard.carClubPage.btnNextPage"
                                      )}
                                    </Button>
                                  </div>
                                </Grid>
                              </Grid>

                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  marginTop: "1em",
                                }}
                              >
                                <span style={{ display: "none" }}>
                                  {t(
                                    "ReservationWizard.carClubPage.labelAvailableVehiclesofPage"
                                  )}
                                </span>
                                <span>
                                  {t(
                                    "ReservationWizard.carClubPage.labelPageNOfN",
                                    {
                                      currentPage: currentPage,
                                      totalPage: totalPages,
                                    }
                                  )}
                                </span>
                              </div>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>{" "}
                    </AccordionDetails>
                  </Accordion>
                </Form>
              </Grid>
            )}
          </Grid>
        </Box>
      )}
    </Formik>
  );
};

export default CarClubSelectionForm;
