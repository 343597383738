import * as Yup from "yup";
import { JourneyMethodType, ValidationResult } from "./reservationFormType";
import { authService } from "services/authService";

function validateSelectField(value) {
  if (
    typeof value["selectedValue"] === "undefined" ||
    value["selectedValue"] === ""
  )
    return false;
  else return true;
}

const isBrandSelected = (carClassInfo) => {
  
  if (carClassInfo && carClassInfo !== null) {
    for (var i = 0; i < carClassInfo.carsInformation.classInfo.length; i++) {
      for (
        var j = 0;
        j < carClassInfo.carsInformation.classInfo[i].brandInfo.length;
        j++
      ) {
        if (carClassInfo.carsInformation.classInfo[i].brandInfo[j].isSelected) {
          return true;
        }
      }
    }
  }
  return false;
};
const Today = () => {
  const today = new Date();
  today.setDate(today.getDate() - 1);
  today.setHours(23, 59, 59);
  return today;
};

const Yesterday = () => {
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 2);
  yesterday.setHours(23, 59, 59);
  return yesterday;
};

const isDriverValid = (endDate) => {
  let driverInfo = authService.getDriverInfo();
  if (
    driverInfo?.isBOBOToggleEnabledAndIsDriverDeclaration == null ||
    !driverInfo?.isBOBOToggleEnabledAndIsDriverDeclaration
  ) {
    return true;
  }

  let isDriverValid = true;
  if (
    driverInfo != null &&
    driverInfo?.primaryDriver?.driverDeclarationExpiryDate != null
  ) {
    isDriverValid = isExpiryDateBeforeEndDate(
      driverInfo?.primaryDriver?.driverDeclarationExpiryDate,
      endDate
    );
    driverInfo?.additionalDriver?.forEach((element) => {
      if (isDriverValid) {
        isDriverValid = isExpiryDateBeforeEndDate(
          element.driverDeclarationExpiryDate,
          endDate
        );
      }
    });
  }
  return isDriverValid;
};

function isExpiryDateBeforeEndDate(driverDeclarationExpiryDate, endDate) {
  let driverExpiryDate = new Date(driverDeclarationExpiryDate);
  let reservationEndDate = new Date(endDate);
  if (reservationEndDate > driverExpiryDate) {
    return false;
  } else {
    return true;
  }
}

export const ValidateFlightNumber = (
  flightNumber: string
): ValidationResult => {
  var result: ValidationResult = { isInValid: false, message: "" };
  // restored the below changes suggested in 23/05/23 in AC
  //const exp =/[A-Z0-9]*[A-Z][A-Z0-9]*[0-9][A-Z0-9]*$/
  const exp = /^[a-zA-Z0-9]*$/;
  if (!flightNumber) {
    result = { isInValid: false, message: "" };
    return result;
  }
  if (flightNumber.length < 3) {
    result = { isInValid: true, message: "Users.Errors.flightNumberLength" };
    return result;
  }
  if (flightNumber.length > 9) {
    result = { isInValid: true, message: "Users.Errors.flightNumberLength" };
    return result;
  }
  // restored the below changes suggested in 23/05/23 in AC
  if (!exp.test(flightNumber)) {
    result = { isInValid: true, message: "Users.Errors.flightNumberIncorrect" };
    return result;
  }
  return result;
};

export const reservationValidationSchema = Yup.object().shape({
  companyUId: Yup.string()
    .required("Reservation.DailyRental.Errors.companyUIdRequired")
    .nullable(),
  dateTime: Yup.object().shape({
    startDateTime: Yup.date()
      .min(Yesterday(), "journeyInput.msgDateCanOnlyBeUpToOneDayPast")
      .typeError("ReservationWizard.reservationPage.msgInvalidStartDate")
      .required("ReservationWizard.reservationPage.msgStartDateRequired"),

    endDateTime: Yup.date()
      .min(Yesterday(), "journeyInput.msgDateCanOnlyBeUpToOneDayPast")
      .typeError("ReservationWizard.reservationPage.msgInvalidEndDate")
      .required("ReservationWizard.reservationPage.msgEndDateRequired"),
    endDate: Yup.date()
      .nullable()
      .required("ReservationWizard.reservationPage.msgEndDateRequired")
      .min(Yesterday(), "journeyInput.msgDateCanOnlyBeUpToOneDayPast")
      .typeError("ReservationWizard.reservationPage.msgInvalidEndDate")
      .test(
        "testAdditionalDriverExpiryDate",
        "ReservationWizard.reservationPage.msgInvalidDriver",
        (value) => {
          return value ? isDriverValid(value) : true;
        }
      ),

    endTime: Yup.string()
      .nullable()
      .test(
        "endTime-required",
        "ReservationWizard.reservationPage.msgEndTimeRequired",
        (value) => value !== "0"
      ),

    startDate: Yup.date()
      .nullable()
      .typeError("ReservationWizard.reservationPage.msgInvalidStartDate")
      .required("ReservationWizard.reservationPage.msgStartDateRequired")
      .min(Yesterday(), "journeyInput.msgDateCanOnlyBeUpToOneDayPast"),

    startTime: Yup.string()
      .nullable()
      .test(
        "startTime-required",
        "ReservationWizard.reservationPage.msgStartTimeRequired",
        (value) => {
          return value !== null && value !== undefined && value.length > 1;
        }
      )     
  }),

  reasonForHire: Yup.object().shape({
    accountUId: Yup.string()
      .required("ReservationWizard.reservationPage.accountUIdRequired")
      .nullable(),
  }),

  locations: Yup.object().shape({
    startLocation: Yup.object().shape({
      selectedMethodTypeId: Yup.string(),
      addressLine1: Yup.string()
        .nullable()
        .when(["selectedMethodTypeId"], {
          is: (selectedMethodTypeId) =>
            selectedMethodTypeId &&
            selectedMethodTypeId === JourneyMethodType.delivery,
          then: Yup.string().required("deliveryAddress1Required").nullable(),
        })
        .nullable(),

      townOrCity: Yup.string()
        .when(["selectedMethodTypeId"], {
          is: (selectedMethodTypeId) =>
            selectedMethodTypeId &&
            selectedMethodTypeId === JourneyMethodType.delivery,
          then: Yup.string().required("deliveryTownRequired").nullable(),
        })
        .nullable(),
      postCode: Yup.string()
        .when(["selectedMethodTypeId"], {
          is: (selectedMethodTypeId) =>
            selectedMethodTypeId &&
            selectedMethodTypeId === JourneyMethodType.delivery,
          then: Yup.string().required("deliveryPostRequired").nullable(),
        })
        .nullable(),
    }),
    endLocation: Yup.object()
      .shape({
        selectedMethodTypeId: Yup.string(),
        addressLine1: Yup.string()
          .nullable()
          .when(["selectedMethodTypeId"], {
            is: (selectedMethodTypeId) =>
              selectedMethodTypeId &&
              selectedMethodTypeId === JourneyMethodType.collection,
            then: Yup.string()
              .required("collectionAddress1Required")
              .nullable(),
          })
          .nullable(),
        townOrCity: Yup.string()
          .when(["selectedMethodTypeId"], {
            is: (selectedMethodTypeId) =>
              selectedMethodTypeId &&
              selectedMethodTypeId === JourneyMethodType.collection,
            then: Yup.string().required("collectionTownRequired").nullable(),
          })
          .nullable(),
        postCode: Yup.string()
          .when(["selectedMethodTypeId"], {
            is: (selectedMethodTypeId) =>
              selectedMethodTypeId &&
              selectedMethodTypeId === JourneyMethodType.collection,
            then: Yup.string().required("collectionPostRequired").nullable(),
          })
          .nullable(),
      })
      .nullable(),
  }),
  notesViewModel: Yup.object().shape({
    reservationNote: Yup.string()
      .nullable()
      .max(150, "maximum allowed characters 150"),
  })
  
  // carClass: Yup.object()
  //   .nullable()
  //   .test(
  //     "vehicle-not-null",
  //     "ReservationWizard.reservationPage.msgSelectVehicle",
  //     (value) => isBrandSelected(value)
  //   )
});
