import React, { useEffect } from "react";
import clsx from "clsx";
import {
  Box,
  Button,
  Grid,
  Step,
  StepIconProps,
  StepLabel,
  Stepper,
  Typography,
  useMediaQuery,
} from "@mui/material";

import { authService } from "../../../../services/authService";
import { useTheme } from "@mui/material/styles";

import makeStyles from '@mui/styles/makeStyles';

import AccessTimeIcon from "@mui/icons-material/AccessTime";
import LocationOnIcon from "@mui/icons-material/LocationOn";

import {
  StartEndLocation,
  JourneyProfilerViaPoint,
  JourneyProfile,
} from "./journeyProfileType";

import { ReservationProps } from "../../reservationType";

import { useTranslation } from "react-i18next";

const initStartEndLocation: StartEndLocation = {
  location: "",
  peopleSoftIds: { brand: "" },
  stationIds: { brand: "" },
  latitude: "",
  longitude: "",
  brand: "",
};

const initJourneyProfile: JourneyProfile = {
  journeyProfilerUId: "",
  journeyProfilerId: -1,
  companyUId: "",
  companyId: 0,
  companyProductUId: "",
  companyProductId: 0,
  description: "",
  startLocation: initStartEndLocation,
  endLocation: initStartEndLocation,
  startDateTime: new Date(),
  clientCurrentDateTime: new Date(),
  endDateTime: new Date(),
  returnToStartLocation: false,
  totalDistance: 0,
  totalDuration: 0,
  modeOfTransportUId: "",
  modeOfTransportId: "",
  modeOfTransport: "",
  registrationNumber: "",
  distanceUnit: 0,
  username: "",
  appsecUserId: 0,
  loggedTimestamp: new Date(),
  deleted: false,
  totalRecords: 0,
  riskAssessmentExists: true,
  isJourneyProfile: false,
  isJourneyUpdated: false,
  referenceNumber: "",
  activeTravelPolicy: false,
  isDailyRentalSingleProduct: false,
  isRebook: false,
  driverName: "",
  status: "",
  isValidJourneyAssessmentStartDate: false,
  endDistance: 0,
  endDuration: 0,
  returnToStartTotalDescription: "",
  returnToStartDistance: 0,
  returnToStartTotalDuration: 0,
  destination: "",
  carClubDriverId: "",
  journeyProfilerViaPoints: [],
  additionalInformation: "",
  isInternationalStartLocation: false,
  isMidpointsEnabled: false,
};

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: "#fff",
    zIndex: 1,
    color: "#fff",
    width: 32,
    height: 32,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    /* backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",*/
    backgroundColor: "#006639",
  },
  completed: {
    backgroundColor: "#FFA500",
    /*   backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
      */
  },
});
function ColorlibStepIcon(props: StepIconProps) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons: { [index: string]: React.ReactElement } = {
    1: <LocationOnIcon />,
    2: <AccessTimeIcon />,
    3: <LocationOnIcon />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}>
      {icons[String(props.icon)]}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  stepper: {
    padding: theme.spacing(0, 0, 2),
    marginLeft: "1em",
    marginRight: "1em",
    width: "100%",
  },
  stepIcon: {
    color: "#cdcdcd",
    width: 40,
    height: 40,
  },
  root: {
    "& .MuiFormHelperText-root": {
      color: "#DF0000",
      fontSize: 12,
      marginLeft: "15px",
    },
    "& ::placeholder": {
      fontStyle: "italic",
      color: "#000",
      opacity: 0.95,
    },
    "& .cke_resizer": {
      borderRightColor: "#000000",
    },
  },
  paper: {
    padding: "6px 6px",
    textAlign: "left",
  },
  outlinedButton: {
    border: "1px solid rgba(0, 102, 57, 0.95);",
  },
  inputRoot: {
    fontSize: 30,
  },
  labelRoot: {
    fontSize: 30,
    color: "red",
    "&$labelFocused": {
      color: "purple",
    },
  },
  errorWarning: {
    color: "#DF0000",
    fontSize: 12,
    marginLeft: "15px",
  },
  backdrop: {
    zIndex: 99999,
    color: "#fff",
  },

  labelFocused: {},

  hover: {
    color: "#ed1212",
    cursor: "pointer",
  },

  endLocationDotColor: {
    "&.MuiTimelineDot-root": { backgroundColor: "#FFA500" },
  },

  oppositeContentSpace: {
    "&.MuiTimelineOppositeContent-root": {
      flex: "0",
      padding: "6px 0px",
      textAlign: "right",
      marginRight: "auto",
    },
  },
  contentSpaceForIE: {
    "&.MuiTimelineContent-root": {
      flex: "1",
      padding: "6px 40px",
    },
  },
  contentSpaceForChrome: {
    "&.MuiTimelineContent-root": {
      flex: "1",
      padding: "6px 16px",
    },
  },

  ConnectorForIE: {
    //backgroundColor: theme.palette.secondary.main,
    position: "relative",
    left: "15px",
    top: "0px",
    padding: "0px 1px",
  },
  ConnectorForChrome: {
    //backgroundColor: theme.palette.secondary.main,
    position: "relative",
    left: "0px",
    top: "0px",
    padding: "0px 1px",
  },
}));
const CompactJourneyDetailsView: React.FC<ReservationProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const steps: string[] = getSteps();
  const theme = useTheme();
  const isSmXs = useMediaQuery(theme.breakpoints.down('lg'));
  useEffect(() => {}, []);

  function getSteps() {
    var language = authService.getUserLocale();
    const distance =
      (language === "en-GB" || language === "en-US")
        ? (props.journeyDetails.totalDistance / 1.609).toFixed(2)
        : props.journeyDetails.totalDistance;
    let steps = Array();
    if (props.journeyDetails) {
      steps.push(props.journeyDetails.startLocation.location);
      steps.push(
        `${t(
          "ReservationWizard.reservationPage.labelTotalDistance"
        )}:  ${distance} (${t("mileUnit")}) , ${t(
          "ReservationWizard.reservationPage.labelTotalDuration"
        )}: ${props.journeyDetails.totalDuration.toString()} (${t("minutes")})`
      );
      steps.push(props.journeyDetails.endLocation.location + " ");
    }

    return steps;
  }
  return (
    <Box
      id = "JourneyDetails"
      data-testid="compactJourneyDetailsView"
      boxShadow={3}
      p={2}
      bgcolor="background.paper"
      style={{ width: "100%" }}>
      <Stepper
        alternativeLabel
        className={classes.stepper}
        orientation={isSmXs ? "vertical" : "horizontal"}>
        {steps.map((label, index) => {
          return (
            <Step key={label+index} id={label} active={true} completed={index === 2}>
              <StepLabel
                data-testid={"journeyStepDetailsDiv" + index}
                id={"journeyStepDetailsDiv" + index}
                StepIconComponent={ColorlibStepIcon}>
                {label}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
};

export default CompactJourneyDetailsView;
