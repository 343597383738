import { TFunction } from "i18next";
import * as Yup from "yup";

export const additionalDriversValidationSchema = (
    t: TFunction,
    primaryDriverDeclarationId: number,
    reservationEndDate?: string
) => {
    return Yup.object().shape({
        additionalDriver: Yup.array()
            .of(
                Yup.object()
                    .nullable()
                    .notRequired()
                    .test(
                        "testAdditionalDriverExpiryDate",
                        "journeyInput.msgInvalidAdditionalDriver",
                        (value) => {
                            if (value !== null) {
                                if (
                                    value["firstName"] !== undefined &&
                                    value["firstName"] !== ""
                                ) {
                                    let driverExpiryDate = new Date(
                                        value["driverDeclarationExpiryDate"]
                                    );
                                    if (reservationEndDate) {
                                        let endDate = new Date(reservationEndDate);
                                        if (driverExpiryDate < endDate) {
                                            return false;
                                        }
                                    }
                                }
                                return true;
                            }
                            return true;
                        }
                    )
                    .test(
                        "testAdditionalDriverSameAsPrimaryDriver",
                        "journeyInput.msgDuplicateAdditionalDriver",
                        (value) => {
                            if (value !== null) {
                                if (
                                    value["driverDeclarationId"] === primaryDriverDeclarationId
                                ) {
                                    return false;
                                } else return true;
                            }
                            return true;
                        }
                    )
                    .test(
                        "AdditionalDriverEmpty",
                        "journeyInput.msgInvalidAdditionalDriver",
                        (value) => {
                            if (
                                value &&
                                value !== null &&
                                value.firstName !== "" &&
                                value.firstName !== undefined
                            ) {
                                return true;
                            }
                            return false;
                        }
                    )
                    .test(
                        "AdditionalDriverDuplicated",
                        "journeyInput.msgDuplicateAdditionalDriver",
                        function (value) {
                            if (this.parent != null && this.parent.length > 1 && value?.firstName !== undefined) {
                                let valueArr = this.parent.map(function (item) {
                                    return item?.driverDeclarationId
                                });
                                let isDuplicate = valueArr.some(function (item, idx) {
                                    return valueArr.indexOf(item) !== idx
                                });
                                if (isDuplicate) { return false; }
                                else { return true; }

                            }
                            return true;
                        }
                    )
            )
            .max(29, t("journeyInput.msgAdditionalDriversReachLimit")),
    });
};
