import {
  Backdrop,
  Box,
  CircularProgress,
  Dialog,
  Divider,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Grid from "@mui/material/Grid";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axiosInstance from "../../helpers/axiosInstance";
import { CarClubReservationViewModel } from "./ReservationViewType";
import CancelIcon from "@mui/icons-material/Cancel";
import PrintIcon from "@mui/icons-material/Print";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import {
  clearAlertMessage,
  showErrorAlertMessage,
  showInfoAlertMessage,
} from "../../components/store/actions/shared/alertActions";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "components/store/reducers/rootReducer";
import CarClubLogin from "controls/CarClubLoginDialog";
import CarClubYesNo from "controls/CarClubYesNoDialog";
import "components/home/home.css";
import moment from "moment";
import { ArrowBack, FormatAlignCenter } from "@mui/icons-material";
import { broadcastAnnouncement } from "components/store/actions/shared/announcementActions";
import { authService } from "services/authService";
import { appInsights } from "helpers/appInsights";
import { SeverityLevel } from "@microsoft/applicationinsights-common";
import {
  ReAuthenticateCarClub,
  reAuthenticationStatus,
} from "services/carClubReAuth";
import {
  getLocaleCurrency,
  getcompanyCountryCurrency,
} from "helpers/localization";
import CarClubLogo from "Assets/Images/16_ECC_RGB.jpg";
import CarShareLogo from "Assets/Images/12_ECS_RGB.jpg";
import { carClubDriverDetails } from "components/store/actions/reservation/carclubActions";
import {
  getUserPermissionsStatus,
  Permissions,
} from "helpers/getUserPermissions";
import { isHiddenAdditionalParameters } from "helpers/additionalParameters";
import { getBookingReference } from "./ViewReservationDR";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
    marginBottom: 20,
    paddingRight: 30,
    paddingTop: 15,
    paddingBottom: 15,
    "& .MuiTableCell-root": {
      verticalAlign: "top",
      borderBottom: "none",
      padding: "10px",
    },
  },
  title: {
    fontSize: "1.4em",
    paddingLeft: 30,
    marginBottom: 10,
  },
  subtitle: {
    fontSize: "1.2em",
    marginTop: 10,
    marginBottom: 10,
  },
  tableHeader: {
    fontWeight: "bold",
    padding: 6,
    borderBottom: "none",
    fontSize: "1.1em",
  },
  tableBody: {
    fontSize: "1.1em",
    padding: 4,
    borderBottom: "none",
  },
  subTableBody: {
    fontSize: "1.1em",
    padding: 0,
    borderBottom: "none",
  },
  divider: {
    backgroundColor: "#000",
    height: "0.8px",
  },
  gridItem: {
    verticalAlign: "center",
    paddingBottom: "8px",
    paddingRight: "10px",
    paddingLeft: "0px",
    "& .MuiTypography-body2": {
      fontWeight: "bold",
    },
  },
  rootcell: {
    "& .MuiTableCell-root": {
      verticalAlign: "top",
      borderBottom: "none",
      padding: "10px",
    },
  },
  tableBodyCurrency: {
    fontSize: "1.1em",
    padding: 4,
    borderBottom: "none",
    wordBreak: "break-word",
    textAlign: "right",
    paddingRight: "10px",
    paddingLeft: "10px",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
}));

const ViewCC = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const ukDate = "DD/MM/YYYY HH:mm";
  const [resData, setresData] = React.useState<CarClubReservationViewModel>();
  const dispatch = useDispatch();
  const { journeyID, reference } = useParams<{
    journeyID: string;
    reference: string;
  }>();
  const CCLocation = useLocation();
  const [open, setOpen] = React.useState(false);
  const [ccOpenYesNo, setOpenCcYesNo] = React.useState(false);
  const [cancelDisabled, setCancelDisabled] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const cancelButtonRef = React.useRef<HTMLButtonElement>(null);
  const printButtonRef = React.useRef<HTMLButtonElement>(null);
  const [isReauth, setisReauth] = React.useState(false);

  const [isCCLoggedIn, setIsCCLoggedIn] = useState(
    JSON.parse(localStorage.getItem("ccLoggedIn") ?? "false") ?? []
  );
  const isccPersisted = authService.getUserccPersisted();

  const locale = authService.getUserLocale();
  const companyCountryCode = authService.getUserCompanyCountryCode();
  const { companyInfo } = useSelector(
    (state: AppState) => state.companyInfo || {}
  );
  const hasUserViewOnlyPermission = getUserPermissionsStatus(
    authService.getUserPermissions(),
    Permissions.ViewOnly
  );

  appInsights.trackEvent({
    name: "View CarClub",
    properties: {
      Reference: reference,
      DriverId: authService.getUserCarClubId(),
    },
  });

  const isTokenExpired = authService.getUserccReauthExpired();
  const takoApiEnv = authService.getTakoApiEnv();

  const isCarShareReservation = String(takoApiEnv).toUpperCase().includes("CS");

  const handleOpenCCLogin = () => {
    setOpen(true);
  };
  const handleCCClose = () => {
    setOpen(false);
    if (cancelButtonRef.current != null) cancelButtonRef.current.focus();
  };
  const handleCCOpenYesNo = () => {
    setOpenCcYesNo(true);
  };
  const handleLoading = (isloading) => {
    setLoading(isloading);
  };

  const handleCCOpenYesNoClose = () => {
    setOpenCcYesNo(false);
  };
  const gobackAction = () => {
    dispatch(clearAlertMessage());
    history(-1);
  };
  let history = useNavigate();
  useEffect(() => {
    async function GetReservation() {
      setLoading(true);
      const data = await axiosInstance
        .post(`carclub/details`, {
          journeyUId: journeyID,
          referenceNumber: reference,
          driverId: authService.getUserCarClubId(),
        })
        .then((response) => {
          setresData(response.data);
          setLoading(false);
          response.data?.status === "Cancelled" ||
          response.data?.isPastReservation
            ? setCancelDisabled(true)
            : setCancelDisabled(false);
          if (response.data?.isPastReservation) {
            dispatch(showInfoAlertMessage(t("Res.ReservationCancelMessage")));
            dispatch(broadcastAnnouncement(t("Res.ReservationCancelMessage")));
          }
        })
        .catch((error) => {
          console.error("There was an error!", error);
          appInsights.trackException({
            id: "ViewCC",
            exception: error,
            severityLevel: SeverityLevel.Error,
          });
        });
    }
    document.title = t("ViewJourneyTitle");
    dispatch(clearAlertMessage());
    GetReservation();
    if (printButtonRef.current != null && !props.disableAutoFocus)
      printButtonRef.current.focus();
  }, []);

  function handleCancelJourneySuccessful() {
    setCancelDisabled(true);
  }

  const userCarClubId = authService.getUserCarClubId();

  return (
    <Box m={3}>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress />
      </Backdrop>
      <Grid
        data-testid="viewCC"
        className={classes.rootcell}
        container
        spacing={3}
      >
        <Dialog
          aria-labelledby="cc-dialog-title"
          aria-describedby="cc-dialog-description"
          open={open}
          onClose={handleCCClose}
        >
          <CarClubLogin
            journeyUId={journeyID}
            reference={reference}
            isView={false}
            isReauth={isReauth}
            handleCancelJourneySuccessful={handleCancelJourneySuccessful}
            action={handleCCClose}
          />
        </Dialog>
        <Dialog
          aria-labelledby="cc-yesno-dialog-title"
          aria-describedby="cc-yesno-dialog-description"
          open={ccOpenYesNo}
          TransitionProps={
            { role: "presentation", visibility: "hidden" } as any
          }
        >
          <CarClubYesNo
            id="carClubYesNoDialog"
            journeyUId={journeyID}
            reference={reference}
            handleLoading={handleLoading}
            handleCancelJourneySuccessful={handleCancelJourneySuccessful}
            action={handleCCOpenYesNoClose}
            dialogType="cancel"
            confirmMessage={t("cancelCCResConfirmationMessage")}
          />
        </Dialog>
        <Grid container direction="row">
          <Grid item xs={12} md={8}></Grid>
          <Grid item xs={12} md={4} id="actionButtons">
            <Box display="flex" style={{ float: "right" }}>
              <IconButton
                id="printJourneyButton"
                size="medium"
                ref={printButtonRef}
                title={t("Res.PrintTitle")}
                aria-label={t("Res.PrintJourney")}
                onClick={() => {
                  dispatch(clearAlertMessage());
                  appInsights.trackEvent({
                    name: "View CarClub Print",
                    properties: { Reference: reference },
                  });
                  window.print();
                }}
                style={{
                  marginRight: 5,
                  border: "1px solid #547D66",
                  color: "#000000",
                }}
              >
                <PrintIcon />
                {t("Print")}
              </IconButton>
              <IconButton
                id="cancelJourneyButton"
                size="medium"
                ref={cancelButtonRef}
                aria-label={t("CancelJourney")}
                title={t("CancelTitle")}
                onClick={async () => {
                  appInsights.trackEvent({
                    name: "View CarClub Cancel",
                    properties: { Reference: reference },
                  });
                  if (hasUserViewOnlyPermission) {
                    dispatch(showErrorAlertMessage(t("ViewOnlyError")));
                    dispatch(broadcastAnnouncement(t("ViewOnlyError")));
                    window.scroll(0, 0);
                  } else {
                    setisReauth(false);
                    if (!isCCLoggedIn) handleOpenCCLogin();
                    else {
                      setLoading(true);
                      const reAuth = (await ReAuthenticateCarClub(
                        userCarClubId
                      )) as reAuthenticationStatus;
                      setLoading(false);
                      if (reAuth.reAuthenticated) {
                        handleCCOpenYesNo();
                      } else {
                        setisReauth(true);
                        handleOpenCCLogin();
                      }
                    }
                  }
                }}
                disabled={cancelDisabled}
                style={
                  cancelDisabled
                    ? {
                        marginRight: 5,
                        border: "1px solid #737774",
                        color: "#7e817e",
                      }
                    : {
                        marginRight: 5,
                        border: "1px solid #547D66",
                        color: "#d02216",
                      }
                }
              >
                <CancelIcon />
                {t("Cancel")}
              </IconButton>
              <IconButton
                id="backButton"
                size="medium"
                aria-label={t("BackToPrevious")}
                title={t("BackTitle")}
                onClick={() => gobackAction()}
                style={{
                  marginRight: 5,
                  border: "1px solid #547D66",
                  color: "#000000",
                }}
              >
                <ArrowBack />
                {t("Back")}
              </IconButton>
            </Box>
          </Grid>
        </Grid>
        <Grid container direction="row">
          <Grid
            item
            xs={12}
            md={2}
            className={classes.gridItem}
            style={{
              minWidth: 100,
              maxWidth: 200,
              /* paddingLeft: 30, */
              paddingRight: 0,
            }}
          >
            <Typography>
              <img
                id="carClubLogo"
                style={{
                  display: "block",
                  margin: "0 auto",
                  paddingLeft: "10px",
                  maxWidth: "80%",
                  maxHeight: "80%",
                }}
                alt="Enterprise Car Club, logo"
                src={isCarShareReservation ? CarShareLogo : CarClubLogo}
              />
            </Typography>
          </Grid>
          <Grid item xs={1} md={8} className={classes.gridItem}>
            <Typography
              id="cctitleHeader"
              className={classes.title}
              variant="h1"
              align="center"
            >
              {t("Res.CarClubPrintReservationHeader")}
            </Typography>
            <div style={{ paddingLeft: 30 }}>
              <Typography
                id="cctitle"
                className={classes.subtitle}
                align="center"
              >
                {t("Res.SummaryOfJourney")}
              </Typography>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            md={2}
            className={classes.gridItem}
            style={{
              minWidth: 100,
              maxWidth: 200,
              paddingLeft: 0,
              /* paddingRight: 30, */
            }}
          >
            <Typography>
              <img
                style={{
                  display: "block",
                  margin: "0 auto",
                  maxWidth: "80%",
                  maxHeight: "80%",
                }}
                alt=""
                src={resData?.imageUrl}
              />
            </Typography>
          </Grid>
        </Grid>
        <Grid container direction="row">
          <Grid item xs={12} md={12}>
            <Box style={{ paddingLeft: 30 }}>
              <Divider className={classes.divider} />
            </Box>
          </Grid>
        </Grid>
        <Grid container direction="row">
          <Grid item xs={12} md={6} className={classes.root}>
            <Box style={{ paddingLeft: 30 }}>
              <TableContainer id="detailsTable">
                <Table aria-label="journey table">
                  <TableBody>
                    <TableRow>
                      <TableCell
                        width="40%"
                        className={classes.tableHeader}
                        component="th"
                      >
                        <Typography variant="h2">
                          {t("ReferenceNumber")}
                        </Typography>
                      </TableCell>
                      <TableCell
                        id="referenceNumberDiv"
                        className={classes.tableBody}
                      >
                        <Typography variant="body1">
                          {resData?.carClubReferenceNumber}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    {resData?.additionalInformation != null &&
                      resData?.additionalInformation.additionalInformationFields.length > 0 && (
                        <TableRow>
                          <TableCell
                            width="19%"
                            className={classes.tableHeader}
                            component="th"
                          >
                            <Typography variant="h2">
                              {t("Res.BookingReferences")}{" "}
                            </Typography>
                          </TableCell>
                          <TableCell className={classes.subTableBody}>
                            <Table
                              id="carClubBookingReferences"
                              aria-label="booking references table"
                            >
                              <TableBody>
                                {resData?.additionalInformation.additionalInformationFields?.map((p, index) => {
                                  return ((!isHiddenAdditionalParameters(p.fieldName) &&
                                    <TableRow
                                      id={"carClubBookingReferences" + index}
                                      key={"bookingReferences_" + index}
                                    >
                                      <TableCell className={classes.tableBody}>
                                        <Typography variant="body1">
                                          {" "}
                                          {getBookingReference(p, locale.toString())}
                                        </Typography>
                                      </TableCell>
                                    </TableRow>
                                  ));
                                })}
                              </TableBody>
                            </Table>
                          </TableCell>
                        </TableRow>
                      )}
                    <TableRow>
                      <TableCell className={classes.tableHeader} component="th">
                        <Typography variant="h2">
                          {" "}
                          {t("StartDateTime")}
                        </Typography>
                      </TableCell>
                      <TableCell
                        id="startDateTimeDiv"
                        className={classes.tableBody}
                      >
                        <Typography variant="body1">
                          {resData?.startDateString === undefined
                            ? ""
                            : moment(resData?.startDateString, ukDate).format(
                                t("DateTimeFormat")
                              )}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.tableHeader} component="th">
                        <Typography variant="h2">{t("EndDateTime")}</Typography>
                      </TableCell>
                      <TableCell
                        id="endDateTimeDiv"
                        className={classes.tableBody}
                      >
                        <Typography variant="body1">
                          {resData?.endDateString === undefined
                            ? ""
                            : moment(resData?.endDateString, ukDate).format(
                                t("DateTimeFormat")
                              )}{" "}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.tableHeader} component="th">
                        <Typography variant="h2">
                          {" "}
                          {t("StartLocation")}
                        </Typography>
                      </TableCell>
                      <TableCell
                        id="startAddressDiv"
                        className={classes.tableBody}
                      >
                        <Typography variant="body1">
                          {resData?.carClubStartLocationLabel}{" "}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.tableHeader} component="th">
                        <Typography variant="h2">{t("EndLocation")}</Typography>
                      </TableCell>
                      <TableCell
                        id="endAddressDiv"
                        className={classes.tableBody}
                      >
                        {" "}
                        <Typography variant="body1">
                          {resData?.carClubStartLocationLabel}{" "}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    {resData?.carClubViaLocationLabel != null &&
                      resData?.carClubViaLocationLabel.length > 0 && (
                        <TableRow id="midPointsDiv">
                          <TableCell
                            className={classes.tableHeader}
                            component="th"
                          >
                            <Typography variant="h2">
                              {t("Res.CarClubViaLocation")}
                            </Typography>
                          </TableCell>
                          <TableCell className={classes.subTableBody}>
                            <Table aria-label="booking references table">
                              <TableBody>
                                {resData?.carClubViaLocationLabel?.map(
                                  (p, index) => {
                                    return (
                                      <TableRow
                                        key={"carClubViaLocation_" + index}
                                      >
                                        <TableCell
                                          style={{
                                            paddingLeft: 0,
                                            paddingTop: 0,
                                          }}
                                          className={classes.tableBody}
                                        >
                                          <Typography variant="body1">
                                            {p}
                                          </Typography>
                                        </TableCell>
                                      </TableRow>
                                    );
                                  }
                                )}
                              </TableBody>
                            </Table>
                          </TableCell>
                        </TableRow>
                      )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} className={classes.root}>
            <Box style={{ paddingLeft: 30 }}>
              <TableContainer id="driverDetailsTable">
                <Table>
                  <TableBody>
                    {resData?.bookerName && (
                      <TableRow>
                        <TableCell
                          width="40%"
                          className={classes.tableHeader}
                          component="th"
                        >
                          {" "}
                          <Typography variant="h2">
                            {" "}
                            {t("Res.booker")}
                          </Typography>
                        </TableCell>
                        <TableCell
                          id="bookerNameDiv"
                          className={classes.tableBody}
                        >
                          <Typography variant="body1">
                            {resData?.bookerName}{" "}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                    <TableRow>
                      <TableCell
                        width="40%"
                        className={classes.tableHeader}
                        component="th"
                      >
                        <Typography variant="h2"> {t("Res.Driver")}</Typography>
                      </TableCell>
                      <TableCell
                        id="driverNameDiv"
                        className={classes.tableBody}
                      >
                        <Typography variant="body1">
                          {resData?.carClubDriverName}{" "}
                        </Typography>
                      </TableCell>
                    </TableRow>

                    {companyInfo?.isDriverValidityActivated &&
                      resData?.additionalDrivers.length > 0 && (
                        <TableRow>
                          <TableCell
                            width="40%"
                            className={classes.tableHeader}
                            component="th"
                          >
                            <Typography variant="h2">
                              {" "}
                              {t("AdditionalDriver").concat(":")}
                            </Typography>
                          </TableCell>
                          <TableCell
                            id="AdditionalDriverDiv"
                            className={classes.tableBody}
                          >
                            {resData?.additionalDrivers?.map(
                              (additionalDriver, index) => {
                                return (
                                  <Typography key={index} variant="body1">
                                    {" "}
                                    {additionalDriver.firstName +
                                      " " +
                                      additionalDriver.lastName}
                                    <br />{" "}
                                  </Typography>
                                );
                              }
                            )}
                          </TableCell>
                        </TableRow>
                      )}
                    <TableRow>
                      <TableCell
                        width="40%"
                        className={classes.tableHeader}
                        component="th"
                      >
                        <Typography variant="h2">
                          {" "}
                          {t("Res.MemoCode")}
                        </Typography>
                      </TableCell>
                      <TableCell
                        className={classes.tableBody}
                        id="carClubMemoDiv"
                      >
                        <Typography variant="body1">
                          {resData?.memoCode}{" "}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        width="40%"
                        className={classes.tableHeader}
                        component="th"
                      >
                        <Typography variant="h2">
                          {" "}
                          {t("Res.VehicleLocation")}
                        </Typography>
                      </TableCell>
                      <TableCell
                        className={classes.tableBody}
                        id="carClubVehicleLocationDiv"
                      >
                        <Typography variant="body1">
                          {" "}
                          {resData?.vehicleLocation}{" "}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        width="40%"
                        className={classes.tableHeader}
                        component="th"
                      >
                        <Typography variant="h2">
                          {" "}
                          {t("Res.VehicleDetails")}
                        </Typography>
                      </TableCell>
                      <TableCell
                        className={classes.tableBody}
                        id="carClubVehicleDetailsDiv"
                      >
                        <Typography variant="body1">
                          {resData?.vehicleDetails}{" "}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              {!resData?.isPastReservation &&
                companyInfo?.isDriverValidityActivated && (
                  <>
                    <Grid container item md={8}>
                      <TableContainer>
                        <Table>
                          <TableBody>
                            <TableRow>
                              <TableCell
                                id="HireCostLabel"
                                width="40%"
                                className={classes.tableHeader}
                                component="th"
                              >
                                <label htmlFor={"EndDateFieldLabel"}>
                                  <Typography variant="h2">
                                    {" "}
                                    {t(
                                      "ReservationWizard.carClubPage.lblHireCost"
                                    )}
                                  </Typography>
                                </label>
                              </TableCell>
                              <TableCell
                                id="HireCostValue"
                                width="60%"
                                className={classes.tableBodyCurrency}
                              >
                                <Typography id={"HireCost"} variant="body1">
                                  {getLocaleCurrency(
                                    resData?.reservationEstimate?.timeAmount.toString(),
                                    locale,
                                    getcompanyCountryCurrency(
                                      companyCountryCode
                                    )
                                  )}
                                </Typography>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                id="BbcInsuranceLabel"
                                width="40%"
                                className={classes.tableHeader}
                                component="th"
                              >
                                <label htmlFor={"EndDateFieldLabel"}>
                                  <Typography variant="h2">
                                    {t(
                                      "ReservationWizard.carClubPage.lblBBCInsurance"
                                    )}
                                  </Typography>
                                </label>
                              </TableCell>
                              <TableCell
                                id="BbcInsuranceValue"
                                width="60%"
                                className={classes.tableBodyCurrency}
                              >
                                <Typography id={"BBCInsurance"}>
                                  {getLocaleCurrency(
                                    resData?.reservationEstimate?.insurance.toString(),
                                    locale,
                                    getcompanyCountryCurrency(
                                      companyCountryCode
                                    )
                                  )}
                                </Typography>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                id="TransactionFeeLabel"
                                width="40%"
                                className={classes.tableHeader}
                                component="th"
                              >
                                <label htmlFor={"EndDateFieldLabel"}>
                                  <Typography variant="h2">
                                    {" "}
                                    {t(
                                      "ReservationWizard.carClubPage.lblTransactionFee"
                                    )}
                                  </Typography>
                                </label>
                              </TableCell>
                              <TableCell
                                id="TransactionFeeValue"
                                width="60%"
                                className={classes.tableBodyCurrency}
                              >
                                <Typography id={"TransactionFee"}>
                                  {getLocaleCurrency(
                                    resData?.reservationEstimate?.transactionFee.toString(),
                                    locale,
                                    getcompanyCountryCurrency(
                                      companyCountryCode
                                    )
                                  )}
                                </Typography>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                className={classes.tableBody}
                                colSpan={2}
                              >
                                <Divider className={classes.divider} />
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                id="TotalEstimateLabel"
                                width="40%"
                                className={classes.tableHeader}
                                component="th"
                              >
                                <label htmlFor={"EndDateFieldLabel"}>
                                  <Typography variant="h2">
                                    {" "}
                                    {t(
                                      "ReservationWizard.carClubPage.lblTotalEstimate"
                                    )}
                                  </Typography>
                                </label>
                              </TableCell>
                              <TableCell
                                id="TotalEstimateValue"
                                width="60%"
                                className={classes.tableBodyCurrency}
                              >
                                <Typography id={"TotalEstimated"}>
                                  {getLocaleCurrency(
                                    resData?.reservationEstimate?.ccEstimate.toString(),
                                    locale,
                                    getcompanyCountryCurrency(
                                      companyCountryCode
                                    )
                                  )}
                                </Typography>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                className={classes.tableBodyCurrency}
                                colSpan={2}
                              >
                                <p
                                  style={{
                                    fontSize: "0.9em",
                                    color: "#006639",
                                  }}
                                >
                                  {t("Res.FeeDisclaimer")}
                                </p>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  </>
                )}
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
export default ViewCC;
