import * as React from "react";
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from "react-i18next";
import { Backdrop, Box, CircularProgress, Dialog, IconButton, Link, Typography } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import "./home.css";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import EditIcon from "@mui/icons-material/Edit";
import CancelIcon from "@mui/icons-material/Cancel";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { JourneyType } from "views/reservation/ReservationViewType";
import AccessibleTooltip from "../../controls/AccessibleTooltip";
import CarClubLogin from "controls/CarClubLoginDialog";
import CarClubYesNo from "controls/CarClubYesNoDialog";
import YesNoDialog from "controls/YesNoDialog";
import { GetMyJourneys } from "../store/actions/home/myjourneyActions";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "../store/reducers/rootReducer";
import { TableCell } from "@mui/material";
import { TableRow } from "@mui/material";
import { Grid } from "@mui/material";
import { TableContainer } from "@mui/material";
import { Table } from "@mui/material";
import { Paper } from "@mui/material";
import { TableHead } from "@mui/material";
import { TableBody } from "@mui/material";
import { isMobile, isTablet } from "react-device-detect";
import { authService } from "services/authService";
import { getEnumKeyByEnumValue } from "helpers/enumMapping";
import { getLocaleAmount } from "helpers/localization";
import { appInsights } from "helpers/appInsights";
import axiosInstance from "helpers/axiosInstance";
import {
  clearAlertMessage,
  showErrorAlertMessage,
} from "components/store/actions/shared/alertActions";
import { broadcastAnnouncement } from "components/store/actions/shared/announcementActions";
import { resetReservation } from "components/store/actions/reservation/reservationActions";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import {
  ReAuthenticateCarClub,
  reAuthenticationStatus,
} from "services/carClubReAuth";
import { useEffect, useState } from "react";
import { getUserPermissionsStatus, Permissions } from "helpers/getUserPermissions";
import { resetBookingReferenceDetails } from "components/store/slicers/bookingReferenceSlice";
import { resetAccountLocationDetails } from "components/store/slicers/accountLocationSlice";
import { resetDriverDetails } from "components/store/slicers/driverDetailSlice";
import { isJourneyEndDateStillWithinRentalMgmtWindow } from "helpers/rentalManagement";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "##fff",
    color: theme.palette.common.black,
    borderBottom: "1px solid #000",
    borderRight: "1px solid #ddd",
    fontWeight: "bold",
    height: "50px",
    padding: "0px 15px",
  },
  body: {
    fontSize: 12,
    padding: "0px 15px",
  },
  footer: {
    textAlign: "right",
    justifyContent: "right",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {},
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 700,
    "& .MuiTableCell-root": {
      borderRight: "1px solid #ddd",
      color: "#000000",
      paddingBottom: "0px",
    },
  },
  pagination: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
    alignItems: "right",
    padding: "0px",
  },
  selectRoot: {
    height: 10,
    display: "table",
  },
  select: {
    height: 6,
    //paddingTop: 0,
    //  paddingBottom:0,
    display: "table-cell",
    verticalAlign: "middle",
  },
  formControl: {
    /*  margin: 1,*/
    minWidth: 60,
  },
  selectEmpty: {
    /*  marginTop: 2,*/
  },
  buttonSize: {
    textAlign: "center",
    padding: 3,
    margin: 0,
    alignItems: "center",
  },
  root: {},
  button: { textTransform: "none" },
  backdrop: {
    zIndex: 99999,
    color: "#fff",
  },
  preWrap: {
    whiteSpace: "pre-wrap",
    lineHeight: "20px",
  },
  preWrapCell: {
    whiteSpace: "pre-wrap",
    lineHeight: "18px",
  },
}));

const MyRecentJourney = (props) => {
  const myJourneys =
    useSelector((state: AppState) => state.myJourney.myJourneys?.data) || [];
  const { t } = useTranslation();
  const inMiles = authService.getisoCountryCode() === "GB" || authService.getisoCountryCode() === "US"? true : false;
  const locale = authService.getUserLocale();
  const ukDate = "DD/MM/YYYY HH:mm";
  const dateFormat = t("DateTimeFormat");
  const [open, setOpen] = React.useState(false);
  const [ccOpenYesNo, setOpenCcYesNo] = React.useState(false);
  const [isReauth, setisReauth] = React.useState(false);
  const [journeyUId, setjourneyUId] = React.useState("");
  const [reference, setreference] = React.useState("");
  const [isView, setisView] = React.useState(true);
  const [hasReservation, sethasReservation] = React.useState(false);
  const [productUId, setProductUId] = React.useState("");
  const [openYesNo, setopenYesNo] = React.useState(false);
  const [cancelJourneySuccessful, setcancelJourneySuccessful] =
    React.useState(false);
  const dispatch = useDispatch();
  const classes = useStyles();
  let history = useNavigate();
  const [Loading, setLoading] = React.useState(false);
  const userCarClubId = authService.getUserCarClubId();

  const [isCCLoggedIn, setIsCCLoggedIn] = useState(
    JSON.parse(localStorage.getItem("ccLoggedIn") ?? "false") ?? []
  );

  useEffect(() => {
    window.localStorage.setItem("ccLoggedIn", JSON.stringify(isCCLoggedIn));
  }, [isCCLoggedIn]);

  const { companyInfo } = useSelector(
    (state: AppState) => state.companyInfo || {}
  );
  const isTokenExpired = authService.getUserccReauthExpired();
  const isccPersisted = authService.getUserccPersisted();
  const hasUserViewOnlyPermission = getUserPermissionsStatus( authService.getUserPermissions(),Permissions.ViewOnly);

  React.useEffect(() => {
    dispatch(GetMyJourneys());
  }, [cancelJourneySuccessful]);

  const ViewReservation = async (
    reference: string,
    journeyUId: string,
    productUI: string
  ) => {
    setjourneyUId(journeyUId);
    setreference(reference);
    switch (productUI.toUpperCase().toString()) {
      case JourneyType.dailyRental:
        appInsights.trackEvent({
          name: "View DR Reservation",
          properties: {
            Reference: reference,
            JourneyUId: journeyUId,
            ProductUI: productUI,
          },
        });
        history(`/reservation/ViewDR/${journeyUId}/${reference}`);
        break;
      case JourneyType.mileageReimbursement:
        appInsights.trackEvent({
          name: "View MR Reservation",
          properties: {
            Reference: reference,
            JourneyUId: journeyUId,
            ProductUI: productUI,
          },
        });
        history(`/reservation/ViewMR/${journeyUId}/${reference}`);
        break;
      case JourneyType.carClub:
        appInsights.trackEvent({
          name: "View CC Reservation",
          properties: {
            Reference: reference,
            JourneyUId: journeyUId,
            ProductUI: productUI,
          },
        });
        setisView(true);
        setisReauth(false);
        if (!isCCLoggedIn) handleOpenCCLogin();
        else {
          setLoading(true);
          const reAuth = (await ReAuthenticateCarClub(
            userCarClubId
          )) as reAuthenticationStatus;
          setLoading(false);
          if (reAuth.reAuthenticated) {
            history({
              pathname: `/reservation/ViewCC/${journeyUId}/${reference}`,
            });
          } else {
            setisReauth(true);
            handleOpenCCLogin();
          }
        }
        break;
      case JourneyType.specialityVehicle:
        appInsights.trackEvent({
          name: "View SV Reservation",
          properties: {
            Reference: reference,
            JourneyUId: journeyUId,
            ProductUI: productUI,
          },
        });
        history(`/reservation/ViewSV/${journeyUId}/${reference}`);
        break;
      default:
        appInsights.trackEvent({
          name: "View JP Reservation",
          properties: {
            Reference: reference,
            JourneyUId: journeyUId,
            ProductUI: productUI,
          },
        });
        history(`/reservation/ViewJP/${journeyUId}/${reference}`);
        break;
    }
  };

  function handleCancelJourneySuccessful() {
    setcancelJourneySuccessful(!cancelJourneySuccessful);
  }

  const handleOpenCCLogin = () => {
    setOpen(true);
  };
  const handleCCClose = () => {
    setOpen(false);
  };

  const resetAllCache = () => {
    dispatch(clearAlertMessage());
    dispatch(resetReservation());
    dispatch(resetDriverDetails());
    dispatch(resetBookingReferenceDetails());
    dispatch(resetAccountLocationDetails());
  }
  const handleCCOpenYesNo = () => {
    setOpenCcYesNo(true);
  };

  const handleCCOpenYesNoClose = () => {
    setOpenCcYesNo(false);
  };

  const editResAfterValidate = async (row: any) => {
    if(hasUserViewOnlyPermission){
      dispatch(showErrorAlertMessage(t("ViewOnlyError")));
      dispatch(broadcastAnnouncement(t("ViewOnlyError")));
      window.scroll(0, 0);
    }else{
    resetAllCache();
    const dateStr = moment(row.startDateTime, ukDate).format(
      "YYYY-MM-DD[T]HH:mm:SS"
    );
    const endDateStr = moment(row.endDateTime, ukDate).format(
      "YYYY-MM-DD[T]HH:mm:SS"
    );
    if (dateStr && endDateStr) {
      await axiosInstance
        .get(
          `reservationwizard/validatestartandenddate?startdatetime=${dateStr.toString()}&enddatetime=${endDateStr.toString()}`
        )
        .then((response) => {
          console.log("dateinpast: ", response.data, companyInfo);
          if (response.data.success && !response.data.data.isStartDateInPast)
            //before reservation starts
            history(`/reservationwizard?mode=3&id=${row.journeyUId}&type=1`);
          else if (
            response.data.success &&
            response.data.data.isStartDateInPast &&
            companyInfo?.isRentalMgmtEnabled &&
            (
              (companyInfo?.isDriverValidityActivated && !response.data.data.isEndDateInPast)
              ||
              (!companyInfo?.isDriverValidityActivated && isJourneyEndDateStillWithinRentalMgmtWindow(endDateStr))
            )
          )
            //during reservation
            history(
              `/reservation/EditDR/${row.journeyUId}/${row.referenceNumber}`
            );
          else {
            //after reservation
            axiosInstance
              .get(
                `crossdomain/getbranchdetails?stationidorpeoplesoftid=${row.startLocationId.toString()}`
              )
              .then((response) => {
                const branch = response.data.data;

                console.log("Branch details: ", branch);
                dispatch(
                  showErrorAlertMessage(
                    t("resPastStartDateMessage", {
                      resNo: row.referenceNumber,
                      branchName: branch.locationName,
                      branchContact: branch.formattedPhoneNumber,
                    })
                  )
                );
                dispatch(
                  broadcastAnnouncement(
                    t("resPastStartDateMessage", {
                      resNo: row.referenceNumber,
                      branchName: branch.locationName,
                      branchContact: branch.formattedPhoneNumber,
                    })
                  )
                );
              });
            window.scroll(0, 0);
          }
        })
        .catch((error) => {
          console.error("There was an error in validating start date!", error);
        });
    }
  }
  };

  const EditReservation = (row: any) => {
    switch (row.productUId.toUpperCase().toString()) {
      case JourneyType.dailyRental:
        editResAfterValidate(row);
        break;
      default:
        break;
    }
    appInsights.trackEvent({
      name: "Edit Reservation",
    });
  };
  const RebookReservation = (journeyID: string, productUI: string) => {
    if(hasUserViewOnlyPermission){
      dispatch(showErrorAlertMessage(t("ViewOnlyError")));
      dispatch(broadcastAnnouncement(t("ViewOnlyError")));
      window.scroll(0, 0);
    }else{
      resetAllCache();
      appInsights.trackEvent({
        name: "Rebook Reservation",
        properties: { JourneyID: journeyID, ProductUI: productUI },
      });
      if (productUI.toUpperCase().toString() === JourneyType.dailyRental)
        history(`/reservationwizard?mode=3&id=${journeyID}&type=2`);
      else history("/home?id=" + journeyID);
    }
  };
  const CancelReservation = async (
    reference: string,
    journeyUId: string,
    productUI: string,
    hasReservation: boolean
  ) => {
    if(hasUserViewOnlyPermission){
      dispatch(showErrorAlertMessage(t("ViewOnlyError")));
      dispatch(broadcastAnnouncement(t("ViewOnlyError")));
      window.scroll(0, 0);
    }else{
      setjourneyUId(journeyUId);
      sethasReservation(hasReservation);
      setreference(reference);
      setProductUId(productUI);
      appInsights.trackEvent({
        name: "Cancel Reservation",
        properties: {
          Reference: reference,
          JourneyUId: journeyUId,
          ProductUI: productUI,
          HasReservation: hasReservation,
        },
      });
      if (productUI.toUpperCase().toString() === JourneyType.carClub) {
        setisView(false);
        setisReauth(false);
        if (!isCCLoggedIn) handleOpenCCLogin();
        else {
          setLoading(true);
          const reAuth = (await ReAuthenticateCarClub(
            userCarClubId
          )) as reAuthenticationStatus;
          setLoading(false);

          if (reAuth.reAuthenticated) {
            handleCCOpenYesNo();
          } else {
            setisReauth(true);
            handleOpenCCLogin();
          }
        }
      } else {
        handleOpenYesNoDialog();
      }
    }
  };

  function handleOpenYesNoDialog() {
    setopenYesNo(true);
  }

  const handleYesNoClose = () => {
    setopenYesNo(false);
  };
  const handleLoading = (isloading) => {
    setLoading(isloading);
  };

  appInsights.trackEvent({
    name: "myRecentJourney Component",
  });

  return (
    <Box
      data-testid="recentJourneysResultBox"
      id="recentJourneysResultBox"
      mt={-2}
      boxShadow={3}
      p={1}
      className={classes.root}
    >
      <Backdrop className={classes.backdrop} open={Loading}>
        <CircularProgress />
      </Backdrop>
      <Dialog
        aria-labelledby="cc-dialog-title"
        aria-describedby="cc-dialog-description"
        open={open}
        TransitionProps={{ role: "presentation" } as any}
      >
        <CarClubLogin
          id="carClubDialog"
          journeyUId={journeyUId}
          reference={reference}
          isView={isView}
          isReauth={isReauth}
          handleCancelJourneySuccessful={handleCancelJourneySuccessful}
          action={handleCCClose}
        />
      </Dialog>
      <Dialog
        aria-labelledby="cc-yesno-dialog-title"
        aria-describedby="cc-yesno-dialog-description"
        open={ccOpenYesNo}
        TransitionProps={{ role: "presentation", visibility: "hidden" } as any}
      >
        <CarClubYesNo
          id="carClubYesNoDialog"
          journeyUId={journeyUId}
          reference={reference}
          isReauth={isReauth}
          handleCancelJourneySuccessful={handleCancelJourneySuccessful}
          action={handleCCOpenYesNoClose}
          dialogType="cancel"
          handleLoading={handleLoading}
          confirmMessage={t("cancelCCResConfirmationMessage")}
        />
      </Dialog>

      <Dialog
        aria-labelledby="yesno-dialog-title"
        aria-describedby="yesno-dialog-description"
        open={openYesNo}
        TransitionProps={{ role: "presentation" } as any}
      >
        <YesNoDialog
          open={openYesNo}
          journeyUId={journeyUId}
          reference={reference}
          hasReservation={hasReservation}
          handleCancelJourneySuccessful={handleCancelJourneySuccessful}
          action={handleYesNoClose}
          handleLoading={handleLoading}
          productUId={productUId}
        />
      </Dialog>

      <Typography variant="h2">{t("RecentJourneysTitle")}</Typography>
      <Box
        id="recentJourneyData"
        style={{ display: "flex", paddingTop: 10, width:"100%" }}
      >
        <Grid container>
          <Grid
            container
            item
            xs={12}
            md={12}
            style={{ display: "flex", width:"100%" }}
          >
            <TableContainer component={Paper} >
              <Table
                className={classes.table}
                aria-label="my recent logged journeys"
                id="myRecentJourneyTable"
              
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell width="160">
                      <div className={classes.preWrap}>
                        {" "}
                        <p style={{ fontWeight: "bold" }}>
                          {t("rentalType.rentalType")}
                        </p>{" "}
                      </div>
                    </StyledTableCell>
                    <StyledTableCell width="120">
                      <div className={classes.preWrap}>
                        {" "}
                        <p style={{ fontWeight: "bold" }}>
                          {t("StartEndDate")}
                        </p>{" "}
                      </div>
                    </StyledTableCell>
                    {isMobile || isTablet ? null : (
                      <StyledTableCell width="140">
                        <div className={classes.preWrap}>
                          {" "}
                          <p style={{ fontWeight: "bold" }}>
                            {t("StartEndLocation")}
                          </p>{" "}
                        </div>
                      </StyledTableCell>
                    )}
                    {isMobile || isTablet ? null : (
                      <StyledTableCell width="70">
                        <div className={classes.preWrap}>
                          {" "}
                          <p style={{ fontWeight: "bold" }}>
                            {t("MidPoints")}
                          </p>{" "}
                        </div>
                      </StyledTableCell>
                    )}
                    {isMobile || isTablet ? null : (
                      <StyledTableCell width="90">
                        <div
                          style={{ fontWeight: "bold" }}
                          className={classes.preWrap}
                        >
                          {t("Distance")} {inMiles ? t("InMiles") : t("InKMs")}
                        </div>
                      </StyledTableCell>
                    )}
                    <StyledTableCell width="135">
                      <div className="preWrap">
                        <p style={{ color: "#000000", fontWeight: "bold" }}>
                          {t("Actions")}
                        </p>
                      </div>
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {myJourneys.length === 0 ? (
                    <StyledTableRow tabIndex={0}>
                      <TableCell
                        colSpan={9}
                        style={{
                          textAlign: "center",
                          justifyContent: "center",
                          marginTop: "2em",
                        }}
                      >
                        {t("EmptyTable")}
                      </TableCell>
                    </StyledTableRow>
                  ) : (
                    myJourneys.map((row: any, index: number) => (
                      <StyledTableRow key={index} className="odd">
                        <StyledTableCell width="160">
                          <Typography
                            className={classes.preWrapCell}
                            variant={"body2"}
                          >
                            {t(
                              "rentalType." +
                                getEnumKeyByEnumValue(
                                  JourneyType,
                                  row.productUId ?? ""
                                )
                            ) || ""}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell width="110">
                          <Typography variant={"body2"}>
                            {moment(row.startDateTime, ukDate).format(
                              dateFormat
                            ) || ""}
                          </Typography>
                          <Typography variant={"body2"}>
                            {moment(row.endDateTime, ukDate).format(
                              dateFormat
                            ) || ""}
                          </Typography>
                        </StyledTableCell>
                        {isMobile || isTablet ? null : (
                          <StyledTableCell width="140">
                            <div
                              className={classes.preWrapCell}
                              style={{
                                width: "140px",
                              }}
                            >
                              <Typography variant={"body2"}>
                                {row.startLocation || ""}
                              </Typography>
                              <Typography variant={"body2"}>
                                {row.endLocation || ""}
                              </Typography>
                            </div>
                          </StyledTableCell>
                        )}
                        {isMobile || isTablet ? null : (
                          <StyledTableCell>
                            <div
                              className={classes.preWrapCell}
                              style={{
                                width: "70px",
                              }}
                            >
                              <Typography variant={"body2"}>
                                {row.midPoints || ""}
                              </Typography>
                            </div>
                          </StyledTableCell>
                        )}
                        {isMobile || isTablet ? null : (
                          <StyledTableCell width="90">
                            <Typography
                              className={classes.preWrapCell}
                              variant="body2"
                            >
                              {getLocaleAmount(row.distanceTravelled, locale) ||
                                ""}
                            </Typography>
                          </StyledTableCell>
                        )}
                        <StyledTableCell width="135">
                          <Box
                            id={
                              "ActionButtons_" +
                              (row.referenceNumber ?? "").replace("COUNT", "")
                            }
                            flexDirection="row"
                            alignItems="center"
                            display="flex"
                          >
                            <AccessibleTooltip
                              text={
                                row.canView
                                  ? t("ViewFormat", {
                                      Reference: (
                                        row.referenceNumber ?? ""
                                      ).replace("COUNT", ""),
                                    }).toString()
                                  : t("ViewDisabledFormat", {
                                      Reference: (
                                        row.referenceNumber ?? ""
                                      ).replace("COUNT", ""),
                                    }).toString()
                              }
                            >
                              <span
                                role="note"
                                style={{
                                  marginTop: 11,
                                  marginBottom: 11,
                                  display: "inline-grid",
                                }}
                              >
                                <div>
                                  <IconButton
                                    id={
                                      "viewJourneyButton" +
                                      (row.referenceNumber ?? "").replace(
                                        "COUNT",
                                        ""
                                      )
                                    }
                                    //role="img"
                                    color="primary"
                                    size="small"
                                    disabled={!row.canView}
                                    aria-label={
                                      row.canView
                                        ? t("ViewFormat", {
                                            Reference: (
                                              row.referenceNumber ?? ""
                                            ).replace("COUNT", ""),
                                          })
                                        : t("ViewDisabledFormat", {
                                            Reference: (
                                              row.referenceNumber ?? ""
                                            ).replace("COUNT", ""),
                                          })
                                    }
                                    onClick={() =>
                                      ViewReservation(
                                        row.referenceNumber || "",
                                        row.journeyUId || "",
                                        row.productUId
                                      )
                                    }
                                    style={
                                      row.canView
                                        ? {
                                            marginRight: "2px",
                                            border: " 1px solid #547D66",
                                            textAlign: "left",
                                            color: "#2F77C6",
                                          }
                                        : {
                                            marginRight: "2px",
                                            textAlign: "left",
                                            color: "#7e817e",
                                            border: " 1px solid #737774",
                                          }
                                    }
                                  >
                                    <ArrowForwardIcon />
                                  </IconButton>
                                </div>
                              </span>
                            </AccessibleTooltip>
                            <AccessibleTooltip
                              text={
                                row.canEdit
                                  ? t("EditJourney", {
                                      Reference: (
                                        row.referenceNumber ?? ""
                                      ).replace("COUNT", ""),
                                    }).toString()
                                  : t("EditDisabled").toString()
                              }
                            >
                              <span
                                role="note"
                                style={{
                                  marginTop: 11,
                                  marginBottom: 11,
                                  display: "inline-grid",
                                }}
                              >
                                <div>
                                  <IconButton
                                    id={
                                      "editJourneyButton" +
                                      (row.referenceNumber ?? "").replace(
                                        "COUNT",
                                        ""
                                      )
                                    }
                                    //role="img"
                                    color="primary"
                                    size="small"
                                    disabled={!row.canEdit}
                                    aria-label={t("EditJourney", {
                                      Reference: (
                                        row.referenceNumber ?? ""
                                      ).replace("COUNT", ""),
                                    }).toString()}
                                    onClick={() => EditReservation(row)}
                                    style={
                                      row.canEdit
                                        ? {
                                            marginRight: "2px",
                                            border: " 1px solid #547D66",
                                            textAlign: "left",
                                            color: "#006639",
                                          }
                                        : { display: "none" }
                                    }
                                  >
                                    <EditIcon />
                                  </IconButton>
                                </div>
                              </span>
                            </AccessibleTooltip>
                            <AccessibleTooltip
                              text={
                                row.canCopy
                                  ? t("BookAgainFormat", {
                                      Reference: (
                                        row.referenceNumber ?? ""
                                      ).replace("COUNT", ""),
                                    }).toString()
                                  : t("BookAgainDisabledFormat", {
                                      Reference: (
                                        row.referenceNumber ?? ""
                                      ).replace("COUNT", ""),
                                    }).toString()
                              }
                            >
                              <span
                                role="note"
                                style={{
                                  marginTop: 11,
                                  marginBottom: 11,
                                  display: "inline-grid",
                                }}
                              >
                                <div>
                                  <IconButton
                                    id={
                                      "repeatJourneyButton" +
                                      (row.referenceNumber ?? "").replace(
                                        "COUNT",
                                        ""
                                      )
                                    }
                                    //role="img"
                                    color="primary"
                                    size="small"
                                    disabled={!row.canCopy}
                                    aria-label={
                                      row.canCopy
                                        ? t("BookAgainFormat", {
                                            Reference: (
                                              row.referenceNumber ?? ""
                                            ).replace("COUNT", ""),
                                          })
                                        : t("BookAgainDisabledFormat", {
                                            Reference: (
                                              row.referenceNumber ?? ""
                                            ).replace("COUNT", ""),
                                          })
                                    }
                                    onClick={() =>
                                      RebookReservation(
                                        row.journeyUId || "",
                                        row.productUId
                                      )
                                    }
                                    style={
                                      row.canCopy
                                        ? {
                                            marginRight: "2px",
                                            border: "1px solid #547D66",
                                            textAlign: "left",
                                            color: "#000000",
                                          }
                                        : {
                                            marginRight: "2px",
                                            border: "1px solid #737774",
                                            textAlign: "left",
                                            color: "#7e817e",
                                          }
                                    }
                                  >
                                    <RefreshIcon />
                                  </IconButton>
                                </div>
                              </span>
                            </AccessibleTooltip>
                            <AccessibleTooltip
                              text={
                                row.canDelete
                                  ? t("CancelFormat", {
                                      Reference: (
                                        row.referenceNumber ?? ""
                                      ).replace("COUNT", ""),
                                    }).toString()
                                  : t("CancelDisabledFormat", {
                                      Reference: (
                                        row.referenceNumber ?? ""
                                      ).replace("COUNT", ""),
                                    }).toString()
                              }
                            >
                              <span
                                role="note"
                                style={{
                                  marginTop: 11,
                                  marginBottom: 11,
                                  display: "inline-grid",
                                }}
                              >
                                <div>
                                  <IconButton
                                    id={
                                      "cancelJourneyButton" +
                                      (row.referenceNumber ?? "").replace(
                                        "COUNT",
                                        ""
                                      )
                                    }
                                    //role="img"
                                    color="primary"
                                    size="small"
                                    disabled={!row.canDelete}
                                    aria-label={t("CancelFormat", {
                                      Reference: (
                                        row.referenceNumber ?? ""
                                      ).replace("COUNT", ""),
                                    })}
                                    onClick={async () =>
                                      await CancelReservation(
                                        row.referenceNumber || "",
                                        row.journeyUId || "",
                                        row.productUId,
                                        row.hasReservation
                                      )
                                    }
                                    style={
                                      row.canDelete
                                        ? {
                                            border: "1px solid #547D66",
                                            textAlign: "left",
                                            color: "#d02216",
                                          }
                                        : { display: "none" }
                                    }
                                  >
                                    <CancelIcon />
                                  </IconButton>
                                </div>
                              </span>
                            </AccessibleTooltip>
                          </Box>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Box>

      <Typography variant="body2" id="myRecentJourneyTable_info">
        {myJourneys.length > 0
          ? t("showing1ToMOfMEntries", {
              sum: myJourneys.length,
              max: myJourneys.length < 5 ? myJourneys.length : 5,
            })
          : t("showing0Entries")}
      </Typography>

      <Box id="seeAllJourneysLink" display="flex" flexDirection="row-reverse">
        <Link
          style={{ alignSelf: "right", fontSize: "1.1em" }}
          href="#/Journey"
          variant="body2"
          color="primary"
          underline="always"
        >
         <Typography variant="body1" sx={{textDecoration:"underline"}}> {t("SeeAllJourneysLabel")}</Typography> 
        </Link>
      </Box>
    </Box>
  );
};

export default MyRecentJourney;
