import { createSlice } from "@reduxjs/toolkit";

interface SkipLinkProperties {
    linkAttribute: string;
}

const initialState : SkipLinkProperties =  {
    linkAttribute: ''
}

const skipLinkSlice = createSlice({
    name: "skipLink",
    initialState,
    reducers: {
        setLoginButtonSkipLink : (state) => {
            state.linkAttribute = "#btnLogin"
        },
        setHomeSkipLink: (state) => {
            state.linkAttribute = "#startLocation"
        },
        setAllJourneysSkipLink: (state) => {
            state.linkAttribute = "#titleHeader"
        },
        setCorporateInformationSkipLink: (state) => {
            state.linkAttribute = "#corporateInformation"
        },
        setCarClubMemberIdSkipLink: (state) => {
            state.linkAttribute = "#carClubDriverIdTextBox"
        },
        setMyProfileTitleSkilLink: (state) => {
            state.linkAttribute = "#userTitle"
        }
    },
})

export const {setLoginButtonSkipLink, setHomeSkipLink, setAllJourneysSkipLink, setCorporateInformationSkipLink, setCarClubMemberIdSkipLink, setMyProfileTitleSkilLink} = skipLinkSlice.actions

export default skipLinkSlice.reducer;