import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "components/store/reducers/rootReducer";
import { ReservationProps } from "../../reservationType";
import BusyIndicator from "controls/busyIndicator/busyIndicator";
import { appInsights } from "helpers/appInsights";
import { authService } from "services/authService";
import { SeverityLevel } from "@microsoft/applicationinsights-common";
import { translateOTA } from "helpers/otaTranslateHelper";
import { isHiddenAdditionalParameters } from "helpers/additionalParameters";
import { 
  showInfoAlertMessage,
  clearAlertMessage,
} from "components/store/actions/shared/alertActions";

import {
  Backdrop,
  Typography,
  Button,
  Box,
  CircularProgress,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { 
  JourneyMethodType1,
  ReservationAdditionalInformationFieldTypeCode,
  ReservationStatusType,
} from "./../../../../views/reservation/ReservationViewType";
import { getEnumKeyByEnumValue } from "helpers/enumMapping";
import { getLocaleDate, getLocaleCurrency } from "helpers/localization";
import { ReservationAdditionalInformationFieldViewModel } from "../Reservation/reservationFormType";

import axiosInstance from "helpers/axiosInstance";
import { useTranslation } from "react-i18next";
import HTMLEllipsis from "react-lines-ellipsis/lib/html";
import { broadcastAnnouncement } from "components/store/actions/shared/announcementActions";
import { updateReservation } from "components/store/actions/reservation/reservationActions";

import { decodeEmailIfEncoded, maskEmail } from "helpers/emailHelpers";
import PaymentResult from "../Payment/paymentResult";


const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
    paddingRight: 30,
    paddingTop: 15,
    paddingBottom: 15,
  },
  title: {
    fontSize: "1.4em",
    paddingLeft: 30,
    paddingTop: 10,
  },
  subtitle: {
    fontSize: "1.2em",
    marginTop: 10,
    marginBottom: 10,
    fontWeight: "normal",
    wordBreak: "break-word",
  },
  tableHeader0: {
    fontWeight: "bold",
    padding: 4,
    borderBottom: "none",
    fontSize: "1.1em",
    overflow: "hidden",
    textOverflow: "ellipsis",
    wordWrap: "break-word",
    //wordBreak: "break-all",
    width: "30%",
  },
  tableBody0: {
    fontSize: "1.1em",
    padding: 4,
    borderBottom: "none",
    overflow: "hidden",
    textOverflow: "ellipsis",
    wordBreak: "break-all",
    wordWrap: "break-word",
    width: "70%",
  },
  tableHeader: {
    fontWeight: "bold",
    padding: 4,
    borderBottom: "none",
    fontSize: "1.1em",
    whiteSpace: "nowrap",
  },
  tableBody: {
    fontSize: "1.1em",
    padding: 4,
    borderBottom: "none",
    wordBreak: "break-word",
  },
  detail: {
    padding: 0,
    whiteSpace: "normal",
    wordWrap: "break-word",
  },
  rootcell: {
    "& .MuiTableCell-root": {
      verticalAlign: "top",
      borderBottom: "none",
      padding: "10px",
    },
  },
  divider: {
    backgroundColor: "#000",
    height: "0.8px",
    marginRight: 30,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  gridItem: {
    verticalAlign: "center",
    padding: "10px",
    "& .MuiTypography-body2": {
      fontWeight: "bold",
    },
  },
  heading: {
    fontSize: 16,
    fontWeight: "bold",
  },
  labelText: {
    color: "#000",
    fontFamily: "Arial, Roboto, Segoe UI",
    fontSize: 14,
    fontWeight: "bold",
    verticalAlign: "middle",
  },
  errorWarning: {
    color: "#DF0000",
    fontSize: 12,
    marginLeft: "15px",
  },
  disclaimer: {
    fontWeight: "bold",
    padding: 4,
    borderBottom: "none",
    fontSize: "1.1em",
  },
}));
const ReviewReservationForm: React.FC<ReservationProps> = (props) => {
  const [open, setOpen] = React.useState(false);

  const classes = useStyles();
  const locale = authService.getUserLocale();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);
  
  const { reservation } = useSelector((state: AppState) => state.dailyRental);

  const { companyInfo } = useSelector(
    (state: AppState) => state.companyInfo || {}
  );

  const titleRef = React.useRef<HTMLElement>(null);

  useEffect(() => {
    console.log("Passed Reservation from Redux: ", reservation);
    dispatch(clearAlertMessage());
    if (
      reservation &&
      reservation?.warnings &&
      reservation?.warnings.length > 0
    ) {
      dispatch(showInfoAlertMessage(reservation?.warnings));
      dispatch(broadcastAnnouncement(reservation?.warnings));
    }
    window.scrollTo(0, 0);
  });

  const handleToggle = async () => {
    setLoading(true);
    dispatch(clearAlertMessage());
    console.log("Submitting the DR Reservation for SAVE: ", reservation);
    await axiosInstance
      .post("/reservationwizard/reservation/savedr", reservation)
      .then((res) => {
        if (res.data.success) {
          let response = res.data.data;
          console.log("submitted.", response);
          response.driverEmail = decodeEmailIfEncoded(response.driverEmail);
          dispatch(
            updateReservation({
              reservation: response,
              hasError: false,
              errors: [],
            })
          );
          setLoading(false);
          if (props.handleNext) props.handleNext(0, 0);
        } else {
          setLoading(false);
          console.log("Errors: ", res.data.errors);          
          dispatch(
            updateReservation({
              reservation: reservation,
              hasError: true,
              errors: res.data.errors,
            })
          );
          if (props.handleBack) 
            {
              if(reservation?.isCreditCard)
               {
                console.log("REverting Card Res")
                props.handleBack(0, 0, false,true);
               }
              else
              props.handleBack(0,0);

            }
        }
      })
      .catch((err) => {
        console.log("Errors: ", err);
        appInsights.trackException({
          exception: err,
          properties: {
            method: "savedr",
            Component: "DR Review",
          },
          severityLevel: SeverityLevel.Error,
        });
        setLoading(false);
      });
  };
  function isNullOrWhitespace(input) {
    return !input || !input.trim();
  }

  function handleBack() {
    if (props.handleBack) props.handleBack(0, 0);
  }

  function getBookingReference(
    input: ReservationAdditionalInformationFieldViewModel
  ) {
    var singleReference;
    switch (input.fieldTypeCode) {
      case ReservationAdditionalInformationFieldTypeCode.Date:
        singleReference =
          input.fieldName +
          " = " +
          (input.fieldValueDateString === ""
            ? ""
            : new Date(
                parseInt(input.fieldValueDateString.substr(0, 4)),
                parseInt(input.fieldValueDateString.substr(4, 2)) - 1,
                parseInt(input.fieldValueDateString.substr(6, 2))
              ).toLocaleString(
                locale.toString() === "en-GB" ? "en-GB" : "de-DE",
                {
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                }
              ));
        break;
      default:
        singleReference =
          input.fieldName + " = " + (input.fieldValue ? input.fieldValue : "");
    }
    return singleReference;
  }

  const selectedCar = reservation?.carClass?.carsInformation.classInfo.find(
    (x) => x.modelCode === reservation?.carClass?.carClassCode
  );

  console.log("Selected Car fees", selectedCar);
  const selectedBrandInfo = selectedCar?.brandInfo.find(
    (x) => x.brand === reservation?.carClass?.brand
  );

  const startLocationMethodType =
    Number(reservation?.locations?.startLocation.selectedMethodTypeId) === 0
      ? (reservation?.locations?.startLocation.methodTypes[0]).text
      : t(
          "Res." +
            JourneyMethodType1[
              Number(reservation?.locations?.startLocation.selectedMethodTypeId)
            ]
        );

  const endLocationMethodType =
    Number(reservation?.locations?.endLocation.selectedMethodTypeId) === 0
      ? (reservation?.locations?.startLocation.methodTypes[0]).text
      : t(
          "Res." +
            JourneyMethodType1[
              Number(reservation?.locations?.endLocation.selectedMethodTypeId)
            ]
        );

  const startLocation =
    reservation?.locations?.startLocation.selectedMethodTypeId === "1" ||
    reservation?.locations?.startLocation.selectedMethodTypeId === "3"
      ? reservation?.startServicingBranch
          ?.split("&lt;br/&gt;")
          ?.map((p, index) => {
            return (
              <Typography key={index}>
                {p} <br />{" "}
              </Typography>
            );
          })
      : reservation?.startLocationString
          ?.replace("&#39;", "'")
          ?.split("&lt;br/&gt;")
          ?.map((p, index) => {
            return (
              <Typography key={index}>
                {p} <br />{" "}
              </Typography>
            );
          });

  const endLocation =
    reservation?.locations?.endLocation.selectedMethodTypeId === "1" ||
    reservation?.locations?.endLocation.selectedMethodTypeId === "3"
      ? reservation?.endServicingBranch
          ?.split("&lt;br/&gt;")
          ?.map((p, index) => {
            return (
              <Typography key={index}>
                {p} <br />{" "}
              </Typography>
            );
          })
      : reservation?.endLocationString
          ?.replace("&#39;", "'")
          ?.split("&lt;br/&gt;")
          ?.map((p, index) => {
            return (
              <Typography key={index}>
                {p} <br />{" "}
              </Typography>
            );
          });
  const isExtrasSelected = () => {
    return (
      (selectedBrandInfo?.includedExtras != null &&
        selectedBrandInfo?.includedExtras.length > 0) ||
      (selectedBrandInfo?.selectedExtras != null &&
        selectedBrandInfo?.selectedExtras.length > 0)
    );
  };
  const displayWarnings = () => {
    if (reservation && reservation?.warnings) {
      dispatch(showInfoAlertMessage(reservation?.warnings));
      dispatch(broadcastAnnouncement(reservation?.warnings));
    }
    window.scrollTo(0, 0);
  };
  
  return (
    <>
      {!isNullOrWhitespace(reservation?.notificationMessage) ? (
        <Box boxShadow={0} m={3}>
          <HTMLEllipsis
            id="marketingMessage"
            unsafeHTML={reservation?.notificationMessage}
            maxLine="5"
            ellipsis="..."
            basedOn="letters"
          />
          {""}
        </Box>
      ) : (
        ""
      )}      
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress />
      </Backdrop>
      <Grid className={classes.rootcell} container spacing={3}>
        <Grid id="titleHeader" item xs={12} md={12} className={classes.root}>
          <Typography
            ref={titleRef}
            className={classes.title}
            variant="h1"
            onClick={() => {
              console.log(
                companyInfo?.isDriverValidityActivated,
                reservation?.isInternationalStartLocation
              );
            }}
          >
            {t("Res.confirmYourBooking")}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} className={classes.root}>
          <Box style={{ paddingLeft: 30 }}>
            <Typography
              className={classes.subtitle}
              style={{ marginTop: 0 }}
              variant="h2"
            >
              {t("Res.DriverDetails")}
            </Typography>
            <Divider
              className={classes.divider}
              style={{ marginTop: 10, marginLeft: 0 }}
            />
            <TableContainer id="detailsTable">
              <Table aria-label="details table" style={{ width: "95%" }}>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" className={classes.tableHeader0}>
                      <Typography variant="h2">{t("DriverName")}</Typography>
                    </TableCell>
                    <TableCell id="DriverName" className={classes.tableBody0}>
                      <Typography variant="body1">
                        {reservation?.driverName}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" className={classes.tableHeader0}>
                      <Typography variant="h2">
                        {t("Res.DriverEmail")}
                      </Typography>
                    </TableCell>
                    <TableCell id="DriverEmail" className={classes.tableBody0}>
                      <Typography variant="body1">
                        {reservation?.driverDeclaration
                          ? maskEmail(reservation?.driverEmail)
                          : reservation?.driverEmail}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  {reservation?.driverDeclaration &&
                    reservation?.additionalDriver &&
                    reservation?.additionalDriver.length > 0 && (
                      <TableRow>
                        <TableCell
                          component="th"
                          className={classes.tableHeader0}
                        >
                          <Typography variant="h2">
                            {t("AdditionalDriver")}
                          </Typography>
                        </TableCell>
                        <TableCell
                          id="AdditionalDrivers"
                          className={classes.tableBody0}
                        >
                          <Typography variant="body1">
                            {reservation?.additionalDriver?.map(
                              (additionalDriver, index) => {
                                return (
                                  <Typography key={index}>
                                    {" "}
                                    {additionalDriver.firstName +
                                      " " +
                                      additionalDriver.lastName}
                                    <br />{" "}
                                  </Typography>
                                );
                              }
                            )}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  {(reservation?.loyalty?.loyaltyId ||
                    reservation?.journeyProduct?.enableLoyalty) && (
                    <TableRow>
                      <TableCell
                        component="th"
                        className={classes.tableHeader0}
                      >
                        <Typography variant="h2">
                          {t(
                            "ReservationWizard.reservationPage.labelLoyaltyId"
                          )}
                        </Typography>
                      </TableCell>
                      <TableCell id="LoyaltyId" className={classes.tableBody0}>
                        <Typography variant="body1">
                          {reservation?.loyalty?.loyaltyId}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                  <TableRow>
                    <TableCell component="th" className={classes.tableHeader0}>
                      <Typography variant="h2">
                        {t("Res.DisplayName")}
                      </Typography>
                    </TableCell>
                    <TableCell id="DisplayName" className={classes.tableBody0}>
                      <Typography variant="body1">
                        {reservation?.displayName ?? reservation?.accountName}
                      </Typography>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell component="th" className={classes.tableHeader0}>
                      <Typography variant="h2">
                        {t("Res.LastModifiedBy")}
                      </Typography>
                    </TableCell>
                    <TableCell
                      id="LastModifiedBy"
                      className={classes.tableBody0}
                    >
                      <Typography variant="body1">
                        {reservation?.bookerName}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" className={classes.tableHeader0}>
                      <Typography variant="h2">
                        {t("Res.BookingReferences")}
                      </Typography>
                    </TableCell>
                    {reservation?.additionalInformation &&
                    reservation?.additionalInformation
                      ?.additionalInformationFields &&
                    reservation?.additionalInformation
                      ?.additionalInformationFields.length > 0 ? (
                      <TableCell
                        id="BookingReferences"
                        data-testid="BookingReferences"
                        className={classes.tableBody0}
                      >
                        <Typography variant="body1">
                          {reservation?.additionalInformation?.additionalInformationFields.map(
                            (additionalInformation, index) => {
                               
                              return (
                                (!isHiddenAdditionalParameters(additionalInformation.fieldName) &&<Typography key={index}>
                                  {" "}
                                  {getBookingReference(
                                    additionalInformation
                                  )}{" "}
                                  <br />{" "}
                                </Typography>)
                              );
                            }
                          )}
                        </Typography>
                      </TableCell>
                    ) : (
                      <TableCell className={classes.tableBody0}></TableCell>
                    )}
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <Typography className={classes.subtitle} variant="h2">
              {t("Res.BranchDetails")}
            </Typography>
            <Divider className={classes.divider} />
            <TableContainer id="branchDetailsTable">
              <Table aria-label="Branch table" style={{ width: "95%" }}>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" className={classes.tableHeader0}>
                      <Typography variant="h2">
                        {t("Res.BranchAddress")}
                      </Typography>
                    </TableCell>
                    <TableCell
                      id="BranchAddress"
                      className={classes.tableBody0}
                    >
                      <Typography variant="body1">
                        {reservation?.branchAddress}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" className={classes.tableHeader0}>
                      <Typography variant="h2">
                        {t("Res.BranchTelephone")}
                      </Typography>
                    </TableCell>
                    <TableCell
                      id="BranchTelephone"
                      className={classes.tableBody0}
                    >
                      <Typography variant="body1">
                        {reservation?.branchTelephone}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" className={classes.tableHeader0}>
                      <Typography variant="h2">
                        {t("Res.VehicleClass")}
                      </Typography>
                    </TableCell>
                    <TableCell id="VehicleClass" className={classes.tableBody0}>
                      <Typography variant="body1">
                        {" "}
                        {reservation?.carClass?.carClassCode +
                          "-" +
                          reservation?.carClass?.carClass?.replace(
                            "OR SIMILAR",
                            t("Res.OTA.OrSimilar").toUpperCase()
                          )}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" className={classes.tableHeader0}>
                      <Typography variant="h2">{t("Res.Brand")}</Typography>
                    </TableCell>
                    <TableCell
                      id="FullBrandName"
                      className={classes.tableBody0}
                    >
                      <Typography variant="body1">
                        {reservation?.fullBrandName}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" className={classes.tableHeader0}>
                      <Typography variant="h2">{t("Res.Notes")}</Typography>
                    </TableCell>
                    <TableCell id="Notes" className={classes.tableBody0}>
                      <Typography variant="body1">
                        {reservation?.notes}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  {reservation?.locations?.startLocation?.isAirportLocation && (
                    <TableRow data-testid="flightNumberRow">
                      <TableCell component="th" className={classes.tableHeader}>
                        <Typography variant="h2">
                          {t("Res.flightNumber")}
                        </Typography>
                      </TableCell>
                      <TableCell
                        id="FlightNumber"
                        className={classes.tableBody0}
                      >
                        <Typography variant="body1">
                          {reservation?.flightNumberViewModel?.flightNumber}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <Typography className={classes.subtitle} variant="h2">
              {t("Res.JourneyDetails")}
            </Typography>
            <Divider className={classes.divider} />
            <TableContainer
              id="reservationdetailsTable"
              className={classes.detail}
            >
              <Table style={{ width: "95%" }}>
                <TableBody>
                  {reservation?.showReservationDetails && (
                    <>
                      <TableRow>
                        <TableCell
                          width="33.33%"
                          component="th"
                          className={classes.tableHeader0}
                        >
                          <Typography variant="h2">
                            {t("Res.ReservationNumber")}
                          </Typography>
                        </TableCell>
                        <TableCell
                          id="ReservationNumber"
                          className={classes.tableBody0}
                        >
                          <Typography variant="body1">
                            {reservation?.reservationNumberString}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          component="th"
                          className={classes.tableHeader0}
                        >
                          <Typography variant="h2">
                            {t("Res.ReservationStatus")}
                          </Typography>
                        </TableCell>
                        <TableCell
                          id="ReservationStatus"
                          className={classes.tableBody}
                        >
                          <Typography variant="body1">
                            {" "}
                            {t(
                              "Res." +
                                getEnumKeyByEnumValue(
                                  ReservationStatusType,
                                  reservation.reservationStatusUId
                                )
                            )}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                  <TableRow>
                    <TableCell component="th" className={classes.tableHeader0}>
                      <Typography variant="h2">{t("StartDateTime")}</Typography>
                    </TableCell>
                    <TableCell
                      id="StartDateTime"
                      className={classes.tableBody0}
                    >
                      <Typography variant="body1">
                        {reservation?.startDateTimeString !== undefined
                          ? getLocaleDate(
                              reservation.startDateTimeString,
                              locale
                            )
                          : ""}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" className={classes.tableHeader0}>
                      <Typography variant="h2">{t("EndDateTime")}</Typography>
                    </TableCell>
                    <TableCell id="EndDateTime" className={classes.tableBody0}>
                      <Typography variant="body1">
                        {reservation?.endDateTimeString !== undefined
                          ? getLocaleDate(reservation.endDateTimeString, locale)
                          : ""}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" className={classes.tableHeader0}>
                      <Typography variant="h2">{t("StartLocation")}</Typography>
                    </TableCell>
                    <TableCell
                      id="StartLocation"
                      className={classes.tableBody0}
                    >
                      <Typography variant="body1">{startLocation}</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" className={classes.tableHeader0}>
                      <Typography variant="h2">
                        {t("Res.StartMethod")}
                      </Typography>
                    </TableCell>
                    <TableCell
                      id="StartMethod"
                      className={classes.tableBody0}
                      style={{ paddingTop: 8, paddingBottom: 8 }}
                    >
                      <Typography variant="body1">
                        {startLocationMethodType}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  {!isNullOrWhitespace(reservation?.startServicingBranch) && (
                    <TableRow>
                      <TableCell
                        className={classes.tableHeader0}
                        component="th"
                      >
                        <Typography variant="h2">
                          {t("Res.ServicingBranch")}
                        </Typography>
                      </TableCell>
                      <TableCell
                        id="StartServicingBranch"
                        className={classes.tableBody0}
                      >
                        <Typography variant="body1">
                          {" "}
                          {reservation?.startServicingBranch}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                  <TableRow>
                    <TableCell component="th" className={classes.tableHeader0}>
                      <Typography variant="h2">{t("EndLocation")}</Typography>
                    </TableCell>
                    <TableCell id="EndLocation" className={classes.tableBody0}>
                      <Typography variant="body1">{endLocation}</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" className={classes.tableHeader0}>
                      <Typography variant="h2">{t("Res.EndMethod")}</Typography>
                    </TableCell>
                    <TableCell
                      id="EndMethod"
                      className={classes.tableBody0}
                      style={{ paddingTop: 8, paddingBottom: 8 }}
                    >
                      <Typography variant="body1">
                        {endLocationMethodType}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  {!isNullOrWhitespace(reservation?.endServicingBranch) && (
                    <TableRow>
                      <TableCell
                        component="th"
                        className={classes.tableHeader0}
                      >
                        <Typography variant="h2">
                          {t("Res.ServicingBranch")}
                        </Typography>
                      </TableCell>
                      <TableCell
                        id="EndServicingBranch"
                        className={classes.tableBody0}
                      >
                        <Typography variant="body1">
                          {" "}
                          {reservation?.endServicingBranch}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>
        {!reservation?.isStartDateInPast && (
          <Grid item xs={12} md={6}>
            <Box id="DRCostDetails" style={{ paddingLeft: 30 }}>
              <Typography
                className={classes.subtitle}
                style={{ marginTop: 0 }}
                variant="h2"
              >
                {t("Res.EstimatedCostExtrasDetails")}
              </Typography>
              <Divider className={classes.divider} />
              <Box className={classes.detail} style={{ paddingTop: 5 }}>
                <Typography
                  variant="h3"
                  className={classes.tableHeader}
                  style={{ marginBottom: 10 }}
                >
                  {t("Res.VehicleClass")}
                </Typography>
                <Divider className={classes.divider} />
                <TableContainer>
                  <Table>
                    <TableBody>
                      {selectedCar?.vehicleClass != null && (
                        <TableRow>
                          <TableCell className={classes.tableBody} width="50%">
                            {" "}
                            {translateOTA(
                              selectedCar?.vehicleClass || "",
                              t
                            )}{" "}
                          </TableCell>
                          <TableCell className={classes.tableBody} width="25%">
                            {/* {selectedBrandInfo?.pricePerBaseWithCurrency} */}
                            <Typography variant="body1">
                              {getLocaleCurrency(
                                selectedBrandInfo?.pricePerBaseWithCurrency,
                                locale,
                                selectedBrandInfo?.currency
                              )}
                              /{" "}
                              {translateOTA(
                                selectedBrandInfo?.unitName || "",
                                t
                              )}{" "}
                            </Typography>
                          </TableCell>
                          <TableCell
                            id="baseRatePriceWithCurrency"
                            className={classes.tableBody}
                            width="25%"
                          >
                            {" "}
                            <Typography variant="body1">
                              {getLocaleCurrency(
                                selectedBrandInfo?.baseRatePriceWithCurrency,
                                locale,
                                selectedBrandInfo?.currency
                              )}{" "}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )}
                      {selectedBrandInfo?.additionalRentalCharge &&
                        selectedBrandInfo?.additionalRentalCharge
                          .filter((x) => x.rate > 0)
                          .map((p, index) => {
                            return ( p.description.toUpperCase().toString() === "MILEAGE CHARGE" ?
                            "" 
                            :
                              <TableRow key={index}>
                                <TableCell
                                  className={classes.tableBody}
                                  width="50%"
                                >
                                  <Typography variant="body1">
                                    {p.description.toLowerCase().toString() ===
                                    "drop charge"
                                      ? translateOTA(p.description, t)
                                      : ""}
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  className={classes.tableBody}
                                  width="25%"
                                >
                                  <Typography variant="body1">
                                    {p.description.toLowerCase().toString() ===
                                    "drop charge" //// there is data issue on p.pricePerUnitWithCurrency, please see comments on 39663
                                      ? ""
                                      : getLocaleCurrency(
                                          p.rateWithCurrency,
                                          locale,
                                          p.currency
                                        ) +
                                        "/ " +
                                        translateOTA(p.unitName || "", t)}{" "}
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  id={p.description.replace(/ /g, "")}
                                  className={classes.tableBody}
                                  width="25%"
                                >
                                  {p.rateWithCurrency}
                                  {/* {getLocaleCurrency(
                                    p.rateWithCurrency,
                                    locale,
                                    p.currency
                                  )} */}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                    </TableBody>
                  </Table>
                </TableContainer>

                {isExtrasSelected() && (
                  <>
                    <Typography
                      variant="h2"
                      style={{ marginTop: 10, marginBottom: 10 }}
                      className={classes.tableHeader}
                    >
                      {t("Res.Extras")}
                    </Typography>
                    <Divider className={classes.divider} />
                    <TableContainer>
                      <Table>
                        <TableBody>
                          {selectedBrandInfo?.includedExtras?.map(
                            (p, index) => {
                              return (
                                <TableRow key={index}>
                                  <TableCell
                                    className={classes.tableBody}
                                    width="50%"
                                  >
                                    <Typography variant="body1">
                                      {translateOTA(p.description, t)}
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    className={classes.tableBody}
                                    width="25%"
                                  ></TableCell>
                                  <TableCell
                                    className={classes.tableBody}
                                    width="25%"
                                  >
                                    <Typography variant="body1">
                                      {" "}
                                      {getLocaleCurrency(
                                        p.totalPriceWithCurrency,
                                        locale,
                                        p.currency
                                      )}
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                              );
                            }
                          )}
                          {selectedBrandInfo?.selectedExtras?.map(
                            (p, index) => {
                              return (
                                <TableRow key={index}>
                                  <TableCell
                                    width="50%"
                                    className={classes.tableBody}
                                  >
                                    <Typography variant="body1">
                                      {translateOTA(p.description, t) + " "}{" "}
                                      {p.isCountable ? "x" + p.quantity : ""}
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    width="25%"
                                    className={classes.tableBody}
                                  >
                                    <Typography variant="body1">
                                      {p.isCountable
                                        ? getLocaleCurrency(
                                            p.unitPriceWithCurrency,
                                            locale,
                                            p.currency
                                          ) +
                                          "/" +
                                          t("Res.PerUnit")
                                        : ""}
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    width="25%"
                                    className={classes.tableBody}
                                  >
                                    <Typography variant="body1">
                                      {getLocaleCurrency(
                                        p.totalUnitPriceWithCurrency,
                                        locale,
                                        p.currency
                                      )}
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                              );
                            }
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </>
                )}

                {selectedCar?.fees != null && selectedCar.fees.length > 0 && (
                  <>
                    <Typography
                      variant="h2"
                      style={{ marginTop: 10, marginBottom: 10 }}
                      className={classes.tableHeader}
                    >
                      {t("Res.TaxesAndFees")}
                    </Typography>
                    <Divider className={classes.divider} />
                    <TableContainer>
                      <Table id="tableFees" style={{ width: "95%" }}>
                        <TableBody>
                          {selectedCar.fees.map((p, index) => {
                            return (
                              <TableRow key={index}>
                                <TableCell
                                  width="50%"
                                  className={classes.tableBody}
                                >
                                  <Typography 
                                    id={p.description.replace(/ /g, "")+"-DESCRIPTION"}
                                    variant="body1" 
                                    style={{whiteSpace: 'pre-line'}}
                                  >
                                  {p.description.toUpperCase() === "MILEAGE CHARGE" ?
                                    t("Res.OTA.MileageCharge", { quantity: selectedCar.distance.quantity, unit: selectedCar.distance.unit.toUpperCase() === "MILE" ? "MI" : "KM", baseAmount: p.baseAmount, currency: p.currency}) :
                                    translateOTA(p.description, t)
                                    }
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  width="25%"
                                  className={classes.tableBody}
                                ></TableCell>
                                <TableCell
                                  id={p.description.replace(/ /g, "")}
                                  width="25%"
                                  className={classes.tableBody}
                                >
                                  <Typography variant="body1">
                                    {p.includedInRate
                                      ? t("Res.Included")
                                      : p.totalAmount.toLocaleString(locale, {
                                          minimumFractionDigits: 2,
                                          useGrouping: false,
                                        }) +
                                        " " +
                                        p.currency}
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </>
                )}
                <p
                  className={classes.tableBody}
                  style={{ fontSize: "0.9em", color: "#006639" }}
                >
                  {t("Res.FeeDisclaimer")}
                </p>
                <Divider className={classes.divider} />
                <TableContainer>
                  <Table id="TotalEstimatedCost">
                    <TableBody>
                      <TableRow>
                        <TableCell width="50%" className={classes.tableBody}>
                          <Typography variant="h2">
                            {companyInfo?.isDriverValidityActivated
                              ? t("Res.BBCTotalEstimatedCost")
                              : t("Res.TotalEstimatedCost")}
                          </Typography>
                        </TableCell>
                        <TableCell width="20%" className={classes.tableBody} />
                        <TableCell
                          id="TotalEstimatedCostWithCurrency"
                          width="30%"
                          className={classes.tableBody}
                        >
                          <Typography variant="h2">
                            {
                              reservation?.carClass?.carsInformation
                                .totalEstimatedCostWithCurrency
                              // getLocaleCurrency(
                              //   reservation?.carClass?.carsInformation
                              //     .totalEstimatedCostWithCurrency,
                              //   locale
                              // )
                            }
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                {companyInfo?.isDriverValidityActivated &&
                  reservation?.isInternationalStartLocation && (
                    <>
                      <div id="BBCCustomNote">
                        <Typography
                          variant="h2"
                          className={classes.tableBody}
                          style={{
                            marginTop: 10,
                            marginBottom: 10,
                            fontWeight: "bold",
                          }}
                        >
                          <div id="BBCCustomNoteLabel">
                            {t("Res.BBCNoteLabel")}
                          </div>
                          <div id="BBCCustomNoteDecription">
                            {t("Res.BBCNote")}
                          </div>
                        </Typography>
                        <Typography
                          id="BBCTransactionNote"
                          variant="h2"
                          className={classes.tableBody}
                          style={{
                            marginTop: 10,
                            marginBottom: 10,
                            fontWeight: "bold",
                          }}
                        >
                          {t("Res.BBCTransactionNote")}
                        </Typography>
                      </div>
                    </>
                  )}
              
            
              <div style={{marginTop:10, marginLeft:10, marginBottom:10}}>
                  {reservation?.isCreditCardToggleEnabled && reservation?.isCreditCard && reservation.payment ?  (
                    <div>
                        <PaymentResult cardNumber={reservation.payment.cardNumber} cardExpiry={reservation.payment.cardExpiry} 
                        cardBrand={reservation.payment.cardBrand} />
                    <Typography id="reviewPaymentDisclaimer"  variant="body2"  style={{ marginTop:20, fontSize: "0.9em", color: "#006639" }}>
                      {t("Res.PaymentDisclaimer")}
                    </Typography>
                    </div>
                  ) : 
                  <div> 
                    <Typography
                  variant="h2"
                  style={{ marginTop: 10, marginBottom: 10 }}
                  className={classes.tableHeader}
                >
                  {t("Res.MethodOfPayment")}
                </Typography>
                <Divider className={classes.divider} />
                <div id="BillToAccountLabel">
                  {reservation?.reasonForHire.isPayAtCounter ? (
                    <Typography variant="body1" className={classes.tableBody}>
                      {t("Res.PointOfRental")}
                    </Typography>
                  ) : (
                    <Typography variant="body1" className={classes.tableBody}>
                      {t("Res.billToAccount")}
                    </Typography>
                  )}
                </div>
                </div>
                }
                </div>
              </Box>
            </Box>
          </Grid>
        )}
      </Grid>
      <Grid
        id="DailyRentalDisclaimerText"
        item
        xs={12}
        md={12}
        style={{ margin: 20 }}
      >
        <Typography className={classes.disclaimer}>
          {" "}
          {t("Res.DailyRentalDisclaimerText")}{" "}
        </Typography>
      </Grid>
      {/* </Box> */}
      <BusyIndicator isloading={open} text={"Please Wait"} />
      <Grid item xs={12}>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "1em",
            marginRight: "0.7em",
          }}
        >
          <Button
            variant="contained"
            id="EdtAction"
            style={{
              marginRight: "1em",
            }}
            onClick={handleBack}
          >
            {t("Res.editReservation")}
          </Button>
          <Button
            variant="contained"
            color="primary"
            id="nextReservationButton"
            onClick={handleToggle}
          >
            {t("Res.confirmBooking")}
          </Button>
        </div>
      </Grid>
    </>
  );
};

export default ReviewReservationForm;
