import { 
  isWithin28Days,
  isCreditCardExpirationDateValid
} from "helpers/creditCard";
import {
  Backdrop,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import PrintIcon from "@mui/icons-material/Print";
import axiosInstance from "helpers/axiosInstance";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  JourneyMethodType1,
  ReservationAdditionalInformationFieldTypeCode,
  ReservationStatusType,
  ReservationViewModel,
} from "./ReservationViewType";
import { useNavigate, useParams } from "react-router-dom";
import "components/home/home.css";
import { clearAlertMessage } from "components/store/actions/shared/alertActions";
import { useDispatch } from "_testHelpers/react-redux-hooks";
import { useSelector } from "react-redux";
import { ArrowBack } from "@mui/icons-material";
import { authService } from "services/authService";
import { getEnumKeyByEnumValue } from "helpers/enumMapping";
import {
  getDateObjectFromShortString,
  getLocaleDate,
} from "helpers/localization";
import { ReservationAdditionalInformationFieldViewModel } from "components/admin/companies/companiesType";
import { appInsights } from "helpers/appInsights";
import { SeverityLevel } from "@microsoft/applicationinsights-common";
import { AppState } from "components/store/reducers/rootReducer";
import EtdDatePickerV2 from "controls/formik/EtdDatePickerV2";
import EtdTimePicker from "../../controls/formik/EtdTimePicker";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import { broadcastAnnouncement } from "../../components/store/actions/shared/announcementActions";
import { getIntlDateFormatForLocale } from "../../helpers/localization";
import {
  showErrorAlertMessage,
  showSuccessAlertMessage,
} from "../../components/store/actions/shared/alertActions";
import {
    daysInPastCalculator,
    isJourneyStartDateToday,
    isJourneyOver
} from "../../helpers/rentalManagement"
import { isHiddenAdditionalParameters } from "helpers/additionalParameters";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
    paddingRight: 30,
    paddingTop: 15,
    paddingBottom: 15,
  },
  title: {
    fontSize: "1.4em",
    paddingLeft: 30,
    paddingTop: 10,
  },
  subtitle: {
    fontSize: "1.2em",
    marginTop: 10,
    marginBottom: 10,
    fontWeight: "normal",
  },
  tableHeader: {
    fontWeight: "bold",
    padding: 4,
    borderBottom: "none",
    fontSize: "1.1em",
  },
  tableBody: {
    fontSize: "1.1em",
    padding: 4,
    borderBottom: "none",
    wordBreak: "break-word",
  },
  detail: {
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 15,
  },
  rootcell: {
    "& .MuiTableCell-root": {
      verticalAlign: "top",
    },
  },
  divider: {
    backgroundColor: "#000",
    height: "0.8px",
    marginRight: 30,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
  errorWarning: {
    color: "#DF0000",
    fontSize: "0.75rem",
    marginLeft: "15px",
  },
}));

const EditDR = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const locale = authService.getUserLocale();
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const [resData, setresData] = React.useState<ReservationViewModel>();
  const { companyInfo } = useSelector(
    (state: AppState) => state.companyInfo || {}
  );
  const { journeyID, reference } = useParams<{
    journeyID: string;
    reference: string;
  }>();
  const titleRef = React.useRef<HTMLElement>(null);
  const printRef = React.useRef<HTMLButtonElement>(null);
  const [creditCardExpirationDate, setCreditCardExpirationDate] = React.useState('2000-08-31T00:00:00');
  const [endDate, setEndDate] = React.useState("");
  const [endTime, setEndTime] = React.useState("");
  const [submitting, setFormSubmitting] = React.useState(false);

  const [offHire, setOffHire] = React.useState(false);
  if (!props?.disableTrackPageView) {
    appInsights.trackPageView();
  }
  
  React.useEffect(() => {
    document.title = t("ViewResTitle");
    dispatch(clearAlertMessage());
    setLoading(true);
    GetReservations();
    if (printRef.current != null && !props.disableAutoFocus)
      printRef.current.focus();
  }, []);

  React.useEffect(() => {
    if (resData) {
      const { isCreditCardToggleEnabled, isCreditCard } = resData;
  
      if (isCreditCardToggleEnabled && isCreditCard) {
        getCreditCardInformation();
      }
    }
  }, [resData]);

  const GetReservations = async () => {
    try {
      const response = await axiosInstance.get(
        `reservation/details/${journeyID}/${reference}`
      );
      setresData({
        ...response.data,
        newEndDate: response.data.reservationNewEndDateTime
          ? moment(response.data.reservationNewEndDateTime).format("YYYY-MM-DD")
          : moment(response.data.dateTime.endDateTime).format("YYYY-MM-DD"),
        newEndTime: response.data.reservationNewEndDateTime
          ? moment(response.data.reservationNewEndDateTime).format("HH:mm:SS")
          : moment(response.data.dateTime.endDateTime).format("HH:mm:SS"),
      });
      if (response.data?.offHire) {
        setOffHire(response.data.offHire);
      }
      console.log("response.data: ", response.data);
    } catch (err) {
      console.log("error occured : ", err);
      appInsights.trackException({
        exception: new Error("An exception has occured " + err),
        id: "EditDR",
        severityLevel: SeverityLevel.Error,
      });
    }
    finally{
      setLoading(false);
    }
  };
  const getCreditCardInformation = async () => {
    await axiosInstance
      .get(`/paymentstore/details/${resData?.reservationNumberString}`)
      .then((result) => {
        console.log("result fetched")
        console.log("Credit Card Information: ", result.data);
        let cardExpiryDate = result.data?.cardExpiryDate;
        setCreditCardExpirationDate(cardExpiryDate);
        setLoading(false);
      })
      .catch((err) => {
        console.log("Error in getting usercount");
        setLoading(false);
      });
  };
  function ValidCreditCardPaymentConditionsForEdit(reservation){
    let valid = true;
    let cardPaymentErrors: string[] = [];
    const endDateTimeString = reservation?.reservationNewEndDateTime;
    const startDateTimeString = reservation?.startDateTimeString;
    const isWith28Days = isWithin28Days(startDateTimeString, endDateTimeString);
    const isAValidCreditCardExpirationDate = isCreditCardExpirationDateValid(endDateTimeString, creditCardExpirationDate);
    if (!isWith28Days)
    {
      cardPaymentErrors.push(t("Res.rentalExceeds28Days"));
      valid = false;
    }
    if (!isAValidCreditCardExpirationDate)
    {
      cardPaymentErrors.push(t("Res.rentalEndDateExceedsCreditCardExpiryDate"));
      valid = false;
    }
    if (!valid)
    {
      dispatch(showErrorAlertMessage(cardPaymentErrors));
      window.scrollTo(0, 0);
      setFormSubmitting(false);
      setLoading(false);
    }
    return valid;
  }
  function isNullOrWhitespace(input) {
    return !input || !input.trim();
  }

  function getBookingReference(
    input: ReservationAdditionalInformationFieldViewModel
  ) {
    var singleReference;
    switch (input.fieldTypeCode) {
      case ReservationAdditionalInformationFieldTypeCode.Date:
        singleReference =
          input.fieldName +
          " = " +
          (input.fieldValueDateString === ""
            ? ""
            : new Date(
                parseInt(input.fieldValueDateString.substr(0, 4)),
                parseInt(input.fieldValueDateString.substr(4, 2)) - 1,
                parseInt(input.fieldValueDateString.substr(6, 2))
              ).toLocaleString(locale.toString() === "en" ? "en-GB" : "de-DE", {
                year: "numeric",
                month: "numeric",
                day: "numeric",
              }));
        break;
      default:
        singleReference =
          input.fieldName + " = " + (input.fieldValue ? input.fieldValue : "");
    }
    return singleReference;
  }

  const localeDateTimeFormat = getIntlDateFormatForLocale(locale);
  const formikRef = React.useRef<any>(null);

  let history = useNavigate();
  const selectedCar = resData?.carClass.carsInformation.classInfo.find(
    (x) => (x.modelCode = resData?.carClass.carClassCode)
  );
  const selectedBrandInfo = selectedCar?.brandInfo.find(
    (x) => x.brand === resData?.carClass.brand
  );
  console.log("Selected Car fees", selectedCar);

  function validateDateTime(dt, tm) {
    if (resData && tm && tm.length > 1) {
      let endDateTime = moment(dt + "T" + tm);
      if (
        endDateTime < moment(resData.earliestPossibleEndDate) ||
        endDateTime > moment(resData.latestPossibleEndDate)
      )
        return false;
    }
    return true;
  }

  const validateSchema = Yup.object().shape({
    newEndDate: Yup.string()
      .nullable()
      .required("journeyInput.msgInvalidEndDate")
      .test(
        "end date with time ",
        "journeyInput.msgInvalidEndDate",
        function (value) {
          return validateDateTime(
            this.parent.newEndDate,
            this.parent.newEndTime
          );
        }
      ),
    newEndTime: Yup.string()
      .nullable()
      .required("journeyInput.msgInvalidEndDate")
      .test("endTime-required", "Required", function (value) {
        return value !== null && value !== undefined && value.length > 1;
      }),
  });

  return (
    <>
      <Box boxShadow={3} m={3}>
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress />
        </Backdrop>
        <Grid
          data-testid="editDR"
          className={classes.rootcell}
          container
          spacing={3}
        >
          <Grid id="titleHeader" item xs={12} md={8} className={classes.root}>
            <Typography ref={titleRef} className={classes.title} variant="h1">
              {t("Res.ReservationDetails")}
            </Typography>
            <Divider
              className={classes.divider}
              style={{ marginTop: 10, marginLeft: 30 }}
            />
          </Grid>
          <Grid item xs={12} md={4} id="actionButtons">
            <Box display="flex" style={{ float: "right" }}>
              <IconButton
                id="printJourneyButton"
                size="medium"
                onClick={() => {
                  appInsights.trackEvent({
                    name: "View DR Print",
                    properties: { Reference: reference },
                  });
                  dispatch(clearAlertMessage());
                  window.print();
                }}
                ref={printRef}
                title={t("Res.PrintTitle")}
                aria-label={t("Res.Print")}
                //role="img"
                style={{
                  marginRight: 5,
                  border: "1px solid #547D66",
                  color: "#000000",
                }}
              >
                <PrintIcon />
                {t("Print")}
              </IconButton>

              <IconButton
                id="backButton"
                size="medium"
                onClick={() => history(-1)}
                title={t("BackTitle")}
                aria-label={t("Back")}
                //role="img"
                style={{
                  marginRight: 15,
                  border: "1px solid #547D66",
                  color: "#000000",
                }}
              >
                <ArrowBack />
                {t("Back")}
              </IconButton>
            </Box>
          </Grid>
          <Grid id="printtitleHeader" style={{ display: "none" }} item xs={12}>
            <Typography align="center" className={classes.title} variant="h1">
              {t("Res.ThankYouForYourReservation")}
            </Typography>
            <div style={{ paddingLeft: 30 }}>
              <Typography
                align="center"
                variant="h2"
                style={{
                  paddingBottom: 10,
                  color: "#d02216",
                  fontSize: "1.2em",
                  fontWeight: "normal",
                }}
              >
                {t("Res.PleaseSeeConfirmationDetails")}
              </Typography>
              <Divider className={classes.divider} />{" "}
            </div>
          </Grid>
          <Grid item xs={12} md={6} className={classes.root}>
            <Box style={{ paddingLeft: 30 }}>
              <Typography
                className={classes.subtitle}
                style={{ marginTop: 0 }}
                variant="h2"
              >
                {t("Res.DriverDetails")}
              </Typography>
              <Divider className={classes.divider} />
              <TableContainer id="detailsTable" className={classes.detail}>
                <Table aria-label="details table">
                  <TableBody>
                    <TableRow>
                      <TableCell
                        component="th"
                        width="33.33%"
                        className={classes.tableHeader}
                      >
                        {t("DriverName")}
                      </TableCell>
                      <TableCell id="DriverName" className={classes.tableBody}>
                        {resData?.driverName}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" className={classes.tableHeader}>
                        {t("Res.DriverEmail")}
                      </TableCell>
                      <TableCell id="DriverEmail" className={classes.tableBody}>
                        {resData?.driverEmail}
                      </TableCell>
                    </TableRow>
                    {/* {resData?.driverDeclaration && resData?.additionalDriver?length>0 && {} */}
                    {resData?.driverDeclaration &&
                      resData?.additionalDriver &&
                      resData?.additionalDriver.length > 0 && (
                        <TableRow>
                          <TableCell
                            component="th"
                            className={classes.tableHeader}
                          >
                            {t("AdditionalDriver")}
                          </TableCell>
                          <TableCell
                            id="AdditionalDrivers"
                            className={classes.tableBody}
                          >
                            {resData?.additionalDriver?.map(
                              (additionalDriver, index) => {
                                return (
                                  <Typography key={index}>
                                    {" "}
                                    {additionalDriver.firstName +
                                      " " +
                                      additionalDriver.lastName}
                                    <br />{" "}
                                  </Typography>
                                );
                              }
                            )}
                          </TableCell>
                        </TableRow>
                      )}
                    <TableRow>
                      <TableCell component="th" className={classes.tableHeader}>
                        {t("Res.DisplayName")}
                      </TableCell>
                      <TableCell id="DisplayName" className={classes.tableBody}>
                        {resData?.displayName}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell component="th" className={classes.tableHeader}>
                        {t("Res.LastModifiedBy")}
                      </TableCell>
                      <TableCell
                        id="LastModifiedBy"
                        className={classes.tableBody}
                      >
                        {resData?.bookerName}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" className={classes.tableHeader}>
                        {t("Res.BookingReferences")}
                      </TableCell>
                      {resData?.additionalInformation === null ||
                      resData?.additionalInformation.additionalInformationFields
                        .length === 0 ? (
                        <TableCell
                          id="BookingReferences"
                          className={classes.tableBody}
                        ></TableCell>
                      ) : (
                        <TableCell
                          id="BookingReferences"
                          data-testid="BookingReferences"
                          className={classes.tableBody}
                        >
                          {resData?.additionalInformation.additionalInformationFields?.map(
                            (additionalInformation, index) => {
                              return (
                                (!isHiddenAdditionalParameters(additionalInformation.fieldName) &&
                                <Typography data-testid="bookingReferenceData" key={index}>
                                  {" "}
                                  {getBookingReference(
                                    additionalInformation
                                  )}{" "}
                                  <br />{" "}
                                </Typography>)
                              );
                            }
                          )}
                        </TableCell>
                      )}
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <Typography className={classes.subtitle} variant="h2">
                {t("Res.BranchDetails")}
              </Typography>
              <Divider className={classes.divider} />
              <TableContainer
                id="branchDetailsTable"
                className={classes.detail}
              >
                <Table aria-label="Branch table">
                  <TableBody>
                    <TableRow>
                      <TableCell
                        width="33.33%"
                        component="th"
                        className={classes.tableHeader}
                      >
                        {t("Res.BranchAddress")}
                      </TableCell>
                      <TableCell
                        id="BranchAddress"
                        width="66.67%"
                        className={classes.tableBody}
                      >
                        {resData?.branchAddress}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" className={classes.tableHeader}>
                        {t("Res.BranchTelephone")}
                      </TableCell>
                      <TableCell
                        id="BranchTelephone"
                        className={classes.tableBody}
                      >
                        {resData?.branchTelephone}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" className={classes.tableHeader}>
                        {t("Res.VehicleClass")}
                      </TableCell>
                      <TableCell
                        id="VehicleClass"
                        className={classes.tableBody}
                      >
                        {resData?.vehicleClass}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" className={classes.tableHeader}>
                        {t("Res.Brand")}
                      </TableCell>
                      <TableCell
                        id="FullBrandName"
                        className={classes.tableBody}
                      >
                        {resData?.fullBrandName}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" className={classes.tableHeader}>
                        {t("Res.Notes")}
                      </TableCell>
                      <TableCell id="Notes" className={classes.tableBody}>
                        {resData?.notes}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <Typography className={classes.subtitle} variant="h2">
                {t("Res.JourneyDetails")}
              </Typography>
              <Divider className={classes.divider} />
              <TableContainer
                id="reservationdetailsTable"
                className={classes.detail}
              >
                <Table>
                  <TableBody>
                    {resData?.showReservationDetails && (
                      <>
                        <TableRow>
                          <TableCell
                            width="33.33%"
                            component="th"
                            className={classes.tableHeader}
                          >
                            {t("Res.ReservationNumber")}
                          </TableCell>
                          <TableCell
                            id="ReservationNumber"
                            className={classes.tableBody}
                          >
                            {resData?.reservationNumberString}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            component="th"
                            className={classes.tableHeader}
                          >
                            {t("Res.ReservationStatus")}
                          </TableCell>
                          <TableCell
                            id="ReservationStatus"
                            className={classes.tableBody}
                          >
                            {t(
                              "Res." +
                                getEnumKeyByEnumValue(
                                  ReservationStatusType,
                                  resData.reservationStatusUId
                                )
                            )}
                          </TableCell>
                        </TableRow>
                      </>
                    )}
                    <TableRow>
                      <TableCell component="th" className={classes.tableHeader}>
                        {t("StartDateTime")}
                      </TableCell>
                      <TableCell
                        id="StartDateTime"
                        className={classes.tableBody}
                      >
                        {resData?.startDateTimeString !== undefined
                          ? getLocaleDate(resData.startDateTimeString, locale)
                          : ""}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" className={classes.tableHeader}>
                        {t("EndDateTime")}
                      </TableCell>
                      <TableCell id="EndDateTime" className={classes.tableBody}>
                        {resData?.endDateTimeString !== undefined
                          ? getLocaleDate(resData.endDateTimeString, locale)
                          : ""}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" className={classes.tableHeader}>
                        {t("StartLocation")}
                      </TableCell>
                      <TableCell
                        id="StartLocation"
                        className={classes.tableBody}
                      >
                        {resData?.startLocationString
                          ?.replace("&#39;", "'")
                          .split("&lt;br/&gt;")
                          ?.map((p, index) => {
                            return (
                              <Typography key={index}>
                                {p} <br />{" "}
                              </Typography>
                            );
                          })}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" className={classes.tableHeader}>
                        {t("Res.StartMethod")}
                      </TableCell>
                      <TableCell
                        id="StartMethod"
                        className={classes.tableBody}
                        style={{ paddingTop: 8, paddingBottom: 8 }}
                      >
                        {t(
                          "Res." +
                            JourneyMethodType1[
                              Number(
                                resData?.locations?.startLocation
                                  .selectedMethodTypeId
                              )
                            ]
                        )}
                      </TableCell>
                    </TableRow>
                    {!isNullOrWhitespace(resData?.startServicingBranch) && (
                      <TableRow>
                        <TableCell
                          component="th"
                          className={classes.tableHeader}
                        >
                          {t("Res.ServicingBranch")}
                        </TableCell>
                        <TableCell
                          id="StartServicingBranch"
                          className={classes.tableBody}
                        >
                          {resData?.startServicingBranch}
                        </TableCell>
                      </TableRow>
                    )}
                    <TableRow>
                      <TableCell component="th" className={classes.tableHeader}>
                        {t("EndLocation")}
                      </TableCell>
                      <TableCell id="EndLocation" className={classes.tableBody}>
                        {resData?.endLocationString
                          ?.replace("&#39;", "'")
                          .split("&lt;br/&gt;")
                          ?.map((p, index) => {
                            return (
                              <Typography key={index}>
                                {p} <br />{" "}
                              </Typography>
                            );
                          })}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" className={classes.tableHeader}>
                        {t("Res.EndMethod")}
                      </TableCell>
                      <TableCell
                        id="EndMethod"
                        className={classes.tableBody}
                        style={{ paddingTop: 8, paddingBottom: 8 }}
                      >
                        {t(
                          "Res." +
                            JourneyMethodType1[
                              Number(
                                resData?.locations?.endLocation
                                  .selectedMethodTypeId
                              )
                            ]
                        )}
                      </TableCell>
                    </TableRow>
                    {!isNullOrWhitespace(resData?.startServicingBranch) && (
                      <TableRow>
                        <TableCell
                          component="th"
                          className={classes.tableHeader}
                        >
                          {t("Res.ServicingBranch")}
                        </TableCell>
                        <TableCell
                          id="EndServicingBranch"
                          className={classes.tableBody}
                        >
                          {resData?.endServicingBranch}
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            {resData?.earliestPossibleEndDate && (
              <Box id="DRMgmtDetails" style={{ paddingLeft: 30 }}>
                <Typography
                  className={classes.subtitle}
                  style={{ marginTop: 0 }}
                  variant="h2"
                >
                  {t("Res.RentalManagementHeader")}
                </Typography>
                <Divider className={classes.divider} />
                <TableContainer
                  id="rentalManagementTable"
                  className={classes.detail}
                >
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell
                          component="th"
                          className={classes.tableHeader}
                        >
                          {t("Res.EarliestPossibleEndDate")}
                        </TableCell>
                        <TableCell
                          id="earliestPossibleEndDate"
                          className={classes.tableBody}
                        >
                          {resData?.earliestPossibleEndDate !== undefined
                            ? getLocaleDate(
                                resData.earliestPossibleEndDateString,
                                locale
                              )
                            : ""}
                        </TableCell>
                      </TableRow>
                      {resData?.latestPossibleEndDate && (
                        <TableRow>
                          <TableCell
                            component="th"
                            className={classes.tableHeader}
                          >
                            {t("Res.LatestPossibleEndDate")}
                          </TableCell>
                          <TableCell
                            id="latestPossibleEndDate"
                            className={classes.tableBody}
                          >
                            {resData?.latestPossibleEndDate !== undefined
                              ? getLocaleDate(
                                  resData.latestPossibleEndDateString,
                                  locale
                                )
                              : ""}
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Formik
                  enableReinitialize={true}
                  initialValues={resData}
                  validateOnChange={true}
                  validateOnBlur={false}
                  validationSchema={validateSchema}
                  // validate={validateForm}

                  innerRef={formikRef}
                  onSubmit={async (
                    values,
                    { setSubmitting, setErrors, setStatus }
                  ) => {
                    // setLoading(true);
                    setFormSubmitting(true);
                    if (resData) {
                      resData.offHire = offHire;
                      resData.reservationNewEndDateTime = moment(
                        values.newEndDate + "T" + values.newEndTime + ".000Z"
                      ).toDate();
                      console.log(resData.reservationNewEndDateTime);

                      let validToProceed = true
                      if (resData.isCreditCardToggleEnabled && resData.isCreditCard)
                      {
                        validToProceed = ValidCreditCardPaymentConditionsForEdit(resData);
                      }
                      if (validToProceed)
                      {
                        await axiosInstance
                        .post("reservation/UpdateReservation", resData)
                        .then((result) => {
                          if (result.data) {
                            console.log("Result data: ", result.data);
                            dispatch(clearAlertMessage());
                            dispatch(
                              showSuccessAlertMessage(
                                t("Res.RentalChangeRequestSuccessTitle")
                              )
                            );

                            dispatch(
                              broadcastAnnouncement(
                                t("Res.RentalChangeRequestSuccessTitle")
                              )
                            );
                          } else {
                            dispatch(
                              showErrorAlertMessage(
                                t("resPastStartDateMessage", {
                                  resNo: resData.reservationNumberString,
                                  branchName: resData.branchAddress,
                                  branchContact: resData.branchTelephone,
                                })
                              )
                            );
                            dispatch(
                              broadcastAnnouncement(
                                t("resPastStartDateMessage", {
                                  resNo: resData.reservationNumberString,
                                  branchName: resData.branchAddress,
                                  branchContact: resData.branchTelephone,
                                })
                              )
                            );
                          }
                          window.scrollTo(0, 0);
                          setFormSubmitting(false);
                          setLoading(false);
                        })
                        .catch((error) => {
                          setFormSubmitting(false);
                          setLoading(false);
                          appInsights.trackException({
                            id: "RentalManagement",
                            exception: error,
                            severityLevel: SeverityLevel.Error,
                          });
                          if (error.response) {
                            appInsights.trackException({
                              id: "RentalManagement",
                              exception: error.response,
                              severityLevel: SeverityLevel.Error,
                            });
                          } else if (error.request) {
                            console.log("error.request", error.request);
                          } else if (error.message) {
                            console.log("error.message", error.message);
                          }

                          window.scrollTo(0, 0);
                        });
                      }
                    }
                  }}
                >
                  {({
                    values,
                    isValid,
                    dirty,
                    touched,
                    setFieldValue,
                    setFieldTouched,
                    setFieldError,
                    handleChange,
                    errors,
                    isSubmitting,
                    validateForm,
                  }) => (
                    <Form>
                      <Backdrop
                        className={classes.backdrop}
                        open={submitting || loading}
                      >
                        <CircularProgress />{" "}
                      </Backdrop>
                      <TableContainer
                        id="rentalManagementEditTable"
                        className={classes.detail}
                      >
                        <Table>
                          <TableBody>
                            <TableRow>
                              <TableCell
                                component="th"
                                className={classes.tableHeader}
                              >
                                {/* <Typography
                          onClick={()=>{console.log("values", values, "validateSchema",validateSchema, "errors", errors,"touched",touched,"isValid",isValid,"Dirty",dirty);}}>
                            
                          </Typography> */}
                                <label htmlFor={"newEndDateTime-Date"}>
                                  {t("Res.CurrentEndDate")}
                                </label>
                              </TableCell>
                              <TableCell
                                component="th"
                                className={classes.tableHeader}
                              >
                                <label htmlFor={"endDateTime-time"}>
                                  {t("Res.CurrentEndTime")}
                                </label>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                id="newEndDateTc"
                                data-testid="newEndDateTc-test-id"
                                className={classes.tableBody}
                              >
                                <Field
                                  component={EtdDatePickerV2}
                                  data-testid="end-date-test-id"
                                  label="End date"
                                  name="newEndDate"
                                  id="newEndDateTime-Date"
                                  errorMessageId="endDateErrorMsg"
                                  value={
                                    values.newEndDate !== undefined
                                      ? getDateObjectFromShortString(
                                          values.newEndDate
                                        )
                                      : null
                                  }
                                  isPastDateAllowed={!values.isDriverValidityActivated && !isJourneyStartDateToday(values.dateTime.startDateTime) && isJourneyOver(values.dateTime.endDateTime)}
                                  daysPastDateAllowed={values.isDriverValidityActivated ? null : daysInPastCalculator(values.dateTime.startDateTime)}
                                  handleChange={(e) => {
                                    let newValue = e;
                                    const format =
                                      localeDateTimeFormat.toUpperCase();
                                    let parsedDate = moment(
                                      newValue,
                                      format,
                                      true
                                    );
                                    if (parsedDate.isValid()) {
                                      newValue =
                                        moment(parsedDate).format("YYYY-MM-DD");
                                    } else {
                                      newValue = null;
                                    }
                                    setEndDate(newValue);
                                    setFieldTouched("newEndDate", true);
                                    setFieldValue(
                                      "newEndDate",
                                      newValue === null ? "" : newValue
                                    );
                                  }}
                                  handleBlur={(e) => {
                                    setFieldTouched("newEndDate", true);
                                  }}
                                  isError={
                                    touched.newEndDate && errors.newEndDate
                                      ? true
                                      : false
                                  }
                                  aria-describedby="endDateErrorMsg"
                                />
                              </TableCell>
                              <TableCell
                                id="endDateTimeTc"
                                className={classes.tableBody}
                              >
                                <Field
                                  id="endDateTime-time"
                                  label="End Time"
                                  name={"newEndTime"}
                                  fieldName={"newEndTime"}
                                  defaultValue={values.newEndTime}
                                  component={EtdTimePicker}
                                  format={t("TimeFormat")}
                                  onChange={(e) => {
                                    let time = e.target.value;
                                    setEndTime(time);
                                    setFieldTouched("newEndTime", true);
                                    setFieldValue("newEndTime", time);
                                  }}
                                  errorMessageId="endTimeErrorMsg"
                                  error={
                                    touched.newEndTime && errors.newEndTime
                                      ? true
                                      : false
                                  }
                                  isError={
                                    touched.newEndTime &&
                                    errors &&
                                    errors.newEndTime
                                      ? true
                                      : false
                                  }
                                  aria-describedby="endTimeErrorMsg"
                                ></Field>
                                {touched.newEndTime &&
                                errors &&
                                errors.newEndTime ? (
                                  <span
                                    className={classes.errorWarning}
                                    id="endTimeErrorMsg"
                                  >
                                    {t(errors.newEndTime)}
                                  </span>
                                ) : (
                                  <span id="endTimeErrorMsg"></span>
                                )}
                              </TableCell>
                              <TableCell
                                id="submitButtonTc"
                                className={classes.tableBody}
                              >
                                <Button
                                  id="SubmitButton"
                                  data-testid="dataSubmitButton"
                                  title={t("Res.SubmitRentalChanges")}
                                  color="primary"
                                  variant="contained"
                                  type="submit"
                                  style={{ textTransform: "none" }}
                                  disabled={!isValid || !dirty}
                                >
                                  {t("Submit")}
                                </Button>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                id="endDateTimeErrorTc"
                                className={classes.tableBody}
                                colSpan={3}
                              >
                                {touched.newEndDate &&
                                errors &&
                                errors.newEndDate ? (
                                  <span
                                    className={classes.errorWarning}
                                    id="endDateErrorMsg"
                                  >
                                    {t(errors.newEndDate)}
                                  </span>
                                ) : (
                                  <span id="endDateErrorMsg"></span>
                                )}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                id="offHireTc"
                                className={classes.tableBody}
                                colSpan={3}
                              >
                                <FormControlLabel
                                  id="Keep_me_signed_in"
                                  control={
                                    <Checkbox
                                      checked={offHire}
                                      id="chkOffHire"
                                      style={{
                                        margin: "5px",
                                      }}
                                      onChange={(e) => {
                                        setOffHire(!offHire);
                                      }}
                                      inputProps={{
                                        "aria-label": t("Res.OffHireText"),
                                      }}
                                    />
                                  }
                                  label={t("Res.OffHireText")}
                                  aria-label={t("Res.OffHireText")}
                                />
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Form>
                  )}
                </Formik>
              </Box>
            )}
          </Grid>
        </Grid>
        <Grid
          id="DailyRentalDisclaimerText"
          item
          xs={12}
          md={12}
          style={{ margin: 20 }}
        >
          <Typography className={classes.tableHeader}>
            {" "}
            {t("Res.DailyRentalDisclaimerText")}{" "}
          </Typography>
        </Grid>
      </Box>
    </>
  );
};

export default EditDR;
