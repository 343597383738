import React, {useState} from "react";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import axiosInstance from "../../helpers/axiosInstance";

import { appInsights } from "../../helpers/appInsights";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { Avatar, Box, Grid, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { UserDetails, DialingCodes } from "components/Reservation/steps/JourneyStart/journeyInputType";
import { authService } from "services/authService";
import { debug } from "console";


const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
  spacing:{
    "& .MuiOutlinedInput-input": {
      fontFamily: "monospace"
    },
  },
  placeholder: {
    "& ::placeholder": {
      color: "#000",
      opacity: 0.85,
      whiteSpace: "pre-wrap",
      fontSize: "0.72rem",
      wordBreak: "break-word",
      flexGrow: 1,
      flexBasis: "100%",
      wordSpacing:"normal",
      fontFamily: "Arial, Roboto, Segoe UI",
    },
    "@media (max-width:390px)": {
      fontSize: "0.4rem",
    },
    "@media (min-width: 391px) and (max-width:480px)": {
      fontSize: "0.6rem",
    },
    "@media (min-width: 960px) and (max-width: 960px)": {
      fontSize: "0.7rem",
    },
  },
  option: {
    // Hover
    '&[data-focus="true"]': {
      outline: "3px solid #0066FF !important",
      outlineOffset: "3px",
    },
    "& ::placeholder": {
      color: "#000",
      opacity: 0.85,
      whiteSpace: "pre-wrap",
      fontSize: "0.72rem",
      wordBreak: "break-word",
    },
  },
}));

export default function UserAutoComplete(props) {
  const { id, placeholder, isReadOnly, defaultValue, noMatchText, allowClearOnEscape} = props;
  const [options, setOptions] = useState<UserDetails[]>([]);
  const [dialingCodes, setDialingCodes] = useState<DialingCodes>();
  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  const classes = useStyles();
  const locale = authService.getUserLocale();
  React.useEffect(() => {
    if(defaultValue.driverDeclarationDetailId === 0){
      //reset option
      setOptions([]);
    }
  }, [defaultValue]);
  let filter = "";

  const hasWhiteSpace= (searchQuery: string): boolean =>  {
    return /\s/g.test(searchQuery);
  }
  const splitString = (seachQuery: string): string[] => {
    return seachQuery.split(/(\s+)/);
  }
  const filterStringArray = (searchArray: string[]): string[] => {
    return searchArray.filter(item => !hasWhiteSpace(item));
  }
  const applyFilter = (searchQuery: string): string => {
    let resultArray = splitString(searchQuery);
    resultArray = filterStringArray(resultArray)
    if(resultArray.length > 0){
      return resultArray[1];
    }
    return searchQuery;
  }

  const onChangeHandle = async (value) => {
    // use the changed value to make request and then use the result. Which
    filter = value;
    console.log("search user: ", value);
    if (value && value.trim().length > 0) {
      setLoading(true);
      await axiosInstance
        .get(`users/search?filter=${value}`)
        .then((response) => {
          console.log("User options received: ", response.data.data);
          if (response.data.data && response.data.filter === filter){
            setOptions(response.data.data);
            setDialingCodes(response.data.dialingCodes);
          } 
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.log("Error in getting user details data: ", value);
          setOptions([]);
          appInsights.trackException({
            exception: error,
            properties: {
              method: "autocomplete",
              Component: "EtdUserLookup",
            },
            severityLevel: SeverityLevel.Error,
          });
          console.log(error);
        });
    }
  };

  const saveUserDetails = (newValue: UserDetails) => {
    props.setUserDetails(newValue,dialingCodes);
  };
  const isMobileView = (isMobile: boolean | undefined) : boolean => {
       return (isMobile === undefined || isMobile === true) ? true : false;
  }

  return (
    <Autocomplete
      //freeSolo
      id={id}
      classes={{
        option: classes.option,
      }}
      disableClearable={!allowClearOnEscape}
      clearOnEscape={allowClearOnEscape}
      noOptionsText={noMatchText}
      filterOptions={(x) => x}
      loading={loading}
      isOptionEqualToValue={(option, value) => {return option.userId === value.userId;}}
      getOptionLabel={(option) =>
        option
          ? (option.firstName && option.lastName
            ? option.firstName + " " + option.lastName
            : "")
          : ""
      }
      options={options}
      value={defaultValue}
      // inputValue={isReadOnly ? defaultValue.location : ""} // this is to update default value for End Location
      //loading={true}
      loadingText={t("journeyInput.labelLoading")}
      onChange={(event, newValue: any) => {
        //console.log("newValue",newValue);
        saveUserDetails(newValue);
      }}
      onBlur={() => {
        if (props.onInputChange) props.onInputChange("blurred");
      }}
      renderOption={(prop, option:any, state) => (

        <React.Fragment>
          <li{...prop}>
          { isMobileView(props?.isMobileView) ? (
                    <Grid container direction="row" spacing={1}>
                    <Grid item xs={12} sm={2} style={{paddingLeft : 0}}>
                      <Avatar style={{color: "#fff", backgroundColor: stringToColor(option.firstName + " " + option.lastName)}}>
                        {(option.firstName[0] + option.lastName[0]).toUpperCase()}
                      </Avatar>
                    </Grid>
                    <Grid item xs={12} sm={7} container>              
                      <Grid container direction="column">
                      <Grid item xs>
                          <Typography
                            variant="body2"
                            style={{
                              fontSize: "1em",
                              wordWrap: "break-word",
                              whiteSpace: "pre-wrap"
                            }}
                            key={option.userId}
                            id={"UserOptionName" + option.userId}>
                            {option.firstName + " " + option.lastName}
                          </Typography>
                        </Grid>
                        <Grid item xs>
                          <Typography
                              variant="body2"
                              style={{
                                fontSize: "0.9em",
                                wordWrap: "break-word",
                                whiteSpace: "pre-wrap",
                                opacity: 0.65,
                              }}
                              key={option.userId}
                              id={"UserOptionEmail" + option.userId}>
                              {option.emailAddress}
                            </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
          ) : (
                  <Grid container direction="row" spacing={1}>
                    <Grid item md={2} container>              
                    <Grid container direction="column">
                        <Grid item >
                        <Typography
                            variant="body2"
                            style={{
                            fontSize: "1em",
                            wordWrap: "break-word",
                            whiteSpace: "pre-wrap"
                            }}
                            key={option.firstName}
                            id={"UserOptionFirstName" + option.firstName}>
                            {option.firstName}
                        </Typography>
                        </Grid>
                    </Grid>
                    </Grid>
                    <Grid item md={2} container>              
                    <Grid container direction="column">
                        <Grid item >
                        <Typography
                            variant="body2"
                            style={{
                            fontSize: "1em",
                            wordWrap: "break-word",
                            whiteSpace: "pre-wrap"
                            }}
                            key={option.lastName}
                            id={"UserOptionLasttName" + option.lastName}>
                            {option.lastName}
                        </Typography>
                        </Grid>
                    </Grid>
                    </Grid>
                    <Grid item md={3} container>              
                    <Grid container direction="column">
                        <Grid item >
                        <Typography
                            variant="body2"
                            style={{
                            fontSize: "1em",
                            wordWrap: "break-word",
                            whiteSpace: "pre-wrap"
                            }}
                            key={option.emailAddress}
                            id={"UserOptionEmailAddress" + option.emailAddress}>
                            {option.emailAddress}
                        </Typography>
                        </Grid>
                    </Grid>
                    </Grid>
              </Grid>
          )}
        </li>
        </React.Fragment>


      )}
      renderInput={(params) => (
        <TextField
          {...params}
          margin="none"
          variant="outlined"
          placeholder={placeholder}
          onChange={(ev) => {
            if (ev.target.value !== null && ev.target.value !== "" && ev.target.value.length > 2) {
              let searchQuery = ev.target.value.trim();
              if(hasWhiteSpace(searchQuery)){
                searchQuery = applyFilter(searchQuery);
              }
              onChangeHandle(searchQuery);
            }
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: null,
            disabled: isReadOnly,
            className: classes.placeholder,
            // "aria-describedby":"locationErrorMsg_"+id
          }}
          inputProps={{
            ...params.inputProps,
            autoComplete: "off",
            disabled: isReadOnly,
            // "aria-describedby":"locationErrorMsg_"+id
          }}
          error={props.error}
        />
      )}
    />
  );
}

function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}