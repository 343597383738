import React from "react";
import {
  Grid,
  Typography, 
  Divider, 
} from "@mui/material";

import { PaymentResultProps } from "./paymentType";
import "react-datepicker/dist/react-datepicker.css";

import { useTranslation } from "react-i18next";



const PaymentResult: React.FC<PaymentResultProps> = (props) => {
  
  const { t } = useTranslation();

 
  return (
        
      <Grid container>
        <div>
          <Typography
            id = "paymentPageMethod"
            variant="h2"
            style={{
              marginTop: 0,
              marginBottom: "15px",
            }}
          >
           {t(
                  "ReservationWizard.paymentPage.labelSectionPaymentMethod"
                )}
          </Typography>
        </div>
        <Grid container spacing={1} >
      <Grid item xs={12}>      
      <Divider style={{ backgroundColor: "black", fontWeight: "normal" }} />
      </Grid>
      
      <Grid item xs={12} md={5}><span id="lblCardNumber">{props.cardNumber}</span>     </Grid>
      <Grid item xs={12} md={4}> <span id="lblCardExpiry">{props.cardExpiry}</span> </Grid>     
      <Grid item xs={12} md={3}> <span id="lblCardBrand">{props.cardBrand}</span>     </Grid>
      
      </Grid>
      </Grid>
      
   
);
};
export default PaymentResult;

