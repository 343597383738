import { useSelector } from "react-redux";
import { AppState } from "../rootReducer";

export function useSpecialityRequestError() {
  return useSelector(
    (state: AppState) => state.speciality.errors
  );
}
export function useSpecialityRequest() {
  return useSelector(
    (state: AppState) => state.speciality.request
  );
}

