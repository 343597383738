import * as React from "react";
import { useRef } from "react";

export const usePrevious = <T extends unknown>(value: T): T | undefined => {
  const ref = useRef<T>();
  React.useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};
