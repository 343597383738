export const COMPANYINFO_LOADING = "COMPANYINFO_LODING";
export const COMPANYINFO_FAIL = "COMPANYINFO_FAIL";
export const COMPANYINFO_SUCCESS = "COMPANYINFO_SUCCESS";

export interface MarketingImage {
  imageSource: string;
  imageTitle: string;
  imageId: string;
  imageLink: string;
  imageDescription: string;
  index: number;
}

export interface CompanyLink {
  linkDescription: string;
  linkURL: string;
}

export interface CompanyInfo {
  companyId: number;
  companyLogo: string;
  companyLinks: CompanyLink[];
  marketingMessage: { title: string; message: any };
  marketingImages: MarketingImage[];
  isDriverValidityActivated: boolean;
  isAdditionalDriverActivated: boolean;
  isSpecialityVehicleActive : boolean;
  isCCProfileManagementActivated: boolean;
  isBoboEnabled: boolean;
  isRentalMgmtEnabled: boolean,
  ccPasswordResetLink: string;
  csPasswordResetLink: string;
  ccEnrollmentLink: string;
  isLoyaltyEnabled: boolean;
  takoApiEnv: string;
}

export interface CompanyInfoLoading {
  type: typeof COMPANYINFO_LOADING;
}

export interface CompanyInfoFail {
  type: typeof COMPANYINFO_FAIL;
}

export interface CompanyInfoSuccess {
  type: typeof COMPANYINFO_SUCCESS;
  payload: CompanyInfo;
}

export type CompanyInfoDispatchTypes =
  | CompanyInfoLoading
  | CompanyInfoSuccess
  | CompanyInfoFail;
