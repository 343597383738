import React from "react";
import { useSelector } from "react-redux";
import { AppState } from "../store/reducers/rootReducer";
import { CarouselProperties } from "components/shared/carousel/models";
import { Carousel } from "components/shared/carousel/Carousel";
import { useTranslation } from "react-i18next";

const MarketingCarousel = (props: any) => {
  const { companyInfo } = useSelector((state: AppState) => state.companyInfo);
  const { t } = useTranslation(["home"]);

  const carouselProperties: CarouselProperties = {
    title: t("carouselTitle"),
    slideDurationMilliseconds: 10000,
    slideItems: companyInfo
      ? companyInfo?.marketingImages.map((image) => ({
          title: `${t("slideItemTitlePrefix")} ${image.imageTitle}`,
          href: image.imageLink,
          slideImage: {
            src: image.imageSource,
            alt: image.imageDescription,
          },
        }))
      : [],
  };

  return carouselProperties.slideItems.length ? (
    <Carousel carouselProperties={carouselProperties}></Carousel>
  ) : (
    <div style={{ minHeight: "250px" }}></div>
  );
};

export default MarketingCarousel;
