import { authService } from "services/authService";
export enum Permissions {
  None = 1,
  BoboEnabled = 2,
  ViewOnly=4
}

export const getUserPermissionsStatus = (
  permissions: number,
  selectedPermission: Permissions
): boolean => {
  const result: boolean = false;

  if (permissions === 0) {
    return result;
  }

  return selectedPermission === (permissions & selectedPermission);
};

export const getUserPermissionsValue = (
  permission: number,
  selectedPermission: Permissions,
  selectedValue: boolean
): number => {
  if (selectedValue) {
    if (!getUserPermissionsStatus(permission, selectedPermission))
      return permission + selectedPermission;
  } else {
    if (getUserPermissionsStatus(permission, selectedPermission))
      return permission - selectedPermission;
  }
  return permission;
};
export const hasPermission = (perm: Permissions): boolean => {
  const result: boolean = false;
  const userPermissions = authService.getUserPermissions();
  if (userPermissions === 0) {
    return result;
  }

  return perm === (userPermissions & perm);
};