import { DriverDeclaration } from "../Reservation/reservationFormType";

export interface driverDetails {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  isSelected: boolean;
}

export enum SearchMatchType {
  exact = 0,
  loose = 1,
}

export interface CarClubDriverState {
  hasError: boolean;
  drivers: driverDetails[];
  journeyEndDate?: string;
  searchParam?: driverDetails;
  errors?: string;
}

export interface CarClubDriverProps {
  handleSelectDriver: (id: string) => void;
  selectedDriver: string;
  carclubregisterhyperlink?: string;
  isDriverValidityActivated?: boolean;
  isBoboEnabled?: boolean;
  carClubDrivers: driverDetails[];
  searchParam?: driverDetails;
  onDataChange: (
    isValid: boolean,
    additionalDriver?: DriverDeclaration[]
  ) => void;
}
export enum CarClubViews {
  NoDriverMatch = 0,
  DriverExactMatch = 1,
  DriverBookForSelf = 2,
  DriverBOBO = 3,
}
