import { isNullOrWhitespace } from "helpers/stringHelpers";
import { BulkUploadUser } from "./bulkuploadTypes";

export const csvJSON = (csvText) => {
  let lines: any[] = [];
  const linesArray = csvText.split("\n");
  console.log("LinesArray: ", linesArray);
  // for trimming and deleting extra space
  linesArray.forEach((e: any) => {
    const row = e.replace(/[\s]+[,]+|[,]+[\s]+/g, ",").trim();
    lines.push(row);
  });

  const result: any[] = [];
  const headers = lines[0].split(",");
  console.log("headers: ", headers);
  console.log("Total Lines: ", lines);
  for (let i = 1; i < lines.length; i++) {
    const obj = {};
    const currentline = lines[i].split(",");

    for (let j = 0; j < headers.length; j++) {
      obj[headers[j].toLowerCase()] = currentline[j];
    }
    if (!isNullRow(obj)) result.push(obj);
  }

  return result;
};

export const validateData = (lines): [boolean, string[]] => {
  let errormsg: string[] = [];
  if (lines !== null && lines.length > 0) {
    //if file has more than 200 records
    if (lines.length > 200) {
      errormsg.push("File has exceeded the record limit size of 200.");
      return [false, errormsg];
    }

    for (let i = 0; i < lines.length; i++) {
      let msg = "";
      let line = lines[i];
      const regexExp = new RegExp(/^[a-zA-Z0-9äöüÄÖÜß@.'*_-]*$/);
      if (isNullOrWhitespace(line.username))
        msg = msg + `username is required.`;
      if (!regexExp.test(line.username)) msg = msg + `invalid Username`;
      if (isNullOrWhitespace(line.firstname))
        msg = msg + `first name required.`;
      if (isNullOrWhitespace(line.lastname))
        msg = msg + `last name is required.`;
      if (isNullOrWhitespace(line.email)) msg = msg + `email is required.`;
      if (isNullOrWhitespace(line.requiredaction))
        msg = msg + `required action is missing.`;
      if (
        (line.preferredphone.length > 0 || line.alternatephone.length > 0) &&
        isNullOrWhitespace(line.countrycode)
      )
        msg = msg + `country code is required.`;
      if (!isNullOrWhitespace(line.bookonbehalf)) {
        if (line.bookonbehalf !== '1' && line.bookonbehalf !== '0') {
          msg = msg + `value entered in bookonbehalf column is invalid. `;
        }
      }
      if (!isNullOrWhitespace(line.viewonly)) {
        if (line.viewonly !== '1' && line.viewonly !== '0') {
          msg = msg + `value entered in viewonly column is invalid. `;
        }
      }  
         

      if (msg.length > 0) {
        errormsg.push(`Line ${i + 1} : ${msg}`);
      }
    }
  } else errormsg.push("Invalid file or no records found.");

  return [errormsg.length === 0, errormsg];
};

export const transformData = (
  lines,
  isoLangCode,
  selectedLang
): BulkUploadUser[] => {
  let data: BulkUploadUser[] = [];

  for (let i = 0; i < lines.length; i++) {
    let line = lines[i];
    let model: BulkUploadUser = {
      lineNo: i + 1,
      title: line.title,
      firstName: line.firstname,
      lastName: line.lastname,
      username: line.username,
      emailAddress: line.email,
      updatedEmailAddress: line.updateemail,
      updatedUserName: line.updateusername,
      requiredAction: line.requiredaction,
      lineManagerEmail: line.linemanageremail,
      isActive: true,
      preferredPhoneCountry: line.countrycode,
      preferredPhoneNumber: line.preferredphone,
      alternativePhoneCountry: line.countrycode,
      alternativePhoneNumber: line.alternatephone,
      companyInternalNumber: 0,
      bobo: line.bookonbehalf,
      viewOnly: line.viewonly,
      selectedLanguage: selectedLang,
      isoLanguageCode: isoLangCode,
      createdModifiedById: "BulkUpload",
    };
    data.push(model);
  }

  console.log("model data: ", data);
  return data;
};

export const isNullRow = (line: any) => {
  if (
    line.username === undefined &&
    line.firtname === undefined &&
    line.lastname === undefined &&
    line.email === undefined &&
    line.requiredaction === undefined
  )
    return true;
  else return false;
};

export const setNullValuesToEmpty = (obj) =>
  JSON.parse(JSON.stringify(obj, (k, v) => (v === null ? "" : v)));

export const hasValidColumns = (arr1, arr2) => {
  let result = true;
  for (let i = 0; i < arr1.length; i++) {
    let header = arr1[i];
    let found = false;
    for (let j = 0; j < arr2.length; j++) {
      if (header === arr2[j]) found = true;
    }
    if (!found) result = false;
  }

  return result;
};
