import {
  Checkbox,
  Grid,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import { Paper } from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { CarClubDriverProps, driverDetails } from "./carClubDriverType";
import { broadcastAnnouncement } from "components/store/actions/shared/announcementActions";
import { useDispatch } from "react-redux";
import { decodeEmailIfEncoded, maskEmail } from "helpers/emailHelpers";
import { authService } from "services/authService";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "##fff",
    color: theme.palette.common.black,
    borderBottom: "1px solid #000",
    borderRight: "1px solid #ddd",
    fontWeight: "bold",
    height: "50px",
    padding: "0px 15px",
    maxWidth: "100px",
  },
  body: {
    fontSize: 12,
    padding: "0px 15px",
    height: "40px",
  },
  footer: {
    textAlign: "right",
    justifyContent: "right",
  },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 700,
    border: "0px",
    "& .MuiTableCell-root": {
      borderRight: "1px solid #ddd",
      color: "#000000",
      paddingBottom: "0px",
      whiteSpace: "nowrap",
    },
  },
}));

const CarClubDriverTable: React.FC<CarClubDriverProps> = ({
  handleSelectDriver,
  selectedDriver,
  carClubDrivers,
  onDataChange
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [ccSelectedDriver, setCCSetSelectedDriver] =
    React.useState(selectedDriver);
  const driverInfo = authService.getDriverInfo();

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setTimeout(() => {
        if (carClubDrivers.length === 1 && selectedDriver !== "") {
          const index = carClubDrivers.findIndex(
            (driver) => driver.id === selectedDriver
          );
          dispatch(
            broadcastAnnouncement(
              t("ReservationWizard.carClubPage.memberIdListLabel")+ ".   "+ t("journeyInput.validOption1") +".   "+
              "Selected Driver " +
                carClubDrivers[index].firstName +
                " " +
                carClubDrivers[index].lastName +
                " " +
                carClubDrivers[index].email +
                " " +
                carClubDrivers[index].id
            )
          );
        } else if (carClubDrivers.length === 0)
          dispatch(broadcastAnnouncement("Users.nodatamessage"));
        else
          dispatch(
            broadcastAnnouncement(
              t("ReservationWizard.carClubPage.memberIdListLabel")
            )
          );
      }, 100);
    }
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <Grid
      data-testid="carClubDriverTable"
      container
      item
      xs={12}
      md={12}
      style={{ display: "flex", width: "100%" }}>
      <TableContainer component={Paper}>
        <Table className={classes.table} id="primaryDriverList">
          <TableHead>
            <TableRow tabIndex={0}>
              {carClubDrivers.length > 1 && (
                <StyledTableCell
                  id="selectDriver"
                  width="30"
                  style={{ whiteSpace: "pre-wrap" }}>
                  {t("ReservationWizard.driverDetails.selectDriver")}
                </StyledTableCell>
              )}
              <StyledTableCell id="firstname" tabIndex={0}>
                {t("ReservationWizard.carClubPage.firstname")}
              </StyledTableCell>
              <StyledTableCell id="lastname" tabIndex={0}>
                {t("ReservationWizard.carClubPage.lastname")}
              </StyledTableCell>
              <StyledTableCell id="emailaddress" tabIndex={0}>
                {t("ReservationWizard.carClubPage.emailaddress")}
              </StyledTableCell>
              <StyledTableCell id="carClubMemberId" tabIndex={0}>
                {t("Res.carClubMemberId")}
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {carClubDrivers !== null && carClubDrivers.length > 0 ? (
              carClubDrivers.map((driver: driverDetails, index: number) => (
                <TableRow key={index} id={"primaryDriverList" + index} tabIndex={0} >
                  {carClubDrivers.length > 1 && (
                    <StyledTableCell>
                      <Checkbox
                    inputProps={{ 
                          "aria-label":
                            "Select Driver " +
                            driver.firstName +
                            " " +
                            driver.lastName +
                            " " +
                            driver.email +
                            " " +
                            driver.id,
                        }}
                        id={"selectedDriver" + index}
                        checked={ccSelectedDriver === driver.id}
                        onChange={() => {
                          setCCSetSelectedDriver(driver.id);
                          handleSelectDriver(driver.id);
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            setCCSetSelectedDriver(driver.id);
                            handleSelectDriver(driver.id);
                          }
                        }}
                        name="selectedDriver"
                        color="primary"
                      />
                    </StyledTableCell>
                  )}
                  <StyledTableCell tabIndex={0} >{driver.firstName}</StyledTableCell>
                  <StyledTableCell tabIndex={0} >{driver.lastName}</StyledTableCell>
                  <StyledTableCell tabIndex={0} >{driverInfo?.isBOBOToggleEnabledAndIsDriverDeclaration ? maskEmail(driver.email) : decodeEmailIfEncoded(driver.email)}</StyledTableCell>
                  <StyledTableCell tabIndex={0} >{driver.id} </StyledTableCell>
                </TableRow>
              ))
            ) : (
              <TableRow tabIndex={0}>
                <TableCell
                  colSpan={7}
                  style={{
                    textAlign: "center",
                    justifyContent: "center",
                    marginTop: "2em",
                  }}>
                  {t("Users.nodatamessage")}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
};
export default CarClubDriverTable;
