import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { alertTypes } from "../../../../models/alertTypes.enum";

interface AlertState {
  open: boolean;
  message?: string | string[];
  severity?: alertTypes;
}

const initialState: AlertState = {
  open: false,
  message: undefined,
  severity: undefined,
};

export const alertSlice = createSlice({
  name: "alert",
  initialState,
  reducers: {
    showSuccessAlertMessage: (_, action: PayloadAction<string | string[]>) => ({
      message: action.payload,
      severity: alertTypes.Success,
      open: true,
    }),
    showErrorAlertMessage: (_, action: PayloadAction<string | string[]>) => ({
      message: action.payload,
      severity: alertTypes.Error,
      open: true,
    }),
    showInfoAlertMessage: (_, action: PayloadAction<string | string[]>) => ({
      message: action.payload,
      severity: alertTypes.Info,
      open: true,
    }),
    clearAlertMessage: () => initialState,
  },
});

export default alertSlice.reducer;
