import React, { useEffect } from "react";

import {
  Backdrop,
  Typography,
  Button,
  Box,
  CircularProgress,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useLocation , useNavigate } from "react-router-dom";
import { isHiddenAdditionalParameters } from "helpers/additionalParameters";

import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { AppState } from "components/store/reducers/rootReducer";
import { ReservationProps } from "../../reservationType";
import { authService } from "services/authService";
import {
  JourneyMethodType1,
  ReservationAdditionalInformationFieldTypeCode,
} from "./../../../../views/reservation/ReservationViewType";

import {
  showInfoAlertMessage,
  clearAlertMessage,
} from "components/store/actions/shared/alertActions";

import { getLocaleDate, getLocaleCurrency } from "helpers/localization";
import { ReservationAdditionalInformationFieldViewModel } from "../Reservation/reservationFormType";
import { broadcastAnnouncement } from "components/store/actions/shared/announcementActions";
import HTMLEllipsis from "react-lines-ellipsis/lib/html";
import BusyIndicator from "controls/busyIndicator/busyIndicator";
import { translateOTA } from "helpers/otaTranslateHelper";
import { resetReservation } from "components/store/actions/reservation/reservationActions";
import { resetDriverDetails } from "components/store/slicers/driverDetailSlice";
import { resetBookingReferenceDetails } from "components/store/slicers/bookingReferenceSlice";
import { resetAccountLocationDetails } from "components/store/slicers/accountLocationSlice";
import PaymentResult from "../Payment/paymentResult";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
    paddingRight: 30,
    paddingTop: 15,
    paddingBottom: 15,
  },
  title: {
    fontSize: "1.4em",
    paddingLeft: 30,
    paddingTop: 10,
  },
  subtitle: {
    fontSize: "1.2em",
    marginTop: 10,
    marginBottom: 10,
    fontWeight: "normal",
  },
  tableHeader: {
    fontWeight: "bold",
    padding: 4,
    borderBottom: "none",
    fontSize: "1.1em",
    whiteSpace: "normal",
  },
  tableBody: {
    fontSize: "1.1em",
    padding: 4,
    borderBottom: "none",
    wordBreak: "break-word",
  },
  tableHeader0: {
    fontWeight: "bold",
    padding: 4,
    borderBottom: "none",
    fontSize: "1.1em",
    overflow: "hidden",
    textOverflow: "ellipsis",
    wordWrap: "break-word",
    //wordBreak: "break-all",
    width: "30%",
  },
  tableBody0: {
    fontSize: "1.1em",
    padding: 4,
    borderBottom: "none",
    overflow: "hidden",
    textOverflow: "ellipsis",
    wordBreak: "break-all",
    wordWrap: "break-word",
    width: "70%",
  },
  detail: {
    padding: 0,
    wordBreak: "break-word",
  },
  rootcell: {
    "& .MuiTableCell-root": {
      verticalAlign: "top",
      borderBottom: "none",
      padding: "10px",
    },
  },
  divider: {
    backgroundColor: "#000",
    height: "0.8px",
    marginRight: 30,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  gridItem: {
    verticalAlign: "center",
    padding: "10px",
    "& .MuiTypography-body2": {
      fontWeight: "bold",
    },
  },
  heading: {
    fontSize: 16,
    fontWeight: "bold",
  },
  labelText: {
    color: "#000",
    fontFamily: "Arial, Roboto, Segoe UI",
    fontSize: 14,
    fontWeight: "bold",
    verticalAlign: "middle",
  },
  errorWarning: {
    color: "#DF0000",
    fontSize: 12,
    marginLeft: "15px",
  },
  "& .MidTitleHR": {
    border: "none",
    //  border: none,
    height: 1,
    backgroundColor: "#111111",
  },
  disclaimer: {
    fontWeight: "bold",
    padding: 4,
    borderBottom: "none",
    fontSize: "1.1em",
  },
}));

// const ConfirmReservationForm = (props: any) => {
const ConfirmReservationForm: React.FC<ReservationProps> = (props) => {
  const [open, setOpen] = React.useState(false);
  const location = useLocation();
  const classes = useStyles();
  const { reservation } = useSelector((state: AppState) => state.dailyRental);
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);
  const queryParams = new URLSearchParams(location.search);
  const qType = queryParams.get("type");
  const resTypeEdited = qType && qType === "1";
  const locale = authService.getUserLocale();
  const { companyInfo } = useSelector(
    (state: AppState) => state.companyInfo || {}
  );

  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(clearAlertMessage());
    dispatch(resetReservation());
    dispatch(resetDriverDetails());
    dispatch(resetBookingReferenceDetails());
    dispatch(resetAccountLocationDetails());
    history("/home");
  };

  const [reservationNumber, setReservationNumber] = React.useState(
    reservation?.reservationNumberString
  );

  const titleRef = React.useRef<HTMLElement>(null);

  function isNullOrWhitespace(input) {
    return !input || !input.trim();
  }

  useEffect(() => {
    dispatch(clearAlertMessage());
    if (
      reservation &&
      reservation?.warnings &&
      reservation?.warnings.length > 0
    ) {
      var updatedWarnings: string[] = [];
      if (Array.isArray(reservation.warnings)) {
        reservation.warnings.forEach((element) => {
          if (element === "ERR561") {
            updatedWarnings.push(
              t("ReservationWizard.reservationPage.msgLoyaltyERR561")
            );
          } else {
            updatedWarnings.push(element);
          }
        });
      }
      if (
        typeof reservation.warnings === "string" &&
        reservation.warnings === "ERR561"
      ) {
        updatedWarnings.push(
          t("ReservationWizard.reservationPage.msgLoyaltyERR561")
        );
      }

      dispatch(showInfoAlertMessage(updatedWarnings));
      dispatch(broadcastAnnouncement(updatedWarnings.toString()));
    }
    window.scrollTo(0, 0);
  });

  function handleBack() {
    if (props.handleBack) props.handleBack(0, 0);
  }

  function getBookingReference(
    input: ReservationAdditionalInformationFieldViewModel
  ) {
    var singleReference;
    switch (input.fieldTypeCode) {
      case ReservationAdditionalInformationFieldTypeCode.Date:
        singleReference =
          input.fieldName +
          " = " +
          (input.fieldValueDateString === ""
            ? ""
            : new Date(
                parseInt(input.fieldValueDateString.substr(0, 4)), // year
                parseInt(input.fieldValueDateString.substr(4, 2)) - 1, // month
                parseInt(input.fieldValueDateString.substr(6, 2)) // date
              ).toLocaleString(
                locale.toString() === "en-GB" ? "en-GB" : "de-DE",
                {
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                }
              ));
        break;
      default:
        singleReference =
          input.fieldName + " = " + (input.fieldValue ? input.fieldValue : "");
    }
    return singleReference;
  }

  let history = useNavigate();

  const selectedCar = reservation?.carClass?.carsInformation.classInfo.find(
    (x) => x.modelCode === reservation?.carClass?.carClassCode
  );

  const selectedBrandInfo = selectedCar?.brandInfo.find(
    (x) => x.brand === reservation?.carClass?.brand
  );

  const startLocationMethodType =
    Number(reservation?.locations?.startLocation.selectedMethodTypeId) === 0
      ? (reservation?.locations?.startLocation.methodTypes[0]).text
      : t(
          "Res." +
            JourneyMethodType1[
              Number(reservation?.locations?.startLocation.selectedMethodTypeId)
            ]
        );

  const endLocationMethodType =
    Number(reservation?.locations?.endLocation.selectedMethodTypeId) === 0
      ? (reservation?.locations?.startLocation.methodTypes[0]).text
      : t(
          "Res." +
            JourneyMethodType1[
              Number(reservation?.locations?.endLocation.selectedMethodTypeId)
            ]
        );

  const startLocation =
    reservation?.locations?.startLocation.selectedMethodTypeId === "1" ||
    reservation?.locations?.startLocation.selectedMethodTypeId === "3"
      ? reservation?.startServicingBranch
          ?.split("&lt;br/&gt;")
          ?.map((p, index) => {
            return (
              <Typography key={index}>
                {p} <br />{" "}
              </Typography>
            );
          })
      : reservation?.startLocationString
          ?.replace("&#39;", "'")
          ?.split("&lt;br/&gt;")
          ?.map((p, index) => {
            return (
              <Typography key={index}>
                {p} <br />{" "}
              </Typography>
            );
          });

  const endLocation =
    reservation?.locations?.endLocation.selectedMethodTypeId === "1" ||
    reservation?.locations?.endLocation.selectedMethodTypeId === "3"
      ? reservation?.endServicingBranch
          ?.replace("&#39;", "'")
          ?.split("&lt;br/&gt;")
          ?.map((p, index) => {
            return (
              <Typography key={index}>
                {p} <br />{" "}
              </Typography>
            );
          })
      : reservation?.endLocationString
          ?.replace("&#39;", "'")
          ?.split("&lt;br/&gt;")
          ?.map((p, index) => {
            return (
              <Typography key={index}>
                {p} <br />{" "}
              </Typography>
            );
          });

  const isExtrasSelected = () => {
    return (
      (selectedBrandInfo?.includedExtras != null &&
        selectedBrandInfo?.includedExtras.length > 0) ||
      (selectedBrandInfo?.selectedExtras != null &&
        selectedBrandInfo?.selectedExtras.length > 0)
    );
  };
  // const displayWarnings = () => {
  //   if (reservation && reservation?.warnings) {
  //     dispatch(showInfoAlertMessage(reservation?.warnings));
  //     dispatch(broadcastAnnouncement(reservation?.warnings));
  //   }
  // };

  //dispatch(clearAlertMessage());

  //displayWarnings();
  // console.log("start=" + startLocationMethodType);
  // console.log("end=" + endLocationMethodType);

  return (
    <div>
      {!isNullOrWhitespace(reservation?.notificationMessage) ? (
        <Box boxShadow={0} m={3}>
          <HTMLEllipsis
            id="marketingMessage"
            unsafeHTML={reservation?.notificationMessage}
            maxLine="5"
            ellipsis="..."
            basedOn="letters"
          />
          {""}
        </Box>
      ) : (
        ""
      )}
      <Grid container>
        <Typography
          variant="h1"
          style={{
            margin: 5,
            paddingLeft: 50,
          }}
        >
          {t("ReservationWizard.confirmationPageTitle")}
        </Typography>
        <Grid item xs={12}>
          {/* <Typography variant={"body2"}> */}
          <Typography
            id="successMessageDiv"
            style={{ fontWeight: "bold", paddingLeft: 55 }}
          >
            {!resTypeEdited
              ? companyInfo?.isDriverValidityActivated
                ? t("Res.thankYouMessageCreatedDD", {
                    reservationNumber: reservationNumber,
                  })
                : t("Res.thankYouMessageCreated", {
                    reservationNumber: reservationNumber,
                  })
              : companyInfo?.isDriverValidityActivated
              ? t("Res.thankYouMessageChangedDD", {
                  reservationNumber: reservationNumber,
                })
              : t("Res.thankYouMessageChanged", {
                  reservationNumber: reservationNumber,
                })}
            {/*
          Thank you! You reservation has been successfully created! To re-book
          please return to the home page. */}
          </Typography>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              marginTop: "1em",
              paddingLeft: 55,
            }}
          >
            <Button
              variant="contained"
              color="primary"
              id="makeAnotherBookingLink"
              onClick={handleClose}
            >
              {t("Res.backToHome")}
            </Button>
          </div>
        </Grid>
      </Grid>
      {/* <Box boxShadow={3} m={3}> */}
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress />
      </Backdrop>
      <Grid className={classes.rootcell} container spacing={3}>
        <Grid id="titleHeader" item xs={12} md={9} className={classes.root}>
          <Typography ref={titleRef} className={classes.title} variant="body2">
            {t("Res.confirmationDetails")}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} className={classes.root}>
          <Box style={{ paddingLeft: 30 }}>
            <Typography
              className={classes.subtitle}
              style={{ marginTop: 0 }}
              variant="h2"
            >
              {t("Res.DriverDetails")}
            </Typography>
            <Divider className={classes.divider} />
            <TableContainer id="detailsTable" className={classes.detail}>
              <Table aria-label="details table" style={{ width: "95%" }}>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" className={classes.tableHeader0}>
                      <Typography variant="h2">{t("DriverName")}</Typography>
                    </TableCell>
                    <TableCell
                      width="66.67%"
                      id="DriverName"
                      className={classes.tableBody0}
                    >
                      <Typography variant="body1">
                        {reservation?.driverName}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" className={classes.tableHeader0}>
                      <Typography variant="h2">
                        {t("Res.DriverEmail")}
                      </Typography>
                    </TableCell>
                    <TableCell id="DriverEmail" className={classes.tableBody0}>
                      <Typography variant="body1">
                        {" "}
                        {reservation?.driverEmail}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  {reservation?.driverDeclaration &&
                    reservation?.additionalDriver &&
                    reservation?.additionalDriver.length > 0 && (
                      <TableRow>
                        <TableCell
                          component="th"
                          className={classes.tableHeader0}
                        >
                          <Typography variant="h2">
                            {t("AdditionalDriver")}
                          </Typography>
                        </TableCell>
                        <TableCell
                          id="AdditionalDrivers"
                          className={classes.tableBody0}
                        >
                          <Typography variant="body1">
                            {reservation?.additionalDriver?.map(
                              (additionalDriver, index) => {
                                return (
                                  <Typography key={index}>
                                    {" "}
                                    {additionalDriver.firstName +
                                      " " +
                                      additionalDriver.lastName}
                                    <br />{" "}
                                  </Typography>
                                );
                              }
                            )}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  {(reservation?.loyalty?.loyaltyId ||
                    reservation?.journeyProduct?.enableLoyalty) && (
                    <TableRow>
                      <TableCell
                        component="th"
                        className={classes.tableHeader0}
                      >
                        <Typography variant="h2">
                          {t(
                            "ReservationWizard.reservationPage.labelLoyaltyId"
                          )}
                        </Typography>
                      </TableCell>
                      <TableCell id="LoyaltyId" className={classes.tableBody0}>
                        <Typography variant="body1">
                          {reservation?.loyalty?.loyaltyId}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                  <TableRow>
                    <TableCell component="th" className={classes.tableHeader0}>
                      <Typography variant="h2">
                        {t("Res.DisplayName")}
                      </Typography>
                    </TableCell>
                    <TableCell id="DisplayName" className={classes.tableBody0}>
                      <Typography variant="body1">
                        {reservation?.displayName ?? reservation?.accountName}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" className={classes.tableHeader0}>
                      <Typography variant="h2">
                        {t("Res.LastModifiedBy")}
                      </Typography>
                    </TableCell>
                    <TableCell
                      id="LastModifiedBy"
                      className={classes.tableBody0}
                    >
                      <Typography variant="body1">
                        {reservation?.bookerName}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" className={classes.tableHeader0}>
                      <Typography noWrap variant="h2">
                        {t("Res.BookingReferences")}
                      </Typography>
                    </TableCell>
                    {reservation?.additionalInformation &&
                    reservation?.additionalInformation
                      ?.additionalInformationFields &&
                    reservation?.additionalInformation
                      ?.additionalInformationFields.length > 0 ? (
                      <TableCell
                        id="BookingReferences"
                        data-testid="BookingReferences"
                        className={classes.tableBody0}
                      >
                        <Typography variant="body1">
                          {reservation?.additionalInformation?.additionalInformationFields.map(
                            (additionalInformation, index) => {
                              return (
                                (!isHiddenAdditionalParameters(additionalInformation.fieldName) &&<Typography key={index}>
                                  {" "}
                                  {getBookingReference(
                                    additionalInformation
                                  )}{" "}
                                  <br />{" "}
                                </Typography>)
                              );
                            }
                          )}
                        </Typography>
                      </TableCell>
                    ) : (
                      <TableCell className={classes.tableBody0}></TableCell>
                    )}
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <Typography className={classes.subtitle} variant="h2">
              {t("Res.BranchDetails")}
            </Typography>
            <Divider className={classes.divider} />
            <TableContainer id="branchDetailsTable" className={classes.detail}>
              <Table aria-label="Branch table">
                <TableBody>
                  <TableRow>
                    <TableCell component="th" className={classes.tableHeader0}>
                      <Typography variant="h2">
                        {t("Res.BranchAddress")}
                      </Typography>
                    </TableCell>
                    <TableCell
                      id="BranchAddress"
                      className={classes.tableBody0}
                    >
                      <Typography variant="body1">
                        {reservation?.branchAddress}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" className={classes.tableHeader0}>
                      <Typography variant="h2">
                        {t("Res.BranchTelephone")}
                      </Typography>
                    </TableCell>
                    <TableCell
                      id="BranchTelephone"
                      className={classes.tableBody0}
                    >
                      <Typography variant="body1">
                        {reservation?.branchTelephone}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" className={classes.tableHeader0}>
                      <Typography variant="h2">
                        {t("Res.VehicleClass")}
                      </Typography>
                    </TableCell>
                    <TableCell id="VehicleClass" className={classes.tableBody0}>
                      <Typography variant="body1">
                        {reservation?.carClass?.carClassCode +
                          "-" +
                          reservation?.carClass?.carClass?.replace(
                            "OR SIMILAR",
                            t("Res.OTA.OrSimilar").toUpperCase()
                          )}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" className={classes.tableHeader0}>
                      <Typography variant="h2">{t("Res.Brand")}</Typography>
                    </TableCell>
                    <TableCell
                      id="FullBrandName"
                      className={classes.tableBody0}
                    >
                      <Typography variant="body1">
                        {reservation?.fullBrandName}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" className={classes.tableHeader0}>
                      <Typography variant="h2">{t("Res.Notes")}</Typography>
                    </TableCell>
                    <TableCell id="Notes" className={classes.tableBody0}>
                      <Typography variant="body1">
                        {reservation?.notes}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  {reservation?.locations?.startLocation?.isAirportLocation && (
                    <TableRow data-testid="flightNumberRow">
                      <TableCell component="th" className={classes.tableHeader}>
                        <Typography variant="h2">
                          {t("Res.flightNumber")}
                        </Typography>
                      </TableCell>
                      <TableCell
                        id="FlightNumber"
                        className={classes.tableBody0}
                      >
                        <Typography variant="body1">
                          {reservation?.flightNumberViewModel?.flightNumber}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <Typography className={classes.subtitle} variant="h2">
              {t("Res.JourneyDetails")}
            </Typography>
            <Divider className={classes.divider} />
            <TableContainer
              id="reservationdetailsTable"
              className={classes.detail}
            >
              <Table>
                <TableBody>
                  {reservation?.showReservationDetails && (
                    <>
                      <TableRow>
                        <TableCell
                          width="33.33%"
                          component="th"
                          className={classes.tableHeader0}
                        >
                          <Typography noWrap variant="h2">
                            {t("Res.ReservationNumber")}
                          </Typography>
                        </TableCell>
                        <TableCell
                          id="ReservationNumber"
                          className={classes.tableBody0}
                        >
                          <Typography variant="body1">
                            {reservation?.reservationNumberString}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          component="th"
                          className={classes.tableHeader0}
                        >
                          <Typography noWrap variant="h2">
                            {t("Res.ReservationStatus")}
                          </Typography>
                        </TableCell>
                        <TableCell
                          id="ReservationStatus"
                          className={classes.tableBody0}
                        >
                          <Typography variant="body1">
                            {t("Res." + reservation.reservationStatus)}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                  <TableRow>
                    <TableCell component="th" className={classes.tableHeader0}>
                      <Typography variant="h2">{t("StartDateTime")}</Typography>
                    </TableCell>
                    <TableCell
                      id="StartDateTime"
                      className={classes.tableBody0}
                    >
                      <Typography variant="body1">
                        {reservation?.startDateTimeString !== undefined
                          ? getLocaleDate(
                              reservation.startDateTimeString,
                              locale
                            )
                          : ""}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" className={classes.tableHeader0}>
                      <Typography variant="h2">{t("EndDateTime")}</Typography>
                    </TableCell>
                    <TableCell id="EndDateTime" className={classes.tableBody0}>
                      <Typography variant="body1">
                        {reservation?.endDateTimeString !== undefined
                          ? getLocaleDate(reservation.endDateTimeString, locale)
                          : ""}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" className={classes.tableHeader0}>
                      <Typography variant="h2">{t("StartLocation")}</Typography>
                    </TableCell>
                    <TableCell
                      id="StartLocation"
                      className={classes.tableBody0}
                    >
                      <Typography variant="body1">{startLocation}</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" className={classes.tableHeader0}>
                      <Typography variant="h2">
                        {t("Res.StartMethod")}
                      </Typography>
                    </TableCell>
                    <TableCell
                      id="StartMethod"
                      className={classes.tableBody0}
                      style={{ paddingTop: 8, paddingBottom: 8 }}
                    >
                      <Typography variant="body1">
                        {startLocationMethodType}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  {!isNullOrWhitespace(reservation?.startServicingBranch) && (
                    <TableRow>
                      <TableCell
                        component="th"
                        className={classes.tableHeader0}
                      >
                        <Typography variant="h2">
                          {t("Res.ServicingBranch")}
                        </Typography>
                      </TableCell>
                      <TableCell
                        id="StartServicingBranch"
                        className={classes.tableBody0}
                      >
                        <Typography variant="body1">
                          {" "}
                          {reservation?.startServicingBranch}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                  <TableRow>
                    <TableCell component="th" className={classes.tableHeader0}>
                      <Typography variant="h2">{t("EndLocation")}</Typography>
                    </TableCell>
                    <TableCell id="EndLocation" className={classes.tableBody0}>
                      <Typography variant="body1">{endLocation}</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" className={classes.tableHeader0}>
                      <Typography variant="h2">{t("Res.EndMethod")}</Typography>
                    </TableCell>
                    <TableCell
                      id="EndMethod"
                      className={classes.tableBody0}
                      style={{ paddingTop: 8, paddingBottom: 8 }}
                    >
                      <Typography variant="body1">
                        {endLocationMethodType}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  {!isNullOrWhitespace(reservation?.endServicingBranch) && (
                    <TableRow>
                      <TableCell
                        component="th"
                        className={classes.tableHeader0}
                      >
                        <Typography variant="h2">
                          {t("Res.ServicingBranch")}
                        </Typography>
                      </TableCell>
                      <TableCell
                        id="EndServicingBranch"
                        className={classes.tableBody0}
                      >
                        <Typography variant="body1">
                          {" "}
                          {reservation?.endServicingBranch}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>
        {!reservation?.isStartDateInPast && (
          <Grid item xs={12} md={6}>
            <Box id="DRCostDetails" style={{ paddingLeft: 30 }}>
              <Typography
                className={classes.subtitle}
                style={{ marginTop: 0 }}
                variant="h2"
              >
                {t("Res.EstimatedCostExtrasDetails")}
              </Typography>
              <Divider className={classes.divider} />
              <Box className={classes.detail} style={{ paddingTop: 5 }}>
                <Typography
                  variant="h2"
                  className={classes.tableHeader}
                  style={{ marginBottom: 10 }}
                >
                  {t("Res.VehicleClass")}
                </Typography>
                <Divider className={classes.divider} />
                <TableContainer>
                  <Table>
                    <TableBody>
                      {selectedCar?.vehicleClass != null && (
                        <TableRow>
                          <TableCell className={classes.tableBody} width="50%">
                            {" "}
                            <Typography variant="body1">
                              {translateOTA(selectedCar?.vehicleClass || "", t)}{" "}
                            </Typography>
                          </TableCell>
                          <TableCell className={classes.tableBody} width="25%">
                            {/* {selectedBrandInfo?.pricePerBaseWithCurrency} */}
                            <Typography variant="body1">
                              {getLocaleCurrency(
                                selectedBrandInfo?.pricePerBaseWithCurrency,
                                locale,
                                selectedBrandInfo?.currency
                              )}
                              /{" "}
                              {translateOTA(
                                selectedBrandInfo?.unitName || "",
                                t
                              )}{" "}
                            </Typography>
                          </TableCell>
                          <TableCell className={classes.tableBody} width="25%">
                            {" "}
                            <Typography variant="body1">
                              {getLocaleCurrency(
                                selectedBrandInfo?.baseRatePriceWithCurrency,
                                locale,
                                selectedBrandInfo?.currency
                              )}{" "}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )}
                      {selectedBrandInfo?.additionalRentalCharge &&
                        selectedBrandInfo?.additionalRentalCharge
                          .filter((x) => x.rate > 0)
                          .map((p, index) => {
                            return (p.description.toUpperCase().toString() === "MILEAGE CHARGE" ?
                            "" 
                            :
                              <TableRow key={index}>
                                <TableCell
                                  className={classes.tableBody}
                                  width="50%"
                                >
                                  <Typography variant="body1">
                                    {p.description.toLowerCase().toString() === "drop charge" || p.description.toUpperCase().toString() === "MILEAGE CHARGE"
                                      ? translateOTA(p.description, t)
                                      : ""}
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  className={classes.tableBody}
                                  width="25%"
                                >
                                  <Typography variant="body1">
                                    {p.description.toLowerCase().toString() ===
                                    "drop charge" // there is data issue on p.pricePerUnitWithCurrency, please see comments on 39663
                                      ? ""
                                      : getLocaleCurrency(
                                          p.rateWithCurrency,
                                          locale,
                                          p.currency
                                        ) +
                                        "/ " +
                                        translateOTA(p.unitName || "", t)}{" "}
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  className={classes.tableBody}
                                  width="25%"
                                >
                                  <Typography variant="body1">
                                    {p.rateWithCurrency}
                                  </Typography>

                                  {/* {getLocaleCurrency(
                                      p.rateWithCurrency,
                                      locale,
                                      p.currency
                                    )} */}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                    </TableBody>
                  </Table>
                </TableContainer>

                {isExtrasSelected() && (
                  <>
                    <Typography
                      variant="h2"
                      style={{ marginTop: 10, marginBottom: 10 }}
                      className={classes.tableHeader}
                    >
                      {t("Res.Extras")}
                    </Typography>
                    <Divider className={classes.divider} />
                    <TableContainer>
                      <Table>
                        <TableBody>
                          {selectedBrandInfo?.includedExtras?.map(
                            (p, index) => {
                              return (
                                <TableRow key={index}>
                                  <TableCell
                                    className={classes.tableBody}
                                    width="50%"
                                  >
                                    <Typography variant="body1">
                                      {" "}
                                      {translateOTA(p.description, t)}
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    className={classes.tableBody}
                                    width="25%"
                                  ></TableCell>
                                  <TableCell
                                    className={classes.tableBody}
                                    width="25%"
                                  >
                                    <Typography variant="body1">
                                      {" "}
                                      {getLocaleCurrency(
                                        p.unitPriceWithCurrency,
                                        locale,
                                        p.currency
                                      )}
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                              );
                            }
                          )}
                          {selectedBrandInfo?.selectedExtras?.map(
                            (p, index) => {
                              return (
                                <TableRow key={index}>
                                  <TableCell
                                    width="50%"
                                    className={classes.tableBody}
                                  >
                                    <Typography variant="body1">
                                      {translateOTA(p.description, t) + " "}{" "}
                                      {p.isCountable ? "x" + p.quantity : ""}
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    width="25%"
                                    className={classes.tableBody}
                                  >
                                    <Typography variant="body1">
                                      {p.isCountable
                                        ? getLocaleCurrency(
                                            p.unitPriceWithCurrency,
                                            locale,
                                            p.currency
                                          ) +
                                          "/" +
                                          t("Res.PerUnit")
                                        : ""}
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    width="25%"
                                    className={classes.tableBody}
                                  >
                                    <Typography variant="body1">
                                      {getLocaleCurrency(
                                        p.totalUnitPriceWithCurrency,
                                        locale,
                                        p.currency
                                      )}
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                              );
                            }
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </>
                )}

                {selectedCar?.fees != null && selectedCar.fees.length > 0 && (
                  <>
                    <Typography
                      variant="h2"
                      style={{ marginTop: 10, marginBottom: 10 }}
                      className={classes.tableHeader}
                    >
                      {t("Res.TaxesAndFees")}
                    </Typography>
                    <Divider className={classes.divider} />
                    <TableContainer>
                      <Table id="tableFees">
                        <TableBody>
                          {selectedCar.fees.map((p, index) => {
                            return (
                              <TableRow key={index}>
                                <TableCell
                                  width="50%"
                                  className={classes.tableBody}
                                >
                                  <Typography 
                                    id={p.description.replace(/ /g, "")+"-DESCRIPTION"}
                                    variant="body1" 
                                    style={{whiteSpace: 'pre-line'}}
                                  >
                                    {p.description.toUpperCase() === "MILEAGE CHARGE" ?
                                    t("Res.OTA.MileageCharge", { quantity: selectedCar.distance.quantity, unit: selectedCar.distance.unit.toUpperCase() === "MILE" ? "MI" : "KM", baseAmount: p.baseAmount, currency: p.currency}) :
                                    translateOTA(p.description, t)
                                    }
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  width="25%"
                                  className={classes.tableBody}
                                ></TableCell>
                                <TableCell
                                  id={p.description.replace(/ /g, "")}
                                  width="25%"
                                  className={classes.tableBody}
                                >
                                  <Typography variant="body1">
                                    {p.includedInRate
                                      ? t("Res.Included")
                                      : p.totalAmount.toLocaleString(locale, {
                                          minimumFractionDigits: 2,
                                          useGrouping: false,
                                        }) +
                                        " " +
                                        p.currency}
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </>
                )}
                <p
                  className={classes.tableBody}
                  style={{ fontSize: "0.9em", color: "#006639" }}
                >
                  {t("Res.FeeDisclaimer")}
                </p>
                <Divider className={classes.divider} />
                <TableContainer>
                  <Table id="TotalEstimatedCost">
                    <TableBody>
                      <TableRow>
                        <TableCell width="40%" className={classes.tableBody}>
                          <Typography variant="h2">
                            {companyInfo?.isDriverValidityActivated
                              ? t("Res.BBCTotalEstimatedCost")
                              : t("Res.TotalEstimatedCost")}
                          </Typography>
                        </TableCell>
                        <TableCell
                          width="30%"
                          className={classes.tableBody}
                        ></TableCell>
                        <TableCell
                          id="TotalEstimatedCostWithCurrency"
                          width="30%"
                          className={classes.tableBody}
                        >
                          <Typography variant="h2">
                            {
                              reservation?.carClass?.carsInformation
                                .totalEstimatedCostWithCurrency
                              // getLocaleCurrency(
                              //   reservation?.carClass?.carsInformation
                              //     .totalEstimatedCostWithCurrency,
                              //   locale
                              // )
                            }
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                {companyInfo?.isDriverValidityActivated &&
                  reservation?.isInternationalStartLocation && (
                    
                      <div id="BBCCustomNote">
                        <Typography
                          variant="h2"
                          className={classes.tableBody}
                          style={{
                            marginTop: 10,
                            marginBottom: 10,
                            fontWeight: "bold",
                          }}
                        >
                          <div id="BBCCustomNoteLabel">
                            {t("Res.BBCNoteLabel")}
                          </div>
                          <div id="BBCCustomNoteDecription">
                            {t("Res.BBCNote")}
                          </div>
                        </Typography>
                        <Typography
                          id="BBCTransactionNote"
                          variant="h2"
                          className={classes.tableBody}
                          style={{
                            marginTop: 10,
                            marginBottom: 10,
                            fontWeight: "bold",
                          }}
                        >
                          {t("Res.BBCTransactionNote")}
                        </Typography>
                      </div>
                    
                  )}

                <div style={{marginTop:10, marginLeft:10, marginBottom:10}}>
                  {reservation?.isCreditCardToggleEnabled && reservation?.isCreditCard && reservation.payment ?  (
                    <div>
                        <PaymentResult cardNumber={reservation.payment.cardNumber} cardExpiry={reservation.payment.cardExpiry} 
                        cardBrand={reservation.payment.cardBrand} />
                    <Typography id="reviewPaymentDisclaimer"  variant="body2"  style={{ marginTop:20, fontSize: "0.9em", color: "#006639" }}>
                      {t("Res.PaymentDisclaimer")}
                    </Typography>
                    </div>
                  ) : 
                  <div> 
                    <Typography
                  variant="h2"
                  style={{ marginTop: 10, marginBottom: 10 }}
                  className={classes.tableHeader}
                >
                  {t("Res.MethodOfPayment")}
                </Typography>
                <Divider className={classes.divider} />
                <div id="BillToAccountLabel">
                  {reservation?.reasonForHire.isPayAtCounter ? (
                    <Typography variant="body1" className={classes.tableBody}>
                      {t("Res.PointOfRental")}
                    </Typography>
                  ) : (
                    <Typography variant="body1" className={classes.tableBody}>
                      {t("Res.billToAccount")}
                    </Typography>
                  )}
                </div>
                </div>
                }
                </div>             
               
              </Box>  
             
            </Box>
          </Grid>
        )}
      </Grid>
      <Grid
        id="DailyRentalDisclaimerText"
        item
        xs={12}
        md={12}
        style={{ margin: 20 }}
      >
        <Typography className={classes.disclaimer}>
          {" "}
          {t("Res.DailyRentalDisclaimerText")}{" "}
        </Typography>
      </Grid>
      {/* </Box> */}
      <BusyIndicator isloading={open} text={"Please Wait"} />
    </div>
  );
};

export default ConfirmReservationForm;
