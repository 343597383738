export function isWithin12Months (endDateString) {

    console.log("endDateString",endDateString)
    const endDate = new Date(endDateString);
    // Get the current date and add 12 months to it
    const currentDate = new Date();
    currentDate.setFullYear(currentDate.getFullYear() + 1);
    // Compare the parsed date with the current date + 12 months
    if(endDate <= currentDate){
      return true;
    }
    console.log("The end date is not within 12 months")
    return false;
  }

  export function isWithin12MonthsEditScenario (endDateString,createdDateString) {

    console.log("endDateString",endDateString)
    const endDate = new Date(endDateString);
    const createdDate = new Date(createdDateString);
    // Get the current date and add 12 months to it
   // const currentDate = new Date();
    createdDate.setFullYear(createdDate.getFullYear() + 1);
    console.log("currentDate",createdDate)
    // Compare the parsed date with the current date + 12 months
    if(endDate <= createdDate){
      return true;
    }
    console.log("The end date is not within 12 months of created Date")
    return false;
  }
  
  export function isWithin28Days(startDateString, endDateString) {
    console.log("startDateString",startDateString)
    console.log("endDateString",endDateString)
    const startDate = new Date(startDateString);
    const endDate = new Date(endDateString);
    
    // Check if endDate is after startDate and the difference is less than or equal to 28 days
    const diffInDays = (endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24);
    console.log("diffInDays",diffInDays)
    return diffInDays <= 28;
  }

  export function isCreditCardExpirationDateValid(reservationEndDateString, creditCardExpirationDateString)
  {
    const reservationEndDate = new Date(reservationEndDateString);
    const creditCardExpirationDate = new Date(creditCardExpirationDateString);
    const endDateYYYYMMstring = reservationEndDate.getFullYear().toString().concat((reservationEndDate.getMonth() + 1).toString().padStart(2, '0'));
    const creditCardExpirationDateYYYYMMstring = creditCardExpirationDate.getFullYear().toString().concat((creditCardExpirationDate.getMonth() + 1).toString().padStart(2, '0'));
    if(creditCardExpirationDateYYYYMMstring >= endDateYYYYMMstring){
      return true;
    }
    console.log("Credit Card expiry date is not valid for the entire reservation period");
    return false;
  }
  
  
  const arrayOfCountriesForInternationalReservation = ['usa', 'canada','kanada','canadá',"ee. uu.", "états-unis"];
  export function isInUSAorCanada(startLocation){
    const countryName = getStartLocationCountryName(startLocation);
    const result = arrayOfCountriesForInternationalReservation.some(countryInList => countryName.toLowerCase() === countryInList);
    return result;
    }

  const arrayOfCountriesCodeForInternationalReservation = ['usa', 'can','ca','us']
  export function isInUSAorCanadaCountryCode(startLocation){
    console.log("startLocation",startLocation);
    const result = arrayOfCountriesCodeForInternationalReservation.some(countryInList => startLocation.toLowerCase() === countryInList);
    console.log("result",result)
    return result;
    }
  
  export function getStartLocationCountryName(startLocation){
   const elements = startLocation.split(",")
   return elements[elements.length - 1].trim();
  }

  export const getCardBrand = (cardBrand:string | null | undefined) => {
    if( cardBrand)
      {let cardBrandName = cardBrand.split(":");
    return cardBrandName[cardBrandName.length-1];
      }
      return "--";
    
  }
export  const getCardExpiry = (lastMonth:string | null | undefined, lastYear:string | null | undefined) => {
    if( lastMonth && lastYear)
      {
        let cardExpiry = ("00" + lastMonth).slice(-2) + "/" + ("00" + lastYear).slice(-2);
        return "(Exp. " + cardExpiry + ")";     
      }
      return "--";
    
  }

  export  const getMaskedCardNumber = (cardNumber:string | null | undefined) => {
    if( cardNumber)
      {        
        return "**** **** **** " + cardNumber.substring(cardNumber.length - 4);     
      }
      return "**** **** **** ----";
    
  }
  export const isCreditCardExpiryValid =  (expiryMonth:number, expiryYear:number, endDateTimeString:string | undefined) : boolean => {
    let isValid = false;
    if(expiryMonth && expiryYear && endDateTimeString) {
      const endDate = new Date(endDateTimeString);
      const expiryDate = new Date(expiryYear, expiryMonth , 0);
       isValid = endDate < expiryDate
    }
    
    return isValid;
  };