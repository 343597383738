import React from "react";
import { Button, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { useSelector } from "react-redux";
import { AppState } from "components/store/reducers/rootReducer";
import dompurify from "dompurify";
import HTMLEllipsis from "react-lines-ellipsis/lib/html";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  subtitle: {
    fontSize: "1.2rem",
    fontWeight: "bold",
    "@media (max-width:360px)": {
      fontSize: "0.7rem",
      fontWeight: "bold",
    },
  },
});

export default function MarketingMessage() {
  const { companyInfo } = useSelector((state: AppState) => state.companyInfo);
  const [expanded, setExpanded] = React.useState(false);
  const [hasBeenClamped, setHasBeenClamped] = React.useState(false);
  const { t } = useTranslation(["home"]);
  const classes = useStyles();

  const sanitizedMarketingMessageHtml = dompurify.sanitize(
    companyInfo && companyInfo.marketingMessage.message
  );

  const handleReflow = (rleState) => {
    const { clamped } = rleState;
    if (clamped && !hasBeenClamped) {
      setHasBeenClamped(true);
    }
  };

  return (
    <section data-testid="marketingMessage" aria-live="polite" aria-atomic="false">
      <div>
        <Typography className={classes.subtitle} variant="h1">
          {companyInfo && companyInfo.marketingMessage.title}
        </Typography>
      </div>
      {!expanded ? (
        <HTMLEllipsis
          id="marketingMessage"
          unsafeHTML={sanitizedMarketingMessageHtml}
          maxLine="1"
          ellipsis="..."
          basedOn="letters"
          onReflow={handleReflow}
        />
      ) : (
        <div
          id="marketingMessage"
          dangerouslySetInnerHTML={{
            __html: sanitizedMarketingMessageHtml,
          }}
        />
      )}
      <div
        style={{
          display: hasBeenClamped ? "flex" : "none",
          marginTop: "12px",
          justifyContent: "flex-end",
        }}
      >
        <Button
          aria-controls="marketingMessage"
          aria-expanded={expanded}
          onClick={() => setExpanded(!expanded)}
        >
          {expanded ? t("btnShowLess") : t("btnShowMore")}
        </Button>
      </div>
    </section>
  );
}
