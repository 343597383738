const terms = {
  "PREMIUM LOCATION FEE": "PremiumLocationFee",
  "DROP CHARGE": "DropCharge",
  "MILEAGE CHARGE": "MileageCharge",
  "UNLIMITED MILEAGE": "UnlimitedMileage",
  "EXTRA HOUR CHARGE": "ExtraHourCharge",
  "VALUE ADDED TAX": "ValueAddedTax",
  "VEHICLE LICENSE FEE": "VehicleLicenseFee",
  "COLLISION DAMAGE WAIVER AND THEFT": "CollisionDamageWaiverAndTheft",
  "COLLISION DAMAGE WAIVER": "CollisionDamageWaiver",
  "YOUNG RENTER FEE": "YoungRenterFee",
  "THEFT PROTECTION": "TheftProtection",
  "PERSONAL ACCIDENT INSURANCE": "PersonalAccidentInsurance",
  "VEH LICENSE FEE RECOVERY": "VehLicenseFeeRecovery",
  "CONCESSION RECOUP": "ConcessionRecoup",
  "AIRPORT CONCESSION FEE RECOVERY": "AirportConcessionFeeRecovery",
  "JEFFERSON PARISH TAX": "JeffersonParishTax",
  "STATE SALES TAX": "StateSalesTax",
  "COLLISION DAMAGE WAIVER FULL": "CollisionDamageWaiverFull",
  "CUSTOMER FACILITY CHARGE": "CustomerFacilityCharge",
  "VEHICLE RENTAL SURCHARGE PCT": "VehicleRentalSurchargePCT",
  "CUSTOMER FACILITY FEE": "CustomerFacilityFee",
  "CONCESSION RECOVERY FEE": "ConcessionRecoveryFee",
  "SALES TAX": "SalesTax",
  "TOURISM SURCHARGE": "TourismSurcharge",
  "EXTRA DAY CHARGE": "ExtraDayCharge",
  "REGISTRATION FEE": "RegistrationFee",
  "EXCESS PROTECTION": "ExcessProtection",
  "NAVIGATION SYSTEM": "NavigationSystem",
  "CARBON OFFSET": "CarbonOffset",
  "OR SIMILAR": "OrSimilar",
  "AIRPORT FEE": "AirportFee",
};
export const translateOTA = (term, t): string => {
  let OTAKeys = Object.keys(terms);

  //Replace unitName
  term = term.toUpperCase().replace("DAY", t("Res.OTA.Day"));
  term = term.replace("WEEK", t("Res.OTA.Week"));
  term = term.replace("MONTH", t("Res.OTA.Month"));
  term = term.replace("RENTALPREIOD", t("Res.OTA.RentalPeriod"));

  let relatedOTAKey = OTAKeys.filter((a) => term.toUpperCase().includes(a));
  if (relatedOTAKey.length < 1) return term;

  let translatedValue = t(`Res.OTA.${terms[relatedOTAKey[0]]}`);
  let result = term.toUpperCase().replace(relatedOTAKey[0], translatedValue);

  return result;
};
