import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import Grid from "@mui/material/Grid";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import axiosInstance from "../../helpers/axiosInstance";
import {
  JourneyDetailsViewModel,
  JourneyItemActionViewModel,
  ReservationAdditionalInformationFieldTypeCode,
  ReservationAdditionalInformationFieldViewModel,
} from "./ReservationViewType";
import RefreshIcon from "@mui/icons-material/Refresh";
import PrintIcon from "@mui/icons-material/Print";
import moment from "moment";
import EmailIcon from "@mui/icons-material/Email";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import {
  clearAlertMessage,
  showErrorAlertMessage,
  showSuccessAlertMessage,
} from "components/store/actions/shared/alertActions";
import { useDispatch } from "_testHelpers/react-redux-hooks";
import { ArrowBack } from "@mui/icons-material";
import { broadcastAnnouncement } from "components/store/actions/shared/announcementActions";
import { appInsights } from "helpers/appInsights";
import { PageView, SeverityLevel } from "@microsoft/applicationinsights-common";
import { authService } from "services/authService";
import { isHiddenAdditionalParameters } from "helpers/additionalParameters";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
    marginBottom: 20,
    paddingRight: 30,
    paddingTop: 15,
    paddingBottom: 15,
  },
  title: {
    fontSize: "1.4em",
    paddingLeft: 30,
    marginBottom: 10,
  },
  subtitle: {
    fontSize: "1.2em",
    marginTop: 10,
    marginBottom: 10,
    maxWidth:"400px",
  },
  tableHeader: {
    fontWeight: "bold",
    padding: 4,
    borderBottom: "none",
    fontSize: "1.1em",
  },
  tableBody: {
    fontSize: "1.1em",
    padding: 4,
    borderBottom: "none",
  },
  detail: {
    paddingTop: 15,
  },
  divider: {
    backgroundColor: "#000",
    height: "0.8px",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
  rootcell: {
    "& .MuiTableCell-root": {
      verticalAlign: "top",
      borderBottom: "none",
      padding:"10px"
    },
  },
}));

const ViewMR = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [resData, setresData] = useState<JourneyDetailsViewModel | undefined>(
    undefined
  );
  const { journeyID, reference } = useParams<{
    journeyID: string;
    reference: string;
  }>();
  const [journeyActions, setjourneyActions] =
    React.useState<JourneyItemActionViewModel>();
  let history = useNavigate();
  const [hideEmailCard, sethideEmailCard] = React.useState(true);
  const [email, setEmail] = React.useState("");
  const [myEmail, setmyEmail] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const emailButtonRef = React.useRef<HTMLButtonElement>(null);
  const sendEmailRef = React.useRef<HTMLInputElement>(null);
  const printRef = React.useRef<HTMLButtonElement>(null);
  const titleRef = React.useRef<HTMLElement>(null);
  const locale = authService.getUserLocale();
  appInsights.trackPageView();

  const handleEmailOptionChange = (event) => {
    if (event.target.value === "true") {
      setmyEmail(true);
      setEmail("");
    } else setmyEmail(false);
  };
  const RebookReservation = () => {
    appInsights.trackEvent({
      name: "View MR Rebook",
      properties: { Reference: reference },
    });
    history("/home?id=" + journeyID);
  };
  const handleEmail = (event) => {
    setEmail(event.target.value);
  };
  const dispatch = useDispatch();

  const keyPress = (e) => {
    if (e.keyCode === 13) {
      dispatch(broadcastAnnouncement("Sending Email"));
      handleSendEmail();
    }
  };

  function getBookingReference(
    input: ReservationAdditionalInformationFieldViewModel
  ) {
    var singleReference;
    switch (input.fieldTypeCode) {
      case ReservationAdditionalInformationFieldTypeCode.Date:
        singleReference =
          input.fieldName +
          " = " +
          (input.fieldValueDateString === ""
            ? ""
            : new Date(
                parseInt(input.fieldValueDateString.substr(0, 4)),
                parseInt(input.fieldValueDateString.substr(4, 2)) - 1,
                parseInt(input.fieldValueDateString.substr(6, 2))
              ).toLocaleString(locale.toString() === "en-GB" ? "en-GB" : "de-DE", {
                year: "numeric",
                month: "numeric",
                day: "numeric",
              }));
        break;
      default:
        singleReference =
          input.fieldName + " = " + (input.fieldValue ? input.fieldValue : "");
    }
    return singleReference;
  }

  async function handleSendEmail() {
    setLoading(true);
    dispatch(clearAlertMessage());
    let emailAddress = "default";
    if (!myEmail) emailAddress = email;
    appInsights.trackEvent({
      name: "View MR Send Email",
      properties: { Reference: reference, isDefault: myEmail },
    });
    if (emailAddress === "") {
      setLoading(false);
      dispatch(showErrorAlertMessage(t("Res.EnterValidEmailMessage")));
      dispatch(showErrorAlertMessage(t("Res.EnterValidEmailMessage")));
    } else {
      const data = await axiosInstance
        .post(
          "journeyassessment/SendConfirmation?id=" +
            journeyID +
            "&defaultEmailAddress=" +
            myEmail +
            "&emailAddress=" +
            emailAddress
        )
        .then((response) => {
          setLoading(false);
          console.log(response.data);
          if (response.data.success) {
            dispatch(
              showSuccessAlertMessage(t("Res." + response.data.message))
            );
            dispatch(broadcastAnnouncement(t("Res." + response.data.message)));
          } else {
            dispatch(showErrorAlertMessage(t("Res." + response.data.message)));
            dispatch(broadcastAnnouncement(t("Res." + response.data.message)));
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error.response);
          if (error.response) {
            if (
              error.response.data.message === "EnterValidEmailMessage" ||
              error.response.data.message ===
                "SendComfirmationEmailFailureMessage"
            ) {
              dispatch(
                showErrorAlertMessage(t("Res." + error.response.data.message))
              );
              dispatch(
                broadcastAnnouncement(t("Res." + error.response.data.message))
              );
            } else {
              dispatch(showErrorAlertMessage(error.response.data.message));
              dispatch(broadcastAnnouncement(error.response.data.message));
            }
          }

          appInsights.trackException({
            id: "ViewMR Email",
            exception: error,
            severityLevel: SeverityLevel.Error,
          });
          console.error("There was an error!", error);
        });
    }
  }

  useEffect(() => {
    async function GetReservation() {
      const data = await axiosInstance
        .get(`reservation/details/${journeyID}/${reference}`)
        .then((response) => {
          setresData(response.data);
          setLoading(false);
        })
        .catch((error) => {
          console.error("There was an error!", error);
          appInsights.trackException({
            id: "ViewMR",
            exception: error,
            severityLevel: SeverityLevel.Error,
          });
        });
    }
    async function GetJourneyActions() {
      const journeyAction = await axiosInstance
        .get(`journeyassessment/journeyactions/${journeyID}`)
        .then((response) => {
          setjourneyActions(response.data);
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    }
    document.title = t("ViewJourneyTitle");
    dispatch(clearAlertMessage());
    setLoading(true);
    GetJourneyActions();
    GetReservation();
    if (printRef.current != null) printRef.current.focus();
  }, []);
  useEffect(() => {
    if (sendEmailRef.current != null) sendEmailRef.current.focus();
  }, [hideEmailCard]);
  return (
    <Box m={3}>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress />
      </Backdrop>
      <Grid className={classes.rootcell} container spacing={3}>
        <Grid id="titleHeader" item xs={12} md={7}>
          <Typography ref={titleRef} className={classes.title} variant="h2">
            {t("Res.MileageReimbursement")} {t("Res.JourneyDetails")}
          </Typography>
          <div style={{ paddingLeft: 30, paddingTop: 10  }}>
            <Typography className={classes.subtitle} variant="h3">
              {t("Res.SummaryOfYourJourney")}
            </Typography>
            <Divider sx={{marginTop:"10px"}} className={classes.divider} />{" "}
          </div>
        </Grid>
        <Grid id="actionButtons" item xs={12} md={4}>
          <Box display="flex" style={{ float: "right" }}>
            <IconButton
              id="printJourneyButton"
              size="medium"
              ref={printRef}
              aria-label={t("Res.Print")}
              title={t("Res.PrintTitle")}
              //role="img"
              onClick={() => {
                appInsights.trackEvent({
                  name: "View MR Print",
                  properties: { Reference: reference },
                });
                dispatch(clearAlertMessage());
                sethideEmailCard(true);
                window.print();
              }}
              style={{
                marginRight: 5,
                border: "1px solid #547D66",
                color: "#000000",
              }}
            >
              <PrintIcon />
              {t("Print")}
            </IconButton>
            <IconButton
              id="repeatJourneyButton"
              size="medium"
              aria-label={t("BookAgain")}
              title={t("BookAgainTitle")}
              //role="img"
              onClick={() => RebookReservation()}
              style={
                journeyActions?.canCopy
                  ? {
                      marginRight: 5,
                      border: "1px solid #547D66",
                      color: "#000000",
                    }
                  : { display: "none" }
              }
            >
              <RefreshIcon />
              {t("BookAgain")}
            </IconButton>
            <IconButton
              id="emailJourneyButton"
              size="medium"
              ref={emailButtonRef}
              aria-label={t("Res.Email")}
              title={t("Res.EmailTitle")}
              //role="img"
              onClick={() => {
                sethideEmailCard(!hideEmailCard);
              }}
              style={
                journeyActions?.canEmail
                  ? {
                      marginRight: 5,
                      border: "1px solid #547D66",
                      color: "#000000",
                    }
                  : { display: "none" }
              }
            >
              <EmailIcon />
              {t("Res.Email")}
            </IconButton>
            <IconButton
              id="backButton"
              size="medium"
              aria-label={t("Back")}
              title={t("BackTitle")}
              //role="img"
              onClick={() => {
                dispatch(clearAlertMessage());
                history(-1);
              }}
              style={{ border: "1px solid #547D66", color: "#000000" }}
            >
              <ArrowBack />
              {t("Back")}
            </IconButton>
          </Box>
        </Grid>
        {!hideEmailCard && (
          <Grid
            aria-labelledby="SendEmail-title"
            id="sendEmailCard"
            item
            xs={12}
          >
            <Card
              style={{ width: "50%", float: "right", marginRight: "5px" }}
              variant="outlined"
            >
              <Typography
                id="SendEmail-title"
                style={{ padding: "16px", fontSize: "1.1em" }}
                variant="h2"
              >
                {t("Res.SendJourneyDetails")}
              </Typography>
              <Divider
                className={classes.divider}
                style={{ marginLeft: "5px", marginRight: "5px" }}
              />
              <CardContent>
                <FormControl component="fieldset" fullWidth>
                  <RadioGroup
                    aria-label="email options"
                    name="emailoption"
                    value={myEmail}
                    onChange={handleEmailOptionChange}
                  >
                    <FormControlLabel
                      id="sendConfirmationMyEmailAddressRadioButton"
                      value={true}
                      control={
                        <Radio
                          color="primary"
                          onKeyDown={keyPress}
                          inputRef={sendEmailRef}
                        />
                      }
                      label={t("Res.SendtoMyEmail")}
                    />
                    <FormControlLabel
                      id="sendConfirmationOtherEmailAddressRadioButton"
                      value={false}
                      control={<Radio onKeyDown={keyPress} color="primary" />}
                      label={t("Res.SendtoOtherEmail")}
                    />
                  </RadioGroup>
                  <TextField
                    value={email}
                    onChange={handleEmail}
                    onKeyDown={keyPress}
                    placeholder={t("Res.OtherEmailAddress")}
                    id="confirmationOtherEmailAddressTextBox"
                    disabled={myEmail}
                    fullWidth
                    margin="dense"
                    size="small"
                    variant="outlined"
                  />
                </FormControl>
              </CardContent>
              <CardActions style={{ float: "right" }}>
                <Button
                  id="sendConfirmationEmailButton"
                  variant="contained"
                  style={{ textTransform: "none" }}
                  onClick={handleSendEmail}
                  color="primary"
                  size="small"
                >
                  {t("Res.Send")}
                </Button>
                <Button
                  style={{ textTransform: "none" }}
                  id="closeConfirmationEmailButton"
                  variant="outlined"
                  color="primary"
                  size="small"
                  onClick={() => {
                    sethideEmailCard(!hideEmailCard);
                    dispatch(broadcastAnnouncement(t("Res.sendEmailClose")));
                    dispatch(clearAlertMessage());
                    if (emailButtonRef.current != null)
                      emailButtonRef.current.focus();
                  }}
                >
                  {t("Res.Close")}
                </Button>
              </CardActions>
            </Card>
          </Grid>
        )}
        <Grid id="printtitleHeader" style={{ display: "none" }} item xs={12}>
          <Typography align="center" className={classes.title} variant="h2">
            {t("Res.ThankYouForYourJourney")}
          </Typography>
          <div style={{ paddingLeft: 30 }}>
            <Typography
              align="center"
              variant="h3"
              style={{
                paddingBottom: 20,
                color: "#d02216",
                fontSize: "1.2em",
              }}
            >
              {t("Res.PleaseSeeConfirmationDetails")}
            </Typography>
            <Divider sx={{marginTop:"10px"}} className={classes.divider} />{" "}
          </div>
        </Grid>
        <Grid item xs={12} md={6} className={classes.root}>
          <Typography className={classes.title} variant="h3">
            {t("Res.JourneyInformation")}
          </Typography>
          <Box style={{ paddingLeft: 30 }}>
            <Divider sx={{marginTop:"10px"}} className={classes.divider} />
            <TableContainer id="detailsTable" className={classes.detail}>
              <Table aria-label="journey table">
                <TableBody>
                  <TableRow>
                    <TableCell width="40%" className={classes.tableHeader}>
                      <Typography variant="h2">{t("ReferenceNumber")}</Typography>
                    </TableCell>
                    <TableCell
                      id="referenceNumberDiv"
                      className={classes.tableBody}
                    >
                      <Typography variant="body1">{resData?.referenceNumber}</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.tableHeader}>
                     <Typography variant="h2"> {t("Status")}</Typography>
                    </TableCell>
                    <TableCell id="statusDiv" className={classes.tableBody}>
                    <Typography variant="body1">{resData?.status}</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.tableHeader}>
                      <Typography variant="h2">{t("Res.StartEndAddress")}</Typography>
                    </TableCell>
                    <TableCell
                      id="startAddressDiv"
                      className={classes.tableBody}
                    >
                      <Typography variant="body1"><strong>{t("Start")}:</strong> {resData?.startAddress}{" "}</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.tableHeader}></TableCell>
                    <TableCell id="endAddressDiv" className={classes.tableBody}>
                    <Typography variant="body1"><strong>{t("End")}: </strong> {resData?.endAddress}{" "}</Typography>
                    </TableCell>
                  </TableRow>
                  {resData?.midPoints != null &&
                    resData?.midPoints.length > 0 && (
                      <TableRow id="midPointsDiv">
                        <TableCell className={classes.tableHeader}>
                        <Typography variant="h2"> {t("MidPoints")}</Typography>
                        </TableCell>
                        <TableCell className={classes.tableBody}>
                          {resData?.midPoints?.map((p, index) => {
                            return (
                              <Typography  variant="body1" key={index}>
                                {" "}
                                {p} <br />{" "}
                              </Typography>
                            );
                          })}{" "}
                        </TableCell>
                      </TableRow>
                    )}
                  <TableRow>
                    <TableCell
                      style={{ whiteSpace: "pre-wrap" }}
                      className={classes.tableHeader}
                    >
                      <Typography variant="h2"> {t("Res.StartEndDateTime")}</Typography>
                    </TableCell>
                    <TableCell
                      id="startDateTimeDiv"
                      className={classes.tableBody}
                    >
                      <Typography variant="body1"><strong>{t("Start")}:</strong>
                      {resData?.startDateTime === null
                        ? ""
                        : moment(resData?.startDateTime).format(
                            t("DateTimeFormat")
                          )}{" "}</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.tableBody}></TableCell>
                    <TableCell
                      id="endDateTimeDiv"
                      className={classes.tableBody}
                    >
                      <Typography variant="body1"><strong>{t("End")}: </strong>{" "}
                      {resData?.endDateTime === null
                        ? ""
                        : moment(resData?.endDateTime).format(
                            t("DateTimeFormat")
                          )}{" "}</Typography>
                    </TableCell>
                  </TableRow>
                  {resData?.bookingReferences != null &&
                    resData?.bookingReferences.length > 0 && (
                      <TableRow>
                        <TableCell className={classes.tableHeader}>
                        <Typography variant="h2">{t("Res.BookingReferences")}{" "}</Typography>
                        </TableCell>
                        <TableCell className={classes.tableBody}>
                          {resData?.additionalInformation.additionalInformationFields?.map(
                            (additionalInformation, index) => {
                              return ( 
                                (!isHiddenAdditionalParameters(additionalInformation.fieldName) &&
                                <Typography
                                  key={"additionalInformation" + index}
                                >
                                  {" "}
                                  {getBookingReference(
                                    additionalInformation
                                  )}{" "}
                                  <br />{" "}
                                </Typography>)
                              );
                            }
                          )}
                        </TableCell>
                      </TableRow>
                    )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography className={classes.title} variant="h3">
            {t("Res.DriversAndRiskAssessmentDetails")}
          </Typography>
          <Box style={{ paddingLeft: 30 }}>
            <Divider sx={{marginTop:"10px"}}  className={classes.divider} />
            <TableContainer id="driverDetailsTable" className={classes.detail}>
              <Table>
                <TableBody>
                  <TableRow id="driversNamediv">
                    <TableCell width="40%" className={classes.tableHeader}>
                      
                      <Typography variant="h2">{" "}{t("Res.Driver")}{" "}</Typography>
                    </TableCell>
                    <TableCell className={classes.tableBody}>
                    <Typography variant="body1">{" "}
                      {resData?.driver}{" "}</Typography>
                    </TableCell>
                  </TableRow>
                  {resData?.riskAssessmentQA != null &&
                    resData?.riskAssessmentQA.map((p, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell width="40%" className={classes.tableBody}>
                          <Typography variant="body1">{" "}
                            {p.question}</Typography>
                          </TableCell>
                          <TableCell
                            id={"riskAssessmentAnswer" + index + "div"}
                            className={classes.tableBody}
                          >
                            <Typography variant="body1">{" "}
                            {p.answer}{" "}</Typography>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
export default ViewMR;
