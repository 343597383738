import {
  Button,
  DialogActions,
  DialogTitle,
  Divider,
  Typography,
} from "@mui/material";
import React from "react";

import axiosInstance from "../helpers/axiosInstance";
import { useTranslation } from "react-i18next";

import {
  showSuccessAlertMessage,
  showErrorAlertMessage,
} from "components/store/actions/shared/alertActions";
import { useDispatch } from "react-redux";
import { broadcastAnnouncement } from "components/store/actions/shared/announcementActions";
import { appInsights } from "helpers/appInsights";
import { JourneyType } from "views/reservation/ReservationViewType";

const YesNoDialog = (props) => {
  const { t } = useTranslation();
  const yesButtonRef = React.useRef<HTMLButtonElement>(null);

  const dispatch = useDispatch();
  React.useEffect(() => {
    if (yesButtonRef.current != null && !props.disableAutoFocus) yesButtonRef.current.focus();
  }, []);

  const OTAReservationNumberRegex = new RegExp("^[0-9]+COUNT$");

  const cancelReservation = (
    journeyUId,
    isReservation,
    reference,
    productUId
  ) => {
    props.action();
    props.handleLoading(true);

    var isSV =
      productUId?.toUpperCase().toString() === JourneyType.specialityVehicle;

    var endPoint = isSV
      ? "svreservation/cancel/" + reference
      : "reservation/cancel?journeyId=" +
        journeyUId +
        "&isReservation=" +
        isReservation;

    axiosInstance
      .post(endPoint)
      .then((response) => {
        props.handleLoading(false);
        if (!isReservation) {
          // journey
          if (response.data.success) {
            appInsights.trackEvent({
              name: "Cancelled Journey",
              properties: { Reference: props.reference },
            });
            dispatch(
              showSuccessAlertMessage(
                t("CancelJourneySuccessMessageFormat", {
                  Reference: response.data.message.replace("COUNT", ""),
                })
              )
            );
            dispatch(
              broadcastAnnouncement(
                t("CancelJourneySuccessMessageFormat", {
                  Reference: response.data.message.replace("COUNT", ""),
                })
              )
            );
            props.handleCancelJourneySuccessful();
          } else {
            dispatch(
              showErrorAlertMessage(
                t("CancelJourneyFailureMessageFormat", {
                  Reference: response.data.message.replace("COUNT", ""),
                })
              )
            );
            dispatch(
              broadcastAnnouncement(
                t("CancelJourneyFailureMessageFormat", {
                  Reference: response.data.message.replace("COUNT", ""),
                })
              )
            );
          }
        } else {
          if (response.data.success) {
            dispatch(
              showSuccessAlertMessage(
                isSV
                  ? t("CancelSVReservationSuccessMessageFormat", {
                      Reference: response.data.message.replace("COUNT", ""),
                    })
                  : t("CancelReservationSuccessMessageFormat", {
                      Reference: response.data.message.replace("COUNT", ""),
                    })
              )
            );
            dispatch(
              broadcastAnnouncement(
                isSV
                  ? t("CancelSVReservationSuccessMessageFormat", {
                      Reference: response.data.message.replace("COUNT", ""),
                    })
                  : t("CancelReservationSuccessMessageFormat", {
                      Reference: response.data.message.replace("COUNT", ""),
                    })
              )
            );
            props.handleCancelJourneySuccessful();
          } else {
            if (OTAReservationNumberRegex.test(response.data.message)) {
              dispatch(
                showErrorAlertMessage(
                  t("CancelReservationContactBranchFormat", {
                    Reference1: response.data.message.replace("COUNT", ""),
                    Reference2: response.data.data.locationName,
                    Reference3: response.data.data.formattedPhoneNumber,
                  })
                )
              );
              dispatch(
                broadcastAnnouncement(
                  t("CancelReservationContactBranchFormat", {
                    Reference1: response.data.message.replace("COUNT", ""),
                    Reference2: response.data.data.locationName,
                    Reference3: response.data.data.formattedPhoneNumber,
                  })
                )
              );
            } else {
              if (
                response.data.message ==
                "AmendReservationLoyaltyNameMismatchErrorFormat"
              ) {
                dispatch(
                  showErrorAlertMessage(
                    t("AmendReservationLoyaltyNameMismatchErrorFormat", {
                      Reference: response.data.data.formattedPhoneNumber,
                    })
                  )
                );
                dispatch(
                  broadcastAnnouncement(
                    t("AmendReservationLoyaltyNameMismatchErrorFormat", {
                      Reference: response.data.data.formattedPhoneNumber,
                    })
                  )
                );
              } else if (response.data.message == "ERR20049") {
                dispatch(showErrorAlertMessage(t("ERR20049")));
                dispatch(broadcastAnnouncement(t("ERR20049")));
              } else if (response.data.message == "ERR20040") {
                dispatch(showErrorAlertMessage(t("ERR20040")));
                dispatch(broadcastAnnouncement(t("ERR20040")));
              } else if (response.data.message == "ERR20020") {
                dispatch(showErrorAlertMessage(t("ERR20020")));
                dispatch(broadcastAnnouncement(t("ERR20020")));
              } else if (
                response.data.message == "CustomErrorGenericErrorMsg"
              ) {
                dispatch(
                  showErrorAlertMessage(t("CustomErrorGenericErrorMsg"))
                );
                dispatch(
                  broadcastAnnouncement(t("CustomErrorGenericErrorMsg"))
                );
              } else {
                dispatch(showErrorAlertMessage(response.data.message));
                dispatch(broadcastAnnouncement(response.data.message));
              }
            }
          }
        }
        window.scroll(0, 0);
      })
      .catch((error) => {
        props.handleLoading(false);
        if (error.response) {
          dispatch(showErrorAlertMessage(error.response.data.message));
        } else if (error.request) {
          console.log("error.request", error.request);
        } else if (error.message) {
          console.log("error.message", error.message);
        }
      });
  };

  return (
    <>
      <DialogTitle data-testid="yesNoDialog" id="yesno-dialog-title">
        <div>
          <Typography variant="h2">
            {" "}
            {props.hasReservation
              ? t("cancelResConfirmationMessage")
              : t("cancelJourneyConfirmationMessage")}{" "}
          </Typography>
        </div>
      </DialogTitle>
      <Divider
        style={{
          marginLeft: 7,
          marginRight: 7,
          backgroundColor: "black",
          height: "0.8px",
        }}
      />

      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          id="yesbutton"
          ref={yesButtonRef}
          onClick={() =>
            cancelReservation(
              props.journeyUId,
              props.hasReservation,
              props.reference,
              props.productUId
            )
          }
        >
          {t("Yes")}
        </Button>
        <Button
          variant="outlined"
          id="nobutton"
          onClick={props.action}
          color="primary"
        >
          {t("No")}
        </Button>
      </DialogActions>
    </>
  );
};
export default YesNoDialog;
