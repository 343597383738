import * as Yup from "yup";

import { authService } from "services/authService";

export const isBrandSelected = (carClassInfo) => {
  // console.log("CarClass Value: ", carClassInfo);
  if (carClassInfo && carClassInfo !== null) {
    for (var i = 0; i < carClassInfo.carsInformation.classInfo.length; i++) {
      for (
        var j = 0;
        j < carClassInfo.carsInformation.classInfo[i].brandInfo.length;
        j++
      ) {
        if (carClassInfo.carsInformation.classInfo[i].brandInfo[j].isSelected) {
          return true;
        }
      }
    }
  }
  return false;
};
const Today = () => {
  const today = new Date();
  today.setDate(today.getDate() - 1);
  today.setHours(23, 59, 59);
  return today;
};

export const drVehicleValidationSchema = Yup.object().shape({
  carClass: Yup.object()    
    .test(
      "vehicle-not-null",
      "ReservationWizard.reservationPage.msgSelectVehicle",
      (value) => { console.log("validating CarClasses: ", value); return isBrandSelected(value) }
    ),
});
