import React, { useEffect, useState } from "react";
import {
  Backdrop,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Link,
  Paper,
  TextField,
  Typography,
  Box,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import { ReservationProps } from "../../reservationType";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from "helpers/axiosInstance";
import {
  JourneyProfilerProductViewModel,
  JourneyType,
} from "views/reservation/ReservationViewType";
import { useTranslation } from "react-i18next";
import { getEnumKeyByEnumValue } from "helpers/enumMapping";
import { authService } from "services/authService";
import {
  getcompanyCountryCurrency,
  getLocaleAmount,
} from "helpers/localization";
import MRProduct from "Assets/Images/MRProduct.png";
import CCProduct from "Assets/Images/CCProduct.png";
import CSProduct from "Assets/Images/CSProduct.png";
import DRProduct from "Assets/Images/DRProduct.png";
import CarClubLogo from "Assets/Images/CarClubLogo.png";
import CarShareLogo from "Assets/Images/CarShareLogo.png";
import orangeEexclamation from "Assets/Images/orange_exclamation_icon.png";
import {
  showSuccessAlertMessage,
  clearAlertMessage,
  showErrorAlertMessage,
  showInfoAlertMessage,
} from "components/store/actions/shared/alertActions";
import { broadcastAnnouncement } from "components/store/actions/shared/announcementActions";
import { appInsights } from "helpers/appInsights";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { resetReservation } from "components/store/actions/reservation/reservationActions";
import {
  ReAuthenticateCarClub,
  reAuthenticationStatus,
} from "services/carClubReAuth";
import {
  CarClubDriverState,
  driverDetails,
  SearchMatchType,
} from "../Driver/carClubDriverType";
import {
  carClubDriverDetails,
  resetCarClubDriverDetails,
} from "components/store/actions/reservation/carclubActions";
import { DriverInfo } from "../JourneyStart/journeyInputType";
import { GetCarClubDrivers } from "services/carClubDriverService";
import { AreStringsEqual, isNullOrUndefined } from "helpers/stringHelpers";

import { AppState } from "components/store/reducers/rootReducer";
import { decodeEmailIfEncoded } from "helpers/emailHelpers";
import { getUserPermissionsStatus, Permissions } from "helpers/getUserPermissions";
import { resetDriverDetails } from "components/store/slicers/driverDetailSlice";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    "& > *": {
      margin: theme.spacing(3),
    },
    "& .MuiFormLabel-root": {
      fontSize: "0.75rem",
    },
  },
  paper: {
    padding: theme.spacing(3),
    border: "1px solid #006639",
    // '&:hover': {
    //   border: "2px solid brown"},
    "&:focus": {
      border: "3px solid #006639",
    },
  },
  imageframe: {
    maxWidth: 236,
    //height: 80,
  },
  img: {
    margin: "15px",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
    "&:focus": {
      border: "3px solid #006639",
    },
  },

  //   imageAnchor: focus img{
  //     border: 2px solid blue;
  // }
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
  centerColumn: {
    "@media (min-width:360px)": {
      borderRight: "3px dotted black",
    },
  },
  customHoverFocus: {
    "&.Mui-focusVisible": { backgroundColor: "#17ED4B" },
    "&:hover": { backgroundColor: "#17ED4B" },
  },
}));

export const DarkerDisabledTextField = withStyles({
  root: {
    marginRight: 8,
    "& .MuiInputBase-root.Mui-disabled": {
      color: "#767676",
    },
  },
})(TextField);

const ProductSelectionForm: React.FC<ReservationProps> = (props) => {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const { t } = useTranslation();
  const locale = authService.getUserLocale();
  const companyCountryCode = authService.getUserCompanyCountryCode();

  const [isCCLoggedIn, setIsCCLoggedIn] = useState(
    JSON.parse(localStorage.getItem("ccLoggedIn") ?? "false") ?? []
  );

  useEffect(() => {
    window.localStorage.setItem("ccLoggedIn", JSON.stringify(isCCLoggedIn));
  }, [isCCLoggedIn]);

  const { companyInfo } = useSelector((state: AppState) => state.companyInfo);

  const takoApiEnv = authService.getTakoApiEnv();
  const isCarShareReservation = String(takoApiEnv).toUpperCase().includes("CS");

  const ccPasswordResetLink = companyInfo?.ccPasswordResetLink;
  const csPasswordResetLink = companyInfo?.csPasswordResetLink;

  const isccReauthExpired =
    !isCCLoggedIn || authService.getUserccReauthExpired();
  const isccPersisted = isCCLoggedIn && authService.getUserccPersisted();
  const titleRef = React.useRef<HTMLDivElement>(null);
  const ccMemIdRef = React.useRef<HTMLInputElement>(null);
  const ccPwdRef = React.useRef<HTMLInputElement>(null);
  const [ccDriverId, setccDriverId] = React.useState(
    authService.getUserDriverId()
  );
  const [ccMemberId, setccMemberId] = React.useState(
    authService.getUserCarClubCompanyId()
  );
  const [isccMemIdError, setisccMemIdError] = React.useState(false);
  const [isccMemIdSaved, setisccMemIdSaved] = React.useState(
    !!authService.getUserDriverId()
  );
  const [isPasswordDisabled, setisPasswordDisabled] = React.useState(
    !isccReauthExpired && isccPersisted
  );
  const [isccMemIdEmpty, setisccMemIdEmpty] = React.useState(
    !authService.getUserDriverId()
  );
  const [ccKeepMeSignedin, setccKeepMeSignedin] = React.useState(isccPersisted);
  const [ccPwd, setCCPwd] = React.useState(isccReauthExpired ? "" : "********");
  const [isReauth, setisReauth] = React.useState(false);
  const [isccPwdError, setisccPwdError] = React.useState(false);
  const [isccPwdEmpty, setisccPwdEmpty] = React.useState(true);
  const driverInfo: DriverInfo = authService.getDriverInfo();
  const isDriverValidityActivated = useSelector(
    (state: AppState) =>
      state.companyInfo.companyInfo?.isDriverValidityActivated
  );
  const isSharedAccessEnabled = props.products?.find(
    (i) => i.productUId.toUpperCase() === JourneyType.carClub
  )?.isSharedAccessEnabled;

  const delay = (ms) => new Promise((res) => setTimeout(res, ms));
  const dispatch = useDispatch();
  //const co2rate = useCo2Dict();
  const hasUserViewOnlyPermission = getUserPermissionsStatus( authService.getUserPermissions(),Permissions.ViewOnly);

  useEffect(() => {
    //dispatch(clearAlertMessage());
    dispatch(resetCarClubDriverDetails);
    if (titleRef.current !== null) titleRef.current.focus();
    if (props.focusCCPwd && ccPwdRef.current !== null) ccPwdRef.current.focus();
  }, []);

  useEffect(() => {
    if (isPasswordDisabled) {
      setCCPwd("********");
      setisccPwdError(false);
    } else {
      setCCPwd("");
      if (isccMemIdEmpty && ccMemIdRef.current != null)
        ccMemIdRef.current.focus();
      else if (ccPwdRef.current != null) ccPwdRef.current.focus();
    }
  }, [isPasswordDisabled]);

  const handleContinue = async (
    product: JourneyProfilerProductViewModel,
    mode: number
  ) => {
    console.log("Products", loading);
    var model = {
      JourneyProfileUId: props.journeyUId,
      IsCarShare: mode === 3,
      ValidDriverId: false,
      Product: product,
    };
    setLoading(true);
    await axiosInstance
      .post("/journeyassessment/product", model)
      .then(async (response) => {
        if (response.data.success) {
          switch (mode) {
            //MR - Milage Reimbursement
            case 1:
              await saveMileageReimbursement();
              break;
            //DR - Daily Rental
            case 2:
              dispatch(resetReservation());
              dispatch(resetDriverDetails());
              if (props.handleNext) props.handleNext(2, 0);
              break;
            //CC - Car Club
            case 3:
              if (props.handleNext) props.handleNext(3, 0);
              break;
          }
        }
      })
      .catch((error) => {
        console.log(error);
        if (
          error.response &&
          error.response.data &&
          error.response.data.messages &&
          error.response.data.messages.indexOf("DailyRentalNearestBranch") !==
            -1
        ) {
          var errormsg = error.response.data.messages.split("_")[0];
          var radius = error.response.data.messages.split("_")[1];
          dispatch(showErrorAlertMessage(t(errormsg, { Radius: radius })));
          dispatch(broadcastAnnouncement(t(errormsg, { Radius: radius })));
        } else {
          dispatch(showErrorAlertMessage(t("UnexpectedError")));
          dispatch(broadcastAnnouncement(t("UnexpectedError")));
        }
        appInsights.trackException({
          properties: {
            method: "handleContinue",
            Component: "Product",
          },
          exception: error,
          severityLevel: SeverityLevel.Error,
        });
        if (error.response) {
          appInsights.trackException({
            properties: {
              method: "handleContinue",
              Component: "Product",
            },
            exception: error.response,
            severityLevel: SeverityLevel.Error,
          });
        } else if (error.request) {
          console.log("error.request", error.request);
        } else if (error.message) {
          console.log("error.message", error.message);
        }
        window.scrollTo(0, 0);
        setLoading(false);
      });
  };
  async function saveMileageReimbursement() {
    if (!props.addFieldsEnabled) {
      console.log("MR AD fields unavailable");
      await axiosInstance
        .post(
          "/journeyassessment/savemilagereimbursement?journeyid=" +
            props.journeyUId
        )
        .then(async (response) => {
          if (response.data.success) {
            console.log("successfully saved MR");
            if (props.handleNext) props.handleNext(1, 0);
            setTimeout(() => {
              dispatch(showSuccessAlertMessage(t("Res.JourneyCreated")));
              dispatch(broadcastAnnouncement(t("Res.JourneyCreated")));
            }, 3000);
          } else console.error("There was an error in saving MR!");
        })
        .catch((error) => {
          appInsights.trackException({
            properties: {
              method: "saveMileageReimbursement",
              Component: "Product",
            },
            exception: error,
            severityLevel: SeverityLevel.Error,
          });
          console.error("There was an error!", error);
        });
    } else if (props.handleNext) props.handleNext(1, 0);
  }

  const buttonFocusedAndClickedReadout = (productName: string) => {
    if(hasUserViewOnlyPermission){
        dispatch(showErrorAlertMessage(t("ViewOnlyError")));
        dispatch(broadcastAnnouncement(t("ViewOnlyError")));
        window.scroll(0, 0);
      }else{
      dispatch(broadcastAnnouncement(""));
      setTimeout(() => {
        dispatch(
          broadcastAnnouncement(t("Res.continueForProduct") + productName)
        );
      }, 200);
    }
  };

  const PaperFocused = (productName: string) => {
    dispatch(broadcastAnnouncement(""));
  };

  const handleCCMemberIdChange = (params) => {
    if (params.target.value.length === 0) {
      setisccMemIdError(true);
    } else {
      setisccMemIdError(false);
      setisccMemIdEmpty(false);
    }
    setccDriverId(params.target.value);
  };

  const handleCCPasswordChange = (params) => {
    if (ccPwdRef.current != null) ccPwdRef.current.focus();
    if (params.target.value.length === 0 || params.target.value.length < 4) {
      setisccPwdError(true);
    } else {
      setisccPwdError(false);
      setisccPwdEmpty(false);
    }
    setCCPwd(params.target.value);
  };

  const handleCCPasswordKeyPress = (
    e,
    product: JourneyProfilerProductViewModel
  ) => {
    if (e.keyCode === 13) {
      AuthenticateCC(product);
    }
  };

  const ReAuthenticateCC = async (product: JourneyProfilerProductViewModel) => {
    dispatch(clearAlertMessage());
    setLoading(true);
    const reAuth = (await ReAuthenticateCarClub(
      ccMemberId.concat("-", ccDriverId)
    )) as reAuthenticationStatus;

    if (reAuth.reAuthenticated) {
      authService.setUserccPersisted(ccKeepMeSignedin);
      setIsCCLoggedIn(true);
      if (isSharedAccessEnabled) {
        var driverDetails: CarClubDriverState = await GetDrivers(
          reAuth.isAccountAuthority
        );
        dispatch(carClubDriverDetails(driverDetails));
        if (driverDetails.errors && driverDetails.hasError) {
          if (driverDetails.errors === "ERRMaxDrivers") {
            dispatch(showInfoAlertMessage(t(driverDetails.errors)));
          } else if (driverDetails.errors === "ERR500002") {
            dispatch(showErrorAlertMessage(t(driverDetails.errors)));
          } else {
            dispatch(showErrorAlertMessage(driverDetails.errors));
          }
          window.scrollTo(0, 0);
          setLoading(false);
        } else handleContinue(product, 3);
      } else handleContinue(product, 3);
    } else {
      setisReauth(true);
      setisPasswordDisabled(false);
      setLoading(false);
      setIsCCLoggedIn(false);
      if (ccPwdRef.current != null) ccPwdRef.current.focus();
    }
  };

  const AuthenticateCC = async (product: JourneyProfilerProductViewModel) => {
    dispatch(clearAlertMessage());

    buttonFocusedAndClickedReadout(product.productName);

    await delay(500);

    if (isccMemIdEmpty || isccMemIdError) {
      if (ccMemIdRef.current != null) ccMemIdRef.current.focus();
      setisccMemIdError(true);
    } else if (isccPwdEmpty || isccPwdError || loading) {
      if (ccPwdRef.current != null) ccPwdRef.current.focus();
      setisccPwdError(true);
    } else {
      setLoading(true);
      const result = axiosInstance
        .post("carclub/authenticate", {
          driverId: authService
            .getUserCarClubCompanyId()
            .concat("-", ccDriverId),
          driverPassword: ccPwd,
          IsCCPersisted: ccKeepMeSignedin,
        })
        .then(async (response) => {
          if (response.data.success) {
            authService.setUserccPersisted(ccKeepMeSignedin);
            authService.setUserDriverId(ccDriverId);
            setIsCCLoggedIn(true);
            if (isccReauthExpired) {
              authService.setUserccReauthExpired(
                response.data.credentials.isReAuthCodeExpired
              );
              setisPasswordDisabled(
                !response.data.credentials.isReAuthCodeExpired
              );
            }
            console.log("CC validation successful");
            //save CCMemID if it's not saved in profile
            if (!isccMemIdSaved) {
              SaveCarClubMemberID(ccDriverId);
            }
            if (isSharedAccessEnabled) {
              var driverDetails: CarClubDriverState = await GetDrivers(
                response.data.credentials.isAccountAuthority
              );
              dispatch(carClubDriverDetails(driverDetails));
              if (driverDetails.hasError && driverDetails.errors) {
                if (driverDetails.errors === "ERRMaxDrivers") {
                  dispatch(showInfoAlertMessage(t(driverDetails.errors)));
                } else if (driverDetails.errors === "ERR500002") {
                  dispatch(showErrorAlertMessage(t(driverDetails.errors)));
                } else {
                  dispatch(showErrorAlertMessage(driverDetails.errors));
                }
                window.scrollTo(0, 0);
                setLoading(false);
              } else {
                setLoading(true);
                handleContinue(product, 3);
              }
            } else handleContinue(product, 3);
          } else {
            setIsCCLoggedIn(false);
            dispatch(clearAlertMessage());
            setLoading(false);
            if (ccPwdRef.current != null) ccPwdRef.current.focus();
            if (
              response.data.message.trim() === "ERR20039" ||
              response.data.message.trim() === "ERRCCJail"
            ) {
              dispatch(
                showErrorAlertMessage(t(response.data.message.trim()))
              );
              dispatch(
                broadcastAnnouncement(t(response.data.message.trim()))
              );
            } else if (
              response.data.message.trim() === "UnexpectedError"
            ) {
              dispatch(showErrorAlertMessage(t("UnexpectedError")));
              dispatch(broadcastAnnouncement(t("UnexpectedError")));
            } else {
              dispatch(showErrorAlertMessage(response.data.message));
              dispatch(broadcastAnnouncement(response.data.message));
            }
          window.scrollTo(0, 0);

          }
        })
        .catch((error) => {
          dispatch(clearAlertMessage());
          setLoading(false);
          appInsights.trackException({
            properties: {
              method: "AuthenticateCC",
              Component: "Product",
            },
            exception: error,
            severityLevel: SeverityLevel.Error,
          });
           console.error("There is an error", error);

          window.scrollTo(0, 0);
        });
    }
  };

  const GetDrivers = async (isAccountAuthority: boolean) => {
    var drivers: driverDetails[] = [];
    var bookerFirstName = authService.getUserFirstName();
    var bookerLastName = authService.getUserLastName();
    var bookerEmail = authService.getUserEmail();
    let driverDetails: CarClubDriverState;

    //NON-BBC or primary is same as ETD User - display booker details
    //Book for myself
    if (
      !isDriverValidityActivated ||
      isNullOrUndefined(driverInfo.primaryDriver) ||
      (AreStringsEqual(driverInfo.primaryDriver?.lastName, bookerLastName) &&
        AreStringsEqual(
          decodeEmailIfEncoded(driverInfo.primaryDriver?.emailAddress),
          bookerEmail
        ))
    ) {
      drivers.push({
        id: authService.getUserCarClubCompanyId().concat("-", ccDriverId),
        firstName: bookerFirstName,
        lastName: bookerLastName,
        email: bookerEmail,
        isSelected: true,
      });
      driverDetails = {
        drivers: drivers,
        journeyEndDate: props.journeyDetails?.endDateTime,
        hasError: false,
        errors: undefined,
      };
    } else {
      //BBC and primary is different from ETD User & authenticated booker is not AA
      if (isAccountAuthority) {
        let driverSearch = {
          MatchType: SearchMatchType.exact,
          ContactLastName: driverInfo.primaryDriver?.lastName,
          ContactEmail: driverInfo.primaryDriver?.emailAddress,
        };
        driverDetails = (await GetCarClubDrivers(
          driverSearch
        )) as CarClubDriverState;
        driverDetails = {
          ...driverDetails,
          journeyEndDate: props.journeyDetails?.endDateTime,
        };
      } else
        driverDetails = {
          drivers: [],
          journeyEndDate: props.journeyDetails?.endDateTime,
          hasError: true,
          errors: "ERR500002",
        };
    }
    return driverDetails;
  };

  const SaveCarClubMemberID = async (ccDriver: string) => {
    dispatch(clearAlertMessage());
    setLoading(true);
    await axiosInstance
      .post(
        "identity/updateuserccdriverId?uid=" +
          authService.getUserName() +
          "&driverid=" +
          ccDriver
      )
      .then((response) => {
        if (response.data.success) {
          authService.setUserDriverId(ccDriver);
          setLoading(false);
          console.log("CarClub MemberID Saved.", response.data.data);
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        dispatch(clearAlertMessage());
        setLoading(false);
        if (error.response.status != null && error.response.status === 400) {
          dispatch(showErrorAlertMessage(error.response.data.message));
          dispatch(broadcastAnnouncement(error.response.data.message));
        } else console.error("There is an error", error);
      });
  };

  return (
    <Grid container>
      <Grid data-testid="ProductSelection" id="ProductSelection" item xs={12}>
        <Typography
          variant="h1"
          style={{
            marginBottom: "10px",
            marginLeft: "30px",
            marginTop: "20px",
          }}
        >
          {t("Res.productSelection")}
        </Typography>
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress />
        </Backdrop>
        {props.products && props.products.length > 0
          ? props.products.map(
              (product: JourneyProfilerProductViewModel, index: number) => {
                switch (product.productName) {
                  case "MileageReimbursement":
                    return (
                      <div className={classes.root} key={index}>
                        <Paper
                          className={classes.paper}
                          elevation={3}
                          square
                          onFocus={() => {
                            PaperFocused(product.productName);
                          }}
                        >
                          <Grid
                            item
                            xs
                            container
                            direction="column"
                            spacing={2}
                          >
                            <Grid container spacing={3}>
                              <Grid item className={classes.imageframe}>
                                <img
                                  className={classes.img}
                                  alt="" //{product.productName}
                                  src={MRProduct}
                                />
                              </Grid>
                              <Grid item xs>
                                <Typography
                                  gutterBottom
                                  variant="subtitle1"
                                  style={{ fontSize: "1.6em" }}
                                >
                                  {t(
                                    "rentalType." +
                                      getEnumKeyByEnumValue(
                                        JourneyType,
                                        product.productUId
                                      )
                                  ) || ""}
                                </Typography>
                                {product.descriptionList.map(
                                  (desc: string, index: number) =>
                                    desc.includes("Estimated cost £") ? (
                                      <Typography
                                        variant="body2"
                                        gutterBottom
                                        style={{ fontSize: "1.2em" }}
                                        key={index}
                                        id={
                                          "mileagereimbursementDescription" +
                                          index
                                        }
                                      >
                                        {t("Res.estimatedCost£", {
                                          Reference:
                                            getLocaleAmount(
                                              desc.replace(/[^0-9.-]+/g, ""),
                                              locale,
                                              true
                                            ) +
                                            " " +
                                            getcompanyCountryCurrency(
                                              companyCountryCode
                                            ),
                                        })}
                                      </Typography>
                                    ) : (
                                      ""
                                    )
                                )}
                              </Grid>
                            </Grid>
                            <Grid item>
                              {product.isSelectable ? (
                                <>
                                  <Divider
                                    style={{
                                      marginBottom: 20,
                                      height: "0.8px",
                                    }}
                                  />
                                  <div style={{ float: "right" }}>
                                    <Button
                                      variant="contained"
                                      style={{ textTransform: "uppercase" }}
                                      onClick={() => {
                                        buttonFocusedAndClickedReadout(
                                          product.productName
                                        );
                                        handleContinue(product, 1);
                                      }}
                                      onFocus={() => {
                                        buttonFocusedAndClickedReadout(
                                          product.productName
                                        );
                                      }}
                                      disabled={!product.isSelectable}
                                      color="primary"
                                      id="mileagereimbursementselecthyperlink"
                                    >
                                      {t("Continue")}
                                    </Button>
                                  </div>
                                </>
                              ) : (
                                <Typography
                                  id="MRNotSelectableMessage"
                                  variant="body2"
                                  tabIndex={0}
                                  style={{ fontSize: "1.2em" }}
                                >
                                  {product.notSelectableMessage.includes(
                                    "safety standards"
                                  )
                                    ? t(
                                        "Res.mileageReimbursementNotAvailableWithInvalidRiskAssessment"
                                      )
                                    : product.notSelectableMessage.includes(
                                        "travel policy"
                                      )
                                    ? t(
                                        "Res.mileageReimbursementNotAvailableWithValidRiskAssessment"
                                      )
                                    : product.notSelectableMessage}
                                </Typography>
                              )}
                            </Grid>
                          </Grid>
                        </Paper>
                      </div>
                    );

                  case "CarClub":
                  case "CarShare":
                    return (
                      <div
                        className={classes.root}
                        key={index}
                        hidden={props.hideCCTile}
                      >
                        <Paper
                          className={classes.paper}
                          elevation={3}
                          square
                          onFocus={() => {
                            PaperFocused(product.productName);
                          }}
                        >
                          <Grid item xs container spacing={2}>
                            <Grid container spacing={3} direction="row">
                              <Grid item xs={12} sm={3}>
                                <img
                                  className={classes.img}
                                  alt="" //{product.productName}
                                  src={isCarShareReservation ? CSProduct : CCProduct}
                                />
                              </Grid>
                              <Grid item xs={12} sm={6} container>
                                <Grid item xs container spacing={2}>
                                  <Grid
                                    item
                                    style={{
                                      minWidth: 100,
                                      maxWidth: 200,
                                      padding: 10,
                                    }}
                                  >
                                    <Typography>
                                      <img
                                        style={{
                                          display: "block",
                                          maxWidth: "100%",
                                          maxHeight: "100%",
                                          marginBottom: "10px",
                                        }}
                                        alt="Enterprise Car Club"
                                        id={"CarClubLogoImage" + index}
                                        src={isCarShareReservation ? CarShareLogo : CarClubLogo}
                                      />
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    container
                                    direction="column"
                                    style={{ minWidth: 110, padding: 10}}
                                    className={classes.centerColumn}
                                  >
                                    <Grid item style={{ paddingLeft :5}}>
                                      {product.descriptionList.map(
                                        (desc: string, index: number) =>
                                          desc.includes(
                                            "Estimated rental cost £"
                                          ) ? (
                                            <Typography
                                              variant="body2"
                                              gutterBottom
                                              style={{
                                                fontSize: "1.2em",
                                                marginBottom: "10px",
                                              }}
                                              key={index}
                                              id={"CarClubDescription" + index}
                                            >
                                              {t("Res.estimatedRentalCost£", {
                                                Reference:
                                                  getLocaleAmount(
                                                    desc.replace(
                                                      /[^0-9.-]+/g,
                                                      ""
                                                    ),
                                                    locale,
                                                    true
                                                  ) +
                                                  " " +
                                                  getcompanyCountryCurrency(
                                                    companyCountryCode
                                                  ),
                                              })}
                                            </Typography>
                                          ) : desc.includes(
                                              "Member ID and Password"
                                            ) ? (
                                            <Typography
                                              variant="body2"
                                              gutterBottom
                                              style={{
                                                fontSize: "1.2em",
                                                marginBottom: "10px",
                                              }}
                                              key={index}
                                              id={"CarClubDescription" + index}
                                            >
                                              {t(
                                                "Res.carClubEnterMemberIdAndPin"
                                              )}
                                            </Typography>
                                          ) : (
                                            <Typography
                                              variant="body2"
                                              gutterBottom
                                              style={{
                                                fontSize: "1.2em",
                                                marginBottom: "10px",
                                              }}
                                              key={index}
                                              id={"CarClubDescription" + index}
                                            >
                                              {isReauth
                                                ? t("Res.carClubReEnterPin")
                                                : t("Res.carClubEnterPin")}
                                            </Typography>
                                          )
                                      )}
                                    </Grid>
                                    <Grid container>
                                      <Grid
                                        item
                                        xs={12}
                                        md={12}
                                        direction="row"
                                        container
                                        style={{ marginBottom: 10 }}
                                      >
                                        <Grid item md={5} xs={12}>
                                          <Typography
                                            variant="body2"
                                            style={{
                                              fontSize: "1.1em",
                                              margin: "5px",
                                              fontWeight: "bold",
                                            }}
                                            id="carClubMemberId"
                                          >
                                            {t("Res.carClubMemberId")}
                                          </Typography>
                                        </Grid>
                                        <Grid item md={3} xs={12}>
                                          <Typography
                                            variant="body2"
                                            style={{
                                              fontSize: "1.1em",
                                              fontWeight: "bold",
                                              margin: "5px",
                                            }}
                                          >
                                            {ccMemberId + "   - "}
                                          </Typography>
                                        </Grid>
                                        <Grid item md={4} xs={12}>
                                          <DarkerDisabledTextField
                                            style={{
                                              marginRight: 10,
                                              minWidth: 90,
                                              maxWidth: 120,
                                            }}
                                            label={t("Res.carClubMemberId")}
                                            size="small"
                                            id="carClubDriverIdTextBox"
                                            defaultValue={ccDriverId}
                                            disabled={!!isccMemIdSaved}
                                            inputRef={ccMemIdRef}
                                            inputProps={{
                                              "aria-label": t(
                                                "Res.carClubMemberId"
                                              ),
                                              "aria-required": true,
                                            }}
                                            error={isccMemIdError}
                                            helperText={
                                              isccMemIdError
                                                ? t(
                                                    "Res.carclubMemberIDValidation"
                                                  )
                                                : ""
                                            }
                                            required
                                            variant="outlined"
                                            //onKeyDown={keyPress}
                                            onChange={handleCCMemberIdChange}
                                          />
                                        </Grid>
                                      </Grid>

                                      <Grid
                                        item
                                        xs={12}
                                        md={12}
                                        direction="row"
                                        container
                                      >
                                        <Grid item md={4} xs={12}>
                                          <Typography
                                            variant="body2"
                                            style={{
                                              fontSize: "1.1em",
                                              margin: "5px",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            {t("Res.carClubPin")}
                                          </Typography>
                                        </Grid>
                                        <Grid item md={4}></Grid>
                                        <Grid item md={4} xs={12}>
                                          <DarkerDisabledTextField
                                            style={{
                                              marginRight: 10,
                                              minWidth: 90,
                                              maxWidth: 120,
                                            }}
                                            id="carClubPinTextBox"
                                            label={t("Res.carClubPin")}
                                            type="password"
                                            size="small"
                                            inputRef={ccPwdRef}
                                            inputProps={{
                                              "aria-label": t("Res.carClubPin"),
                                              "aria-required": true,
                                            }}
                                            {...(isccPwdError && {
                                              "aria-describedby":
                                                "carClubPinTextBox-helper-text",
                                            })}
                                            value={ccPwd}
                                            error={isccPwdError}
                                            helperText={
                                              isccPwdError
                                                ? t(
                                                    "Res.carclubPasswordValidation"
                                                  )
                                                : ""
                                            }
                                            disabled={
                                              !product.isSelectable ||
                                              isPasswordDisabled
                                            }
                                            required
                                            variant="outlined"
                                            onKeyDown={(e) => {
                                              handleCCPasswordKeyPress(
                                                e,
                                                product
                                              );
                                            }}
                                            onChange={handleCCPasswordChange}
                                          />
                                        </Grid>
                                      </Grid>
                                      <Grid item xs={12} md={12}>
                                        <FormControlLabel
                                          id="Keep_me_signed_in"
                                          control={
                                            <Checkbox
                                              checked={ccKeepMeSignedin}
                                              id="chkKeepMeSignedIn"
                                              style={{
                                                margin: "5px",
                                              }}
                                              disabled={!product.isSelectable}
                                              onChange={(e) => {
                                                if (
                                                  isccPersisted &&
                                                  !isccReauthExpired
                                                )
                                                  setisPasswordDisabled(
                                                    !ccKeepMeSignedin
                                                  );
                                                setccKeepMeSignedin(
                                                  !ccKeepMeSignedin
                                                );
                                              }}
                                              inputProps={{
                                                "aria-label": t(
                                                  "Res.carClubKeepMeSignedIn"
                                                ),
                                              }}
                                            />
                                          }
                                          label={t("Res.carClubKeepMeSignedIn")}
                                          aria-label={t(
                                            "Res.carClubKeepMeSignedIn"
                                          )}
                                        />
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={3}
                                container
                                alignContent="center"
                              >
                                <Typography
                                  display="inline"
                                  variant="body2"
                                  style={{
                                    fontSize: "1.1em",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {t("Res.carClubNotMember")}
                                </Typography>
                                <Button
                                  variant="contained"
                                  style={{
                                    textTransform: "uppercase",
                                    backgroundColor: "#337AB7",
                                    color: "#ffffff",
                                  }}
                                  disabled={
                                    product.companyCarShareEnrollmentLink === ""
                                      ? true
                                      : false
                                  }
                                  target="_blank"
                                  href={product.companyCarShareEnrollmentLink}
                                  id="carclubregisterhyperlink"
                                  aria-describedby={"CarClubLogoImage" + index}
                                >
                                  {t("Res.registerNowText")}
                                  <Box
                                    component="span"
                                    className="visuallyHidden"
                                  >
                                    {t("Footer.OpenNewWindow")}
                                  </Box>
                                </Button>
                              </Grid>
                            </Grid>
                            <Grid
                              item
                              style={{ width: "100%", paddingTop: "15px" }}
                            >
                              {product.totalMinutes > 7 * 24 * 60 ? (
                                <Typography
                                  id="CCWarningMsg"
                                  variant="body2"
                                  tabIndex={0}
                                  style={{ fontSize: "1.2em" }}
                                >
                                  {
                                    //product.isPreffered is always true in ETD 2.0
                                    t("Res.carClub7DaysDisplayMessage")
                                  }
                                </Typography>
                              ) : product.totalMinutes < 30 ? (
                                <Typography
                                  id="CCWarningMsg"
                                  variant="body2"
                                  tabIndex={0}
                                  style={{ fontSize: "1.2em" }}
                                >
                                  {
                                    //product.isPreffered is always true in ETD 2.0
                                    t("Res.carClub30MinsDisplayMessage")
                                  }
                                </Typography>
                              ) : product.isSelectable ? (
                                <>
                                  <Divider
                                    style={{
                                      marginBottom: 20,
                                      height: "0.8px",
                                    }}
                                  />
                                  <Grid item sm container>
                                    <Grid item sm={1} xs={12}>
                                      <img
                                        alt="Attention"
                                        src={orangeEexclamation}
                                      />
                                    </Grid>
                                    <Grid
                                      item
                                      sm={11}
                                      xs={12}
                                      id="CCPasswordChangingMsg"
                                    >
                                      <Typography
                                        display="inline"
                                        variant="body2"
                                        tabIndex={0}
                                        style={{
                                          fontSize: "1.2em",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        {t("Res.pINToPasswordFormatPart1")}
                                      </Typography>
                                      <br/>
                                      <Typography
                                        display="inline"
                                        variant="body2"
                                        style={{ fontSize: "1.2em" }}
                                      >
                                        {t("Res.pINToPasswordFormatPart3")}
                                      </Typography>
                                      <Link
                                        id="carclubresetpwhyperlink"
                                        target="_blank"
                                        underline="always"
                                        href={ isCarShareReservation ? csPasswordResetLink : ccPasswordResetLink}
                                        display="inline"
                                        variant="body2"
                                        style={{ fontSize: "1.2em" }}
                                      >
                                        {t("Res.pINToPasswordFormatPart4")}
                                        <Box
                                          component="span"
                                          className="visuallyHidden"
                                        >
                                          {t("Footer.OpenNewWindow")}
                                        </Box>
                                      </Link>
                                    </Grid>
                                  </Grid>
                                  <div style={{ float: "right" }}>
                                    <Button
                                      variant="contained"
                                      style={{ textTransform: "uppercase" }}
                                      onClick={() => {
                                        if (
                                          !ccKeepMeSignedin ||
                                          isccReauthExpired ||
                                          !isccPersisted ||
                                          isReauth
                                        )
                                          AuthenticateCC(product);
                                        else ReAuthenticateCC(product);
                                      }}
                                      onFocus={() => {
                                        buttonFocusedAndClickedReadout(
                                          product.productName
                                        );
                                      }}
                                      disabled={
                                        !product.isSelectable || ccPwd === ""
                                      }
                                      color="primary"
                                      id="carclubselecthyperlink"
                                    >
                                      {t("Continue")}
                                    </Button>
                                  </div>
                                </>
                              ) : (
                                <Typography
                                  variant="body2"
                                  id="CCWarningMsg"
                                  tabIndex={0}
                                  style={{ fontSize: "1.2em" }}
                                >
                                  {
                                    //product.isPreffered is always true in ETD 2.0
                                    t("Res.carClubNotAvailable")
                                  }
                                </Typography>
                              )}
                            </Grid>
                          </Grid>
                        </Paper>
                      </div>
                    );

                  case "Daily Rental":
                    return (
                      <div className={classes.root} key={index}>
                        <Paper
                          className={classes.paper}
                          elevation={3}
                          square
                          onFocus={() => {
                            PaperFocused(product.productName);
                          }}
                        >
                          <Grid
                            item
                            xs
                            container
                            direction="column"
                            spacing={2}
                          >
                            <Grid container spacing={3}>
                              <Grid item className={classes.imageframe}>
                                <img
                                  className={classes.img}
                                  alt="" //{product.productName}
                                  src={DRProduct}
                                />
                              </Grid>
                              <Grid item xs>
                                <Typography
                                  gutterBottom
                                  variant="subtitle1"
                                  id={"DailyRentalHeading" + index}
                                  style={{ fontSize: "1.6em" }}
                                >
                                  {t(
                                    "rentalType." +
                                      getEnumKeyByEnumValue(
                                        JourneyType,
                                        product.productUId
                                      )
                                  ) || ""}
                                </Typography>
                                {product.descriptionList.map(
                                  (desc: string, index: number) =>
                                    desc.includes("Estimated rental cost £") ? (
                                      <Typography
                                        variant="body2"
                                        gutterBottom
                                        style={{ fontSize: "1.2em" }}
                                        key={index}
                                        id={"DailyRentalDescription" + index}
                                      >
                                        {t("Res.estimatedRentalCost£", {
                                          Reference:
                                            getLocaleAmount(
                                              desc.replace(/[^0-9.-]+/g, ""),
                                              locale,
                                              true
                                            ) +
                                            " " +
                                            getcompanyCountryCurrency(
                                              companyCountryCode
                                            ),
                                        })}
                                      </Typography>
                                    ) : (
                                      ""
                                    )
                                )}
                              </Grid>
                            </Grid>
                            <Grid item>
                              {product.isSelectable ? (
                                <>
                                  <Divider
                                    style={{
                                      marginBottom: 20,
                                      height: "0.8px",
                                    }}
                                  />
                                  <div style={{ float: "right" }}>
                                    <Button
                                      variant="contained"
                                      style={{ textTransform: "uppercase" }}
                                      onClick={() => {
                                        buttonFocusedAndClickedReadout(
                                          product.productName
                                        );
                                        handleContinue(product, 2);
                                      }}
                                      onFocus={() => {
                                        buttonFocusedAndClickedReadout(
                                          product.productName
                                        );
                                      }}
                                      disabled={!product.isSelectable}
                                      color="primary"
                                      id="dailyrentalselecthyperlink"
                                      aria-describedby={
                                        "DailyRentalHeading" + index
                                      }
                                    >
                                      {t("Continue")}
                                    </Button>
                                  </div>
                                </>
                              ) : (
                                <Typography
                                  variant="body2"
                                  tabIndex={0}
                                  style={{ fontSize: "1.2em" }}
                                >
                                  {t("Res.dailyRentalNotAvailable")}
                                </Typography>
                              )}
                            </Grid>
                          </Grid>
                        </Paper>
                      </div>
                    );

                  default:
                    return "";
                }
              }
            )
          : ""}
      </Grid>
    </Grid>
  );
};

export default ProductSelectionForm;
