import { Box, Grid, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  gridItem: {
    verticalAlign: "center",
    "& .MuiTypography-body2": {
      fontWeight: "bold",
      fontSize: "1.2em",
    },
  },
  labelText: {
    color: "#000",
    fontFamily: "Arial, Roboto, Segoe UI",
    fontSize: 14,
    fontWeight: "bold",
    verticalAlign: "middle",
  },
}));

const CarClubLegend = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid data-testid="carClubLegend" container>
      <Grid item xs={12} md={1} className={classes.gridItem}>
        <Typography variant="h2">
          {t("ReservationWizard.carClubPage.legendTitle")}
        </Typography>
      </Grid>
      <Grid item xs={12} md={2} className={classes.gridItem}>
        <Box display="flex" flexDirection="row" alignItems="flex-start">
          <Box width="20px" style={{ backgroundColor: "rgba(15,15,15,0.3)", marginTop: 2 }}>
            <span>&nbsp;</span>
          </Box>
          <span style={{ marginLeft: "5px", marginTop: 2 }}>
            {t("ReservationWizard.carClubPage.legendAvailable")}
          </span>
        </Box>
      </Grid>
      <Grid item xs={12} md={2} className={classes.gridItem}>
        <Box display="flex" flexDirection="row" alignItems="flex-start">
          <Box width="20px" style={{ backgroundColor: "rgba(255,0,0,1)", marginTop: 2 }}>
            <span>&nbsp;</span>
          </Box>
          <span style={{ marginLeft: "5px", marginTop: 2 }}>
            {t("ReservationWizard.carClubPage.legendUnavailable")}
          </span>
        </Box>
      </Grid>
      <Grid item xs={12} md={7} className={classes.gridItem}>
        <Box display="flex" flexDirection="row" alignItems="flex-start">
          <Box width="20px" style={{ backgroundColor: "rgba(0,128,0,0.6)", marginTop: 2 }}>
            <span>&nbsp;</span>
          </Box>
          <span style={{ marginLeft: "5px", marginTop: 2 }}>
            {t("ReservationWizard.carClubPage.legendRequestedTimeAvailable")}
          </span>
        </Box>
      </Grid>
    </Grid>
  );
};
export default CarClubLegend;
