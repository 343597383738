import Home from "./views/home/home";
import Users from "components/admin/users";
import Login from "components/home/login";
import SsoLogin from "components/home/ssologin";
import Logout from "components/home/logout";
import AddUser from "components/admin/adduser";
import BulkUploadUser from "components/admin/bulkupload/userBulkUpload";
import ViewUser from "components/admin/viewuser";
import MyProfile from "components/admin/profiles/myprofile";
import Companies from "components/admin/companies/companies";
import Unauthorised from "views/unauthorised";
import NotFound from "views/notfound";
import CorporateInformation from "views/CorporateInformation";
import TermsOfUse from "views/TermsOfUse";
import ContactUs from "views/ContactUs";
import LegalContactUs from "views/LegalContactUs";
import UserGuide from "views/UserGuide";
import LegalUserGuide from "views/LegalUserGuide";
import SummaryOfChanges from "views/SummaryOfChanges";
import LegalSummaryOfChanges from "views/LegalSummaryOfChanges";
import { userRoles } from "helpers/authTypes";
import EditDR from "views/reservation/EditReservationDR";
import ViewDR from "views/reservation/ViewReservationDR";
import Rebook from "views/reservation/RebookReservation";
import MyJourneys from "components/home/myJourneys";
import ViewMR from "views/reservation/ViewReservationMR";
import ViewJP from "views/reservation/ViewReservationJP";
import ViewCC from "views/reservation/ViewReservationCC";
import ViewSV from "views/reservation/ViewReservationSV";
import ReservationWizard from "components/Reservation/reservationWizard";
import ETDInstructionVideo from "views/ETDInstructionVideo";
import AllProfiles from "components/admin/profiles/allprofile";
import CarClubHome from "components/home/carClubHome";
export const componentRegistry = {
  Home: Home,
  Users: Users,
  Companies: Companies,
  Unauthorised: Unauthorised,
  NotFound: NotFound,
  CorporateInformation: CorporateInformation,
  TermsOfUse: TermsOfUse,
  ContactUs: ContactUs,
  UserGuide: UserGuide,
  LegalUserGuide: LegalUserGuide,
  SummaryOfChanges: SummaryOfChanges,
  LegalSummaryOfChanges: LegalSummaryOfChanges,
  LegalContactUs: LegalContactUs,
  ViewDR: ViewDR,
  ViewMR: ViewMR,
  ViewJP: ViewJP,
  ViewCC: ViewCC,
  ViewSV: ViewSV,
  EditDR: EditDR,
  Rebook: Rebook,
  MyJourneys: MyJourneys,
  AddUser: AddUser,
  ViewUser: ViewUser,
  BulkUploadUser: BulkUploadUser,
  MyProfile: MyProfile,
  AllProfile: AllProfiles,
  Login: Login,
  SsoLogin: SsoLogin,
  Logout: Logout,
  ReservationWizard: ReservationWizard,
  ETDInstructionVideo: ETDInstructionVideo,
  CarClubHome: CarClubHome,
};

export const routes = [
  {
    exact: true,
    path: "/sso",
    component: <SsoLogin />,
    needsAuth: false,
    requiredRoles: userRoles.all,
  },
  {
    exact: true,
    path: "/",
    component: <Login />,
    needsAuth: false,
    requiredRoles: userRoles.all,
  },
  {
    exact: true,
    path: "/home",
    component: <Home />,
    needsAuth: true,
    requiredRoles: userRoles.users,
  },
  {
    exact: true,
    path: "/admin/user/myprofile",
    component: <MyProfile />,
    needsAuth: true,
    requiredRoles: userRoles.users,
  },
  {
    exact: true,
    path: "/admin/user/allprofile",
    component: <AllProfiles />,
    needsAuth: true,
    requiredRoles: userRoles.users,
  },
  {
    exact: true,
    path: "/admin/companies",
    component: <Companies />,
    needsAuth: true,
    requiredRoles: userRoles.ehiAdmin,
  },
  {
    exact: true,
    path: "/admin/users",
    component: <Users />,
    needsAuth: true,
    requiredRoles: userRoles.admins,
  },
  {
    exact: true,
    path: "/termsofuse",
    component: <Home />,
    needsAuth: false,
    requiredRoles: userRoles.all,
  },
  {
    exact: true,
    path: "/LegalLoggedIn/CorporateInformation",
    component: <CorporateInformation />,
    needsAuth: false,
    requiredRoles: userRoles.all,
  },
  {
    exact: true,
    path: "/LegalAnonymous/TermsOfUse",
    component: <TermsOfUse />,
    needsAuth: false,
    requiredRoles: userRoles.all,
  },
  {
    exact: true,
    path: "/views/ETDInstructionVideo",
    component: <ETDInstructionVideo />,
    needsAuth: false,
    requiredRoles: userRoles.all,
  },
  {
    exact: true,
    path: "/CompanyProfile/ContactUs",
    component: <ContactUs />,
    needsAuth: false,
    requiredRoles: userRoles.all,
  },
  {
    exact: true,
    path: "/views/home/userGuide",
    component: <UserGuide />,
    needsAuth: false,
    requiredRoles: userRoles.all,
  },
  {
    exact: true,
    path: "/LegalAnonymous/userGuide",
    component: <LegalUserGuide />,
    needsAuth: false,
    requiredRoles: userRoles.all,
  },
  {
    exact: true,
    path: "/views/home/summaryOfChanges",
    component: <SummaryOfChanges />,
    needsAuth: false,
    requiredRoles: userRoles.all,
  },
  {
    exact: true,
    path: "/LegalAnonymous/summaryOfChanges",
    component: <LegalSummaryOfChanges />,
    needsAuth: false,
    requiredRoles: userRoles.all,
  },

  {
    exact: true,
    path: "/LegalAnonymous/ITHelp",
    component: <LegalContactUs />,
    needsAuth: false,
    requiredRoles: userRoles.all,
  },
  {
    exact: true,
    path: "/reservation/ViewDR/:journeyID/:reference",
    component: <ViewDR />,
    needsAuth: true,
    requiredRoles: userRoles.all,
  },
  {
    exact: true,
    path: "/reservation/ViewMR/:journeyID/:reference",
    component: <ViewMR />,
    needsAuth: true,
    requiredRoles: userRoles.all,
  },
  {
    exact: true,
    path: "/reservation/ViewSV/:journeyID/:reference",
    component: <ViewSV />,
    needsAuth: true,
    requiredRoles: userRoles.all,
  },
  {
    exact: true,
    path: "/reservation/ViewJP/:journeyID/:reference",
    component: <ViewJP />,
    needsAuth: true,
    requiredRoles: userRoles.all,
  },
  {
    exact: true,
    path: "/reservation/ViewCC/:journeyID/:reference",
    component: <ViewCC />,
    needsAuth: true,
    requiredRoles: userRoles.all,
  },
  {
    exact: true,
    path: "/reservation/EditDR/:journeyID/:reference",
    component: <EditDR />,
    needsAuth: true,
    requiredRoles: userRoles.all,
  },
  {
    exact: true,
    path: "/reservation/Rebook",
    component: <Rebook />,
    needsAuth: true,
    requiredRoles: userRoles.all,
  },
  {
    exact: true,
    path: "/Journey",
    component: <MyJourneys />,
    needsAuth: true,
    requiredRoles: userRoles.users,
  },
  {
    exact: true,
    path: "/admin/user/adduser",
    component: <AddUser />,
    needsAuth: true,
    requiredRoles: userRoles.admins,
  },
  {
    exact: true,
    path: "/admin/user/viewuser",
    component: <ViewUser />,
    needsAuth: true,
    requiredRoles: userRoles.admins,
  },
  {
    exact: true,
    path: "/admin/user/bulkupload",
    component: <BulkUploadUser />,
    needsAuth: true,
    requiredRoles: userRoles.admins,
  },
  {
    exact: true,
    path: "/unauthorised",
    component: <Login />,
    needsAuth: false,
    requiredRoles: userRoles.all,
  },
  {
    exact: true,
    path: "/notfound",
    component: <NotFound />,
    needsAuth: false,
    requiredRoles: userRoles.all,
  },
  {
    exact: true,
    path: "/reservationwizard",
    component: <ReservationWizard />,
    needsAuth: false,
    requiredRoles: userRoles.all,
  },
  {
    exact: true,
    path: "/logout",
    component: <Logout />,
    needsAuth: false,
    requiredRoles: userRoles.all,
  },
  {
    exact: true,
    path: "/carclubhome",
    component: (
      <CarClubHome redirectTo="" focusCCPwd={true} hideCCTile={false} />
    ),
    needsAuth: true,
    requiredRoles: userRoles.users,
  },
];
