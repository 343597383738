export interface ManualCardStartActivity {
    url: string;
    activityUrn: string;   
    contextId: string;
    success:boolean;
  }

  export interface ManualCardActivityResult {
    data? : { cardDetails: CardDetails;
               cardAttributes: CardAttributes;   
              }
    errors: string;
    handleEdit?: () => void;
    hideEdit?: boolean;
    success:boolean;
  }

  export interface CardDetails {
    nameProvided: string;
    maskedCardNumber: string;   
    expiration: CardExpiration;    
  }
  export interface CardExpiration {
    lastValidMonth: number;
    year: number;   
  }

  export interface CardAttributes {
    cardBrand: string;
    cardIssuer: string;   
  }
  export const ManualCardStartActivityInitialState: ManualCardStartActivity = {
    url: "",
    activityUrn: "",
    contextId: "",
    success:false,
  };

  export interface Payment {
    activityUrn: string;
    contextId: string;
    cardNumber? : string;
    cardExpiry? : string;
    cardBrand? : string;   
  }

  export interface PaymentResultProps {
    cardNumber? : string;
    cardExpiry? : string;
    cardBrand? : string;    
  }
