import {
  AdditionalDriverViewModel,
  DriverDeclaration,
} from "components/Reservation/steps/Reservation/reservationFormType";
import { Formik, Field } from "formik";
import React, { useState, useEffect } from "react";
import { Box, Button, Grid, Typography, IconButton } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import AccessibleTooltip from "controls/AccessibleTooltip";
import CancelIcon from "@mui/icons-material/Cancel";
import InfoIcon from "@mui/icons-material/Info";
import { CheckSharp } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import DriverAutoComplete from "controls/formik/EtdDriverLookup";
import { useDispatch } from "react-redux";
import { broadcastAnnouncement } from "components/store/actions/shared/announcementActions";
import { clearAlertMessage, showInfoAlertMessage } from "components/store/actions/shared/alertActions";
import { additionalDriversValidationSchema } from "./AdditionalDriversValidation";

const AdditionalDrivers: React.FC<{
  primaryDriverDeclarationId: number;
  reservationEndDate?: string;
  initialDrivers?: DriverDeclaration[];
  onDataChange: (
    isValid: boolean,
    additionalDriver?: DriverDeclaration[]
  ) => void;
  hideDriverDeclarationId:boolean,
}> = ({
  primaryDriverDeclarationId,
  reservationEndDate,
  initialDrivers,
  onDataChange,
  hideDriverDeclarationId
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [enableAdditionalDriver, setEnableAdditionalDriver] = useState(true);
  const [isMobile, setIsMobile] = useState(false)
  const [enableAdditionalDriverBtn, setEnableAdditionalDriverBtn] =
    useState(true);
  const [additionalDriversReachLimit, setAdditionalDriversReachLimit] = useState(false);
  const [hasAdditionalDrivers, setHasAdditionalDrivers] = useState(false);
  const [additionalDriver, setAdditionalDriver] = React.useState<
    DriverDeclaration[] | undefined
  >(initialDrivers);
    const values: AdditionalDriverViewModel = {
    primaryDriverDeclarationId: primaryDriverDeclarationId,
    reservationEndDate: reservationEndDate,
    additionalDriver: initialDrivers,
  };

  const useStyles = makeStyles((theme) => ({
    gridItem: {
      verticalAlign: "center",
      padding: "10px",
      "& .MuiTypography-body2": {
        fontWeight: "bold",
        fontSize: "0.875rem",
      },
    },
    heading: {
      fontSize: "1rem",
      fontWeight: "bold",
    },
    labelText: {
      color: "#000",
      fontFamily: "Arial, Roboto, Segoe UI",
      fontSize: "0.875rem",
      fontWeight: "bold",
      verticalAlign: "middle",
    },
    errorWarning: {
      color: "#DF0000",
      fontSize: "0.75rem",
      marginLeft: "15px",
    },
    textarea: {
      resize: "both",
    },
    focused: { outline: "none" },
    placeHolder: {
      "& ::placeholder": {
        color: "#767676",
        opacity: "1",
      },
    },
  }));

  const classes = useStyles();

  const isValidDrivers = (drivers: DriverDeclaration[] | undefined): void => {
    if (drivers && drivers.length > 0) {
      if (drivers[0].lastName !== "" && drivers[0].emailAddress !== "") {
        setHasAdditionalDrivers(true);
        return;
      }
    }
    setHasAdditionalDrivers(false);
  };

  const deleteAdditionalDriver = (
    additionalDriver,
    touched,
    id,
    setFieldValue
  ) => {
    let newAdditionalDriverList = [...additionalDriver];
    newAdditionalDriverList.splice(id, 1);
    if (touched.additionalDriver && touched.additionalDriver[id]) {
      touched.additionalDriver[id] = false;
    }
    setFieldValue("additionalDriver", newAdditionalDriverList);
    setAdditionalDriver(newAdditionalDriverList);
    if(additionalDriversReachLimit && newAdditionalDriverList.length < 30){
       dispatch(clearAlertMessage());
       setAdditionalDriversReachLimit(false);
    }
    let isValid = additionalDriversValidationSchema(
      t,
      primaryDriverDeclarationId,
      reservationEndDate
    ).isValidSync({ ...values, additionalDriver: newAdditionalDriverList });
    onDataChange(isValid, newAdditionalDriverList);
    if (newAdditionalDriverList.length === 0) {
      setEnableAdditionalDriver(false);
      if (touched.additionalDriver) {
        delete touched.additionalDriver;
      }
    }
  };

  const isAdditionalDriverValid = (driverDeclarationExpiryDate, endDate) => {
    if (driverDeclarationExpiryDate) {
      let driverExpiryDate = new Date(
        driverDeclarationExpiryDate.toString().substring(0, 10)
      );
      let reservationEndDate = new Date(endDate);

      if (driverExpiryDate >= reservationEndDate) {
        return false;
      } else {
        return true;
      }
    }
    return false;
  };

  const isAdditionalDriverDuplicated = (
    additionalDriver,
    newAdditionalDriver,
    index,
    errors,
    primaryDriverDeclarationId
  ) => {
    if (additionalDriver) {
      for (let i = 0; i < additionalDriver.length; i++) {
        if (
          additionalDriver[i] &&
          additionalDriver[i] !== null &&
          newAdditionalDriver !== null
        ) {
          if (
            additionalDriver[i] &&
            additionalDriver[i].driverDeclarationId &&
            additionalDriver[i].driverDeclarationId ===
              newAdditionalDriver.driverDeclarationId &&
            i !== index
          ) {
            //console.log("Driver already in the list <!>");
            errors.additionalDriver = t(
              "journeyInput.msgDuplicateAdditionalDriver"
            );
            return true;
          } else if (
            additionalDriver[i] &&
            additionalDriver[i].driverDeclarationId &&
            additionalDriver[i].driverDeclarationId ===
              primaryDriverDeclarationId &&
            i === index
          ) {
            //console.log("Driver == primaryDriver <!>");
            errors.additionalDriver = t(
              "journeyInput.msgDuplicateAdditionalDriver"
            );
            return true;
          }
        }
      }
    }
    return false;
  };

  const handleResize = () => {
    if (window.innerWidth < 960) {
        setIsMobile(true)
    } else {
        setIsMobile(false)
    }
  }
  const invalidAdditionalDriver = (aD, values, index, errors, touched, notification ) => {
    //console.log("***errors = ",errors);
    if (
      errors &&
      errors.additionalDriver &&
      errors.additionalDriver[index] ===
        t("journeyInput.msgInvalidAdditionalDriver")
    ) {
      return true;
    }
    if (
      aD &&
      (aD === null || aD.firstName === "") &&
      touched.additionalDriver &&
      touched.additionalDriver[index] &&
      touched.additionalDriver &&
      touched.additionalDriver[index] === true
    ) {
      errors.additionalDriver = t("journeyInput.msgInvalidAdditionalDriver");
      return true;
    }
    if (
      aD &&
      isAdditionalDriverValid(
        aD.driverDeclarationExpiryDate,
        values.reservationEndDate
      ) &&
      touched.additionalDriver &&
      touched.additionalDriver[index] === true
    ) {
      //ExparyDate < endDate
      //console.log("<additionalDriverNoLongerValid>");
      errors.additionalDriver = t("journeyInput.msgInvalidAdditionalDriver");
      return true;
    } else if (
      isAdditionalDriverDuplicated(
        values.additionalDriver,
        aD,
        index,
        errors,
        values.primaryDriverDeclarationId
      ) &&
      touched.additionalDriver &&
      touched.additionalDriver[index] === true
    ) {
      //driver already Exist or == to primaryDriver
      //console.log("<additionalDriver-ALREADY-IN-THE-LIST>");
      return true;
    }
    if (aD && notification && aD.lastName === "" && aD.emailAddress === ""){
      return true;
    }
    return false;
  };

  const handleaddAdditionalDriverList = (additionalDriver, setFieldValue) => {
    setHasAdditionalDrivers(true);
    if (additionalDriver) {
      let newAdditionalDriverList;
      if(additionalDriver.length === 1 && additionalDriver[0].lastName === "" && additionalDriver[0].emailAddress === ""){
        newAdditionalDriverList = additionalDriver;
      }
      else{
        newAdditionalDriverList = [
          ...additionalDriver,
          {
            firstName: "",
            lastName: "",
            emailAddress: "",
            driverDeclarationExpiryDate: new Date(),
          },
        ];
      }
      setEnableAdditionalDriver(true);
      if (newAdditionalDriverList.length > 29) {
        dispatch(
          broadcastAnnouncement(
            t("journeyInput.msgAdditionalDriversReachLimit")
          )
        );
        dispatch(
          showInfoAlertMessage(t("journeyInput.msgAdditionalDriversReachLimit"))
        );
        window.scroll(0, 0);
        setAdditionalDriversReachLimit(true);
      } else {
        setFieldValue("additionalDriver", newAdditionalDriverList);
      }
      let isValid = additionalDriversValidationSchema(
        t,
        primaryDriverDeclarationId,
        reservationEndDate
      ).isValidSync({ ...values, additionalDriver: newAdditionalDriverList.length > 29 ? additionalDriver:  newAdditionalDriverList });
      onDataChange(isValid,  newAdditionalDriverList.length > 29 ? additionalDriver:  newAdditionalDriverList);
    } else {
      //additionalDriverList doesn't Exist
      let newAdditionalDriverList = [
        {
          firstName: "",
          lastName: "",
          emailAddress: "",
          driverDeclarationExpiryDate: new Date(),
        },
      ];
      setEnableAdditionalDriver(true);
      setFieldValue("additionalDriver", newAdditionalDriverList);
      let isValid = additionalDriversValidationSchema(
        t,
        primaryDriverDeclarationId,
        reservationEndDate
      ).isValidSync({ ...values, additionalDriver: newAdditionalDriverList });
      onDataChange(isValid, values.additionalDriver);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize)
    let isValid = additionalDriversValidationSchema(
      t,
      primaryDriverDeclarationId,
      reservationEndDate
    ).isValidSync(values);
    isValidDrivers(values.additionalDriver);
     if(hasAdditionalDrivers){
      setEnableAdditionalDriver(true);
      onDataChange(isValid, values.additionalDriver);
     }  
  }, []);

  return (
    <Formik
      initialValues={values}
      validateOnChange={true}
      validateOnBlur={true}
      validateOnMount={true}
      enableReinitialize={true}
      validationSchema={additionalDriversValidationSchema(
        t,
        primaryDriverDeclarationId,
        reservationEndDate
      )}
      onSubmit={() => {}}
    >
      {({
        values,
        isValid,
        dirty,
        setFieldValue,
        setFieldTouched,
        touched,
        handleChange,
        handleBlur,
        errors,
        isSubmitting,
      }) => (
        <>
          {enableAdditionalDriver && hasAdditionalDrivers && (
            <>
              <Box
                className="paddingLeft1cm"
                style={{
                  marginTop: 30,
                  marginBottom: 5,
                  marginLeft: -30,
                  display: "inline-grid",
                }}
                fontWeight="fontWeightBold"
              >
                <Grid item xs={12}>
                  <div className="alignItemCentre">
                    <Typography
                      className="topLeftZeroPadding"
                      variant="h2"
                      id="additionalDriverLabel"
                      tabIndex={0}
                    >
                      {t("AdditionalDriver")}
                    </Typography>
                  </div>
                </Grid>
              </Box>

              {hasAdditionalDrivers && (
                <>
                  <Grid item xs={12} md={12} style={{ marginLeft: 0 }}>
                    {values.additionalDriver?.map((aD, index) => (
                      <div key={index} style={{ marginTop: 10 }}>
                        <Grid container>
                          <Grid item xs={11}>
                            <Box
                              id={"AdditionalDriverStatus" + (index + 1)}
                              display="flex"
                              flexDirection="row"
                              alignItems="flex-start"
                              style={{ float: "right" }}
                            >
                              {aD ? (
                                invalidAdditionalDriver(
                                  aD,
                                  values,
                                  index,
                                  errors,
                                  touched,
                                  true
                                ) ? (
                                  ""
                                ) : (
                                  <CheckSharp
                                    style={{ color: "green", marginTop: 2 }}
                                  />
                                )
                              ) : (
                                ""
                              )}
                              {aD ? (
                                <span
                                  style={{
                                    color: invalidAdditionalDriver(
                                      aD,
                                      values,
                                      index,
                                      errors,
                                      touched,
                                      true
                                    )
                                      ? ""
                                      : "green",
                                    marginLeft: "5px",
                                    marginTop: 4,
                                  }}
                                >
                                  {invalidAdditionalDriver(
                                    aD,
                                    values,
                                    index,
                                    errors,
                                    touched,
                                    true
                                  )
                                    ? ""
                                    : t("journeyInput.validOption1")}
                                </span>
                              ) : (
                                ""
                              )}
                            </Box>
                          </Grid>
                          <Grid item xs={11} sm={11} md={11} lg={11}>
                            <Field
                              name={`additionalDriver.${index}`}
                              id={"additionalDriver" + (index + 1)}
                              placeholder={t("PrimaryDDNameEmailNumber")}
                              noMatchText={t(
                                "journeyInput.labelNoAdditionalDrivers"
                              )}
                              journeyEndDate={values.reservationEndDate}
                              allowClearOnEscape={true}
                              defaultValue={
                                aD && aD.driverDeclarationId
                                  ? aD
                                  : values.additionalDriver
                              }
                              fullWidth
                              isReadOnly={false}
                              style={{ paddingBottom: 0, paddingTop: 0 }}
                              Label={{ label: "Driver Declaration" }}
                              inputprops={{
                                "aria-label": t("AdditionalDriver"),
                                "aria-required": false,
                                "data-val-required": "Required",
                                "auto-complete": "false",
                              }}
                              component={DriverAutoComplete}
                              isMobileView={isMobile}
                              hideDriverDeclarationId={hideDriverDeclarationId}
                              onInputChange={(val) => {
                                setFieldTouched(
                                  `additionalDriver.${index}`,
                                  true,
                                  true
                                );
                                setAdditionalDriver(values.additionalDriver);
                              }}
                              setDriverSearch={(dd) => {
                                setFieldTouched(
                                  `additionalDriver.${index}`,
                                  true
                                );
                                setFieldValue(`additionalDriver.${index}`, dd);
                                setAdditionalDriver(values.additionalDriver);

                                if (values.additionalDriver?.length) {
                                  let newAdditionalDriverList = [
                                    ...values.additionalDriver,
                                  ];
                                  newAdditionalDriverList[index] = dd;
                                  let isValid =
                                    additionalDriversValidationSchema(
                                      t,
                                      primaryDriverDeclarationId,
                                      reservationEndDate
                                    ).isValidSync({
                                      ...values,
                                      additionalDriver: newAdditionalDriverList,
                                    });
                                  onDataChange(
                                    isValid,
                                    newAdditionalDriverList
                                  );
                                } else {
                                  let isValid =
                                    additionalDriversValidationSchema(
                                      t,
                                      primaryDriverDeclarationId,
                                      reservationEndDate
                                    ).isValidSync(values);
                                  onDataChange(
                                    isValid,
                                    values.additionalDriver
                                  );
                                }
                              }}
                              error={
                                touched.additionalDriver &&
                                errors?.additionalDriver &&
                                invalidAdditionalDriver(
                                  aD,
                                  values,
                                  index,
                                  errors,
                                  touched,
                                  false
                                )
                              }
                              aria-describedby="additionalDriverErrorMsg"
                              aria-labelledby="start"
                            />
                            {touched.additionalDriver &&
                            errors &&
                            errors.additionalDriver &&
                            invalidAdditionalDriver(
                              aD,
                              values,
                              index,
                              errors,
                              touched,
                              false
                            ) ? (
                              <span
                                className={classes.errorWarning}
                                style={{ marginLeft: 3 }}
                                id={"additionalDriverErrorMsg" + (index + 1)}
                              >
                                {t(errors?.additionalDriver.toString())}
                              </span>
                            ) : (
                              ""
                            )}
                          </Grid>
                          <Grid item xs={1} sm={1} md={1} lg={1}>
                            <div>
                              <AccessibleTooltip
                                text={t("deleteAdditionalDriver")}
                              >
                                <IconButton
                                  id={"infoIconDeleteAddDriver" + (index + 1)}
                                  aria-label={t("deleteAdditionalDriver")}
                                  style={{
                                    color: "#d02216",
                                    borderColor: "#547D66",
                                    marginTop: 3,
                                    marginLeft: -1,
                                  }}
                                  onClick={() => {
                                    deleteAdditionalDriver(
                                      values.additionalDriver,
                                      touched,
                                      index,
                                      setFieldValue
                                    );
                                  }}
                                  edge="end"
                                  size="large">
                                  <CancelIcon />
                                </IconButton>
                              </AccessibleTooltip>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    ))}
                  </Grid>
                </>
              )}
            </>
          )}
          {enableAdditionalDriverBtn && (
            <>
              <Grid item xs={12}>
                <Box
                  role="note"
                  className="alignItemCentreWithH2Font"
                  display="flex"
                  justifyContent="flex-end"
                >
                  <Button
                    id="addDriverButton"
                    title={t("journeyInput.addAdditionalDriver")}
                    style={{ textTransform: "none" }}
                    color="inherit"
                    disabled={false}
                    onClick={() => {
                      handleaddAdditionalDriverList(
                        values.additionalDriver,
                        setFieldValue
                      );
                    }}
                  >
                    {" "}
                    {t("addAdditionalDriverBtn")}
                  </Button>
                  <AccessibleTooltip
                    text={t("journeyInput.addAdditionalDriverTooltip")}
                  >
                    <span
                      role="note"
                      style={{
                        marginTop: 11,
                        marginBottom: 11,
                        display: "inline-grid",
                      }}
                    >
                      <div>
                        <IconButton
                          id="additionalDriverinfoIcon"
                          size="small"
                          color="primary"
                          style={{ paddingBottom: 8 }}
                          aria-label={t(
                            "journeyInput.addAdditionalDriverTooltip"
                          )}
                        >
                          <InfoIcon fontSize="small" />
                        </IconButton>
                      </div>
                    </span>
                  </AccessibleTooltip>
                </Box>
              </Grid>
            </>
          )}
        </>
      )}
    </Formik>
  );
};

export default AdditionalDrivers;
