import "./BookingTimeRangeSlider.css";
import withStyles from '@mui/styles/withStyles';
import Slider from "@mui/material/Slider";
import {
  BookingTimeRangeSliderChangeEvent,
  BookingTimeRangeSliderProperties,
} from "./models";
import {
  appendMinutes,
  ariaText,
  buildMarks,
  format,
  formattedLabel,
  isBetweenTwoDates,
  minutesBetweenTwoDates,
  timeRangeSliderStyle,
} from "./functions";
import React from "react";
import { Typography } from "@mui/material";
import { Opacity } from "@mui/icons-material";

export const BookingTimeRangeSlider = ({
  properties,
  onChange,
}: {
  properties: BookingTimeRangeSliderProperties;
  onChange?: (event: BookingTimeRangeSliderChangeEvent) => void;
}) => {
  const MaterialSlider = withStyles(
    timeRangeSliderStyle(properties.availabilityPeriods)
  )(Slider);

  const periodStartDate = properties.availabilityPeriods[0].startDateTime;
  const periodEndDate =
    properties.availabilityPeriods[properties.availabilityPeriods.length - 1]
      .startDateTime;

  const value = (dates: Date[], periodStartDate: Date) =>
    dates.map((date) => minutesBetweenTwoDates(periodStartDate, date));

  const [sliderValues, setSliderValues] = React.useState(
    value([properties.startDate, properties.endDate], periodStartDate)
  );

  const handleChange = (_: any, newValue: any) => {
    const meetsMinimumHireTime =
      newValue[1] - newValue[0] >= properties.config.minimumHireTimeMinutes;

    const startDate = appendMinutes(periodStartDate, newValue[0]);
    const endDate = appendMinutes(periodStartDate, newValue[1]);

    const valid =
      properties.availabilityPeriods
        .filter((period) => !period.isAvailable)
        .filter((period) =>
          isBetweenTwoDates(startDate, endDate, period.startDateTime)
        ).length === 0;

    if (onChange && meetsMinimumHireTime) {
      onChange({ startDate, endDate, valid });
    }

    return meetsMinimumHireTime && setSliderValues(newValue);
  };

  return (
    <>
      <div className="start-end-date">
        <div id={"paddedStartDate" + properties.index} style={{opacity:"0.8"}}>
         <Typography variant="caption" gutterBottom fontSize="small" >{format(periodStartDate, properties.config.dateFormat)}</Typography>
        </div>
        <div id={"paddedEndDate" + properties.index} style={{opacity:"0.8"}}>
        <Typography variant="caption" gutterBottom fontSize="small" >{format(periodEndDate, properties.config.dateFormat)}</Typography>
        </div>
      </div>
      <MaterialSlider
        sx={{marginTop:"3px"}}
        data-testid="bookingTimeRangeSlider"
        id={"timeRangeSlider" + properties.index}
        onChange={handleChange}
        value={sliderValues}
        getAriaLabel={(index) => (index === 0 ? "Start Time" : "End Time")}
        getAriaValueText={(stepValue) => ariaText(periodStartDate, stepValue)}
        step={properties.config.minutesPerStep}
        marks={buildMarks(
          periodStartDate,
          periodEndDate,
          properties.config.minutesPerStep,
          properties.config.dateFormat
        )}
        min={0}
        max={minutesBetweenTwoDates(periodStartDate, periodEndDate)}
        valueLabelDisplay="on"
        valueLabelFormat={(stepValue) =>
          formattedLabel(
            periodStartDate,
            stepValue,
            properties.config.dateFormat
          )
        }
        disabled={true}
      />
    </>
  );
};
