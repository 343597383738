import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface Co2RateState {
  co2dict: Map<string, string>;
}

const initialState: Co2RateState = {
  co2dict: new Map<string, string>(),
};

export const co2Slice = createSlice({
  name: "co2Dict",
  initialState,
  reducers: {
    updateCo2Dict: (_, action: PayloadAction<Co2RateState>) => ({
      co2dict: action.payload.co2dict,
    }),
    resetCo2Dict: () => initialState,
  },
});

export default co2Slice.reducer;
