import {
  Accordion,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import { clearAlertMessage, showErrorAlertMessage } from "components/store/actions/shared/alertActions";
import CompactJourneyDetailsView from "../Shared/compactJourneyDetailsView";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import React from "react";
import { useSpecialityRequest } from "components/store/reducers/reservation/specialitySelector";
import { SpecialityRequest, SpecialityRequestInitialValue, SpecialityRequestState, SpecialityVehicleType } from "./specialityRequestType";
import makeStyles from '@mui/styles/makeStyles';
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { Formik, Form, Field } from "formik";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { appInsights } from "helpers/appInsights";
import { SeverityLevel } from "@microsoft/applicationinsights-common";
import * as Yup from "yup";
import { getEnumKeyByEnumValue } from "helpers/enumMapping";
import { ReviewSpecialityRequest } from "services/SpecialityService";
import { updateSpecialityRequest } from "components/store/actions/reservation/specialityActions";
import { DropDownList } from "components/admin/companies/companiesType";
import { isNullOrWhitespace } from "helpers/stringHelpers";
import AdditionalInformationForm from "../Shared/additionalInformation";
import { AdditionalInformationViewModel } from "../AdditionalInformation/additionalInformationType";
import { broadcastAnnouncement } from "components/store/actions/shared/announcementActions";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  gridItem: {
    verticalAlign: "center",
    padding: "10px",
    "& .MuiTypography-body2": {
      fontWeight: "bold",
      fontSize: "0.875rem",
    },
  },
  heading: {
    fontSize: "1rem",
    fontWeight: "bold",
  },
  labelText: {
    color: "#000",
    fontFamily: "Arial, Roboto, Segoe UI",
    fontSize: "0.875rem",
    fontWeight: "bold",
    verticalAlign: "middle",
  },
  backdrop: {
    zIndex: 99999,
    color: "#fff",
  },
  errorWarning: {
    color: "#DF0000",
    fontSize: "0.75rem",
    marginLeft: "15px",
  },
  textarea: {
    resize: "both",
  },
  focused: { outline: "none" },
}));

const AccordionSummary = withStyles({
  root: {
    //  flexDirection: "column",
    backgroundColor: "#006639",
    fontWeight: "bold",
    color: "#fff",
    border: "2px solid #006639",
    "&.Mui-expanded": {
      minHeight: "48px",
    },
    "&.Mui-focused": {
      backgroundColor: "#006639",
    },
  },

  content: {
    marginBottom: 0,
    marginTop: 0,
    padding: 0,
    minHeight: "12px",
  },
  expandIcon: {
    marginRight: 0,
    paddingTop: 0,
    color: "#fff",
  },
})(MuiAccordionSummary);

const AccordionDetails = withStyles({
  root: {
    "& ::placeholder": {
      color: "#767676",
      opacity: "1",
    },
  },
})(MuiAccordionDetails);



export const reservationRequestValidationSchema = Yup.object().shape({ 
   vehicleTypeNotes: Yup.string()
    .required("SV.Errors.vehicleTypeRequired")
    .max(150, "SV.Errors.vehicleTypeMaxLength")
    .nullable(),  
   selectedSpecialityVehicleValue: Yup.string()
    .test("vehicle", "SV.Errors.vehicleCategoryRequired", (value) =>
      validateSelectField(value)
    )
    .required()
    .nullable(),
});

function validateSelectField(value) {
   console.log("VALUE=", value);
  if (
    typeof value=== "undefined" ||
    value === "" ||
    value === "00000000-0000-0000-0000-000000000000" ||
    value === null
  )
    return false;
  else return true;
}


const ReservationRequest: React.FC<any> = (props: any) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const classes = useStyles();
    const [loading, setLoading] = React.useState(false);
    const specialityRequest : SpecialityRequest = useSpecialityRequest() ?? SpecialityRequestInitialValue;
    const [request, setRequest] = React.useState<SpecialityRequest>(SpecialityRequestInitialValue);
    //let request : SpecialityRequest = JSON.parse(JSON.stringify(props.svRequest)); 
    const addInfo  = JSON.parse(JSON.stringify(request.reservation.additionalInformation)); 
    const titleRef = React.useRef<HTMLDivElement>(null);
    const formRef = React.useRef<any>(null);    
    const [additionalInfo, setAdditionalInfo] = React.useState<AdditionalInformationViewModel | null>({additionalInformation :addInfo});
    const [isValidAdditionalInfo, setIsValidAdditionalInfo] = React.useState<Boolean>(true);
    const [additionalInfoValidationResponse,setadditionalInfoValidationResponse] =
     React.useState<any>(null);

    React.useEffect(() => {
      setLoading(true);       
      setRequest(JSON.parse(JSON.stringify(specialityRequest)));
      if(specialityRequest.reservation.additionalInformation !== null)
      {
        setAdditionalInfo({additionalInformation : JSON.parse(JSON.stringify(specialityRequest.reservation.additionalInformation))}); 
        setIsValidAdditionalInfo(false); 
      }
      setLoading(false); 
    },[specialityRequest]);

    React.useEffect(() => {
      if (titleRef.current !== null) titleRef.current.focus();
      //Forcing a change in form to set dirty flag true for Edit Request           
      setTimeout(() => {
        if (formRef.current && !isNullOrWhitespace(formRef.current.values.vehicleTypeNotes)) {
          formRef.current.setFieldValue("vehicleTypeNotes",formRef.current.values.vehicleTypeNotes + " ");
          setIsValidAdditionalInfo(true); 
        }
      }, 100);
    },[]);
    
 
    const transformValues = (values: SpecialityRequest) => {
        let vehicles : DropDownList[] = []; 
        const index = values.specialityVehicles.findIndex(
        (vehicle) => vehicle.value === values.selectedSpecialityVehicleValue
        );
        if (index > -1)
          values.specialityVehicles.forEach((vehicle) => {
        vehicle = JSON.parse(JSON.stringify(vehicle));
        if (vehicle.value === values.selectedSpecialityVehicleValue) vehicle.selected = true;
        else vehicle.selected = false;
        vehicles.push(vehicle);
        });             
        var reviewValues : SpecialityRequest =  JSON.parse(JSON.stringify(values));
        reviewValues.specialityVehicles = vehicles; 
        reviewValues.vehicleTypeNotes = values.vehicleTypeNotes.trimEnd();  
        reviewValues.reservation.additionalInformation = additionalInfo?.additionalInformation ?? null; 
        return reviewValues; 
    }

    const onBookingRefChange = (additionalInfo : AdditionalInformationViewModel, isValid: boolean) => {
      //setAdditionalInfo(additionalInfo);
      //setRequest({...request, reservation:{...request.reservation, additionalInformation: additionalInfo }});
      //setRequest(prevState => ({...prevState, ...prevState.reservation, additionalInformation: additionalInfo}));
      setIsValidAdditionalInfo(isValid); 
    }
  
    return (
      <Formik
      enableReinitialize={true}
      initialValues={request}
      validateOnChange={true}
      validateOnBlur={false}
      validationSchema={reservationRequestValidationSchema}
      innerRef={formRef}
      onSubmit={async (values, { setFieldTouched, setSubmitting }) => {
        dispatch(clearAlertMessage());  
        setLoading(true); 
        appInsights.trackTrace({
          message: "sending SV request for validation",
          properties: { object: values, Component: "SV Reservation" },
          severityLevel: SeverityLevel.Information,
        });
        let reviewValues = transformValues(values);        
        console.log("submitted values:", reviewValues);
        setadditionalInfoValidationResponse(null);
        var svResponse = (await ReviewSpecialityRequest(reviewValues)  as SpecialityRequestState);
        console.log("svRes", svResponse); 
        setLoading(false); 
        if(svResponse && !svResponse.hasError)
        {
          dispatch(updateSpecialityRequest(svResponse));          
          if (props.handleNext) props.handleNext(0, 0);
        }
        else
        {
          if(svResponse && svResponse.hasError)
          {
            if (typeof svResponse.errors === "string") {
              dispatch(showErrorAlertMessage(svResponse.errors));
              dispatch(broadcastAnnouncement(svResponse.errors));
            } else {
              setadditionalInfoValidationResponse(svResponse.errors);
              let errorlist: string[] = [];
              if (Object.keys(svResponse.errors).length > 0) {
                for (const [key, value] of Object.entries(svResponse.errors)) {
                  if (
                    key === "unexpectedError" &&
                    Object.keys(svResponse.errors).length > 1
                  )
                    // remove the exception error if there are more than 1 errors in the list
                    continue;
                  else errorlist.push(value as string);
                  //console.log(value)
                }
                dispatch(showErrorAlertMessage(errorlist));
                dispatch(broadcastAnnouncement(errorlist.join(". ")));
              }
          }
        }
          else           
            dispatch(showErrorAlertMessage(t("CustomErrorGenericErrorMsg")));
        }   
        window.scrollTo(0, 0);
      }}>
      {({
        values,
        isValid,
        dirty,
        setFieldValue,
        setFieldTouched,
        touched,
        handleChange,
        errors,
        isSubmitting,
      }) => (
        <Box
          data-testid="reservationRequest"
          boxShadow={3}
          p={3}
          bgcolor="background.paper"
          style={{ width: "100%" }}>
          <Form style={{ width: "100%" }}>
          <Grid container>
              <div ref={titleRef} tabIndex={-1} className={classes.focused}>
                <Typography
                  variant="h1"
                  id="reservationRequestPageTitle" 
                  style={{
                    marginTop: 0,
                    marginBottom: "15px",
                  }}>
                  {t("ReservationWizard.reservationRequestPageTitle")}
                </Typography>
              </div>
              <Backdrop
                className={classes.backdrop}
                open={loading}>
                <CircularProgress />
              </Backdrop>
              <CompactJourneyDetailsView
                journeyUId={"00-00"}
                journeyDetails={props.journeyDetails}
              /> </Grid>
              <Accordion
                    style={{ width: "100%" }}
                    defaultExpanded
                    id="sectionVehicle">
                    <h2>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon sx={{color:"#FFFFFF"}}/>}
                        aria-controls="panel-vehicle"
                        id="vehicleRequirement"
                        className={classes.heading}>
                        {t("SV.labelSectionVehicle")}
                      </AccordionSummary>
                    </h2>
                    <AccordionDetails id="panel-vehicle">
                      <Grid container>
                        <Grid container direction="row">
                          <Grid
                            item
                            xs={12}
                            md={3}
                            className={classes.gridItem}>
                            <label
                              htmlFor={"vehicleCategory"}
                              className={classes.labelText}>
                              {t("SV.labelSelectVehicleCategory")}{" "}
                              *
                            </label>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={9}
                            className={classes.gridItem}>
                            <FormControl variant="outlined" fullWidth>
                              <Select
                              native
                              value={
                                values.selectedSpecialityVehicleValue
                                  ? values.selectedSpecialityVehicleValue
                                  : ""
                              }
                              fullWidth
                              inputProps={{
                                id: "specialityVehicle",
                                name: "specialityVehicle",
                                "aria-label": t(
                                  "specialityVehicle"
                                ),
                                "aria-haspopup": "listbox",
                              }}
                              title={t(
                                "SV.labelSelectVehicleCategory"
                              )}
                              name="specialityVehicle"
                              aria-describedby={"specialityVehicleError"}
                              error={
                                errors.selectedSpecialityVehicleValue !== undefined &&
                                touched.specialityVehicles
                                  ? true
                                  : false
                              }
                              onChange={(e) => {
                                  setFieldValue(
                                  "selectedSpecialityVehicleValue",
                                  e.target.value
                                );
                                setFieldTouched("selectedSpecialityVehicleValue");
                              }}
                              onBlur={(e) => {
                                setFieldTouched("selectedSpecialityVehicleValue");
                              }}>
                              {values.specialityVehicles &&
                                values.specialityVehicles?.map(
                                  (opt: any) => (
                                    <option key={opt.value} value={opt.value}>
                                      {t("SV.vehicleCategory." + getEnumKeyByEnumValue(SpecialityVehicleType, opt.value))}
                                    </option>
                                  )
                                )}
                            </Select></FormControl>
                            {errors.selectedSpecialityVehicleValue &&
                            touched.selectedSpecialityVehicleValue? (
                              <span
                                id="specialityVehicleError"
                                className={classes.errorWarning}>
                                {t(errors?.selectedSpecialityVehicleValue ?? "")}
                              </span>
                            ) : (
                              <span id="specialityVehicleError"></span>
                            )}
                         
                         </Grid>
                        </Grid>
                       <Grid container direction="row">
                        <Grid item xs={12} md={3} className={classes.gridItem}>
                          <label
                            htmlFor={"vehicleTypeNotes"}
                            className={classes.labelText}>
                            {t("SV.labelVehicleTypeNotes")}
                          </label>
                        </Grid>
                        <Grid item xs={12} md={9} className={classes.gridItem}>
                          <Field
                            name="vehicleTypeNotes"
                            id="vehicleTypeNotes"
                            value={values.vehicleTypeNotes ?? ""}
                            fullWidth
                            style={{ paddingBottom: 0, paddingTop: 0 }}
                            inputProps={{
                              "aria-label": t("SV.labelVehicleTypeNotes"),
                              className: classes.textarea, 
                              "aria-required": true,                             
                              "aria-describedby":"vehicleTypeNotesError",
                            }}
                            onChange={(e) =>{
                              setFieldValue(
                                "vehicleTypeNotes",
                                e.target.value ?? ""
                              );
                              setFieldTouched("vehicleTypeNotes");
                            }
                            }                          
                            onBlur={(e) => {
                              setFieldTouched("vehicleTypeNotes");
                            }}
                            component={TextField}
                            multiline                          
                            variant="outlined"
                            error={
                              errors.vehicleTypeNotes !== undefined
                            }
                            placeholder={t(
                              "SV.placeholderVehicleTypeNotes"
                            )}
                          />
                          {errors.vehicleTypeNotes ? (
                            <span id="vehicleTypeNotesError" className={classes.errorWarning}>
                              {t(errors.vehicleTypeNotes)}
                            </span>
                          ) : (
                            <span id="vehicleTypeNotesError">
                            </span>
                          )}
                        </Grid>
                          </Grid>                       
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                  {additionalInfo && additionalInfo?.additionalInformation !== null ? <Accordion
                    style={{ width: "100%" }}
                    defaultExpanded
                    id="sectionBookingReference">
                    <h2>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon sx={{color:"#FFFFFF"}}/>}
                        aria-controls="panel-BookingReference"
                        id="bookingReference"
                        className={classes.heading}>
                        {t("SV.labelSectionBookingRef")}
                      </AccordionSummary>
                    </h2>
                    <AccordionDetails id="panel-BookingReference">
                     <AdditionalInformationForm 
                      additionalInfo={additionalInfo} 
                      additionalInfoValidation = {additionalInfoValidationResponse}
                      onDataChange={ onBookingRefChange } />
                    </AccordionDetails>
                  </Accordion> : <></> }
              <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "1em",
              }}>
              <Button
                id="btnBack"
                color="primary"
                aria-label={t("lblBackToPreviousStep")}
                variant="contained"
                onClick={() => {
                  dispatch(clearAlertMessage());
                  if (props.handleBack) props.handleBack(0, 0);
                }}>
                {t("btnBack")}
              </Button>
              <Button
                id="btnSubmit"
                color="primary"
                variant="contained"
                aria-label={t("lblContinueToNextStep")}
                disabled={!isValid || !dirty || !isValidAdditionalInfo}
                type="submit">
                {t("btnContinue")}
              </Button>
            </div> </Form>   </Box>
      )}
    </Formik>
  );
};
export default ReservationRequest;