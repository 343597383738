import { isHiddenAdditionalParameters } from "helpers/additionalParameters";
import React, { useEffect, Fragment, useState, useCallback } from "react";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  Link,
  Select,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
  IconButton,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import InfoIcon from "@mui/icons-material/Info";
import withStyles from "@mui/styles/withStyles";
import { ReservationProps } from "../../reservationType";
import Accordion from "@mui/material/Accordion";
import {
  reservationDetailsValidationSchema,
  ValidatePhoneNumber,
  ValidateCountryCode,
  ValidateAltCountryCode,
  ValidateAltPhoneNumber,
  ValidateFlightNumber,
} from "./reservationDetailsValidationSchema";
import { applyAdditionalInformationValidationSchema } from "../AdditionalInformation/additionalInformationForm";
import {
  ReservationLocationsViewModel,
  ReservationObject,
  ReservationReasonForHireViewModel,
  resInitialValue,
  additionalInformationFormikFormError,
  additionalInformationFormikFormTouched,
  AdditionalInformationValidationResponse,
  reservationDriverInitData,
  ReservationDriver,
  DriverViewModel,
  DriverDeclaration,
  LoyaltyProgramType,
  ReservationLoyaltyViewModel,
} from "../Reservation/reservationFormType";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { Formik, Form, Field } from "formik";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { appInsights } from "helpers/appInsights";
import { SeverityLevel } from "@microsoft/applicationinsights-common";
import EtdDatePickerV2 from "controls/formik/EtdDatePickerV2";
import axiosInstance from "helpers/axiosInstance";
import { registerLocale } from "react-datepicker";
import de from "date-fns/locale/de";
import es from "date-fns/locale/es";
import fr from "date-fns/locale/fr";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "components/store/reducers/rootReducer";
import { updateReservation } from "components/store/actions/reservation/reservationActions";
import "react-datepicker/dist/react-datepicker.css";
import makeStyles from "@mui/styles/makeStyles";
import {
  clearAlertMessage,
  showErrorAlertMessage,
  showInfoAlertMessage,
} from "components/store/actions/shared/alertActions";

import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { broadcastAnnouncement } from "components/store/actions/shared/announcementActions";
import { authService } from "services/authService";
import moment from "moment";
import { getIntlDateFormatForLocale } from "helpers/localization";
import Divider from "@mui/material/Divider";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { getLocaleShortDate } from "helpers/localization";
import { displayDriverAccordion, displayBoboTabs } from "helpers/driverHelpers";
import AdditionalDrivers from "components/shared/AdditionalDrivers/AdditionalDrivers";
import { CheckSharp } from "@mui/icons-material";
import BOBOSomeone from "components/shared/BOBOSomeone/BOBOSomeone";
import DriverDetails from "components/shared/DriverDetails/DriverDetails";
import { maskEmail } from "helpers/emailHelpers";
import { getEnumKeyByEnumValue } from "helpers/enumMapping";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  gridItem: {
    verticalAlign: "center",
    padding: "10px",
    "& .MuiTypography-body2": {
      fontWeight: "bold",
      fontSize: "0.875rem",
    },
  },
  gridItemEmail: {
    verticalAlign: "center",
    wordBreak: "break-all",
    padding: "10px",
    "& .MuiTypography-body2": {
      fontWeight: "bold",
      fontSize: "0.875rem",
    },
  },
  gridItemWithMargin: {
    verticalAlign: "center",
    padding: "10px",
    margin: "10px auto 10px auto",
    "& .MuiTypography-body2": {
      fontWeight: "bold",
      fontSize: "0.875rem",
    },
  },
  heading: {
    fontSize: "1rem",
    fontWeight: "bold",
  },
  labelText: {
    color: "#000",
    fontFamily: "Arial, Roboto, Segoe UI",
    fontSize: "0.875rem",
    fontWeight: "bold",
    verticalAlign: "middle",
  },
  backdrop: {
    zIndex: 99999,
    color: "#fff",
  },
  divider: {
    margin: "10px",
    backgroundColor: "background.paper",
    height: "0.8px",
    width: "98%",
  },
  errorWarning: {
    color: "#DF0000",
    fontSize: "0.75rem",
    marginLeft: "15px",
  },
  textarea: {
    resize: "both",
  },
  focused: { outline: "none" },
  KeyboardArrow: {
    textAlign: "right",
    paddingTop: "10px",
    cursor: "pointer",
  },
  KeyboardArrowIcon: {
    color: "#006639",
  },
  KeyboardArrowText: {
    textAlign: "left",
    paddingTop: "13px",
    cursor: "pointer",
  },
  colorWarning: {
    color: "#DF0000",
  },
  colorSuccess: {
    color: "#006639",
  },
  activeButtonClass: {
    textTransform: "none",
    fontWeight: "bold",
    width: 400,
    height: 40,
    border: "2px solid #006639",
  },
  inActiveButtonClass: {
    textTransform: "none",
    width: 400,
    height: 40,
  },
  activeButtonClassSm: {
    textTransform: "none",
    fontWeight: "bold",
    width: 200,
    height: 40,
    border: "2px solid #006639",
  },
  inActiveButtonClassSm: {
    textTransform: "none",
    width: 200,
    height: 40,
  },
}));

const AccordionSummary = withStyles({
  root: {
    //  flexDirection: "column",
    backgroundColor: "#006639",
    fontWeight: "bold",
    color: "#fff",
    border: "2px solid #006639",
    "&$focused": {
      color: "#006639",
    },
    "&.Mui-expanded": {
      minHeight: "48px",
    },
    "&.Mui-focused": {
      backgroundColor: "#006639",
    },
  },

  content: {
    marginBottom: 0,
    marginTop: 0,
    padding: 0,
    minHeight: "12px",
  },
  expandIcon: {
    marginRight: 0,
    paddingTop: 0,
    color: "#fff",
  },
})(MuiAccordionSummary);

const AccordionDetails = withStyles({
  root: {
    "& ::placeholder": {
      color: "#767676",
      opacity: "1",
    },
  },
})(MuiAccordionDetails);

const ReservationForm: React.FC<ReservationProps> = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);
  const [isPageRendered, setIsPageRendered] = React.useState(false);
  const [loyaltyExpanded, setLoyaltyExpanded] = React.useState(false);
  const [loyaltyViewModel, setLoyaltyViewModel] =
    useState<ReservationLoyaltyViewModel>(resInitialValue.loyalty);
  const [driverExpanded, setDriverExpanded] = React.useState(false);
  const [notesExpanded, setNotesExpanded] = React.useState(false);
  const [bookingRefExpanded, setBookingRefExpanded] = React.useState(false);
  const [carClassLoading, setCarClassLoading] = React.useState(false);
  const [loadingReservationDriver, SetLoadingReservationDriver] =
    React.useState(false);
  const [loadingAccount, setLoadingAccount] = React.useState(false);
  const [validatingAccount, setValidatingAccount] = React.useState(false);
  const [additionalInfoData, setAdditionalInfoData] = React.useState<any>();
  const [
    additionalInfoValidationResponse,
    setadditionalInfoValidationResponse,
  ] = React.useState<AdditionalInformationValidationResponse | null>(null);
  const [resdata, setResData] =
    React.useState<ReservationObject>(resInitialValue);
  const [reservationDriverData, setReservationDriverData] =
    React.useState<ReservationDriver>(reservationDriverInitData);
  const [additionalDrivers, setAdditionalDrivers] = useState<
    DriverDeclaration[]
  >(resInitialValue.additionalDriver);
  const [boboSomeone, setBoboSomeone] = useState<DriverViewModel | null>(null);
  const [isValidAdditionalDrivers, setIsValidAdditionalDrivers] =
    useState<boolean>(true);
  const [driverViewModel, setDriverViewModel] = useState<DriverViewModel>(
    resInitialValue.driverViewModel
  );
  const [isValidBOBOSomeone, setisValidBOBOSomeone] = useState<boolean>(true);
  const [selectedReasonForHire, setselectedReasonForHire] =
    React.useState<ReservationReasonForHireViewModel>();
  const [locationsView, setlocationsView] =
    React.useState<ReservationLocationsViewModel>();
  const [accountChanged, setaccountChanged] = React.useState(false);
  const [formValidation, setFormValidation] = React.useState(
    reservationDetailsValidationSchema
  );
  const [isEditBooking, setIsEditBooking] = React.useState(false);
  const locale = authService.getUserLocale();
  const driverInfo = authService.getDriverInfo();
  const [primaryDriverDetailsToggle, setPrimaryDriverDetailsToggle] =
    useState<boolean>(false);
  const titleRef = React.useRef<HTMLDivElement>(null);
  registerLocale("de", de);
  registerLocale("es", es);
  registerLocale("fr", fr);
  const formRef = React.useRef<any>(null);
  let existingRes = useSelector((state: AppState) => state.dailyRental);
  const delay = (ms) => new Promise((res) => setTimeout(res, ms));
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const qsType = queryParams.get("type")?.toString();
  const [isConsentCountry, setIsConsentCountry] = React.useState(false);
  const [acceptConsent, setAcceptConsent] = React.useState(false);
  const [resType, setResType] = React.useState("0");
  const [isDirty, setIsDirty] = React.useState(false);
  const [isValidDriverViewModel, setIsValidDriverViewModel] =
    useState<Boolean>(true);
  const [isReservationDriverDataValid, setIsReservationDriverDataValid] =
    useState<boolean>(false);
  const [reservationDriverDataTouched, setReservationDriverDataTouched] =
    useState<boolean>(false);
  const [contactPhoneNumberHasError, setContactPhoneNumberHasError] =
    useState<boolean>(false);
  const [flightNumberHasError, setFlightNumberHasError] =
    useState<boolean>(false);
  const [
    contactPhoneSelectedCodeHasError,
    setContactPhoneSelectedCodeHasError,
  ] = useState<boolean>(false);
  const [alternatePhoneNumberHasError, setAlternatePhoneNumberHasError] =
    useState<boolean>(false);
  const [
    alternatePhoneSelectedCodeHasError,
    setAlternatePhoneSelectedCodeHasError,
  ] = useState<boolean>(false);
  const [driverDeclarationExpiryDate, setDriverDeclarationExpiryDate] =
    useState<string>("");
  const [isValidContinue, setIsValidContinue] = useState<boolean>(true);
  const [showSaveSuccessLabel, setShowSaveSuccessLabel] =
    useState<boolean>(false);
  const localeDateTimeFormat = getIntlDateFormatForLocale(locale);
  const [bookForMyself, setBookForMyself] = useState(true);
  const [showDriverAccordion, setShowDriverAccordion] = useState(false);
  const [showBoboTabs, setShowBoboTabs] = useState(false);
  const [userDetails, setUserDetails] = useState<DriverViewModel>();
  const theme = useTheme();
  const isSmXs = useMediaQuery(theme.breakpoints.down("lg"));

  const onClickPrimaryDriverDetails = () => {
    setPrimaryDriverDetailsToggle(!primaryDriverDetailsToggle);
  };

  function isNullOrWhitespace(input) {
    return !input || !input.trim();
  }

  const handleSave = () => {
    try {
      authService.setReservationDriver(reservationDriverData);
      setShowSaveSuccessLabel(true);
      setReservationDriverDataTouched(false);
    } catch {
      dispatch(
        showErrorAlertMessage(
          t("ReservationWizard.reservationPage.primaryDriverSaveFailure")
        )
      );
      dispatch(
        broadcastAnnouncement(
          t("ReservationWizard.reservationPage.primaryDriverSaveFailure")
        )
      );
      window.scroll(0, 0);
    }
  };
  const onAdditionalDriverChange = (
    isValid: boolean,
    additionalDriver?: DriverDeclaration[]
  ) => {
    setIsValidAdditionalDrivers(isValid);
    if (isValid) {
      setAdditionalDrivers(
        additionalDriver ?? resInitialValue.additionalDriver
      );
    }
  };
  const onBOBOUserChange = useCallback(
    (isValid: boolean, userDetails: DriverViewModel) => {
      console.log(
        "isValidBOBOSomeone",
        isValidBOBOSomeone,
        "userDetails",
        userDetails
      );
      setisValidBOBOSomeone(isValid);
      if (isValid) {
        setUserDetails(userDetails);
      } else {
        //update state although userDetails is invalid, so that loylaty fields can be updated
        if (
          userDetails.firstName &&
          userDetails.lastName &&
          userDetails.emailAddress
        ) {
          setUserDetails(userDetails);
        } else {
          setUserDetails(undefined);
        }
      }

      // if(isValid){
      //   setBoboSomeone(userDetails);
      // }
    },
    []
  );

  const onDriverDetailsChange = useCallback(
    (driverDetails: DriverViewModel, isValid: boolean) => {
      setIsValidDriverViewModel(isValid);
      if (isValid) {
        setDriverViewModel(driverDetails);
      }
    },
    []
  );

  const checkDriver = (
    isBOBOToggleEnabled: boolean,
    driverDeclaration: boolean,
    boboEnabled: boolean,
    userPermissions: number
  ) => {
    let driverAccordion = displayDriverAccordion(
      isBOBOToggleEnabled,
      driverDeclaration,
      boboEnabled,
      userPermissions
    );
    let boboTabs = displayBoboTabs(
      isBOBOToggleEnabled,
      driverDeclaration,
      boboEnabled,
      userPermissions
    );
    setShowDriverAccordion(driverAccordion);
    setShowBoboTabs(boboTabs);
    return { driverAccordion, boboTabs };
  };

  const setExpiryDate = (firstName, lastName, email) => {
    var searchCriteria = {
      FirstName: firstName,
      LastName: lastName,
      Email: email,
    };
    axiosInstance
      .post("/drivers/driverdeclaration", searchCriteria)
      .then((result) => {
        setDriverDeclarationExpiryDate(
          getLocaleShortDate(
            result.data?.driverDeclarationExpiryDate?.toString() ?? "",
            locale
          )
        );
      });
  };
  const formatPhoneNumber = (number) => {
    return number?.replace(/[\(\)]|null/g, " ").replace("+01", "+1") ?? "";
  };

  const getPrimaryDriverDetails = async (
    firstName,
    lastName,
    email,
    driverDeclarationId
  ) => {
    if (!firstName && !lastName && !email) return;
    const primaryDriverRequest = {
      FirstName: firstName,
      LastName: lastName,
      Email: email,
    };
    const reservationDriver = authService.getReservationDriver();
    if (reservationDriver !== null) {
      if (
        reservationDriver.firstName === firstName &&
        reservationDriver.lastName === lastName &&
        reservationDriver.emailAddress === email
      ) {
        setReservationDriverData(reservationDriver);
        return;
      } else {
        authService.resetReservationDriver();
      }
    }

    SetLoadingReservationDriver(true);
    var url = `reservation/reservationdriver`;
    await axiosInstance
      .post(url, primaryDriverRequest)
      .then((response) => {
        /* console.log(
          "Res data - getPrimaryDriverDetails: ",
          JSON.stringify(response?.data)
        ); */
        if (response?.data) {
          let data = response.data;
          if (
            data.emailAddress === null ||
            data.firstName === null ||
            data.lastName === null
          ) {
            data = {
              ...data,
              firstName: driverInfo?.primaryDriver?.firstName,
              lastName: driverInfo?.primaryDriver?.lastName,
              emailAddress: driverInfo?.primaryDriver?.emailAddress,
              driverDeclarationId:
                driverDeclarationId ??
                driverInfo?.primaryDriver?.driverDeclarationId,
            };
          }
          if (
            data.firstName === reservationDriver.firstName &&
            data.lastName === reservationDriver.lastName &&
            data.emailAddress === reservationDriver.emailAddress
          ) {
            if (reservationDriver?.contactPhoneNumber?.number !== null) {
              data = {
                ...data,
                contactPhoneNumber: { ...reservationDriver.contactPhoneNumber },
                contactPhoneNumberString:
                  reservationDriver.contactPhoneNumberString,
              };
            }
            if (reservationDriver?.alternatePhoneNumber?.number !== null) {
              data = {
                ...data,
                alternatePhoneNumber: {
                  ...reservationDriver.alternatePhoneNumber,
                },
                alternatePhoneNumberString:
                  reservationDriver.alternatePhoneNumberString,
              };
            }
          }
          setReservationDriverData(data);
          setContactPhoneSelectedCodeHasError(
            ValidateCountryCode(
              data.contactPhoneNumber.selectedInternationalDiallingCode
            ).isInValid
          );
          setContactPhoneNumberHasError(
            ValidatePhoneNumber(data.contactPhoneNumber.number).isInValid
          );
          setAlternatePhoneSelectedCodeHasError(
            ValidateAltCountryCode(
              data.alternatePhoneNumber.selectedInternationalDiallingCode,
              data.alternatePhoneNumber.number
            ).isInValidCode
          );
          setAlternatePhoneNumberHasError(
            ValidateAltCountryCode(
              data.alternatePhoneNumber.number,
              data.alternatePhoneNumber.number
            ).isInValidNumber
          );
          authService.setReservationDriver(data);
        }
        SetLoadingReservationDriver(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
        appInsights.trackException({
          exception: error,
          properties: {
            method: "getPrimaryDriverDetails",
            Component: "DR Reservation details",
          },
          severityLevel: SeverityLevel.Error,
        });
        SetLoadingReservationDriver(false);
      });
  };

  const getReservationDetails = async (id, restype) => {
    setLoading(true);
    if (existingRes.reservation === undefined) {
      let url = `reservationwizard/${
        restype === "1"
          ? "getmodifydetails"
          : restype === "2"
          ? "getrebookdetails"
          : "reservation"
      }/${id}`;
      await axiosInstance
        .get(url)
        .then((response) => {
          console.log("Res data: ", response.data.data);
          appInsights.trackTrace({
            message: "getting reservation details",
            properties: {
              reservation: response.data.data,
              Component: "Daily Rental",
              userId: authService.getUserName(),
              companyId: authService.getUserCompanyInternalId(),
            },
            severityLevel: SeverityLevel.Information,
          });
          const { driverAccordion, boboTabs } = checkDriver(
            resdata?.isBOBOToggleEnabled ?? false,
            resdata?.driverDeclaration ?? false,
            resdata?.boboEnabled ?? false,
            resdata?.userPermissions ?? 0
          );
          let isPrimaryDriver = driverAccordion === false && boboTabs === false;
          setResData({
            ...response.data.data,
            driverViewModel: {
              ...response.data.data.driverViewModel,
              isPrimaryDriver: isPrimaryDriver,
              savedEnterprisePlusNumber:
                response.data.data.loyalty.savedEnterprisePlusNumber ?? "",
              savedEmeraldClubNumber:
                response.data.data.loyalty.savedEmeraldClubNumber ?? "",
            },
            additionalDriver: driverInfo?.additionalDriver,
          });
          setLoyaltyViewModel(response.data.data.loyalty);
          setFlightNumberHasError(
            ValidateFlightNumber(
              resdata.flightNumberViewModel?.flightNumber ?? ""
            ).isInValid
          );
          if (restype === "1" || restype === "2") {
            setselectedReasonForHire(response.data.data.reasonForHire);
            setAdditionalInfoData(response.data.data.additionalInformation);
            setAdditionalDrivers(
              resdata?.additionalDriver ?? resInitialValue.additionalDriver
            );
            authService.setDriverInfo({
              ...driverInfo,
              additionalDriver: additionalDrivers,
            });
            if (response.data.data.additionalInformation !== undefined) {
              const newSchema = applyAdditionalInformationValidationSchema(
                response.data.data.additionalInformation
                  .additionalInformationFields,
                formValidation
              );
              setFormValidation(newSchema);
            }
            setlocationsView(response.data.data.locations);
            getIsConsentCountry(
              response.data.data.locations.startLocation.country
            );
            setDriverExpanded(true);
            setLoyaltyExpanded(true);
            setNotesExpanded(true);
            setBookingRefExpanded(true);
          }
          if (titleRef.current !== null) titleRef.current.focus();

          if (
            response.data.data.warnings &&
            response.data.data.warnings.length > 0
          ) {
            dispatch(showInfoAlertMessage(response.data.data.warnings));
            dispatch(broadcastAnnouncement(response.data.data.warnings));
            window.scrollTo(0, 0);
          }
        })
        .catch((error) => {
          console.error("There was an error!", error);
          appInsights.trackException({
            exception: error,
            properties: {
              method: "reservationdetails",
              Component: "DR Reservation details",
            },
            severityLevel: SeverityLevel.Error,
          });
        });
    } else {
      if (formRef.current) {
        formRef.current.resetForm();
      }
      if (existingRes.reservation !== null) {
        if (!existingRes.reservation.isBOBOBooking) {
          if (
            existingRes.reservation.driverViewModel?.isConsent !== null &&
            existingRes.reservation.driverViewModel?.isConsent !== undefined
          ) {
            setAcceptConsent(existingRes.reservation.driverViewModel.isConsent);
          }
        } else {
          if (
            existingRes.reservation.driverViewModelForBOBO?.isConsent !==
              null &&
            existingRes.reservation.driverViewModelForBOBO?.isConsent !==
              undefined
          ) {
            setAcceptConsent(
              existingRes.reservation.driverViewModelForBOBO.isConsent
            );
          }
        }
        setIsDirty(false);
        const { driverAccordion, boboTabs } = checkDriver(
          existingRes.reservation?.isBOBOToggleEnabled ?? false,
          existingRes.reservation?.driverDeclaration ?? false,
          existingRes.reservation?.boboEnabled ?? false,
          existingRes.reservation?.userPermissions ?? 0
        );
        let isPrimaryDriver = driverAccordion === false && boboTabs === false;
        setResData({
          ...existingRes.reservation,
          driverViewModel: {
            ...existingRes.reservation.driverViewModel,
            isPrimaryDriver: isPrimaryDriver,
            isConsent: isConsentCountry ? acceptConsent : true,
            savedEnterprisePlusNumber:
              existingRes.reservation.loyalty.savedEnterprisePlusNumber ?? "",
            savedEmeraldClubNumber:
              existingRes.reservation.loyalty.savedEmeraldClubNumber ?? "",
          },
          additionalDriver: driverInfo?.additionalDriver,
        });
        setLoyaltyViewModel(existingRes.reservation.loyalty);
        console.log("existingRes.reservation", {
          ...existingRes.reservation,
          driverViewModel: {
            ...existingRes.reservation.driverViewModel,
            isPrimaryDriver: isPrimaryDriver,
            isConsent: isConsentCountry ? acceptConsent : true,
          },
        });

        if (!existingRes.reservation.isBOBOBooking) {
          setBookForMyself(true);
        } else {
          setUserDetails(existingRes.reservation.driverViewModel);
          setBookForMyself(false);
        }

        if (!driverInfo?.primaryDriver) {
          getPrimaryDriverDetails(
            existingRes.reservation?.driverViewModel?.firstName,
            existingRes.reservation.driverViewModel?.lastName,
            existingRes.reservation.driverViewModel?.emailAddress,
            existingRes.reservation.driverViewModel?.driverDeclarationId
          );
          setExpiryDate(
            existingRes.reservation?.driverViewModel?.firstName,
            existingRes.reservation.driverViewModel?.lastName,
            existingRes.reservation.driverViewModel?.emailAddress
          );
        }

        setAdditionalDrivers(
          existingRes.reservation.additionalDriver ??
            resInitialValue.additionalDriver
        );
        var rfh = JSON.parse(
          JSON.stringify(existingRes.reservation.reasonForHire)
        );
        rfh.isSelectedAccountValid = true;
        setselectedReasonForHire(rfh);
        var addi = JSON.parse(
          JSON.stringify(existingRes.reservation.additionalInformation)
        ); //deep copy
        if (addi == null) {
          if (additionalInfoData == null) {
            getAdditionalInformation(
              existingRes.reservation.reasonForHire.accountUId
            );
          }
        } else {
          setAdditionalInfoData(addi);
          if (existingRes.reservation.additionalInformation !== undefined) {
            const newSchema = applyAdditionalInformationValidationSchema(
              existingRes.reservation.additionalInformation
                .additionalInformationFields,
              formValidation
            );
            setFormValidation(newSchema);
          }
        }

        const response2 = Object.assign({}, existingRes.reservation.locations);
        setlocationsView(existingRes.reservation.locations);
        await getIsConsentCountry(
          existingRes.reservation.locations?.startLocation.country
        );
        setDriverExpanded(true);
        setLoyaltyExpanded(true);
        setNotesExpanded(true);
        setBookingRefExpanded(true);
        await delay(500);

        if (titleRef.current !== null) titleRef.current.focus();

        if (existingRes.hasError) {
          if (Object.keys(existingRes.errors).length > 0) {
            let errors: string[] = [];
            for (const [key, value] of Object.entries(existingRes.errors)) {
              console.log(`${key}: ${value}`);
              errors.push(value);
            }
            //  let errors = convertToFormikError(
            //   err.response.data.errors,
            //    t
            //  );
            //  console.log("ERRS: ", errors);
            //  setErrors(errors);

            dispatch(showErrorAlertMessage(errors));
            dispatch(broadcastAnnouncement(errors.join(". ")));
            window.scrollTo(0, 0);
          }
        }
      }
    }
    setLoading(false);
  };

  const getIsConsentCountry = async (countryCode) => {
    console.log("fetching country valikdation");
    await axiosInstance
      .get(`/reservation/country/${countryCode}`)
      .then((response) => {
        if (response.data.success) {
          setIsConsentCountry(response.data.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    dispatch(clearAlertMessage());
    if (titleRef.current !== null) titleRef.current.focus();
    if (qsType !== null || qsType !== undefined) {
      setResType(qsType?.toString() || "");
      setIsDirty(!(qsType === "1" || qsType === "2"));
    }

    if (qsType === "1" || qsType === "2") {
      setisValidBOBOSomeone(true);
    }
    console.log("ResType = ", resType);
    getReservationDetails(props.journeyUId, qsType ?? "0");
    const reservationDriver = authService.getReservationDriver();
    if (
      driverInfo?.primaryDriver ||
      Object.keys(reservationDriver).length === 0 ||
      reservationDriver?.emailAddress === null ||
      reservationDriver?.firstName === null ||
      reservationDriver?.lastName === null
    ) {
      getPrimaryDriverDetails(
        driverInfo?.primaryDriver?.firstName,
        driverInfo?.primaryDriver?.lastName,
        driverInfo?.primaryDriver?.emailAddress,
        driverInfo?.primaryDriver?.driverDeclarationId
      );
    } else {
      setReservationDriverData(reservationDriver);
    }
    if (driverInfo?.primaryDriver?.driverDeclarationExpiryDate) {
      setDriverDeclarationExpiryDate(
        getLocaleShortDate(
          driverInfo?.primaryDriver?.driverDeclarationExpiryDate?.toString() ??
            "",
          locale
        )
      );
    }
    return () => {
      dispatch(clearAlertMessage());
    };
  }, []);

  useEffect(() => {
    const resType = qsType ?? "0";
    if (
      resType === "0" &&
      (existingRes.reservation === undefined || accountChanged)
    )
      displayAccountSpecificViews();
    if (
      formRef.current &&
      selectedReasonForHire &&
      (existingRes.reservation === undefined || accountChanged)
    ) {
      formRef.current.setFieldValue(
        "reasonForHire.accountUId",
        selectedReasonForHire.accountUId
      );
      formRef.current.setFieldValue(
        "reasonForHire.accountName",
        selectedReasonForHire.accountName
      );
      formRef.current.setFieldValue(
        "reasonForHire.accountNumber",
        selectedReasonForHire.accountNumber
      );
      formRef.current.setFieldValue(
        "reasonForHire.isSelectedAccountValid",
        selectedReasonForHire.isSelectedAccountValid
      );
      formRef.current.setFieldValue(
        "reasonForHire.brands",
        selectedReasonForHire.brands
      );
      formRef.current.setFieldValue("isAccountValidated", true);
    }
    if (selectedReasonForHire === undefined && formRef.current)
      formRef.current.setFieldValue("reasonForHire.accountUId", null);
  }, [selectedReasonForHire, resdata]);

  useEffect(() => {
    const { driverAccordion, boboTabs } = checkDriver(
      resdata?.isBOBOToggleEnabled ?? false,
      resdata?.driverDeclaration ?? false,
      resdata?.boboEnabled ?? false,
      resdata?.userPermissions ?? 0
    );
    let isPrimaryDriver = driverAccordion === false && boboTabs === false;
    if (
      contactPhoneNumberHasError ||
      alternatePhoneNumberHasError ||
      contactPhoneSelectedCodeHasError ||
      alternatePhoneSelectedCodeHasError
    ) {
      setPrimaryDriverDetailsToggle(true);
      setIsReservationDriverDataValid(false);
    } else {
      setIsReservationDriverDataValid(true);
    }

    if (
      (isPrimaryDriver &&
        (contactPhoneNumberHasError ||
          alternatePhoneNumberHasError ||
          contactPhoneSelectedCodeHasError ||
          alternatePhoneSelectedCodeHasError)) ||
      !(!isReservationDriverDataValid || !reservationDriverDataTouched)
    ) {
      setIsValidContinue(false);
    } else {
      setIsValidContinue(true);
    }
  }, [
    resdata,
    contactPhoneNumberHasError,
    alternatePhoneNumberHasError,
    contactPhoneSelectedCodeHasError,
    alternatePhoneSelectedCodeHasError,
    isReservationDriverDataValid,
    reservationDriverDataTouched,
  ]);

  useEffect(() => {
    console.log("useEffect driverViewModel, isPageRendered = ", isPageRendered);
    if (
      loyaltyViewModel.enableLoyalty &&
      loyaltyViewModel.loyaltyPrograms.length !== 1 &&
      resType !== "1" /* && resType !== "2" */
    ) {
      console.log("Run useEffect when driverViewModel updates");
      if (!isPageRendered) {
        setIsPageRendered(true);
      } else {
        if (!isDriverInfoMatchedMyProfileSingleCase(driverViewModel)) {
          //driver doesn't match user profile for loyalty, clear the input
          //clearLoyaltyFields(false);  //new request, no clear
        } else {
          //driver matches user profile for loyalty
          updateLoyaltyFields(
            false,
            driverViewModel.savedEnterprisePlusNumber,
            driverViewModel.savedEmeraldClubNumber
          );
        }
      }
    }
  }, [driverViewModel.lastName, driverViewModel.emailAddress]);

  useEffect(() => {
    console.log(
      "useEffect userDetails, isPageRendered = ",
      isPageRendered,
      "userDetails?.boboId=",
      userDetails?.boboId
    );
    if (
      loyaltyViewModel.enableLoyalty &&
      userDetails?.boboId &&
      loyaltyViewModel.loyaltyPrograms.length !== 1 &&
      resType !== "1" /* && resType !== "2" */
    ) {
      console.log("Run useEffect when userDetails updates");
      if (!isPageRendered) {
        setIsPageRendered(true);
      } else {
        updateLoyaltyFields(
          true,
          userDetails?.savedEnterprisePlusNumber,
          userDetails?.savedEmeraldClubNumber
        );
      }
    }
  }, [userDetails?.boboId]);

  useEffect(() => {
    if (additionalInfoData && additionalInfoData.additionalInformationFields) {
      for (var anAdditionalInfoData of additionalInfoData.additionalInformationFields) {
        if (
          anAdditionalInfoData.mandatory &&
          !anAdditionalInfoData.FieldValue
        ) {
          var continueButton = document.getElementById("btnSubmit");
          continueButton?.click();
          continue;
        }
      }
    }
  }, [additionalInfoData]);

  const clearLoyaltyFields = (
    resetSavedLoyaltyNumber: boolean,
    savedEnterprisePlusNumber?: string,
    savedEmeraldClubNumber?: string
  ) => {
    console.log("run clearLoyaltyFields");
    var updatedLoyalty: ReservationLoyaltyViewModel = { ...loyaltyViewModel };
    if (resetSavedLoyaltyNumber) {
      console.log("run reset in clearLoyaltyFields");
      updatedLoyalty = {
        ...updatedLoyalty,
        savedEnterprisePlusNumber: savedEnterprisePlusNumber ?? "",
        savedEmeraldClubNumber: savedEmeraldClubNumber ?? "",
      };
    }
    updatedLoyalty = {
      ...updatedLoyalty,
      selectedLoyaltyProgramValue: LoyaltyProgramType.pleaseSelect,
      selectedLoyaltyProgram: "",
      loyaltyId: "",
    };

    setLoyaltyViewModel(updatedLoyalty);
    formRef.current.setFieldValue("loyalty", updatedLoyalty);
  };

  const updateLoyaltyFields = (
    resetSavedLoyaltyNumber: boolean,
    savedEnterprisePlusNumber?: string,
    savedEmeraldClubNumber?: string
  ) => {
    console.log("run updateLoyaltyFields");
    var updatedLoyalty: ReservationLoyaltyViewModel = { ...loyaltyViewModel };
    if (resetSavedLoyaltyNumber) {
      console.log("run reset in updateLoyaltyFields");
      updatedLoyalty = {
        ...updatedLoyalty,
        savedEnterprisePlusNumber: savedEnterprisePlusNumber ?? "",
        savedEmeraldClubNumber: savedEmeraldClubNumber ?? "",
      };
    }
    //update loyalty program and number based on the profile of chosen driver
    if (savedEnterprisePlusNumber && !savedEmeraldClubNumber) {
      //only EnterprisePlus number available
      updatedLoyalty = {
        ...updatedLoyalty,
        selectedLoyaltyProgramValue: LoyaltyProgramType.ET,
        selectedLoyaltyProgram: "ET",
        loyaltyId: savedEnterprisePlusNumber,
      };
    } else if (!savedEnterprisePlusNumber && savedEmeraldClubNumber) {
      //only Emerald Club number available
      updatedLoyalty = {
        ...updatedLoyalty,
        selectedLoyaltyProgramValue: LoyaltyProgramType.ZL,
        selectedLoyaltyProgram: "ZL",
        loyaltyId: savedEmeraldClubNumber,
      };
    } else {
      //both loyalty numbers are available or no number saved
      updatedLoyalty = {
        ...updatedLoyalty,
        selectedLoyaltyProgramValue: LoyaltyProgramType.pleaseSelect,
        selectedLoyaltyProgram: "",
        loyaltyId: "",
      };
    }
    setLoyaltyViewModel(updatedLoyalty);
    formRef.current.setFieldValue("loyalty", updatedLoyalty);
  };

  const isDriverInfoMatchedMyProfileSingleCase = (
    currentDriver: DriverViewModel | undefined
  ) => {
    if (currentDriver) {
      return (
        authService.getUserLastName().toUpperCase() ===
          currentDriver.lastName.toUpperCase() &&
        authService.getUserEmail().toUpperCase() ===
          currentDriver.emailAddress.toUpperCase()
      );
    } else {
      return false;
    }
  };

  const isDriverInfoMatchedMyProfileAllCase = () => {
    if (showDriverAccordion || showBoboTabs) {
      //non-DriverDecleration
      if (bookForMyself) {
        //DriverAccordion or boboFreeText
        console.log("check book for myself or freetext");
        return isDriverInfoMatchedMyProfileSingleCase(driverViewModel);
      } else {
        //BoBoForSomeone, do not need check so always return true
        console.log("check book for Someone");
        return true;
      }
    } else {
      //DriverDecleration
      console.log("check book for Primary driver");
      return false;
    }
  };

  function convertToDate(dt: any) {
    if (dt === null || dt === "") return null;
    if (dt && typeof dt === "string" && dt.includes("-")) {
      let dateParts = dt.split("-");
      return new Date(+dateParts[0], Number(dateParts[1]) - 1, +dateParts[2]);
    }
    return moment(dt).format("YYYY-MM-DD");
  }

  const getAdditionalInformation = async (accountid) => {
    await axiosInstance
      .get(`reservationwizard/additioninformation/${accountid}`)
      .then((response) => {
        let addInfoReceived = response.data.data;
        console.log("Additional Info data: ", addInfoReceived);
        setAdditionalInfoData(addInfoReceived);
        //create dynamical Yup validation and apply it
        const newSchema = applyAdditionalInformationValidationSchema(
          response.data.data.additionalInformationFields,
          formValidation
        );
        setFormValidation(newSchema);

        //initial form prop after new account selection
        if (formRef.current) {
          formRef.current.setFieldValue(
            "additionalInformation",
            response.data.data
          );
          if (formRef.current.touched.additionalInformation !== undefined) {
            formRef.current.touched.additionalInformation.additionalInformationFields.fill(
              false
            );
          }
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
        appInsights.trackException({
          exception: error,
          properties: {
            method: "getAdditionalInformation",
            Component: "DR Reservation details",
          },
          severityLevel: SeverityLevel.Error,
        });
      });
  };

  const displayAccountSpecificViews = async () => {
    dispatch(clearAlertMessage());
    if (selectedReasonForHire) {
      setLoadingAccount(true);
      dispatch(broadcastAnnouncement(t("journeyMethod.loadingJourneyMethods")));
      await axiosInstance
        .get(
          `reservation/locations/` +
            resdata.locationsURL +
            `/${selectedReasonForHire?.accountUId}/${selectedReasonForHire?.brands}/${props.journeyUId}`
        )
        .then((response) => {
          const response2 = Object.assign({}, response.data);
          console.log("locationView: ", response.data);
          setlocationsView(response.data);
          setLoadingAccount(false);
          dispatch(
            broadcastAnnouncement(t("journeyMethod.loadedJourneyMethods"))
          );
        })
        .catch((error) => {
          dispatch(showErrorAlertMessage(t("UnexpectedError")));
          dispatch(broadcastAnnouncement(t("UnexpectedError")));
          console.error("There was an error!", error);
          setLoadingAccount(false);
        });
    }
  };

  const convertArrayToDictionary = (inputArray) => {
    let dicObject = {};
    if (inputArray && inputArray.length > 0) {
      for (var i = 0; i < inputArray.length; i++) {
        dicObject[inputArray[i].brand] = inputArray[i].value;
      }
    }
    return dicObject;
  };

  /*  useEffect(() => {
    if (isDirty) {
      getCarClasses();
    }
  }, [startMethod, endMethod, startLocInfo, endLocInfo]);
*/
  const handlePhoneNumberChange = (
    selectedInternationalDiallingCode,
    number
  ) => {
    return "".concat(
      "(+",
      typeof selectedInternationalDiallingCode === "string" &&
        selectedInternationalDiallingCode !== "+"
        ? selectedInternationalDiallingCode
        : "",
      ")",
      number
    );
  };
  const handleConsentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAcceptConsent(event.target.checked);
  };

  const getDriverName = (): string | undefined => {
    if (driverInfo?.primaryDriver) {
      return `${driverInfo.primaryDriver?.firstName} ${driverInfo.primaryDriver?.lastName}`;
    }
  };
  const getDriverEmail = (): string | undefined => {
    if (driverInfo?.primaryDriver) {
      return driverInfo.primaryDriver?.emailAddress;
    }
  };
  const getPrimaryDriverDeclaration = (
    resDriverDeclaration: DriverDeclaration
  ): DriverDeclaration => {
    let primaryDriverDeclaration = resDriverDeclaration;
    const reservationDriver = authService.getReservationDriver();
    const selectedPrimaryDriver =
      driverInfo?.primaryDriver as DriverDeclaration;

    if (selectedPrimaryDriver) {
      primaryDriverDeclaration = {
        ...primaryDriverDeclaration,
        ...selectedPrimaryDriver,
      };
    }
    if (reservationDriver.contactPhoneNumber) {
      primaryDriverDeclaration = {
        ...primaryDriverDeclaration,
        contactPhoneNumber: reservationDriver.contactPhoneNumber,
      };
    }
    if (reservationDriver.alternatePhoneNumber) {
      primaryDriverDeclaration = {
        ...primaryDriverDeclaration,
        alternatePhoneNumber: reservationDriver.alternatePhoneNumber,
      };
    }

    return primaryDriverDeclaration;
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={resdata}
      validateOnChange={true}
      validateOnMount={true}
      validateOnBlur={false}
      validationSchema={formValidation}
      innerRef={formRef}
      onSubmit={async (values, { setFieldTouched, setSubmitting }) => {
        dispatch(clearAlertMessage());
        setadditionalInfoValidationResponse(null);
        let url = `/reservationwizard/reservation/${
          resType === "2" ? "rebook" : "validate"
        }`;
        const reservationDriver = authService.getReservationDriver();
        if (!(showDriverAccordion || showBoboTabs)) {
          const primaryDriverDeclaration = getPrimaryDriverDeclaration(
            values.primaryDriverDeclaration as DriverDeclaration
          );
          values = {
            ...values,
            driverName: getDriverName() as string,
            driverEmail: getDriverEmail() as string,
            driverViewModel: {
              ...reservationDriver,
              isPrimaryDriver: true,
              isConsent: isConsentCountry ? acceptConsent : true,
            } as DriverViewModel,
            primaryDriverDeclaration: {
              ...values?.primaryDriverDeclaration,
              ...primaryDriverDeclaration,
            },
            additionalDriver:
              JSON.stringify(additionalDrivers) !==
              JSON.stringify(resInitialValue.additionalDriver)
                ? additionalDrivers
                : authService.getDriverInfo()?.additionalDriver,
          };
        }

        appInsights.trackTrace({
          message: "sending DR request for validation",
          properties: { object: values, Component: "DR Reservation" },
          severityLevel: SeverityLevel.Information,
        });

        if (isValidAdditionalDrivers) {
          const driverInfo = authService.getDriverInfo();
          if (
            JSON.stringify(additionalDrivers) !==
            JSON.stringify(resInitialValue.additionalDriver)
          ) {
            authService.setDriverInfo({
              ...driverInfo,
              additionalDriver: additionalDrivers,
            });
          }

          if (
            showBoboTabs &&
            !bookForMyself &&
            isValidBOBOSomeone &&
            boboSomeone
          ) {
            values = { ...values, driverViewModel: boboSomeone };
          }
        }

        if ((showDriverAccordion || showBoboTabs) && bookForMyself) {
          if (isValidDriverViewModel) {
            values = { ...values, driverViewModel: driverViewModel };
            values.driverViewModel = {
              ...values.driverViewModel,
              isConsent: isConsentCountry ? acceptConsent : true,
            };
          }
        }

        if (!bookForMyself) {
          console.log("its a bobo");
          values.driverEmail = userDetails?.emailAddress
            ? userDetails?.emailAddress
            : "";
          values.driverName =
            (userDetails?.firstName ? userDetails?.firstName : "") +
            " " +
            (userDetails?.lastName ? userDetails?.lastName : "");
          values.driverViewModelForBOBO =
            userDetails !== undefined
              ? {
                  ...userDetails,
                  isConsent: isConsentCountry ? acceptConsent : true,
                }
              : resInitialValue.driverViewModelForBOBO;
          values.isBOBOBooking = true;
        } else {
          values.isBOBOBooking = false;
        }

        const driverInfo = authService.getDriverInfo();
        if (
          !showDriverAccordion &&
          !showBoboTabs &&
          driverInfo?.primaryDriver?.driverDeclarationId
        ) {
          values = {
            ...values,
            driverViewModel: {
              ...values.driverViewModel,
              driverDeclarationId:
                driverInfo?.primaryDriver?.driverDeclarationId,
            },
          };
        }

        console.log("submitted values: ", values);

        await axiosInstance
          .post(url, values)
          .then((res) => {
            setSubmitting(false);
            console.log("validated..", res.data.data);
            if (res.data.success) {
              dispatch(
                updateReservation({
                  reservation: res.data.data,
                  hasError: false,
                  errors: [],
                })
              );
              if (props.handleNext) props.handleNext(0, 0);
            } else {
              console.log("Validation Error", res.data.errors);
              let errorlist: string[] = [];
              if (Object.keys(res.data.errors).length > 0) {
                for (const [key, value] of Object.entries(res.data.errors)) {
                  if (
                    key === "unexpectedError" &&
                    Object.keys(res.data.errors).length > 1
                  )
                    // remove the exception error if there are more than 1 errors in the list
                    continue;
                  else errorlist.push(value as string);
                  //console.log(value)
                }
                dispatch(showErrorAlertMessage(errorlist));
                dispatch(broadcastAnnouncement(errorlist.join(". ")));
              }
            }
          })
          .catch((err) => {
            setSubmitting(false);
            setadditionalInfoValidationResponse(err.response.data);
            if (err.response && err.response.data.messages) {
              if (Object.keys(err.response.data.errors).length > 0) {
                for (const [key, value] of Object.entries(
                  err.response.data.errors
                )) {
                  //  console.log(`${key}: ${value}`);
                  // errors;
                }
                //  let errors = convertToFormikError(
                //   err.response.data.errors,
                //    t
                //  );
                //  console.log("ERRS: ", errors);
                //  setErrors(errors);
              }
              let errorlist = err.response.data.messages.filter(
                (n) => n !== ""
              );
              const translatedErrorlist = errorlist
                .map(function (value) {
                  return t(value);
                })
                .join("\r\n");
              dispatch(showErrorAlertMessage(translatedErrorlist));
              dispatch(broadcastAnnouncement(translatedErrorlist));
            } else {
              dispatch(
                showErrorAlertMessage("unable to save the journey." + err)
              );
              dispatch(
                broadcastAnnouncement("unable to save the journey." + err)
              );
            }

            appInsights.trackException({
              exception: err,
              properties: { method: "onSubmit", Component: "DR Reservation" },
              severityLevel: SeverityLevel.Error,
            });
          });
        window.scrollTo(0, 0);
      }}
    >
      {({
        values,
        isValid,
        dirty,
        setFieldValue,
        setFieldTouched,
        touched,
        handleChange,
        errors,
        isSubmitting,
      }) => (
        <Box
          boxShadow={3}
          p={3}
          bgcolor="background.paper"
          style={{ width: "100%" }}
        >
          {loading && resdata ? (
            <div style={{ textAlign: "center" }}>{t("pleaseWait")}</div>
          ) : (
            <Grid container>
              <div ref={titleRef} tabIndex={-1} className={classes.focused}>
                <Typography
                  variant="h1"
                  style={{
                    marginTop: 0,
                    marginBottom: "15px",
                  }}
                  onClick={() => {
                    console.log(
                      "values=",
                      values,
                      "validateSchema=",
                      formValidation,
                      "errors=",
                      errors,
                      "touched=",
                      touched,
                      "isValid=",
                      isValid,
                      "Dirty=",
                      dirty,
                      "reservationDriverData for bbc=",
                      reservationDriverData,
                      "DriverViewModel for self/bobofreetext=",
                      driverViewModel,
                      "userDetails for boboSomeone=",
                      userDetails,
                      "loyaltyViewModel=",
                      loyaltyViewModel,
                      "resType=",
                      resType
                    );
                  }}
                >
                  {t("ReservationWizard.reservationDetailsPageTitle")}
                </Typography>
              </div>
              <Backdrop className={classes.backdrop} open={validatingAccount}>
                <Typography variant="h1">
                  {t("journeyMethod.validatingAccountMessage")}
                </Typography>
              </Backdrop>
              {/* need to refine */}
              <Backdrop
                className={classes.backdrop}
                open={
                  loading ||
                  loadingAccount ||
                  carClassLoading ||
                  isSubmitting ||
                  loadingReservationDriver
                }
              >
                <CircularProgress />
              </Backdrop>
              <Grid item xs={12} style={{ marginTop: 10, color: "#AB0808" }}>
                <Form style={{ width: "100%" }}>
                  <span style={{ fontWeight: "bold", color: "#000000" }}>
                    {t("lblRequiedFields")}
                  </span>
                  {resType === "1" && (
                    <Accordion
                      style={{ width: "100%" }}
                      defaultExpanded
                      id="sectionResNumber"
                    >
                      <h2>
                        <AccordionSummary
                          expandIcon={
                            <ExpandMoreIcon sx={{ color: "#FFFFFF" }} />
                          }
                          aria-controls="panel-resnumber"
                          id="resNumber"
                          className={classes.heading}
                        >
                          {t(
                            "ReservationWizard.reservationPage.labelSectionResNumber"
                          )}
                        </AccordionSummary>
                      </h2>
                      <AccordionDetails>
                        <Grid container>
                          <Grid container direction="row">
                            <Grid
                              item
                              xs={12}
                              md={3}
                              className={classes.gridItem}
                            >
                              <label
                                htmlFor={"resNumber"}
                                className={classes.labelText}
                              >
                                {t(
                                  "ReservationWizard.reservationPage.labelResNumber"
                                )}
                              </label>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={9}
                              className={classes.gridItem}
                            >
                              <Field
                                name="reservationNumber"
                                id="reservationNumber"
                                value={values.reservationNumberString}
                                fullWidth
                                style={{ paddingBottom: 0, paddingTop: 0 }}
                                component={TextField}
                                variant="outlined"
                                disabled={true}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  )}
                  <Accordion
                    style={{ width: "100%" }}
                    id="sectionDriverDetails"
                    expanded={driverExpanded}
                    onChange={() => {
                      setDriverExpanded(!driverExpanded);
                    }}
                  >
                    <h2>
                      <AccordionSummary
                        expandIcon={
                          <ExpandMoreIcon sx={{ color: "#FFFFFF" }} />
                        }
                        aria-controls="driverdetails"
                        id="driverdetails"
                        className={classes.heading}
                      >
                        {t(
                          "ReservationWizard.reservationPage.labelSectionDriver"
                        )}
                      </AccordionSummary>
                    </h2>
                    {showBoboTabs && (
                      <Grid item md={12}>
                        <Box
                          flexDirection="row"
                          alignItems="center"
                          textAlign="center"
                          tabIndex={0}
                          style={{
                            paddingBottom: 0,
                            paddingTop: 0,
                            paddingLeft: 25,
                            paddingRight: 25,
                            // maxWidth: 20,
                            // minWidth: 10,
                          }}
                        >
                          <Button
                            id="btnBookForMyself"
                            data-testid="btnBookForMyself"
                            style={{
                              width: "48%",
                              height: "100%",
                              wordBreak: "break-all",
                              wordWrap: "break-word",
                              paddingLeft: 0,
                              paddingRight: 0,
                              fontSize: "0.8em",
                            }}
                            color="primary"
                            aria-label={t(
                              "ReservationWizard.carClubPage.btnBookForMyself"
                            )}
                            variant="outlined"
                            disabled={resType === "1" && values.isBOBOBooking}
                            className={
                              bookForMyself
                                ? isSmXs
                                  ? classes.activeButtonClassSm
                                  : classes.activeButtonClass
                                : isSmXs
                                ? classes.inActiveButtonClassSm
                                : classes.inActiveButtonClass
                            }
                            onClick={() => {
                              if (resType === "1" && values.isBOBOBooking)
                                return;
                              setFieldValue(
                                "driverViewModel.isPrimaryDriver",
                                false,
                                false
                              );
                              if (
                                !bookForMyself &&
                                values.loyalty?.enableLoyalty
                              ) {
                                //switch from BookToOther to BookForMyself
                                console.log(
                                  "switch from BookToOther to BookForMyself"
                                );
                                if (
                                  !isDriverInfoMatchedMyProfileSingleCase(
                                    values.driverViewModel
                                  )
                                ) {
                                  //saved driver doesn't match user profile for loyalty, clear the input
                                  clearLoyaltyFields(
                                    true,
                                    values.driverViewModel
                                      .savedEnterprisePlusNumber,
                                    values.driverViewModel
                                      .savedEmeraldClubNumber
                                  );
                                } else {
                                  updateLoyaltyFields(
                                    true,
                                    values.driverViewModel
                                      .savedEnterprisePlusNumber,
                                    values.driverViewModel
                                      .savedEmeraldClubNumber
                                  );
                                }
                              }
                              setBookForMyself(true);
                              setisValidBOBOSomeone(true);
                            }}
                            startIcon={bookForMyself && <CheckIcon />}
                          >
                            {t(
                              "ReservationWizard.carClubPage.btnBookForMyself"
                            )}
                          </Button>
                          <Button
                            id="btnBookForOthers"
                            data-testid="btnBookForOthers"
                            style={{
                              width: "48%",
                              height: "100%",
                              wordBreak: "break-all",
                              wordWrap: "break-word",
                              paddingLeft: 0,
                              paddingRight: 0,
                              fontSize: "0.8em",
                            }}
                            color="primary"
                            variant="outlined"
                            disabled={resType === "1" && !values.isBOBOBooking}
                            className={
                              bookForMyself
                                ? isSmXs
                                  ? classes.inActiveButtonClassSm
                                  : classes.inActiveButtonClass
                                : isSmXs
                                ? classes.activeButtonClassSm
                                : classes.activeButtonClass
                            }
                            aria-label={t(
                              "ReservationWizard.carClubPage.btnBookForOthers"
                            )}
                            onClick={() => {
                              if (resType === "1" && values.isBOBOBooking)
                                return;
                              setFieldValue(
                                "driverViewModel.isPrimaryDriver",
                                true,
                                false
                              );
                              if (
                                bookForMyself &&
                                values.loyalty?.enableLoyalty
                              ) {
                                //switch from BookForMyself to BookToOther
                                console.log(
                                  "switch from BookForMyself to BookToOther"
                                );
                                clearLoyaltyFields(true, "", "");
                              }
                              setBookForMyself(false);
                            }}
                            startIcon={!bookForMyself && <CheckIcon />}
                          >
                            {t(
                              "ReservationWizard.carClubPage.btnBookForOthers"
                            )}
                          </Button>
                        </Box>
                      </Grid>
                    )}
                    {showDriverAccordion || showBoboTabs ? (
                      bookForMyself ? (
                        <AccordionDetails>
                          {values.driverViewModel === null ? (
                            ""
                          ) : (
                            // <DriverDetails
                            //   values={values.driverViewModel}
                            //   isConsentCountry={isConsentCountry}
                            //   acceptConsent={acceptConsent}
                            //   onConsentChange={handleConsentChange}
                            //   isBOBOUser={
                            //     values.driverViewModelForBOBO !== null
                            //   }
                            //   resType={resType}
                            //   onDataChange={onDriverDetailsChange}
                            // />
                            <></>
                          )}
                        </AccordionDetails>
                      ) : (
                        <AccordionDetails>
                          {/* <BOBOSomeone
                            initialDriver={
                              values.driverViewModelForBOBO == null
                                ? resInitialValue.driverViewModelForBOBO
                                : values.driverViewModelForBOBO
                            }
                            isConsentCountry={isConsentCountry}
                            acceptConsent={acceptConsent}
                            handleConsentChange={handleConsentChange}
                            resType={resType}
                            onDataChange={onBOBOUserChange}
                            disableSearch={resType === "1"}
                          /> */}
                          <></>
                        </AccordionDetails>
                      )
                    ) : (
                      <AccordionDetails>
                        {driverInfo === null ? (
                          ""
                        ) : (
                          <Grid container>
                            <Grid container direction="row">
                              <Grid
                                item
                                xs={12}
                                md={6}
                                className={classes.gridItem}
                                tabIndex={0}
                              >
                                <label
                                  id="primaryDriverLabel"
                                  htmlFor={"primaryDriverLabel"}
                                  className={classes.labelText}
                                >
                                  {t(
                                    "ReservationWizard.reservationPage.primaryDriverLabel"
                                  )}
                                </label>
                                <CheckSharp
                                  style={{
                                    color: "green",
                                    marginLeft: 20,
                                    verticalAlign: "bottom",
                                  }}
                                />
                                <span
                                  style={{
                                    color: "green",
                                    marginLeft: "5px",
                                  }}
                                >
                                  {t("journeyInput.validOption1")}
                                </span>
                              </Grid>
                            </Grid>
                            <Grid container direction="row">
                              <Grid
                                item
                                md={2}
                                className={classes.gridItem}
                                component={Box}
                                display={{ xs: "none", md: "block" }}
                                tabIndex={0}
                              >
                                <label
                                  id="firstNameLabel"
                                  htmlFor={"firstName"}
                                  className={classes.labelText}
                                >
                                  {t("Users.firstname")}
                                </label>
                              </Grid>
                              <Grid
                                item
                                md={2}
                                className={classes.gridItem}
                                component={Box}
                                display={{ xs: "none", md: "block" }}
                                tabIndex={0}
                              >
                                <label
                                  id="lastNameLabel"
                                  htmlFor={"lastName"}
                                  className={classes.labelText}
                                >
                                  {t("Users.lastname")}
                                </label>
                              </Grid>
                              <Grid
                                item
                                md={2}
                                className={classes.gridItem}
                                component={Box}
                                display={{ xs: "none", md: "block" }}
                                tabIndex={0}
                              >
                                <label
                                  id="emailLabel"
                                  htmlFor={"email"}
                                  className={classes.labelText}
                                >
                                  {t("Users.emailaddress")}
                                </label>
                              </Grid>
                              <Grid
                                item
                                md={2}
                                className={classes.gridItem}
                                component={Box}
                                display={{ xs: "none", md: "block" }}
                                tabIndex={0}
                              >
                                <label
                                  id="driverDeclarationIdLabel"
                                  htmlFor={"DriverDeclarationId"}
                                  className={classes.labelText}
                                >
                                  {t(
                                    "ReservationWizard.reservationPage.DriverDeclarationId"
                                  )}
                                </label>
                              </Grid>
                              <Grid
                                item
                                md={2}
                                className={classes.gridItem}
                                component={Box}
                                display={{ xs: "none", md: "block" }}
                                tabIndex={0}
                              >
                                <label
                                  id="phoneNumberLabel"
                                  htmlFor={"PhoneNumber"}
                                  className={classes.labelText}
                                >
                                  {t("Users.phoneNumber")}
                                </label>
                              </Grid>
                            </Grid>
                            <Divider className={classes.divider} />
                            <Grid container direction="row">
                              <Grid
                                item
                                md={2}
                                className={classes.gridItem}
                                component={Box}
                                display={{ xs: "none", md: "block" }}
                                tabIndex={0}
                              >
                                <Typography
                                  display="inline"
                                  variant="inherit"
                                  id="primaryDriver.firstName"
                                >
                                  {driverInfo?.primaryDriver?.firstName ??
                                    reservationDriverData?.firstName}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                md={2}
                                className={classes.gridItem}
                                component={Box}
                                display={{ xs: "none", md: "block" }}
                                tabIndex={0}
                              >
                                <Typography
                                  display="inline"
                                  variant="inherit"
                                  id="primaryDriver.lastName"
                                >
                                  {driverInfo?.primaryDriver?.lastName ??
                                    reservationDriverData?.lastName}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                md={2}
                                className={classes.gridItemEmail}
                                component={Box}
                                display={{ xs: "none", md: "block" }}
                                tabIndex={0}
                              >
                                <Typography
                                  display="inline"
                                  variant="inherit"
                                  id="primaryDriver.emailAddress"
                                >
                                  {maskEmail(
                                    driverInfo?.primaryDriver?.emailAddress ??
                                      reservationDriverData?.emailAddress
                                  )}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                md={2}
                                className={classes.gridItem}
                                component={Box}
                                display={{ xs: "none", md: "block" }}
                                tabIndex={0}
                              >
                                <Typography
                                  display="inline"
                                  variant="inherit"
                                  id="primaryDriver.driverDeclarationId"
                                >
                                  {driverInfo?.primaryDriver
                                    ?.driverDeclarationId ??
                                    reservationDriverData?.driverDeclarationId}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                md={2}
                                className={classes.gridItem}
                                component={Box}
                                display={{ xs: "none", md: "block" }}
                                tabIndex={0}
                              >
                                <Typography
                                  display="inline"
                                  variant="inherit"
                                  id="primaryDriver.phoneNumber"
                                >
                                  {formatPhoneNumber(
                                    reservationDriverData?.contactPhoneNumberString
                                  )}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                className={classes.gridItem}
                                component={Box}
                                display={{ xs: "block", md: "none" }}
                                tabIndex={0}
                              >
                                <label
                                  id="firstNameLabelXS"
                                  htmlFor={"firstName"}
                                  className={classes.labelText}
                                >
                                  {t("Users.firstname")}
                                </label>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                className={classes.gridItem}
                                component={Box}
                                display={{ xs: "block", md: "none" }}
                                tabIndex={0}
                              >
                                <Typography
                                  display="inline"
                                  variant="inherit"
                                  id="primaryDriver.firstNameXS"
                                >
                                  {driverInfo?.primaryDriver?.firstName ??
                                    reservationDriverData?.firstName}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                className={classes.gridItem}
                                component={Box}
                                display={{ xs: "block", md: "none" }}
                                tabIndex={0}
                              >
                                <label
                                  id="lastNameLabelXS"
                                  htmlFor={"lastName"}
                                  className={classes.labelText}
                                >
                                  {t("Users.lastname")}
                                </label>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                className={classes.gridItem}
                                component={Box}
                                display={{ xs: "block", md: "none" }}
                                tabIndex={0}
                              >
                                <Typography
                                  display="inline"
                                  variant="inherit"
                                  id="primaryDriver.lastNameXS"
                                >
                                  {driverInfo?.primaryDriver?.lastName ??
                                    reservationDriverData?.lastName}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                className={classes.gridItem}
                                component={Box}
                                display={{ xs: "block", md: "none" }}
                                tabIndex={0}
                              >
                                <label
                                  id="emailLabelXS"
                                  htmlFor={"email"}
                                  className={classes.labelText}
                                >
                                  {t("Users.emailaddress")}
                                </label>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                className={classes.gridItem}
                                component={Box}
                                display={{ xs: "block", md: "none" }}
                                tabIndex={0}
                              >
                                <Typography
                                  display="inline"
                                  variant="inherit"
                                  id="primaryDriver.emailAddressXS"
                                >
                                  {maskEmail(
                                    driverInfo?.primaryDriver?.emailAddress ??
                                      reservationDriverData?.emailAddress
                                  )}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                className={classes.gridItem}
                                component={Box}
                                display={{ xs: "block", md: "none" }}
                                tabIndex={0}
                              >
                                <label
                                  id="driverDeclarationIdLabelXS"
                                  htmlFor={"DriverDeclarationId"}
                                  className={classes.labelText}
                                >
                                  {t(
                                    "ReservationWizard.reservationPage.DriverDeclarationId"
                                  )}
                                </label>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                className={classes.gridItem}
                                component={Box}
                                display={{ xs: "block", md: "none" }}
                                tabIndex={0}
                              >
                                <Typography
                                  display="inline"
                                  variant="inherit"
                                  id="primaryDriver.driverDeclarationIdXS"
                                >
                                  {driverInfo?.primaryDriver
                                    ?.driverDeclarationId ??
                                    reservationDriverData?.driverDeclarationId}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                className={classes.gridItem}
                                component={Box}
                                display={{ xs: "block", md: "none" }}
                                tabIndex={0}
                              >
                                <label
                                  id="phoneNumberLabelLabelXS"
                                  htmlFor={"PhoneNumberLabel"}
                                  className={classes.labelText}
                                >
                                  {t("Users.phoneNumber")}
                                </label>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                className={classes.gridItem}
                                component={Box}
                                display={{ xs: "block", md: "none" }}
                                tabIndex={0}
                              >
                                <Typography
                                  display="inline"
                                  variant="inherit"
                                  id="primaryDriver.phoneNumberXS"
                                >
                                  {formatPhoneNumber(
                                    reservationDriverData?.contactPhoneNumberString
                                  )}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={9}
                                md={1}
                                className={classes.KeyboardArrow}
                                onClick={onClickPrimaryDriverDetails}
                              >
                                {!primaryDriverDetailsToggle ? (
                                  <KeyboardArrowDownIcon
                                    className={classes.KeyboardArrowIcon}
                                  />
                                ) : (
                                  <KeyboardArrowUpIcon
                                    className={classes.KeyboardArrowIcon}
                                  />
                                )}
                              </Grid>
                              <Grid
                                item
                                xs={3}
                                md={1}
                                className={classes.KeyboardArrowText}
                                tabIndex={0}
                              >
                                <Typography
                                  display="inline"
                                  variant="inherit"
                                  className={classes.KeyboardArrowIcon}
                                  id="reservationPage.Details"
                                >
                                  <Link
                                    onClick={onClickPrimaryDriverDetails}
                                    underline="none"
                                  >
                                    {t(
                                      "ReservationWizard.reservationPage.Details"
                                    )}
                                  </Link>
                                </Typography>
                              </Grid>
                            </Grid>
                            {primaryDriverDetailsToggle && (
                              <Grid container direction="row">
                                <Grid
                                  item
                                  xs={12}
                                  md={3}
                                  className={classes.gridItemWithMargin}
                                >
                                  <label
                                    id="driverDeclarationExpiryDateLabel"
                                    htmlFor={"DriverDeclarationExpiryDate"}
                                    className={classes.labelText}
                                  >
                                    {t(
                                      "ReservationWizard.reservationPage.DriverDeclarationExpiryDate"
                                    )}
                                  </label>
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  md={9}
                                  className={classes.gridItemWithMargin}
                                >
                                  <Typography
                                    display="inline"
                                    variant="inherit"
                                    id="primaryDriver.driverDeclarationExpiryDate"
                                  >
                                    {driverDeclarationExpiryDate}
                                  </Typography>
                                </Grid>

                                <Grid
                                  item
                                  xs={12}
                                  md={3}
                                  className={classes.gridItem}
                                >
                                  <label
                                    id="preferredPhoneNumber.selectedInternationalDiallingCodeLabel"
                                    htmlFor={
                                      "contactPhoneNumber.selectedInternationalDiallingCode"
                                    }
                                    className={classes.labelText}
                                  >
                                    {t("Users.phoneNumber").concat(" *")}
                                  </label>
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  md={3}
                                  className={classes.gridItem}
                                >
                                  <FormControl variant="outlined" fullWidth>
                                    <Select
                                      native
                                      value={
                                        reservationDriverData.contactPhoneNumber
                                          ?.selectedInternationalDiallingCode ??
                                        ""
                                      }
                                      onChange={(e) => {
                                        setReservationDriverData({
                                          ...reservationDriverData,
                                          contactPhoneNumber: {
                                            ...reservationDriverData.contactPhoneNumber,
                                            selectedInternationalDiallingCode: e
                                              .target.value as string,
                                          },
                                          contactPhoneNumberString: `(+${
                                            e.target.value as string
                                          })${
                                            reservationDriverData
                                              .contactPhoneNumber.number
                                          }`,
                                        });
                                        setContactPhoneSelectedCodeHasError(
                                          ValidateCountryCode(
                                            e.target.value as string
                                          ).isInValid
                                        );
                                        setReservationDriverDataTouched(true);
                                      }}
                                      fullWidth
                                      error={contactPhoneSelectedCodeHasError}
                                      inputProps={{
                                        id: "preferredPhoneNumber.selectedInternationalDiallingCode",
                                        name: "contactPhoneNumber.selectedInternationalDiallingCode",
                                        title: t(
                                          "Users.countryCodeForPreferredNumber"
                                        ),
                                        "aria-label": t(
                                          "Users.countryCodeForPreferredNumber"
                                        ),
                                        "aria-describedby":
                                          "contactPhoneNumber.selectedInternationalDiallingCodeError",
                                      }}
                                      name="contactPhoneNumber.selectedInternationalDiallingCode"
                                    >
                                      {(
                                        reservationDriverData.contactPhoneNumber
                                          ?.availableInternationalDiallingCodes ??
                                        values.driverViewModel
                                          .contactPhoneNumber
                                          ?.availableInternationalDiallingCodes
                                      )?.map((opt: any, optIndex) => (
                                        <option
                                          key={
                                            "contactPhoneNumberDiallingCodes" +
                                            optIndex
                                          }
                                          value={opt.value}
                                          disabled={
                                            opt.value === "+" ? true : false
                                          }
                                        >
                                          {opt.text}
                                        </option>
                                      ))}
                                    </Select>
                                  </FormControl>
                                  <span
                                    id="preferredPhoneNumber.selectedInternationalDiallingCodeError"
                                    className={classes.errorWarning}
                                  >
                                    {contactPhoneSelectedCodeHasError &&
                                      t(
                                        ValidateCountryCode(
                                          reservationDriverData
                                            .contactPhoneNumber
                                            .selectedInternationalDiallingCode
                                        ).message
                                      )}
                                  </span>
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  md={6}
                                  className={classes.gridItem}
                                >
                                  <Field
                                    name="contactPhoneNumber.number"
                                    id="preferredPhoneNumber.number"
                                    value={
                                      reservationDriverData?.contactPhoneNumber
                                        ?.number ?? ""
                                    }
                                    fullWidth
                                    style={{ paddingBottom: 0, paddingTop: 0 }}
                                    inputProps={{
                                      "aria-label": t("Users.phoneNumber"),
                                      "aria-describedby":
                                        "contactPhoneNumber.numberError",
                                    }}
                                    onChange={(e) => {
                                      setReservationDriverData({
                                        ...reservationDriverData,
                                        contactPhoneNumber: {
                                          ...reservationDriverData.contactPhoneNumber,
                                          number: e.target.value as string,
                                        },
                                        contactPhoneNumberString: `(+${
                                          reservationDriverData
                                            .contactPhoneNumber
                                            .selectedInternationalDiallingCode
                                        })${e.target.value as string}`,
                                      });
                                      setContactPhoneNumberHasError(
                                        ValidatePhoneNumber(
                                          e.target.value as string
                                        ).isInValid
                                      );
                                      setReservationDriverDataTouched(true);
                                    }}
                                    component={TextField}
                                    variant="outlined"
                                    error={contactPhoneNumberHasError}
                                    placeholder={t(
                                      "Users.preferredPhonePlaceholder"
                                    )}
                                  />
                                  <span
                                    id="preferredPhoneNumber.numberError"
                                    className={classes.errorWarning}
                                  >
                                    {contactPhoneNumberHasError &&
                                      t(
                                        ValidatePhoneNumber(
                                          reservationDriverData
                                            .contactPhoneNumber.number
                                        ).message
                                      )}
                                  </span>
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  md={3}
                                  className={classes.gridItem}
                                >
                                  <label
                                    id="alternativePhoneNumber.selectedInternationalDiallingCodeLabel"
                                    htmlFor={
                                      "alternatePhoneNumber.selectedInternationalDiallingCode"
                                    }
                                    className={classes.labelText}
                                  >
                                    {t("Users.alternateNumber")}
                                  </label>
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  md={3}
                                  className={classes.gridItem}
                                >
                                  <FormControl variant="outlined" fullWidth>
                                    <Select
                                      native
                                      value={
                                        reservationDriverData
                                          .alternatePhoneNumber
                                          ?.selectedInternationalDiallingCode ??
                                        ""
                                      }
                                      onChange={(e) => {
                                        setReservationDriverData({
                                          ...reservationDriverData,
                                          alternatePhoneNumber: {
                                            ...reservationDriverData.alternatePhoneNumber,
                                            selectedInternationalDiallingCode: e
                                              .target.value as string,
                                          },
                                          alternatePhoneNumberString: `(+${
                                            e.target.value as string
                                          })${
                                            reservationDriverData
                                              .alternatePhoneNumber.number
                                          }`,
                                        });
                                        setAlternatePhoneSelectedCodeHasError(
                                          ValidateAltCountryCode(
                                            e.target.value as string,
                                            reservationDriverData
                                              .alternatePhoneNumber.number
                                          ).isInValidCode
                                        );
                                        setAlternatePhoneNumberHasError(
                                          ValidateAltCountryCode(
                                            e.target.value as string,
                                            reservationDriverData
                                              .alternatePhoneNumber.number
                                          ).isInValidNumber
                                        );
                                        setReservationDriverDataTouched(true);
                                      }}
                                      fullWidth
                                      error={alternatePhoneSelectedCodeHasError}
                                      inputProps={{
                                        id: "alternativePhoneNumber.selectedInternationalDiallingCode",
                                        name: "alternatePhoneNumber.selectedInternationalDiallingCode",
                                        title: t(
                                          "Users.countryCodeForAlternateNumber"
                                        ),
                                        "aria-label": t(
                                          "Users.countryCodeForAlternateNumber"
                                        ),
                                        "aria-describedby":
                                          "alternatePhoneNumber.selectedInternationalDiallingCodeError",
                                      }}
                                      name="alternatePhoneNumber.selectedInternationalDiallingCode"
                                    >
                                      {(
                                        reservationDriverData
                                          .alternatePhoneNumber
                                          ?.availableInternationalDiallingCodes ??
                                        values.driverViewModel
                                          .alternatePhoneNumber
                                          ?.availableInternationalDiallingCodes
                                      )?.map((opt: any, optIndex) => (
                                        <option
                                          key={
                                            "alternatePhoneNumberDiallingCodes" +
                                            optIndex
                                          }
                                          value={opt.value}
                                        >
                                          {opt.text}
                                        </option>
                                      ))}
                                    </Select>
                                  </FormControl>
                                  <span
                                    id="alternativePhoneNumber.selectedInternationalDiallingCodeError"
                                    className={classes.errorWarning}
                                  >
                                    {alternatePhoneSelectedCodeHasError &&
                                      t(
                                        ValidateAltCountryCode(
                                          reservationDriverData
                                            .alternatePhoneNumber
                                            .selectedInternationalDiallingCode,
                                          reservationDriverData
                                            .alternatePhoneNumber.number
                                        ).messageCode
                                      )}
                                  </span>
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  md={6}
                                  className={classes.gridItem}
                                >
                                  <Field
                                    name="alternatePhoneNumber.number"
                                    id="alternativePhoneNumber.number"
                                    value={
                                      reservationDriverData
                                        ?.alternatePhoneNumber?.number ?? ""
                                    }
                                    fullWidth
                                    style={{ paddingBottom: 0, paddingTop: 0 }}
                                    inputProps={{
                                      "aria-label": t("Users.alternateNumber"),
                                      "aria-describedby":
                                        "alternatePhoneNumber.numberError",
                                    }}
                                    onChange={(e) => {
                                      setReservationDriverData({
                                        ...reservationDriverData,
                                        alternatePhoneNumber: {
                                          ...reservationDriverData.alternatePhoneNumber,
                                          number: e.target.value as string,
                                        },
                                        alternatePhoneNumberString: `(+${
                                          reservationDriverData
                                            .alternatePhoneNumber
                                            .selectedInternationalDiallingCode
                                        })${e.target.value as string}`,
                                      });
                                      setAlternatePhoneSelectedCodeHasError(
                                        ValidateAltPhoneNumber(
                                          reservationDriverData
                                            .alternatePhoneNumber
                                            .selectedInternationalDiallingCode,
                                          e.target.value as string
                                        ).isInValidCode
                                      );
                                      setAlternatePhoneNumberHasError(
                                        ValidateAltPhoneNumber(
                                          reservationDriverData
                                            .alternatePhoneNumber
                                            .selectedInternationalDiallingCode,
                                          e.target.value as string
                                        ).isInValidNumber
                                      );
                                      setReservationDriverDataTouched(true);
                                    }}
                                    error={alternatePhoneNumberHasError}
                                    component={TextField}
                                    variant="outlined"
                                    placeholder={t(
                                      "Users.alternatePhonePlaceholder"
                                    )}
                                  />
                                  <span
                                    id="alternativePhoneNumber.numberError"
                                    className={classes.errorWarning}
                                  >
                                    {alternatePhoneNumberHasError &&
                                      t(
                                        ValidateAltPhoneNumber(
                                          reservationDriverData
                                            .alternatePhoneNumber
                                            .selectedInternationalDiallingCode,
                                          reservationDriverData
                                            .alternatePhoneNumber.number
                                        ).messageNumber
                                      )}
                                  </span>
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  md={11}
                                  className={classes.gridItem}
                                >
                                  {isReservationDriverDataValid &&
                                    reservationDriverDataTouched && (
                                      <div
                                        style={{
                                          margin: "1em 1em 1em auto",
                                          textAlign: "right",
                                        }}
                                      >
                                        <Typography
                                          id="labelPreSave"
                                          display="inline"
                                          variant="inherit"
                                          tabIndex={0}
                                          className={classes.colorWarning}
                                        >
                                          {t(
                                            "ReservationWizard.reservationPage.labelPreSave"
                                          )}
                                        </Typography>
                                      </div>
                                    )}
                                  {!(
                                    isReservationDriverDataValid &&
                                    reservationDriverDataTouched
                                  ) &&
                                    showSaveSuccessLabel && (
                                      <div
                                        style={{
                                          margin: "1em 1em 1em auto",
                                          textAlign: "right",
                                        }}
                                      >
                                        <Typography
                                          id="labelSaveSuccess"
                                          display="inline"
                                          variant="inherit"
                                          tabIndex={0}
                                          className={classes.colorSuccess}
                                        >
                                          {t(
                                            "ReservationWizard.reservationPage.labelSaveSuccess"
                                          )}
                                        </Typography>
                                      </div>
                                    )}
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  md={1}
                                  className={classes.gridItem}
                                >
                                  <div
                                    style={{
                                      textAlign: "right",
                                      margin: "0em 1em 0em auto",
                                    }}
                                  >
                                    <Button
                                      id="btnSave"
                                      color="primary"
                                      variant="contained"
                                      aria-label={t("lblSave")}
                                      disabled={
                                        !isReservationDriverDataValid ||
                                        !reservationDriverDataTouched
                                      }
                                      onClick={handleSave}
                                    >
                                      {t("btnSave")}
                                    </Button>
                                  </div>
                                </Grid>
                              </Grid>
                            )}
                            <Divider className={classes.divider} />
                            <AdditionalDrivers
                              primaryDriverDeclarationId={
                                driverInfo?.primaryDriver
                                  ?.driverDeclarationId ??
                                reservationDriverData?.driverDeclarationId
                              }
                              reservationEndDate={values.dateTime.endDate}
                              //initialDrivers = {values.additionalDriver}
                              onDataChange={onAdditionalDriverChange}
                              hideDriverDeclarationId={false}
                              initialDrivers={
                                values.additionalDriver ?? additionalDrivers
                              }
                            />
                            <Grid
                              item
                              xs={12}
                              md={12}
                              className={classes.gridItem}
                            >
                              <Typography
                                display="inline"
                                variant="inherit"
                                tabIndex={0}
                              >
                                {t(
                                  "ReservationWizard.reservationPage.labelConsentMessagePart1"
                                )}
                              </Typography>
                              <Link
                                id="privacypolicyhyperlink"
                                target="_blank"
                                href={t(
                                  "ReservationWizard.reservationPage.privacyPolicyURL"
                                )}
                                display="inline"
                                variant="inherit"
                              >
                                {t(
                                  "ReservationWizard.reservationPage.labelConsentMessagePart2"
                                )}
                                <Box
                                  component="span"
                                  className="visuallyHidden"
                                >
                                  {t("Footer.OpenNewWindow")}
                                </Box>
                              </Link>
                              <Typography display="inline" variant="inherit">
                                {t(
                                  "ReservationWizard.reservationPage.labelConsentMessagePart3"
                                )}
                              </Typography>
                            </Grid>
                          </Grid>
                        )}
                      </AccordionDetails>
                    )}
                  </Accordion>
                  {resdata &&
                    (resdata.journeyProduct?.enableLoyalty ||
                      resdata.loyalty?.loyaltyId) && (
                      <Accordion
                        expanded={loyaltyExpanded}
                        onChange={() => {
                          setLoyaltyExpanded(!loyaltyExpanded);
                        }}
                        style={{ width: "100%" }}
                        id="sectionloyaltydetails"
                      >
                        <h2>
                          <AccordionSummary
                            expandIcon={
                              <ExpandMoreIcon sx={{ color: "#FFFFFF" }} />
                            }
                            aria-controls="loyaltydetails"
                            id="loyaltydetails"
                            className={classes.heading}
                          >
                            {t(
                              "ReservationWizard.reservationPage.labelSectionLoyalty"
                            )}
                          </AccordionSummary>
                        </h2>
                        <AccordionDetails>
                          <Grid container direction="row">
                            <Grid
                              item
                              xs={12}
                              md={3}
                              className={classes.gridItem}
                            >
                              <label
                                htmlFor={"loyaltyProgram"}
                                className={classes.labelText}
                              >
                                {t(
                                  "ReservationWizard.reservationPage.labelProgramAndLoyaltyId"
                                )}
                              </label>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={3}
                              className={classes.gridItem}
                            >
                              <FormControl variant="outlined" fullWidth>
                                <Select
                                  native
                                  disabled={
                                    resType === "1" /* || resType === "2" */
                                  }
                                  value={
                                    values.loyalty.selectedLoyaltyProgramValue
                                      ? values.loyalty
                                          .selectedLoyaltyProgramValue
                                      : ""
                                  }
                                  fullWidth
                                  inputProps={{
                                    id: "loyaltyProgram",
                                    name: "loyaltyProgram",
                                    "aria-label": t("loyaltyProgram"),
                                    "aria-haspopup": "listbox",
                                  }}
                                  title={t(
                                    "ReservationWizard.reservationPage.labelSelectProgramAndLoyaltyId"
                                  )}
                                  id="loyalty.selectedLoyaltyProgramValue"
                                  name="loyalty.selectedLoyaltyProgramValue"
                                  aria-describedby={"loyaltyProgramError"}
                                  error={
                                    errors.loyalty
                                      ?.selectedLoyaltyProgramValue !==
                                    undefined
                                      ? true
                                      : false
                                  }
                                  onChange={(e) => {
                                    if (typeof e.target.value === "string") {
                                      var enumKey = getEnumKeyByEnumValue(
                                        LoyaltyProgramType,
                                        e.target.value
                                      );
                                      var newLoyaltyId = "";
                                      switch (e.target.value) {
                                        case LoyaltyProgramType.pleaseSelect:
                                          newLoyaltyId = "";
                                          break;
                                        case LoyaltyProgramType.ET:
                                          newLoyaltyId =
                                            isDriverInfoMatchedMyProfileAllCase()
                                              ? values.loyalty
                                                  .savedEnterprisePlusNumber
                                              : "";
                                          break;
                                        case LoyaltyProgramType.ZL:
                                          newLoyaltyId =
                                            isDriverInfoMatchedMyProfileAllCase()
                                              ? values.loyalty
                                                  .savedEmeraldClubNumber
                                              : "";
                                          break;
                                      }

                                      var updatedLoyalty = {
                                        ...loyaltyViewModel,
                                        selectedLoyaltyProgramValue:
                                          e.target.value,
                                        selectedLoyaltyProgram:
                                          enumKey === "pleaseSelect"
                                            ? ""
                                            : enumKey ?? "",
                                        loyaltyId: newLoyaltyId,
                                      };

                                      setLoyaltyViewModel(updatedLoyalty);
                                      setFieldValue("loyalty", updatedLoyalty);
                                      setFieldTouched(
                                        "loyalty.selectedLoyaltyProgramValue",
                                        false
                                      );
                                    }
                                  }}
                                  onBlur={(e) => {
                                    setFieldTouched(
                                      "loyalty.selectedLoyaltyProgramValue"
                                    );
                                  }}
                                >
                                  {values.loyalty?.loyaltyPrograms &&
                                    values.loyalty?.loyaltyPrograms?.map(
                                      (opt: any) => (
                                        <option
                                          key={opt.value}
                                          value={opt.value}
                                        >
                                          {t(
                                            "LoyaltyProgramOption." +
                                              getEnumKeyByEnumValue(
                                                LoyaltyProgramType,
                                                opt.value
                                              )
                                          )}
                                        </option>
                                      )
                                    )}
                                </Select>
                              </FormControl>
                              {errors.loyalty?.selectedLoyaltyProgramValue &&
                              touched.loyalty?.selectedLoyaltyProgramValue ? (
                                <span
                                  id="loyaltyProgramError"
                                  className={classes.errorWarning}
                                >
                                  {t(
                                    errors.loyalty
                                      ?.selectedLoyaltyProgramValue ?? ""
                                  )}
                                </span>
                              ) : (
                                <span id="loyaltyProgramError"></span>
                              )}
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={6}
                              className={classes.gridItem}
                            >
                              <Field
                                name="loyalty.loyaltyId"
                                id="loyalty.loyaltyId"
                                value={
                                  values.loyalty.loyaltyId
                                    ? values.loyalty.loyaltyId
                                    : ""
                                }
                                onChange={(e) => {
                                  setFieldValue(
                                    "loyalty.loyaltyId",
                                    e.target.value.trim().slice(0, 9).toUpperCase()
                                  );
                                  setFieldTouched("loyalty.loyaltyId");
                                }}
                                onBlur={(e) => {
                                  setFieldTouched("loyalty.loyaltyId");
                                }}
                                fullWidth
                                style={{ paddingBottom: 0, paddingTop: 0 }}
                                inputProps={{
                                  "aria-describedby": "loyalty.loyaltyId",
                                  "aria-label": t("labelSectionLoyalty"),
                                }}
                                component={TextField}
                                variant="outlined"
                                error={
                                  errors.loyalty?.loyaltyId !== undefined
                                    ? true
                                    : false
                                }
                                disabled={
                                  resType === "1" /* || resType === "2" */ ||
                                  !values.loyalty.selectedLoyaltyProgram ||
                                  values.loyalty.selectedLoyaltyProgramValue ===
                                    LoyaltyProgramType.pleaseSelect
                                }
                              />
                              {errors.loyalty?.loyaltyId &&
                              touched.loyalty?.loyaltyId ? (
                                <span
                                  id="loyaltyError"
                                  className={classes.errorWarning}
                                >
                                  {t(errors.loyalty?.loyaltyId)}
                                </span>
                              ) : (
                                <span id="loyaltyError"></span>
                              )}
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={12}
                              className={classes.gridItem}
                            >
                              <span
                                style={{
                                  fontStyle: "italic",
                                  fontWeight: "bold",
                                }}
                              >
                                {t(
                                  "ReservationWizard.reservationPage.labelLoyaltyWarningMessage"
                                )}
                              </span>
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    )}
                  {selectedReasonForHire &&
                    selectedReasonForHire?.accountUId && (
                      <Accordion
                        style={{ width: "100%" }}
                        id="sectionbookingRef"
                        expanded={bookingRefExpanded}
                        onChange={() => {
                          setBookingRefExpanded(!bookingRefExpanded);
                        }}
                      >
                        <h2>
                          <AccordionSummary
                            expandIcon={
                              <ExpandMoreIcon sx={{ color: "#FFFFFF" }} />
                            }
                            aria-controls="bookingRef"
                            id="bookingRef"
                            className={classes.heading}
                          >
                            {t(
                              "ReservationWizard.reservationPage.labelSectionBookingRef"
                            )}
                          </AccordionSummary>
                        </h2>
                        <AccordionDetails>
                          {/* <Field
                          name="additionalalInformation"
                          id="additionalalInformation"
                          fullWidth
                          style={{ paddingBottom: 0, paddingTop: 0 }}
                          component={EtdAdditionalInfo}
                          validationSchema={reservationValidationSchema}
                          errors={errors}
                          accountId={selectedReasonForHire?.accountUId ?? ""}
                        />
                        {errors["additionalalInformation"] &&
                        errors["additionalalInformation"] ? (
                          <span className={classes.errorWarning}>
                            {errors["additionalalInformation"]}
                          </span>
                        ) : (
                          ""
                        )} */}
                          <div style={{ flexGrow: 1 }}>
                            <Grid container direction="row">
                              {additionalInfoData &&
                                additionalInfoData?.additionalInformationFields?.map(
                                  (info, index) => (
                                    (!isHiddenAdditionalParameters(info.fieldName) &&<Fragment key={index}>
                                      <Grid
                                        item
                                        xs={12}
                                        md={3}
                                        className={classes.gridItem}
                                      >
                                        <Typography
                                          variant="body2"
                                          id={"AdditionalInformationFieldLabel".concat(
                                            info.additionalInformationGid
                                          )}
                                          title={info.fieldName}
                                        >
                                          {info.fieldName.concat(
                                            info.mandatory ? " *" : ""
                                          )}
                                          {"  "}
                                          {info.helperText !== null ? (
                                            <IconButton
                                              id={
                                                "additionalInformationIcon" +
                                                index
                                              }
                                              size="small"
                                              color="primary"
                                              style={{
                                                marginLeft: 0,
                                                paddingBottom: 8,
                                              }}
                                              title={info.helperText}
                                              //role="img"
                                            >
                                              <InfoIcon fontSize="small" />
                                            </IconButton>
                                          ) : (
                                            ""
                                          )}
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={12}
                                        md={9}
                                        className={classes.gridItem}
                                      >
                                        {/* {renderAdditionalInformationField(info, index)} */}
                                        {[3, 4, 5].includes(
                                          info.fieldTypeCode
                                        ) ? (
                                          <Fragment>
                                            <Field
                                              name={
                                                "AdditionalInformation.AdditionalInformationFields[" +
                                                index +
                                                "].FieldValue"
                                              }
                                              id={
                                                "AdditionalInformationField-TextTextbox-" +
                                                index
                                              }
                                              value={info.fieldValue ?? ""}
                                              fullWidth
                                              style={{
                                                paddingBottom: 0,
                                                paddingTop: 0,
                                              }}
                                              inputProps={{
                                                "aria-label": info.fieldName,
                                                "aria-required": true,
                                                /* maxLength: info.maxLength, */
                                                "aria-describedby":
                                                  "AdditionalInformationFieldError" +
                                                  index,
                                              }}
                                              onChange={(e) => {
                                                info.fieldValue =
                                                  e.target.value.replace("%20", " ").trim();
                                                setFieldValue(
                                                  "additionalInformation",
                                                  additionalInfoData
                                                );
                                              }}
                                              onBlur={(e) => {
                                                setFieldTouched(
                                                  "additionalInformation.additionalInformationFields[" +
                                                    index +
                                                    "]"
                                                );
                                              }}
                                              onKeyUp={(e) => {
                                                setFieldTouched(
                                                  "additionalInformation.additionalInformationFields[" +
                                                    index +
                                                    "]"
                                                );
                                              }}
                                              component={TextField}
                                              //required={info.mandatory}
                                              variant="outlined"
                                              error={
                                                (errors?.[
                                                  "additionalInformation"
                                                ] !== undefined &&
                                                  (
                                                    errors.additionalInformation as unknown as additionalInformationFormikFormError
                                                  )
                                                    ?.additionalInformationFields[
                                                    index
                                                  ] !== undefined &&
                                                  (
                                                    touched.additionalInformation as unknown as additionalInformationFormikFormTouched
                                                  )
                                                    ?.additionalInformationFields[
                                                    index
                                                  ]) ||
                                                (additionalInfoValidationResponse !==
                                                  null &&
                                                  additionalInfoValidationResponse.errors.hasOwnProperty(
                                                    info.additionalInformationGid
                                                  ))
                                                  ? true
                                                  : false
                                              }
                                              placeholder={
                                                locale === "de-DE"
                                                  ? ""
                                                  : t(
                                                      "AdditionalInformation.pleaseSelectValueFor"
                                                    ) + info.fieldName
                                              }
                                            />
                                            {(
                                              errors.additionalInformation as unknown as additionalInformationFormikFormError
                                            )?.additionalInformationFields[
                                              index
                                            ] &&
                                            (
                                              touched.additionalInformation as unknown as additionalInformationFormikFormTouched
                                            )?.additionalInformationFields[
                                              index
                                            ] ? (
                                              <span
                                                id={
                                                  "AdditionalInformationFieldError" +
                                                  index
                                                }
                                                role="alert"
                                                className={classes.errorWarning}
                                              >
                                                {t(
                                                  (
                                                    errors.additionalInformation as unknown as additionalInformationFormikFormError
                                                  )
                                                    ?.additionalInformationFields[
                                                    index
                                                  ],
                                                  {
                                                    fieldName: info.fieldName,
                                                    maxLength: info.maxLength
                                                      ? info.maxLength
                                                      : 100,
                                                  }
                                                )}
                                              </span>
                                            ) : (
                                              <span
                                                id={
                                                  "AdditionalInformationFieldError" +
                                                  index
                                                }
                                              ></span>
                                            )}
                                          </Fragment>
                                        ) : [2].includes(info.fieldTypeCode) ? (
                                          <Fragment>
                                            <FormControl
                                              variant="outlined"
                                              fullWidth
                                            >
                                              <Select
                                                name={
                                                  "AdditionalInformation.AdditionalInformationFields[" +
                                                  index +
                                                  "].FieldValue"
                                                }
                                                id={
                                                  "AdditionalInformationField-DropDownListComboBox-" +
                                                  index
                                                }
                                                native
                                                value={info.fieldValue ?? ""}
                                                onChange={(e) => {
                                                  info.dropDownListSelectedValue =
                                                    e.target.value;
                                                  info.fieldValue =
                                                    e.target.value;
                                                  setFieldValue(
                                                    "additionalInformation",
                                                    additionalInfoData
                                                  );
                                                }}
                                                onBlur={(e) => {
                                                  setFieldTouched(
                                                    "additionalInformation.additionalInformationFields[" +
                                                      index +
                                                      "]"
                                                  );
                                                }}
                                                //required={info.mandatory}
                                                inputProps={{
                                                  "aria-label": info.fieldName,
                                                  "aria-describedby":
                                                    "AdditionalInformationFieldError" +
                                                    index,
                                                }}
                                                error={
                                                  (errors?.[
                                                    "additionalInformation"
                                                  ] !== undefined &&
                                                    (
                                                      errors.additionalInformation as unknown as additionalInformationFormikFormError
                                                    )
                                                      ?.additionalInformationFields[
                                                      index
                                                    ] !== undefined &&
                                                    (
                                                      touched.additionalInformation as unknown as additionalInformationFormikFormTouched
                                                    )
                                                      ?.additionalInformationFields[
                                                      index
                                                    ]) ||
                                                  (additionalInfoValidationResponse !==
                                                    null &&
                                                    additionalInfoValidationResponse.errors.hasOwnProperty(
                                                      info.additionalInformationGid
                                                    ))
                                                    ? true
                                                    : false
                                                }
                                              >
                                                <option
                                                  key={
                                                    "AdditionalInformationField-DropDownListComboBox-" +
                                                    index +
                                                    "-Emtpy"
                                                  }
                                                  value=""
                                                >
                                                  {t(
                                                    "AdditionalInformation.pleaseSelect"
                                                  )}
                                                </option>
                                                {info.dropDownList?.map(
                                                  (opt: any, optIndex) => (
                                                    <option
                                                      key={
                                                        "AdditionalInformationField-DropDownListComboBox-" +
                                                        index +
                                                        "-" +
                                                        optIndex
                                                      }
                                                      value={opt.value}
                                                    >
                                                      {opt.text}
                                                    </option>
                                                  )
                                                )}
                                              </Select>
                                              {(
                                                errors.additionalInformation as unknown as additionalInformationFormikFormError
                                              )?.additionalInformationFields[
                                                index
                                              ] &&
                                              (
                                                touched.additionalInformation as unknown as additionalInformationFormikFormTouched
                                              )?.additionalInformationFields[
                                                index
                                              ] ? (
                                                <span
                                                  id={
                                                    "AdditionalInformationFieldError" +
                                                    index
                                                  }
                                                  role="alert"
                                                  className={
                                                    classes.errorWarning
                                                  }
                                                >
                                                  {t(
                                                    (
                                                      errors.additionalInformation as unknown as additionalInformationFormikFormError
                                                    )
                                                      ?.additionalInformationFields[
                                                      index
                                                    ],
                                                    // { fieldName: info.fieldName }
                                                    {
                                                      fieldName: info.fieldName,
                                                      maxLength: info.maxLength
                                                        ? info.maxLength
                                                        : 100,
                                                    }
                                                  )}
                                                </span>
                                              ) : (
                                                <span
                                                  id={
                                                    "AdditionalInformationFieldError" +
                                                    index
                                                  }
                                                ></span>
                                              )}
                                            </FormControl>
                                          </Fragment>
                                        ) : [1].includes(info.fieldTypeCode) ? (
                                          <Fragment>
                                            <Field
                                              component={EtdDatePickerV2}
                                              name={
                                                "AdditionalInformation.AdditionalInformationFields[" +
                                                index +
                                                "].FieldValue"
                                              }
                                              id={
                                                "AdditionalInformationField-DateTextbox-" +
                                                index
                                              }
                                              label={info.fieldName}
                                              localeDateTimeFormat={
                                                localeDateTimeFormat
                                              }
                                              isFullWidth={false}
                                              value={
                                                info.fieldValue
                                                  ? convertToDate(
                                                      info.fieldValue
                                                    )
                                                  : null
                                              }
                                              errorMessageId={
                                                "AdditionalInformationField-DateTextboxError-" +
                                                index
                                              }
                                              handleChange={(e) => {
                                                const format =
                                                  localeDateTimeFormat.toUpperCase();
                                                let parsedDate = moment(
                                                  e,
                                                  format,
                                                  true
                                                );

                                                if (parsedDate.isValid()) {
                                                  const newValue =
                                                    moment(parsedDate).format(
                                                      "YYYY-MM-DD"
                                                    );
                                                  if (
                                                    moment(
                                                      parsedDate
                                                    ).isSameOrAfter(
                                                      moment().add(-1, "days")
                                                    )
                                                  ) {
                                                    info.fieldValue = newValue;
                                                    info.fieldValueDateString =
                                                      newValue.replace(
                                                        /\D/g,
                                                        ""
                                                      );
                                                  } else {
                                                    info.fieldValue = null;
                                                    info.fieldValueDateString =
                                                      "DateInPast";
                                                  }
                                                } else {
                                                  info.fieldValue = null;
                                                  info.fieldValueDateString = e
                                                    ? "FormatError"
                                                    : "";
                                                }
                                                setFieldValue(
                                                  "additionalInformation",
                                                  additionalInfoData
                                                );
                                              }}
                                              handleBlur={(e) => {
                                                setFieldTouched(
                                                  "additionalInformation.additionalInformationFields[" +
                                                    index +
                                                    "]"
                                                );
                                              }}
                                              isError={
                                                (errors?.[
                                                  "additionalInformation"
                                                ] !== undefined &&
                                                  (
                                                    errors.additionalInformation as unknown as additionalInformationFormikFormError
                                                  )
                                                    ?.additionalInformationFields[
                                                    index
                                                  ] !== undefined) ||
                                                (additionalInfoValidationResponse !==
                                                  null &&
                                                  additionalInfoValidationResponse.errors.hasOwnProperty(
                                                    info.additionalInformationGid
                                                  ))
                                                  ? true
                                                  : false
                                              }
                                            />
                                            {(
                                              errors.additionalInformation as unknown as additionalInformationFormikFormError
                                            )?.additionalInformationFields[
                                              index
                                            ] /* &&
                                          (
                                            touched.additionalInformation as unknown as additionalInformationFormikFormTouched
                                          )?.additionalInformationFields[
                                            index
                                          ] */ ? (
                                              <span
                                                id={
                                                  "AdditionalInformationField-DateTextboxError-" +
                                                  index
                                                }
                                                role="alert"
                                                className={classes.errorWarning}
                                              >
                                                {t(
                                                  (
                                                    errors.additionalInformation as unknown as additionalInformationFormikFormError
                                                  )
                                                    ?.additionalInformationFields[
                                                    index
                                                  ],
                                                  { fieldName: info.fieldName }
                                                )}
                                              </span>
                                            ) : (
                                              <span
                                                id={
                                                  "AdditionalInformationField-DateTextboxError-" +
                                                  index
                                                }
                                              ></span>
                                            )}
                                          </Fragment>
                                        ) : (
                                          ""
                                        )}
                                      </Grid>
                                    </Fragment>)
                                  )
                                )}
                            </Grid>
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    )}
                  <Accordion
                    style={{ width: "100%" }}
                    id="sectionnotes"
                    expanded={notesExpanded}
                    onChange={() => {
                      setNotesExpanded(!notesExpanded);
                    }}
                  >
                    <h2>
                      <AccordionSummary
                        expandIcon={
                          <ExpandMoreIcon sx={{ color: "#FFFFFF" }} />
                        }
                        aria-controls="notes"
                        id="notes"
                        className={classes.heading}
                      >
                        {t(
                          "ReservationWizard.reservationPage.labelSectionNotes"
                        )}
                      </AccordionSummary>
                    </h2>
                    <AccordionDetails>
                      <Grid container direction="row">
                        <Grid item xs={12} md={3} className={classes.gridItem}>
                          <label
                            htmlFor={"notesViewModel.reservationNote"}
                            className={classes.labelText}
                          >
                            {t("ReservationWizard.reservationPage.labelNotes")}
                          </label>
                        </Grid>
                        <Grid item xs={12} md={9} className={classes.gridItem}>
                          <Field
                            name="notesViewModel.reservationNote"
                            id="notesViewModel.reservationNote"
                            value={values.notesViewModel.reservationNote ?? ""}
                            fullWidth
                            style={{ paddingBottom: 0, paddingTop: 0 }}
                            inputProps={{
                              "aria-label": t("Res.Notes"),
                              className: classes.textarea,
                              "aria-describedby":
                                "notesViewModel.reservationNote-helper-text",
                            }}
                            onChange={(e) =>
                              setFieldValue(
                                "notesViewModel.reservationNote",
                                e.target.value
                              )
                            }
                            component={TextField}
                            multiline
                            variant="outlined"
                            error={
                              errors["notesViewModel"] !== undefined &&
                              errors["notesViewModel"]["reservationNote"] !==
                                undefined
                                ? true
                                : false
                            }
                            placeholder={t(
                              "ReservationWizard.reservationPage.placeholderNotes"
                            )}
                          />
                          {errors["notesViewModel"] &&
                          errors["notesViewModel"]["reservationNote"] ? (
                            <span
                              id="notesViewModel.reservationNote-helper-text"
                              className={classes.errorWarning}
                            >
                              {errors["notesViewModel"]["reservationNote"]}
                            </span>
                          ) : (
                            <span id="notesViewModel.reservationNote-helper-text"></span>
                          )}
                        </Grid>
                        {values.locations?.startLocation?.isAirportLocation && (
                          <Grid
                            item
                            xs={12}
                            md={3}
                            className={classes.gridItem}
                          >
                            <label
                              htmlFor={"flightNumber"}
                              className={classes.labelText}
                            >
                              {t(
                                "ReservationWizard.reservationPage.labelFlightNumber"
                              )}
                            </label>
                          </Grid>
                        )}
                        {values.locations?.startLocation?.airportCity && (
                          <Grid
                            item
                            xs={12}
                            md={9}
                            className={classes.gridItem}
                          >
                            <Field
                              name="flightNumberViewModel.flightNumber"
                              id="flightNumberViewModel.flightNumber"
                              onChange={(e) => {
                                setFieldValue(
                                  "flightNumberViewModel.flightNumber",
                                  e.target.value.toUpperCase()
                                );

                                setFlightNumberHasError(
                                  ValidateFlightNumber(e.target.value as string)
                                    .isInValid
                                );
                              }}
                              value={
                                values.flightNumberViewModel?.flightNumber ?? ""
                              }
                              fullWidth
                              style={{ paddingBottom: 0, paddingTop: 0 }}
                              inputProps={{
                                "aria-describedby": "flightNumber.Error",
                                "aria-label": t(
                                  "ReservationWizard.reservationPage.labelFlightNumber"
                                ),
                              }}
                              component={TextField}
                              variant="outlined"
                              placeholder={t(
                                "ReservationWizard.reservationPage.placeholderFlightNumber"
                              )}
                              error={flightNumberHasError}
                            />
                            <span
                              id="flightNumber.Error"
                              className={classes.errorWarning}
                            >
                              {flightNumberHasError &&
                                t(
                                  ValidateFlightNumber(
                                    values.flightNumberViewModel
                                      ?.flightNumber ?? ""
                                  ).message
                                )}
                            </span>
                          </Grid>
                        )}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "1em",
                    }}
                  >
                    <Button
                      id="btnBack"
                      color="primary"
                      aria-label={t("lblBackToPreviousStep")}
                      variant="contained"
                      onClick={() => {
                        if (props.handleBack) {
                          //handleToggle();
                          props.handleBack(0, 0);
                        }
                      }}
                    >
                      {t("btnBack")}
                    </Button>
                    <Button
                      id="btnSubmit"
                      color="primary"
                      variant="contained"
                      aria-label={t("lblContinueToNextStep")}
                      disabled={
                        !isValid ||
                        !isValidContinue ||
                        !isValidAdditionalDrivers ||
                        !isValidBOBOSomeone ||
                        flightNumberHasError ||
                        (!isValidDriverViewModel && bookForMyself)
                      }
                      type="submit"
                    >
                      {t("btnContinue")}
                    </Button>
                  </div>
                </Form>
              </Grid>
            </Grid>
          )}
        </Box>
      )}
    </Formik>
  );
};

export default ReservationForm;
