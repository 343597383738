import React from "react";
import PropTypes from "prop-types";
import makeStyles from '@mui/styles/makeStyles';
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {
  Backdrop,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  Select,
  TextField, 
} from "@mui/material";
import Autocomplete from '@mui/material/Autocomplete';
import { createFilterOptions } from '@mui/material/useAutocomplete';
import { useTranslation } from "react-i18next";
import CompanyProfile from "./profile";
import AddProfile from "./addprofile";
import Content from "./content";
import TravelPolicy from "./travelpolicy";
import RiskAssessment from "./riskassessment";
import Accounts from "./accounts";
import AdditionaFields from "./additionalfields";
import Notifications from "./notifications";
import { companyProfileType } from "./companiesType";
import {
  clearAlertMessage,
  showErrorAlertMessage,
  showSuccessAlertMessage,
} from "components/store/actions/shared/alertActions";
import axiosInstance from "helpers/axiosInstance";
import { useDispatch } from "react-redux";
import InfoIcon from "@mui/icons-material/Info";
import { broadcastAnnouncement } from "components/store/actions/shared/announcementActions";
import { appInsights } from "helpers/appInsights";
import { SeverityLevel } from "@microsoft/applicationinsights-common";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    "& .MuiTab-wrapper": {
      alignItems: "flex-end",
      marginRight: 10,
      marginTop: 10,
      marginBottom: 10,
    },
    "& .MuiTab-root": {
      lineHeight: 2,
      fontWeight: "bold",
      fontSize: "0.9rem",
      textTransform: "none",
      minWidth: "100px",
    },
  },
  tabs: {
    borderRight: `1px solid black`,
    overflow: "visible",
    minWidth: "110px",
    marginRight: 20,
  },
  button: { textTransform: "none" },
  backdrop: {
    zIndex: 99999,
    color: "#fff",
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} bgcolor="background.paper">
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

interface CompanyItem
{
  disabled: boolean;
  group: null;
  selected: boolean;
  text: string;
  value: string;
}
export default function Companies(props ) {

  const initialCompany: CompanyItem = {
    disabled: false,
    group: null,
    selected: false,
    text: "Please Select a Company",
    value: "",
  }

  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [companyList, setcompanyList] = React.useState<CompanyItem[]>([]);
  const [value, setValue] = React.useState(0);

  const [loading, setLoading] = React.useState(false);
  const [manageCompany, setmanageCompany] = React.useState(false);
  const [addCompany, setaddCompany] = React.useState(false);
  const [selectedCompanyUId, setselectedCompanyUId] = React.useState("");
  const [selectedCompanyName, setselectedCompanyName] = React.useState("");
  const titleRef = React.useRef<HTMLDivElement>(null);
  const profileRef = React.useRef<HTMLDivElement>(null);
  const [val, setVal] = React.useState<CompanyItem>(initialCompany);
  const [inputValue, setInputValue] = React.useState(initialCompany.text)

//   const OPTIONS_LIMIT = 10;
//   const filterOptions = createFilterOptions({
//   limit: OPTIONS_LIMIT
// });



  const initialcompProfile: companyProfileType = {
    companyName: "",
    reasonForHireLabel: "",
    isDeleted: false,
    isNew: true,
    isEmailNotificationActivated: false,
    isAppDriverValidityActivated: false,
    isCompanyDriverValidityActivated: false,
    boboEnabled:false,
    isBOBOToggleEnabled:false,
    isCreditCardToggleEnabled:false,
    isAllowAdminToAccessAllJourneysToggleEnabled:false,
    companyUId: "",
    countries: {
      selectedValue: "",
      selectedText: "",
      items: [
        {
          disabled: false,
          group: null,
          selected: false,
          text: "",
          value: "",
        },
      ],
    },
    isRentalMgmtEnabled:false,
    isRentalMgmtLocked:false
  };

  const [compProfile, setcompProfile] =
    React.useState<companyProfileType>(initialcompProfile);

  React.useEffect(() => {
    document.title = t("Companies.pagetitle");
    dispatch(clearAlertMessage());
    getCompanies();
  }, []);
  const handleChange = (event, newValue) => {
    dispatch(clearAlertMessage());
    setValue(newValue);
    switch (newValue) {
      case 0:
        dispatch(broadcastAnnouncement(t("Companies.CompanyProfile")));
        break;
      case 1:
        dispatch(broadcastAnnouncement(t("Companies.companyContent")));
        break;
      case 2:
        dispatch(
          broadcastAnnouncement(t("TravelPolicy.CompanyTravelPolicyRules"))
        );
        break;
      case 3:
        dispatch(broadcastAnnouncement(t("Companies.companyAccountPageTitle")));
        break;
      case 4:
        dispatch(broadcastAnnouncement(t("Companies.CompanyAdditionalField")));
        break;
      case 5:
        dispatch(
          broadcastAnnouncement(t("Companies.companyRiskAssesmentPageTitle"))
        );
        break;
      case 6:
        dispatch(
          broadcastAnnouncement(t("Companies.companyNotifications"))
        );
        break;
      default:
        dispatch(broadcastAnnouncement(t("Companies.CompanyProfile")));
    }
  };

  React.useEffect(() => {
    var addCompanyProfileHeading = document.getElementById(
      "addCompanyProfileHeading"
    );
    if (addCompanyProfileHeading !== null && !props.disableAutoFocus ) addCompanyProfileHeading.focus();
  }, [addCompany]);

  const landingPage = (companyUid) => {
    setaddCompany(false);
    if (companyUid !== "") {
      getCompanies();
      //setselectedCompanyUId(companyUid);
      getCompanyProfile(companyUid);
      setValue(0);
      setmanageCompany(true);
    } else {
      dispatch(clearAlertMessage());
      setselectedCompanyUId(companyUid);
      setselectedCompanyName("");
      setmanageCompany(false);
      setcompProfile(initialcompProfile);
      if (titleRef.current !== null) titleRef.current.focus();
    }
  };

  const companyDeleted = (companyName) => {
    setaddCompany(false);
    getCompanies();
    setselectedCompanyUId("");
    setselectedCompanyName("");
    setmanageCompany(false);
    setcompProfile(initialcompProfile);

     setVal(initialCompany);
     setInputValue(initialCompany.text)

    if (titleRef.current !== null) titleRef.current.focus();   
    dispatch(
      showSuccessAlertMessage(
        t("Companies.DeleteCompanyProfileSuccess", {
          CompanyName: companyName,
        })
      )
    );
    dispatch(
      broadcastAnnouncement(
        t("Companies.DeleteCompanyProfileSuccess", {
          CompanyName: companyName,
        })
      )
    );
  };

  const getCompanies = async () => {
    setLoading(true);
    await axiosInstance
      .get("/company/getcompanylist")
      .then((response) => {
        setcompanyList(response.data.items);
      })
      .catch((error) => {
        appInsights.trackException({id: "getCompanies", exception: error, severityLevel: SeverityLevel.Error});
        dispatch(showErrorAlertMessage(t("UnexpectedError")));
        dispatch(broadcastAnnouncement(t("UnexpectedError")));
        setLoading(false);
        console.error("There is an error", error);
      });
    setLoading(false);
  };

  const getCompanyProfile = async (companyUId) => {
    setLoading(true);
    if (companyUId !== "") {
      await axiosInstance
        .get(`/company/getCompanyProfileByUId/${companyUId}`)
        .then((response) => {
          setselectedCompanyName(response.data.existingCompanyName);
          if(response.data.reasonForHireLabel === null)
          response.data.reasonForHireLabel = ""
          setcompProfile(response.data);

          let companyItem1 = {} as CompanyItem;
          companyItem1.text = response.data?.companyName ?? "";
          companyItem1.value = companyUId;
          setVal(companyItem1);
          setInputValue(companyItem1.text);
          
          dispatch(
            broadcastAnnouncement(
              t("Companies.CompanyProfileFormat", {
                CompanyName: response.data.existingCompanyName,
              })
            )
          );
        })
        .catch((error) => {
          setLoading(false);
          appInsights.trackException({id: "getCompanyProfile", exception: error, severityLevel: SeverityLevel.Error});
          if (
            error.response != null &&
            error.response.status != null &&
            error.response.status === 400
          ) {
            dispatch(showErrorAlertMessage(t("UnexpectedError")));
            dispatch(broadcastAnnouncement(t("UnexpectedError")));
            console.error("There is an error", error);
          } else console.error("There is an error", error);
        });
    } else {
      setselectedCompanyName("");
    }
    setLoading(false);
  };
  React.useEffect(() => {
    if (profileRef.current !== null && !props.disableAutoFocus ) profileRef.current.focus();
  }, [manageCompany]);

  const handleChangeCompany = (event,value) => {
    
    setValue(0);
    if (value===null) {
      setselectedCompanyUId("");
      setselectedCompanyName("");
      setmanageCompany(false);
      return;
    }
  
    dispatch(clearAlertMessage());
    setselectedCompanyUId(value.value);
    getCompanyProfile(value.value);
    setmanageCompany(true);
    setaddCompany(false);
    if (value.value === "") {
      setmanageCompany(false);
      setcompProfile(initialcompProfile);
      if (titleRef.current !== null) titleRef.current.focus();
    }
  };
  return (
    <Box data-testid="companyManagement" id="companyManagement" boxShadow={3} p={3} bgcolor="background.paper">
      <Grid container>
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress />
        </Backdrop>
        <Grid item xs={12} md={12} style={{ marginBottom: 20 }}>
          <div ref={titleRef} tabIndex={-1} className="visuallyHidden">
            {t("Companies.ManageCompanies")}
          </div>
          <Typography id="titleHeader" variant="h1">
            {t("Companies.ManageCompanies")}
          </Typography>
        </Grid>
        <Grid item xs={12} md={12} style={{ marginBottom: 20 }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              margin: 10,
              marginLeft: 0,
            }}
          >
            <Typography variant="h2">{t("Companies.Companies")}</Typography>
            <IconButton
              id="infoIcon"
              size="small"
              color="primary"
              style={{
                paddingBottom: 8,
                marginLeft: 3,
                height: 15,
                width: 15,
              }}
              title={t("Companies.SelectCompanyProfile")}
              aria-label={t("Companies.SelectCompanyProfile")}
              role="img"
            >
              <InfoIcon fontSize="small" />
            </IconButton>
          </div>
          <FormControl id="companyList" variant="outlined">
            <Autocomplete
              id="ddcompanies"
              aria-label={t("Users.company")}
              options={companyList}
              autoComplete
              disableClearable
              defaultValue={initialCompany}
              renderInput={(params) => (
                <TextField
                  //{...input}
                  {...params}
                  label={t("Users.company")}
                  variant="outlined"
                />
              )}
              getOptionLabel={(option: CompanyItem) => option.text}
              isOptionEqualToValue={(option, value) => option.text === value.text}
              style={{ width: 230 }}
              //value={val}
              //inputValue={inputValue}
              onChange={handleChangeCompany}
              // onInputChange={(event, newInputValue) => {
              // setInputValue(newInputValue);
              // }}
            />

            {/* <Select
              native
              value={selectedCompanyUId}
              onChange={handleChangeCompany}
              style={{ width: "230px", marginRight: 30 }}
              inputProps={{
                id: "ddcompanies",
                name: "ddcompanies",
                title: "select a company",
                "aria-label": t("Users.company"),
              }}
            >
              {companyList?.map((opt: any) => (
                <option key={opt.value} value={opt.value}>
                  {opt.text}
                </option>
              ))}
            </Select> */}
          </FormControl>
          {"                        "}
          <Button
            id="addCompanyProfileHyperLink"
            color="primary"
            variant="contained"
            onClick={() => {
              dispatch(clearAlertMessage());
              setselectedCompanyUId("");
              setselectedCompanyName("");
              setmanageCompany(false);
              setaddCompany(true);
            }}
            style={{ marginTop: 20, textTransform: "none" }}
          >
            {t("Companies.AddNewCompany")}
          </Button>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          className={classes.root}
          style={addCompany ? { display: "block" } : { display: "none" }}
        >
          <AddProfile action={landingPage} />
        </Grid>
        <Box
          id="manageCompany"
          boxShadow={3}
          p={3}
          width="100%"
          bgcolor="background.paper"
          style={manageCompany ? { display: "block" } : { display: "none" }}
        >
          <Grid item xs={12} md={12} className={classes.root}>
            <Typography variant="h1" style={{ margin: "auto" }}>
              {selectedCompanyName === ""
                ? ""
                : t("Companies.CompanyProfileFormat", {
                    CompanyName: selectedCompanyName,
                  })}
            </Typography>
          </Grid>
          <Grid container>
            <Grid
              item
              xs={12}
              sm={2}
              className={classes.root}
              style={{ display: "flex", minWidth: "60" }}
            >
              <Tabs
                id="compManagmentTabs"
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Company Management Tabs"
                className={classes.tabs}
                textColor="primary"
                indicatorColor="primary"
                style={{ width: "100%" }}
              >
                <Tab
                  ref={profileRef}
                  style={value !== 0 ? { color: "black" } : {}}
                  label={t("Companies.Profile")}
                  {...a11yProps(0)}
                />
                <Tab
                  style={value !== 1 ? { color: "black" } : {}}
                  label={t("Companies.Content")}
                  {...a11yProps(1)}
                />
                <Tab
                  style={value !== 2 ? { color: "black" } : {}}
                  label={t("Companies.TravelPolicy")}
                  {...a11yProps(2)}
                />
                <Tab
                  style={value !== 3 ? { color: "black" } : {}}
                  label={t("Companies.Accounts")}
                  {...a11yProps(3)}
                />
                <Tab
                  style={value !== 4 ? { color: "black" } : {}}
                  label={t("Companies.AdditionalFields")}
                  {...a11yProps(4)}
                />
                <Tab
                  style={value !== 5 ? { color: "black" } : {}}
                  label={t("Companies.RiskAssessment")}
                  {...a11yProps(5)}
                />
                <Tab
                  style={value !== 6 ? { color: "black" } : {}}
                  label={t("Companies.DailyRental")}
                  {...a11yProps(6)}
                />
              </Tabs>
            </Grid>
            <Grid item xs={12} sm={10}>
              <TabPanel value={value} index={0}>
                <CompanyProfile
                  {...compProfile}
                  action={landingPage}
                  deleteAction={companyDeleted}
                />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Content
                  action={landingPage}
                  companyUid={compProfile?.companyUId}
                />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <TravelPolicy
                  action={landingPage}
                  companyUid={compProfile?.companyUId}
                />
              </TabPanel>
              <TabPanel value={value} index={3}>
                <Accounts
                  country={compProfile?.countries?.selectedText}
                  companyUid={compProfile?.companyUId}
                  isCreditCardToggleEnabled={compProfile?.isCreditCardToggleEnabled} 
                  action={landingPage}
                />
              </TabPanel>
              <TabPanel value={value} index={4}>
                <AdditionaFields companyUId={compProfile?.companyUId} />
              </TabPanel>
              <TabPanel value={value} index={5}>
                <RiskAssessment
                  action={landingPage}
                  companyUid={compProfile?.companyUId}
                />
              </TabPanel>
              <TabPanel value={value} index={6}>
                <Notifications
                  action={landingPage}
                  companyUId={compProfile?.companyUId}
                />
              </TabPanel>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Box>
  );
}
