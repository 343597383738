import { Box, Button, Grid, Link, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from "react-i18next";

import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { broadcastAnnouncement } from "components/store/actions/shared/announcementActions";
import { isNullOrWhitespace } from "helpers/stringHelpers";
import { authService } from "services/authService";
import { DriverInfo } from "../../JourneyStart/journeyInputType";
import { maskEmail } from "helpers/emailHelpers";

const useStyles = makeStyles((theme) => ({
  img: {
    margin: "15px",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
    "&:focus": {
      border: "3px solid #006639",
    },
  },
  inputLabel: {
    fontWeight: "bold",
    color: "rgba(0,0,0,0.87)",
    fontSize: "0.8rem",
    lineHeight: "1.43",
  },
  link: {
    fontWeight: "bold",
    textDecoration: "underline",
    cursor: "pointer",
  },
}));

export const CarClubNoDriverMatch: React.FC<any> = (props: any) => {
  const { t } = useTranslation();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const qId = queryParams.get("id");
  const history = useNavigate();
  const classes = useStyles();
  const dispatch = useDispatch();
  const driverInfo: DriverInfo = authService.getDriverInfo();
  const driverDetails = driverInfo.primaryDriver
    ? driverInfo.primaryDriver.firstName +
      " " +
      driverInfo.primaryDriver.lastName +
      ", " +
      (driverInfo?.isBOBOToggleEnabledAndIsDriverDeclaration
        ? maskEmail(driverInfo.primaryDriver.emailAddress)
        : driverInfo.primaryDriver.emailAddress)
    : "";

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setTimeout(() => {
        dispatch(
          broadcastAnnouncement(
            t("ReservationWizard.carClubPage.noMemberId") +
              t("ReservationWizard.driverDetails.labelDriver") +
              ": " +
              driverDetails
          )
        );
      }, 200);
    }
    return () => {
      isMounted = false;
    };
  }, []);
  return (
    <Grid data-testid="carClubNoDriverMatch" container id="carClubNoDriverMatch">
      <Grid container direction="column">
        <Typography variant="h2">
          {t("ReservationWizard.carClubPage.noMemberId")}
        </Typography>
        <br />{" "}
        <Typography style={{ fontWeight: "bold" }}>
          {t("ReservationWizard.driverDetails.labelDriver") +
            ": " +
            driverDetails}
        </Typography>{" "}
        <br />
        <br />{" "}
        <Typography style={{ fontWeight: "bold" }}>
          {t("ReservationWizard.carClubPage.commonReasons")}
        </Typography>{" "}
        <br />{" "}
        <Typography id="action1">
          {t("ReservationWizard.carClubPage.action1")}
        </Typography>{" "}
        <Grid container direction="row">
          <Typography style={{ fontWeight: "bold" }}>
            {t("ReservationWizard.carClubPage.action")}
          </Typography>{" "}
          <Grid item id="carClubStartAgain">
            <Typography display="inline">
              {t("ReservationWizard.carClubPage.startAgainLink1")}
            </Typography>
            <Link
              id="startAgainLink"
              target="_blank"
              tabIndex={0}
              className={classes.link}
              style={{ fontSize: "1.1em" }}
              onClick={() => history("/home?id=" + qId + "&isStartAgain=1")}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  history("/home?id=" + qId + "&isStartAgain=1");
                }
              }}
              display="inline"
              variant="inherit"
            >
              {t("ReservationWizard.carClubPage.startAgainLink2")}
              <Box component="span" className="visuallyHidden">
                {t("ReservationWizard.carClubPage.startAgainLink1") +
                  t("ReservationWizard.carClubPage.startAgainLink2") +
                  t("ReservationWizard.carClubPage.startAgainLink3")}
              </Box>
            </Link>
            <Typography display="inline">
              {t("ReservationWizard.carClubPage.startAgainLink3")}
            </Typography>
          </Grid>{" "}
        </Grid>{" "}
        <br />
        <Typography id="action2">
          {t("ReservationWizard.carClubPage.action2")}
        </Typography>{" "}
        <Grid container direction="row">
          <Typography style={{ fontWeight: "bold" }}>
            {t("ReservationWizard.carClubPage.action")}
          </Typography>{" "}
          <Typography>
            {t("ReservationWizard.carClubPage.action2Details")}
          </Typography>{" "}
        </Grid>{" "}
        <br />
        <Typography id="action3">
          {t("ReservationWizard.carClubPage.action3")}
        </Typography>{" "}
        <Grid container direction="row">
          <Typography style={{ fontWeight: "bold" }}>
            {t("ReservationWizard.carClubPage.action")}
          </Typography>{" "}
          <Typography>
            {t("ReservationWizard.carClubPage.action3Details")}
          </Typography>{" "}
        </Grid>{" "}
        <Grid container direction="row" style={{ marginTop: "5px" }}>
          <Grid
            item
            xs={12}
            md={2}
            style={{
              alignSelf: "center",
            }}
          >
            <Link
              id="carclubregisterhyperlink"
              target="_blank"
              tabIndex={0}
              underline="always"
              href={props.carclubregisterhyperlink}
              className={classes.link}
              variant="inherit"
            >
              {t("ReservationWizard.carClubPage.carClubLinkToRegister")}
              <Box component="span" className="visuallyHidden">
                {" " + t("ReservationWizard.carClubPage.carClubLinkToRegister")}
              </Box>
            </Link>{" "}
          </Grid>
          <Grid item xs={12} md={10}>
            {" "}
            <Button
              id="btnCopyLink"
              color="primary"
              variant="contained"
              disabled={isNullOrWhitespace(props.carclubregisterhyperlink)}
              onClick={() =>
                navigator.clipboard.writeText(props.carclubregisterhyperlink)
              }
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  navigator.clipboard.writeText(props.carclubregisterhyperlink);
                }
              }}
              aria-label={
                t("ReservationWizard.carClubPage.carClubLinkToRegister") +
                " " +
                t("ReservationWizard.carClubPage.btnCopyLink")
              }
            >
              {t("ReservationWizard.carClubPage.btnCopyLink")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
