export interface ReservationEstimate {
  insurance: number,
  transactionFee: number,
  ccEstimate: number,
  availableBalance: number,
  estimate: number,
  timeAmount: number,
  distanceAmount: number,
  feeAmount: number,
  taxAmount: number,
  hourlyRate: number,
  dailyRate: number,
  amountDue:number,
  errorMessages?: [],
  success: boolean
}

export interface DropDownList {
  disabled: boolean;
  group?: any;
  selected: boolean;
  text: string;
  value: string;
}

export interface AdditionalInformationField {
  additionalInformationGid: string;
  sequence: number;
  fieldTypeCode: number;
  fieldName: string;
  helperText: string;
  fieldValue?: any;
  fieldValueDateString: string;
  dropDownList: DropDownList[];
  dropDownListSelectedValue?: any;
  maxLength?: string;
  mandatory: boolean;
  includeInReservation: boolean;
  isBillingRef: boolean;
  customValidate: boolean;
  webApproved: boolean;
}

export interface AdditionalInformation {
  additionalInformationFields: AdditionalInformationField[];
  message: string;
  renderTime?: any;
  errors?: any;
  warnings?: any;
  hasErrors: boolean;
  hasWarnings: boolean;
}

export interface CarClubReservation {
  journeyProfileId: number;
  journeyProfileUId: string;
  vehicleLocation: string;
  vehicleDetails: string;
  startDateString: string;
  endDateString: string;
  memoCode?: any;
  imageUrl: string;
  stackId: number;
  companyUId: string;
  companyId: number;
  driverAuthCodeExpiry: Date;
  isMemoCodeRequired: boolean;
  productAgnosticAccountUId: string;
  additionalInformation: AdditionalInformation | null;
  startDateTime: Date;
  endDateTime: Date;
  firstName?: string;
  lastName?: string;
  email?: string;
  userId: string;
  driverDeclarationId?: number;
  carClubId?: string;
  isPrimaryDriver: boolean;
  reservationEstimate: ReservationEstimate | null;
  bookerName: string;

  errors?: any;
  warnings?: any;
  hasErrors: boolean;
  hasWarnings: boolean;
  additionalDriver: DriverDeclaration[];
}

export const carClubReservationInitialValue = {
  additionalInformation: null,
  companyId: 0,
  companyUId: "",
  driverAuthCodeExpiry: new Date(),
  startDateString: "",
  startDateTime: new Date(),
  endDateString: "",
  endDateTime: new Date(),
  imageUrl: "",
  isMemoCodeRequired: false,
  memoCode: "",
  journeyProfileId: 0,
  journeyProfileUId: "",
  productAgnosticAccountUId: "",
  stackId: 0,
  vehicleDetails: "",
  vehicleLocation: "",
  firstName: "",
  lastName: "",
  email: "",
  userId: "",
  driverDeclarationId: 0,
  carClubId: "",
  isPrimaryDriver: false,
  bookerName: "",
  reservationEstimate: null,
  errors: [],
  warnings: [],
  hasErrors: false,
  hasWarnings: false,
  additionalDriver: []
};

export interface DriverDeclaration {
  driverDeclarationDetailId : number;
  driverDeclarationId? : number;
  companyInternalNumber : number;
  firstName : string;
  lastName : string;
  emailAddress : string;
  driverDeclarationExpiryDate : Date;
  driverDeclarationStatusId? : number;
  driverDeclarationStatus : string;
}
