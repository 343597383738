/* eslint-disable react/jsx-no-target-blank */
import React from "react";

import Grid from "@mui/material/Grid";
import { Button } from "@mui/material";
import { Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import "../views/general.css";
import { authService } from "services/authService";
import { useTranslation } from "react-i18next";
import axiosInstance from "helpers/axiosInstance";
import { useLocation } from "react-router-dom";

import { useDispatch } from "react-redux";
import { clearAlertMessage } from "../components/store/actions/shared/alertActions";
import configParams from "../configParams";
interface ContactInfo {
  AccountManagerName: string;
  AccountManagerEmailAddress: string;
  AccountManagerPhone: string;

  CompanyContactName: string;
  CompanyContactEmailAddress: string;
  CompanyContactPhone: string;
  CustomerServiceName: string;
  CustomerServiceEmailAddress: string;
  CustomerServicePhone: string;
}

const ContactUs = (props) => {
  const [contactInfo, setcontactInfo] = React.useState<null | ContactInfo>();

  const { t } = useTranslation();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const lng = queryParams.get("locale");
  let language = lng !== null ? lng.toString() : "en-GB";
  const baseURL = window.location.hostname.endsWith("etd.enterprise.co.uk")
    ? configParams.API_BASE_URL
    : configParams.API_BASE_URL_EHI;
  const userGuideURL = baseURL + "/userGuide";

  const dispatch = useDispatch();

  async function loadCompanyContact() {
    let userInfo = authService.getUserInfo();
    let companyUId = userInfo["companyUId"];

    await axiosInstance
      .get(`/company/contactus/${companyUId}`)
      .then((response) => {
        setcontactInfo(response.data.data);
      })
      .catch((error) => {
        if (
          error.response != null &&
          error.response.status != null &&
          error.response.status === 400
        ) {
          console.error("There is an error", error);
        } else console.error("There is an error", error);
      });
  }

  React.useEffect(() => {
    dispatch(clearAlertMessage());
    document.title = "Enterprise Rent-A-Car - CONTACT US";
  });

  React.useEffect(() => {
    loadCompanyContact();
  }, [props]);

  const ContactDetailsTable = (t) => {
    return (
      <div>
        <TableContainer id="conttactTable">
          <Table
            aria-label="contact table"
            style={{ width: "auto", tableLayout: "auto" }}
          >
            <TableBody style={{ width: "100%" }}>
              {/* United Kingdom */}
              <TableRow>
                <TableCell
                  width="10%"
                  style={{
                    padding: 0,
                    paddingLeft: 20,
                    borderBottom: "none",
                    fontWeight: "bold",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {t("ContactUs.UK")}{" "}
                </TableCell>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    fontWeight: "bold",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {t("ContactUs.ETDHelpdesk")}{" "}
                </TableCell>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {" "}
                </TableCell>
                <TableCell
                  width="55%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {" "}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    paddingLeft: 20,
                    borderBottom: "none",
                    fontWeight: "bold",
                    border: "0px solid green",
                  }}
                  align="left"
                ></TableCell>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    minWidth: "150px",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {t("ContactUs.Phone")} :
                </TableCell>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  0044 800 783 9445
                </TableCell>
                <TableCell
                  width="55%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {" "}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    paddingLeft: 20,
                    borderBottom: "none",
                    fontWeight: "bold",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {" "}
                </TableCell>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {t("ContactUs.Email")}:
                </TableCell>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  <a
                    href="mailto:ApplicationHelpdesk@ehi.com"
                    style={{ textDecorationLine: "underline", color: "blue" }}
                  >
                    ApplicationHelpdesk@ehi.com
                  </a>
                </TableCell>
                <TableCell
                  width="55%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {" "}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    paddingLeft: 20,
                    borderBottom: "none",
                    fontWeight: "bold",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {" "}
                </TableCell>
                <TableCell
                  width="28%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    fontWeight: "bold",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {t("ContactUs.BusinessRentalSupportTeam")}{" "}
                </TableCell>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {" "}
                </TableCell>
                <TableCell
                  width="55%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {" "}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    paddingLeft: 20,
                    borderBottom: "none",
                    fontWeight: "bold",
                    border: "0px solid green",
                  }}
                  align="left"
                ></TableCell>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    minWidth: "150px",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {t("ContactUs.Phone")}:
                </TableCell>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  0044​ 344 335 0283
                </TableCell>
                <TableCell
                  width="55%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {" "}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    paddingLeft: 20,
                    borderBottom: "none",
                    fontWeight: "bold",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {" "}
                </TableCell>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {t("ContactUs.Email")}:
                </TableCell>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  <a
                    href="mailto:BusinessRentalSupport@ehi.com"
                    style={{ textDecorationLine: "underline", color: "blue" }}
                  >
                    BusinessRentalSupport@ehi.com
                  </a>
                </TableCell>
                <TableCell
                  width="55%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {" "}
                </TableCell>
              </TableRow>
              <TableRow style={{ height: 10 }}>
                {" "}
                <TableCell
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                ></TableCell>
                <TableCell
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                ></TableCell>
                <TableCell
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                ></TableCell>
                <TableCell
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                ></TableCell>
              </TableRow>
              {/* Republic of Ireland */}
              <TableRow>
                <TableCell
                  width="10%"
                  style={{
                    padding: 0,
                    paddingLeft: 20,
                    borderBottom: "none",
                    fontWeight: "bold",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {t("ContactUs.IE")}{" "}
                </TableCell>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    fontWeight: "bold",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {t("ContactUs.ETDHelpdesk")}{" "}
                </TableCell>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {" "}
                </TableCell>
                <TableCell
                  width="55%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {" "}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    paddingLeft: 20,
                    borderBottom: "none",
                    fontWeight: "bold",
                    border: "0px solid green",
                  }}
                  align="left"
                ></TableCell>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    minWidth: "150px",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {t("ContactUs.Phone")}:
                </TableCell>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  0044 800 783 9445
                </TableCell>
                <TableCell
                  width="55%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {" "}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    paddingLeft: 20,
                    borderBottom: "none",
                    fontWeight: "bold",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {" "}
                </TableCell>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {t("ContactUs.Email")}:
                </TableCell>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  <a
                    href="mailto:ApplicationHelpdesk@ehi.com"
                    style={{ textDecorationLine: "underline", color: "blue" }}
                  >
                    ApplicationHelpdesk@ehi.com
                  </a>
                </TableCell>
                <TableCell
                  width="55%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {" "}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    paddingLeft: 20,
                    borderBottom: "none",
                    fontWeight: "bold",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {" "}
                </TableCell>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    fontWeight: "bold",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {t("ContactUs.BusinessRentalSupportTeam")}{" "}
                </TableCell>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {" "}
                </TableCell>
                <TableCell
                  width="55%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {" "}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    paddingLeft: 20,
                    borderBottom: "none",
                    fontWeight: "bold",
                    border: "0px solid green",
                  }}
                  align="left"
                ></TableCell>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    minWidth: "150px",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {t("ContactUs.Phone")}:
                </TableCell>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  0044​ 344 335 0283
                </TableCell>
                <TableCell
                  width="55%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {" "}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    paddingLeft: 20,
                    borderBottom: "none",
                    fontWeight: "bold",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {" "}
                </TableCell>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {t("ContactUs.Email")}:
                </TableCell>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  <a
                    href="mailto:BusinessRentalSupport@ehi.com"
                    style={{ textDecorationLine: "underline", color: "blue" }}
                  >
                    BusinessRentalSupport@ehi.com
                  </a>
                </TableCell>
                <TableCell
                  width="55%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {" "}
                </TableCell>
              </TableRow>
              <TableRow style={{ height: 10 }}>
                {" "}
                <TableCell
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                ></TableCell>
                <TableCell
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                ></TableCell>
                <TableCell
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                ></TableCell>
                <TableCell
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                ></TableCell>
              </TableRow>

              {/* France */}
              <TableRow>
                <TableCell
                  width="10%"
                  style={{
                    padding: 0,
                    paddingLeft: 20,
                    borderBottom: "none",
                    fontWeight: "bold",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {t("ContactUs.FR")}{" "}
                </TableCell>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    fontWeight: "bold",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {t("ContactUs.ETDHelpdesk")}{" "}
                </TableCell>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {" "}
                </TableCell>
                <TableCell
                  width="55%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {" "}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    paddingLeft: 20,
                    borderBottom: "none",
                    fontWeight: "bold",
                    border: "0px solid green",
                  }}
                  align="left"
                ></TableCell>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    minWidth: "150px",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {t("ContactUs.Phone")}:
                </TableCell>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  0033 801 840 954
                </TableCell>
                <TableCell
                  width="55%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {" "}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    paddingLeft: 20,
                    borderBottom: "none",
                    fontWeight: "bold",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {" "}
                </TableCell>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {t("ContactUs.Email")}:
                </TableCell>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  <a
                    href="mailto:ApplicationHelpdesk@ehi.com"
                    style={{ textDecorationLine: "underline", color: "blue" }}
                  >
                    ApplicationHelpdesk@ehi.com
                  </a>
                </TableCell>
                <TableCell
                  width="55%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {" "}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    paddingLeft: 20,
                    borderBottom: "none",
                    fontWeight: "bold",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {" "}
                </TableCell>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    fontWeight: "bold",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {t("ContactUs.BusinessRentalSupportTeam")}{" "}
                </TableCell>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {" "}
                </TableCell>
                <TableCell
                  width="55%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {" "}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    paddingLeft: 20,
                    borderBottom: "none",
                    fontWeight: "bold",
                    border: "0px solid green",
                  }}
                  align="left"
                ></TableCell>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    minWidth: "150px",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {t("ContactUs.Phone")}:
                </TableCell>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  0044​ 344 335 0283
                </TableCell>
                <TableCell
                  width="55%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {" "}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    paddingLeft: 20,
                    borderBottom: "none",
                    fontWeight: "bold",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {" "}
                </TableCell>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {t("ContactUs.Email")}:
                </TableCell>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  <a
                    href="mailto:BusinessRentalSupport@ehi.com"
                    style={{ textDecorationLine: "underline", color: "blue" }}
                  >
                    BusinessRentalSupport@ehi.com
                  </a>
                </TableCell>
                <TableCell
                  width="55%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {" "}
                </TableCell>
              </TableRow>
              <TableRow style={{ height: 10 }}>
                {" "}
                <TableCell
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                ></TableCell>
                <TableCell
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                ></TableCell>
                <TableCell
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                ></TableCell>
                <TableCell
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                ></TableCell>
              </TableRow>
              {/* Germany */}
              <TableRow>
                <TableCell
                  width="10%"
                  style={{
                    padding: 0,
                    paddingLeft: 20,
                    borderBottom: "none",
                    fontWeight: "bold",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {t("ContactUs.DE")}{" "}
                </TableCell>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    fontWeight: "bold",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {t("ContactUs.ETDHelpdesk")}{" "}
                </TableCell>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {" "}
                </TableCell>
                <TableCell
                  width="55%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {" "}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    paddingLeft: 20,
                    borderBottom: "none",
                    fontWeight: "bold",
                    border: "0px solid green",
                  }}
                  align="left"
                ></TableCell>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    minWidth: "150px",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {t("ContactUs.Phone")}:
                </TableCell>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  ​0049 800 00 02 618
                </TableCell>
                <TableCell
                  width="55%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {" "}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    paddingLeft: 20,
                    borderBottom: "none",
                    fontWeight: "bold",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {" "}
                </TableCell>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {t("ContactUs.Email")}:
                </TableCell>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  <a
                    href="mailto:	​Germany.ETD@ehi.com"
                    style={{ textDecorationLine: "underline", color: "blue" }}
                  >
                    ​Germany.ETD@ehi.com
                  </a>
                </TableCell>
                <TableCell
                  width="55%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {" "}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    paddingLeft: 20,
                    borderBottom: "none",
                    fontWeight: "bold",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {" "}
                </TableCell>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    fontWeight: "bold",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {t("ContactUs.BusinessRentalSupportTeam")}{" "}
                </TableCell>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {" "}
                </TableCell>
                <TableCell
                  width="55%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {" "}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    paddingLeft: 20,
                    borderBottom: "none",
                    fontWeight: "bold",
                    border: "0px solid green",
                  }}
                  align="left"
                ></TableCell>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    minWidth: "150px",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {t("ContactUs.Phone")}:
                </TableCell>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  0049 6196 7698 6285
                </TableCell>
                <TableCell
                  width="55%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {" "}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    paddingLeft: 20,
                    borderBottom: "none",
                    fontWeight: "bold",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {" "}
                </TableCell>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {t("ContactUs.Email")}:
                </TableCell>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  <a
                    href="mailto:	​team@ehisales.com"
                    style={{ textDecorationLine: "underline", color: "blue" }}
                  >
                    ​team@ehisales.com
                  </a>
                </TableCell>
                <TableCell
                  width="55%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {" "}
                </TableCell>
              </TableRow>
              <TableRow style={{ height: 10 }}>
                {" "}
                <TableCell
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                ></TableCell>
                <TableCell
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                ></TableCell>
                <TableCell
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                ></TableCell>
                <TableCell
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                ></TableCell>
              </TableRow>
              {/* Spain */}
              <TableRow>
                <TableCell
                  width="10%"
                  style={{
                    padding: 0,
                    paddingLeft: 20,
                    borderBottom: "none",
                    fontWeight: "bold",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {t("ContactUs.ES")}{" "}
                </TableCell>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    fontWeight: "bold",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {t("ContactUs.ETDHelpdesk")}{" "}
                </TableCell>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {" "}
                </TableCell>
                <TableCell
                  width="55%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {" "}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    paddingLeft: 20,
                    borderBottom: "none",
                    fontWeight: "bold",
                    border: "0px solid green",
                  }}
                  align="left"
                ></TableCell>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    minWidth: "150px",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {t("ContactUs.Phone")}:
                </TableCell>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  0034 900 43 1593
                </TableCell>
                <TableCell
                  width="55%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {" "}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    paddingLeft: 20,
                    borderBottom: "none",
                    fontWeight: "bold",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {" "}
                </TableCell>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {t("ContactUs.Email")}:
                </TableCell>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  <a
                    href="mailto:​ApplicationHelpdesk@ehi.com"
                    style={{ textDecorationLine: "underline", color: "blue" }}
                  >
                    ​ApplicationHelpdesk@ehi.com
                  </a>
                </TableCell>
                <TableCell
                  width="55%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {" "}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    paddingLeft: 20,
                    borderBottom: "none",
                    fontWeight: "bold",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {" "}
                </TableCell>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    fontWeight: "bold",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {t("ContactUs.BusinessRentalSupportTeam")}{" "}
                </TableCell>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {" "}
                </TableCell>
                <TableCell
                  width="55%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {" "}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    paddingLeft: 20,
                    borderBottom: "none",
                    fontWeight: "bold",
                    border: "0px solid green",
                  }}
                  align="left"
                ></TableCell>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    minWidth: "150px",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {t("ContactUs.Phone")}:
                </TableCell>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  0044​ 344 335 0283
                </TableCell>
                <TableCell
                  width="55%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {" "}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    paddingLeft: 20,
                    borderBottom: "none",
                    fontWeight: "bold",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {" "}
                </TableCell>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {t("ContactUs.Email")}:
                </TableCell>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  <a
                    href="mailto:	​	​BusinessRentalSupport@ehi.com"
                    style={{ textDecorationLine: "underline", color: "blue" }}
                  >
                    ​BusinessRentalSupport@ehi.com
                  </a>
                </TableCell>
                <TableCell
                  width="55%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {" "}
                </TableCell>
              </TableRow>
              <TableRow style={{ height: 10 }}>
                {" "}
                <TableCell
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                ></TableCell>
                <TableCell
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                ></TableCell>
                <TableCell
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                ></TableCell>
                <TableCell
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                ></TableCell>
              </TableRow>
              {/* United States */}
              <TableRow>
                <TableCell
                  width="10%"
                  style={{
                    padding: 0,
                    paddingLeft: 20,
                    borderBottom: "none",
                    fontWeight: "bold",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {t("ContactUs.US")}{" "}
                </TableCell>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    fontWeight: "bold",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {t("ContactUs.ETDHelpdesk")}{" "}
                </TableCell>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {" "}
                </TableCell>
                <TableCell
                  width="55%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {" "}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    paddingLeft: 20,
                    borderBottom: "none",
                    fontWeight: "bold",
                    border: "0px solid green",
                  }}
                  align="left"
                ></TableCell>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    minWidth: "150px",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {t("ContactUs.Phone")}:
                </TableCell>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  1-855-573-0012
                </TableCell>
                <TableCell
                  width="55%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {" "}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    paddingLeft: 20,
                    borderBottom: "none",
                    fontWeight: "bold",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {" "}
                </TableCell>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {t("ContactUs.Email")}:
                </TableCell>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  <a
                    href="mailto:	etdsupport@ehi.com"
                    style={{ textDecorationLine: "underline", color: "blue" }}
                  >
                    etdsupport@ehi.com
                  </a>
                </TableCell>
                <TableCell
                  width="55%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {" "}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    paddingLeft: 20,
                    borderBottom: "none",
                    fontWeight: "bold",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {" "}
                </TableCell>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    fontWeight: "bold",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {t("ContactUs.BusinessRentalSupportTeam")}{" "}
                </TableCell>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {" "}
                </TableCell>
                <TableCell
                  width="55%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {" "}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    paddingLeft: 20,
                    borderBottom: "none",
                    fontWeight: "bold",
                    border: "0px solid green",
                  }}
                  align="left"
                ></TableCell>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    minWidth: "150px",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {t("ContactUs.Phone")}:
                </TableCell>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  1-855-573-0012
                </TableCell>
                <TableCell
                  width="55%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {" "}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    paddingLeft: 20,
                    borderBottom: "none",
                    fontWeight: "bold",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {" "}
                </TableCell>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {t("ContactUs.Email")}:
                </TableCell>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  <a
                    href="mailto:etdsupport@ehi.com"
                    style={{ textDecorationLine: "underline", color: "blue" }}
                  >
                    etdsupport@ehi.com
                  </a>
                </TableCell>
                <TableCell
                  width="55%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {" "}
                </TableCell>
              </TableRow>
              <TableRow style={{ height: 10 }}>
                {" "}
                <TableCell
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                ></TableCell>
                <TableCell
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                ></TableCell>
                <TableCell
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                ></TableCell>
                <TableCell
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                ></TableCell>
              </TableRow>
              {/* Canada */}
              <TableRow>
                <TableCell
                  width="10%"
                  style={{
                    padding: 0,
                    paddingLeft: 20,
                    borderBottom: "none",
                    fontWeight: "bold",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {t("ContactUs.CA")}{" "}
                </TableCell>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    fontWeight: "bold",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {t("ContactUs.ETDHelpdesk")}{" "}
                </TableCell>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {" "}
                </TableCell>
                <TableCell
                  width="55%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {" "}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    paddingLeft: 20,
                    borderBottom: "none",
                    fontWeight: "bold",
                    border: "0px solid green",
                  }}
                  align="left"
                ></TableCell>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    minWidth: "150px",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {t("ContactUs.Phone")}:
                </TableCell>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  1-855-573-0012
                </TableCell>
                <TableCell
                  width="55%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {" "}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    paddingLeft: 20,
                    borderBottom: "none",
                    fontWeight: "bold",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {" "}
                </TableCell>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {t("ContactUs.Email")}:
                </TableCell>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  <a
                    href="mailto:	etdsupport@ehi.com"
                    style={{ textDecorationLine: "underline", color: "blue" }}
                  >
                    etdsupport@ehi.com
                  </a>
                </TableCell>
                <TableCell
                  width="55%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {" "}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    paddingLeft: 20,
                    borderBottom: "none",
                    fontWeight: "bold",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {" "}
                </TableCell>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    fontWeight: "bold",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {t("ContactUs.BusinessRentalSupportTeam")}{" "}
                </TableCell>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {" "}
                </TableCell>
                <TableCell
                  width="55%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {" "}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    paddingLeft: 20,
                    borderBottom: "none",
                    fontWeight: "bold",
                    border: "0px solid green",
                  }}
                  align="left"
                ></TableCell>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    minWidth: "150px",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {t("ContactUs.Phone")}:
                </TableCell>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  1-855-573-0012
                </TableCell>
                <TableCell
                  width="55%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {" "}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    paddingLeft: 20,
                    borderBottom: "none",
                    fontWeight: "bold",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {" "}
                </TableCell>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {t("ContactUs.Email")}:
                </TableCell>
                <TableCell
                  width="25%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  <a
                    href="mailto:etdsupport@ehi.com"
                    style={{ textDecorationLine: "underline", color: "blue" }}
                  >
                    etdsupport@ehi.com
                  </a>
                </TableCell>
                <TableCell
                  width="55%"
                  style={{
                    padding: 0,
                    borderBottom: "none",
                    border: "0px solid green",
                  }}
                  align="left"
                >
                  {" "}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  };
  if (language === "de-DE") {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <div>
            <Button
              variant="outlined"
              color="primary"
              title={t("ClicktoPrintThisPage")}
              aria-label={t("ClicktoPrintThisPage")}
              onClick={() => {
                window.print();
              }}
            >
              {" "}
              {t("Footer.ClicktoPrintThisPage")}
            </Button>
          </div>

          <Typography variant="h1" className="paddingtophalfcm" gutterBottom>
            Kontakt
          </Typography>
          <Typography
            className="paddingLefthalfcm"
            variant="body2"
            gutterBottom
          >
            Bei Fragen zu Ihrem Firmenprofil kontaktieren Sie bitte:
          </Typography>
          <div className="width30  paddingLeft2cm paddingbottomhalfcm body2">
            <TableContainer>
              <Table aria-label="contact table">
                <TableBody>
                  <TableRow>
                    <TableCell
                      style={{ padding: 0, borderBottom: "none" }}
                      align="left"
                    >
                      Firmenkontakt:{" "}
                    </TableCell>
                    <TableCell
                      style={{ padding: 0, borderBottom: "none" }}
                      align="left"
                    >
                      {contactInfo ? contactInfo["companyContactName"] : ""}
                    </TableCell>
                  </TableRow>
                  <TableRow style={{ height: 5 }}>
                    <TableCell
                      style={{ padding: 0, borderBottom: "none" }}
                      align="left"
                    >
                      Email Addresse:
                    </TableCell>
                    <TableCell
                      style={{ padding: 0, borderBottom: "none" }}
                      align="left"
                    >
                      <a
                        href={
                          "mailto:" +
                          (contactInfo
                            ? contactInfo["companyContactEmailAddress"]
                            : "")
                        }
                      >
                        {" "}
                        {contactInfo
                          ? contactInfo["companyContactEmailAddress"]
                          : ""}
                      </a>
                    </TableCell>
                  </TableRow>
                  <TableRow style={{ height: 5 }}>
                    <TableCell
                      style={{ padding: 0, borderBottom: "none" }}
                      align="left"
                    >
                      Telefon:
                    </TableCell>
                    <TableCell
                      style={{ padding: 0, borderBottom: "none" }}
                      align="left"
                    >
                      {contactInfo ? contactInfo["companyContactPhone"] : ""}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <div className="width30  paddingLeft2cm paddingbottomhalfcm body2">
            <TableContainer>
              <Table className="tableStyle" aria-label="contact table">
                <TableBody>
                  <TableRow>
                    <TableCell
                      style={{ padding: 0, borderBottom: "none" }}
                      align="left"
                    >
                      Enterprise Kontakt:{" "}
                    </TableCell>
                    <TableCell
                      style={{ padding: 0, borderBottom: "none" }}
                      align="left"
                    >
                      {contactInfo ? contactInfo["accountManagerName"] : ""}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      style={{ padding: 0, borderBottom: "none" }}
                      align="left"
                    >
                      Email Addresse:
                    </TableCell>
                    <TableCell
                      style={{ padding: 0, borderBottom: "none" }}
                      align="left"
                    >
                      <a
                        href={
                          "mailto:" +
                          (contactInfo
                            ? contactInfo["accountManagerEmailAddress"]
                            : "")
                        }
                      >
                        {" "}
                        {contactInfo
                          ? contactInfo["accountManagerEmailAddress"]
                          : ""}
                      </a>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      style={{ padding: 0, borderBottom: "none" }}
                      align="left"
                    >
                      Telefon:
                    </TableCell>
                    <TableCell
                      style={{ padding: 0, borderBottom: "none" }}
                      align="left"
                    >
                      {contactInfo ? contactInfo["accountManagerPhone"] : ""}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <div className="width30  paddingLeft2cm   body2">
            <TableContainer>
              <Table className="tableStyle" aria-label="contact table">
                <TableBody>
                  <TableRow>
                    <TableCell
                      style={{ padding: 0, borderBottom: "none" }}
                      align="left"
                    >
                      Kundenservice Kontakt:{" "}
                    </TableCell>
                    <TableCell
                      style={{ padding: 0, borderBottom: "none" }}
                      align="left"
                    >
                      {contactInfo ? contactInfo["customerServiceName"] : ""}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      style={{ padding: 0, borderBottom: "none" }}
                      align="left"
                    >
                      Email Addresse:
                    </TableCell>

                    <TableCell
                      style={{ padding: 0, borderBottom: "none" }}
                      align="left"
                    >
                      <a
                        href={
                          "mailto:" +
                          (contactInfo
                            ? contactInfo["customerServiceEmailAddress"]
                            : "")
                        }
                      >
                        {" "}
                        {contactInfo
                          ? contactInfo["customerServiceEmailAddress"]
                          : ""}
                      </a>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      style={{ padding: 0, borderBottom: "none" }}
                      align="left"
                    >
                      Telefon:
                    </TableCell>
                    <TableCell
                      style={{ padding: 0, borderBottom: "none" }}
                      align="left"
                    >
                      {contactInfo ? contactInfo["customerServicePhone"] : ""}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            Fragen zu Ihrer Anmietung?
          </Typography>
          <Typography
            className="paddingLefthalfcm"
            variant="body2"
            gutterBottom
          >
            Wenn Sie Fragen oder Anmerkungen zu Ihrer Anmietung haben oder Hilfe
            benötigen (z.B. Wünsche zur Abgabe oder Absprache bzgl. der Abholung
            Ihres Mietwagens) kontaktieren Sie bitte die Filiale, in der Sie das
            Fahrzeug angemietet haben.
          </Typography>
          <Typography
            className="paddingLefthalfcm"
            variant="body2"
            gutterBottom
          >
            Details zur Filiale können Sie auf Ihrer Reservierungsbestätigung
            oder auf der Reservierungsübersicht auf Enterprise Travel Direct
            finden.
          </Typography>
          <Typography variant="h6" className="paddingtophalfcm" gutterBottom>
            Benötigen Sie Hilfe bei der Verwendung ETD?
          </Typography>
          <Typography
            className="paddingLefthalfcm"
            variant="body2"
            gutterBottom
          >
            Wenn Sie Schwierigkeiten bei der Nutzung der Enterprise Travel
            Direct-Anwendung haben, lesen Sie bitte zunächst unser{" "}
            <a
              href={userGuideURL}
              target="_blank"
              style={{ textDecorationLine: "underline", color: "blue" }}
            >
              FAQ
            </a>
            . Hier finden Sie Anleitungen und häufig gestellte Fragen zu den
            Funktionen dieser Anwendung. <br /> Wenn Sie Hilfe bei der
            Verwendung des Enterprise Travel Direct-Reservierungstools
            benötigen, wenden Sie sich bitte an unseren{" "}
            <span>
              <b>{t("ContactUs.ETDHelpdesk")}</b>
            </span>{" "}
            aus dem jeweiligen Land, in dem Sie sich befinden.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            ETD und andere Lösungen
          </Typography>
          <Typography
            className="paddingLefthalfcm"
            variant="body2"
            gutterBottom
          >
            Die richtige Entscheidung, jedes Mal. Jede Organisation ist anders.
            Für manche haben die Kosten Vorrang. Andere wiederum legen mehr Wert
            auf das Reduzieren von Risikofaktoren. Um Unternehmen aller
            Größenordnungen dabei zu helfen, die Mitarbeitermobilität effektiver
            zu verwalten, haben wir Enterprise Travel Direct (ETD) entwickelt,
            ein intelligentes Entscheidungstool, das Fahrern hilft, die richtige
            Transportoption für jede Reise auszuwählen. Die von ETD gelieferten
            Antworten basieren auf einzigartigen Entscheidungsalgorithmen, die
            im Vorfeld entwickelt, getestet und abgestimmt wurden.
          </Typography>
          <br />
          <Typography
            className="paddingLefthalfcm"
            variant="body2"
            gutterBottom
          >
            <span>
              Möchten Sie mehr über das Enterprise Travel Direct
              Reservierungstool oder andere Business Rental-Lösungen von
              Enterprise Holdings erfahren? Bitte wenden Sie sich an Ihren
              lokalen Business Rental Sales Executive oder kontaktieren Sie
              unser{" "}
            </span>{" "}
            <span>
              <b>{t("ContactUs.BusinessRentalSupportTeam")}</b>
            </span>{" "}
            aus dem jeweiligen Land, in dem Sie sich befinden.
          </Typography>
          <br />
          {ContactDetailsTable(t)}
        </Grid>
      </Grid>
    );
  } else if (language === "fr-FR") {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <div>
            <Button
              variant="outlined"
              color="primary"
              title={t("ClicktoPrintThisPage")}
              aria-label={t("ClicktoPrintThisPage")}
              onClick={() => {
                window.print();
              }}
            >
              {" "}
              {t("Footer.ClicktoPrintThisPage")}
            </Button>
          </div>
          <Typography variant="h1" className="paddingtophalfcm" gutterBottom>
            Nous contacter
          </Typography>
          <Typography
            className="paddingLefthalfcm"
            variant="body2"
            gutterBottom
          >
            Pour les questions concernant votre compte et d'autres éléments non
            liés à l'application:
          </Typography>
          <div className="width30  paddingLeft2cm paddingbottomhalfcm body2">
            <TableContainer>
              <Table aria-label="contact table">
                <TableBody>
                  <TableRow>
                    <TableCell
                      style={{ padding: 0, borderBottom: "none" }}
                      align="left"
                    >
                      Coordonnées EHI:{" "}
                    </TableCell>
                    <TableCell
                      style={{ padding: 0, borderBottom: "none" }}
                      align="left"
                    >
                      {contactInfo ? contactInfo["companyContactName"] : ""}
                    </TableCell>
                  </TableRow>
                  <TableRow style={{ height: 5 }}>
                    <TableCell
                      style={{ padding: 0, borderBottom: "none" }}
                      align="left"
                    >
                      Adresse e-mail:
                    </TableCell>
                    <TableCell
                      style={{ padding: 0, borderBottom: "none" }}
                      align="left"
                    >
                      <a
                        href={
                          "mailto:" +
                          (contactInfo
                            ? contactInfo["companyContactEmailAddress"]
                            : "")
                        }
                      >
                        {" "}
                        {contactInfo
                          ? contactInfo["companyContactEmailAddress"]
                          : ""}
                      </a>
                    </TableCell>
                  </TableRow>
                  <TableRow style={{ height: 5 }}>
                    <TableCell
                      style={{ padding: 0, borderBottom: "none" }}
                      align="left"
                    >
                      Téléphoner:
                    </TableCell>
                    <TableCell
                      style={{ padding: 0, borderBottom: "none" }}
                      align="left"
                    >
                      {contactInfo ? contactInfo["companyContactPhone"] : ""}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <div className="width30  paddingLeft2cm paddingbottomhalfcm body2">
            <TableContainer>
              <Table className="tableStyle" aria-label="contact table">
                <TableBody>
                  <TableRow>
                    <TableCell
                      style={{ padding: 0, borderBottom: "none" }}
                      align="left"
                    >
                      Coordonnées EHI:{" "}
                    </TableCell>
                    <TableCell
                      style={{ padding: 0, borderBottom: "none" }}
                      align="left"
                    >
                      {contactInfo ? contactInfo["accountManagerName"] : ""}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      style={{ padding: 0, borderBottom: "none" }}
                      align="left"
                    >
                      Adresse e-mail:
                    </TableCell>
                    <TableCell
                      style={{ padding: 0, borderBottom: "none" }}
                      align="left"
                    >
                      <a
                        href={
                          "mailto:" +
                          (contactInfo
                            ? contactInfo["accountManagerEmailAddress"]
                            : "")
                        }
                      >
                        {" "}
                        {contactInfo
                          ? contactInfo["accountManagerEmailAddress"]
                          : ""}
                      </a>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      style={{ padding: 0, borderBottom: "none" }}
                      align="left"
                    >
                      Téléphoner:
                    </TableCell>
                    <TableCell
                      style={{ padding: 0, borderBottom: "none" }}
                      align="left"
                    >
                      {contactInfo ? contactInfo["accountManagerPhone"] : ""}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <div className="width30  paddingLeft2cm   body2">
            <TableContainer>
              <Table className="tableStyle" aria-label="contact table">
                <TableBody>
                  <TableRow>
                    <TableCell
                      style={{ padding: 0, borderBottom: "none" }}
                      align="left"
                    >
                      Contacter le service client:{" "}
                    </TableCell>
                    <TableCell
                      style={{ padding: 0, borderBottom: "none" }}
                      align="left"
                    >
                      {contactInfo ? contactInfo["customerServiceName"] : ""}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      style={{ padding: 0, borderBottom: "none" }}
                      align="left"
                    >
                      Adresse e-mail:
                    </TableCell>

                    <TableCell
                      style={{ padding: 0, borderBottom: "none" }}
                      align="left"
                    >
                      <a
                        href={
                          "mailto:" +
                          (contactInfo
                            ? contactInfo["customerServiceEmailAddress"]
                            : "")
                        }
                      >
                        {" "}
                        {contactInfo
                          ? contactInfo["customerServiceEmailAddress"]
                          : ""}
                      </a>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      style={{ padding: 0, borderBottom: "none" }}
                      align="left"
                    >
                      Téléphoner:
                    </TableCell>
                    <TableCell
                      style={{ padding: 0, borderBottom: "none" }}
                      align="left"
                    >
                      {contactInfo ? contactInfo["customerServicePhone"] : ""}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            Des questions concernant votre réservation ?
          </Typography>
          <Typography
            className="paddingLefthalfcm"
            variant="body2"
            gutterBottom
          >
            Si vous avez des questions concernant votre réservation, telles que
            des informations spécifiques pour la livraison ou l’organisation
            d’une reprise, veuillez contacter l'agence de location désignée pour
            votre réservation.
          </Typography>
          <Typography
            className="paddingLefthalfcm"
            variant="body2"
            gutterBottom
          >
            Les détails de l’agence de location se trouvent sur votre e-mail de
            confirmation de réservation ou sur l'écran Résumé de la réservation
            lorsque vous consultez les réservations dans Enterprise Travel
            Direct.
          </Typography>
          <Typography variant="h6" className="paddingtophalfcm" gutterBottom>
            Besoin d'aide pour utiliser ETD ?
          </Typography>
          <Typography
            className="paddingLefthalfcm"
            variant="body2"
            gutterBottom
          >
            Si vous rencontrez des difficultés à utiliser l'application
            Enterprise Travel Direct, veuillez d'abord consulter notre Foire aux
            questions{" "}
            <a
              href={userGuideURL}
              target="_blank"
              style={{ textDecorationLine: "underline", color: "blue" }}
            >
              (FAQ)
            </a>
            . Vous y trouverez des conseils et des réponses aux questions les
            plus courantes concernant les fonctionnalités de cet outil. <br />
            Pour obtenir de l'aide sur l'utilisation de l'outil de réservation
            Enterprise Travel Direct, veuillez contacter notre{" "}
            <span>
              <b>{t("ContactUs.ETDHelpdesk")}</b>
            </span>{" "}
            par pays.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            ETD et autres solutions
          </Typography>
          <Typography
            className="paddingLefthalfcm"
            variant="body2"
            gutterBottom
          >
            La bonne décision, en toutes circonstances. Chaque société est
            différente. Pour certains, rien n’est plus important que les coûts.
            D’autres peuvent accorder plus d’importance aux facteurs de risque.
            Pour aider les entreprises de toutes tailles à gérer plus
            efficacement la mobilité des employés, nous avons développé
            Enterprise Travel Direct (ETD), un outil de décision intelligent qui
            aide les conducteurs à choisir l’option de transport adaptée à
            chaque déplacement. Les réponses fournies par ETD sont basées sur
            des algorithmes exclusifs de prise de décisions, développés, testés
            et convenus à l’avance.
          </Typography>
          <br />
          <Typography
            className="paddingLefthalfcm"
            variant="body2"
            gutterBottom
          >
            <span>
              Souhaitez-vous en savoir plus sur l'outil de réservation
              Enterprise Travel Direct ou sur d'autres solutions de location
              d'entreprise proposées par Enterprise Holdings ? Veuillez
              contacter votre responsable des ventes de location d'entreprise
              local ou contacter notre
            </span>{" "}
            <span>
              <b>{t("ContactUs.BusinessRentalSupportTeam")}</b>
            </span>{" "}
            par pays.
          </Typography>
          <br />
          {ContactDetailsTable(t)}
        </Grid>
      </Grid>
    );
  } else if (language === "fr-CA") {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <div>
            <Button
              variant="outlined"
              color="primary"
              title={t("ClicktoPrintThisPage")}
              aria-label={t("ClicktoPrintThisPage")}
              onClick={() => {
                window.print();
              }}
            >
              {" "}
              {t("Footer.ClicktoPrintThisPage")}
            </Button>
          </div>
          <Typography variant="h1" className="paddingtophalfcm" gutterBottom>
            Nous contacter
          </Typography>
          <Typography
            className="paddingLefthalfcm"
            variant="body2"
            gutterBottom
          >
            Pour les questions concernant votre compte et d'autres éléments non
            liés à l'application:
          </Typography>
          <div className="width30  paddingLeft2cm paddingbottomhalfcm body2">
            <TableContainer>
              <Table aria-label="contact table">
                <TableBody>
                  <TableRow>
                    <TableCell
                      style={{ padding: 0, borderBottom: "none" }}
                      align="left"
                    >
                      Coordonnées EHI:{" "}
                    </TableCell>
                    <TableCell
                      style={{ padding: 0, borderBottom: "none" }}
                      align="left"
                    >
                      {contactInfo ? contactInfo["companyContactName"] : ""}
                    </TableCell>
                  </TableRow>
                  <TableRow style={{ height: 5 }}>
                    <TableCell
                      style={{ padding: 0, borderBottom: "none" }}
                      align="left"
                    >
                      Adresse e-mail:
                    </TableCell>
                    <TableCell
                      style={{ padding: 0, borderBottom: "none" }}
                      align="left"
                    >
                      <a
                        href={
                          "mailto:" +
                          (contactInfo
                            ? contactInfo["companyContactEmailAddress"]
                            : "")
                        }
                      >
                        {" "}
                        {contactInfo
                          ? contactInfo["companyContactEmailAddress"]
                          : ""}
                      </a>
                    </TableCell>
                  </TableRow>
                  <TableRow style={{ height: 5 }}>
                    <TableCell
                      style={{ padding: 0, borderBottom: "none" }}
                      align="left"
                    >
                      Téléphoner:
                    </TableCell>
                    <TableCell
                      style={{ padding: 0, borderBottom: "none" }}
                      align="left"
                    >
                      {contactInfo ? contactInfo["companyContactPhone"] : ""}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <div className="width30  paddingLeft2cm paddingbottomhalfcm body2">
            <TableContainer>
              <Table className="tableStyle" aria-label="contact table">
                <TableBody>
                  <TableRow>
                    <TableCell
                      style={{ padding: 0, borderBottom: "none" }}
                      align="left"
                    >
                      Coordonnées EHI:{" "}
                    </TableCell>
                    <TableCell
                      style={{ padding: 0, borderBottom: "none" }}
                      align="left"
                    >
                      {contactInfo ? contactInfo["accountManagerName"] : ""}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      style={{ padding: 0, borderBottom: "none" }}
                      align="left"
                    >
                      Adresse e-mail:
                    </TableCell>
                    <TableCell
                      style={{ padding: 0, borderBottom: "none" }}
                      align="left"
                    >
                      <a
                        href={
                          "mailto:" +
                          (contactInfo
                            ? contactInfo["accountManagerEmailAddress"]
                            : "")
                        }
                      >
                        {" "}
                        {contactInfo
                          ? contactInfo["accountManagerEmailAddress"]
                          : ""}
                      </a>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      style={{ padding: 0, borderBottom: "none" }}
                      align="left"
                    >
                      Téléphoner:
                    </TableCell>
                    <TableCell
                      style={{ padding: 0, borderBottom: "none" }}
                      align="left"
                    >
                      {contactInfo ? contactInfo["accountManagerPhone"] : ""}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <div className="width30  paddingLeft2cm   body2">
            <TableContainer>
              <Table className="tableStyle" aria-label="contact table">
                <TableBody>
                  <TableRow>
                    <TableCell
                      style={{ padding: 0, borderBottom: "none" }}
                      align="left"
                    >
                      Contacter le service client:{" "}
                    </TableCell>
                    <TableCell
                      style={{ padding: 0, borderBottom: "none" }}
                      align="left"
                    >
                      {contactInfo ? contactInfo["customerServiceName"] : ""}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      style={{ padding: 0, borderBottom: "none" }}
                      align="left"
                    >
                      Adresse e-mail:
                    </TableCell>

                    <TableCell
                      style={{ padding: 0, borderBottom: "none" }}
                      align="left"
                    >
                      <a
                        href={
                          "mailto:" +
                          (contactInfo
                            ? contactInfo["customerServiceEmailAddress"]
                            : "")
                        }
                      >
                        {" "}
                        {contactInfo
                          ? contactInfo["customerServiceEmailAddress"]
                          : ""}
                      </a>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      style={{ padding: 0, borderBottom: "none" }}
                      align="left"
                    >
                      Téléphoner:
                    </TableCell>
                    <TableCell
                      style={{ padding: 0, borderBottom: "none" }}
                      align="left"
                    >
                      {contactInfo ? contactInfo["customerServicePhone"] : ""}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            Des questions concernant votre réservation ?
          </Typography>
          <Typography
            className="paddingLefthalfcm"
            variant="body2"
            gutterBottom
          >
            Si vous avez des questions concernant votre réservation, telles que
            des informations spécifiques pour la livraison ou l’organisation
            d’une reprise, veuillez contacter l'agence de location désignée pour
            votre réservation.
          </Typography>
          <Typography
            className="paddingLefthalfcm"
            variant="body2"
            gutterBottom
          >
            Les détails de l’agence de location se trouvent sur votre e-mail de
            confirmation de réservation ou sur l'écran Résumé de la réservation
            lorsque vous consultez les réservations dans Enterprise Travel
            Direct.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" className="paddingtophalfcm" gutterBottom>
            Besoin d'aide pour utiliser ETD ?
          </Typography>
          <Typography
            className="paddingLefthalfcm"
            variant="body2"
            gutterBottom
          >
            Si vous rencontrez des difficultés à utiliser l'application
            Enterprise Travel Direct, veuillez d'abord consulter notre Foire aux
            questions{" "}
            <a
              href={userGuideURL}
              target="_blank"
              style={{ textDecorationLine: "underline", color: "blue" }}
            >
              (FAQ)
            </a>
            . Vous y trouverez des conseils et des réponses aux questions les
            plus courantes concernant les fonctionnalités de cet outil. <br />
            Pour obtenir de l'aide sur l'utilisation de l'outil de réservation
            Enterprise Travel Direct, veuillez contacter notre{" "}
            <span>
              <b>{t("ContactUs.ETDHelpdesk")}</b>
            </span>{" "}
            par pays.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            ETD et autres solutions
          </Typography>
          <Typography
            className="paddingLefthalfcm"
            variant="body2"
            gutterBottom
          >
            La bonne décision, en toutes circonstances. Chaque société est
            différente. Pour certains, rien n’est plus important que les coûts.
            D’autres peuvent accorder plus d’importance aux facteurs de risque.
            Pour aider les entreprises de toutes tailles à gérer plus
            efficacement la mobilité des employés, nous avons développé
            Enterprise Travel Direct (ETD), un outil de décision intelligent qui
            aide les conducteurs à choisir l’option de transport adaptée à
            chaque déplacement. Les réponses fournies par ETD sont basées sur
            des algorithmes exclusifs de prise de décisions, développés, testés
            et convenus à l’avance.
          </Typography>
          <br />
          <Typography
            className="paddingLefthalfcm"
            variant="body2"
            gutterBottom
          >
            <span>
              Souhaitez-vous en savoir plus sur l'outil de réservation
              Enterprise Travel Direct ou sur d'autres solutions de location
              d'entreprise proposées par Enterprise Holdings ? Veuillez
              contacter votre responsable des ventes de location d'entreprise
              local ou contacter notre
            </span>{" "}
            <span>
              <b>{t("ContactUs.BusinessRentalSupportTeam")}</b>
            </span>{" "}
            par pays.
          </Typography>
          <br />
          {ContactDetailsTable(t)}
        </Grid>
      </Grid>
    );
  } else if (language === "es-ES") {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <div>
            <Button
              variant="outlined"
              color="primary"
              title={t("ClicktoPrintThisPage")}
              aria-label={t("ClicktoPrintThisPage")}
              onClick={() => {
                window.print();
              }}
            >
              {" "}
              {t("Footer.ClicktoPrintThisPage")}
            </Button>
          </div>
          <Typography variant="h1" className="paddingtophalfcm" gutterBottom>
            Contacta con nosotros
          </Typography>
          <Typography
            className="paddingLefthalfcm"
            variant="body2"
            gutterBottom
          >
            Para consultas sobre su cuenta y otros asuntos no relacionados con
            la aplicación:
          </Typography>
          <div className="width30  paddingLeft2cm paddingbottomhalfcm body2">
            <TableContainer>
              <Table aria-label="contact table">
                <TableBody>
                  <TableRow>
                    <TableCell
                      style={{ padding: 0, borderBottom: "none" }}
                      align="left"
                    >
                      Contacto de empresa:{" "}
                    </TableCell>
                    <TableCell
                      style={{ padding: 0, borderBottom: "none" }}
                      align="left"
                    >
                      {contactInfo ? contactInfo["companyContactName"] : ""}
                    </TableCell>
                  </TableRow>
                  <TableRow style={{ height: 5 }}>
                    <TableCell
                      style={{ padding: 0, borderBottom: "none" }}
                      align="left"
                    >
                      Dirección de correo electrónico:
                    </TableCell>
                    <TableCell
                      style={{ padding: 0, borderBottom: "none" }}
                      align="left"
                    >
                      <a
                        href={
                          "mailto:" +
                          (contactInfo
                            ? contactInfo["companyContactEmailAddress"]
                            : "")
                        }
                      >
                        {" "}
                        {contactInfo
                          ? contactInfo["companyContactEmailAddress"]
                          : ""}
                      </a>
                    </TableCell>
                  </TableRow>
                  <TableRow style={{ height: 5 }}>
                    <TableCell
                      style={{ padding: 0, borderBottom: "none" }}
                      align="left"
                    >
                      Teléfono:
                    </TableCell>
                    <TableCell
                      style={{ padding: 0, borderBottom: "none" }}
                      align="left"
                    >
                      {contactInfo ? contactInfo["companyContactPhone"] : ""}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <div className="width30  paddingLeft2cm paddingbottomhalfcm body2">
            <TableContainer>
              <Table className="tableStyle" aria-label="contact table">
                <TableBody>
                  <TableRow>
                    <TableCell
                      style={{ padding: 0, borderBottom: "none" }}
                      align="left"
                    >
                      Contacto de Enterprise:{" "}
                    </TableCell>
                    <TableCell
                      style={{ padding: 0, borderBottom: "none" }}
                      align="left"
                    >
                      {contactInfo ? contactInfo["accountManagerName"] : ""}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      style={{ padding: 0, borderBottom: "none" }}
                      align="left"
                    >
                      Dirección de correo electrónico:
                    </TableCell>
                    <TableCell
                      style={{ padding: 0, borderBottom: "none" }}
                      align="left"
                    >
                      <a
                        href={
                          "mailto:" +
                          (contactInfo
                            ? contactInfo["accountManagerEmailAddress"]
                            : "")
                        }
                      >
                        {" "}
                        {contactInfo
                          ? contactInfo["accountManagerEmailAddress"]
                          : ""}
                      </a>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      style={{ padding: 0, borderBottom: "none" }}
                      align="left"
                    >
                      Teléfono:
                    </TableCell>
                    <TableCell
                      style={{ padding: 0, borderBottom: "none" }}
                      align="left"
                    >
                      {contactInfo ? contactInfo["accountManagerPhone"] : ""}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <div className="width30  paddingLeft2cm   body2">
            <TableContainer>
              <Table className="tableStyle" aria-label="contact table">
                <TableBody>
                  <TableRow>
                    <TableCell
                      style={{ padding: 0, borderBottom: "none" }}
                      align="left"
                    >
                      Contacto de atención al cliente:{" "}
                    </TableCell>
                    <TableCell
                      style={{ padding: 0, borderBottom: "none" }}
                      align="left"
                    >
                      {contactInfo ? contactInfo["customerServiceName"] : ""}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      style={{ padding: 0, borderBottom: "none" }}
                      align="left"
                    >
                      Dirección de correo electrónico:
                    </TableCell>

                    <TableCell
                      style={{ padding: 0, borderBottom: "none" }}
                      align="left"
                    >
                      <a
                        href={
                          "mailto:" +
                          (contactInfo
                            ? contactInfo["customerServiceEmailAddress"]
                            : "")
                        }
                      >
                        {" "}
                        {contactInfo
                          ? contactInfo["customerServiceEmailAddress"]
                          : ""}
                      </a>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      style={{ padding: 0, borderBottom: "none" }}
                      align="left"
                    >
                      Teléfono:
                    </TableCell>
                    <TableCell
                      style={{ padding: 0, borderBottom: "none" }}
                      align="left"
                    >
                      {contactInfo ? contactInfo["customerServicePhone"] : ""}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            ¿Preguntas sobre tu Reserva?
          </Typography>
          <Typography
            className="paddingLefthalfcm"
            variant="body2"
            gutterBottom
          >
            Si tiene alguna duda con respecto a su reserva, como dar detalles de
            la entrega o realizar ajustes para recogida, póngase en contacto con
            la oficina de alquiler asignada para su reserva.
          </Typography>
          <Typography
            className="paddingLefthalfcm"
            variant="body2"
            gutterBottom
          >
            Los detalles de la oficina se pueden encontrar en el correo
            electrónico de confirmación de su reserva en la pantalla Resumen de
            la reserva al ver las reservas dentro de Enterprise Travel Direct.
          </Typography>
          <Typography variant="h6" className="paddingtophalfcm" gutterBottom>
            ¿Necesita ayuda para utilizar ETD?
          </Typography>
          <Typography
            className="paddingLefthalfcm"
            variant="body2"
            gutterBottom
          >
            Si tiene dificultades para utilizar la aplicación Enterprise Travel
            Direct, consulte primero nuestras preguntas frecuentes{" "}
            <a
              href={userGuideURL}
              target="_blank"
              style={{ textDecorationLine: "underline", color: "blue" }}
            >
              (FAQ)
            </a>
            . Contienen ayuda y las respuestas a las preguntas más comunes sobre
            la funcionalidad de esta herramienta. <br />
            Si necesita ayuda para utilizar la herramienta de reservas
            Enterprise Travel Direct, póngase en contacto con nuestro{" "}
            <span>
              <b>{t("ContactUs.ETDHelpdesk")}</b>
            </span>{" "}
            por país.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            ETD y otras soluciones
          </Typography>
          <Typography
            className="paddingLefthalfcm"
            variant="body2"
            gutterBottom
          >
            La decisión correcta, siempre. Cada empresa es diferente. Para
            algunas, el coste puede priorizarse sobre cualquier otra cuestión.
            Otras pueden dar más importancia a los factores de riesgo. Para
            ayudar a empresas de todos los tamaños a gestionar la movilidad de
            sus empleados de una forma más eficaz, hemos desarrollado Enterprise
            Travel Direct (ETD), una herramienta de decisión inteligente que
            ayuda a los conductores a seleccionar la opción de transporte más
            adecuada para cada viaje. Las respuestas proporcionadas por ETD se
            basan en algoritmos únicos de toma de decisiones, desarrollados,
            probados y acordados de antemano.
          </Typography>
          <br />
          <Typography
            className="paddingLefthalfcm"
            variant="body2"
            gutterBottom
          >
            <span>
              ¿Está interesado en obtener más información sobre la herramienta
              de reserva Enterprise Travel Direct u otras soluciones de alquiler
              para empresas ofrecidas por Enterprise Holdings? Póngase en
              contacto con su Comercial de Alquiler para Empresas local, o con
              nuestro{" "}
            </span>{" "}
            <span>
              <b>{t("ContactUs.BusinessRentalSupportTeam")}</b>
            </span>{" "}
            por país.
          </Typography>
          <br />
          {ContactDetailsTable(t)}
        </Grid>
      </Grid>
    );
  } else {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <div>
            <Button
              variant="outlined"
              color="primary"
              title={t("ClicktoPrintThisPage")}
              aria-label={t("ClicktoPrintThisPage")}
              onClick={() => {
                window.print();
              }}
            >
              {" "}
              {t("Footer.ClicktoPrintThisPage")}
            </Button>
          </div>
          <Typography variant="h1" className="paddingtophalfcm" gutterBottom>
            {t("Footer.ContactUs")}
          </Typography>
          <Typography
            className="paddingLefthalfcm"
            variant="body2"
            gutterBottom
          >
            For queries regarding your account and other non-application related
            items:
          </Typography>
          <div className="width30  paddingLeft2cm paddingbottomhalfcm body2">
            <TableContainer>
              <Table aria-label="contact table">
                <TableBody>
                  <TableRow>
                    <TableCell
                      style={{ padding: 0, borderBottom: "none" }}
                      align="left"
                    >
                      Company Contact:{" "}
                    </TableCell>
                    <TableCell
                      style={{ padding: 0, borderBottom: "none" }}
                      align="left"
                    >
                      {contactInfo ? contactInfo["companyContactName"] : ""}
                    </TableCell>
                  </TableRow>
                  <TableRow style={{ height: 5 }}>
                    <TableCell
                      style={{ padding: 0, borderBottom: "none" }}
                      align="left"
                    >
                      Email Address:
                    </TableCell>
                    <TableCell
                      style={{ padding: 0, borderBottom: "none" }}
                      align="left"
                    >
                      <a
                        href={
                          "mailto:" +
                          (contactInfo
                            ? contactInfo["companyContactEmailAddress"]
                            : "")
                        }
                      >
                        {" "}
                        {contactInfo
                          ? contactInfo["companyContactEmailAddress"]
                          : ""}
                      </a>
                    </TableCell>
                  </TableRow>
                  <TableRow style={{ height: 5 }}>
                    <TableCell
                      style={{ padding: 0, borderBottom: "none" }}
                      align="left"
                    >
                      Phone:
                    </TableCell>
                    <TableCell
                      style={{ padding: 0, borderBottom: "none" }}
                      align="left"
                    >
                      {contactInfo ? contactInfo["companyContactPhone"] : ""}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <div className="width30  paddingLeft2cm paddingbottomhalfcm body2">
            <TableContainer>
              <Table className="tableStyle" aria-label="contact table">
                <TableBody>
                  <TableRow>
                    <TableCell
                      style={{ padding: 0, borderBottom: "none" }}
                      align="left"
                    >
                      Enterprise Contact:{" "}
                    </TableCell>
                    <TableCell
                      style={{ padding: 0, borderBottom: "none" }}
                      align="left"
                    >
                      {contactInfo ? contactInfo["accountManagerName"] : ""}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      style={{ padding: 0, borderBottom: "none" }}
                      align="left"
                    >
                      Email Address:
                    </TableCell>
                    <TableCell
                      style={{ padding: 0, borderBottom: "none" }}
                      align="left"
                    >
                      <a
                        href={
                          "mailto:" +
                          (contactInfo
                            ? contactInfo["accountManagerEmailAddress"]
                            : "")
                        }
                      >
                        {" "}
                        {contactInfo
                          ? contactInfo["accountManagerEmailAddress"]
                          : ""}
                      </a>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      style={{ padding: 0, borderBottom: "none" }}
                      align="left"
                    >
                      Phone:
                    </TableCell>
                    <TableCell
                      style={{ padding: 0, borderBottom: "none" }}
                      align="left"
                    >
                      {contactInfo ? contactInfo["accountManagerPhone"] : ""}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <div className="width30  paddingLeft2cm   body2">
            <TableContainer>
              <Table className="tableStyle" aria-label="contact table">
                <TableBody>
                  <TableRow>
                    <TableCell
                      style={{ padding: 0, borderBottom: "none" }}
                      align="left"
                    >
                      Customer Service Contact:{" "}
                    </TableCell>
                    <TableCell
                      style={{ padding: 0, borderBottom: "none" }}
                      align="left"
                    >
                      {contactInfo ? contactInfo["customerServiceName"] : ""}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      style={{ padding: 0, borderBottom: "none" }}
                      align="left"
                    >
                      Email Address:
                    </TableCell>

                    <TableCell
                      style={{ padding: 0, borderBottom: "none" }}
                      align="left"
                    >
                      <a
                        href={
                          "mailto:" +
                          (contactInfo
                            ? contactInfo["customerServiceEmailAddress"]
                            : "")
                        }
                      >
                        {" "}
                        {contactInfo
                          ? contactInfo["customerServiceEmailAddress"]
                          : ""}
                      </a>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      style={{ padding: 0, borderBottom: "none" }}
                      align="left"
                    >
                      Phone:
                    </TableCell>
                    <TableCell
                      style={{ padding: 0, borderBottom: "none" }}
                      align="left"
                    >
                      {contactInfo ? contactInfo["customerServicePhone"] : ""}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            Questions regarding your Reservation?
          </Typography>
          <Typography
            className="paddingLefthalfcm"
            variant="body2"
            gutterBottom
          >
            If you have any queries regarding your reservation, such as specific
            drop off details or making arrangements for collection, please
            contact the rental branch assigned for your booking.
          </Typography>
          <Typography
            className="paddingLefthalfcm"
            variant="body2"
            gutterBottom
          >
            Details for the branch can be found on your reservation confirmation
            email, or on the Reservation Summary screen when viewing
            reservations within Enterprise Travel Direct.
          </Typography>
          <Typography variant="h6" className="paddingtophalfcm" gutterBottom>
            Need help using ETD?
          </Typography>
          <Typography
            className="paddingLefthalfcm"
            variant="body2"
            gutterBottom
          >
            If you are experiencing difficulties using the Enterprise Travel
            Direct application, please first refer to our{" "}
            <a
              href={userGuideURL}
              target="_blank"
              style={{ textDecorationLine: "underline", color: "blue" }}
            >
              FAQ
            </a>
            . This contains guidance and commonly asked questions around the
            functionality of this tool. <br />
            For assistance using the Enterprise Travel Direct reservation tool,
            please contact our{" "}
            <span>
              <b>ETD Helpdesk</b>
            </span>{" "}
            by country.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            ETD and other solutions
          </Typography>
          <Typography
            className="paddingLefthalfcm"
            variant="body2"
            gutterBottom
          >
            The Right Decision, Every Time. Every organization is different. For
            some, cost may override all other issues. Others may place more
            importance on risk factors. To help organisations of all sizes
            manage employee mobility more effectively, we’ve developed
            Enterprise Travel Direct (ETD), an intelligent decision tool that
            helps drivers select the right transport option for every journey.
            The answers provided by ETD are based on unique decision-making
            algorithms, developed, tested and agreed in advance. <br />
          </Typography>
          <br />
          <Typography
            className="paddingLefthalfcm"
            variant="body2"
            gutterBottom
          >
            <span>
              Are you interested to learn more about the Enterprise Travel
              Direct reservation tool, or other Business Rental solutions
              offered by Enterprise Holdings? Please contact your local Business
              Rental Sales Executive, or contact our
            </span>{" "}
            <span>
              <b>Business Rental Support Team</b>
            </span>{" "}
            by country.
          </Typography>
          <br />
          {ContactDetailsTable(t)}
        </Grid>
      </Grid>
    );
  }
};
export default ContactUs;
