import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "components/store/reducers/rootReducer";
import { authService } from "services/authService";
import { Permissions, hasPermission } from "helpers/getUserPermissions";

const useDriverDetails = () => {
  const companyBoboEnabled = useSelector(
    (state: AppState) => state.companyInfo?.companyInfo?.isBoboEnabled || false
  );
  const isLoyaltyEnabled = useSelector(
    (state: AppState) =>
      state.companyInfo?.companyInfo?.isLoyaltyEnabled || false
  );
  const isBBCDriver = useSelector(
    (state: AppState) =>
      state.companyInfo?.companyInfo?.isDriverValidityActivated || false
  );
  const userHasBobo = hasPermission(Permissions.BoboEnabled);
  const [isBoboEnabled, setIsBoboEnabled] = useState(
    companyBoboEnabled && userHasBobo
  );
 const booker = authService.getBookerDetails();

  useEffect(() => {}, []);

  return [isBoboEnabled, isLoyaltyEnabled, isBBCDriver, booker];
};

export default useDriverDetails;
