import {
  ReservationAdditionalInformationFieldViewModel,
  DropDownList,
} from "../../../../views/reservation/ReservationViewType";
export interface StartEndLocation {
  location: string;
  peopleSoftId: string;
  stationId: string;
  latitude: string;
  longitude: string;
  brand: string;
  countryCode: string;
}

export interface ViaPoints {
  newClientIndex?: number;
  destination: string;
  peopleSoftId?: string;
  stationId?: string;
  latitude: string;
  longitude: string;
  travelDistance?: number;
  travelDistanceDurationString?: string;
  travelDuration?: number;
  order?: number;
  uId?: string;
  locationPlaceholder?: string;
  location?: string;
  id?: string;
}
export interface AllPoints {
  destination: string;
  travelDistance: number;
  travelDistanceDurationString: string;
  travelDuration: number;
  order: number;
  uId: string;
  locationPlaceholder: string;
  distanceUnit: string;
}

export interface JourneyStartModel {
  journeyProfilerUId: string | null;
  originalJourneyProfilerUId: string | null;
  referenceNumber: string | null;
  activeTravelPolicy: boolean;
  clientCurrentDateTime: Date;
  startLocation: StartEndLocation;
  endLocation: StartEndLocation;
  endPeopleSoftId: string | null;
  endStationId: string | null;
  endLatitude: string | null;
  endLongitude: string | null;
  useStartAsEndLocation: boolean;
  startDateTime: string | null;
  endDateTime: string;
  availableModesOfTransport: DropDownList[];
  registrationNumber: string | null;
  useOwnVehicle: boolean;
  viaPoints: ViaPoints[];
  allPoints: AllPoints[];
  activeMileageReimbursement: boolean;
  riskAssessmentExists: boolean;
  reservationCreated: boolean;
  isJourneyProfile: boolean;
  isSpecialityVehicle : boolean;
  disableEditJourney: boolean;
  isRebook: boolean;
  isDailyRentalSingleProduct: boolean;
  primaryDriverDeclaration: DriverDeclaration;
  additionalDriver: DriverDeclaration[];
  isInternationalStartLocation: boolean;
  isMidpointsEnabled: boolean;
  isMidpointsEnabledInAdminSide: boolean;
  modeOfTransportUId: string | null;
  additionalInformation: {
    additionalInformationFields: ReservationAdditionalInformationFieldViewModel[];
    message: string | null;
    renderTime: string | null;
    errors: string[];
    warnings: string[];
    hasErrors: boolean;
    hasWarnings: boolean;
  };
  bookingReferences: string[];
  edtAction: number;
  startLocationAddressJson: string | null;
  endLocationAddressJson: string | null;
  startTime?: string;
  endTime?: string;
  startDate?: string;
  endDate?: string;
}

export var myJourneyIntitalValue = {
  journeyProfilerUId: "",
  originalJourneyProfilerUId: "",
  referenceNumber: "",
  activeTravelPolicy: false,
  clientCurrentDateTime: new Date(),
  startLocation: {
    location: "",
    peopleSoftId: "",
    stationId: "",
    latitude: "",
    longitude: "",
    brand: "",
    countryCode: "",
  } as StartEndLocation,
  endLocation: {
    location: "",
    peopleSoftId: "",
    stationId: "",
    latitude: "",
    longitude: "",
    brand: "",
    countryCode: "",
  } as StartEndLocation,
  endPeopleSoftId: "",
  endStationId: "",
  endLatitude: "",
  endLongitude: "",
  useStartAsEndLocation: true,
  startDateTime: "",
  endDateTime: "",
  availableModesOfTransport: [
    {
      disabled: true,
      group: {
        disabled: true,
        name: "",
      },
      selected: true,
      text: "",
      value: "",
    },
  ] as DropDownList[],
  registrationNumber: "",
  useOwnVehicle: false,
  viaPoints: [
    {
      newClientIndex: 0,
      destination: "",
      peopleSoftId: "",
      stationId: "",
      latitude: "",
      longitude: "",
      travelDistance: 0,
      travelDistanceDurationString: "",
      travelDuration: 0,
      order: 0,
      uId: "",
      locationPlaceholder: "",
      location: "",
    },
  ] as ViaPoints[],
  allPoints: [
    {
      destination: "",
      travelDistance: 0,
      travelDistanceDurationString: "",
      travelDuration: 0,
      order: 0,
      uId: "",
      locationPlaceholder: "",
      distanceUnit: "",
    },
  ] as AllPoints[],
  activeMileageReimbursement: true,
  riskAssessmentExists: true,
  reservationCreated: true,
  isJourneyProfile: true,
  isSpecialityVehicle : false,
  disableEditJourney: true,
  isRebook: true,
  isDailyRentalSingleProduct: false,
  primaryDriverDeclaration : {
    driverDeclarationDetailId : 0,
    driverDeclarationId : 0,
    companyInternalNumber : 0,
    firstName : "",
    lastName : "",
    emailAddress : "",
    driverDeclarationExpiryDate : new Date(),
    driverDeclarationStatusId : 0,
    driverDeclarationStatus : "",
  } as DriverDeclaration,
  additionalDriver: [
    {
      driverDeclarationDetailId : 0,
      driverDeclarationId : 0,
      companyInternalNumber : 0,
      firstName : "",
      lastName : "",
      emailAddress : "",
      driverDeclarationExpiryDate : new Date(),
      driverDeclarationStatusId : 0,
      driverDeclarationStatus : "",
    }
  ] as DriverDeclaration[],
  isInternationalStartLocation: false,
  isMidpointsEnabled: true,
  isMidpointsEnabledInAdminSide: true,
  modeOfTransportUId: "",
  additionalInformation: {
    additionalInformationFields: [
      {
        additionalInformationGid: "",
        sequence: 0,
        fieldTypeCode: 0,
        fieldName: "",
        helperText: "",
        fieldValue: "",
        fieldValueDateString: "",
        dropDownList: [
          {
            disabled: true,
            group: {
              disabled: true,
              name: "",
            },
            selected: true,
            text: "",
            value: "",
          },
        ] as DropDownList[],
        dropDownListSelectedValue: "",
        maxLength: "",
        mandatory: true,
        includeInReservation: true,
        isBillingRef: true,
        customValidate: true,
        webApproved: true,
      },
    ] as ReservationAdditionalInformationFieldViewModel[],
    message: "",
    renderTime: "",
    errors: [""],
    warnings: [""],
    hasErrors: true,
    hasWarnings: true,
  },
  bookingReferences: [""],
  edtAction: 0,
  startLocationAddressJson: "",
  endLocationAddressJson: "",
  startTime: "",
  endTime: "",
  startDate: "",
  endDate: "",
} as JourneyStartModel;

export interface GooglLocation {
  location: string;
  peopleSoftId?: string;
  stationId?: string;
  latitude?: string;
  longitude?: string;
  brand?: string;
  countryCode?: string;
  address1?: string;
  address2?: string;
  address3?: string;
  townOrCity?: string;
  postcode?: string;
  addressSearch?: string;
}

export interface DriverDeclaration {
  driverDeclarationDetailId : number;
  driverDeclarationId? : number;
  companyInternalNumber : number;
  firstName : string;
  lastName : string;
  emailAddress : string;
  driverDeclarationExpiryDate : Date;
  driverDeclarationStatusId? : number;
  driverDeclarationStatus : string;
  isPrimaryDriver? : boolean; 
}
export interface UserDetails {
  userId: number;
  username: string,
  firstName: string;
  lastName: string;
  emailAddress: string;
  preferredPhoneCountry: string;
  preferredPhoneNumber: string;
  alternativePhoneCountry: string;
  alternativePhoneNumber: string;
  enterprisePlusNumber?: string;
  emeraldClubNumber?: string;
  isConsent?: boolean
}
export interface DialingCodes {
  dialingCodes: any;
}
export interface DriverInfo {
  primaryDriver?: DriverDeclaration;
  additionalDriver: DriverDeclaration[];
  isBOBOToggleEnabledAndIsDriverDeclaration:boolean|undefined;
}


