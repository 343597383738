import {
  Backdrop,
  Button, Checkbox, CircularProgress,
  FormControlLabel, Grid,
  TextField, Typography,
  IconButton,
} from "@mui/material";
import Divider from '@mui/material/Divider';
import CancelIcon from "@mui/icons-material/Cancel";
import makeStyles from '@mui/styles/makeStyles';
import { broadcastAnnouncement } from "components/store/actions/shared/announcementActions";
import axiosInstance from "helpers/axiosInstance";
import React from "react";
import { renderToStaticMarkup } from "react-dom/server";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  showErrorAlertMessage,
  //showInfoAlertMessage,
  showSuccessAlertMessage,
  clearAlertMessage,
} from "../../store/actions/shared/alertActions";
import {
  nonComplianceNotification,
  notificationRecipient, riskAssessment
} from "./riskAssessmentType";

import { appInsights } from "helpers/appInsights";
import { SeverityLevel } from "@microsoft/applicationinsights-common";


// import linkDeleteIcon from "Assets/Images/LinkDeleteRedCross.png";
// import {isIE} from 'react-device-detect';

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormHelperText-root": {
      color: "#DF0000",
      fontSize: 12,
      marginLeft: "15px",
    },
    "& ::placeholder": {
      fontStyle: "italic",
      color: "#000",
      opacity: 0.95,
    },
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
  outlinedButton: {
    border: "1px solid rgba(0, 102, 57, 0.95);",
  },
  inputRoot: {
    fontSize: 30,
  },
  labelRoot: {
    fontSize: 30,
    color: "red",
    "&$labelFocused": {
      color: "purple",
    },
  },
  errorWarning: {
    color: "#DF0000",
    fontSize: 12,
    marginLeft: "15px",
  },
  backdrop: {
    zIndex: 99999,
    color: "#fff",
  },

  labelFocused: {},

  instruction: {
    opacity: 0.6,
    fontStyle: "italic",
    fontWeight: "bold",
    paddingTop: "5px",
    paddingBottom: "5px"
  },

}));

// const intiNotificationRecipient:notificationRecipient ={ 
//   companyNotificationRecipientUId:"",
//   companyNotificationId:0,
//   companyNotificationUId:"",
//   recipientType:0,
//   recipient:""
// }

const intiNotificationRecipients:notificationRecipient[]=[];

const initNonComplianceNotification  :nonComplianceNotification ={ 
  
  uId :"",
  companyNotificationId:0,
  companyId:"",
  companyUId:"",
  name:"",
  companyContactTypeUId: "",
  notificationType: 0,
  activate:false,
  newRecipient:false,
  notificationRecipients:[],    
  isNew:false,
  newRecipientActio:""
}

const initRiskAssessment: riskAssessment = { 
  title :"",
  existingCompanyName:0,
  nonComplianceNotification:initNonComplianceNotification
}

export default function RiskAssessment(props) {
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);

  const [riskAssessment, setRiskAssessment] =
    React.useState(initRiskAssessment);

  // const [nonComplianceNotification, setNonComplianceNotification] =
  //   React.useState(null);

  const [notificationRecipients, setNotificationRecipients] = React.useState(
    intiNotificationRecipients
  );

  const [newEmailAddress, setNewEmailAddress] = React.useState("");

  const [activate, setActivate] = React.useState(false);

  const classes = useStyles();
  const dispatch = useDispatch();

  const { companyUid } = props;

  const titleRef = React.useRef<HTMLDivElement>(null);

  const [linkTableRowCount, setLinkTableRowCount] = React.useState(100);

  // const [refreshPage, setRefreshPage] = React.useState(false);

  const [saveButtonStatusTouched, setSaveButtonStatusTouched] =
    React.useState(false);

  const [copiedRiskAssessment, setCopiedRiskAssessment] =
    React.useState<riskAssessment>(initRiskAssessment);

  React.useEffect(() => {
    dispatch(clearAlertMessage());
    setLoading(true);
    fetchCompanyRiskAssessment(companyUid);
    if (titleRef.current !== null) titleRef.current.focus();
}, [props]);
  // }, [props, refreshPage]);

  var emailPattern = new RegExp(/^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i);
  function validateEmailAddress(emailAddress) {
    let isValid = true;

    if (!emailAddress) {
      isValid = false;
      return isValid;
    }
    if (!emailPattern.test(emailAddress)) {
      isValid = false;
    }

    return isValid;
  }

  const recipientTable = (notificationRecipients: notificationRecipient[]) => {
    return (
      <div style={{ width: "80%" }}>
        <table id="recipientTable" style={{ width: "100%", border: "1" }}>
          <tbody>
            {notificationRecipients.map((aLink, index) =>
              recipientRow(aLink, index)
            )}
          </tbody>
        </table>
      </div>
    );
  };

  const recipientRow = (aLink: notificationRecipient, index: any) => {
    return (
      <tr id={aLink.companyNotificationRecipientUId} key={index}>
        <td style={{ width: "90%" }}>
        <div id={"Input_" + aLink.companyNotificationRecipientUId} style={{border:"1px solid grey", width: "100%", wordBreak: "break-all", wordWrap: "break-word"}}>{aLink.recipient}</div>
          {/* <input
            type="text"
            id={"Input_" + aLink.companyNotificationRecipientUId}
            style={{ width: "100%" }}
            disabled={true}
            defaultValue={aLink.recipient}
            aria-label={t("RiskAssessment.companyLinkDescription")}
          /> */}
        </td>
        <td style={{ width: "10%" }}>
          {/* <input
            id={
              "removeNotificationRecipientButton_" +
              aLink.companyNotificationRecipientUId
            }
            type="image"
            className="input"
            title={t("RiskAssessment.deleteEmailTooltips")}
            alt={t("Delete Email")}
            src={linkDeleteIcon}
            aria-label={t("RiskAssessment.deleteEmail")}
            color="primary"
            style = {isIE?{ height: "18px", width: "18px",borderColor: "red", borderWidth: 1, }:{height: "18px", width: "18px", borderColor: 'Green', borderWidth: 3,  marginBottom: 0, outlineColor: "#006639"}}
            //style={{ height: "18px", width: "18px", borderColor: 'Green', borderWidth: 3,  marginBottom: 0, outlineColor: "#006639"}}
            onClick={() => {
              handleDeleteRow(aLink.companyNotificationRecipientUId);

            }}
          /> */}
           <IconButton
            role="img"
            id={"removeNotificationRecipientButton_"+aLink.companyNotificationRecipientUId}
            aria-label={t("Delete")}
            title={t("Companies.deleteEmail")}
            color="primary"
            size="small"
            style={{
              marginRight: 5,
              border: "0px solid #547D66",
              color: "#d02216",
            }}


            onClick={() => {
              handleDeleteRow(
                aLink.companyNotificationRecipientUId,
                aLink.recipient);
              setSaveButtonStatusTouched(true)
            }}
          >
            <CancelIcon />
          </IconButton>         
        </td>
      </tr>
    );
  };

  const handleDeleteRow = (rowId: string, email: string) => {
    dispatch(clearAlertMessage());
   
    let updatednotificationRecipients =  notificationRecipients.filter(link => link.companyNotificationRecipientUId !== rowId);    
    setNotificationRecipients(updatednotificationRecipients);

    recipientTable(notificationRecipients);

    // var input_value = (
    //   document.getElementById("Input_" + rowId) as HTMLInputElement
    // ).value;

    // var row = document.getElementById(rowId) as HTMLTableRowElement;

    // row.parentElement?.removeChild(row);

    setSaveButtonStatusTouched(true);
    
    setTimeout(() => {
      dispatch(
        broadcastAnnouncement(
          email + " " + t("RiskAssessment.emailAddressIsDeleted")
        )
      );
    }, 100);
  };

  const handleAddRow = () => {
    dispatch(clearAlertMessage());
    setSaveButtonStatusTouched(true);
    if (!validateEmailAddress(newEmailAddress)) {
      dispatch(showErrorAlertMessage(t("RiskAssessment.cannotAddInvalidEailAddress")));
      dispatch(broadcastAnnouncement(t("RiskAssessment.cannotAddInvalidEailAddress")));
      window.scrollTo(0, 0);
      return;
    }

    setLinkTableRowCount(linkTableRowCount + 1);

    // var table = document.getElementById("recipientTable") as HTMLTableElement;

    var aLink = {
      companyNotificationRecipientUId: "TEMP_ID_" + linkTableRowCount,
      companyNotificationId: 0,
      companyNotificationUId: "00000000-0000-0000-0000-000000000000",
      recipientType: 0,
      recipient: newEmailAddress,
    };

    notificationRecipients.push(aLink);

    recipientTable(notificationRecipients);

    // var innerHTML = renderToStaticMarkup(recipientRow(aLink, 0));

    // if (table) {
    //   var aRow = table.insertRow(table.rows.length);
    //   aRow.innerHTML = innerHTML;
    //   aRow.id = aLink.companyNotificationRecipientUId;
    // }

    // var btn = document.getElementById(
    //   "removeNotificationRecipientButton_" +
    //     aLink.companyNotificationRecipientUId //+ //aLink.id
    // ) as HTMLButtonElement;

    // btn.onclick = function () {
    //   handleDeleteRow(aLink.companyNotificationRecipientUId); //aLink.id+''"");
    // };

    // var input = document.getElementById(
    //   "Input_" + aLink.companyNotificationRecipientUId
    // ) as HTMLInputElement;
    // input.disabled = true;

    setNewEmailAddress("");

    var addButton = document.getElementById("addNewNotificationRecipientButton") as HTMLInputElement;
    if (addButton) {
      addButton.focus();
    }

    
    setTimeout(() => {
      dispatch(
        broadcastAnnouncement(
          newEmailAddress + " " + t("RiskAssessment.emailAddressIsAdded")
        )
      );
    }, 100);

    window.scrollTo(0, 0);
  };

  const handleDeletionButtonMouseOver = () => {

    dispatch(broadcastAnnouncement(""));
    setTimeout(() => {
      dispatch(broadcastAnnouncement(t("RiskAssessment.deleteEmail")));
    }, 100);
  }  

  const fetchCompanyRiskAssessment = async (companyUId) => {
    if (companyUId !== "") {
      await axiosInstance
        .get(`/company/getRiskAssessmentByCompanyUId/${companyUId}`)
        .then((response) => {
          appInsights.trackEvent({
            name: "Fetch Company Risk Assessment", properties: { companyUId : companyUid }
          });
          setRiskAssessment(response.data);
          setCopiedRiskAssessment(JSON.parse(JSON.stringify(response.data)));
          // setNonComplianceNotification(response.data.nonComplianceNotification);
          setNotificationRecipients(
            response.data.nonComplianceNotification.notificationRecipients
          );
          setActivate(response.data.nonComplianceNotification.activate);

          dispatch(
            broadcastAnnouncement(
              t("RiskAssessment.companyProfileFormat", {
                CompanyName: response.data.existingCompanyName,
              })
            )
          );
        })
        .catch((error) => {
          if (
            error.response != null &&
            error.response.status != null &&
            error.response.status === 400
          ) {
            appInsights.trackException({id: "Fetch Company Risk Assessment", exception: error, severityLevel: SeverityLevel.Error});

            dispatch(showErrorAlertMessage(t("UnexpectedError")));
            dispatch(broadcastAnnouncement(t("UnexpectedError")));
            console.error("There is an error", error);
          } else console.error("There is an error", error);
        });
    } else {
      //doing nothing here
    }
    setLoading(false);
  };

  const uploadCompanyRiskAssessment = async (values) => {
    setLoading(true);
    await axiosInstance
      .post("/company/UpdateRiskAssessment/", values)
      .then((res) => {
        //setRefreshPage(!refreshPage)
        //fetchCompanyContent(companyUid);
        dispatch(
          showSuccessAlertMessage(
            t("RiskAssessment.updateRiskAssessmentSuccess")
          )
        );
        dispatch(
          broadcastAnnouncement(t("RiskAssessment.updateRiskAssessmentSuccess"))
        );
        appInsights.trackEvent({
          name: "Upload Company Risk Assessment", properties: { companyUId : companyUid }
        });

        setSaveButtonStatusTouched(false);
      })
      .catch((err) => {
        if (err.response && err.response.data.messages) {
          dispatch(
            showErrorAlertMessage(
              "Unable to save: " + err.response.data.messages
            )
          );
          dispatch(
            broadcastAnnouncement(
              "Unable to save: " + err.response.data.messages
            )
          );
          appInsights.trackException({id: "Upload Company Risk Assessment", exception: err.response.data.messages, severityLevel: SeverityLevel.Error});
 
        }
      });
    window.scrollTo(0, 0);
    setLoading(false);
  };

  const handleUpdate = () => {

    var table = document.getElementById("recipientTable") as HTMLTableElement;

    if (activate && table.rows.length === 0) {
      dispatch(
        showErrorAlertMessage(
          t("RiskAssessment.atLeastOneNotificationRecipientIsRequired")
        )
      );
      dispatch(
        broadcastAnnouncement(
          t("RiskAssessment.atLeastOneNotificationRecipientIsRequired")
        )
      );

      window.scrollTo(0, 0);
      return;
    }

    var aLinks: notificationRecipient[] = [];

    //var count = 0;
    
    for (var i = 0; i < table.rows.length; i++) {
      var originalLink = notificationRecipients.find(
        (e) => e.companyNotificationRecipientUId === table.rows[i].id
      );
      if (originalLink && originalLink.companyNotificationRecipientUId && !originalLink.companyNotificationRecipientUId.includes("TEMP_ID_")) {
        aLinks.push(originalLink);
      } else {
        var emailDiv = document.getElementById(
          "Input_" + table.rows[i].id
        ) as HTMLDivElement;

        if (emailDiv) {
          var input_value = emailDiv.innerText;
          var aLink = {
            companyNotificationRecipientUId:
              "00000000-0000-0000-0000-000000000000",
            companyNotificationId: 0,
            companyNotificationUId: "00000000-0000-0000-0000-000000000000",
            recipientType: 0,
            recipient: input_value,
          };
          aLinks.push(aLink);
        }
      }
    }

    riskAssessment.nonComplianceNotification.notificationRecipients = aLinks;
    riskAssessment.nonComplianceNotification.activate = activate;

    uploadCompanyRiskAssessment(riskAssessment);
  };

  appInsights.trackEvent({
    name: "Company Risk Assessment", properties: { companyUId : companyUid }
  });

  return (
    <div className={classes.root}>
      <div
        id="companyRiskAssessmentSettingsDiv"
        ref={titleRef}
        tabIndex={-1}
        className="visuallyHidden"
      >
      <Typography variant="h1">
        {t("RiskAssessment.companyRiskAssesmentSettings")}
      </Typography>
      </div>
      <Typography variant="h1">
        {t("RiskAssessment.companyRiskAssesmentSettings")}
      </Typography>
      <br /> <br />
      <Grid container item spacing={3}>
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress />
        </Backdrop>

        {/* <fieldset>
          <legend>
            <Typography variant="h2">
              {" "}
              {"Non Compliancy Notification"}
            </Typography>
          </legend>
          <div
            style={{
              display: "flex",
              justifyContent: "left",
              alignItems: "left",
              flexDirection: "column",
            }}
          > */}
            {/* {recipientTable(notificationRecipients)} */}

            <Grid item xs={12} md={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={activate}
                    onChange={(e) => {
                      setActivate(!activate);
                      //setSaveButtonStatusTouched(activate===copiedRiskAssessment.nonComplianceNotification.activate)
                      setSaveButtonStatusTouched(true);
                    }}
                    name="Activate"
                    color="primary"
                  />
                }
                label="Activate"
              />
            </Grid>
            <Grid item xs={12} md={12} style={{ marginBottom:-20 }}>
                <Typography variant="h2">
                  {t("Add Address")}
                </Typography>
              </Grid>
            <Grid item xs={12} md={12} style={{ marginBottom:0 }}>
              <TextField
                id="riskAssessmentEmailAddressTextBox"
                // fullWidth={true}
                style={{ width: "50%", minWidth: "110px" }}
                size="medium"
                margin="dense"
                //InputLabelProps={{ shrink: false }}
                inputProps={{
                  "aria-label": t("Companies.emailAddress"),
                  "aria-required": true,
                }}
                label={"Email Address"}
                variant="outlined"
                value={newEmailAddress}
                onChange={(e) => setNewEmailAddress(e.target.value)}
                placeholder={t("RiskAssessment.emailPlaceholder")}
                error={
                  newEmailAddress !== "" && !newEmailAddress.match(emailPattern)
                }
              />
              <Button
                color="primary"
                id="addNewNotificationRecipientButton"
                variant="contained"
                onClick={() => {
                  handleAddRow();
                  setSaveButtonStatusTouched(true);
                }}
                style={{
                  marginTop: "0.8em",
                  marginBottom: "0.3em",
                  marginLeft: "0.5em",
                  textTransform: "none",
                }}
              >
                {t("RiskAssessment.addNew")}
              </Button>
              </Grid >
              <Divider
                style={{
                  marginTop: 0,
                  marginBottom: -15,
                  backgroundColor: "black",
                  height: "0.8px",
                  width: "60%",
                }}
              />
              <Grid item xs={12}>
              {recipientTable (notificationRecipients) }
                {/* <div style={{ width: "75%" }}>
                  <table id="recipientTable" style={{ width: "100%" }}>
                    <tbody>
                      {notificationRecipients.map((aLink, index) =>
                        recipientRow(aLink, index)
                      )}
                    </tbody>
                  </table>
                </div> */}
              </Grid>
              <div className={classes.instruction} style={{width:"70%"}}>
                {t("RiskAssessment.notes")}
              </div>

          {/* </div> */}
        {/* </fieldset> */}
        <Grid container justifyContent="flex-end">
          <Button
            id="btnUpdate"
            color="primary"
            variant="contained"
            disabled={!saveButtonStatusTouched}
            onClick={handleUpdate}
            // onKeyDown={handleKeyPressForSaveButton}
            type="submit"
            style={{ marginTop: "0.5em", textTransform: "none" }}
          >
            {t("RiskAssessment.btnSave")}
          </Button>
          <Button
            classes={{ outlinedPrimary: classes.outlinedButton }}
            id="btnCancel"
            color="primary"
            variant="outlined"
            type="button"
            onClick={() => {
              props.action("");
            }}
            style={{
              marginTop: "0.5em",
              marginLeft: "0.5em",
              textTransform: "none",
            }}
          >
            {t("RiskAssessment.btnCancel")}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}

