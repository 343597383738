import React from "react";
import { CarClubDriverProps, CarClubViews } from "./carClubDriverType";
import { CarClubNoDriverMatch } from "./Views/carClubNoDriverMatch";
import { CarClubDriverMatch } from "./Views/carClubDriverMatch";
import { CarClubDriverBOBO } from "./Views/carClubDriverBOBO";

const CarClubDriver: React.FC<CarClubDriverProps> = ({
  handleSelectDriver,
  selectedDriver,
  carclubregisterhyperlink,
  isDriverValidityActivated,
  carClubDrivers,
  isBoboEnabled,
  searchParam,
  onDataChange
}) => {
  const isUniversal = !isDriverValidityActivated;
  const driverMatched = carClubDrivers.length > 0;
  let ccView = isUniversal
    ? isBoboEnabled
      ? CarClubViews.DriverBOBO
      : CarClubViews.DriverBookForSelf
    : driverMatched
    ? CarClubViews.DriverExactMatch
    : CarClubViews.NoDriverMatch;

  return (
    <>
      {ccView === CarClubViews.NoDriverMatch && (
        <CarClubNoDriverMatch
          carclubregisterhyperlink={carclubregisterhyperlink}
        />
      )}
      {(ccView === CarClubViews.DriverBookForSelf ||
        ccView === CarClubViews.DriverExactMatch) && (
        <CarClubDriverMatch
          handleSelectDriver={handleSelectDriver}
          selectedDriver={selectedDriver}
          isDriverValidityActivated={isDriverValidityActivated}
          carClubDrivers={carClubDrivers}
          onDataChange={onDataChange}
        />
      )}
      {ccView === CarClubViews.DriverBOBO && (
        <CarClubDriverBOBO
          handleSelectDriver={handleSelectDriver}
          selectedDriver={selectedDriver}
          carClubDrivers={carClubDrivers}
          searchParam={searchParam}
          onDataChange={onDataChange}
        />
      )}
    </>
  );
};
export default CarClubDriver;
