export function AreStringsEqual(string1?: string, string2?: string) {
  if (string1?.toLowerCase() === string2?.toLowerCase()) return true;
  else return false;
}

export function isNullOrWhitespace(input) {
  return !input || !input.trim();
}

export function isNullOrUndefined(input) {
  return !input || input === undefined;
}
