import React, { useEffect, useState } from "react";
import makeStyles from '@mui/styles/makeStyles';
import { Formik, Form, Field } from "formik";
import { useTranslation } from "react-i18next";
import { CCPasswordChange, CCPinChange } from "../admin/ccProfileType";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import {
  Box,
  TextField,
  Backdrop,
  Button,
  Grid,
  Typography,
  CircularProgress,
  Link,
} from "@mui/material";
import axiosInstance from "../../helpers/axiosInstance";

import * as Yup from "yup";
import {
  showSuccessAlertMessage,
  showErrorAlertMessage,
  clearAlertMessage,
} from "../store/actions/shared/alertActions";
import { broadcastAnnouncement } from "../store/actions/shared/announcementActions";
import { useDispatch, useSelector } from "react-redux";
import { authService } from "../../services/authService";
import { useMsal } from "@azure/msal-react";
import { appInsights } from "../../helpers/appInsights";
import { SeverityLevel } from "@microsoft/applicationinsights-common";
import { convertToFormikError } from "../admin/profiles/myprofile";
import { AppState } from "../store/reducers/rootReducer";
import WarningAmberIcon from "@mui/icons-material/WarningTwoTone";

export const ChangeCCPinPassword: React.FC<{
  passwordValues: CCPasswordChange;
  pinValues: CCPinChange;
  ccMemberDriverId: string;
}> = ({ passwordValues, pinValues, ccMemberDriverId }) => {
  const { t } = useTranslation();
  let history = useNavigate();
  const dispatch = useDispatch();
  const useStyles = makeStyles((theme) => ({
    root: {
      "& .MuiFormHelperText-root": {
        color: "#DF0000",
        fontSize: 12,
        marginLeft: "15px",
      },
      "& ::placeholder": {
        fontStyle: "italic",
        color: "#000",
        opacity: 0.95,
        whiteSpace: "pre-wrap",
        "@media (max-width:330px)": {
          fontSize: "0.6rem",
        },
      },
      "& .MuiTypography-h1": {
        color: "red",
        fontSize: 14,
        marginLeft: "15px",
      },
    },
    gridItem: {
      verticalAlign: "center",
      paddingBottom: 0,
      paddingTop: 10,
      "& .MuiTypography-body2": {
        fontWeight: "bold",
        fontSize: "0.875rem",
      },
    },
    heading: {
      fontSize: "1rem",
      fontWeight: "bold",
    },
    labelText: {
      color: "#000",
      fontFamily: "Arial, Roboto, Segoe UI",
      fontSize: "0.875rem",
      fontWeight: "bold",
      verticalAlign: "middle",
    },
    errorWarning: {
      color: "#DF0000",
      fontSize: "0.75rem",
      marginLeft: "15px",
    },
    backdrop: {
      zIndex: 99999,
      color: "#fff",
    },
    textarea: {
      resize: "both",
    },
    focused: { outline: "none" },
    placeHolder: {
      "& ::placeholder": {
        color: "#767676",
        opacity: "1",
      },
    },
    attention: {
      display: "flex",
      float: "right",
      color: "#000",
      fontFamily: "Arial, Roboto, Segoe UI",
      fontSize: "0.575rem",
      fontWeight: "normal",
      verticalAlign: "middle",
      backgroundColor: "#add8e6",
      backgroundImage: "url(/Content/x.png)",
      backgroundSize: "20px 20px",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "2px",
      padding: "5px",
      borderRadius: "5px 5px 5px",
      border: "1px solid blue",
      paddingLeft: "25px",
      position: "relative",
      justify: "flex-end",
      flexDirection: "row",
      justifyContent: "center",
    },
  }));
  const classes = useStyles();

  const handleCancel = () => {
    history("/home");
  };

  useEffect(() => {
    let isValid = changePasswordValidationSchema.isValidSync(passwordValues);
    let isPinValid = changePinValidationSchema.isValidSync(pinValues);
  }, []);

  const changePasswordValidationSchema = Yup.object().shape({
    currentPassword: Yup.string().required(t("Required").toString()).nullable(),
    newPassword: Yup.string()
      .required(t("Required").toString())
      .min(8, t("Users.Errors.pwMinLength", { length: 8 }))
      .matches(/\d/, "Users.Errors.pwOneDigit")
      .matches(/^.*[!@#€%^&*()\[\]{}].*$/, "Users.Errors.pwOneSpecialCharacter")
      .matches(/\w*[a-z]\w*/, "Users.Errors.pwBothUpperLowerCase")
      .matches(/\w*[A-Z]\w*/, "Users.Errors.pwBothUpperLowerCase")
      .matches(/^(?!.*(\w)\1{2,}).+$/, "Users.Errors.pwNoRepeat")
      .nullable(),
    confirmPassword: Yup.string()
      .oneOf(
        [Yup.ref("newPassword"), null],
        t("Users.Errors.passwordsNotMatch")
      )
      .required(t("Required").toString()),
  });

  const changePinValidationSchema = Yup.object().shape({
    newPin: Yup.string()
      .required(t("Required").toString())
      .min(4, t("Users.Errors.pwMinLength", { length: 4 }))
      .nullable(),
    confirmPin: Yup.string()
      .oneOf([Yup.ref("newPin"), null], t("Users.Errors.pinsNotMatch"))
      .required(t("Required").toString()),
  });

  const [loading, setLoading] = useState(false);
  const { companyInfo } = useSelector((state: AppState) => state.companyInfo);
  const ccPasswordResetLink = companyInfo?.ccPasswordResetLink;
  const csPasswordResetLink = companyInfo?.csPasswordResetLink;

  const takoApiEnv = authService.getTakoApiEnv();
  const isCarShareReservation = String(takoApiEnv).toUpperCase().includes("CS");

  const [isCCLoggedIn, setIsCCLoggedIn] = useState(
    JSON.parse(localStorage.getItem("ccLoggedIn") ?? "false") ?? []
  );

  function logOutCC() {
    setIsCCLoggedIn(false);
    window.location.reload();
  }

  useEffect(() => {
    window.localStorage.setItem("ccLoggedIn", JSON.stringify(isCCLoggedIn));
  }, [isCCLoggedIn]);

  return (
    <Box
      id="changePasswordPinBox"
      boxShadow={3}
      p={3}
      bgcolor="background.paper"
    >
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress />
      </Backdrop>
      <Formik
        initialValues={passwordValues}
        validateOnChange={true}
        validateOnBlur={true}
        validateOnMount={true}
        enableReinitialize={true}
        validationSchema={changePasswordValidationSchema}
        onSubmit={async (values, { setErrors, setSubmitting }) => {
          dispatch(clearAlertMessage());
          setLoading(true);
          await axiosInstance
            .post("/carclub/updatedriverpassword", {
              Password: values.newPassword,
              CurrentPassword: values.currentPassword,
            })
            .then((response) => {
              setSubmitting(false);
              if (response.data.success) {
                logOutCC();
                appInsights.trackEvent({
                  name: "My CC Password Updated",
                  properties: { CCMemberDriverId: ccMemberDriverId },
                });
                dispatch(showSuccessAlertMessage(t("Users.ccPasswordUpdated")));
                dispatch(broadcastAnnouncement(t("Users.ccPasswordUpdated")));
              }
              //errors in updating profile
              else {
                let message = _.camelCase(response.data.message);
                message = t(`Users.Errors.${message}`);
                dispatch(showErrorAlertMessage(message));
                dispatch(broadcastAnnouncement(message));
              }
              window.scrollTo(0, 0);
            })
            .catch((err) => {
              setSubmitting(false);
              if (err.response && err.response.data.messages) {
                if (Object.keys(err.response.data.errors).length > 0) {
                  for (const [key, value] of Object.entries(
                    err.response.data.errors
                  )) {
                    console.log(`${key}: ${value}`);
                    // errors;
                  }
                  let errors = convertToFormikError(
                    err.response.data.errors,
                    t
                  );
                  //  console.log("ERRS: ", errors);
                  setErrors(errors);
                }
                let errorlist = err.response.data.messages.filter(
                  (n) => n !== ""
                );
                const translatedErrorlist = errorlist
                  .map(function (value) {
                    return t(value);
                  })
                  .join("\r\n");
                dispatch(showErrorAlertMessage(translatedErrorlist));
                dispatch(broadcastAnnouncement(translatedErrorlist));
              } else {
                dispatch(
                  showErrorAlertMessage("unable to save the car club user.")
                );
                dispatch(
                  broadcastAnnouncement("unable to save the car club user.")
                );
              }

              appInsights.trackException({
                exception: err,
                properties: {
                  method: "onSubmit",
                  Component: "MyCCprofilePersonalInfo",
                },
                severityLevel: SeverityLevel.Error,
              });
            });
          setLoading(false);
          window.scrollTo(0, 0);
        }}
      >
        {({
          values,
          isValid,
          dirty,
          setFieldValue,
          setFieldTouched,
          touched,
          handleChange,
          handleBlur,
          errors,
          isSubmitting,
        }) => (
          <Form /* style={{ width: "100%" }} */>
            <Grid container className={classes.placeHolder}>
              <Grid item xs={12} md={12}>
                <Typography
                  id="passwordtitleHeader"
                  //onClick={()=>{console.log("values", values, "isValid",isValid, "dirty",dirty, "result",!isValid || !dirty,"errors", errors,"touched",touched,changePasswordValidationSchema);}}
                  variant="h1"
                  tabIndex={-1}
                >
                  {t("Users.ChangePasswordTitle")}
                </Typography>
              </Grid>
              <Grid item xs={12} md={12} className={classes.gridItem}>
                <label
                  htmlFor={"currentPassword"}
                  className={classes.labelText}
                >
                  {t("Users.CurrentPassword")}
                </label>
              </Grid>
              <Grid item xs={12} md={12} className={classes.gridItem}>
                <Field
                  name="currentPassword"
                  id="currentPassword"
                  value={values.currentPassword}
                  fullWidth
                  style={{ paddingBottom: 0, paddingTop: 0 }}
                  type="password"
                  inputProps={{
                    "aria-label": t("Users.CurrentPassword"),
                    "aria-required": "true",
                    "aria-describedby": "currentPassword",
                  }}
                  onChange={(e) => {
                    setFieldTouched("currentPassword");
                    setFieldValue("currentPassword", e.target.value);
                    let currentValues = {
                      ...values,
                      currentPassword: e.target.value,
                    };
                    isValid =
                      changePasswordValidationSchema.isValidSync(currentValues);
                  }}
                  onBlur={handleBlur}
                  component={TextField}
                  variant="outlined"
                  error={
                    touched.currentPassword &&
                    errors.currentPassword !== undefined
                      ? true
                      : false
                  }
                  placeholder={""}
                />
                {touched.currentPassword && errors.currentPassword ? (
                  <span
                    id="currentPasswordError"
                    className={classes.errorWarning}
                    role="alert"
                  >
                    {t(errors?.currentPassword ?? "")}
                  </span>
                ) : (
                  <span id="currentPasswordError"></span>
                )}
              </Grid>
              <Grid item xs={12} md={12} className={classes.gridItem}>
                <Link
                  id="carclubresetpwhyperlinkpp"
                  target="_blank"
                  underline="always"
                  onClick={logOutCC}
                  href={isCarShareReservation ? csPasswordResetLink : ccPasswordResetLink}
                  display="inline"
                  variant="body2"
                  style={{ fontSize: "1.2em", fontWeight: "normal" }}
                >
                  {t("Users.ForgotPassword")}
                  <Box component="span" className="visuallyHidden">
                    {t("Footer.OpenNewWindow")}
                  </Box>
                </Link>
              </Grid>
              <Grid item xs={12} md={12} className={classes.gridItem}>
                <label htmlFor={"newPassword"} className={classes.labelText}>
                  {t("Users.NewPassword")}
                </label>
              </Grid>
              <Grid item xs={12} md={12} className={classes.gridItem}>
                <Field
                  name="newPassword"
                  id="newPassword"
                  value={values.newPassword}
                  fullWidth
                  style={{ paddingBottom: 0, paddingTop: 0 }}
                  inputProps={{
                    "aria-label": t("Users.NewPassword"),
                    "aria-required": true,
                    "aria-describedby": "newPassword",
                  }}
                  type="password"
                  onChange={(e) => {
                    setFieldTouched("newPassword");
                    setFieldValue("newPassword", e.target.value);
                    let currentValues = {
                      ...values,
                      newPassword: e.target.value,
                    };
                    isValid =
                      changePasswordValidationSchema.isValidSync(currentValues);
                  }}
                  onBlur={handleBlur}
                  component={TextField}
                  variant="outlined"
                  error={
                    touched.newPassword && errors.newPassword !== undefined
                      ? true
                      : false
                  }
                  placeholder={""}
                />
                {touched.newPassword && errors.newPassword ? (
                  <span
                    id="newPasswordError"
                    className={classes.errorWarning}
                    role="alert"
                  >
                    {t(errors?.newPassword ?? "")}
                  </span>
                ) : (
                  <span id="newPasswordError"></span>
                )}
              </Grid>

              <Grid item xs={12} md={12} className={classes.gridItem}>
                <label
                  htmlFor={"confirmPassword"}
                  className={classes.labelText}
                >
                  {t("Users.ConfirmPassword")}
                </label>
              </Grid>
              <Grid item xs={12} md={5} />
              <Grid
                item
                xs={12}
                style={{
                  paddingBottom: 0,
                  paddingTop: 10,
                  marginBottom: "1em",
                }}
              >
                <Field
                  name="confirmPassword"
                  id="confirmPassword"
                  value={values.confirmPassword}
                  fullWidth
                  style={{ paddingBottom: 0, paddingTop: 0 }}
                  inputProps={{
                    "aria-label": t("Users.ConfirmPassword"),
                    "aria-required": true,
                    "aria-describedby": "confirmPassword",
                  }}
                  type="password"
                  onChange={(e) => {
                    setFieldTouched("confirmPassword");
                    setFieldValue("confirmPassword", e.target.value);
                    let currentValues = {
                      ...values,
                      confirmPassword: e.target.value,
                    };
                    isValid =
                      changePasswordValidationSchema.isValidSync(currentValues);
                  }}
                  onBlur={handleBlur}
                  component={TextField}
                  variant="outlined"
                  error={
                    touched.confirmPassword &&
                    errors.confirmPassword !== undefined
                      ? true
                      : false
                  }
                  placeholder={""}
                />
                {touched.confirmPassword && errors.confirmPassword ? (
                  <span
                    id="confirmPassword"
                    className={classes.errorWarning}
                    role="alert"
                  >
                    {t(errors?.confirmPassword ?? "")}
                  </span>
                ) : (
                  <span id="confirmPasswordError"></span>
                )}
              </Grid>

              <Grid container justifyContent="flex-end">
                <Button
                  color="primary"
                  id="btnSubmit"
                  variant="contained"
                  disabled={!isValid || !dirty}
                  type="submit"
                  style={{ textTransform: "none" }}
                >
                  {t("Users.btnSave")}
                </Button>
                <Button
                  color="primary"
                  id="btnCancel"
                  variant="outlined"
                  type="button"
                  onClick={handleCancel}
                  style={{ marginLeft: "0.5em", textTransform: "none" }}
                >
                  {t("Users.btnCancel")}
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>

      <Formik
        initialValues={pinValues}
        validateOnChange={true}
        validateOnBlur={true}
        enableReinitialize={true}
        validationSchema={changePinValidationSchema}
        onSubmit={async (values, { setErrors, setSubmitting }) => {
          setLoading(true);
          await axiosInstance
            .post("/carclub/updatedriverpin", { Pin: values.newPin })
            .then((response) => {
              setSubmitting(false);
              if (response.data.success) {
                appInsights.trackEvent({
                  name: "Car Club PIN Updated",
                  properties: { CCMemberDriverId: ccMemberDriverId },
                });
                dispatch(showSuccessAlertMessage(t("Users.ccPinUpdated")));
                dispatch(broadcastAnnouncement(t("Users.ccPinUpdated")));
              }
              //errors in updating profile
              else {
                let message = _.camelCase(response.data.message);
                if (message.indexOf("IsNotAValidPin") > 0) {
                  let prefix = message.replace("IsNotAValidPin", "");
                  message = `${prefix} ${t("Users.Errors.isNotAValidPin")}`;
                } else {
                  message = t(`Users.Errors.${message}`);
                }
                dispatch(showErrorAlertMessage(message));
                dispatch(broadcastAnnouncement(message));
              }
              window.scrollTo(0, 0);
            })
            .catch((err) => {
              setSubmitting(false);
              if (err.response && err.response.data.messages) {
                if (Object.keys(err.response.data.errors).length > 0) {
                  for (const [key, value] of Object.entries(
                    err.response.data.errors
                  )) {
                    console.log(`${key}: ${value}`);
                    // errors;
                  }
                  let errors = convertToFormikError(
                    err.response.data.errors,
                    t
                  );
                  //  console.log("ERRS: ", errors);
                  setErrors(errors);
                }
                let errorlist = err.response.data.messages.filter(
                  (n) => n !== ""
                );
                const translatedErrorlist = errorlist
                  .map(function (value) {
                    return t(value);
                  })
                  .join("\r\n");
                dispatch(showErrorAlertMessage(translatedErrorlist));
                dispatch(broadcastAnnouncement(translatedErrorlist));
              } else {
                dispatch(
                  showErrorAlertMessage("unable to save the car club user.")
                );
                dispatch(
                  broadcastAnnouncement("unable to save the car club user.")
                );
              }

              appInsights.trackException({
                exception: err,
                properties: {
                  method: "onSubmit",
                  Component: "MyCCprofilePersonalInfo",
                },
                severityLevel: SeverityLevel.Error,
              });
            });
          setLoading(false);
          window.scrollTo(0, 0);
        }}
      >
        {({
          values,
          isValid,
          dirty,
          setFieldValue,
          setFieldTouched,
          touched,
          handleChange,
          handleBlur,
          errors,
          isSubmitting,
        }) => (
          <Grid container>
            <Grid item xs={12} md={12} className={classes.gridItem}></Grid>
            <Grid item xs={12} md={12} className={classes.gridItem}>
              <Typography
                id="pintitleHeader"
                //ref={divRef}
                variant="h1"
                tabIndex={-1}
              >
                {t("Users.ChangePinTitle")}
                <span className={classes.attention} role="alert">
                  <WarningAmberIcon />
                  {t("Users.ccPinChangeAlert")}
                </span>
              </Typography>
            </Grid>
            <Backdrop className={classes.backdrop} open={loading}>
              <CircularProgress />
            </Backdrop>

            <Grid
              container
              direction="row"
              style={{ marginTop: "1em" }}
              className={classes.root}
            >
              <Form style={{ width: "100%" }}>
                <Grid container>
                  <Grid
                    style={{
                      paddingBottom: 0,
                      paddingTop: 10,
                      marginBottom: "1em",
                    }}
                    item
                    xs={12}
                  >
                    <Typography style={{ fontWeight: "bold" }}>
                      {t("Users.NewPin")}
                    </Typography>
                    <Field
                      name="newPin"
                      id="newPin"
                      fullWidth
                      style={{ paddingBottom: 0, paddingTop: 0 }}
                      inputProps={{
                        "aria-label": t("Users.NewPin"),
                        "aria-required": true,
                      }}
                      type="password"
                      onChange={(e) => {
                        setFieldTouched("newPin");
                        setFieldValue("newPin", e.target.value);
                        let currentValues = {
                          ...values,
                          newPin: e.target.value,
                        };
                        isValid =
                          changePinValidationSchema.isValidSync(currentValues);
                      }}
                      onBlur={handleBlur}
                      component={TextField}
                      variant="outlined"
                      error={
                        touched.newPin && errors.newPin !== undefined
                          ? true
                          : false
                      }
                      placeholder={""}
                    />
                    {touched.newPin && errors.newPin ? (
                      <span
                        id="newPinError"
                        className={classes.errorWarning}
                        role="alert"
                      >
                        {t(errors?.newPin ?? "")}
                      </span>
                    ) : (
                      <span id="newPinError"></span>
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{
                      paddingBottom: 0,
                      paddingTop: 10,
                      marginBottom: "1em",
                    }}
                  >
                    <Typography style={{ fontWeight: "bold" }}>
                      {t("Users.ConfirmPin")}
                    </Typography>
                    <Field
                      name="confirmPin"
                      id="confirmPin"
                      fullWidth
                      style={{ paddingBottom: 0, paddingTop: 0 }}
                      inputProps={{
                        "aria-label": t("Users.ConfirmPin"),
                        "aria-required": true,
                      }}
                      type="password"
                      onChange={(e) => {
                        setFieldTouched("confirmPin");
                        setFieldValue("confirmPin", e.target.value);
                        let currentValues = {
                          ...values,
                          confirmPin: e.target.value,
                        };
                        isValid =
                          changePinValidationSchema.isValidSync(currentValues);
                      }}
                      onBlur={handleBlur}
                      component={TextField}
                      variant="outlined"
                      error={
                        touched.confirmPin && errors.confirmPin !== undefined
                          ? true
                          : false
                      }
                      placeholder={""}
                    />
                    {touched.confirmPin && errors.confirmPin ? (
                      <span
                        id="confirmPinError"
                        className={classes.errorWarning}
                        role="alert"
                      >
                        {t(errors?.confirmPin ?? "")}
                      </span>
                    ) : (
                      <span id="confirmPinError"></span>
                    )}
                  </Grid>
                  <Grid container justifyContent="flex-end">
                    <Button
                      color="primary"
                      id="btnSubmitPin"
                      variant="contained"
                      disabled={!isValid || !dirty}
                      type="submit"
                      style={{ textTransform: "none" }}
                    >
                      {t("Users.btnSave")}
                    </Button>
                    <Button
                      color="primary"
                      id="btnCancelPin"
                      variant="outlined"
                      type="button"
                      onClick={handleCancel}
                      style={{ marginLeft: "0.5em", textTransform: "none" }}
                    >
                      {t("Users.btnCancel")}
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            </Grid>
          </Grid>
        )}
      </Formik>
    </Box>
  );
};

export default ChangeCCPinPassword;
