import { Dispatch } from "redux";
import {
  MY_JOURNEY_FAIL,
  MY_JOURNEY_LOADING,
  MY_JOURNEY_SUCCESS,
  MyJourneyDispatchTypes,
} from "./myjourneyActionTypes";
import axiosInstance from "../../../../helpers/axiosInstance";

export const GetMyJourneys = () => async (
  dispatch: Dispatch<MyJourneyDispatchTypes>
) => {
  try {
    dispatch({
      type: MY_JOURNEY_LOADING,
    });
    const result = await axiosInstance.get('myjourney/recent/5');
     dispatch({
      type: MY_JOURNEY_SUCCESS,
      payload: result.data,
    });
  } catch (e) {
     dispatch({
      type: MY_JOURNEY_FAIL,
    });
  }
};
