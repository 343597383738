import { ReservationEstimate } from "components/Reservation/steps/CarClub/carClubFormType";
import { Payment } from "components/Reservation/steps/Payment/paymentType";

export interface ReservationViewModel {
  companyUId: string;
  accountNumber: string;
  companyAccountId: number;
  bookerName: string;
  bookingReferences: string[];
  branchAddress: string;
  branchTelephone: string;
  vehicleClass: string;
  fullBrandName: string;
  notes: string;
  showReservationDetails: boolean;
  reservationNumberString: string;
  startLocationString: string;
  endLocationString: string;
  startMethodString: string;
  startServicingBranch: string;
  endMethodString: string;
  endServicingBranch: string;
  reservationStatus: string;
  endDateTimeString: string;
  startDateTimeString: string;
  driverName: string;
  driverEmail: string;
  displayName: string;
  journeyDetails: string;
  journeyViewModel: JourneyProfilerViewModel;
  JourneyUId: string;
  reservationStatusUId: string;
  StartLatitude: string;
  StartLongitude: string;
  endLatitude: string;
  endLongitude: string;
  startLocationPeopleSoftId: string;
  startLocationStationId: string;
  endLocationPeopleSoftId: string;
  endLocationStationId: string;
  deliveryCollectionEnabled: string;
  isAccountValidated: string;
  clientCurrentDateTime: string;
  reservationNumber: reservationNumberViewModel;
  bookerViewModel: ReservationBookerViewModel;
  driverViewModel: ReservationDriverViewModel;
  dateTime: ReservationDateTimeViewModel;
  reasonForHire: ReservationReasonForHireViewModel;
  loyalty: ReservationLoyaltyViewModel;
  carClass: ReservationCarClassViewModel;
  locations: ReservationLocationsViewModel;
  LocationsURL: string;
  notesViewModel: ReservationNotesViewModel;
  additionalInformation: ReservationAdditionalInformationViewModel;
  isStartDateInPast: boolean;
  isNonEditable: boolean;
  resetJourneyDateTime: boolean;
  isRebuildReservation: boolean;
  edtAction: number;
  isInternationalStartLocation: boolean;
  notificationMessage: string;
  primaryDriverDeclaration: DriverDeclaration;
  additionalDriver: DriverDeclaration[];
  driverDeclaration: boolean;
  earliestPossibleEndDate?: Date;
  latestPossibleEndDate?: Date;
  earliestPossibleEndDateString: string;
  latestPossibleEndDateString: string;
  reservationNewEndDateTime?: Date;
  reservationNewEndDateTimeString: string;
  offHire: boolean;
  newEndTime?: string;
  newEndDate?: string;
  flightNumberViewModel: FlightNumberViewModel;
  isDriverValidityActivated: boolean;
  isCreditCardToggleEnabled : boolean;
  isCreditCard : boolean;
  payment? : Payment;
}

export interface FlightNumberViewModel {
  code?: string;
  flightNumber?:string;
  operatingCompanyCode?:string;
}

export interface JourneyDetailsViewModel {
  journeyUId: string;
  startDateTime: Date;
  endDateTime: Date;
  startAddress: string;
  endAddress: string;
  referenceNumber: string;
  driver: string;
  status: string;
  modeOfTransport: string;
  registrationNumber: string;
  midPoints: string[];
  bookingReferences: string[];
  riskAssessmentQA: RiskAssessmentQuestionAnswerDto[];
  additionalInformation: ReservationAdditionalInformationViewModel;
}

export interface JourneyItemActionViewModel {
  journeyUId: string;
  referenceNumber: string;
  hasReservation: boolean;
  isStartDateInPast: boolean;
  canView: boolean;
  canEdit: boolean;
  canDelete: boolean;
  canCopy: boolean;
  canEmail: boolean;
  canPrint: boolean;
  isCarClub: boolean;
}

export const reportColumns = [
  //it's order of columns in export and also used in translation
  "companyName",
  "brand",
  "rentalTypeName",
  "referenceNumber",
  "reservationCreated",
  "driverName",
  "startDateTime",
  "endDateTime",
  "startLocation",
  "distanceTravelled",
  "status",
  "startLocationBranchCode",
  "endLocationBranchCode",
  "carType",
  "carClass",  
  "numberOfDays",
  "dayRate",
  "weekRate",
  "monthRate",
  "estimateTotal",
  "estimateCurrency",
  "userName",
  "reservationModified",
  /* endLocation,  
  endDateTime,  
  endLocationStationId,  
  journeyUId,
  loyaltyNumber,  
  productUId,  
  startDateTime,
  startLocationStationId,
  statusUId, */
]

interface RiskAssessmentQuestionAnswerDto {
  question: string;
  answer: string;
}

export interface reservationNumberViewModel {
  number: string;
}
export interface ReservationBookerViewModel {
  titledFullName: string;
}
export interface ReservationDriverViewModel {
  UserTitle: string;
  firstName: string;
  lastName: string;
  emailAddress: string;
  ContactPhoneNumber: string;
  AlternatePhoneNumber: string;
}

export interface ReservationDateTimeViewModel {
  startDateTime: string;
  endDateTime: string;
}

export interface ReservationReasonForHireViewModel {
  accountUId: string;
  accountName: string;
  displayName: string;
  accountNumber: string;
  availableReasonsForHire: string;
  isSelectedAccountValid: string;
  renderTime: string;
  reasonForHireLabel: string;
  brands: string;
  isCreditCard:boolean;
  isPayAtCounter: boolean;
  hasErrors: boolean;
  errors:  string[];
}

export interface ReservationLoyaltyViewModel {
  loyaltyId: string;
  enableLoyalty: boolean;
}

export interface ReservationCarClassViewModel {
  carClassId: string;
  carClassCode: string;
  carClass: string;
  brand: string;
  carsInformation: ReservationCarInfoViewModel;
  distance: Distance;
  selectList: string;
  renderTime: string;
}

export interface CoverageViewModel {
  amount: number;
  currency: string;
  description: string;
  includedInEstimatedAmount: boolean;
  includedInRate: boolean;
  taxInclusive: boolean;
}

export interface FeeViewModel {
  baseAmount: number;
  currency: string;
  description: string;
  includedInEstimatedAmount: boolean;
  includedInRate: boolean;
  totalAmount: number;
}

export interface AdditionalRentalChargeViewModel {
  description: string;
  rate: number;
  currency: string;
  rateWithCurrency: string;
  pricePerUnitWithCurrency: string;
  unitName: string;
}

export interface ReservationExtrasOptionsViewModel {
  brand: string;
  extrasTotalAmount: number;
  extras: ReservationExtrasViewModel[];
}

export interface ReservationExtrasViewModel {
  id: string;
  description: string;
  totalCost: number;
  unitPrice: number;
  taxAmount: number;
  currency: string;
  amount: number;
  isSelected: boolean;
  isCountable: boolean;
  quantity: number;
  includedInEstimatedAmount: number;
  type: string;
  equipmentType: EquipmentType;
  unitPriceWithCurrency: string;
  totalUnitPriceWithCurrency: string;
  totalPriceWithCurrency: string;
}
export enum EquipmentType {
  Unknown = 0,
  Mandatory = 1,
  Inclusive = 2,
}
export interface BrandInfoViewModel {
  additionalRentalCharge: AdditionalRentalChargeViewModel[];
  baseEstimatedTotalAmount: number;
  baseRatePrice: number;
  baseRatePriceWithCurrency: string;
  brand: string;
  distance: Distance;
  currency: string;
  estimatedTotalAmount: number;
  extrasOptions: ReservationExtrasOptionsViewModel;
  includedExtras: ReservationExtrasViewModel[];
  isSelected: boolean;
  isSelectedPosition: string;
  pricePerBase: number;
  pricePerBaseWithCurrency: string;
  rate: string;
  unitName: string;
}

export interface ClassInfoViewModel {
  airCondition: boolean;
  baggageQt: number;
  brandInfo: BrandInfoViewModel[];
  category: string;
  coverages: CoverageViewModel[];
  doorCount: string;
  fees: FeeViewModel[];
  distance: Distance;
  fuel: string;
  manual: boolean;
  modelCode: string;
  modelDescription: string;
  passengerQt: 0;
  pictureUrl: null;
  preferred: boolean;
  size: string;
  vehicleClass: string;
}

export interface Distance {
  quantity: number;
  unit: string;
  unlimited: boolean;
}

export interface ReservationCarInfoViewModel {
  classInfo: ClassInfoViewModel[];
  isDualBrandingCarClass: boolean;
  totalEstimatedCostWithCurrency: string;
}

export interface ReservationLocationsViewModel {
  startLocation: ReservationLocationViewModel;
  endLocation: ReservationLocationViewModel;
}

export interface ReservationLocationViewModel {
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  addressLocationSearch: string;
  addressType: { selectedValue: string; items: []; selectedText: string };
  airportCity: string;
  branchDescription: string;
  branchDescriptionWithBranchCode: string;
  branchLocationSearch: string;
  brand: string;
  country: string;
  divType: string;
  isAirportLocation: boolean;
  isCollectionAccepted: boolean;
  isDeliveryAccepted: boolean;
  isDeliveryAddress: boolean;
  isDeliveryAndCollectionEnabled: boolean;
  latitude: string;
  locationEditorName: string;
  longitude: string;
  location: string;
  methodTypes: string;
  methodTypesExists: boolean;
  nearestBranchIsAirportHasDuplicatedBrand: boolean;
  peopleSoftId: string;
  postCode: boolean;
  selectedMethodTypeId: number;
  showControls: boolean;
  stationId: string;
  telephone: string;
  title: string;
  townOrCity: string;
  userSelectedBrand: string;
  countryCode: string;
}

export interface ReservationNotesViewModel {
  reservationNote: string;
}

export interface ReservationAdditionalInformationViewModel {
  additionalInformationFields: ReservationAdditionalInformationFieldViewModel[];
  message: string;
  renderTime: string;
}

export interface ReservationAdditionalInformationFieldViewModel {
  additionalInformationGid: string;
  sequence: number;
  fieldTypeCode: number;
  fieldName: string;
  helperText: string;
  fieldValue: string;
  fieldValueDateString: string;
  dropDownList: DropDownList[];
  dropDownListSelectedValue: string;
  maxLength: string;
  mandatory: boolean;
  includeInReservation: boolean;
  isBillingRef: boolean;
  customValidate: boolean;
  webApproved: boolean;
}

export interface JourneyProfilerViewModel {
  activeMileageReimbursement: boolean;
  activeTravelPolicy: boolean;
  additionalInformation: string;
  allPoints: string;
  availableModesOfTransport: DropDownList[];
  bookingReferences: string[];
  clientCurrentDateTime: string;
  disableEditJourney: boolean;
  edtAction: number;
  endDateTime: string;
  endLatitude: string;
  endLocation: ReservationLocationViewModel;
  endLocationAddressJson: string;
  endLongitude: string;
  endPeopleSoftId: string;
  endStationId: string;
  isDailyRentalSingleProduct: boolean;
  isInternationalStartLocation: boolean;
  isJourneyProfile: boolean;
  isMidpointsEnabled: boolean;
  isMidpointsEnabledInAdminSide: boolean;
  isRebook: boolean;
  journeyProfilerUId: string;
  modeOfTransportUId: string;
  originalJourneyProfilerUId: string;
  referenceNumber: string;
  registrationNumber: string;
  reservationCreated: boolean;
  riskAssessmentExists: boolean;
  startDateTime: string;
  startLocation: ReservationLocationViewModel;
  startLocationAddressJson: string;
  useOwnVehicle: boolean;
  useStartAsEndLocation: boolean;
  viaPoints: ViaPoints[];
}

interface ViaPoints {
  newClientIndex: number;
  destination: string;
  peopleSoftId: string;
  stationId: string;
  latitude: string;
  longitude: string;
  travelDistance: number;
  travelDistanceDurationString: string;
  travelDuration: number;
  order: number;
  uId: string;
  locationPlaceholder: string;
}

export interface CarClubReservationViewModel {
  additionalInformation: ReservationAdditionalInformationViewModel;
  journeyUId: string;
  carClubReferenceNumber: string;
  carClubDriverName: string;
  startDateString: string;
  endDateString: string;
  memoCode: string;
  vehicleLocation: string;
  vehicleDetails: string;
  carClubStartLocationLabel: string;
  carClubEndLocationLabel: string;
  carClubViaLocationLabel: string[];
  imageUrl: string;
  status: string;
  bookingReferences: string[];
  startDateTime: string;
  endDateTime: string;
  bookerName: string;
  reservationEstimate: ReservationEstimate | null;
  errors: string;
  warnings: string;
  hasErrors: boolean;
  hasWarnings: boolean;
  isPastReservation: boolean;
  additionalDrivers
}

export interface JourneyProfilerProductViewModel {
  uId: string;
  companyProductUId: string;
  companyUId: string;
  productId: number;
  productUId: string;
  productName: string;
  productImage: string;
  companyCarShareEnrollmentLink: string;
  companyCarShareResetPasswordLink: string;
  isAvailable: boolean;
  isMidpointEnabled: boolean;
  enableLoyalty: boolean;
  isSelectable: boolean;
  descriptionList: string[];
  isPreffered: boolean;
  notSelectableMessage: string;
  carClubCompanyId: string;
  carClubDriverId: string;
  pin: string;
  displayMidpoints: boolean;
  hours: number;
  days: number;
  totalMinutes: number;
  isSharedAccessEnabled: boolean;
}

export interface MileageReimbursementViewModel {
  journeyUId: string;
  additionalInformation: ReservationAdditionalInformationViewModel;
}

export interface ProductSelectionViewModel {
  journeyProfileUId: string;
  isCarShare: boolean;
  validDriverId: boolean;
  product: JourneyProfilerProductViewModel;
}

export enum BrandType {
  ET = "ENTERPRISE",
  AL = "ALAMO",
  ZL = "NATIONAL",
  CI = "CITER",
  AT = "ATESA",
}

export enum JourneyMethodType {
  walkIn = "1",
  delivery = "2",
  returnToBranch = "3",
  collection = "4",
}

export enum JourneyMethodType1 {
  walkIn = 1,
  delivery = 2,
  returnToBranch = 3,
  collection = 4,
}

export enum JourneyType {
  journeyProfiler = "00000000-0000-0000-0000-000000000000",
  carClub = "F4542E26-E2F1-41F7-9413-E6C49D504961",
  dailyRental = "83EC23E7-C05A-4417-ADE3-F4F0D52DDA2E",
  mileageReimbursement = "E6999A23-447E-4F49-A35D-4E75B94279AF",
  specialityVehicle = "9BEC68B2-D6DE-4156-9370-CF8DA54F34B5"
}

export enum ReservationStatusType {
  pending = "35A8B8C5-9E26-4C43-BA26-ED9142E70237",
  open = "3BCBDDE4-D59A-4932-96C7-18FE24B27DDF",
  confirmed = "BE1993F7-CD0F-429B-AD50-89A4A9DAD303",
  checkedOut = "F266DF32-412B-4824-BBC6-46CAB24304E6",
  noShow = "60CB7F7B-9F6C-4556-806E-73A75A385850",
  cancelled = "65E96E66-ACD5-4B52-9E0E-2695AA66EB31",
  closed = "93170CCB-9910-4067-854A-E201DB4AD46D",
  turnDown = "F8DE846D-F01D-4EE4-A25D-CB3727D0E705",
  wrapUp = "BC88D31B-E9B4-4008-AF96-462FC004ABF9",
  ticket = "D3869C9C-37EF-4479-8857-7CBDB288F151",
  invoiced = "FC9E852D-DF59-4ADA-9C7E-B1BC89389802",
  directbill = "674CC7C1-B14D-49E8-9895-A1274146A0BC",
  cardpayment = "32BCE52A-2A54-4369-ACE3-C9402F1DF31C"
}

export enum DateRangeType {
  today = "today",
  last7Days = "last7Days",
  last30Days = "last30Days",
  thisMonth = "thisMonth",
  lastMonth = "lastMonth",
  customRange = "customRange",
  pleaseSelect = "-1",
}

export enum ReservationAdditionalInformationFieldTypeCode {
  None = 0,
  Date = 1,
  DropDownList = 2,
  ExactValue = 3,
  Pattern = 4,
  Text = 5,
}

export interface DropDownList {
  disabled: boolean;
  group: {
    disabled: boolean;
    name: string;
  };
  selected: boolean;
  text: string;
  value: string;
}

export enum localeMapping {
  en = "en-gb",
  de = "de-DE",
}

export interface DriverDeclaration {
  driverDeclarationDetailId : number;
  driverDeclarationId? : number;
  companyInternalNumber : number;
  firstName : string;
  lastName : string;
  emailAddress : string;
  driverDeclarationExpiryDate : Date;
  driverDeclarationStatusId? : number;
  driverDeclarationStatus : string;
}