import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import CancelIcon from "@mui/icons-material/Cancel";
import { renderToStaticMarkup } from "react-dom/server";
import { useTranslation } from "react-i18next";
import Divider from "@mui/material/Divider";
import {
  CompanyNotificationRecipientViewModel,
  companyNotificationsViewModel,
  ReservationNotificationViewModel,
} from "./companiesType";
import "../../../views/general.css";
import { appInsights } from "helpers/appInsights";
import { SeverityLevel } from "@microsoft/applicationinsights-common";
import {
  Box,
  Switch,
  Backdrop,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import axiosInstance from "../../../helpers/axiosInstance";
import {
  showSuccessAlertMessage,
  showErrorAlertMessage,
  clearAlertMessage,
} from "../../store/actions/shared/alertActions";
import { broadcastAnnouncement } from "../../store/actions/shared/announcementActions";
import { useDispatch } from "react-redux";
import linkDeleteIcon from "Assets/Images/LinkDeleteRedCross.png";
import { isIE } from "react-device-detect";
var InitialValue = {
  title: "",
  existingCompanyName: "",
  reservationNotification: {
    uId: "",
    companyNotificationId: 0,
    companyNotificationUId: "",
    companyId: 0,
    companyUId: " ",
    productId: 0,
    productUId: "",
    name: "",
    notificationType: "",
    activate: false,
    sendNotificationToBooker: false,
    sendNotificationToDriver: false,
    newRecipient: "",
    notificationRecipients: [
      {
        companyNotificationRecipientUId: "",
        companyNotificationId: 0,
        companyNotificationUId: "",
        recipientType: 0,
        recipient: "",
      },
    ] as CompanyNotificationRecipientViewModel[],
  } as ReservationNotificationViewModel,
} as unknown as companyNotificationsViewModel;

const intiNotificationRecipients: CompanyNotificationRecipientViewModel[] = [];

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormHelperText-root": {
      color: "#DF0000",
      fontSize: 12,
      marginLeft: "15px",
    },
    "& ::placeholder": {
      fontStyle: "italic",
      color: "#000",
      opacity: 0.95,
    },
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
  outlinedButton: {
    border: "1px solid rgba(0, 102, 57, 0.95);",
  },
  inputRoot: {
    fontSize: 30,
  },
  labelRoot: {
    fontSize: 30,
    color: "red",
    "&$labelFocused": {
      color: "purple",
    },
  },
  errorWarning: {
    color: "#DF0000",
    fontSize: 12,
    marginLeft: "15px",
  },
  backdrop: {
    zIndex: 99999,
    color: "#fff",
  },

  labelFocused: {},
}));

const Notifications = (props) => {
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);

  const [dailyRental, setdailyRental] =
    React.useState<companyNotificationsViewModel>(InitialValue);

  const [notificationRecipients, setNotificationRecipients] = React.useState(
    intiNotificationRecipients
  );

  const [saveButtonStatusTouched, setSaveButtonStatusTouched] =
    React.useState(false);

  const [newEmailAddress, setNewEmailAddress] = React.useState("");

  const [activate, setActivate] = React.useState(false);

  const [sendNotificationToBooker, setSendNotificationToBooker] =
    React.useState(false);

  const [sendNotificationToDriver, setSendNotificationToDriver] =
    React.useState(false);

  const classes = useStyles();
  const dispatch = useDispatch();

  const titleRef = React.useRef<HTMLDivElement>(null);

  const [linkTableRowCount, setLinkTableRowCount] = React.useState(100);

  var pattern = new RegExp(/^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i);

  React.useEffect(() => {
    dispatch(clearAlertMessage());
    setLoading(true);

    getDailyRental(props.companyUId);
    if (titleRef.current !== null) titleRef.current.focus();
  }, [props]);

  function validateEmailAddress(emailAddress) {
    let isValid = true;

    if (!emailAddress) {
      isValid = false;
      return isValid;
    }

    if (!pattern.test(emailAddress)) {
      isValid = false;
    }

    return isValid;
  }

  const recipientTable = (
    notificationRecipients: CompanyNotificationRecipientViewModel[]
  ) => {
    return (
      <div style={{ width: "80%" }}>
        <table id="recipientTable" style={{ width: "100%", border: "1" }}>
          <tbody>
            {notificationRecipients.map((aLink, index) =>
              recipientRow(aLink, index)
            )}
          </tbody>
        </table>
      </div>
    );
  };

  const recipientRow = (
    aLink: CompanyNotificationRecipientViewModel,
    index: any
  ) => {
    return (
      <tr id={aLink.companyNotificationRecipientUId} key={index}>
        <td style={{ width: "90%" }}>
          <div
            id={"Input_" + aLink.companyNotificationRecipientUId}
            style={{
              border: "1px solid grey",
              width: "100%",
              wordBreak: "break-all",
              wordWrap: "break-word",
            }}
          >
            {aLink.recipient}
          </div>

          {/* <input
            type="text"
            id={"Input_" + aLink.companyNotificationRecipientUId}
            style={{ width: "100%" }}
            disabled={true}
            defaultValue={aLink.recipient}
            aria-label={aLink.recipient}
          /> */}
        </td>
        <td style={{ width: "10%" }}>
          {/* <input
            id={
              "removeNotificationRecipientButton_" +
              aLink.companyNotificationRecipientUId
            }
            //ref={input => input && input.focus()}
            type="image"
            className="input"
            title={t("RiskAssessment.deleteEmailTooltips")}
            alt={t("Delete Email")}
            src={linkDeleteIcon}
            aria-label={t("RiskAssessment.deleteEmail")}
            color="primary"
            style = {isIE?{ height: "18px", width: "18px",borderColor: "red", borderWidth: 1, }:{height: "18px", width: "18px", borderColor: 'Green', borderWidth: 3,  marginBottom: 0, outlineColor: "#006639"}}
           // {{ height: "18px", width: "18px",borderColor: 'Green',borderWidth: 1,   outlineStyle: "solid", outlineColor: "#006639"}}
            onClick={() => {
              handleDeleteRow(aLink.companyNotificationRecipientUId,
                aLink.recipient);
              setSaveButtonStatusTouched(true);
            }}
          /> */}
          <IconButton
            id={
              "removeNotificationRecipientButton_" +
              aLink.companyNotificationRecipientUId
            }
            aria-label={t("Delete")}
            title={t("Companies.deleteEmail")}
            role="img"
            size="small"
            style={{
              marginRight: 5,
              border: "0px solid #547D66",
              color: "#d02216",
            }}
            onClick={() =>
              handleDeleteRow(
                aLink.companyNotificationRecipientUId,
                aLink.recipient
              )
            }
          >
            <CancelIcon />
          </IconButton>
        </td>
      </tr>
    );
  };

  const handleDeleteRow = (rowId: string, email: string) => {
    dispatch(clearAlertMessage());

    let updatednotificationRecipients = notificationRecipients.filter(
      (link) => link.companyNotificationRecipientUId !== rowId
    );
    setNotificationRecipients(updatednotificationRecipients);

    recipientTable(notificationRecipients);

    // var row = document.getElementById(rowId) as HTMLTableRowElement;
    // row.parentElement?.removeChild(row);

    setSaveButtonStatusTouched(true);

    setTimeout(() => {
      dispatch(
        broadcastAnnouncement(email + " " + t("Companies.emailAddressRemoved"))
      );
    }, 100);

    // var addButton = document.getElementById("newNotificationRecipientTextBox") as HTMLInputElement;
    // if (addButton) {
    //   addButton.focus();
    // }
  };

  const handleAddRow = () => {
    dispatch(clearAlertMessage());
    setSaveButtonStatusTouched(true);
    if (!validateEmailAddress(newEmailAddress)) {
      dispatch(showErrorAlertMessage(t("Companies.invalidEmailAddress")));
      dispatch(broadcastAnnouncement(t("Companies.invalidEmailAddress")));
      window.scrollTo(0, 0);
      return;
    }
    setLinkTableRowCount(linkTableRowCount + 1);

    var aLink = {
      companyNotificationRecipientUId: "TEMP_ID_" + linkTableRowCount,
      companyNotificationId: 0,
      companyNotificationUId: "00000000-0000-0000-0000-000000000000",
      recipientType: 0,
      recipient: newEmailAddress,
    };

    notificationRecipients.push(aLink);

    recipientTable(notificationRecipients);

    // var table = document.getElementById("recipientTable") as HTMLTableElement;

    // var innerHTML = renderToStaticMarkup(recipientRow(aLink, 0));

    // if (table) {
    //   var aRow = table.insertRow(table.rows.length);
    //   aRow.innerHTML = innerHTML;
    //   aRow.id = aLink.companyNotificationRecipientUId;
    // }

    // var btn = document.getElementById(
    //   aLink.companyNotificationRecipientUId
    // ) as HTMLButtonElement;

    // btn.onclick = function () {
    //   handleDeleteRow(aLink.companyNotificationRecipientUId, aLink.recipient);
    // };

    setNewEmailAddress("");

    var addButton = document.getElementById(
      "newNotificationRecipientTextBox"
    ) as HTMLInputElement;
    if (addButton) {
      addButton.focus();
    }

    setTimeout(() => {
      dispatch(
        broadcastAnnouncement(
          newEmailAddress + " " + t("Companies.newEmailAddressAdded")
        )
      );
    }, 100);

    window.scrollTo(0, 0);
  };

  const getDailyRental = async (companyUId) => {
    if (companyUId !== "") {
      await axiosInstance
        .get(`/company/notifications/${companyUId}`)
        .then((response) => {
          appInsights.trackEvent({
            name: "getDailyRental", properties: { CompanyUId : companyUId }
          });
          setdailyRental(response.data);
          setNotificationRecipients(
            response.data.reservationNotification.notificationRecipients
          );

          setActivate(response.data.reservationNotification.activate);
          setSendNotificationToBooker(
            response.data.reservationNotification.sendNotificationToBooker
          );
          setSendNotificationToDriver(
            response.data.reservationNotification.sendNotificationToDriver
          );
        })
        .catch((error) => {
          if (
            error.response != null &&
            error.response.status != null &&
            error.response.status === 400
          ) {
            dispatch(showErrorAlertMessage(t("UnexpectedError")));
            dispatch(broadcastAnnouncement(t("UnexpectedError")));
            console.error("There is an error", error);
          } else console.error("There is an error", error);
        });
    } else {
      //doing nothing here
    }
    setLoading(false);
  };

  const updateDailyRental = async (values) => {
    dispatch(clearAlertMessage());
    setLoading(true);

    await axiosInstance
      .post("/company/notifications/updatenotifications/", values)
      .then((response) => {
        if (response.data.success) {
          dispatch(
            showSuccessAlertMessage(
              t("Companies.updateNotidicationsSuccess")
            )
          );

          dispatch(
            broadcastAnnouncement(
              t("Companies.updateNotidicationsSuccess")
            )
          );

          if (titleRef.current !== null) titleRef.current.focus();

          window.scrollTo(0, 0);

          setSaveButtonStatusTouched(false);
        }
      })
      .catch((err) => {
        dispatch(showErrorAlertMessage(t("UnexpectedError")));
        dispatch(broadcastAnnouncement(t("UnexpectedError")));

        appInsights.trackException({
          exception: err,
          properties: { method: "onSubmit", Component: "dailyRental" },
          severityLevel: SeverityLevel.Error,
        });
      });
    window.scrollTo(0, 0);
    setLoading(false);
  };

  const handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      handleUpdate();
    }
  };

  const handleDeletionButtonMouseOver = () => {
    dispatch(broadcastAnnouncement(""));
    setTimeout(() => {
      dispatch(broadcastAnnouncement(t("DailyRental.deleteEmail")));
    }, 100);
  };
  const handleUpdate = () => {
    var table = document.getElementById("recipientTable") as HTMLTableElement;

    var aLinks: CompanyNotificationRecipientViewModel[] = [];

    for (var i = 0; i < table.rows.length; i++) {
      var originalLink = notificationRecipients.find(
        (e) => e.companyNotificationRecipientUId === table.rows[i].id
      );
      if (
        originalLink &&
        originalLink.companyNotificationRecipientUId &&
        !originalLink.companyNotificationRecipientUId.includes("TEMP_ID_")
      ) {
        aLinks.push(originalLink);
      } else {
        var emailDiv = document.getElementById(
          "Input_" + table.rows[i].id
        ) as HTMLDivElement;
        if (emailDiv) {
          var input_value = emailDiv.innerText;
          var aLink = {
            companyNotificationRecipientUId:
              "00000000-0000-0000-0000-000000000000",
            companyNotificationId: 0,
            companyNotificationUId: "00000000-0000-0000-0000-000000000000",
            recipientType: 0,
            recipient: input_value,
          };
          aLinks.push(aLink);
        }
      }
    }

    dailyRental.reservationNotification.notificationRecipients = aLinks;
    dailyRental.reservationNotification.activate = activate;
    dailyRental.reservationNotification.sendNotificationToBooker =
      sendNotificationToBooker;
    dailyRental.reservationNotification.sendNotificationToDriver =
      sendNotificationToDriver;

    updateDailyRental(dailyRental);
  };

  return (
    <div className={classes.root}>
      <div
        id="companyNotificationsDiv"
        ref={titleRef}
        tabIndex={-1}
        className="visuallyHidden"
      >
        <Typography variant="h1">
          {t("RiskAssessment.companyNotifications")}
        </Typography>
      </div>
      <Typography variant="h1">
        {t("Companies.companyNotifications")}
      </Typography>
      <br />
      <Grid container item spacing={3}>
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress />
        </Backdrop>

        {/* <Grid container item xs={12} direction="column"> */}
        {/* <fieldset>
            <legend>
              <Typography variant="h2">
                {t("Companies.createNotificationRule")}
              </Typography>
            </legend>

            <div
              style={{
                display: "flex",
                justifyContent: "left",
                alignItems: "left",
                flexDirection: "column",
              }}
            > */}
        {/* {recipientTable(notificationRecipients)} */}
        <Grid container item xs={6} direction="column">
          <FormControlLabel
            control={
              <Switch
                id="activateProductNotificationCheckBox"
                checked={activate}
                onChange={(e) => {
                  dispatch(broadcastAnnouncement(" "));
                  dispatch(clearAlertMessage());
                  setActivate(!activate);
                  setSaveButtonStatusTouched(true);
                  if(activate){
                    setSendNotificationToBooker(!activate);
                    setSendNotificationToDriver(!activate);
                  }
                }}
                name="Activate"
                color="primary"
              />
            }
            label={t("Companies.activate")}
          />

{/* <Grid container item xs={6} direction="column"> */}
<br />
<Typography variant="h1">
          {t("Notifications")}
        </Typography>
        <br />
{/* </Grid> */}
          <FormControlLabel
            control={
              <Checkbox
                id="sendNotificationToBookerCheckBox"
                checked={sendNotificationToBooker}
                onChange={(e) => {
                  dispatch(broadcastAnnouncement(" "));
                  dispatch(clearAlertMessage());
                  setSendNotificationToBooker(!sendNotificationToBooker);
                  setSaveButtonStatusTouched(true);
                  if (!sendNotificationToBooker && !activate){
                    setActivate(true);
                  }
                }}
                name="sendNotificationToBooker"
                color="primary"
              />
            }
            label={t("Companies.sendNotificationToBooker")}
          />
          <FormControlLabel
            control={
              <Checkbox
                id="sendNotificationToDriverCheckBox"
                checked={sendNotificationToDriver}
                onChange={(e) => {
                  dispatch(broadcastAnnouncement(" "));
                  dispatch(clearAlertMessage());
                  setSendNotificationToDriver(!sendNotificationToDriver);
                  setSaveButtonStatusTouched(true);
                  if (!sendNotificationToDriver && !activate){
                    setActivate(true);
                  }
                }}
                name="sendNotificationToDriver"
                color="primary"
              />
            }
            label={t("Companies.sendNotificationToDriver")}
          />
        </Grid>
        <Grid item xs={12} md={12} style={{ marginBottom: -20 }}>
          <Typography variant="h2">
            {t("Companies.additionalEmailAddress")}
          </Typography>
        </Grid>
        <Grid item xs={12} md={12} style={{ marginBottom: 0 }}>
          <TextField
            id="newNotificationRecipientTextBox"
            fullWidth={true}
            style={{ width: "50%", minWidth: "110px" }}
            size="medium"
            margin="dense"
            inputProps={{
              "aria-label": t("Companies.emailAddress"),
              "aria-required": true,
            }}
            label={t("Companies.emailAddress")}
            variant="outlined"
            value={newEmailAddress}
            onChange={(e) => setNewEmailAddress(e.target.value)}
            placeholder={t("Companies.emailAddress")}
            error={newEmailAddress !== "" && !newEmailAddress.match(pattern)}
          />
          <Button
            color="primary"
            id="addNewNotificationRecipientButton"
            variant="contained"
            type="button"
            onClick={() => handleAddRow()}
            style={{
              marginTop: "0.8em",
              marginBottom: "0.3em",
              marginLeft: "0.5em",
              textTransform: "none",
            }}
          >
            {t("Companies.addNew")}
          </Button>
        </Grid>
        <Divider
          style={{
            marginTop: 0,
            marginBottom: -15,
            backgroundColor: "black",
            height: "0.8px",
            width: "60%",
          }}
        />

        <Grid item xs={12}>
          {recipientTable(notificationRecipients)}
          {/* <div style={{ width: "80%" }}>
                  <table id="recipientTable" style={{ width: "100%", border:"1" }}>
                    <tbody>
                      {notificationRecipients.map((aLink, index) =>
                        recipientRow(aLink, index)
                      )}
                    </tbody>
                  </table>
                </div> */}
        </Grid>
        {/* </div>
        </fieldset> */}
      </Grid>

      <Grid container justifyContent="flex-end">
        <Button
          classes={{ outlinedPrimary: classes.outlinedButton }}
          id="btnCancel"
          color="primary"
          variant="outlined"
          type="button"
          onClick={() => {
            props.action("");
          }}
          style={{
            marginTop: "0.5em",
            marginLeft: "0.5em",
            textTransform: "none",
          }}
        >
          {t("Companies.btnCancel")}
        </Button>
        &nbsp;
        <Button
          id="btnUpdate"
          color="primary"
          variant="contained"
          disabled={!saveButtonStatusTouched}
          onClick={handleUpdate}
          onMouseEnter={() => handleDeletionButtonMouseOver()}
          onKeyDown={handleKeyPress}
          type="submit"
          style={{ marginTop: "0.5em", textTransform: "none" }}
        >
          {t("Companies.btnSave")}
        </Button>
      </Grid>
    </div>
  );
};

export default Notifications;
