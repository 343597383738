import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface Location {
  Name: string;
  Latitude: string;
  Longitude: string;
  StationIds: object;
  PeopleSoftIds: object;
}
export interface locationState {
  journeyProfileId: string;
  accountId: string;
  startLocation: Location;
  endLocation: Location;
  startDateTime: string;
  endDateTime: string;
}
const initialState: locationState = {
  journeyProfileId: "",
  accountId: "",
  startLocation: {
    Name: "",
    Latitude: "",
    Longitude: "",
    StationIds: {},
    PeopleSoftIds: {},
  },
  endLocation: {
    Name: "",
    Latitude: "",
    Longitude: "",
    StationIds: {},
    PeopleSoftIds: {},
  },
  startDateTime: "",
  endDateTime: "",
};

export const AccountLocationDetailSlice = createSlice({
  name: "accountLocationDetail",
  initialState,
  reducers: {
    updateAccountLocationDetails: (
      state,
      action: PayloadAction<locationState>
    ) => {
      
      state.journeyProfileId = action.payload.journeyProfileId;
      state.accountId = action.payload.accountId;
      state.startLocation = action.payload.startLocation;
      state.endLocation = action.payload.endLocation;
      state.startDateTime = action.payload.startDateTime;
      state.endDateTime = action.payload.endDateTime;
    },
    resetAccountLocationDetails: () => initialState,
  },
});

export const { updateAccountLocationDetails, resetAccountLocationDetails } =
  AccountLocationDetailSlice.actions;

export default AccountLocationDetailSlice.reducer;
