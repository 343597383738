
export interface RiskAssessmentParamViewModel { 
  JourneyProfileUId:string; 
  OrigJourneyUId?:string;  
}
export interface RiskAssessmentViewModel{
  RiskAssessmentUId:string,
  FormTemplateUId : string,
  FormTemplateId : number,
  JourneyProfileUId:string,
  JourneyProfileId: number,
  Valid : boolean,
  Sections :FormSectionViewModel[],
  IsRebook?: boolean
}
export interface FormSectionViewModel{
  FormSectionUId : string,
  FormSectionId: number, 
  Title : string,
  DisplayOrder : Int16Array,
  Questions :   FormQuestionViewModel[]
}
export interface FormQuestionViewModel{
  FormQuestionUId : string,
  FormQuestionId :number,       
  Description : string,  
  DescriptionTranslated : string,     
  Answer : string;      
  ToolTip : SVGStringList,        
  ExternalLink : string,
  DisplayOrder : number,
  Required: boolean,
  QuestionTypeUId: string,
  QuestionTypeId: number,
  Choices: RequiredFormChoicesViewModel

}

export interface RequiredFormChoicesViewModel {
 SelectedValue: string,
  SelectedText: string  ,
  SelectedFormQuestionChoiceId:number
}

export const InitialValue: RiskAssessmentViewModel = {
  RiskAssessmentUId: "",
  FormTemplateUId: "",
  FormTemplateId: 0,
  JourneyProfileUId: "",
  JourneyProfileId: 0,
  Valid: false,
  Sections: [
    {
      FormSectionUId: "",
      FormSectionId: 0,
      Title: "",
      DisplayOrder: 0,
      Questions: [
        {
          FormQuestionUId: "",
          FormQuestionId: 0,
          Description: "",
          Answer: "",
          ToolTip: "",
          ExternalLink: "",
          DisplayOrder: 0,
          Required: false,
          QuestionTypeUId: "",
          QuestionTypeId: 0,
          Choices: {
            SelectedValue: "",
            SelectedText: "",
            SelectedFormQuestionChoiceId: 0,
          } as RequiredFormChoicesViewModel,
        },
        {
          FormQuestionUId: "",
          FormQuestionId: 1,
          Description: "",
          Answer: "",
          ToolTip: "",
          ExternalLink: "",
          DisplayOrder: 1,
          Required: false,
          QuestionTypeUId: "",
          QuestionTypeId: 0,
          Choices: {
            SelectedValue: "",
            SelectedText: "",
            SelectedFormQuestionChoiceId: 0,
          } as RequiredFormChoicesViewModel,
        },
        {
          FormQuestionUId: "",
          FormQuestionId: 2,
          Description: "",
          Answer: "",
          ToolTip: "",
          ExternalLink: "",
          DisplayOrder: 2,
          Required: false,
          QuestionTypeUId: "",
          QuestionTypeId: 0,
          Choices: {
            SelectedValue: "",
            SelectedText: "",
            SelectedFormQuestionChoiceId: 0,
          } as RequiredFormChoicesViewModel,
        },
        {
          FormQuestionUId: "",
          FormQuestionId: 3,
          Description: "",
          Answer: "",
          ToolTip: "",
          ExternalLink: "",
          DisplayOrder: 3,
          Required: false,
          QuestionTypeUId: "",
          QuestionTypeId: 0,
          Choices: {
            SelectedValue: "",
            SelectedText: "",
          } as RequiredFormChoicesViewModel,
        },
      ] as unknown as FormQuestionViewModel[],
    },
  ] as unknown as FormSectionViewModel[],
  IsRebook: false,
};
