import moment from "moment";
import { AvailabilityPeriod } from "../models";
import { minutesBetweenTwoDates } from "./../functions/";

export function mockAvailability(
  startDate: Date,
  endDate: Date,
  minutesPerStep = 15,
  minutesToAppend = 120,
  minutesToPrepend = 120
) {
  const periodStartDate = moment(startDate)
    .subtract(minutesToPrepend, "m")
    .toDate();

  const periodEndDate = moment(endDate).add(minutesToAppend, "m").toDate();

  var periods: Array<AvailabilityPeriod> = [];
  for (
    let i = 0;
    i <= minutesBetweenTwoDates(periodStartDate, periodEndDate);
    i = i + minutesPerStep
  ) {
    periods.push({
      startDateTime: moment(periodStartDate).add(i, "m").toDate(),
      isAvailable: true,
    });
  }
  if (periods[periods.length - 1] && periods[periods.length - 2]) {
    periods[periods.length - 1].isAvailable = false;
    periods[periods.length - 2].isAvailable = false;
    periods[0].isAvailable = false;
  }

  return periods;
}
