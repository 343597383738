import React from "react";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import "../views/general.css";
import { useDispatch } from "react-redux";
import {
  clearAlertMessage,
} from "../components/store/actions/shared/alertActions";
import { useTranslation } from "react-i18next";

const LegalContactUs = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(clearAlertMessage());
    document.title = "Enterprise Rent-A-Car - CONTACT US"
  }
);


  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h5" className="paddingtophalfcm" gutterBottom>
        Need help using ETD?
        </Typography>
        <Typography className="paddingLefthalfcm" variant="body2" gutterBottom>
        For assistance using the Enterprise Travel Direct reservation tool, please contact our <span><b>ETD Helpdesk</b></span> by country.
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h5" gutterBottom>
        ETD and other solutions
        </Typography>
        <Typography className="paddingLefthalfcm" variant="body2" gutterBottom>
        The Right Decision, Every Time. Every organization is different. For some, cost may override all other issues. Others may place more importance on risk factors. To help organisations of all sizes manage employee mobility more effectively, we’ve developed Enterprise Travel Direct (ETD), an intelligent decision tool that helps drivers select the right transport option for every journey. The answers provided by ETD are based on unique decision-making algorithms, developed, tested and agreed in advance.
        </Typography>
        <br />
        <Typography className="paddingLefthalfcm" variant="body2" gutterBottom>
        <span>Are you interested to learn more about the Enterprise Travel Direct reservation tool, or other Business Rental solutions offered by Enterprise Holdings? Please contact your local Business Rental Sales Executive, or contact our</span> <span><b>Business Rental Support Team</b></span> by country.
        </Typography>
        <br />
        <div >
          <TableContainer id="conttactTable">
            <Table aria-label="contact table">
              <TableBody style={{width: "100%"}}>
  {/* United Kingdom */}
                <TableRow>
                <TableCell
                    width="15%"
                    style={{ padding:0, paddingLeft: 20, borderBottom: "none", fontWeight:"bold", border:"0px solid green"}}
                    align="left"
                  >United Kingdom{" "}
                  </TableCell>
                  <TableCell
                  width="15%"
                    style={{ padding: 0, borderBottom: "none" , fontWeight:"bold", border:"0px solid green"}}
                    align="left"
                  >
                    ETD Helpdesk{" "}
                  </TableCell>
                  <TableCell
                  width="15%"
                    style={{ padding: 0, borderBottom: "none", border:"0px solid green" }}
                    align="left"
                  >
                    {" "}
                  </TableCell>
                  <TableCell
                  width="55%"
                    style={{ padding: 0, borderBottom: "none", border:"0px solid green" }}
                    align="left"
                  >
                    {" "}
                  </TableCell>
                </TableRow>
                <TableRow>
                <TableCell
                    width="15%"
                    style={{ padding:0, paddingLeft: 20, borderBottom: "none", fontWeight:"bold", border:"0px solid green"}}
                    align="left"
                  >
                    
                  </TableCell>
                  <TableCell
                  width="15%"
                    style={{ padding: 0, borderBottom: "none" , border:"0px solid green"}}
                    align="left"
                  >
                    Phone: 
                  </TableCell>
                  <TableCell
                  width="15%"
                    style={{ padding: 0, borderBottom: "none", border:"0px solid green" }}
                    align="left"
                  >
                    	0044 800 783 9445
                  </TableCell>
                  <TableCell
                  width="55%"
                    style={{ padding: 0, borderBottom: "none", border:"0px solid green" }}
                    align="left"
                  >
                    {" "}
                  </TableCell>
                </TableRow>
                <TableRow>
                <TableCell
                    width="15%"
                    style={{ padding:0, paddingLeft: 20, borderBottom: "none", fontWeight:"bold", border:"0px solid green"}}
                    align="left"
                  >
                    {" "}
                  </TableCell>
                  <TableCell
                  width="15%"
                    style={{ padding: 0, borderBottom: "none", border:"0px solid green"}}
                    align="left"
                  >
                   Email:
                  </TableCell>
                  <TableCell
                  width="15%"
                    style={{ padding: 0, borderBottom: "none", border:"0px solid green" }}
                    align="left"
                  >
                    <a href="mailto:ApplicationHelpdesk@ehi.com" style={{textDecorationLine:"underline", color:"blue"}}>
                    ApplicationHelpdesk@ehi.com
                    </a>
                  </TableCell>
                  <TableCell
                  width="55%"
                    style={{ padding: 0, borderBottom: "none", border:"0px solid green" }}
                    align="left"
                  >
                    {" "}
                  </TableCell>
                </TableRow>
                <TableRow>
                <TableCell
                    width="15%"
                    style={{ padding:0, paddingLeft: 20, borderBottom: "none", fontWeight:"bold", border:"0px solid green"}}
                    align="left"
                  >
                    {" "}
                  </TableCell>
                  <TableCell
                  width="15%"
                    style={{ padding: 0, borderBottom: "none" , fontWeight:"bold", border:"0px solid green"}}
                    align="left"
                  >
                    Business Rental Support Team{" "}
                  </TableCell>
                  <TableCell
                  width="15%"
                    style={{ padding: 0, borderBottom: "none", border:"0px solid green" }}
                    align="left"
                  >
                    {" "}
                  </TableCell>
                  <TableCell
                  width="55%"
                    style={{ padding: 0, borderBottom: "none", border:"0px solid green" }}
                    align="left"
                  >
                    {" "}
                  </TableCell>
                </TableRow>
                <TableRow>
                <TableCell
                    width="15%"
                    style={{ padding:0, paddingLeft: 20, borderBottom: "none", fontWeight:"bold", border:"0px solid green"}}
                    align="left"
                  >
                    
                  </TableCell>
                  <TableCell
                  width="15%"
                    style={{ padding: 0, borderBottom: "none" , border:"0px solid green"}}
                    align="left"
                  >
                    Phone: 
                  </TableCell>
                  <TableCell
                  width="15%"
                    style={{ padding: 0, borderBottom: "none", border:"0px solid green" }}
                    align="left"
                  >
                    0044​ 344 335 0283
                  </TableCell>
                  <TableCell
                  width="55%"
                    style={{ padding: 0, borderBottom: "none", border:"0px solid green" }}
                    align="left"
                  >
                    {" "}
                  </TableCell>
                </TableRow>
                <TableRow>
                <TableCell
                    width="15%"
                    style={{ padding:0, paddingLeft: 20, borderBottom: "none", fontWeight:"bold", border:"0px solid green"}}
                    align="left"
                  >
                    {" "}
                  </TableCell>
                  <TableCell
                  width="15%"
                    style={{ padding: 0, borderBottom: "none", border:"0px solid green"}}
                    align="left"
                  >
                   Email:
                  </TableCell>
                  <TableCell
                  width="15%"
                    style={{ padding: 0, borderBottom: "none", border:"0px solid green" }}
                    align="left"
                  >
                    <a href="mailto:BusinessRentalSupport@ehi.com" style={{textDecorationLine:"underline", color:"blue"}}>
                    BusinessRentalSupport@ehi.com
                    </a>
                  </TableCell>
                  <TableCell
                  width="55%"
                    style={{ padding: 0, borderBottom: "none", border:"0px solid green" }}
                    align="left"
                  >
                    {" "} 
                  </TableCell>
                </TableRow>
                <TableRow style={{height: 10}}> <TableCell style={{ padding: 0, borderBottom: "none" , border:"0px solid green"}}></TableCell><TableCell style={{ padding: 0, borderBottom: "none" , border:"0px solid green"}}></TableCell><TableCell style={{ padding: 0, borderBottom: "none" , border:"0px solid green"}}></TableCell><TableCell style={{ padding: 0, borderBottom: "none" , border:"0px solid green"}}></TableCell></TableRow> 
  {/* Republic of Ireland */}
  <TableRow>
                <TableCell
                    width="15%"
                    style={{ padding:0, paddingLeft: 20, borderBottom: "none", fontWeight:"bold", border:"0px solid green"}}
                    align="left"
                  >
                    Republic of Ireland{" "}
                  </TableCell>
                  <TableCell
                  width="15%"
                    style={{ padding: 0, borderBottom: "none" , fontWeight:"bold", border:"0px solid green"}}
                    align="left"
                  >
                    ETD Helpdesk{" "}
                  </TableCell>
                  <TableCell
                  width="15%"
                    style={{ padding: 0, borderBottom: "none", border:"0px solid green" }}
                    align="left"
                  >
                    {" "}
                  </TableCell>
                  <TableCell
                  width="55%"
                    style={{ padding: 0, borderBottom: "none", border:"0px solid green" }}
                    align="left"
                  >
                    {" "}
                  </TableCell>
                </TableRow>
                <TableRow>
                <TableCell
                    width="15%"
                    style={{ padding:0, paddingLeft: 20, borderBottom: "none", fontWeight:"bold", border:"0px solid green"}}
                    align="left"
                  >
                    
                  </TableCell>
                  <TableCell
                  width="15%"
                    style={{ padding: 0, borderBottom: "none" , border:"0px solid green"}}
                    align="left"
                  >
                    Phone: 
                  </TableCell>
                  <TableCell
                  width="15%"
                    style={{ padding: 0, borderBottom: "none", border:"0px solid green" }}
                    align="left"
                  >
                    	0044 800 783 9445
                  </TableCell>
                  <TableCell
                  width="55%"
                    style={{ padding: 0, borderBottom: "none", border:"0px solid green" }}
                    align="left"
                  >
                    {" "}
                  </TableCell>
                </TableRow>
                <TableRow>
                <TableCell
                    width="15%"
                    style={{ padding:0, paddingLeft: 20, borderBottom: "none", fontWeight:"bold", border:"0px solid green"}}
                    align="left"
                  >
                    {" "}
                  </TableCell>
                  <TableCell
                  width="15%"
                    style={{ padding: 0, borderBottom: "none", border:"0px solid green"}}
                    align="left"
                  >
                   Email:
                  </TableCell>
                  <TableCell
                  width="15%"
                    style={{ padding: 0, borderBottom: "none", border:"0px solid green" }}
                    align="left"
                  >
                    <a href="mailto:ApplicationHelpdesk@ehi.com" style={{textDecorationLine:"underline", color:"blue"}}>
                    ApplicationHelpdesk@ehi.com
                    </a>
                  </TableCell>
                  <TableCell
                  width="55%"
                    style={{ padding: 0, borderBottom: "none", border:"0px solid green" }}
                    align="left"
                  >
                    {" "}
                  </TableCell>
                </TableRow>
                <TableRow>
                <TableCell
                    width="15%"
                    style={{ padding:0, paddingLeft: 20, borderBottom: "none", fontWeight:"bold", border:"0px solid green"}}
                    align="left"
                  >
                    {" "}
                  </TableCell>
                  <TableCell
                  width="15%"
                    style={{ padding: 0, borderBottom: "none" , fontWeight:"bold", border:"0px solid green"}}
                    align="left"
                  >
                    Business Rental Support Team{" "}
                  </TableCell>
                  <TableCell
                  width="15%"
                    style={{ padding: 0, borderBottom: "none", border:"0px solid green" }}
                    align="left"
                  >
                    {" "}
                  </TableCell>
                  <TableCell
                  width="55%"
                    style={{ padding: 0, borderBottom: "none", border:"0px solid green" }}
                    align="left"
                  >
                    {" "}
                  </TableCell>
                </TableRow>
                <TableRow>
                <TableCell
                    width="15%"
                    style={{ padding:0, paddingLeft: 20, borderBottom: "none", fontWeight:"bold", border:"0px solid green"}}
                    align="left"
                  >
                    
                  </TableCell>
                  <TableCell
                  width="15%"
                    style={{ padding: 0, borderBottom: "none" , border:"0px solid green"}}
                    align="left"
                  >
                    Phone: 
                  </TableCell>
                  <TableCell
                  width="15%"
                    style={{ padding: 0, borderBottom: "none", border:"0px solid green" }}
                    align="left"
                  >
                    0044​ 344 335 0283
                  </TableCell>
                  <TableCell
                  width="55%"
                    style={{ padding: 0, borderBottom: "none", border:"0px solid green" }}
                    align="left"
                  >
                    {" "}
                  </TableCell>
                </TableRow>
                <TableRow>
                <TableCell
                    width="15%"
                    style={{ padding:0, paddingLeft: 20, borderBottom: "none", fontWeight:"bold", border:"0px solid green"}}
                    align="left"
                  >
                    {" "}
                  </TableCell>
                  <TableCell
                  width="15%"
                    style={{ padding: 0, borderBottom: "none", border:"0px solid green"}}
                    align="left"
                  >
                   Email:
                  </TableCell>
                  <TableCell
                  width="15%"
                    style={{ padding: 0, borderBottom: "none", border:"0px solid green" }}
                    align="left"
                  >
                    <a href="mailto:BusinessRentalSupport@ehi.com" style={{textDecorationLine:"underline", color:"blue"}}>
                    BusinessRentalSupport@ehi.com
                    </a>
                  </TableCell>
                  <TableCell
                  width="55%"
                    style={{ padding: 0, borderBottom: "none", border:"0px solid green" }}
                    align="left"
                  >
                    {" "}
                  </TableCell>
                </TableRow>
                <TableRow style={{height: 10}}> <TableCell style={{ padding: 0, borderBottom: "none" , border:"0px solid green"}}></TableCell><TableCell style={{ padding: 0, borderBottom: "none" , border:"0px solid green"}}></TableCell><TableCell style={{ padding: 0, borderBottom: "none" , border:"0px solid green"}}></TableCell><TableCell style={{ padding: 0, borderBottom: "none" , border:"0px solid green"}}></TableCell></TableRow> 
 
 {/* France */}
 <TableRow>
                <TableCell
                    width="15%"
                    style={{ padding:0, paddingLeft: 20, borderBottom: "none", fontWeight:"bold", border:"0px solid green"}}
                    align="left"
                  >
                    France{" "}
                  </TableCell>
                  <TableCell
                  width="15%"
                    style={{ padding: 0, borderBottom: "none" , fontWeight:"bold", border:"0px solid green"}}
                    align="left"
                  >
                    ETD Helpdesk{" "}
                  </TableCell>
                  <TableCell
                  width="15%"
                    style={{ padding: 0, borderBottom: "none", border:"0px solid green" }}
                    align="left"
                  >
                    {" "}
                  </TableCell>
                  <TableCell
                  width="55%"
                    style={{ padding: 0, borderBottom: "none", border:"0px solid green" }}
                    align="left"
                  >
                    {" "}
                  </TableCell>
                </TableRow>
                <TableRow>
                <TableCell
                    width="15%"
                    style={{ padding:0, paddingLeft: 20, borderBottom: "none", fontWeight:"bold", border:"0px solid green"}}
                    align="left"
                  >
                    
                  </TableCell>
                  <TableCell
                  width="15%"
                    style={{ padding: 0, borderBottom: "none" , border:"0px solid green"}}
                    align="left"
                  >
                    Phone: 
                  </TableCell>
                  <TableCell
                  width="15%"
                    style={{ padding: 0, borderBottom: "none", border:"0px solid green" }}
                    align="left"
                  >
                    	0033 801 840 954
                  </TableCell>
                  <TableCell
                  width="55%"
                    style={{ padding: 0, borderBottom: "none", border:"0px solid green" }}
                    align="left"
                  >
                    {" "}
                  </TableCell>
                </TableRow>
                <TableRow>
                <TableCell
                    width="15%"
                    style={{ padding:0, paddingLeft: 20, borderBottom: "none", fontWeight:"bold", border:"0px solid green"}}
                    align="left"
                  >
                    {" "}
                  </TableCell>
                  <TableCell
                  width="15%"
                    style={{ padding: 0, borderBottom: "none", border:"0px solid green"}}
                    align="left"
                  >
                   Email:
                  </TableCell>
                  <TableCell
                  width="15%"
                    style={{ padding: 0, borderBottom: "none", border:"0px solid green" }}
                    align="left"
                  >
                    <a href="mailto:ApplicationHelpdesk@ehi.com" style={{textDecorationLine:"underline", color:"blue"}}>
                    ApplicationHelpdesk@ehi.com
                    </a>
                  </TableCell>
                  <TableCell
                  width="55%"
                    style={{ padding: 0, borderBottom: "none", border:"0px solid green" }}
                    align="left"
                  >
                    {" "}
                  </TableCell>
                </TableRow>
                <TableRow>
                <TableCell
                    width="15%"
                    style={{ padding:0, paddingLeft: 20, borderBottom: "none", fontWeight:"bold", border:"0px solid green"}}
                    align="left"
                  >
                    {" "}
                  </TableCell>
                  <TableCell
                  width="15%"
                    style={{ padding: 0, borderBottom: "none" , fontWeight:"bold", border:"0px solid green"}}
                    align="left"
                  >
                    Business Rental Support Team{" "}
                  </TableCell>
                  <TableCell
                  width="15%"
                    style={{ padding: 0, borderBottom: "none", border:"0px solid green" }}
                    align="left"
                  >
                    {" "}
                  </TableCell>
                  <TableCell
                  width="55%"
                    style={{ padding: 0, borderBottom: "none", border:"0px solid green" }}
                    align="left"
                  >
                    {" "}
                  </TableCell>
                </TableRow>
                <TableRow>
                <TableCell
                    width="15%"
                    style={{ padding:0, paddingLeft: 20, borderBottom: "none", fontWeight:"bold", border:"0px solid green"}}
                    align="left"
                  >
                    
                  </TableCell>
                  <TableCell
                  width="15%"
                    style={{ padding: 0, borderBottom: "none" , border:"0px solid green"}}
                    align="left"
                  >
                    Phone: 
                  </TableCell>
                  <TableCell
                  width="15%"
                    style={{ padding: 0, borderBottom: "none", border:"0px solid green" }}
                    align="left"
                  >
                    0044​ 344 335 0283
                  </TableCell>
                  <TableCell
                  width="55%"
                    style={{ padding: 0, borderBottom: "none", border:"0px solid green" }}
                    align="left"
                  >
                    {" "}
                  </TableCell>
                </TableRow>
                <TableRow>
                <TableCell
                    width="15%"
                    style={{ padding:0, paddingLeft: 20, borderBottom: "none", fontWeight:"bold", border:"0px solid green"}}
                    align="left"
                  >
                    {" "}
                  </TableCell>
                  <TableCell
                  width="15%"
                    style={{ padding: 0, borderBottom: "none", border:"0px solid green"}}
                    align="left"
                  >
                   Email:
                  </TableCell>
                  <TableCell
                  width="15%"
                    style={{ padding: 0, borderBottom: "none", border:"0px solid green" }}
                    align="left"
                  >
                    <a href="mailto:BusinessRentalSupport@ehi.com" style={{textDecorationLine:"underline", color:"blue"}}>
                    BusinessRentalSupport@ehi.com
                    </a>
                  </TableCell>
                  <TableCell
                  width="55%"
                    style={{ padding: 0, borderBottom: "none", border:"0px solid green" }}
                    align="left"
                  >
                    {" "}
                  </TableCell>
                </TableRow>
                <TableRow style={{height: 10}}> <TableCell style={{ padding: 0, borderBottom: "none" , border:"0px solid green"}}></TableCell><TableCell style={{ padding: 0, borderBottom: "none" , border:"0px solid green"}}></TableCell><TableCell style={{ padding: 0, borderBottom: "none" , border:"0px solid green"}}></TableCell><TableCell style={{ padding: 0, borderBottom: "none" , border:"0px solid green"}}></TableCell></TableRow> 
 {/* Germany */}
            <TableRow>
                <TableCell
                    width="15%"
                    style={{ padding:0, paddingLeft: 20, borderBottom: "none", fontWeight:"bold", border:"0px solid green"}}
                    align="left"
                  >
                    Germany{" "}
                  </TableCell>
                  <TableCell
                  width="15%"
                    style={{ padding: 0, borderBottom: "none" , fontWeight:"bold", border:"0px solid green"}}
                    align="left"
                  >
                    ETD Helpdesk{" "}
                  </TableCell>
                  <TableCell
                  width="15%"
                    style={{ padding: 0, borderBottom: "none", border:"0px solid green" }}
                    align="left"
                  >
                    {" "}
                  </TableCell>
                  <TableCell
                  width="55%"
                    style={{ padding: 0, borderBottom: "none", border:"0px solid green" }}
                    align="left"
                  >
                    {" "}
                  </TableCell>
                </TableRow>
                <TableRow>
                <TableCell
                    width="15%"
                    style={{ padding:0, paddingLeft: 20, borderBottom: "none", fontWeight:"bold", border:"0px solid green"}}
                    align="left"
                  >
                    
                  </TableCell>
                  <TableCell
                  width="15%"
                    style={{ padding: 0, borderBottom: "none" , border:"0px solid green"}}
                    align="left"
                  >
                    Phone: 
                  </TableCell>
                  <TableCell
                  width="15%"
                    style={{ padding: 0, borderBottom: "none", border:"0px solid green" }}
                    align="left"
                  >
                    		​0049 800 00 02 618
                  </TableCell>
                  <TableCell
                  width="55%"
                    style={{ padding: 0, borderBottom: "none", border:"0px solid green" }}
                    align="left"
                  >
                    {" "}
                  </TableCell>
                </TableRow>
                <TableRow>
                <TableCell
                    width="15%"
                    style={{ padding:0, paddingLeft: 20, borderBottom: "none", fontWeight:"bold", border:"0px solid green"}}
                    align="left"
                  >
                    {" "}
                  </TableCell>
                  <TableCell
                  width="15%"
                    style={{ padding: 0, borderBottom: "none", border:"0px solid green"}}
                    align="left"
                  >
                   Email:
                  </TableCell>
                  <TableCell
                  width="15%"
                    style={{ padding: 0, borderBottom: "none", border:"0px solid green" }}
                    align="left"
                  >
                    <a href="mailto:	​Germany.ETD@ehi.com" style={{textDecorationLine:"underline", color:"blue"}}>
                    ​Germany.ETD@ehi.com
                    </a>
                  </TableCell>
                  <TableCell
                  width="55%"
                    style={{ padding: 0, borderBottom: "none", border:"0px solid green" }}
                    align="left"
                  >
                    {" "}
                  </TableCell>
                </TableRow>
                <TableRow>
                <TableCell
                    width="15%"
                    style={{ padding:0, paddingLeft: 20, borderBottom: "none", fontWeight:"bold", border:"0px solid green"}}
                    align="left"
                  >
                    {" "}
                  </TableCell>
                  <TableCell
                  width="15%"
                    style={{ padding: 0, borderBottom: "none" , fontWeight:"bold", border:"0px solid green"}}
                    align="left"
                  >
                    Business Rental Support Team{" "}
                  </TableCell>
                  <TableCell
                  width="15%"
                    style={{ padding: 0, borderBottom: "none", border:"0px solid green" }}
                    align="left"
                  >
                    {" "}
                  </TableCell>
                  <TableCell
                  width="55%"
                    style={{ padding: 0, borderBottom: "none", border:"0px solid green" }}
                    align="left"
                  >
                    {" "}
                  </TableCell>
                </TableRow>
                <TableRow>
                <TableCell
                    width="15%"
                    style={{ padding:0, paddingLeft: 20, borderBottom: "none", fontWeight:"bold", border:"0px solid green"}}
                    align="left"
                  >
                    
                  </TableCell>
                  <TableCell
                  width="15%"
                    style={{ padding: 0, borderBottom: "none" , border:"0px solid green"}}
                    align="left"
                  >
                    Phone: 
                  </TableCell>
                  <TableCell
                  width="15%"
                    style={{ padding: 0, borderBottom: "none", border:"0px solid green" }}
                    align="left"
                  >
                    0049 6196 7698 6285
                  </TableCell>
                  <TableCell
                  width="55%"
                    style={{ padding: 0, borderBottom: "none", border:"0px solid green" }}
                    align="left"
                  >
                    {" "}
                  </TableCell>
                </TableRow>
                <TableRow>
                <TableCell
                    width="15%"
                    style={{ padding:0, paddingLeft: 20, borderBottom: "none", fontWeight:"bold", border:"0px solid green"}}
                    align="left"
                  >
                    {" "}
                  </TableCell>
                  <TableCell
                  width="15%"
                    style={{ padding: 0, borderBottom: "none", border:"0px solid green"}}
                    align="left"
                  >
                   Email:
                  </TableCell>
                  <TableCell
                  width="15%"
                    style={{ padding: 0, borderBottom: "none", border:"0px solid green" }}
                    align="left"
                  >
                    <a href="mailto:	​team@ehisales.com" style={{textDecorationLine:"underline", color:"blue"}}>
                    ​team@ehisales.com
                    </a>
                  </TableCell>
                  <TableCell
                  width="55%"
                    style={{ padding: 0, borderBottom: "none", border:"0px solid green" }}
                    align="left"
                  >
                    {" "}
                  </TableCell>
                </TableRow>    
                <TableRow style={{height: 10}}> <TableCell style={{ padding: 0, borderBottom: "none" , border:"0px solid green"}}></TableCell><TableCell style={{ padding: 0, borderBottom: "none" , border:"0px solid green"}}></TableCell><TableCell style={{ padding: 0, borderBottom: "none" , border:"0px solid green"}}></TableCell><TableCell style={{ padding: 0, borderBottom: "none" , border:"0px solid green"}}></TableCell></TableRow> 
{/* Spain */}
<TableRow>
                <TableCell
                    width="15%"
                    style={{ padding:0, paddingLeft: 20, borderBottom: "none", fontWeight:"bold", border:"0px solid green"}}
                    align="left"
                  >
                    Spain{" "}
                  </TableCell>
                  <TableCell
                  width="15%"
                    style={{ padding: 0, borderBottom: "none" , fontWeight:"bold", border:"0px solid green"}}
                    align="left"
                  >
                    ETD Helpdesk{" "}
                  </TableCell>
                  <TableCell
                  width="15%"
                    style={{ padding: 0, borderBottom: "none", border:"0px solid green" }}
                    align="left"
                  >
                    {" "}
                  </TableCell>
                  <TableCell
                  width="55%"
                    style={{ padding: 0, borderBottom: "none", border:"0px solid green" }}
                    align="left"
                  >
                    {" "}
                  </TableCell>
                </TableRow>
                <TableRow>
                <TableCell
                    width="15%"
                    style={{ padding:0, paddingLeft: 20, borderBottom: "none", fontWeight:"bold", border:"0px solid green"}}
                    align="left"
                  >
                    
                  </TableCell>
                  <TableCell
                  width="15%"
                    style={{ padding: 0, borderBottom: "none" , border:"0px solid green"}}
                    align="left"
                  >
                    Phone: 
                  </TableCell>
                  <TableCell
                  width="15%"
                    style={{ padding: 0, borderBottom: "none", border:"0px solid green" }}
                    align="left"
                  >
                    		0034 900 43 1593
                  </TableCell>
                  <TableCell
                  width="55%"
                    style={{ padding: 0, borderBottom: "none", border:"0px solid green" }}
                    align="left"
                  >
                    {" "}
                  </TableCell>
                </TableRow>
                <TableRow>
                <TableCell
                    width="15%"
                    style={{ padding:0, paddingLeft: 20, borderBottom: "none", fontWeight:"bold", border:"0px solid green"}}
                    align="left"
                  >
                    {" "}
                  </TableCell>
                  <TableCell
                  width="15%"
                    style={{ padding: 0, borderBottom: "none", border:"0px solid green"}}
                    align="left"
                  >
                   Email:
                  </TableCell>
                  <TableCell
                  width="15%"
                    style={{ padding: 0, borderBottom: "none", border:"0px solid green" }}
                    align="left"
                  >
                    <a href="mailto:​ApplicationHelpdesk@ehi.com" style={{textDecorationLine:"underline", color:"blue"}}>
                    ​ApplicationHelpdesk@ehi.com
                    </a>
                  </TableCell>
                  <TableCell
                  width="55%"
                    style={{ padding: 0, borderBottom: "none", border:"0px solid green" }}
                    align="left"
                  >
                    {" "}
                  </TableCell>
                </TableRow>
                <TableRow>
                <TableCell
                    width="15%"
                    style={{ padding:0, paddingLeft: 20, borderBottom: "none", fontWeight:"bold", border:"0px solid green"}}
                    align="left"
                  >
                    {" "}
                  </TableCell>
                  <TableCell
                  width="15%"
                    style={{ padding: 0, borderBottom: "none" , fontWeight:"bold", border:"0px solid green"}}
                    align="left"
                  >
                    Business Rental Support Team{" "}
                  </TableCell>
                  <TableCell
                  width="15%"
                    style={{ padding: 0, borderBottom: "none", border:"0px solid green" }}
                    align="left"
                  >
                    {" "}
                  </TableCell>
                  <TableCell
                  width="55%"
                    style={{ padding: 0, borderBottom: "none", border:"0px solid green" }}
                    align="left"
                  >
                    {" "}
                  </TableCell>
                </TableRow>
                <TableRow>
                <TableCell
                    width="15%"
                    style={{ padding:0, paddingLeft: 20, borderBottom: "none", fontWeight:"bold", border:"0px solid green"}}
                    align="left"
                  >
                    
                  </TableCell>
                  <TableCell
                  width="15%"
                    style={{ padding: 0, borderBottom: "none" , border:"0px solid green"}}
                    align="left"
                  >
                    Phone: 
                  </TableCell>
                  <TableCell
                  width="15%"
                    style={{ padding: 0, borderBottom: "none", border:"0px solid green" }}
                    align="left"
                  >
                    	0044​ 344 335 0283
                  </TableCell>
                  <TableCell
                  width="55%"
                    style={{ padding: 0, borderBottom: "none", border:"0px solid green" }}
                    align="left"
                  >
                    {" "}
                  </TableCell>
                </TableRow>
                <TableRow>
                <TableCell
                    width="15%"
                    style={{ padding:0, paddingLeft: 20, borderBottom: "none", fontWeight:"bold", border:"0px solid green"}}
                    align="left"
                  >
                    {" "}
                  </TableCell>
                  <TableCell
                  width="15%"
                    style={{ padding: 0, borderBottom: "none", border:"0px solid green"}}
                    align="left"
                  >
                   Email:
                  </TableCell>
                  <TableCell
                  width="15%"
                    style={{ padding: 0, borderBottom: "none", border:"0px solid green" }}
                    align="left"
                  >
                    <a href="mailto:	​	​BusinessRentalSupport@ehi.com" style={{textDecorationLine:"underline", color:"blue"}}>
                    ​BusinessRentalSupport@ehi.com
                    </a>
                  </TableCell>
                  <TableCell
                  width="55%"
                    style={{ padding: 0, borderBottom: "none", border:"0px solid green" }}
                    align="left"
                  >
                    {" "}
                  </TableCell>
                </TableRow>        
                <TableRow style={{height: 10}}> <TableCell style={{ padding: 0, borderBottom: "none" , border:"0px solid green"}}></TableCell><TableCell style={{ padding: 0, borderBottom: "none" , border:"0px solid green"}}></TableCell><TableCell style={{ padding: 0, borderBottom: "none" , border:"0px solid green"}}></TableCell><TableCell style={{ padding: 0, borderBottom: "none" , border:"0px solid green"}}></TableCell></TableRow> 
 {/* United States */}
<TableRow>
                <TableCell
                    width="15%"
                    style={{ padding:0, paddingLeft: 20, borderBottom: "none", fontWeight:"bold", border:"0px solid green"}}
                    align="left"
                  >
                    United States{" "}
                  </TableCell>
                  <TableCell
                  width="15%"
                    style={{ padding: 0, borderBottom: "none" , fontWeight:"bold", border:"0px solid green"}}
                    align="left"
                  >
                    ETD Helpdesk{" "}
                  </TableCell>
                  <TableCell
                  width="15%"
                    style={{ padding: 0, borderBottom: "none", border:"0px solid green" }}
                    align="left"
                  >
                    {" "}
                  </TableCell>
                  <TableCell
                  width="55%"
                    style={{ padding: 0, borderBottom: "none", border:"0px solid green" }}
                    align="left"
                  >
                    {" "}
                  </TableCell>
                </TableRow>
                <TableRow>
                <TableCell
                    width="15%"
                    style={{ padding:0, paddingLeft: 20, borderBottom: "none", fontWeight:"bold", border:"0px solid green"}}
                    align="left"
                  >
                    
                  </TableCell>
                  <TableCell
                  width="15%"
                    style={{ padding: 0, borderBottom: "none" , border:"0px solid green"}}
                    align="left"
                  >
                    Phone: 
                  </TableCell>
                  <TableCell
                  width="15%"
                    style={{ padding: 0, borderBottom: "none", border:"0px solid green" }}
                    align="left"
                  >
                    		1-855-573-0012
                  </TableCell>
                  <TableCell
                  width="55%"
                    style={{ padding: 0, borderBottom: "none", border:"0px solid green" }}
                    align="left"
                  >
                    {" "}
                  </TableCell>
                </TableRow>
                <TableRow>
                <TableCell
                    width="15%"
                    style={{ padding:0, paddingLeft: 20, borderBottom: "none", fontWeight:"bold", border:"0px solid green"}}
                    align="left"
                  >
                    {" "}
                  </TableCell>
                  <TableCell
                  width="15%"
                    style={{ padding: 0, borderBottom: "none", border:"0px solid green"}}
                    align="left"
                  >
                   Email:
                  </TableCell>
                  <TableCell
                  width="15%"
                    style={{ padding: 0, borderBottom: "none", border:"0px solid green" }}
                    align="left"
                  >
                    <a href="mailto:	etdsupport@ehi.com" style={{textDecorationLine:"underline", color:"blue"}}>
                    etdsupport@ehi.com
                    </a>
                  </TableCell>
                  <TableCell
                  width="55%"
                    style={{ padding: 0, borderBottom: "none", border:"0px solid green" }}
                    align="left"
                  >
                    {" "}
                  </TableCell>
                </TableRow>
                <TableRow>
                <TableCell
                    width="15%"
                    style={{ padding:0, paddingLeft: 20, borderBottom: "none", fontWeight:"bold", border:"0px solid green"}}
                    align="left"
                  >
                    {" "}
                  </TableCell>
                  <TableCell
                  width="15%"
                    style={{ padding: 0, borderBottom: "none" , fontWeight:"bold", border:"0px solid green"}}
                    align="left"
                  >
                    Business Rental Support Team{" "}
                  </TableCell>
                  <TableCell
                  width="15%"
                    style={{ padding: 0, borderBottom: "none", border:"0px solid green" }}
                    align="left"
                  >
                    {" "}
                  </TableCell>
                  <TableCell
                  width="55%"
                    style={{ padding: 0, borderBottom: "none", border:"0px solid green" }}
                    align="left"
                  >
                    {" "}
                  </TableCell>
                </TableRow>
                <TableRow>
                <TableCell
                    width="15%"
                    style={{ padding:0, paddingLeft: 20, borderBottom: "none", fontWeight:"bold", border:"0px solid green"}}
                    align="left"
                  >
                    
                  </TableCell>
                  <TableCell
                  width="15%"
                    style={{ padding: 0, borderBottom: "none" , border:"0px solid green"}}
                    align="left"
                  >
                    Phone: 
                  </TableCell>
                  <TableCell
                  width="15%"
                    style={{ padding: 0, borderBottom: "none", border:"0px solid green" }}
                    align="left"
                  >
                    		1-855-573-0012
                  </TableCell>
                  <TableCell
                  width="55%"
                    style={{ padding: 0, borderBottom: "none", border:"0px solid green" }}
                    align="left"
                  >
                    {" "}
                  </TableCell>
                </TableRow>
                <TableRow>
                <TableCell
                    width="15%"
                    style={{ padding:0, paddingLeft: 20, borderBottom: "none", fontWeight:"bold", border:"0px solid green"}}
                    align="left"
                  >
                    {" "}
                  </TableCell>
                  <TableCell
                  width="15%"
                    style={{ padding: 0, borderBottom: "none", border:"0px solid green"}}
                    align="left"
                  >
                   Email:
                  </TableCell>
                  <TableCell
                  width="15%"
                    style={{ padding: 0, borderBottom: "none", border:"0px solid green" }}
                    align="left"
                  >
                    <a href="mailto:etdsupport@ehi.com" style={{textDecorationLine:"underline", color:"blue"}}>
                    etdsupport@ehi.com
                    </a>
                  </TableCell>
                  <TableCell
                  width="55%"
                    style={{ padding: 0, borderBottom: "none", border:"0px solid green" }}
                    align="left"
                  >
                    {" "}
                  </TableCell>
                </TableRow>        
                <TableRow style={{height: 10}}> <TableCell style={{ padding: 0, borderBottom: "none" , border:"0px solid green"}}></TableCell><TableCell style={{ padding: 0, borderBottom: "none" , border:"0px solid green"}}></TableCell><TableCell style={{ padding: 0, borderBottom: "none" , border:"0px solid green"}}></TableCell><TableCell style={{ padding: 0, borderBottom: "none" , border:"0px solid green"}}></TableCell></TableRow>               
  {/* Canada */}
<TableRow>
                <TableCell
                    width="15%"
                    style={{ padding:0, paddingLeft: 20, borderBottom: "none", fontWeight:"bold", border:"0px solid green"}}
                    align="left"
                  >
                    Canada{" "}
                  </TableCell>
                  <TableCell
                  width="15%"
                    style={{ padding: 0, borderBottom: "none" , fontWeight:"bold", border:"0px solid green"}}
                    align="left"
                  >
                    ETD Helpdesk{" "}
                  </TableCell>
                  <TableCell
                  width="15%"
                    style={{ padding: 0, borderBottom: "none", border:"0px solid green" }}
                    align="left"
                  >
                    {" "}
                  </TableCell>
                  <TableCell
                  width="55%"
                    style={{ padding: 0, borderBottom: "none", border:"0px solid green" }}
                    align="left"
                  >
                    {" "}
                  </TableCell>
                </TableRow>
                <TableRow>
                <TableCell
                    width="15%"
                    style={{ padding:0, paddingLeft: 20, borderBottom: "none", fontWeight:"bold", border:"0px solid green"}}
                    align="left"
                  >
                    
                  </TableCell>
                  <TableCell
                  width="15%"
                    style={{ padding: 0, borderBottom: "none" , border:"0px solid green"}}
                    align="left"
                  >
                    Phone: 
                  </TableCell>
                  <TableCell
                  width="15%"
                    style={{ padding: 0, borderBottom: "none", border:"0px solid green" }}
                    align="left"
                  >
                    		1-855-573-0012
                  </TableCell>
                  <TableCell
                  width="55%"
                    style={{ padding: 0, borderBottom: "none", border:"0px solid green" }}
                    align="left"
                  >
                    {" "}
                  </TableCell>
                </TableRow>
                <TableRow>
                <TableCell
                    width="15%"
                    style={{ padding:0, paddingLeft: 20, borderBottom: "none", fontWeight:"bold", border:"0px solid green"}}
                    align="left"
                  >
                    {" "}
                  </TableCell>
                  <TableCell
                  width="15%"
                    style={{ padding: 0, borderBottom: "none", border:"0px solid green"}}
                    align="left"
                  >
                   Email:
                  </TableCell>
                  <TableCell
                  width="15%"
                    style={{ padding: 0, borderBottom: "none", border:"0px solid green" }}
                    align="left"
                  >
                    <a href="mailto:	etdsupport@ehi.com" style={{textDecorationLine:"underline", color:"blue"}}>
                    etdsupport@ehi.com
                    </a>
                  </TableCell>
                  <TableCell
                  width="55%"
                    style={{ padding: 0, borderBottom: "none", border:"0px solid green" }}
                    align="left"
                  >
                    {" "}
                  </TableCell>
                </TableRow>
                <TableRow>
                <TableCell
                    width="15%"
                    style={{ padding:0, paddingLeft: 20, borderBottom: "none", fontWeight:"bold", border:"0px solid green"}}
                    align="left"
                  >
                    {" "}
                  </TableCell>
                  <TableCell
                  width="15%"
                    style={{ padding: 0, borderBottom: "none" , fontWeight:"bold", border:"0px solid green"}}
                    align="left"
                  >
                    Business Rental Support Team{" "}
                  </TableCell>
                  <TableCell
                  width="15%"
                    style={{ padding: 0, borderBottom: "none", border:"0px solid green" }}
                    align="left"
                  >
                    {" "}
                  </TableCell>
                  <TableCell
                  width="55%"
                    style={{ padding: 0, borderBottom: "none", border:"0px solid green" }}
                    align="left"
                  >
                    {" "}
                  </TableCell>
                </TableRow>
                <TableRow>
                <TableCell
                    width="15%"
                    style={{ padding:0, paddingLeft: 20, borderBottom: "none", fontWeight:"bold", border:"0px solid green"}}
                    align="left"
                  >
                    
                  </TableCell>
                  <TableCell
                  width="15%"
                    style={{ padding: 0, borderBottom: "none" , border:"0px solid green"}}
                    align="left"
                  >
                    Phone: 
                  </TableCell>
                  <TableCell
                  width="15%"
                    style={{ padding: 0, borderBottom: "none", border:"0px solid green" }}
                    align="left"
                  >
                    		1-855-573-0012
                  </TableCell>
                  <TableCell
                  width="55%"
                    style={{ padding: 0, borderBottom: "none", border:"0px solid green" }}
                    align="left"
                  >
                    {" "}
                  </TableCell>
                </TableRow>
                <TableRow>
                <TableCell
                    width="15%"
                    style={{ padding:0, paddingLeft: 20, borderBottom: "none", fontWeight:"bold", border:"0px solid green"}}
                    align="left"
                  >
                    {" "}
                  </TableCell>
                  <TableCell
                  width="15%"
                    style={{ padding: 0, borderBottom: "none", border:"0px solid green"}}
                    align="left"
                  >
                   Email:
                  </TableCell>
                  <TableCell
                  width="15%"
                    style={{ padding: 0, borderBottom: "none", border:"0px solid green" }}
                    align="left"
                  >
                    <a href="mailto:etdsupport@ehi.com" style={{textDecorationLine:"underline", color:"blue"}}>
                    etdsupport@ehi.com
                    </a>
                  </TableCell>
                  <TableCell
                  width="55%"
                    style={{ padding: 0, borderBottom: "none", border:"0px solid green" }}
                    align="left"
                  >
                    {" "}
                  </TableCell>
                </TableRow>   
                <TableRow style={{height: 10}}> <TableCell style={{ padding: 0, borderBottom: "none" , border:"0px solid green"}}></TableCell><TableCell style={{ padding: 0, borderBottom: "none" , border:"0px solid green"}}></TableCell><TableCell style={{ padding: 0, borderBottom: "none" , border:"0px solid green"}}></TableCell><TableCell style={{ padding: 0, borderBottom: "none" , border:"0px solid green"}}></TableCell></TableRow>                     
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </Grid>
    </Grid>
  );
};
export default LegalContactUs;
