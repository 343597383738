import {
  COMPANYINFO_FAIL,
  COMPANYINFO_LOADING,
  COMPANYINFO_SUCCESS,
  CompanyInfoDispatchTypes,
  CompanyInfo,
} from "../../actions/home/companyInfoActionTypes";

interface DefaultStateI {
  loading: boolean;
  companyInfo?: CompanyInfo;
  error: string;
}

const defaultState: DefaultStateI = {
  loading: false,
  companyInfo: {
    companyId: 1,
    companyLinks: [],
    companyLogo: "",
    marketingMessage: { title: "", message: "" },
    marketingImages: [],
    isDriverValidityActivated: false,
    isAdditionalDriverActivated: false,
    isSpecialityVehicleActive : false,
    isCCProfileManagementActivated: false,
    isBoboEnabled: false,
    isLoyaltyEnabled:false,
    isRentalMgmtEnabled: false,
    ccPasswordResetLink: "",
    csPasswordResetLink: "",
    ccEnrollmentLink: "",
    takoApiEnv:"",
  },
  error: "",
};

const companyInfoReducer = (
  state: DefaultStateI = defaultState,
  action: CompanyInfoDispatchTypes
): DefaultStateI => {
  switch (action.type) {
    case COMPANYINFO_FAIL:
      return {
        loading: false,
        error: "An error occured",
      };
    case COMPANYINFO_LOADING:
      return {
        loading: true,
        error: "",
      };
    case COMPANYINFO_SUCCESS:
      return {
        loading: false,
        companyInfo: action.payload,
        error: "",
      };
    default:
      return state;
  }
};

export default companyInfoReducer;
