import { Button } from "@mui/material";
import { authService } from "services/authService";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { useDispatch } from "react-redux";
import { clearAlertMessage } from "../components/store/actions/shared/alertActions";
import { setCorporateInformationSkipLink } from "components/store/slicers/skipLinkSlice";
const CorporateInformation = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const lng = queryParams.get("locale");
  var language = lng !== null ? lng.toString() : "en-GB";

  const dispatch = useDispatch();

  useEffect(() => {
    document.title = "Enterprise Rent-A-Car - Corporate Information";
    dispatch(clearAlertMessage());
  });

  useEffect(() => {
    dispatch(setCorporateInformationSkipLink())  
  }, [])
  

  return language === "de-DE" ? (
    <div style={{ marginLeft: "1em", marginBottom: "1em" }}>
      <head>
        <title>Enterprise Rent-A-Car - Home</title>
      </head>
      <div>
        <Button
          variant="outlined"
          color="primary"
          title={"Klicken Sie zum Drucken"}
          aria-label={"Klicken Sie zum Drucken"}
          onClick={() => {
            window.print();
          }}
        >
          {" "}
          {"Klicken Sie zum Drucken"}
        </Button>
      </div>
      <div>
        {" "}
        <h1 id="corporateInformation">Impressum</h1>
      </div>
      <div>
        <p>Diese Seite wird betrieben von:</p>

        <p> Enterprise Rent-A-Car UK Limited </p>

        <p> Enterprise House </p>
        <p> Vicarage Road </p>
        <p> Egham </p>
        <p> Surrey </p>
        <p> TW20 9FB </p>

        <p> Geschäftsführer: </p>

        <p> Rick Short </p>

        <p> Commercial Registry: </p>

        <p>
          {" "}
          Limited Liability Company, eingetragen: England und Wales im Companies
          House unter Registernummer 2946689{" "}
        </p>

        <p> Umsatzsteuer-Nummer: </p>

        <p> GB 641634450 </p>

        <p> Kontaktdaten: </p>

        <p> E-Mail-Adresse: nicadmin@enterprise.com </p>
        <p> Telefon: 0800 111 4312 </p>
      </div>
    </div>
  ) : language === "fr-FR" ? (
    <div style={{ marginLeft: "1em", marginBottom: "1em" }}>
      <div>
        <Button
          variant="outlined"
          color="primary"
          title={"Cliquez pour imprimer cette page"}
          aria-label={"Cliquez pour imprimer cette page"}
          onClick={() => {
            window.print();
          }}
        >
          {" "}
          {"Cliquez pour imprimer cette page"}
        </Button>
      </div>
      <div>
        {" "}
        <h1 id="corporateInformation">Informations sur l’entreprise</h1>
      </div>
      <div>
        <p>Ce site est exploité par:</p>

        <p> Enterprise Rent-A-Car UK Limited </p>

        <p> Enterprise House </p>
        <p> Vicarage Road </p>
        <p> Egham </p>
        <p> Surrey </p>
        <p> TW20 9FB </p>

        <p> Représentant légal / Directeur </p>

        <p> Rick Short </p>

        <p> Registre commercial : </p>

        <p>
          {" "}
          Société à responsabilité limitée enregistrée auprès de Companies House
          en Angleterre et au Pays de Galles sous le numéro d’enregistrement
          2946689{" "}
        </p>

        <p> Numéro de TVA: </p>

        <p> GB 641634450 </p>

        <p> Coordonnées: </p>

        <p> Adresse e-mail: nicadmin@enterprise.com </p>
        <p> Téléphone: 0800 111 4312 </p>
      </div>
    </div>
  ) : language === "es-ES" ? (
    <div style={{ marginLeft: "1em", marginBottom: "1em" }}>
      <div>
        <Button
          variant="outlined"
          color="primary"
          title={"Haga click aquí para imprimir esta página"}
          aria-label={"Haga click aquí para imprimir esta página"}
          onClick={() => {
            window.print();
          }}
        >
          {" "}
          {"Haga click aquí para imprimir esta página"}
        </Button>
      </div>
      <div>
        {" "}
        <h1 id="corporateInformation">Información corporativa</h1>
      </div>
      <div>
        <p>Este sitio está gestionado por:</p>

        <p> Enterprise Rent-A-Car UK Limited </p>

        <p> Enterprise House </p>
        <p> Vicarage Road </p>
        <p> Egham </p>
        <p> Surrey </p>
        <p> TW20 9FB </p>

        <p> Representante legal / Director </p>

        <p> Rick Short </p>

        <p> Registro comercial: </p>

        <p>
          {" "}
          Una sociedad de responsabilidad limitada registrada en Companies House
          en Inglaterra y Gales con el número de registro 2946689{" "}
        </p>

        <p> Número de IVA: </p>

        <p> GB 641634450 </p>

        <p> Contacto: </p>

        <p> Correo electrónico: nicadmin@enterprise.com </p>
        <p> Teléfono: 0800 111 4312 </p>
      </div>
    </div>
  ) : language === "en-US" ? (
    <div style={{ marginLeft: "1em", marginBottom: "1em" }}>
      <div>
        <Button
          variant="outlined"
          color="primary"
          title={"Click to Print This Page"}
          aria-label={"Click to Print This Page"}
          onClick={() => {
            window.print();
          }}>
          {" "}
          {"Click to Print This Page"}
        </Button>
      </div>
      <div>
        {" "}
        <h1 id="corporateInformation">Corporate Information</h1>
      </div>
      <div>
        <p>This site is operated by:</p>

        <p> Enterprise Rent-A-Car UK Limited </p>

        <p> Enterprise House </p>
        <p> Vicarage Road </p>
        <p> Egham </p>
        <p> Surrey </p>
        <p> TW20 9FB </p>

        <p> Legal Representative / Director: </p>

        <p> Rick Short </p>

        <p> Commercial Registry: </p>

        <p>
          {" "}
          A limited liability company registered with Companies House in England
          and Wales with registered number 2946689{" "}
        </p>

        <p> VAT Number: </p>

        <p> GB 641634450 </p>

        <p> Contact: </p>

        <p> Email: nicadmin@enterprise.com </p>
        <p> Telephone: 0800 111 4312 </p>
      </div>
    </div>
  ) : language === "en-CA" ? (
    <div style={{ marginLeft: "1em", marginBottom: "1em" }}>
      <div>
        <Button
          variant="outlined"
          color="primary"
          title={"Click to Print This Page"}
          aria-label={"Click to Print This Page"}
          onClick={() => {
            window.print();
          }}>
          {" "}
          {"Click to Print This Page"}
        </Button>
      </div>
      <div>
        {" "}
        <h1 id="corporateInformation">Corporate Information</h1>
      </div>
      <div>
        <p>This site is operated by:</p>

        <p> Enterprise Rent-A-Car UK Limited </p>

        <p> Enterprise House </p>
        <p> Vicarage Road </p>
        <p> Egham </p>
        <p> Surrey </p>
        <p> TW20 9FB </p>

        <p> Legal Representative / Director: </p>

        <p> Rick Short </p>

        <p> Commercial Registry: </p>

        <p>
          {" "}
          A limited liability company registered with Companies House in England
          and Wales with registered number 2946689{" "}
        </p>

        <p> VAT Number: </p>

        <p> GB 641634450 </p>

        <p> Contact: </p>

        <p> Email: nicadmin@enterprise.com </p>
        <p> Telephone: 0800 111 4312 </p>
      </div>
    </div>
  ) : language === "fr-CA" ? (
    <div style={{ marginLeft: "1em", marginBottom: "1em" }}>
      <div>
        <Button
          variant="outlined"
          color="primary"
          title={"Click to Print This Page"}
          aria-label={"Click to Print This Page"}
          onClick={() => {
            window.print();
          }}>
          {" "}
          {"Click to Print This Page"}
        </Button>
      </div>
      <div>
        {" "}
        <h1 id="corporateInformation">Corporate Information</h1>
      </div>
      <div>
        <p id="corporateInformationMain">This site is operated by:</p>

        <p> Enterprise Rent-A-Car UK Limited </p>

        <p> Enterprise House </p>
        <p> Vicarage Road </p>
        <p> Egham </p>
        <p> Surrey </p>
        <p> TW20 9FB </p>

        <p> Legal Representative / Director: </p>

        <p> Rick Short </p>

        <p> Commercial Registry: </p>

        <p>
          {" "}
          A limited liability company registered with Companies House in England
          and Wales with registered number 2946689{" "}
        </p>

        <p> VAT Number: </p>

        <p> GB 641634450 </p>

        <p> Contact: </p>

        <p> Email: nicadmin@enterprise.com </p>
        <p> Telephone: 0800 111 4312 </p>
      </div>
    </div>
  ):(
    <div style={{ marginLeft: "1em", marginBottom: "1em" }}>
      <div>
        <Button
          variant="outlined"
          color="primary"
          title={"Click to Print This Page"}
          aria-label={"Click to Print This Page"}
          onClick={() => {
            window.print();
          }}
        >
          {" "}
          {"Click to Print This Page"}
        </Button>
      </div>
      <div>
        {" "}
        <h1 id="corporateInformation">Corporate Information</h1>
      </div>
      <div>
        <p>This site is operated by:</p>

        <p> Enterprise Rent-A-Car UK Limited </p>

        <p> Enterprise House </p>
        <p> Vicarage Road </p>
        <p> Egham </p>
        <p> Surrey </p>
        <p> TW20 9FB </p>

        <p> Legal Representative / Director: </p>

        <p> Rick Short </p>

        <p> Commercial Registry: </p>

        <p>
          {" "}
          A limited liability company registered with Companies House in England
          and Wales with registered number 2946689{" "}
        </p>

        <p> VAT Number: </p>

        <p> GB 641634450 </p>

        <p> Contact: </p>

        <p> Email: nicadmin@enterprise.com </p>
        <p> Telephone: 0800 111 4312 </p>
      </div>
    </div>
  );
};
export default CorporateInformation;
