import { SpecialityRequest } from './../components/Reservation/steps/SpecialityRequest/specialityRequestType';
import { appInsights } from "helpers/appInsights";
import axiosInstance from "helpers/axiosInstance";
import { SeverityLevel } from "@microsoft/applicationinsights-common";
import { SpecialityRequestInitialValue, SpecialityRequestState } from 'components/Reservation/steps/SpecialityRequest/specialityRequestType';


export const CreateSpecialityRequest = async (journeyUId: any) => {
 var specialityRequest: SpecialityRequestState = {
  request: SpecialityRequestInitialValue ,
  hasError: false,
  errors: [],
};
  await axiosInstance
    .get("svreservation/create/" + journeyUId)
    .then((result) => {
      
      if (result.data.success) {
        console.log("result.data.data", result.data.data);
        specialityRequest = {
          request:  result.data.data,
          hasError: false,
          errors: [],
        };
      } else {
        var message = result.data.message;
        specialityRequest = {
          request: SpecialityRequestInitialValue,
          hasError: true,
          errors: message,
        };
      }
    })
    .catch((error) => {
      appInsights.trackException({
        properties: {
          method: "CreateSpecialityRequest",
          Component: "Action",
        },
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
      specialityRequest = {
        request: SpecialityRequestInitialValue,
        hasError: true,
        errors: error,
      };
    });
  return new Promise((resolve) => resolve(specialityRequest));
};

export const ReviewSpecialityRequest = async (specialityRequest: SpecialityRequest) => {
  var specialityReviewed: SpecialityRequestState = {
    request: SpecialityRequestInitialValue ,
    hasError: false,
    errors: [],
  };
 
   await axiosInstance
     .post("svreservation/review", specialityRequest)
     .then((result) => {       
       if (result.data.success) {
        specialityReviewed = {
          request: specialityRequest,
          hasError: false,
          errors: [],
        };
         console.log("result.data.data", result.data.data);       
       } else {
        console.log("result", result); 
         var message = result.data.errors;
         specialityReviewed = {
           request: SpecialityRequestInitialValue,
           hasError: true,
           errors: message,
         };
       }
     })
     .catch((error) => {
       appInsights.trackException({
         properties: {
           method: "ReviewSpecialityRequest",
           Component: "Action",
         },
         exception: error,
         severityLevel: SeverityLevel.Error,
       });
       specialityReviewed = {
         request: SpecialityRequestInitialValue,
         hasError: true,
         errors: error,
       };
     });
   return new Promise((resolve) => resolve(specialityReviewed));
 };

 export const SaveSpecialityRequest = async (specialityRequest: SpecialityRequest) => {
  var specialitySaved: SpecialityRequestState = {
    request: specialityRequest ,
    hasError: false,
    errors: [],
  };
 
  // var model = {
  //   SVReservationId : specialityRequest.svReservationId,
  //   SpecialityVehicles : specialityRequest.specialityVehicles, 
  //   VehicleTypeNotes :  specialityRequest.vehicleTypeNotes, 
  //   Reservation : specialityRequest.reservation,
  //   SelectedSpecialityVehicle : specialityRequest.selectedSpecialityVehicle
  // }
   await axiosInstance
     .post("svreservation/save", specialityRequest)
     .then((result) => {
        console.log("result",result);
        if (result.data.success) {
          console.log("submitted.", result.data.data);
          specialitySaved = {
            request: specialityRequest ,
            hasError: false,
            errors: []
          };
        } 
        else {
          var message = result.data.message;
          specialitySaved = {
            request: specialityRequest,
            hasError: true,
            errors: message,
          };
        }
      })
     .catch((error) => {
       appInsights.trackException({
         properties: {
           method: "SaveSpecialityRequest",
           Component: "Action",
         },
         exception: error,
         severityLevel: SeverityLevel.Error,
       });
       specialitySaved = {
         request: SpecialityRequestInitialValue,
         hasError: true,
         errors: error,
       };
     });
   return new Promise((resolve) => resolve(specialitySaved));
 };

