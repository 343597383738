const isValidEmail = (email = ''): boolean => {
     var re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  
const applyMasking = (email = ''): string => {
    const [name, domain] = email.split('@');
    const { length: len } = name;
    const maskedName = (len >= 3 ? name.substring(0,3) : name) +'******';
    return `${maskedName}@${domain}`
 };

 export const decodeEmailIfEncoded = (email = ''): string => {
  return isValidEmail(email) ? email  : atob(atob(email));
}

const decodeEmail = (email = ''): string => {
    return atob(atob(email))
 }
const encodeEmail = (email = ''): string => {
  return btoa(btoa(email))
}

export const maskEmail = (email) => {

    if(isValidEmail(email)){
      return applyMasking(email);
    }
    return applyMasking(decodeEmail(email)); 
}
let emailPattern = new RegExp(/^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i); // NOSONAR
 export function isValidEtdEmailAddress(emailAddress) {
    let isValid = false;

    if (!emailAddress) {      
      return isValid;
    }
    if (emailPattern.test(emailAddress)) {
      return true;
    }

    return isValid;
  }