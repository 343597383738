import React, { useEffect, useMemo } from "react";
import { DriverViewModel } from "components/Reservation/steps/Reservation/reservationFormType";
import {
  Typography,
  Grid,
  FormControl,
  TextField,
  Link,
  Box,
  Select,
  Checkbox,
} from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import makeStyles from "@mui/styles/makeStyles";
import { useTranslation } from "react-i18next";
import { driverDetailsValidationSchema } from "./DriverDetailsValidation";
import { maskEmail } from "helpers/emailHelpers";
import { ReservationProps } from "components/Reservation/reservationType";
import { ReservationMode } from "helpers/useReservationMode";

const DriverDetails: React.FC<{
  values: DriverViewModel;
  resType: ReservationMode;
  isConsentCountry?: boolean;
  IsEditReservation?: boolean;
  acceptConsent?: boolean;
  onConsentChange?: (
    isConsentCountry: React.ChangeEvent<HTMLInputElement>
  ) => void;
  startLocation?: ReservationProps;
  onDataChange: (driverViewModel: DriverViewModel, isValid: boolean) => void;
  isBOBOUser?: boolean;
  isPhoneNumberOptional?: boolean;
  isEmailAddressMaskToggleEnabled?: boolean;
}> = ({
  values,
  resType,
  isConsentCountry,
  IsEditReservation,
  acceptConsent,
  onConsentChange,
  startLocation,
  onDataChange,
  isBOBOUser = false,
  isPhoneNumberOptional = false,
  isEmailAddressMaskToggleEnabled = false,
}) => {
  const { t } = useTranslation();
  const useStyles = makeStyles((theme) => ({
    gridItem: {
      verticalAlign: "center",
      padding: "10px",
      "& .MuiTypography-body2": {
        fontWeight: "bold",
        fontSize: "0.875rem",
      },
    },
    heading: {
      fontSize: "1rem",
      fontWeight: "bold",
    },
    labelText: {
      color: "#000",
      fontFamily: "Arial, Roboto, Segoe UI",
      fontSize: "0.875rem",
      fontWeight: "bold",
      verticalAlign: "middle",
    },
    grayed: {
      backgroundColor: "#EDEDED",
    },
    errorWarning: {
      color: "#DF0000",
      fontSize: "0.75rem",
      marginLeft: "15px",
    },
    textarea: {
      resize: "both",
    },
    focused: { outline: "none" },
    placeHolder: {
      "& ::placeholder": {
        color: "#767676",
        opacity: "1",
      },
    },
  }));

  const classes = useStyles();

  const handlePhoneNumberChange = (
    selectedInternationalDiallingCode,
    number
  ) => {
    return "".concat(
      "(+",
      typeof selectedInternationalDiallingCode === "string" &&
        selectedInternationalDiallingCode !== "+"
        ? selectedInternationalDiallingCode
        : "",
      ")",
      number
    );
  };

  const isDisabled = useMemo((): boolean => {
    if (
      isBOBOUser &&
      values.firstName === "" &&
      values.lastName === "" &&
      values.emailAddress === ""
    ) {
      return true;
    }
    return false;
  }, [isBOBOUser, values]);

  useEffect(() => {
    //let result = driverDetailsValidationSchema(isPhoneNumberOptional, isEmailAddressMaskToggleEnabled).validateSync(values);
    let isValid = driverDetailsValidationSchema(
      isPhoneNumberOptional,
      isEmailAddressMaskToggleEnabled
    ).isValidSync(values);
    console.log("Driver Received: ", values, isConsentCountry, acceptConsent);
    onDataChange(values, isValid);
  }, [values]);

  return (
    <Formik
      initialValues={values}
      validateOnChange={true}
      validateOnBlur={true}
      validateOnMount={true}
      enableReinitialize={true}
      validationSchema={driverDetailsValidationSchema(
        isPhoneNumberOptional,
        isEmailAddressMaskToggleEnabled
      )}
      onSubmit={() => {}}
    >
      {({
        values,
        isValid,
        dirty,
        setFieldValue,
        setFieldTouched,
        touched,
        handleChange,
        handleBlur,
        errors,
        isSubmitting,
      }) => (
        <Grid container className={classes.placeHolder}>
          {!isBOBOUser && (
            <Grid container direction="row">
              <Grid item xs={12} md={3} className={classes.gridItem}>
                <label htmlFor={"userTitle"} className={classes.labelText}>
                  {t("Users.title")}
                </label>
              </Grid>
              <Grid item xs={12} md={9} className={classes.gridItem}>
                <FormControl variant="outlined" fullWidth>
                  <Select
                    native
                    value={
                      values.userTitle.selectedValue
                        ? values.userTitle.selectedValue
                        : ""
                    }
                    onChange={(e) => {
                      setFieldTouched("userTitle");
                      let text = values.userTitle.items?.find(
                        (x) => x.value === e.target.value
                      )?.text;
                      console.log(text);
                      setFieldValue("userTitle.selectedText", text);
                      setFieldValue("userTitle.selectedValue", e.target.value);
                      let currentValues = {
                        ...values,
                        userTitle: {
                          ...values.userTitle,
                          selectedText: text,
                          selectedValue: e.target.value,
                        },
                      };
                      let isValid = driverDetailsValidationSchema(
                        isPhoneNumberOptional,
                        isEmailAddressMaskToggleEnabled
                      ).isValidSync(currentValues);

                      onDataChange(currentValues as DriverViewModel, isValid);
                    }}
                    onBlur={handleBlur}
                    fullWidth
                    inputProps={{
                      id: "userTitle",
                      name: "userTitle",
                    }}
                    title={t("Users.title")}
                    name="userTitle"
                    id="userTitle"
                    aria-describedby={"userTitle"}
                    error={
                      touched.userTitle && errors.userTitle !== undefined
                        ? true
                        : false
                    }
                  >
                    {values.userTitle.items?.map((opt: any) => (
                      <option key={opt.value} value={opt.value}>
                        {opt.text}
                      </option>
                    ))}
                  </Select>
                  {touched.userTitle && errors.userTitle ? (
                    <span
                      id="userTitle-helper-select-error"
                      className={classes.errorWarning}
                    >
                      {t(errors.userTitle.toString())}
                    </span>
                  ) : (
                    <span id="userTitle-helper-select-error"></span>
                  )}
                </FormControl>
              </Grid>
            </Grid>
          )}

          <Grid container direction="row">
            <Grid item xs={12} md={3} className={classes.gridItem}>
              <label htmlFor={"firstName"} className={classes.labelText}>
                {isBOBOUser
                  ? t("Users.firstname")
                  : t("Users.firstname").concat(" *")}
              </label>
            </Grid>
            <Grid item xs={12} md={9} className={classes.gridItem}>
              <Field
                name="firstName"
                id="firstName"
                value={values.firstName}
                fullWidth
                style={{ paddingBottom: 0, paddingTop: 0 }}
                className={isBOBOUser ? classes.grayed : ""}
                inputProps={{
                  "aria-label": t("Users.firstname"),
                  "aria-required": "true",
                  "aria-describedby": "firstName",
                }}
                onChange={(e) => {
                  setFieldTouched("firstName");
                  setFieldValue("firstName", e.target.value);
                  let currentValues = { ...values, firstName: e.target.value };
                  let isValid = driverDetailsValidationSchema(
                    isPhoneNumberOptional,
                    isEmailAddressMaskToggleEnabled
                  ).isValidSync(currentValues);

                  onDataChange(currentValues as DriverViewModel, isValid);
                }}
                onBlur={handleBlur}
                component={TextField}
                variant="outlined"
                error={
                  touched.firstName && errors.firstName !== undefined
                    ? true
                    : false
                }
                placeholder={isDisabled ? "" : t("Users.firstnamePlaceholder")}
                disabled={resType === ReservationMode.Edit || isBOBOUser}
              />
              {touched.firstName && errors.firstName ? (
                <span id="firstNameError" className={classes.errorWarning}>
                  {t(errors?.firstName ?? "")}
                </span>
              ) : (
                <span id="firstNameError"></span>
              )}
            </Grid>
          </Grid>
          <Grid container direction="row">
            <Grid item xs={12} md={3} className={classes.gridItem}>
              <label htmlFor={"lastName"} className={classes.labelText}>
                {isBOBOUser
                  ? t("Users.lastname")
                  : t("Users.lastname").concat(" *")}
              </label>
            </Grid>
            <Grid item xs={12} md={9} className={classes.gridItem}>
              <Field
                name="lastName"
                id="lastName"
                value={values.lastName}
                fullWidth
                style={{ paddingBottom: 0, paddingTop: 0 }}
                className={isBOBOUser ? classes.grayed : ""}
                inputProps={{
                  "aria-label": t("Users.lastname"),
                  "aria-required": true,
                  "aria-describedby": "lastName",
                }}
                onChange={(e) => {
                  setFieldTouched("lastName");
                  setFieldValue("lastName", e.target.value);
                  let currentValues = { ...values, lastName: e.target.value };
                  let isValid = driverDetailsValidationSchema(
                    isPhoneNumberOptional,
                    isEmailAddressMaskToggleEnabled
                  ).isValidSync(currentValues);

                  onDataChange(currentValues as DriverViewModel, isValid);
                }}
                onBlur={handleBlur}
                component={TextField}
                variant="outlined"
                error={
                  touched.lastName && errors.lastName !== undefined
                    ? true
                    : false
                }
                placeholder={isDisabled ? "" : t("Users.lastnamePlaceholder")}
                disabled={resType === ReservationMode.Edit || isBOBOUser}
              />
              {touched.lastName && errors.lastName ? (
                <span id="lastNameError" className={classes.errorWarning}>
                  {t(errors?.lastName ?? "")}
                </span>
              ) : (
                <span id="lastNameError"></span>
              )}
            </Grid>
          </Grid>
          <Grid container direction="row">
            <Grid item xs={12} md={3} className={classes.gridItem}>
              <label htmlFor={"email"} className={classes.labelText}>
                {isBOBOUser
                  ? t("Users.emailaddress")
                  : t("Users.emailaddress").concat(" *")}
              </label>
            </Grid>
            <Grid item xs={12} md={9} className={classes.gridItem}>
              <Field
                name="email"
                id="email"
                value={
                  isEmailAddressMaskToggleEnabled && isBOBOUser
                    ? maskEmail(values.emailAddress)
                    : values.emailAddress
                }
                fullWidth
                style={{ paddingBottom: 0, paddingTop: 0 }}
                className={isBOBOUser ? classes.grayed : ""}
                inputProps={{
                  "aria-label": t("Users.emailaddress"),
                  "aria-required": true,
                  "aria-describedby": "email",
                }}
                onChange={(e) => {
                  setFieldTouched("emailAddress");
                  setFieldValue("emailAddress", e.target.value);
                  let currentValues = {
                    ...values,
                    emailAddress: e.target.value,
                  };
                  let isValid = driverDetailsValidationSchema(
                    isPhoneNumberOptional,
                    isEmailAddressMaskToggleEnabled
                  ).isValidSync(currentValues);

                  onDataChange(currentValues as DriverViewModel, isValid);
                }}
                onBlur={handleBlur}
                component={TextField}
                variant="outlined"
                error={
                  touched.emailAddress && errors.emailAddress !== undefined
                    ? true
                    : false
                }
                placeholder={isDisabled ? "" : t("Users.emailPlaceholder")}
                //disabled={resType !=="1" }//&& isBOBOUser}
                disabled={isBOBOUser}
              />
              {touched.emailAddress && errors.emailAddress ? (
                <span id="emailAddressError" className={classes.errorWarning}>
                  {t(errors?.emailAddress ?? "")}
                </span>
              ) : (
                <span id="emailAddressError"></span>
              )}
            </Grid>
          </Grid>
          <Grid container direction="row">
            <Grid item xs={12} md={3} className={classes.gridItem}>
              <label
                htmlFor={"contactPhoneNumber.selectedInternationalDiallingCode"}
                className={classes.labelText}
              >
                {isPhoneNumberOptional
                  ? t("Users.phoneNumber")
                  : t("Users.phoneNumber").concat(" *")}
              </label>
            </Grid>
            <Grid item xs={12} md={3} className={classes.gridItem}>
              <FormControl variant="outlined" fullWidth>
                <Select
                  native
                  value={
                    values.contactPhoneNumber
                      ?.selectedInternationalDiallingCode ?? ""
                  }
                  onChange={(e) => {
                    setFieldTouched(
                      "contactPhoneNumber.selectedInternationalDiallingCode"
                    );
                    let contactPhoneNumberString = handlePhoneNumberChange(
                      e.target.value,
                      values.contactPhoneNumber.number
                    );
                    setFieldValue(
                      "contactPhoneNumberString",
                      contactPhoneNumberString
                    );
                    setFieldValue(
                      "contactPhoneNumber.selectedInternationalDiallingCode",
                      e.target.value
                    );
                    let currentValues = {
                      ...values,
                      contactPhoneNumberString: contactPhoneNumberString,
                      contactPhoneNumber: {
                        ...values.contactPhoneNumber,
                        selectedInternationalDiallingCode: e.target.value,
                      },
                    };
                    let isValid = driverDetailsValidationSchema(
                      isPhoneNumberOptional,
                      isEmailAddressMaskToggleEnabled
                    ).isValidSync(currentValues);

                    onDataChange(currentValues as DriverViewModel, isValid);
                  }}
                  onBlur={handleBlur}
                  fullWidth
                  inputProps={{
                    id: "preferredPhoneNumber.selectedInternationalDiallingCode",
                    name: "contactPhoneNumber.selectedInternationalDiallingCode",
                    title: t("Users.countryCodeForPreferredNumber"),
                    "aria-label": t("Users.countryCodeForPreferredNumber"),
                    "aria-describedby":
                      "contactPhoneNumber.selectedInternationalDiallingCodeError",
                  }}
                  name="contactPhoneNumber.selectedInternationalDiallingCode"
                  error={
                    (touched?.contactPhoneNumber?.number ||
                      (!isDisabled && isPhoneNumberOptional === false)) &&
                    errors?.contactPhoneNumber
                      ?.selectedInternationalDiallingCode !== undefined
                      ? true
                      : false
                  }
                  disabled={isDisabled}
                  className={isDisabled ? classes.grayed : ""}
                >
                  {values.contactPhoneNumber?.availableInternationalDiallingCodes?.map(
                    (opt: any, optIndex) => (
                      <option
                        key={"contactPhoneNumberDiallingCodes" + optIndex}
                        value={opt.value}
                        disabled={opt.value === "+" ? true : false}
                      >
                        {opt.text}
                      </option>
                    )
                  )}
                </Select>
              </FormControl>
              {(touched?.contactPhoneNumber?.number ||
                (!isDisabled && isPhoneNumberOptional === false)) &&
              errors?.contactPhoneNumber?.selectedInternationalDiallingCode ? (
                <span
                  id="preferredPhoneNumber.selectedInternationalDiallingCodeError"
                  className={classes.errorWarning}
                >
                  {t(
                    errors.contactPhoneNumber
                      ?.selectedInternationalDiallingCode ?? ""
                  )}
                </span>
              ) : (
                <span id="preferredPhoneNumber.selectedInternationalDiallingCodeError"></span>
              )}
            </Grid>
            <Grid item xs={12} md={6} className={classes.gridItem}>
              <Field
                name="contactPhoneNumber.number"
                id="preferredPhoneNumber.number"
                value={values.contactPhoneNumber.number ?? ""}
                fullWidth
                style={{ paddingBottom: 0, paddingTop: 0 }}
                inputProps={{
                  "aria-label": t("Users.phoneNumber"),
                  "aria-describedby": "contactPhoneNumber.numberError",
                }}
                onChange={(e) => {
                  setFieldTouched("contactPhoneNumber.number");
                  let contactPhoneNumberString = handlePhoneNumberChange(
                    values.contactPhoneNumber
                      ?.selectedInternationalDiallingCode,
                    e.target.value
                  );
                  setFieldValue(
                    "contactPhoneNumberString",
                    contactPhoneNumberString
                  );
                  setFieldValue("contactPhoneNumber.number", e.target.value);
                  let currentValues = {
                    ...values,
                    contactPhoneNumberString: contactPhoneNumberString,
                    contactPhoneNumber: {
                      ...values.contactPhoneNumber,
                      number: e.target.value,
                    },
                  };
                  let isValid = driverDetailsValidationSchema(
                    isPhoneNumberOptional,
                    isEmailAddressMaskToggleEnabled
                  ).isValidSync(currentValues);
                  onDataChange(currentValues as DriverViewModel, isValid);
                }}
                onBlur={handleBlur}
                component={TextField}
                variant="outlined"
                error={
                  (touched.contactPhoneNumber
                    ?.selectedInternationalDiallingCode ||
                    (!isDisabled && isPhoneNumberOptional === false)) &&
                  errors?.contactPhoneNumber?.number !== undefined
                    ? true
                    : false
                }
                placeholder={
                  isDisabled ? "" : t("Users.preferredPhonePlaceholder")
                }
                disabled={isDisabled}
                className={isDisabled ? classes.grayed : ""}
              />
              {errors.contactPhoneNumber && !isDisabled ? (
                <span
                  id="preferredPhoneNumber.numberError"
                  className={classes.errorWarning}
                >
                  {t(errors?.contactPhoneNumber?.number ?? "")}
                </span>
              ) : (
                <span id="preferredPhoneNumber.numberError"></span>
              )}
            </Grid>
          </Grid>
          <Grid container direction="row">
            <Grid item xs={12} md={3} className={classes.gridItem}>
              <label
                htmlFor={
                  "alternatePhoneNumber.selectedInternationalDiallingCode"
                }
                className={classes.labelText}
              >
                {t("Users.alternateNumber")}
              </label>
            </Grid>
            <Grid item xs={12} md={3} className={classes.gridItem}>
              <FormControl variant="outlined" fullWidth>
                <Select
                  native
                  value={
                    values.alternatePhoneNumber
                      ?.selectedInternationalDiallingCode ?? ""
                  }
                  onChange={(e) => {
                    setFieldTouched(
                      "alternatePhoneNumber.selectedInternationalDiallingCode"
                    );
                    let alternatePhoneNumberString = handlePhoneNumberChange(
                      e.target.value,
                      values.alternatePhoneNumber.number
                    );
                    setFieldValue(
                      "alternatePhoneNumberString",
                      alternatePhoneNumberString
                    );
                    setFieldValue(
                      "alternatePhoneNumber.selectedInternationalDiallingCode",
                      e.target.value
                    );
                    let currentValues = {
                      ...values,
                      alternatePhoneNumberString: alternatePhoneNumberString,
                      alternatePhoneNumber: {
                        ...values.alternatePhoneNumber,
                        selectedInternationalDiallingCode: e.target.value,
                      },
                    };
                    let isValid = driverDetailsValidationSchema(
                      isPhoneNumberOptional,
                      isEmailAddressMaskToggleEnabled
                    ).isValidSync(currentValues);
                    onDataChange(currentValues as DriverViewModel, isValid);
                  }}
                  onBlur={handleBlur}
                  fullWidth
                  inputProps={{
                    id: "alternativePhoneNumber.selectedInternationalDiallingCode",
                    name: "alternatePhoneNumber.selectedInternationalDiallingCode",
                    title: t("Users.countryCodeForAlternateNumber"),
                    "aria-label": t("Users.countryCodeForAlternateNumber"),
                    "aria-describedby":
                      "alternatePhoneNumber.selectedInternationalDiallingCodeError",
                  }}
                  name="alternatePhoneNumber.selectedInternationalDiallingCode"
                  error={
                    touched?.alternatePhoneNumber?.number &&
                    errors?.alternatePhoneNumber
                      ?.selectedInternationalDiallingCode !== undefined
                      ? true
                      : false
                  }
                  disabled={isDisabled}
                  className={isDisabled ? classes.grayed : ""}
                >
                  {values.alternatePhoneNumber?.availableInternationalDiallingCodes?.map(
                    (opt: any, optIndex) => (
                      <option
                        key={"alternatePhoneNumberDiallingCodes" + optIndex}
                        value={opt.value}
                      >
                        {opt.text}
                      </option>
                    )
                  )}
                </Select>
              </FormControl>
              {touched.alternatePhoneNumber?.number &&
              errors?.alternatePhoneNumber
                ?.selectedInternationalDiallingCode ? (
                <span
                  id="alternativePhoneNumber.selectedInternationalDiallingCodeError"
                  className={classes.errorWarning}
                >
                  {t(
                    errors?.alternatePhoneNumber
                      ?.selectedInternationalDiallingCode ?? ""
                  )}
                </span>
              ) : (
                <span id="alternatePhoneNumber.selectedInternationalDiallingCodeError"></span>
              )}
            </Grid>
            <Grid item xs={12} md={6} className={classes.gridItem}>
              <Field
                name="alternatePhoneNumber.number"
                id="alternativePhoneNumber.number"
                value={values.alternatePhoneNumber.number ?? ""}
                fullWidth
                style={{ paddingBottom: 0, paddingTop: 0 }}
                inputProps={{
                  "aria-label": t("Users.alternateNumber"),
                  "aria-describedby": "alternatePhoneNumber.numberError",
                }}
                onChange={(e) => {
                  setFieldTouched("alternatePhoneNumber.number");
                  let alternatePhoneNumberString = handlePhoneNumberChange(
                    values.alternatePhoneNumber
                      ?.selectedInternationalDiallingCode,
                    e.target.value
                  );
                  setFieldValue(
                    "alternatePhoneNumberString",
                    alternatePhoneNumberString
                  );
                  setFieldValue("alternatePhoneNumber.number", e.target.value);
                  let currentValues = {
                    ...values,
                    alternatePhoneNumberString: alternatePhoneNumberString,
                    alternatePhoneNumber: {
                      ...values.alternatePhoneNumber,
                      number: e.target.value,
                    },
                  };
                  let isValid = driverDetailsValidationSchema(
                    isPhoneNumberOptional,
                    isEmailAddressMaskToggleEnabled
                  ).isValidSync(currentValues);
                  onDataChange(currentValues as DriverViewModel, isValid);
                }}
                onBlur={handleBlur}
                component={TextField}
                variant="outlined"
                error={
                  touched?.alternatePhoneNumber
                    ?.selectedInternationalDiallingCode &&
                  errors?.alternatePhoneNumber?.number !== undefined
                    ? true
                    : false
                }
                placeholder={
                  isDisabled ? "" : t("Users.alternatePhonePlaceholder")
                }
                disabled={isDisabled}
                className={isDisabled ? classes.grayed : ""}
              />
              {errors.alternatePhoneNumber ? (
                <span
                  id="alternativePhoneNumber.numberError"
                  className={classes.errorWarning}
                >
                  {t(errors?.alternatePhoneNumber?.number ?? "")}
                </span>
              ) : (
                <span id="alternativePhoneNumber.numberError"></span>
              )}
            </Grid>
          </Grid>
          {isConsentCountry ? (
            <Grid sx={{ marginTop: "1rem" }}>
              <Typography display="inline" variant="h2" tabIndex={0}>
                {t(
                  "ReservationWizard.reservationPage.labelConsentMessageHeader"
                )}
              </Typography>
              <Grid item xs={12} md={true} sx={{ marginTop: "2rem" }}>
                <Typography display="inline" variant="inherit" tabIndex={0}>
                  {t(
                    "ReservationWizard.reservationPage.labalConsentMessageForConsentRequiredCountriesPart1"
                  )}
                </Typography>
                {t(
                  "ReservationWizard.reservationPage.labalConsentMessageForConsentRequiredCountriesPart2"
                )}
                <Checkbox
                  id="consentCheckbox"
                  checked={acceptConsent}
                  disabled={resType === ReservationMode.Edit}
                  onChange={onConsentChange}
                  sx={{ padding: "0", margin: "0" }}
                />
              </Grid>
            </Grid>
          ) : (
            <Grid item xs={12} md={12} className={classes.gridItem}>
              <Typography display="inline" variant="inherit" tabIndex={0}>
                {t(
                  "ReservationWizard.reservationPage.labelConsentMessagePart1"
                )}
              </Typography>
              <Link
                id="privacypolicyhyperlink"
                target="_blank"
                href={t("ReservationWizard.reservationPage.privacyPolicyURL")}
                display="inline"
                variant="inherit"
              >
                {t(
                  "ReservationWizard.reservationPage.labelConsentMessagePart2"
                )}
                <Box component="span" className="visuallyHidden">
                  {t("Footer.OpenNewWindow")}
                </Box>
              </Link>
              <Typography display="inline" variant="inherit">
                {t(
                  "ReservationWizard.reservationPage.labelConsentMessagePart3"
                )}
              </Typography>
            </Grid>
          )}

          <Grid></Grid>
        </Grid>
      )}
    </Formik>
  );
};

export default DriverDetails;
