export interface CCPasswordChange {
    currentPassword: string,
    newPassword: string,
    confirmPassword: string
}

export interface CCPinChange {
    newPin: string,
    confirmPin: string
}

export interface CCHomeProps {
    redirectTo: string,
    hideCCTile: boolean,
    focusCCPwd: boolean
}

export const InitialPasswordValue : CCPasswordChange = {
    currentPassword: "",
    newPassword: "",
    confirmPassword: ""
}

export const InitialPinValue : CCPinChange = {
    newPin: "",
    confirmPin: ""
}