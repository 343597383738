import { history } from "./history";
import configParams from "../configParams";

import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";

const reactPlugin = new ReactPlugin();
const ai = new ApplicationInsights({
  config: {
    instrumentationKey: configParams.APP_INSIGHTS_KEY,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: history },
    },
  },
});
ai.loadAppInsights();
const appInsights = ai.appInsights;
if(configParams.APP_INSIGHTS_KEY !== "test"){
  appInsights.trackPageView();
}
export { reactPlugin, appInsights };
