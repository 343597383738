import createStyles from '@mui/styles/createStyles';
import moment from "moment";
import { minutesBetweenTwoDates } from ".";
import { AvailabilityPeriod } from "../models";

//use this function to generate css property value, but IE doesn't support -webkit-gradient
//background: `-webkit-gradient(${buildColours(availabilityPeriods)})`,
function buildColours(availabilityPeriods: AvailabilityPeriod[]) {
  const free = "rgba(15,15,15,0.3)";
  const busy = "rgba(255,0,0,1)";
  const horizontal = "linear, left top, right top";
  const numberOfPeriods = availabilityPeriods.length - 1;
  const stepPercent = 100 / numberOfPeriods;
  const colors = availabilityPeriods
    .map((period: AvailabilityPeriod, i: number) =>
      period.isAvailable
        ? `color-stop(${i * stepPercent}%, ${free}), color-stop(${
            (i + 1) * stepPercent
          }%, ${free})`
        : `color-stop(${i * stepPercent}%, ${busy}), color-stop(${
            (i + 1) * stepPercent
          }%, ${busy})`
    )
    .join(",");
  return [horizontal, colors].join(",");
}

//use this function to generate css property value like
//background: linear-gradient(to right, lime 25%, red 25%, red 50%, cyan 50%, cyan 75%, yellow 75% );
function buildColoursNew(availabilityPeriods: AvailabilityPeriod[]) {
  const free = "rgba(15,15,15,0.3)";
  const busy = "rgba(255,0,0,1)";
  const horizontal = "to right";
  var periods = availabilityPeriods;
  const numberOfPeriods = periods.length - 1;
  const stepPercent = 100 / numberOfPeriods;
  const colors = periods
    .map((period: AvailabilityPeriod, i: number) =>
      period.isAvailable
        ? `${free} ${i * stepPercent}%, ${free} ${(i + 1) * stepPercent}%`
        : `${busy} ${i * stepPercent}%, ${busy} ${(i + 1) * stepPercent}%`
    ).join(",");
  return [horizontal, colors].join(",");
}

export function getAvailabilityPeriodWithDownTime(availabilityPeriods: AvailabilityPeriod[]) : AvailabilityPeriod[]{
  //assume minutesPerStep is always 15, a reservation will add 15 min before and after as unavailable time
  var periods = JSON.parse(JSON.stringify(availabilityPeriods));
  if(periods.length > 0){    
    for(var index = 0; index <= periods.length-2; index++){
      if(periods[index].isAvailable === true && periods[index+1].isAvailable === false){
        periods[index].isAvailable = false;
      }
      else if(periods[index].isAvailable === false && periods[index+1].isAvailable === true){
        periods[index+1].isAvailable = false;
        index++;
      }
    }
  }  
  return periods;
}

export function timeRangeSliderStyle(
  availabilityPeriods: AvailabilityPeriod[]
) {
  return createStyles({
    root: {
      "& .MuiSlider-valueLabel":{
        background: "#006639",
      },
    },
    rail: {
      background: `linear-gradient(${buildColoursNew(availabilityPeriods)})`,
      height: "10px",
      color: "#333",
      borderRadius: "3px",
      border: "1px solid #CCC",
      opacity: 0.95,
    },
    track: {
      //"#006639",
      background:
      "linear-gradient(to top, rgba(0,128,0,0.6) 0%,rgba(0,128,0,0.6) 100%)",
      height: "10px",
      borderTop: "1px solid #99CC00",
      borderBottom: "1px solid #99CC00",
      opacity: 0.95,
    },
    mark: {
      marginTop: "12px",
      height: "10px",
      color: "#fff",
      width: "1px",
    },
    markActive: {
      backgroundColor: "#fff",
    },
    markLabel: {
      marginTop: "10px",
      color: "#000",
      fontSize: "0.8em",
      whiteSpace: "pre",
    },
    thumb: {
      border: "1px solid #000",
      borderRadius: 0,
      background: "#000",
      //"linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(220,220,220,1) 20%,rgba(255,255,255,1) 100%)",
      color: "#229B22",
      height: "36px !important",
      width: "2px !important",
      marginLeft: "-1px !important",
      marginTop: "-12px !important",
      "&:focus,&:hover,&active": {
        boxShadow: "inherit",
      },

      // display: "none",
    },

    valueLabel: {
      top: "-35px",
      left: "unset",
      transform: "unset",
      borderRadius: 0,
      fontSize: "0.8em",
      "@media (max-width:520px)": {
        top: "-25px",
        fontSize: "0.4rem",
      },
      "@media (max-width:410px)": {
        top: "-20px",
        fontSize: "0.2rem",
      },
      "& *": {
        color: "#FFF",
        transform: "rotate(0deg)",
        borderRadius: "3px",
        height: "unset",
        padding: "1px 0px",
        "@media (max-width:520px)": {
          width: "20px",          
        },
        "@media (max-width:410px)": {
          borderRadius: "5px",
          width: "10px",          
        },
      },
    },
    focusVisible: {
      "& .MuiSlider-thumb:hover": {
        background: "red",
      },
      background: "#FFF",
    },
  });
}

export function buildMarks(
  paddedStartDate,
  paddedEndDate,
  minutesPerStep,
  dateFormat
) {
  const totalMinutes = minutesBetweenTwoDates(paddedStartDate, paddedEndDate);
  const totalMarks = totalMinutes / minutesPerStep + 1; //Math.ceil(totalMinutes / minutesPerStep);

  return Array(totalMarks)
    .fill(null)
    .map((_, i) => ({
      value: i * minutesPerStep,
      label:
        /* moment(paddedStartDate)
          .add(i * minutesPerStep, "m")
          .format("mm") === "00"
          ? moment(paddedStartDate)
              .add(i * minutesPerStep, "m")
              .format(dateFormat)
              .replace(/\s/, "\n")
          : */ null,
    }));
}

export const formattedLabel = (periodStartDate, stepValue, dateFormat) =>
  moment(periodStartDate).add(stepValue, "m").format(dateFormat);

export const ariaText = (periodStartDate, stepValue) =>
  moment(periodStartDate).add(stepValue, "m").format("LLL");
