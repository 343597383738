import {
    MY_JOURNEY_FAIL,
    MY_JOURNEY_LOADING,
    MY_JOURNEY_SUCCESS,
    MyJourneyDispatchTypes,
    myJourneys,
    JourneyDetails,
  } from "../../actions/home/myjourneyActionTypes";
  
  interface DefaultStateI {
    loading: boolean;
    myJourneys ?: myJourneys;
    error : string;
  }
  
  const defaultState: DefaultStateI = {
    loading: false,
    myJourneys: {
      totalRecords : 0,
      data : [],
      errorMessage : ""    
    },
    error: "",
  };
  
  const myJourneyReducer = (
    state: DefaultStateI = defaultState,
    action: MyJourneyDispatchTypes
  ): DefaultStateI => {
    switch (action.type) {
      case MY_JOURNEY_FAIL:
        return {
          loading: false,
          error: "An error occured",
        };
      case MY_JOURNEY_LOADING:
        return {
          loading: true,
          error: "",
        };
      case MY_JOURNEY_SUCCESS:
        return {
          loading: false,
          myJourneys: action.payload,
          error: "",
        };
      default:
        return state;
    }
  };
  
  export default myJourneyReducer;
  