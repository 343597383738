import moment from "moment";
import { BookingTimeRangeSliderProperties } from "../models";
import { mockAvailability } from "./Availability.mock";

export function mockBookingTimeRangeProperties(): BookingTimeRangeSliderProperties {
  const minutesPerStep = 15;
  const minutesToAppend = 120;
  const minutesToPrepend = 120;
  const now = new Date();
  const coeff = 1000 * 60 * 60;
  // next nearest hour + minutes to prepend
  const startDate = moment(new Date(Math.ceil(now.getTime() / coeff) * coeff))
    .add(minutesToPrepend, "m")
    .toDate();

  const endDate = moment(startDate).add(6, "hours").toDate();

  return {
    availabilityPeriods: mockAvailability(
      startDate,
      endDate,
      minutesPerStep,
      minutesToAppend,
      minutesToPrepend
    ),
    startDate,
    endDate,
    config: {
      dateFormat: "D/MM HH:mm",
      minimumHireTimeMinutes: 30,
      minutesPerStep,
    },
  };
}
