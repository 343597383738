import { combineReducers } from "redux";
import reservationReducer from "./reservation/reservationReducer";
import carClubReducer from "./reservation/carclubReducer";
import companyInfoReducer from "./home/companyInfoReducer";
import myJourneyReducer from "./home/myJourneyReducer";
import alertReducer from "./shared/alertReducer";
import announcementReducer from "./shared/announcementReducer";
import specialityReducer from "./reservation/specialityReducer";
import co2DictReducer from "./reservation/co2Reducer";
import driverDetail from "../slicers/driverDetailSlice";
import accountLocationDetail from "../slicers/accountLocationSlice";
import bookingReferenceDetail from "../slicers/bookingReferenceSlice";
import JourneyProfileDetail  from "../slicers/journeyInputSlice";
import skipLinkDetails from "../slicers/skipLinkSlice";

const RootReducer = combineReducers({
  dailyRental: reservationReducer,
  carclub: carClubReducer,
  companyInfo: companyInfoReducer,
  alertMessage: alertReducer,
  announcement: announcementReducer,
  myJourney: myJourneyReducer,
  speciality: specialityReducer,
  co2Dict: co2DictReducer,
  driverDetail: driverDetail,
  accountLocationDeatils: accountLocationDetail,
  bookingReference: bookingReferenceDetail,
  journeyProfile: JourneyProfileDetail,
  skipLink: skipLinkDetails
});

export type AppState = ReturnType<typeof RootReducer>;

export default RootReducer;
