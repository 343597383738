import * as Yup from "yup";
import {LoyaltyProgramType, ValidationResult, ValidationResultExtended}  from "../Reservation/reservationFormType"; 

function validateSelectField(value) {
  if (
    typeof value["selectedValue"] === "undefined" ||
    value["selectedValue"] === ""
  )
    return false;
  else return true;
}

const isBrandSelected = (carClassInfo) => {
  // console.log("CarClass Value: ", carClassInfo);
  if (carClassInfo && carClassInfo !== null) {
    for (var i = 0; i < carClassInfo.carsInformation.classInfo.length; i++) {
      for (
        var j = 0;
        j < carClassInfo.carsInformation.classInfo[i].brandInfo.length;
        j++
      ) {
        if (carClassInfo.carsInformation.classInfo[i].brandInfo[j].isSelected) {
          return true;
        }
      }
    }
  }
  return false;
};
const Today = () => {
  const today = new Date();
  today.setDate(today.getDate() - 1);
  today.setHours(23, 59, 59);
  return today;
};

export const reservationDetailsValidationSchema = Yup.object().shape({ 
  // driverViewModel: Yup.object().shape({
  //   firstName: Yup.string()
  //     .required("Users.Errors.firtNameRequired")
  //     .max(20, "Users.Errors.firstNameMaxLength")
  //     .nullable(),
  //   lastName: Yup.string()
  //     .required("Users.Errors.lastNameRequired")
  //     .max(30, "Users.Errors.lastNameMaxLength")
  //     .nullable(),
  //   emailAddress: Yup.string()
  //     .required("Users.Errors.emailRequired")
  //     .email("Users.Errors.invalidEmail")
  //     .max(80, "Users.Errors.emailMaxLength")
  //     .nullable(),
  // }),
  notesViewModel: Yup.object().shape({
    reservationNote: Yup.string()
      .nullable()
      .max(150, "maximum allowed characters 150"),
  }),
  loyalty: Yup.object().shape({
    /* loyaltyId: Yup.string()
      .nullable()
      .max(9, "Users.Errors.loyaltyIdMaxLength")
      .matches(/^[a-zA-Z0-9]*$/, "Users.Errors.invalidLoyaltyId")
      .when(['selectedLoyaltyProgramValue'], {
        is: (selectedLoyaltyProgramValue) => {
          console.log("validate=",document?.activeElement?.id + " + " +selectedLoyaltyProgramValue,);
          return document?.activeElement?.id === "btnBookForOthers" ? false : (selectedLoyaltyProgramValue===LoyaltyProgramType.ET || selectedLoyaltyProgramValue===LoyaltyProgramType.ZL)},
        then: Yup.string().required("Users.Errors.loyaltyIdRequired").nullable(),
        otherwise: Yup.string().nullable(),
      }), */

    loyaltyId: Yup.string()
    .max(9, "Users.Errors.loyaltyIdMaxLength")
    .nullable()
    .test(
      "testLoyaltyIdRequired",
      "Users.Errors.loyaltyIdRequired",
      function (value) {
        if (this.parent != null ) {
          //console.log("----related info=",this.parent.selectedLoyaltyProgramValue + "+" + this.parent.loyaltyId+"+"+value+"+"+document?.activeElement?.id);
          if(this.parent.selectedLoyaltyProgramValue !== LoyaltyProgramType.pleaseSelect && !value)
            return false;
          else
            return true;
        }
        else
          return true;
      }
    )
    .test(
      "testLoyaltyValid",
      "Users.Errors.invalidLoyaltyId",
      function (value) {
        if (this.parent != null && value) {
          const exp = /^[a-zA-Z0-9]*$/;
          return exp.test(value);
        }
        else
          return true;
      }
    )
  }),
  
});


export const ValidateFlightNumber = (flightNumber: string): ValidationResult =>{
  var result : ValidationResult = { isInValid: false, message:''}
  // restored the below changes suggested in 23/05/23 in AC
  //const exp =/[A-Z0-9]*[A-Z][A-Z0-9]*[0-9][A-Z0-9]*$/
  const exp =  /^[a-zA-Z0-9]*$/
  if(!flightNumber)
  {
     result = {isInValid: false, message:''}
     return result;
  }
  if(flightNumber.length < 3){
    result = {isInValid: true, message:'Users.Errors.flightNumberLength'}
    return result;
  }
  if(flightNumber.length > 9){
    result = {isInValid: true, message:'Users.Errors.flightNumberLength'}
    return result;
  }
  // restored the below changes suggested in 23/05/23 in AC
    if(!exp.test(flightNumber)){
     result = {isInValid: true, message:'Users.Errors.flightNumberIncorrect'}
     return result;
    }
  return result;
}

export const ValidatePhoneNumber = (number: string): ValidationResult =>{
  var result : ValidationResult = { isInValid: false, message:''}
  const exp = /^[0-9]*$/;
  if(!number)
  {
     result = {isInValid: true, message:'Users.Errors.preferredPhoneRequired'}
     return result;
  }
  if(number.length < 2){
    result = {isInValid: true, message:'Users.Errors.preferPhoneMaxLength'}
    return result;
  }
  if(number.length > 17){
    result = {isInValid: true, message:'Users.Errors.preferPhoneMaxLength'}
    return result;
  }
   if(!exp.test(number)){
    result = {isInValid: true, message:'Users.Errors.preferredPhoneRequired'}
    return result;
   }
  return result;
}
export const ValidateCountryCode = (number: string): ValidationResult =>{
  var result : ValidationResult = { isInValid: false, message:''}
  if(!number)
  {
     result = {isInValid: true, message:'Users.Errors.preferredCountryCodeRequired'}
     return result;
  }
  if(!(number.length > 0 && number !== "+")){
    result = {isInValid: true, message:'Users.Errors.preferredCountryCodeRequired'}
    return result;
  }
  return result;
}

export const ValidateAltPhoneNumber = (selectedCode: string, number: string): ValidationResultExtended =>{
  var result : ValidationResultExtended = { isInValidCode: false, messageCode:'', isInValidNumber:false, messageNumber:''}
  const exp = /^[0-9]*$/;
  if(!number){
    if(selectedCode){
      result = {...result, isInValidNumber: true, messageNumber:'Users.Errors.alternatePhoneRequired'}
      return result;
    }
  }
  if(number)
  {
    if(!selectedCode)
    {
     result = {...result, isInValidCode: true, messageCode:'Users.Errors.alternateCountryCodeRequired'}
     return result;
    }
     if(number.length < 2){
      result = {...result, isInValidNumber: true, messageNumber:'Users.Errors.preferPhoneMaxLength'}
      return result;
    }
    if(number.length > 17){
      result = {...result, isInValidNumber: true, messageNumber:'Users.Errors.preferPhoneMaxLength'}
      return result;
    }
     if(!exp.test(number)){
      result = {...result, isInValidNumber: true, messageNumber:'Users.Errors.alternatePhoneRequired'}
      return result;
     }
  } 


  return result;
}

export const ValidateAltCountryCode = (selectedCode: string, number: string): ValidationResultExtended =>{
  var result : ValidationResultExtended = { isInValidCode: false, messageCode:'', isInValidNumber:false, messageNumber:''}
  const exp = /^[0-9]*$/;
  if(number)
  {
    if(!(selectedCode?.length > 0 && selectedCode !== "+")){
      result = {...result, isInValidCode: true, messageCode:'Users.Errors.alternateCountryCodeRequired'}
      return result;
    }
  }
  if(!number){
    if(selectedCode){
      result = {...result, isInValidNumber: true, messageNumber:'Users.Errors.alternatePhoneRequired'}
      return result;
    }
  }
  if(number){
    if(selectedCode){
      if(number.length < 2){
        result = {...result, isInValidNumber: true, messageNumber:'Users.Errors.preferPhoneMaxLength'}
        return result;
      }
      if(number.length > 17){
        result = {...result, isInValidNumber: true, messageNumber:'Users.Errors.preferPhoneMaxLength'}
        return result;
      }
       if(!exp.test(number)){
        result = {...result, isInValidNumber: true, messageNumber:'Users.Errors.alternatePhoneRequired'}
        return result;
       }
    }
  }
 
  return result;
}


export const reservationDriversValidationSchema = Yup.object().shape({  
  contactPhoneNumber: Yup.object().shape({
    selectedInternationalDiallingCode: Yup.string()
    .required("Users.Errors.preferredCountryCodeRequired")
    .test(
        "selectedInternationalDiallingCode",
        "Users.Errors.preferredCountryCodeRequired",
        (value) => {
          if (value) {
            return value.length > 0 && value !== "+";
          } else return false;
        }
      ),
    number: Yup.string()
      .required("Users.Errors.preferredPhoneRequired")
      .min(2, "Users.Errors.preferPhoneMaxLength")
      .max(17, "Users.Errors.preferPhoneMaxLength")
      .test("number", "Users.Errors.preferredPhoneRequired", (value) => {
        let exp = /^[0-9]*$/;
        if (value) {
          return exp.test(value);
        } else return false;
      }),
  }),
  alternatePhoneNumber: Yup.object().shape(
    {
       selectedInternationalDiallingCode: Yup.string()
        .ensure()
        .when(["number"], {
          is: (number) => number,
          then: Yup.string()
            .notRequired()
            .nullable()
            .test(
              "selectedInternationalDiallingCode",
              "Users.Errors.alternateCountryCodeRequired",
              (value) => {
                if (value) {
                  return value.length > 0 && value !== "+";
                } else return false;
              }
            ),
        }),
      number: Yup.string()
        .ensure()
        .when(["selectedInternationalDiallingCode"], {
          is: (selectedInternationalDiallingCode) =>
            selectedInternationalDiallingCode &&
            selectedInternationalDiallingCode.length > 0 &&
            selectedInternationalDiallingCode !== "+",
          then: Yup.string()
            .notRequired()
            .nullable()
            .test(
              "number",
              "Users.Errors.alternatePhoneRequired",
              (value) => {
                let exp = /^[0-9]*$/;
                if (value) {
                  return exp.test(value);
                } else return false;
              }
            )
            .min(2, "Users.Errors.preferPhoneMaxLength")
            .max(17, "Users.Errors.preferPhoneMaxLength"),
        }),
    },
    [["selectedInternationalDiallingCode", "number"]]
  ),
});
