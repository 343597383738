import { CarouselState } from "../models";
import { CarouselAction } from "../models/CarouselAction.enum";

export function reducer(state: CarouselState, action: any) {
  switch (action.type) {
    case "PROGRESS":
    case "NEXT":
      return {
        ...state,
        takeFocus: false,
        isPlaying: action.type === CarouselAction.PROGRESS,
        currentIndex: (state.currentIndex + 1) % state.slidesLength
      };
    case "PREV":
      return {
        ...state,
        takeFocus: false,
        isPlaying: false,
        currentIndex:
          (state.currentIndex - 1 + state.slidesLength) % state.slidesLength
      };
    case "PLAY":
      return {
        ...state,
        takeFocus: false,
        isPlaying: true
      };
    case "PAUSE":
      return {
        ...state,
        takeFocus: false,
        isPlaying: false
      };
    case "GOTO":
      return {
        ...state,
        takeFocus: true,
        currentIndex: action.index,
        isPlaying: false
      };
    default:
      return state;
  }
}
