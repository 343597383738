import * as Yup from "yup";
import { LoyaltyProgramType } from "../Reservation/reservationFormType";

function validateSelectField(value) {
  if (
    typeof value["selectedValue"] === "undefined" ||
    value["selectedValue"] === ""
  )
    return false;
  else return true;
}

export const driverDetailsValidationSchema = Yup.object().shape({
  driver: Yup.object().shape({
    firstName: Yup.string()
      .required("Users.Errors.firtNameRequired")
      .max(20, "Users.Errors.firstNameMaxLength")
      .nullable(),
    lastName: Yup.string()
      .required("Users.Errors.lastNameRequired")
      .max(30, "Users.Errors.lastNameMaxLength")
      .nullable(),
    emailAddress: Yup.string()
      .required("Users.Errors.emailRequired")
      .email("Users.Errors.invalidEmail")
      .max(80, "Users.Errors.emailMaxLength")
      .nullable(),
  }),

  loyalty: Yup.object().shape({
    loyaltyId: Yup.string()
      .max(9, "Users.Errors.loyaltyIdMaxLength")
      .nullable()
      .test(
        "testLoyaltyIdRequired",
        "Users.Errors.loyaltyIdRequired",
        function (value) {
          if (this.parent != null) {        
            
            if (
              this.parent.selectedLoyaltyProgramValue !==
                LoyaltyProgramType.pleaseSelect && (value === undefined || value === null || value === "")
            )
            {         
              
                return false;
            }
            else 
            {      return true; }
          } else {    return true;}
        }
      )
      .test(
        "testLoyaltyValid",
        "Users.Errors.invalidLoyaltyId",
        function (value) {
          if (this.parent != null && value) {
            const exp = /^[a-zA-Z0-9]*$/;
            return exp.test(value);
          } else return true;
        }
      ),
    selectedLoyaltyProgramValue: Yup.string()
      .nullable()
      .test(
        "testLoyaltyProgramValueRequired",
        "Users.Errors.loyaltyProgramValueRequired",
        function (value) {
          if (this.parent != null) {
            if (LoyaltyProgramType.pleaseSelect && !value) return false;
            else return true;
          } else return true;
        }
      ),
  }),
  isValidDriver : Yup.boolean()
  .test(
    "testAllDriverFieldsRequired", "Users.Errors.requiedFieldsMissing",
    function (value) {
      if (this.parent != null ) {
       // console.log("Validating isValidDriver: ", this.parent.driver);
          return this.parent.driver.firstName!== "" && this.parent.driver.lastName!== "" && this.parent.driver.emailAddress!== "" ;
      } else return false;
    }
  ),
  isValidLoyalty : Yup.boolean().test("testAllLoyaltyFieldsRequired", "Users.Errors.requiredFieldsMissing", function (value) {
    // if (this.parent != null ) {
    //    console.log("isValidaLoyalty: ", this.parent.loyalty, value);
    //   //   return this.parent.driver.firstName!== "" && this.parent.driver.lastName!== "" && this.parent.driver.emailAddress!== "" ;
    //  } 
  return true;
  }
  )
});
