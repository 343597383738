import React, { useEffect, useState } from "react";
import { useRef } from "react";
import makeStyles from '@mui/styles/makeStyles';
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useTranslation } from "react-i18next";
import { UserDetail } from "./usertype";
import { useNavigate } from "react-router-dom";

import { useLocation } from "react-router-dom";
import {
  Box,
  FormControl,
  Backdrop,
  Dialog,
  DialogContent,
  Button,
  Divider,
  Grid,
  DialogActions,
  Typography,
  Select,
  CircularProgress,
  TextField,
  Checkbox,
  Autocomplete,
} from "@mui/material";
import axiosInstance from "helpers/axiosInstance";
import {
  showSuccessAlertMessage,
  showErrorAlertMessage,
  clearAlertMessage,
} from "../../components/store/actions/shared/alertActions";
import { broadcastAnnouncement } from "../store/actions/shared/announcementActions";
import { useDispatch } from "react-redux";
import { authService } from "services/authService";
import { InitialValue } from "./usertype";
import { userValidationSchema } from "./userValidationSchema";
import { appInsights } from "helpers/appInsights";
import { SeverityLevel } from "@microsoft/applicationinsights-common";
import {
  Permissions,
  getUserPermissionsStatus,
  getUserPermissionsValue,
} from "helpers/getUserPermissions";

const useStyles = makeStyles({
  root: {
    "& .MuiFormHelperText-root": {
      color: "#DF0000",
      fontSize: 12,
      marginLeft: "15px",
      display: "none",
    },
    "& ::placeholder": {
      fontStyle: "italic",
      color: "#000",
      opacity: 0.95,
      whiteSpace: "pre-wrap",
      "@media (max-width:330px)": {
        fontSize: "0.6rem",
      },
    },
  },
  outlinedButton: {
    border: "1px solid rgba(0, 102, 57, 0.95);",
  },
  inputRoot: {
    fontSize: 30,
  },
  labelRoot: {
    fontSize: 30,
    color: "red",
    "&$labelFocused": {
      color: "purple",
    },
  },
  errorWarning: {
    color: "#DF0000",
    fontSize: 12,
    marginLeft: "15px",
  },
  backdrop: {
    zIndex: 99999,
    color: "#fff",
  },
  labelFocused: {},
});
export default function ViewUser() {

  interface CompanyItem
{
  disabled: boolean;
  group: null;
  selected: boolean;
  text: string;
  value: string;
}

  const [loading, setLoading] = useState(false);
  const [userDetail, setuserDetail] = useState<UserDetail>(InitialValue);
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [activateDlgopen, setActivateDlgOpen] = useState(false);

  let history = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const queryParams = new URLSearchParams(location.search);

  const classes = useStyles();
  const inputRef = useRef<HTMLInputElement>(null);
  const btnActivateDlgRef = useRef<HTMLButtonElement>(null);
  const btnResetPassDlgRef = useRef<HTMLButtonElement>(null);
  const [companyUId, setCompanyUid] = useState<string>("");
  const [
    isCompanyDriverValidityActivated,
    setIsCompanyDriverValidityActivated,
  ] = useState<boolean>(false);
  const [boboEnabled, setBoboEnabled] = useState<boolean>(false);

  const getCompanyProfile = async (companyUId) => {
    await axiosInstance
      .get(`/company/getCompanyProfileByUId/${companyUId}`)
      .then((response) => {
        setIsCompanyDriverValidityActivated(
          response.data?.isCompanyDriverValidityActivated ?? false
        );
        setBoboEnabled(response.data?.boboEnabled ?? false);
      })
      .catch((error) => {
        setIsCompanyDriverValidityActivated(false);
      });
  };

  const setNullValuesToEmpty = (obj) =>
    JSON.parse(JSON.stringify(obj, (k, v) => (v === null ? "" : v)));

  const getUserDetail = async (uid) => {
    setLoading(true);
    appInsights.trackTrace({
      message: "getting existing user details",
      properties: { Component: "viewUser" },
      severityLevel: SeverityLevel.Information,
    });
    try {
      const result = await axiosInstance.get(
        "/identity/user?uid=" + encodeURIComponent(uid)
      );
      console.log("User details: ", result.data);
      setuserDetail(setNullValuesToEmpty(result.data));
      setCompanyUid(result.data?.companyName?.selectedValue ?? "");

      let companyList = result.data.companyName?.items?.map(item => {
        let list: CompanyItem = {
        "disabled":item.disabled,
        "group":item.group,
        "selected": item.selected,
        "text" :item.text,
        "value": item.value,
      }
        return list
    })    

    setCompanyList(companyList?? []);

    let selectedCompany = companyList?.find(obj=>obj.value===result.data?.companyName?.selectedValue);

    setSelectedCompany(selectedCompany);

      setLoading(false);
    } catch (err) {
      setLoading(false);
      dispatch(
        showErrorAlertMessage(
          "There was some problem. please contact support team"
        )
      );

      console.log(err);
    }
  };

  const updateDriverId = async (companyId, setValue) => {
    const result = await axiosInstance.get(
      "/identity/getcarclubcompanyid/" + companyId
    );
    if (result.data !== null) {
      console.log(result.data.data);
      setValue("carClubDriver", {
        carClubCompanyId: result.data.data.toString(),
        carClubDriverId: "",
      });
    } else {
      setValue("carClubDriver", { carClubCompanyId: "", carClubDriverId: "" });
    }
  };
  const handleCancel = () => {
    history("/admin/users");
  };

  const handleActivateDialogOpen = () => {
    setActivateDlgOpen(true);
  };

  const handleDialogClose = () => {
    setOpen(false);
    setActivateDlgOpen(false);
  };

  const initialCompanyItem: CompanyItem =
  {
    disabled: false,
    group: null,
    selected: false,
    text: "Please Select a Company",
    value: "-1",
  }

  
  const [companyList, setCompanyList] = useState<CompanyItem[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<CompanyItem>(initialCompanyItem);

  const handleActivateDeActivate = async (user, activate, b2cObjectid) => {
    const model = {
      username: user,
      activate: activate,
      b2CObjectId: b2cObjectid,
    };
    setLoading(true);
    setActivateDlgOpen(false);
    appInsights.trackEvent({
      name: "Activate/Deactivate User Event",
      properties: { Component: "ViewUser" },
    });
    console.log("Model: ", model);
    await axiosInstance
      .post("/identity/activate", model)
      .then(async (res) => {
        dispatch(broadcastAnnouncement(""));
        await getUserDetail(user);
        dispatch(
          showSuccessAlertMessage(
            (activate
              ? t("Users.ActivateUserSuccess")
              : t("Users.DeactivateUserSuccess")) +
              " : " +
              user
          )
        );
        dispatch(
          broadcastAnnouncement(t("Users.DeactivateUserSuccess") + " : " + user)
        );
        appInsights.trackTrace({
          message: "user activated successfully",
          properties: { Component: "ViewUser" },
          severityLevel: SeverityLevel.Information,
        });
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setActivateDlgOpen(false);
        dispatch(
          showErrorAlertMessage(
            "There was some problem. please contact support team"
          )
        );
        appInsights.trackException({
          exception: err,
          properties: {
            method: "handleActivateDeactivate",
            Component: "ViewUser",
          },
          severityLevel: SeverityLevel.Error,
        });
        console.log(err);
      });
  };
  const onEnterActivateDialog = () => {
    if (null !== btnActivateDlgRef.current) {
      btnActivateDlgRef.current.focus();
    }
  };
  const onEnterResetPasswordDialog = () => {
    if (null !== btnResetPassDlgRef.current) {
      btnResetPassDlgRef.current.focus();
    }
  };
  const handleResetPassword = async (user) => {
    const model = { username: user };
    setLoading(true);
    setOpen(false);
    await axiosInstance
      .post("/user/resetpassword", model)
      .then((res) => {
        dispatch(showSuccessAlertMessage(t("Users.ResetPasswordNotification")));
        dispatch(broadcastAnnouncement(t("Users.ResetPasswordNotification")));
        setLoading(false);
      })
      .catch((err) => {
        dispatch(
          showErrorAlertMessage(
            "There was some problem. please contact support team"
          )
        );
        console.log(err);
        setLoading(false);
        appInsights.trackException({
          exception: err,
          properties: { method: "handleResetPassword", Component: "ViewUser" },
          severityLevel: SeverityLevel.Error,
        });
      });
  };

  useEffect(() => {
    if (companyUId) getCompanyProfile(companyUId);
  }, [companyUId]);

  useEffect(() => {
    document.title =
      "Enterprise Rent-A-Car - " + t("Users.updateUserPageTitle");
    if (queryParams.get("isnew") && queryParams.get("isnew") !== "1")
      dispatch(clearAlertMessage());

    const fetchUserDetails = async () => {
      await getUserDetail(queryParams.get("uid"));
    };

    // call the function
    fetchUserDetails()
      // make sure to catch any error
      .catch(console.error);
  }, []);

  return (
    <Box data-testid="viewUserBox" id="viewUserBox" boxShadow={3} p={3} bgcolor="background.paper">
      <Formik
        initialValues={loading ? InitialValue : userDetail}
        validateOnChange={true}
        validateOnBlur={false}
        enableReinitialize={true}
        validationSchema={userValidationSchema}
        onSubmit={async (values, { setErrors, setSubmitting }) => {
          dispatch(broadcastAnnouncement(t("")));
          await axiosInstance
            .post("/identity/update", values)
            .then(async (res) => {
              setSubmitting(false);
              dispatch(
                showSuccessAlertMessage(
                  t("Users.updateUserSuccess") + ": " + values.username
                )
              );
              dispatch(broadcastAnnouncement(t("Users.saveUserSuccess")));

              const query = new URLSearchParams(location.search);
              if (query && query.get("uid")) {
                await getUserDetail(query.get("uid"));
              }
            })
            .catch((err) => {
              setSubmitting(false);
              if (err.response && err.response.data.messages) {
                let errorlist = err.response.data.messages.filter(
                  (n) => n !== ""
                );
                console.log(errorlist);
                const translatedErrorlist = errorlist
                  .map(function (value) {
                    return t(value);
                  })
                  //.join("\r\n");
                dispatch(showErrorAlertMessage(translatedErrorlist));
                dispatch(broadcastAnnouncement(translatedErrorlist));
              } else {
                dispatch(showErrorAlertMessage("unable to save the user."));
                dispatch(broadcastAnnouncement("unable to save the user."));
              }
              appInsights.trackException({
                exception: err,
                properties: { method: "onSubmit", Component: "ViewUser" },
                severityLevel: SeverityLevel.Error,
              });
              /*   setErrors({
                username: "invalid username",
                firstName: "ivalid first name",
              });*/
            });

          /*   if (null !== inputRef.current) {
            inputRef.current.focus();
          }*/
          window.scrollTo(0, 0);
        }}
      >
        {({
          values,
          isValid,
          dirty,
          setFieldValue,
          setFieldTouched,
          touched,
          handleChange,
          errors,
          isSubmitting,
        }) => {
          return (
            <Grid container>
              <Dialog
                aria-labelledby="resetPassword-Dialog-title"
                open={open}
                role="dialog"
                TransitionProps={{
                  onEnter: onEnterResetPasswordDialog,
                }}>
                <DialogContent id="resetPassword-Dialog-title">
                  {t("Users.ResetPasswordConfirmationFormat")} {values.username}{" "}
                  {"?"}
                  <Divider
                    style={{
                      marginTop: 20,
                      marginBottom: 10,
                      backgroundColor: "black",
                      height: "0.8px",
                    }}
                  />
                </DialogContent>
                <DialogActions>
                  <Button
                    color="primary"
                    variant="contained"
                    id="okDialogButton"
                    type="submit"
                    ref={btnResetPassDlgRef}
                    onClick={() => handleResetPassword(values.username)}
                    style={{ textTransform: "none" }}
                  >
                    {t("Users.btnOK")}
                  </Button>
                  <Button
                    classes={{ outlinedPrimary: classes.outlinedButton }}
                    color="primary"
                    variant="outlined"
                    id="cancelDialogButton"
                    type="button"
                    onClick={handleDialogClose}
                    style={{ marginLeft: "0.5em", textTransform: "none" }}
                  >
                    {t("Users.btnCancel")}
                  </Button>
                </DialogActions>
              </Dialog>
              <Dialog
                aria-labelledby="activate-deactivate-user-title"
                open={activateDlgopen}
                role="dialog"
                TransitionProps={{
                  onEnter: onEnterActivateDialog,
                }}
              >
                <DialogContent id="activate-deactivate-user-title">
                  {values.isActive
                    ? t("Users.DeactivateUserConfirmationFormat")
                    : t("Users.ActivateUserConfirmationFormat")}
                  {values.username} {"?"}
                  <Divider
                    style={{
                      marginTop: 20,
                      marginBottom: 10,
                      backgroundColor: "black",
                      height: "0.8px",
                    }}
                  />
                </DialogContent>
                <DialogActions>
                  <Button
                    color="primary"
                    variant="contained"
                    id="okDialogButton"
                    type="submit"
                    ref={btnActivateDlgRef}
                    onClick={() => {
                      handleActivateDeActivate(
                        values.username,
                        !values.isActive,
                        values.b2CObjectId
                      );
                    }}
                    style={{ textTransform: "none" }}
                  >
                    {t("Users.btnOK")}
                  </Button>
                  <Button
                    classes={{ outlinedPrimary: classes.outlinedButton }}
                    color="primary"
                    id="cancelDialogButton"
                    variant="outlined"
                    type="button"
                    onClick={handleDialogClose}
                    style={{ marginLeft: "0.5em", textTransform: "none" }}
                  >
                    {t("Users.btnCancel")}
                  </Button>
                </DialogActions>
              </Dialog>
              <Grid item xs={12} md={6}>
                <Typography id="titleHeader" variant="h1">
                  {t("Users.updatePageHeading")}
                  {values.username}
                </Typography>
              </Grid>
              <Backdrop
                className={classes.backdrop}
                open={loading || isSubmitting}
              >
                <CircularProgress />
              </Backdrop>
              <Grid
                container
                item
                xs={12}
                md={6}
                justifyContent="flex-end"
                style={{ lineHeight: "13em" }}
              >
                <Button
                  color="primary"
                  variant="contained"
                  id="btnActivateDeactivate"
                  type="button"
                  onClick={() => handleActivateDialogOpen()}
                  style={{
                    marginTop: "0.5em",
                    marginLeft: "0.5em",
                    textTransform: "none",
                  }}
                >
                  {values.isActive
                    ? t("Users.btnDeActivate")
                    : t("Users.btnActivate")}
                </Button>
              </Grid>
              <Grid
                container
                direction="row"
                style={{ marginTop: "1em" }}
                className={classes.root}
              >
                <Form style={{ width: "100%" }}>
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      style={{
                        paddingBottom: 0,
                        paddingTop: 10,
                        marginBottom: "1em",
                      }}
                    >
                      <FormControl variant="outlined" fullWidth>
                        <Typography style={{ fontWeight: "bold" }}>
                          {t("Users.title")}
                        </Typography>
                        <Select
                          inputRef={inputRef}
                          native
                          value={values.userTitle.selectedValue}
                          onChange={(opt) => {
                            let text = values.userTitle.items?.find(
                              (x) => x.value === opt.target.value
                            )?.text;
                            setFieldValue("userTitle.selectedText", text);
                            setFieldValue(
                              "userTitle.selectedValue",
                              opt.target.value
                            );
                            setFieldTouched("userTitle");
                          }}
                          fullWidth
                          inputProps={{
                            id: "userTitle",
                            name: "userTitle",
                            title: "select a title",
                            "aria-label": t("Users.title"),
                          }}
                          name="userTitle"
                          error={
                            errors["userTitle"] !== undefined ? true : false
                          }
                        >
                          {values.userTitle.items?.map((opt: any) => (
                            <option key={opt.value} value={opt.value}>
                              {opt.text}
                            </option>
                          ))}
                        </Select>
                        {errors.userTitle && touched.userTitle ? (
                          <span className={classes.errorWarning}>
                            {t(errors["userTitle"].toString())}
                          </span>
                        ) : (
                          ""
                        )}
                      </FormControl>
                    </Grid>
                    <Grid
                      style={{
                        paddingBottom: 0,
                        paddingTop: 10,
                        marginBottom: "1em",
                      }}
                      item
                      xs={12}
                    >
                      <Typography style={{ fontWeight: "bold" }}>
                        {t("Users.firstname")}
                      </Typography>
                      <Field
                        name="firstName"
                        id="firstName"
                        fullWidth
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        inputProps={{
                          "aria-label": t("Users.firstname"),
                          "aria-required": true,
                        }}
                        value={values.firstName}
                        onChange={(e) => {
                          setFieldValue("firstName", e.target.value);
                          setFieldTouched("firstName");
                        }}
                        onBlur={() => {
                          setFieldTouched("firstName");
                        }}
                        component={TextField}
                        variant="outlined"
                        placeholder={t("Users.firstnamePlaceholder")}
                        error={errors["firstName"] !== undefined ? true : false}
                      />
                      <ErrorMessage name="firstName">
                        {(msg) => (
                          <span className={classes.errorWarning}>{t(msg)}</span>
                        )}
                      </ErrorMessage>
                    </Grid>
                    <Grid
                      style={{
                        paddingBottom: 0,
                        paddingTop: 10,
                        marginBottom: "1em",
                      }}
                      item
                      xs={12}
                    >
                      <Typography style={{ fontWeight: "bold" }}>
                        {t("Users.lastname")}
                      </Typography>
                      <Field
                        name="lastName"
                        id="lastName"
                        fullWidth
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        inputProps={{
                          "aria-label": t("Users.lastname"),
                          "aria-required": true,
                        }}
                        value={values.lastName}
                        onChange={(e) => {
                          setFieldValue("lastName", e.target.value);
                          setFieldTouched("lastName");
                        }}
                        onBlur={() => {
                          setFieldTouched("lastName");
                        }}
                        component={TextField}
                        variant="outlined"
                        placeholder={t("Users.lastnamePlaceholder")}
                        error={errors["lastName"] !== undefined ? true : false}
                      />
                      <ErrorMessage name="lastName">
                        {(msg) => (
                          <span className={classes.errorWarning}>{t(msg)}</span>
                        )}
                      </ErrorMessage>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      style={{
                        paddingBottom: 0,
                        paddingTop: 10,
                        marginBottom: "1em",
                      }}
                    >
                      <Typography style={{ fontWeight: "bold" }}>
                        {t("Users.emailaddress")}
                      </Typography>
                      <Field
                        name="email"
                        id="email"
                        fullWidth
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        inputProps={{
                          "aria-label": t("Users.emailaddress"),
                          "aria-required": true,
                        }}
                        value={values.email}
                        onChange={(e) => {
                          setFieldValue("email", e.target.value);
                          setFieldTouched("email");
                        }}
                        onBlur={() => {
                          setFieldTouched("email");
                        }}
                        component={TextField}
                        variant="outlined"
                        placeholder={t("Users.emailPlaceholder")}
                        error={errors["email"] !== undefined ? true : false}
                      />
                      <ErrorMessage name="email">
                        {(msg) => (
                          <span className={classes.errorWarning}>{t(msg)}</span>
                        )}
                      </ErrorMessage>
                    </Grid>
                    <Grid
                      container
                      spacing={3}
                      style={{
                        paddingBottom: 0,
                        paddingTop: 10,
                        marginBottom: "1em",
                      }}
                    >
                      <Grid item xs={12} md={12}>
                        <Typography style={{ fontWeight: "bold" }}>
                          {t("Users.preferredPhoneNumber")}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={3}
                        style={{
                          paddingBottom: 0,
                          paddingTop: 0,
                          marginBottom: "1em",
                        }}
                      >
                        <FormControl
                          variant="outlined"
                          fullWidth
                          sx={{ height: "100%" }}
                        >
                          <Select
                            native
                            value={
                              values.preferredPhoneNumber
                                ?.selectedInternationalDiallingCode
                            }
                            onChange={handleChange}
                            fullWidth
                            inputProps={{
                              id: "preferredPhoneNumber.selectedInternationalDiallingCode",
                              name: "preferredPhoneNumber.selectedInternationalDiallingCode",
                              title: t("Users.countryCodeForPreferredNumber"),
                              "aria-label": t(
                                "Users.countryCodeForPreferredNumber"
                              ),
                            }}
                            name="preferredPhoneNumber.selectedInternationalDiallingCode"
                            error={
                              errors["preferredPhoneNumber"] !== undefined &&
                              errors["preferredPhoneNumber"][
                                "selectedInternationalDiallingCode"
                              ] !== undefined
                                ? true
                                : false
                            }
                          >
                            {" "}
                            <option key="" value="">
                              -
                            </option>
                            {values.preferredPhoneNumber?.availableInternationalDiallingCodes?.map(
                              (opt: any) => (
                                <option key={opt.value} value={opt.value}>
                                  {opt.text}
                                </option>
                              )
                            )}
                          </Select>
                          {errors["preferredPhoneNumber"] !== undefined &&
                          errors["preferredPhoneNumber"][
                            "selectedInternationalDiallingCode"
                          ] !== undefined ? (
                            <span
                              id="preferredPhoneNumber.selectedInternationalDiallingCodeError"
                              className={classes.errorWarning}
                            >
                              {t(
                                errors["preferredPhoneNumber"][
                                  "selectedInternationalDiallingCode"
                                ]
                              )}
                            </span>
                          ) : (
                            <span id="preferredPhoneNumber.selectedInternationalDiallingCodeError"></span>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={9}
                        style={{
                          paddingBottom: 0,
                          paddingTop: 0,
                          marginBottom: "1em",
                        }}
                      >
                        <Field
                          name="preferredPhoneNumber.number"
                          id="preferredPhoneNumber.number"
                          fullWidth
                          style={{ paddingBottom: 0, paddingTop: 0 }}
                          inputProps={{
                            "aria-label": t("Users.preferredPhoneNumber"),
                          }}
                          value={values.preferredPhoneNumber?.number}
                          onChange={(e) => {
                            setFieldValue(
                              "preferredPhoneNumber.number",
                              e.target.value
                            );
                            setFieldTouched("preferredPhoneNumber.number");
                          }}
                          component={TextField}
                          variant="outlined"
                          error={
                            errors["preferredPhoneNumber"] !== undefined &&
                            errors["preferredPhoneNumber"]["number"] !==
                              undefined
                              ? true
                              : false
                          }
                          placeholder={t("Users.preferredPhonePlaceholder")}
                        />
                        {errors["preferredPhoneNumber"] !== undefined &&
                        errors["preferredPhoneNumber"]["number"] !==
                          undefined ? (
                          <span
                            id="preferredPhoneNumber.number"
                            className={classes.errorWarning}
                          >
                            {t(errors["preferredPhoneNumber"]["number"])}
                          </span>
                        ) : (
                          <span id="preferredPhoneNumber.number"></span>
                        )}
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      spacing={3}
                      style={{ paddingBottom: 0, marginBottom: "1em" }}
                    >
                      <Grid item xs={12} md={12}>
                        <Typography style={{ fontWeight: "bold" }}>
                          {t("Users.alternativePhoneNumber")}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={3}
                        style={{
                          paddingBottom: 0,
                          paddingTop: 0,
                          marginBottom: "1em",
                        }}
                      >
                        <FormControl
                          variant="outlined"
                          fullWidth
                          sx={{ height: "100%" }}
                        >
                          <Select
                            native
                            value={
                              values.alternativePhoneNumber
                                ?.selectedInternationalDiallingCode
                            }
                            onChange={handleChange}
                            fullWidth
                            inputProps={{
                              id: "alternativePhoneNumber.selectedInternationalDiallingCode",
                              name: "alternativePhoneNumber.selectedInternationalDiallingCode",
                              title: t("Users.countryCodeForAlternateNumber"),
                              "aria-label": t(
                                "Users.countryCodeForAlternateNumber"
                              ),
                            }}
                            name="alternativePhoneNumber.selectedInternationalDiallingCode"
                            error={
                              errors["alternativePhoneNumber"] !== undefined &&
                              errors["alternativePhoneNumber"][
                                "selectedInternationalDiallingCode"
                              ] !== undefined
                                ? true
                                : false
                            }
                          >
                            <option key="" value="">
                              -
                            </option>
                            {values.alternativePhoneNumber?.availableInternationalDiallingCodes?.map(
                              (opt: any) => (
                                <option key={opt.value} value={opt.value}>
                                  {opt.text}
                                </option>
                              )
                            )}
                          </Select>
                          {errors["alternativePhoneNumber"] !== undefined &&
                          errors["alternativePhoneNumber"][
                            "selectedInternationalDiallingCode"
                          ] !== undefined ? (
                            <span
                              id="alternativePhoneNumber.selectedInternationalDiallingCodeError"
                              className={classes.errorWarning}
                            >
                              {t(
                                errors["alternativePhoneNumber"][
                                  "selectedInternationalDiallingCode"
                                ]
                              )}
                            </span>
                          ) : (
                            <span id="alternativePhoneNumber.selectedInternationalDiallingCodeError"></span>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={9}
                        style={{
                          paddingBottom: 0,
                          paddingTop: 0,
                          marginBottom: "1em",
                        }}
                      >
                        <Field
                          name="alternativePhoneNumber.number"
                          id="alternativePhoneNumber.number"
                          fullWidth
                          style={{ paddingBottom: 0, paddingTop: 0 }}
                          inputProps={{
                            "aria-label": t("Users.alternativePhoneNumber"),
                          }}
                          value={values.alternativePhoneNumber?.number}
                          onChange={(e) => {
                            setFieldValue(
                              "alternativePhoneNumber.number",
                              e.target.value
                            );
                            setFieldTouched("alternativePhoneNumber.number");
                          }}
                          component={TextField}
                          variant="outlined"
                          placeholder={t("Users.alternatePhonePlaceholder")}
                          error={
                            errors["alternativePhoneNumber"] !== undefined &&
                            errors["alternativePhoneNumber"]["number"] !==
                              undefined
                              ? true
                              : false
                          }
                        />
                        {errors["alternativePhoneNumber"] !== undefined &&
                        errors["alternativePhoneNumber"]["number"] !==
                          undefined ? (
                          <span
                            id="alternativePhoneNumber.number"
                            className={classes.errorWarning}
                          >
                            {t(errors["alternativePhoneNumber"]["number"])}
                          </span>
                        ) : (
                          <span id="alternativePhoneNumber.number"></span>
                        )}
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      style={{ paddingBottom: 0, marginBottom: "1em" }}
                    >
                      <Typography style={{ fontWeight: "bold" }}>
                        {t("Users.username")}
                      </Typography>
                      <Field
                        name="username"
                        id="username"
                        fullWidth
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        inputProps={{
                          "aria-label": t("Users.username"),
                          "aria-required": true,
                        }}
                        value={values.username}
                        onChange={(e) => {
                          setFieldValue("username", e.target.value);
                          setFieldTouched("username");
                        }}
                        onBlur={() => {
                          setFieldTouched("username");
                        }}
                        component={TextField}
                        variant="outlined"
                        disabled
                        placeholder={t("Users.usernamePlaceholder")}
                      />
                      <ErrorMessage name="username">
                        {(msg) => (
                          <span className={classes.errorWarning}>{t(msg)}</span>
                        )}
                      </ErrorMessage>
                    </Grid>
                    <Grid
                      container
                      spacing={3}
                      style={{
                        paddingBottom: 0,
                        paddingTop: 10,
                        marginBottom: "1em",
                      }}
                    >
                      <Grid item xs={12} md={12}>
                        <Typography style={{ fontWeight: "bold" }}>
                          {t(
                            "ReservationWizard.reservationPage.labelLoyaltyNumbersIfApplicable"
                          )}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={4}
                        style={{
                          paddingLeft: 36,
                          paddingTop: 12,
                          paddingBottom: 0,
                          marginBottom: "1em",
                        }}
                      >
                        <Typography style={{ fontWeight: "bold" }}>
                          {t("Users.emeraldClubNumber")}
                        </Typography>
                        <Field
                          name="emeraldClubNumber"
                          id="emeraldClubNumber"
                          onChange={(opt) => {
                            setFieldValue(
                              "emeraldClubNumber",
                              opt.target.value.slice(0, 9).toUpperCase()
                            );
                          }}
                          fullWidth
                          style={{ paddingBottom: 0, paddingTop: 0 }}
                          inputProps={{
                            "aria-label": t("Users.emeraldClubNumber"),
                            "aria-required": true,
                          }}
                          value={values.emeraldClubNumber}
                          component={TextField}
                          variant="outlined"
                        />
                        <ErrorMessage name="emeraldClubNumber">
                          {(msg) => (
                            <span className={classes.errorWarning}>
                              {t(msg)}
                            </span>
                          )}
                        </ErrorMessage>
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        md={4}
                        style={{
                          paddingLeft: 36,
                          paddingTop: 12,
                          paddingBottom: 0,
                          marginBottom: "1em",
                        }}
                      >
                        <Typography style={{ fontWeight: "bold" }}>
                          {t("Users.enterprisePlusNumber")}
                        </Typography>
                        <Field
                          name="enterprisePlusNumber"
                          id="enterprisePlusNumber"
                          onChange={(opt) => {
                            setFieldValue(
                              "enterprisePlusNumber",
                              opt.target.value.slice(0, 9).toUpperCase()
                            );
                          }}
                          fullWidth
                          maxLength="15"
                          style={{ paddingBottom: 0, paddingTop: 0 }}
                          inputProps={{
                            "aria-label": t("Users.enterprisePlusNumber"),
                            "aria-required": true,
                          }}
                          value={values.enterprisePlusNumber}
                          component={TextField}
                          variant="outlined"
                        />
                        <ErrorMessage name="enterprisePlusNumber">
                          {(msg) => (
                            <span className={classes.errorWarning}>
                              {t(msg)}
                            </span>
                          )}
                        </ErrorMessage>
                      </Grid>
                    </Grid>
                    {/* <Grid
                      item
                      xs={12}
                      style={{
                        paddingBottom: 0,
                        paddingTop: 10,
                        marginBottom: "1em",
                      }}
                    >
                      <FormControl variant="outlined" fullWidth>
                        <Typography style={{ fontWeight: "bold" }}>
                          {t("Users.company")}
                        </Typography>
                        <Select
                          native
                          disabled={authService.isCompanyAdmin()}
                          value={values.companyName?.selectedValue}
                          onChange={async (opt) => {
                            let text = values.companyName?.items?.find(
                              (x) => x.value === opt.target.value
                            )?.text;
                            setFieldValue(
                              "companyName.selectedValue",
                              opt.target.value
                            );
                            setFieldValue("companyName.selectedText", text);
                            setFieldTouched("companyName");
                            await updateDriverId(
                              opt.target.value,
                              setFieldValue
                            );
                            getCompanyProfile(opt.target.value);
                          }}
                          onBlur= {() => {
                            setFieldTouched("companyName");
                          }}
                          fullWidth
                          inputProps={{
                            id: "companyName.selectedValue",
                            name: "companyName.selectedValue",
                            title: "select a company",
                            "aria-label": t("Users.company"),
                            "data-selectedtext":
                              values.companyName?.selectedText,
                          }}
                          name="companyName.selectedValue"
                          id="companyName.selectedValue"
                          error={
                            errors["companyName"] !== undefined ? true : false
                          }
                        >
                          {values.companyName?.items?.map((opt: any) => (
                            <option key={opt.value} value={opt.value}>
                              {opt.text}
                            </option>
                          ))}
                        </Select>
                        <ErrorMessage name="companyName">
                          {(msg) => (
                            <span className={classes.errorWarning}>
                              {t(msg)}
                            </span>
                          )}
                        </ErrorMessage>
                      </FormControl>
                    </Grid> */}
                    <Grid
                      item
                      xs={12}
                      style={{
                        paddingBottom: 0,
                        paddingTop: 10,
                        marginBottom: "2em",
                      }}
                    >
                      <FormControl variant="outlined" fullWidth>
                        <Typography
                          style={{ fontWeight: "bold"}}
                        >
                          {t("Users.company")}
                        </Typography>
                        <Autocomplete
                          fullWidth
                          disabled={authService.isCompanyAdmin()}
                          id="companyName.selectedValue"
                          aria-label={t("Users.company")}
                          options={companyList}
                          autoComplete
                          value={selectedCompany}
                          filterSelectedOptions
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              id={"companyName.selectedValue"}
                              name={"companyName.selectedValue"}
                              title={"select a company"}
                              data-selectedtext={
                                values.companyName?.selectedText
                              }
                              //label={t("Users.company")}
                              variant="outlined"
                            />
                          )}
                          getOptionLabel={(option: CompanyItem) => option.text}
                          isOptionEqualToValue={(option, value) =>
                            option.value === value.value
                          }
                          getOptionDisabled={(option) =>
                            option === companyList[0]
                          }
                          onChange={async (event, value) => {
                            if (value == null) return;

                            let selectedCompany = companyList?.find(
                              (obj) => obj.value === value.value
                            );
                            if (selectedCompany !== undefined) {
                              setSelectedCompany(selectedCompany);
                            }

                            setFieldValue(
                              "companyName.selectedValue",
                              value.value
                            );
                            setFieldValue(
                              "companyName.selectedText",
                              value.text
                            );
                            setFieldTouched("companyName");
                            await updateDriverId(value.value, setFieldValue);
                            getCompanyProfile(value.value);
                          }}
                          onBlur={() => {
                            setFieldTouched("companyName");
                          }}
                        />
                        <ErrorMessage name="companyName">
                          {(msg) => (
                            <span className={classes.errorWarning}>
                              {t(msg)}
                            </span>
                          )}
                        </ErrorMessage>
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      style={{
                        paddingBottom: 0,
                        paddingTop: 10,
                        marginBottom: "1em",
                      }}
                    >
                      <FormControl
                        id="permissionLst"
                        variant="outlined"
                        fullWidth
                        style={{
                          flexDirection: "unset",
                          flexWrap: "wrap",
                        }}
                      >
                        <Typography
                          style={{ fontWeight: "bold", width: "100%" }}
                        >
                          {t("Users.Permissions")}
                        </Typography>
                        {Object.entries(Permissions)
                          .filter(([key]) => {
                            return isNaN(Number(key)) && key !== "None";
                          })
                          .map(([key]) => {
                            const labelId = key + "Label";
                            const checkboxId = key + "Checkox";
                            const selectedPermission: Permissions =
                              Permissions[key];
                            const label = "Users." + key;
                            return (
                              ((values.isBOBOToggleEnabled &&
                                boboEnabled &&
                                key === "BoboEnabled") ||
                                key !== "BoboEnabled") && (
                                <Grid key={key} item xs={12} md={3}>
                                  <label id={labelId}>
                                    <Field
                                      type="checkbox"
                                      name="permissions"
                                      component={Checkbox}
                                      id={checkboxId}
                                      disabled={
                                        isCompanyDriverValidityActivated
                                      }
                                      checked={
                                        isCompanyDriverValidityActivated
                                          ? false
                                          : getUserPermissionsStatus(
                                              values.permissions ?? 0,
                                              selectedPermission
                                            )
                                      }
                                      onChange={(opt) => {
                                        setFieldValue(
                                          "permissions",
                                          getUserPermissionsValue(
                                            values.permissions ?? 0,
                                            selectedPermission,
                                            opt.target.checked === true
                                          )
                                        );
                                        setFieldTouched("permissions");
                                      }}
                                    />
                                    {t(label)}
                                  </label>
                                </Grid>
                              )
                            );
                          })}
                      </FormControl>
                    </Grid>

                    <Grid
                      container
                      spacing={3}
                      style={{
                        paddingBottom: 0,
                        paddingTop: 10,
                        marginBottom: "1em",
                      }}
                    >
                      <Grid item xs={12} md={12}>
                        <Typography style={{ fontWeight: "bold" }}>
                          {t("Users.carClubDriver")}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        md={3}
                        style={{
                          paddingBottom: 0,
                          paddingTop: 0,
                          marginBottom: "1em",
                        }}
                      >
                        <FormControl variant="outlined" fullWidth>
                          <Field
                            disabled
                            name="carClubDriver.carClubCompanyId"
                            id="carClubDriver.carClubCompanyId"
                            fullWidth
                            style={{ paddingBottom: 0, paddingTop: 0 }}
                            inputProps={{
                              "aria-label": t("Users.carClubDriver"),
                            }}
                            value={values.carClubDriver?.carClubCompanyId}
                            onChange={(e) => {
                              setFieldValue(
                                "carClubDriver.carClubCompanyId",
                                e.target.value
                              );
                              setFieldTouched("carClubDriver.carClubCompanyId");
                            }}
                            component={TextField}
                            variant="outlined"
                            placeholder={t("Users.carClubCompanyIdPlaceholder")}
                          />
                        </FormControl>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        md={9}
                        style={{
                          paddingBottom: 0,
                          paddingTop: 0,
                          marginBottom: "1em",
                        }}
                      >
                        <Field
                          name="carClubDriver.carClubDriverId"
                          id="carClubDriver.carClubDriverId"
                          fullWidth
                          style={{ paddingBottom: 0, paddingTop: 0 }}
                          inputProps={{
                            "aria-label": t("Users.carClubDriver"),
                          }}
                          value={values.carClubDriver?.carClubDriverId}
                          onChange={(e) => {
                            setFieldValue(
                              "carClubDriver.carClubDriverId",
                              e.target.value
                            );
                            setFieldTouched("carClubDriver.carClubDriverId");
                          }}
                          component={TextField}
                          variant="outlined"
                          placeholder={t("Users.driverIdPlaceholder")}
                        />
                        <ErrorMessage name="carClubDriver.carClubDriverId">
                          {(msg) => (
                            <span className={classes.errorWarning}>
                              {t(msg)}
                            </span>
                          )}
                        </ErrorMessage>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      style={{ paddingBottom: 0, marginBottom: "1em" }}
                    >
                      <Typography style={{ fontWeight: "bold" }}>
                        {t("Users.lineManageremail")}
                      </Typography>
                      <Field
                        name="lineManagerEmail"
                        id="lineManagerEmail"
                        fullWidth
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        inputProps={{
                          "aria-label": t("Users.lineManageremail"),
                        }}
                        value={values.lineManagerEmail}
                        onChange={(e) => {
                          setFieldValue("lineManagerEmail", e.target.value);
                          setFieldTouched("lineManagerEmail");
                        }}
                        component={TextField}
                        variant="outlined"
                        placeholder={t("Users.linemanagerEmailPlaceholder")}
                      />
                      <ErrorMessage name="lineManagerEmail">
                        {(msg) => (
                          <span className={classes.errorWarning}>{t(msg)}</span>
                        )}
                      </ErrorMessage>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      style={{
                        paddingBottom: 0,
                        paddingTop: 10,
                        marginBottom: "1em",
                      }}
                    >
                      <FormControl
                        variant="outlined"
                        fullWidth
                        error={
                          errors && errors["preferredLanguage.selectedValue"]
                        }
                      >
                        <Typography style={{ fontWeight: "bold" }}>
                          {t("Users.preferredLanguage")}{" "}
                          {/*errors && errors["preferredLanguage.selectedValue"]*/}
                        </Typography>
                        <Select
                          native
                          value={values.preferredLanguage?.selectedValue}
                          onChange={(opt) => {
                            setFieldTouched("preferredLanguage", true);
                            setFieldValue(
                              "preferredLanguage.selectedValue",
                              opt.target.value
                            );
                          }}
                          fullWidth
                          inputProps={{
                            id: "preferredLanguage.selectedValue",
                            name: "preferredLanguage",
                            title: "select a language",
                            "aria-label": t("Users.preferredLanguage"),
                          }}
                          name="preferredLanguage"
                          error={
                            errors["preferredLanguage"] !== undefined
                              ? true
                              : false
                          }
                        >
                          {values.preferredLanguage?.items?.map((opt: any) => (
                            <option key={opt.value} value={opt.value}>
                              {opt.text}
                            </option>
                          ))}
                        </Select>
                        {errors.preferredLanguage &&
                        touched.preferredLanguage ? (
                          <span className={classes.errorWarning}>
                            {t(errors["preferredLanguage"].toString())}
                          </span>
                        ) : (
                          ""
                        )}
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      style={{
                        paddingBottom: 0,
                        paddingTop: 10,
                        marginBottom: "1em",
                      }}
                    >
                      <FormControl id="cc" variant="outlined" fullWidth>
                        <Typography style={{ fontWeight: "bold" }}>
                          {t("Users.role")}
                        </Typography>
                        <Select
                          native
                          value={values.role?.selectedValue}
                          onChange={(opt) => {
                            let text = values.role?.items?.find(
                              (x) => x.value === opt.target.value
                            )?.text;
                            setFieldValue("role.selectedText", text);
                            setFieldValue(
                              "role.selectedValue",
                              opt.target.value
                            );
                            setFieldTouched("role");
                          }}
                          fullWidth
                          inputProps={{
                            id: "role.selectedValue",
                            name: "role.selectedValue",
                            title: "select a role",
                            "aria-label": t("Users.role"),
                          }}
                          name="role"
                          error={errors["role"] !== undefined ? true : false}
                        >
                          {values.role?.items?.map((opt: any) => (
                            <option key={opt.value} value={opt.value}>
                              {opt.text}
                            </option>
                          ))}
                        </Select>
                        {errors.role && touched.role ? (
                          <span className={classes.errorWarning}>
                            {t(errors["role"], toString())}
                          </span>
                        ) : (
                          ""
                        )}
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      style={{
                        paddingBottom: 0,
                        paddingTop: 10,
                        marginBottom: "1em",
                      }}
                    >
                      <Typography
                        style={{
                          fontStyle: "italic",
                          fontWeight: "bold",
                          opacity: 0.9,
                        }}
                      >
                        {t("Users.roleInfoMessage")}
                      </Typography>
                    </Grid>
                    <Grid container justifyContent="flex-end">
                      <Button
                        color="primary"
                        id="btnSubmit"
                        variant="contained"
                        disabled={!isValid || !dirty}
                        type="submit"
                        style={{ textTransform: "none" }}
                      >
                        {t("Users.btnSave")}
                      </Button>
                      <Button
                        classes={{ outlinedPrimary: classes.outlinedButton }}
                        color="primary"
                        id="btnCancel"
                        variant="outlined"
                        type="button"
                        onClick={handleCancel}
                        style={{ marginLeft: "0.5em", textTransform: "none" }}
                      >
                        {t("Users.btnCancel")}
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              </Grid>
            </Grid>
          );
        }}
      </Formik>
    </Box>
  );
}
