export const MY_JOURNEY_LOADING = "MY_JOURNEY_LOADING";
export const MY_JOURNEY_FAIL = "MY_JOURNEY_FAIL";
export const MY_JOURNEY_SUCCESS = "MY_JOURNEY_SUCCESS";

export interface myJourneys{
  totalRecords : number; 
  data : JourneyDetails[];
  errorMessage : string;
}

export interface JourneyDetails{
  id : number; 
  journeyUId : string;
  journeyId  : number;
  rentalTypeName : string; 
  productUId  : string;
  productId  : number; 
  referenceNumber: string; 
  canView: boolean;
  canEdit: boolean;
  canDelete: boolean;
  canCopy: boolean;
  driverName  : string;
  reservationStartDate : string; 
  startDateTimeDB : string;
  endDateTimeDB  : string;
  startDateTime  : string;
  endDateTime  : string; 
  startLocation  : string; 
  startLocationId : string; 
  endLocation  : string; 
  statusUId  : string; 
  status  : string; 
  distanceTravelled  : string; 
  actions  : string; 
  companyActiveTravelPolicy : string; 
  activeTravelPolicy  : string; 
  midPoints  : string; 
  hasReservation  : boolean; 
  isCarClub  : boolean;
  isStartDateInPast  : boolean; 
  riskAssessmentValid  : boolean;
}


export interface MyJourneyLoading {
  type: typeof MY_JOURNEY_LOADING;
}

export interface MyJourneyFail {
  type: typeof MY_JOURNEY_FAIL;
}

export interface MyJourneySuccess {
  type: typeof MY_JOURNEY_SUCCESS;
  payload: myJourneys;
}

export type MyJourneyDispatchTypes =
  | MyJourneyLoading
  | MyJourneyFail
  | MyJourneySuccess;
