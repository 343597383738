import { DropDownList} from "../Reservation/reservationFormType";
import {  DriverDeclaration } from "../JourneyStart/journeyInputType"
import { AdditionalInformation } from "../AdditionalInformation/additionalInformationType";

export interface SpecialityRequest {
  reservation : {
    accountNumber: string; 
    additionalInformation: AdditionalInformation | null;
    bookerName: string; 
    companyUId: string;
    driverName: string; 
    drivers: DriverDeclaration[];
    journeyProfileId: number;
    journeyProfileUId: string;
    reservationNumber:  string;
    reservationStatusUId: string;
    reservationStatus?: string;
    startLocation: string;
    endLocation: string;
    startDateTime: Date; 
    endDateTime: Date; 
  };
  specialityVehicles : DropDownList[]; 
  selectedSpecialityVehicle : string; 
  selectedSpecialityVehicleValue : string; 
  vehicleTypeNotes : string; 
  svReservationId? : string;
  createdUtc: Date;
  modifiedUtc: Date;
}

export interface SpecialityRequestState {
  hasError: boolean;
  request? : SpecialityRequest | null
  errors: string[];
}

export enum SpecialityVehicleType {
  pleaseSelect = "",
  car = "EF48E60F-3B3C-4915-9842-6B18E8CEC0BD",
  four = "4940B0A1-4AC8-4455-A414-A56269C7F0D9",
  lcv = "8FD1362B-88C3-45C0-8435-B86E33136FAA",
  tip = "E2810201-0457-400C-AEB9-EB93FA16287B",
  hgv = "80CDB866-8A5A-498E-977B-12DB54391BF2",
  acc = "A855855F-6D54-4757-913F-BA215545151E",
  fri = "622C814F-F989-48E6-8D42-2AD461BA0922",
  oth = "56E56AB4-113A-494C-BA3F-43EF2003D90C"
}



export const SpecialityRequestInitialValue : SpecialityRequest= {
  reservation:{
  accountNumber : "", 
  additionalInformation: null,
  companyUId: "",
  startDateTime: new Date(),
  endDateTime: new Date(),  
  startLocation: "",
  endLocation: "",
  journeyProfileId: 0,
  journeyProfileUId: "",
  reservationStatusUId: "",
  reservationNumber: "",  
  driverName: "", 
  bookerName:  "",
  drivers: [],
},
specialityVehicles : [{
  disabled: false,
  group: {
    disabled: false,
    name: "",
  }, 
  selected: false,
  text: "",
  value: ""
}], 
selectedSpecialityVehicle : "",
selectedSpecialityVehicleValue : "", 
svReservationId: "",
vehicleTypeNotes: "",
createdUtc: new Date(),
modifiedUtc: new Date()
};