import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  DriverDetailsViewModel,
  driverInitialValue,
} from "components/Reservation/steps/DriverDetails/driverDetailsType";

const initialState: DriverDetailsViewModel = driverInitialValue;

export const DriverDetailSlice = createSlice({
  name: "dirverDetail",
  initialState,
  reducers: {
    updateDriverDetails: (
      state,
      action: PayloadAction<DriverDetailsViewModel>
    ) => {
      console.log("Payload received: ", action.payload);
      state.driver = action.payload.driver;
      state.loyalty = action.payload.loyalty;
    },
    resetDriverDetails: () => initialState,
  },
});

export const { updateDriverDetails, resetDriverDetails } =
  DriverDetailSlice.actions;

export default DriverDetailSlice.reducer;
