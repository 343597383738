import TextField from '@mui/material/TextField';
import { authService } from "services/authService";
import { ThemeProvider, createTheme } from "@mui/material";
import theme from "theme";
import moment from "moment";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { datePickerLocaleMapping, datePickerWeekdayLocaleMapping } from "helpers/localization";



const customTheme = createTheme(theme, {
  components: {
    MuiButtonBase: {
      styleOverrides: {
        root: {
          "&:focus": {
            outline: "0px solid #0066FF !important",
            outlineOffset: "0px",
          },
        },
      }
    },
  },
});

export default function EtdDatePickerV2(props) {
  const { id, value, handleChange, handleBlur, isPastDateAllowed, isReadOnly, isFullWidth, isError, localeDateTimeFormat, errorMessageId, daysPastDateAllowed} = props;
  const locale = authService.getUserLocale();

  //Unlike AdapterDayjs, AdapterMoment always returns english weekdaysShort (a bug?)
  //Need to update the weekdaysShort for different locale
  moment.updateLocale("en", {weekdaysShort: datePickerWeekdayLocaleMapping[locale]});

  return (
    <>
      <LocalizationProvider
        dateAdapter={AdapterMoment}
        adapterLocale={datePickerLocaleMapping[locale]}
      >
        <ThemeProvider theme={customTheme}>
          <DatePicker
            //label="Date Picker"
            value={value}
            components={{
              OpenPickerIcon: CalendarMonthIcon,
            }}
            //inputFormat={localeDateTimeFormat} //depends on date lib
            minDate={!isPastDateAllowed ? new Date() : daysPastDateAllowed? new Date(Date.now()- 86400000*daysPastDateAllowed ) :new Date(1900, 0, 1)} // changes for 47173
            maxDate={new Date(2050, 12, 31)}
            readOnly={isReadOnly ?? false}
            showDaysOutsideCurrentMonth
            dayOfWeekFormatter={(day) => `${day}`} //depends on dateAdapter, change weekday label
            onChange={(e) => {
              handleChange(e);
            }}            
            InputProps={{
              sx: {
                "& .MuiSvgIcon-root": {
                  color: "#006639",
                },
                "& .MuiIconButton-edgeEnd": {
                  marginRight: "0px",
                },
              },
            }}
            PopperProps={{
              sx: {
                "& .MuiPickersDay-dayWithMargin": {
                  /* color: "green",
                  backgroundColor: "red",
                  width: "32px",
                  height: "32px",
                  margin: "2px 4px", */
                },
              },
            }}
            renderInput={(params) => (
              <TextField
                id={id}
                variant="standard"
                fullWidth={isFullWidth ?? true}
                autoComplete="off"
                onBlur={(e) => {
                  handleBlur(e);
                }}
                error={isError}
                //helperText={"abcdefg"}  //replaced with separate <span/>
                {...params}
              />
            )}
            aria-describedby={errorMessageId}
          />
        </ThemeProvider>
      </LocalizationProvider>
    </>
  );
}