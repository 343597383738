import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import CarClubDriverTable from "../carClubDriverTable";
import { useTranslation } from "react-i18next";
import CheckIcon from "@mui/icons-material/Check";
import {
  CarClubDriverProps,
  CarClubDriverState,
  driverDetails,
  SearchMatchType,
} from "../carClubDriverType";
import React, { useEffect } from "react";
import InfoIcon from "@mui/icons-material/Info";
import {
  carClubDriverDetails,
  resetCarClubDriverDetails,
} from "components/store/actions/reservation/carclubActions";
import {
  clearAlertMessage,
  showErrorAlertMessage,
  showInfoAlertMessage,
} from "components/store/actions/shared/alertActions";
import { useDispatch } from "react-redux";
import { GetCarClubDrivers } from "services/carClubDriverService";
import { authService } from "services/authService";
import { broadcastAnnouncement } from "components/store/actions/shared/announcementActions";

const useStyles = makeStyles((theme) => ({
  activeButtonClass: {
    textTransform: "none",
    fontWeight: "bold",
    width: 400,
    height:40,
    border: "2px solid #006639",
  },
  inActiveButtonClass: {
    textTransform: "none",
    width: 400,
    height:40,
  },
  activeButtonClassSm: {
    textTransform: "none",
    fontWeight: "bold",
    width: 200,
    height:40,
    border: "2px solid #006639",
  },
  inActiveButtonClassSm: {
    textTransform: "none",
    width: 200,
    height:40,
  },
  backdrop: {
    zIndex: 99999,
    color: "#fff",
  },
}));
const HighlightedTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "2px solid",
      },
    },
  },
})(TextField);

export const CarClubDriverBOBO: React.FC<CarClubDriverProps> = ({
  handleSelectDriver,
  selectedDriver,
  carClubDrivers,
  searchParam,
  onDataChange,
}) => {
  const { t } = useTranslation();
  const [bookForMyself, setBookForMyself] = React.useState(true);
  const [searchDisabled, setSearchDisabled] = React.useState(true);
  const [searched, setSearched] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const bookother = true;
  const [ccSelectedDriver, setCCSelectedDriver] =
    React.useState(selectedDriver);
  const [email, setEmail] = React.useState("");
  const [lastname, setLastname] = React.useState("");
  const [firstname, setFirstname] = React.useState("");
  const [memberId, setMemberId] = React.useState("");
  const bookForMyselfRef = React.useRef<HTMLButtonElement>(null);
  const classes = useStyles();
  const dispatch = useDispatch();
  var booker: driverDetails[] = [];
  const bookerCarClubMember = authService.getUserCarClubCompanyId() ?? "";
  var bookerId = bookerCarClubMember.concat("-", authService.getUserDriverId());
  const theme = useTheme();
  const isSmXs = useMediaQuery(theme.breakpoints.down('lg'));
  booker.push({
    id: bookerId,
    firstName: authService.getUserFirstName(),
    lastName: authService.getUserLastName(),
    email: authService.getUserEmail(),
    isSelected: true,
  });
  const bookerCarClubDrivers = {
    drivers: booker,
    hasError: false,
    searchParam: undefined,
    errors: undefined,
  };

  useEffect(() => {
    if (searchParam !== undefined) {
      setLastname(searchParam.lastName);
      setFirstname(searchParam.firstName);
      setEmail(searchParam.email);
      setMemberId(searchParam.id);
      setSearched(true);
      setBookForMyself(false);
    } else setBookForMyself(true);
  }, []);

  useEffect(() => {
    console.log("carClubDrivers BOBO", carClubDrivers);
  }, [carClubDrivers]);
  useEffect(() => {
    handleSelectDriver(ccSelectedDriver);
  }, [ccSelectedDriver]);

  useEffect(() => {
    setSearchDisabled(
      !(
        lastname.trim().length > 1 ||
        firstname.trim().length > 1 ||
        email.trim().length > 1 ||
        memberId.trim().length > 0
      )
    );
  }, [firstname, lastname, email, memberId]);

  const handleClearClick = () => {
    dispatch(resetCarClubDriverDetails());
    setCCSelectedDriver("");
    handleSelectDriver("");
    setLastname("");
    setFirstname("");
    setEmail("");
    setMemberId("");
    setSearched(false);
  };
  const handleSearch = async () => {
    dispatch(clearAlertMessage());
    setLoading(true);
    setCCSelectedDriver("");
    setSearched(true);
    let driverSearch;
    if (memberId.trim().length === 0) {
      driverSearch = {
        MatchType: SearchMatchType.loose,
        ContactLastName: lastname,
        ContactEmail: email,
        ContactFirstName: firstname,
      };
    } else {
      driverSearch = {
        MatchType: SearchMatchType.exact,
        AssociationId: memberId,
      };
    }
    var driverDetails: CarClubDriverState = (await GetCarClubDrivers(
      driverSearch
    )) as CarClubDriverState;
    driverDetails.searchParam = {
      firstName: firstname,
      lastName: lastname,
      email: email,
      id: memberId,
      isSelected: false,
    };
    dispatch(carClubDriverDetails(driverDetails));
    setCCSelectedDriver(
      driverDetails.drivers.find((x) => x.isSelected == true)?.id ?? ""
    );
    if (driverDetails.errors && driverDetails.hasError) {
      setSearched(false);
      if (driverDetails.errors === "ERRMaxDrivers") {
        dispatch(showInfoAlertMessage(t(driverDetails.errors)));
      } else if (driverDetails.errors === "ERR500002") {
        dispatch(showErrorAlertMessage(t(driverDetails.errors)));
      } else {
        dispatch(showErrorAlertMessage(driverDetails.errors));
      }
      window.scrollTo(0, 0);
    }
    setLoading(false);
  };

  return (
    <Grid data-testid="carClubDriverBOBO" container id="carClubDriverBOBO">
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress />
      </Backdrop>
      <Grid
          item
          md={12}
          >
          <Box 
            flexDirection="row"  
            alignItems="center" 
            textAlign="center"  
            tabIndex={0}
            style={{
              paddingBottom: 0,
              paddingTop: 0,
              paddingLeft: 25,
              paddingRight: 25,
              // maxWidth: 20,
              // minWidth: 10,
            }}
            >
            <Button
              id="btnBookForMyself"
              data-testid="btnBookForMyself"
              style={{width:"48%", 
              height:"100%",
              wordBreak: "break-all",
              wordWrap: "break-word",
              paddingLeft:0,
              paddingRight:0,
              fontSize: "0.8em",
            }}
              ref={bookForMyselfRef}
              color="primary"
              aria-label={t("ReservationWizard.carClubPage.btnBookForMyself")}
              variant="outlined"
              className={
                bookForMyself
                ? isSmXs ? classes.activeButtonClassSm : classes.activeButtonClass
                : isSmXs ? classes.inActiveButtonClassSm:  classes.inActiveButtonClass
              }
              onClick={() => {
                dispatch(clearAlertMessage());
                setBookForMyself(true);
                setCCSelectedDriver(bookerId);
                dispatch(carClubDriverDetails(bookerCarClubDrivers));
              }}
              startIcon={bookForMyself && <CheckIcon />}>
              {t("ReservationWizard.carClubPage.btnBookForMyself")}
            </Button>
            <Button
              id="btnBookForOthers"
              data-testid="btnBookForOthers"
              style={{width:"48%", 
              height: "100%",
              wordBreak: "break-all",
              wordWrap: "break-word",
              paddingLeft:0,
              paddingRight:0,
              fontSize: "0.8em",
            }}
              color="primary"
              variant="outlined"
              className={
                bookForMyself
                ?  isSmXs ? classes.inActiveButtonClassSm:  classes.inActiveButtonClass
                : isSmXs ? classes.activeButtonClassSm : classes.activeButtonClass
              }
              aria-label={t("ReservationWizard.carClubPage.btnBookForOthers")}
              onClick={() => {
                handleClearClick();
                setBookForMyself(false);
              }}
              startIcon={!bookForMyself && <CheckIcon />}>
              {t("ReservationWizard.carClubPage.btnBookForOthers")}
            </Button>
          </Box>
       </Grid>
      {bookForMyself ? (
        <Grid
          container
          id="bookForMyself"
          data-testid="bookForMyself"
          style={{ marginTop: 50 }}>
          <CarClubDriverTable
            handleSelectDriver={handleSelectDriver}
            selectedDriver={ccSelectedDriver}
            carClubDrivers={carClubDrivers}
            onDataChange={onDataChange}
            ></CarClubDriverTable>
        </Grid>
      ) : (
        <Grid
          container
          id="bookForOthers"
          data-testid="bookForOthers"
          style={{ marginTop: 50 }}>
          {bookother && (
            <Grid container>
              <Grid
                item
                xs={12}
                md={12}
                style={{
                  display: "flex",
                  alignItems: "center",
                }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    margin: 10,
                    marginLeft: 0,
                  }}>
                  <Typography id="searchCriteria" variant="h2">
                    {t("ReservationWizard.carClubPage.searchCriteria")}
                  </Typography>
                  <IconButton
                    id="infoIcon"
                    size="small"
                    color="primary"
                    style={{
                      marginBottom: "1em",
                      marginLeft: "0.25em",
                      height: 15,
                      width: 15,
                    }}
                    title={t(
                      "ReservationWizard.carClubPage.searchCriteriaDesc"
                    )}>
                    <InfoIcon fontSize="small" />
                  </IconButton>
                </div>
                <br />
              </Grid>
              <Grid container direction="row">
                <Grid item xs={12} sm={3}>
                  <TextField
                    id="txtLastname"
                    size="small"
                    margin="dense"
                    value={lastname}
                    label={t("ReservationWizard.carClubPage.lastname")}
                    variant="outlined"
                    onChange={(e) => setLastname(e.target.value)}
                    placeholder={t(
                      "ReservationWizard.carClubPage.lastnamePlaceholder"
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    id="txtFirstname"
                    size="small"
                    margin="dense"
                    value={firstname}
                    label={t("ReservationWizard.carClubPage.firstname")}
                    variant="outlined"
                    onChange={(e) => setFirstname(e.target.value)}
                    placeholder={t(
                      "ReservationWizard.carClubPage.firstnamePlaceholder"
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    id="txtEmail"
                    size="small"
                    margin="dense"
                    value={email}
                    style={{ minWidth: "220px" }}
                    label={t("ReservationWizard.carClubPage.emailaddress")}
                    variant="outlined"
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder={t(
                      "ReservationWizard.carClubPage.emailPlaceholder"
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Grid container>
                    <Grid item xs={12} md={12} direction="row" container>
                      <Grid item md={4} xs={12}>
                        <Typography
                          variant="body2"
                          style={{
                            fontWeight: "bold",
                            marginTop: "15px",
                          }}>
                          {bookerCarClubMember + "   - "}
                        </Typography>
                      </Grid>
                      <Grid item md={8} xs={12}>
                        <HighlightedTextField
                          id="txtMemberId"
                          size="small"
                          margin="dense"
                          value={memberId}
                          label={t("Res.carClubMemberId")}
                          variant="outlined"
                          onChange={(e) => setMemberId(e.target.value)}
                          placeholder={t(
                            "ReservationWizard.carClubPage.memberIdPlaceholder"
                          )}
                        />{" "}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                style={{
                  justifyContent: "flex-end",
                  marginTop: "1em",
                  marginBottom: "2em",
                }}>
                <Button
                  id="btnSearch"
                  color="primary"
                  variant="contained"
                  style={{ alignSelf: "right", marginRight: "1em" }}
                  aria-label={t("Search")}
                  disabled={searchDisabled}
                  onClick={() => {
                    handleSearch();
                  }}>
                  {t("Search")}
                </Button>{" "}
                <Button
                  id="btnClear"
                  color="primary"
                  variant="contained"
                  style={{ alignSelf: "right" }}
                  aria-label={t("ClearAll")}
                  onClick={() => {
                    handleClearClick();
                  }}>
                  {t("ClearAll")}
                </Button>{" "}
              </Grid>
              {searched && !loading && (
                <>
                  <Grid item xs={12} md={12}>
                    <Typography id="memberIdListLabel" variant="h2">
                      {t("ReservationWizard.carClubPage.memberIdListLabel")}
                    </Typography>
                    <br />
                  </Grid>
                  <CarClubDriverTable
                    handleSelectDriver={handleSelectDriver}
                    selectedDriver={ccSelectedDriver}
                    carClubDrivers={carClubDrivers}
                    onDataChange={onDataChange}
                    ></CarClubDriverTable>
                </>
              )}
            </Grid>
          )}
        </Grid>
      )}
    </Grid>
  );
};
