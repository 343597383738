import { useSelector } from "react-redux";
import { AppState } from "../rootReducer";

export function useCarClubDriverDetailsHasError() {
  return useSelector(
    (state: AppState) => state.carclub.carClubDriverReducer.hasError
  );
}
export function useCarClubDriverDetailsError() {
  return useSelector(
    (state: AppState) => state.carclub.carClubDriverReducer.errors
  );
}
export function useCarClubDriverDetails() {
  return useSelector(
    (state: AppState) => state.carclub.carClubDriverReducer.drivers
  );
}

export function useCarClubJourneyEndDate() {
  return useSelector(
    (state: AppState) => state.carclub.carClubDriverReducer.journeyEndDate
  );
}

export function useCarClubDriverSearch() {
  return useSelector(
    (state: AppState) => state.carclub.carClubDriverReducer.searchParam
  );
}

export function useCarClubVehicleAvailability() {
  return useSelector(
    (state: AppState) => state.carclub.carClubVehicleReducer.vehicles
  );
}
