import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import axiosInstance from "helpers/axiosInstance";
import { Button } from "@mui/material";
import { useDispatch } from "react-redux";
import { broadcastAnnouncement } from "components/store/actions/shared/announcementActions";

const LegalUserGuide = () => {
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [fileURL, setFileURL] = useState<String>();

  const dispatch = useDispatch();
  const options = {
    cMapUrl: "cmaps/",
    cMapPacked: true,
  };

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  React.useEffect(() => {
    loadPDF();
    var nextButton = document.getElementById("nextButton");
    nextButton?.focus();
  }, []);

  function loadPDF() {
    axiosInstance
      .get("/legaluserguide", { method: "GET", responseType: "blob" })
      .then((response) => {
        var result = new Blob([response.data], { type: "json" });
        pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
        var fileURLResult = URL.createObjectURL(result);
        setFileURL(fileURLResult);
      })
      .catch((error) => {
        if (
          error.response != null &&
          error.response.status != null &&
          error.response.status === 400
        ) {
          console.error("There is an error", error);
        } else console.error("There is an error", error);
      });
  }

  function previousClick() {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
      dispatch(broadcastAnnouncement("page " + (pageNumber - 1)));
    }
  }

  function nextClick() {
    if (pageNumber < numPages) {
      setPageNumber(pageNumber + 1);
      dispatch(broadcastAnnouncement("page " + (pageNumber + 1)));
    }
  }

  return (
    <div>
      <Document
        file={{ url: fileURL }}
        options={options}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        {Array.from(new Array(numPages), (el, index) => (
          <Page key={`page_${index + 1}`} pageNumber={index + 1} />
        ))}
      </Document>
    </div>
  );
};

export default LegalUserGuide;
