import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import React from "react";
import Alert from '@mui/material/Alert';
import axiosInstance from "../helpers/axiosInstance";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  clearAlertMessage,
  showErrorAlertMessage,
  showSuccessAlertMessage,
} from "components/store/actions/shared/alertActions";
import { broadcastAnnouncement } from "components/store/actions/shared/announcementActions";
import { authService } from "services/authService";
import { appInsights } from "helpers/appInsights";
import { SeverityLevel } from "@microsoft/applicationinsights-common";

const useStyles = makeStyles({
  root: {
    "& .MuiFormHelperText-root": {
      fontSize: 12,
      marginLeft: "15px",
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#909090",
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "black",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#005423",
    },
    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
      borderColor: "#E91B0C",
    },
    "& .MuiFormLabel-root": {
      color: "#757575",
      fontSize: "1.2em",
    },
  },
  outlinedButton: {
    border: "1px solid rgba(0, 102, 57, 0.95);",
  },
});

const CarClubLogin = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  let history = useNavigate();
  const [ccPwd, setCCPwd] = React.useState("");
  const [isccError, setisccError] = React.useState(false);
  const [ccErrorMsg, setccErrorMsg] = React.useState("");
  const [Loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  const ccPwdRef = React.useRef<HTMLInputElement>(null);
  const [ispwdEmpty, setispwdEmpty] = React.useState(true);
  const isccPersisted = authService.getUserccPersisted();

  const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  const ReservationReferenceViewModel = {
    JourneyUId: props.journeyUId,
    ReferenceNumber: props.reference,
    DriverId: authService.getUserCarClubId(),
  };

  React.useEffect(() => {
    if (ccPwdRef.current != null) ccPwdRef.current.focus();
  }, []);
  const AuthenticateCC = () => {
    setccErrorMsg("");
    setLoading(true);
    const result = axiosInstance
      .post("carclub/authenticate", {
        driverId: authService.getUserCarClubId(),
        driverPassword: ccPwd,
        IsCCPersisted: isccPersisted,
      })
      .then(async (response) => {
        if (response.data.success) {
          if (authService.getUserccReauthExpired())
            authService.setUserccReauthExpired(
              response.data.credentials.isReAuthCodeExpired
            );
          if (props.isView) {
            setLoading(false);
            history({
              pathname: `/reservation/ViewCC/${props.journeyUId}/${props.reference}`,
            });
          } //cancel  res
          else {
            axiosInstance
              .post("carclub/cancel", ReservationReferenceViewModel) // can pass on the header here
              .then(async (response) => {
                if (response.data.success === true) {
                  dispatch(clearAlertMessage());
                  setLoading(false);
                  props.action();
                  appInsights.trackEvent({
                    name: "Cancelled CarClub",
                    properties: { Reference: props.reference },
                  });
                  await delay(500);
                  dispatch(
                    showSuccessAlertMessage(
                      t("CancelCarClubReservationSuccessMessageFormat", {
                        Reference: response.data.message,
                      })
                    )
                  );
                  dispatch(
                    broadcastAnnouncement(
                      t("CancelCarClubReservationSuccessMessageFormat", {
                        Reference: response.data.message,
                      })
                    )
                  );
                  props.handleCancelJourneySuccessful();
                  window.scrollTo(0, 0);
                } else {
                  dispatch(clearAlertMessage());
                  setLoading(false);
                  props.action();
                  await delay(500);
                  if (
                    response.data.message.trim() === "ERR20040" ||
                    response.data.message.trim() === "ERR20039" ||
                    response.data.message.trim() === "WSUser"
                  ) {
                    dispatch(
                      showErrorAlertMessage(t(response.data.message.trim()))
                    );
                    dispatch(
                      broadcastAnnouncement(t(response.data.message.trim()))
                    );
                  } else {
                    dispatch(showErrorAlertMessage(response.data.message));
                    dispatch(broadcastAnnouncement(response.data.message));
                  }
                  window.scrollTo(0, 0);
                }
              })
              .catch(async (error) => {
                dispatch(clearAlertMessage());
                setLoading(false);
                props.action();
                await delay(500);
                dispatch(
                  showErrorAlertMessage(t("CustomErrorGenericErrorMsg"))
                );
                dispatch(
                  broadcastAnnouncement(t("CustomErrorGenericErrorMsg"))
                );
                window.scrollTo(0, 0);
              });
          }
        } else {
          setLoading(false);
          dispatch(clearAlertMessage());
          if (ccPwdRef.current != null) ccPwdRef.current.focus();
          if (response.data.message.trim() === "ERR20039")
            setccErrorMsg(t(response.data.message.trim()));
          else if (response.data.message.trim() === "ERRCCJail")
            setccErrorMsg(t(response.data.message.trim()));
          else if (response.data.message.trim() === "UnexpectedError") {
            setccErrorMsg(t("UnexpectedError"));
          } else setccErrorMsg(response.data.message);
          dispatch(broadcastAnnouncement(response.data.message));
        }
      })
      .catch((error) => {
        dispatch(clearAlertMessage());
        setLoading(false);
        appInsights.trackException({
          id: "Authenticate CC",
          exception: error,
          severityLevel: SeverityLevel.Error,
        });
        console.error("There is an error", error);
      });
  };

  const handleCCPasswordChange = (params) => {
    if (params.target.value.length == 0 || params.target.value.length < 4) {
      setisccError(true);
    } else {
      setisccError(false);
      setispwdEmpty(false);
    }
    setCCPwd(params.target.value);
  };
  const keyPress = (e) => {
    if (e.keyCode === 13) {
      dispatch(broadcastAnnouncement(t("Continue")));
      AuthenticateCC();
    }
  };

  return (
    <>
      <DialogTitle data-testid="carClubLoginDialog" id="cc-dialog-title">
        {props.isView
          ? t("Res.viewReservationDialogTitle", {
              Reference: props.reference,
            })
          : t("Res.cancelReservationDialogTitle", {
              Reference: props.reference,
            })}
      </DialogTitle>
      <Divider
        aria-hidden="true"
        style={{
          marginLeft: 20,
          marginRight: 20,
          backgroundColor: "black",
          height: "0.8px",
        }}
      />
      <DialogContent id="cc-dialog-description">
        <DialogContentText style={{ color: "#000000" }}>
          {props.isView
            ? props.isReauth
              ? t("Res.viewReservationReauthDialogMessage", {
                  Reference: props.reference,
                })
              : t("Res.viewReservationDialogMessage", {
                  Reference: props.reference,
                })
            : props.isReauth
            ? t("Res.cancelReservationReEnterDialogMessage", {
                Reference: props.reference,
              })
            : t("Res.cancelReservationDialogMessage", {
                Reference: props.reference,
              })}
        </DialogContentText>
        {ccErrorMsg.length !== 0 && (
          <Alert variant="filled" severity="error" className={classes.root}>
            {" "}
            {ccErrorMsg}{" "}
          </Alert>
        )}
        {Loading && (
          <Alert variant="filled" className={classes.root} severity="info">
            {t("ValidatingYourRequest")}
          </Alert>
        )}
        <TextField
          className={classes.root}
          style={{ marginRight: 10 }}
          margin="dense"
          id="ccPassword"
          data-testid="pwd"
          label={t("Res.carClubPin")}
          type="password"
          inputRef={ccPwdRef}
          aria-required="true"
          aria-invalid={isccError}
          error={isccError}
          helperText={isccError ? t("Res.carclubPasswordValidation") : ""}
          required
          variant="outlined"
          onKeyDown={keyPress}
          onChange={handleCCPasswordChange}
        />
        {Loading && (
          <CircularProgress style={{ margin: "auto" }} title="Loading" />
        )}
      </DialogContent>
      <Divider
        aria-hidden="true"
        style={{
          marginLeft: 20,
          marginRight: 20,
          backgroundColor: "black",
          height: "0.8px",
        }}
      />

      <DialogActions>
        <Button
          id="CancelDialogButton"
          color="primary"
          variant="outlined"
          classes={{ outlinedPrimary: classes.outlinedButton }}
          style={{ textTransform: "none" }}
          onClick={props.action}
        >
          {t("Back")}
        </Button>
        <Button
          id="ContinueDialogButton"
          variant="contained"
          style={{ textTransform: "none" }}
          onClick={AuthenticateCC}
          disabled={ispwdEmpty || isccError || Loading}
          color="primary"
        >
          {t("Continue")}
        </Button>
      </DialogActions>
    </>
  );
};
export default CarClubLogin;
