import { getUserPermissionsStatus, Permissions } from "./getUserPermissions";

export const displayDriverAccordion = (isBOBOToggleEnabled:boolean, driverDeclaration:boolean, boboEnabled: boolean, userPermissions: number): boolean => {
  
   if(isBOBOToggleEnabled === false && driverDeclaration === false){
    return true;
   }
    var userBoboEnabled = getUserPermissionsStatus(userPermissions,Permissions.BoboEnabled);
    if(driverDeclaration === false && boboEnabled === false && userBoboEnabled === false){
      return true;
    }
    if(driverDeclaration === false && boboEnabled === true && userBoboEnabled === false){
      return true;
    }
    if(driverDeclaration === false && boboEnabled === false && userBoboEnabled === true){
      return true;
    }

    return false;
  }

  export const displayBoboTabs = (isBOBOToggleEnabled:boolean, driverDeclaration:boolean, boboEnabled: boolean, userPermissions: number): boolean => {

    var userBoboEnabled = getUserPermissionsStatus(userPermissions,Permissions.BoboEnabled);
    if(driverDeclaration === false && isBOBOToggleEnabled === true && boboEnabled === true && userBoboEnabled === true){
      return true;
    }
    return false;
  }