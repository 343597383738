import { createSlice, PayloadAction } from "@reduxjs/toolkit";
interface AnnouncementState {
  announcement: string;
}

const initialState: AnnouncementState = {
  announcement: "",
};

export const announcementSlice = createSlice({
  name: "announcement",
  initialState,
  reducers: {
    broadcastAnnouncement: (state, action: PayloadAction<string>) => ({
      ...state,
      announcement: action.payload,
    }),
  },
});

export default announcementSlice.reducer;
