import React, { useEffect } from "react";
import { useFormikContext } from "formik";

const FocusError = (props) => {
  const { errors, isSubmitting, isValidating } = useFormikContext();

  useEffect(() => {
    if (isSubmitting && !isValidating) {
      let keys = Object.keys(errors);

      var elementToFocus = "";
      if (keys.length > 0) {
        if (keys.indexOf("registrationNumber") > -1)
          elementToFocus = "registrationNumber";
        if (keys.indexOf("availableModesOfTransport") > -1)
          elementToFocus = "availableModesOfTransport";
        if (keys.indexOf("endDateTime") > -1) elementToFocus = "endDateTime";
        if (keys.indexOf("startDateTime") > -1)
          elementToFocus = "startDateTime";

        if (keys.indexOf("endLocation") > -1) elementToFocus = "endLocation";

        if (keys.indexOf("midLocation5") > -1) elementToFocus = "midLocation5";

        if (keys.indexOf("midLocation4") > -1) elementToFocus = "midLocation4";

        if (keys.indexOf("midLocation3") > -1) elementToFocus = "midLocation3";

        if (keys.indexOf("midLocation2") > -1) elementToFocus = "midLocation2";

        if (keys.indexOf("midLocation1") > -1) elementToFocus = "midLocation1";

        if (keys.indexOf("startLocation") > -1)
          elementToFocus = "startLocation";
          
      }
    }
  }, [errors, isSubmitting, isValidating]);

  return null;
};

export default FocusError;
