import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SpecialityRequestState } from "components/Reservation/steps/SpecialityRequest/specialityRequestType";



const initialState: SpecialityRequestState = {
  hasError: false,  
  request: undefined,
  errors: [],
};


export const specialityRequestSlice = createSlice({
  name: "specialityRequest",
  initialState,
  reducers: {
    updateSpecialityRequest: (_, action: PayloadAction<SpecialityRequestState>) => ({
      request: action.payload.request,
      hasError: action.payload.hasError,
      errors: action.payload.errors,
    }),
    resetSpecialityRequest: () => initialState,
  },
});

export default specialityRequestSlice.reducer;
