import { appInsights } from "helpers/appInsights";
import axiosInstance from "helpers/axiosInstance";
import { SeverityLevel } from "@microsoft/applicationinsights-common";
import {
  CarClubDriverState,
  driverDetails,
} from "components/Reservation/steps/Driver/carClubDriverType";

export const GetCarClubDrivers = async (driverSearch: any) => {
  var driverDetails: CarClubDriverState = {
    drivers: [],
    hasError: false,
    errors: undefined,
  };
  await axiosInstance
    .post("carclub/getdrivers", driverSearch)
    .then((result) => {
      var drivers: driverDetails[] = [];
      if (result.data.success) {
        console.log("result.data.data", result.data.data);
        result.data.data &&
          result.data.data.forEach((element) => {
            var driver: driverDetails = {
              id: element.id,
              firstName: element.contact.firstName,
              lastName: element.contact.lastName,
              email: element.contact.email,
              isSelected: result.data.data.length === 1 ? true : false,
            };
            drivers.push(driver);
          });
        driverDetails = {
          drivers: drivers,
          hasError: false,
          errors: undefined,
        };
      } else {
        var message = result.data.message;
        driverDetails = {
          drivers: [],
          hasError: true,
          errors: message,
        };
      }
    })
    .catch((error) => {
      appInsights.trackException({
        properties: {
          method: "GetDrivers CC",
          Component: "Action",
        },
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
      driverDetails = {
        drivers: [],
        hasError: true,
        errors: error,
      };
    });
  return new Promise((resolve) => resolve(driverDetails));
};
