import configParams from 'configParams';
import { createBrowserHistory } from 'history';

export const history = createBrowserHistory();


const getBaseURL = () => {
  
  let baseURL;
  if (window.location.hostname.endsWith("etd.enterprise.co.uk")) {
    baseURL = configParams.API_BASE_URL;
  } else if (window.location.hostname.endsWith("etd.ehi.com")) {
    baseURL = configParams.API_BASE_URL_EHI;
  } else {
    baseURL = configParams.API_BASE_URL_Localhost ?? "http://localhost:3004/api/";
  }
  return baseURL;
}

  export const getApiUrl = () :URL => {
    return new URL(getBaseURL());
  };

  export const getApiOrigin = () => {
    return getApiUrl().origin;
  };

  export const getApiProtocol = () => {
    return getApiUrl().protocol;
  };

  export const getApiPort = () => {
    return getApiUrl().port;
  };