import React, { useEffect } from "react";
import {
  Box,
  Button,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { clearAlertMessage } from "components/store/actions/shared/alertActions";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "components/store/reducers/rootReducer";
import {
  CarClubReservationViewModel,
  ReservationAdditionalInformationFieldTypeCode,
  ReservationAdditionalInformationFieldViewModel,
} from "views/reservation/ReservationViewType";
import { authService } from "services/authService";
import { appInsights } from "helpers/appInsights";
import moment from "moment";
import { broadcastAnnouncement } from "components/store/actions/shared/announcementActions";
import CarClubLogo from "Assets/Images/CarClubLogo.png";
import CarShareLogo from "Assets/Images/CarShareLogo.png";
import {
  getLocaleCurrency,
  getcompanyCountryCurrency,
} from "helpers/localization";
import { isHiddenAdditionalParameters } from "helpers/additionalParameters";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 240,
    marginBottom: 0,
    paddingRight: 0,
    paddingLeft: 0,
    paddingTop: 15,
    paddingBottom: 0,
    "& .MuiTableCell-root": {
      borderBottom: "none",

    },
  },
  title: {
    fontSize: "1.4em",
    marginBottom: 10,
  },
  subtitle: {
    fontSize: "1.2em",
    marginTop: 10,
    marginBottom: 0,
  },
  tableHeader: {
    fontWeight: "bold",
    padding: 6,
    borderBottom: "none",
    fontSize: "1.1em",
    verticalAlign: "top",
  },
  subTableHeader: {
    padding: 0,
    borderBottom: "none",
  },
  tableBody: {
    fontSize: "1.1em",
    padding: 0,
    borderBottom: "none",
    //verticalAlign: 'top',
  },
  divider: {
    backgroundColor: "#000",
    height: "0.8px",
    margin: 10,
  },
  rootcell: {
    "& .MuiTableCell-root": {
      marginLeft: 0,
      borderBottom: "none",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
  gridItem: {
    verticalAlign: "center",
    paddingBottom: "8px",
    paddingRight: "1px",
    paddingLeft: "0px",
    "& .MuiTypography-body2": {
      fontWeight: "bold",
    },
  },
  labelText: {
    color: "#000",
    fontFamily: "Arial, Roboto, Segoe UI",
    fontSize: 14,
    fontWeight: "bold",
    verticalAlign: "middle",
  },
  tableBodyCurrency: {
    fontSize: "1.1em",
    padding: 4,
    borderBottom: "none",
    wordBreak: "break-word",
    textAlign: "right",
    paddingRight: "10px",
    paddingLeft: "10px",
  },
  tableHeader0: {
    fontWeight: "bold",
    paddingBottom: 6,
    paddingTop: 0,
    paddingRight: 6,
    paddingLeft: 6,
    borderBottom: "none",
    fontSize: "1.1em",
    overflow: "hidden",
    textOverflow: "ellipsis",
    wordWrap: "break-word",
    //wordBreak: "break-all",
    width: "30%",
    verticalAlign: "top",
  },
  tableBody0: {
    fontSize: "1.1em",
    padding: 0,
    borderBottom: "none",
    overflow: "hidden",
    textOverflow: "ellipsis",
    wordBreak: "break-all",
    wordWrap: "break-word",
    width: "70%",
  },
}));

const ConfirmCarClubForm = (props: any) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();  
  const { reservationViewModel } = useSelector(
    (state: AppState) => state.carclub.carClubRentalReducer
  );
  const { reservation } = useSelector(
    (state: AppState) => state.carclub.carClubRentalReducer
  );
  const [resData, setResData] = React.useState<CarClubReservationViewModel>();
  const history = useNavigate();
  const classes = useStyles();
  const ukDate = "DD/MM/YYYY HH:mm";
  const locale = authService.getUserLocale();
  const { companyInfo } = useSelector(
    (state: AppState) => state.companyInfo || {}
  );
  const companyCountryCode = authService.getUserCompanyCountryCode();

  const takoApiEnv = authService.getTakoApiEnv();
  const isCarShareReservation = String(takoApiEnv).toUpperCase().includes("CS");

  appInsights.trackEvent({
    name: "Confirm CarClub Reservation",
    properties: {
      DriverId: authService.getUserCarClubId(),
    },
  });

  const handleClose = () => {
    history("/home");
  };

  function getBookingReference(
    input: ReservationAdditionalInformationFieldViewModel
  ) {
    var singleReference;
    switch (input.fieldTypeCode) {
      case ReservationAdditionalInformationFieldTypeCode.Date:
        singleReference =
          input.fieldName +
          " = " +
          (input.fieldValueDateString === ""
            ? ""
            : new Date(
                parseInt(input.fieldValueDateString.substr(0, 4)), // year
                parseInt(input.fieldValueDateString.substr(4, 2)) - 1, // month
                parseInt(input.fieldValueDateString.substr(6, 2)) // date
              ).toLocaleString(locale.toString() === "en-GB" ? "en-GB" : "de-DE", {
                year: "numeric",
                month: "numeric",
                day: "numeric",
              }));
        break;
      default:
        singleReference =
          input.fieldName + " = " + (input.fieldValue ? input.fieldValue : "");
    }
    return singleReference;
  }

  useEffect(() => {
    dispatch(broadcastAnnouncement(""));
    dispatch(clearAlertMessage());
    console.log(
      "reservationViewModel",
      reservationViewModel,
      "reservation",
      reservation
    );
    if (reservationViewModel) {
      setResData(reservationViewModel);
    }
  }, []);

  return (
    <>
      <Grid data-testid="confirmCarClubForm" className={classes.root} container spacing={3}>
        <Grid container direction="row">
          <Grid
            item
            xs={12}
            md={2}
            className={classes.gridItem}
            style={{
              minWidth: 100,
              maxWidth: 200,
              /* paddingLeft: 30, */
              paddingRight: 0,
            }}
          >
            <Typography>
              <img
                data-testid="carClubImageId"
                style={{
                  display: "block",
                  margin: "0 auto",
                  marginTop:"10px",
                  maxWidth: "80%",
                  maxHeight: "80%",
                }}
                alt="Enterprise Car Club, logo"
                src={isCarShareReservation ? CarShareLogo : CarClubLogo}
              />
            </Typography>
          </Grid>
          <Grid
            id="confirmCarClubTitleHeader"
            item
            xs={12}
            md={8}
            className={classes.gridItem}
          >
            <Typography variant="h1" align="center" className={classes.title} sx={{marginTop:"10px"}}>
              {isCarShareReservation ? t("ReservationWizard.carClubPage.lblCSConfirmReservationPageHeading") : t("ReservationWizard.carClubPage.lblCCConfirmReservationPageHeading")}
            </Typography>
            <Typography align="center" className={classes.subtitle}>
              {t(
                "ReservationWizard.carClubPage.lblCCReviewReservationPagePleaseSeeDetails"
              )}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={2}
            className={classes.gridItem}
            style={{
              minWidth: 100,
              maxWidth: 200,
              paddingLeft: 0,
            }}
          >
            <Typography>
              <img
              data-testid="carClubImageId"
                style={{
                  display: "block",
                  margin: "0 auto",
                  maxWidth: "80%",
                  maxHeight: "80%",
                }}
                alt=""
                src={resData?.imageUrl}
              />
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
      {"   "}
      <Grid className={classes.rootcell} container spacing={3}>
        <Grid item xs={12} md={6} className={classes.root}>
          <Box style={{ paddingLeft: 15 }}>
            <TableContainer id="detailsTable">
              <Table aria-label="journey table">
                <TableBody>
                  <TableRow>
                    <TableCell
                      width="30%"
                      className={classes.tableHeader}
                      component="th"
                      role="rowheader"
                    >
                      <Typography variant="h2">{t("ReferenceNumber")}</Typography>
                    </TableCell>
                    <TableCell
                      width="70%"
                      id="referenceNumberDiv"
                      className={classes.tableBody}
                    >
                      <Typography variant="body1">{resData?.carClubReferenceNumber}</Typography>
                    </TableCell>
                  </TableRow>
                  {resData?.bookingReferences != null &&
                    resData?.bookingReferences.length > 0 && (
                      <TableRow>
                        <TableCell
                          width="30%"
                          className={classes.tableHeader}
                          component="th"
                          role="rowheader"
                        >
                          <Typography variant="h2">{t("Res.BookingReferences")}{" "}</Typography>
                        </TableCell>
                        <TableCell
                          width="70%"
                          className={classes.subTableHeader}
                        >
                          <Table
                            id="carClubBookingReferences"
                            aria-label="journey table"
                          >
                            <TableBody>
                              {resData?.additionalInformation?.additionalInformationFields.map((p, index) => {
                                return ((!isHiddenAdditionalParameters(p.fieldName) && 
                                  <TableRow
                                    id={"carClubBookingReferences" + index}
                                    key={"bookingReferences_".concat(
                                      index.toString()
                                    )}
                                  >
                                  <Typography variant="body1">{getBookingReference(p)}</Typography>
                                  </TableRow>
                                ))
                              })}
                            </TableBody>
                          </Table>
                        </TableCell>
                      </TableRow>
                    )}
                  <TableRow>
                    <TableCell
                      className={classes.tableHeader}
                      component="th"
                      role="rowheader"
                    >
                      <Typography variant="h2">{t("StartDateTime")}</Typography>
                    </TableCell>
                    <TableCell
                      id="startDateTimeDiv"
                      className={classes.tableBody}
                    >
                      <Typography variant="body1">{resData?.startDateString === null
                        ? ""
                        : moment(resData?.startDateString, ukDate).format(
                            t("DateTimeFormat")
                          )}</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      className={classes.tableHeader}
                      component="th"
                      role="rowheader"
                    >
                      <Typography variant="h2">{t("EndDateTime")}</Typography>
                    </TableCell>
                    <TableCell
                      id="endDateTimeDiv"
                      className={classes.tableBody}
                    >
                      <Typography variant="body1">   {resData?.endDateString === null
                        ? ""
                        : moment(resData?.endDateString, ukDate).format(
                            t("DateTimeFormat")
                          )}{" "}</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      className={classes.tableHeader}
                      component="th"
                      role="rowheader"
                    >
                      <Typography variant="h2">{t("StartLocation")}</Typography>
                    </TableCell>
                    <TableCell
                      id="startAddressDiv"
                      className={classes.tableBody}
                    >
                      <Typography variant="body1">{resData?.carClubStartLocationLabel}{" "}</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      className={classes.tableHeader}
                      component="th"
                      role="rowheader"
                    >
                      <Typography variant="h2">{t("EndLocation")}</Typography>
                    </TableCell>
                    <TableCell id="endAddressDiv" className={classes.tableBody}>
                    <Typography variant="body1">  {resData?.carClubStartLocationLabel}{" "}</Typography>
                    </TableCell>
                  </TableRow>
                  {resData?.carClubViaLocationLabel != null &&
                    resData?.carClubViaLocationLabel.length > 0 && (
                      <TableRow id="midPointsDiv">
                        <TableCell
                          className={classes.tableHeader}
                          component="th"
                          role="rowheader"
                        >
                          <Typography variant="h2">{t("Res.CarClubViaLocation")}</Typography>
                        </TableCell>
                        <TableCell className={classes.subTableHeader}>
                          <Table aria-label="journey table">
                            <TableBody>
                              {resData?.carClubViaLocationLabel?.map(
                                (p, index) => {
                                  return (
                                    <TableRow
                                      key={"carClubViaLocation_".concat(
                                        index.toString()
                                      )}
                                    >
                                      <Typography variant="body1">
                                        {p}
                                      </Typography>
                                    </TableRow>
                                  );
                                }
                              )}
                            </TableBody>
                          </Table>
                        </TableCell>
                      </TableRow>
                    )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box style={{ paddingLeft: 15 }}>
            <TableContainer id="driverDetailsTable">
              <Table>
                <TableBody>
                  {props.isSharedAccessEnabled && (
                    <TableRow>
                      <TableCell
                        width="30%"
                        className={classes.tableHeader}
                        component="th"
                        role="rowheader"
                      >
                        <Typography variant="h2">{" "}
                        {t("Res.booker")}</Typography>
                      </TableCell>
                      <TableCell
                        id="bookerNameDiv"
                        width="70%"
                        className={classes.tableBody}
                      >
                        <Typography variant="body1">{resData?.bookerName}</Typography>
                      </TableCell>
                    </TableRow>
                  )}
                  <TableRow>
                    <TableCell
                      width="30%"
                      className={classes.tableHeader}
                      component="th"
                      role="rowheader"
                    >
                      <Typography variant="h2">{t("Res.Driver")}</Typography>{" "}
                      
                    </TableCell>
                    <TableCell
                      id="driverNameDiv"
                      width="70%"
                      className={classes.tableBody}
                    >
                      <Typography variant="body1">{resData?.carClubDriverName}{" "}</Typography>
                    </TableCell>
                  </TableRow>
                  {
                    companyInfo?.isDriverValidityActivated &&
                      resData?.additionalDrivers.length > 0 && (
                        <TableRow>
                          <TableCell
                            component="th"
                            className={classes.tableHeader0}
                          >
                           <Typography variant="h2">{t("AdditionalDriver")}</Typography>
                          </TableCell>
                          <TableCell
                            id="AdditionalDrivers"
                            className={classes.tableBody0}
                          >
                            {resData?.additionalDrivers?.map(
                              (additionalDriver, index) => {
                                return (
                                  <Typography key={index} variant="body1">
                                    {" "}
                                    {additionalDriver.firstName +
                                      " " +
                                      additionalDriver.lastName}
                                    <br />{" "}
                                  </Typography>
                                );
                              }
                            )}
                          </TableCell>
                        </TableRow>
                      )
                    // <TableRow>
                    //   <TableCell
                    //     width="30%"
                    //     className={classes.tableHeader}
                    //     component="th"
                    //     role="rowheader"
                    //     >
                    //     {" "}
                    //     {t("AdditionalDriver").concat(":")}
                    //   </TableCell>
                    // <TableCell
                    //     id="driverNameDiv"
                    //     width="70%"
                    //     className={classes.tableBody}>
                    //     {resData?.additionalDrivers?.map(
                    //     (additionalDriver, index) => {
                    //       return (
                    //         <Typography key={index} >
                    //           {/* {" "} */}
                    //           {additionalDriver.firstName +" "+additionalDriver.lastName}
                    //           <br />
                    //           {" "}
                    //         </Typography>
                    //       );
                    //     })
                    //     }
                    //   </TableCell>
                    // </TableRow>
                  }
                  <TableRow>
                    <TableCell
                      width="30%"
                      className={classes.tableHeader}
                      component="th"
                      role="rowheader"
                    >
                      <Typography variant="h2">{" "}
                      {t("Res.MemoCode")}</Typography>
                    </TableCell>
                    <TableCell
                      className={classes.tableBody}
                      id="carClubMemoDiv"
                    >
                      <Typography variant="body1"> {resData?.memoCode}{" "}</Typography>
                     
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      width="30%"
                      className={classes.tableHeader}
                      component="th"
                      role="rowheader"
                    >
                     <Typography variant="h2">{" "}
                      {t("Res.VehicleLocation")}</Typography> 
                    </TableCell>
                    <TableCell
                      width="70%"
                      className={classes.tableBody}
                      id="carClubVehicleLocationDiv"
                    >
                      <Typography variant="body1">  {resData?.vehicleLocation}{" "}</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      width="30%"
                      className={classes.tableHeader}
                      component="th"
                      role="rowheader"
                    >
                     <Typography variant="h2">{" "}{t("Res.VehicleDetails")}</Typography> 
                      
                    </TableCell>
                    <TableCell
                      width="70%"
                      className={classes.tableBody}
                      id="carClubVehicleDetailsDiv"
                    >
                         <Typography variant="body1">  {resData?.vehicleDetails}{" "}</Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            {companyInfo?.isDriverValidityActivated && (
              <>
                <TableContainer>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell
                          width="30%"
                          className={classes.tableHeader}
                          component="th"
                        >
                          <label htmlFor={"EndDateFieldLabel"}>
                          <Typography variant="h2">{t("ReservationWizard.carClubPage.lblHireCost")}</Typography>
                          </label>
                        </TableCell>
                        <TableCell
                          width="40%"
                          className={classes.tableBodyCurrency}
                        >
                          <Typography id={"HireCost"} variant="body1">
                            {getLocaleCurrency(
                              resData?.reservationEstimate?.timeAmount.toString(),
                              locale,
                              getcompanyCountryCurrency(companyCountryCode)
                            )}
                          </Typography>
                        </TableCell>
                        <TableCell
                          width="30%"
                          className={classes.tableBody}
                        ></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          width="30%"
                          className={classes.tableHeader}
                          component="th"
                        >
                          <label htmlFor={"EndDateFieldLabel"}>
                          <Typography variant="h2"> {t("ReservationWizard.carClubPage.lblBBCInsurance")}</Typography>
                           
                          </label>
                        </TableCell>
                        <TableCell
                          width="40%"
                          className={classes.tableBodyCurrency}
                        >
                          <Typography id={"BBCInsurance"} variant="body1">
                            {getLocaleCurrency(
                              resData?.reservationEstimate?.insurance.toString(),
                              locale,
                              getcompanyCountryCurrency(companyCountryCode)
                            )}
                          </Typography>
                        </TableCell>
                        <TableCell
                          width="30%"
                          className={classes.tableBody}
                        ></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          width="30%"
                          className={classes.tableHeader}
                          component="th"
                        >
                          <label htmlFor={"EndDateFieldLabel"}>
                          <Typography variant="h2">{t(
                              "ReservationWizard.carClubPage.lblTransactionFee"
                            )}</Typography> 
                          </label>
                        </TableCell>
                        <TableCell
                          width="40%"
                          className={classes.tableBodyCurrency}
                        >
                          <Typography id={"TransactionFee"} variant="body1">
                            {getLocaleCurrency(
                              resData?.reservationEstimate?.transactionFee.toString(),
                              locale,
                              getcompanyCountryCurrency(companyCountryCode)
                            )}
                          </Typography>
                        </TableCell>
                        <TableCell
                          width="30%"
                          className={classes.tableBody}
                        ></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.tableBody} colSpan={2}>
                          <Divider className={classes.divider} />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          width="30%"
                          className={classes.tableHeader}
                          component="th"
                        >
         
                          <label htmlFor={"EndDateFieldLabel"}>
                          <Typography variant="h2"> 
                            {t(
                              "ReservationWizard.carClubPage.lblTotalEstimate"
                            )}
                            </Typography>
                          </label>
                        </TableCell>
                        <TableCell
                          width="40%"
                          className={classes.tableBodyCurrency}
                        >
                          <Typography id={"TotalEstimated"} variant="body1">
                            <strong>
                              {getLocaleCurrency(
                                resData?.reservationEstimate?.ccEstimate.toString(),
                                locale,
                                getcompanyCountryCurrency(companyCountryCode)
                              )}
                            </strong>
                          </Typography>
                        </TableCell>
                        <TableCell
                          width="30%"
                          className={classes.tableBody}
                        ></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          className={classes.tableBodyCurrency}
                          colSpan={2}
                        >
                          <p style={{ fontSize: "1.0em", color: "#006639" }}>
                             {t("Res.FeeDisclaimer")}
                          </p>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            )}
          </Box>
        </Grid>
      </Grid>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          marginTop: "1em",
        }}
      >
        <Button
          id="makeAnotherBookingLink"
          variant="contained"
          color="primary"
          onClick={handleClose}
        >
          {t("Res.MakeAnotherBooking")}
        </Button>
      </div>
    </>
  );
};

export default ConfirmCarClubForm;
