export interface user {
  username: string;
  lastName: string;
  firstName: string;
  emailAddress: string;
  companyInternalNumber: string;
  company: string;
  deleted: boolean;
  actions: string;
  userRole: string;
}

export interface Item {
  disabled: boolean;
  group?: any;
  selected: boolean;
  text: string;
  value: string;
}

export interface UserTitle {
  selectedValue: string;
  selectedText: string;
  items?: Item[];
}

export interface CarClubDriver {
  carClubCompanyId: string;
  carClubDriverId: string;
}

export interface PreferredLanguage {
  selectedValue: string;
  selectedText: string;
  items?: Item[];
}

export interface Role {
  selectedValue: string;
  selectedText: string;
  items?: Item[];
}

export interface CompanyName {
  selectedValue: string;
  selectedText: string;
  items?: Item[];
}

export interface DropDownList {
  selectedValue: string;
  selectedText: string;
  items?: Item[];
}

export interface AvailableInternationalDiallingCode {
  disabled: boolean;
  group?: any;
  selected: boolean;
  text: string;
  value: string;
}

export interface PreferredPhoneNumber {
  selectedInternationalDiallingCode: string | null;
  availableInternationalDiallingCodes: AvailableInternationalDiallingCode[];
  number?: string | null;
}

export interface AlternativePhoneNumber {
  selectedInternationalDiallingCode?: any;
  availableInternationalDiallingCodes: AvailableInternationalDiallingCode[];
  number?: string;
}

export interface UserDetail {
  userTitle: UserTitle;
  b2CObjectId?: string | null;
  username: string;
  email: string;
  firstName: string;
  lastName: string;
  carClubDriver?: CarClubDriver;
  preferredLanguage?: PreferredLanguage;
  role?: Role;
  companyName?: CompanyName;
  preferredPhoneNumber?: PreferredPhoneNumber;
  preferredPhoneNumberString: string;
  alternativePhoneNumber?: any;
  alternativePhoneNumberString: string;
  lineManagerEmail?: string | null;
  pageTitle: string;
  clientIPAddress?: any;
  hostDomain?: any;
  isNew: boolean;
  canActivateDeactivateUser?: boolean;
  deleted?: boolean;
  isActive?: boolean;
  appsecUserId: string;
  isUsernameChanged?: boolean;
  isReLoginRequired?: boolean;
  availableTitles?: any;
  permissions?: number;
  isBOBOToggleEnabled?: boolean;
  enterprisePlusNumber: string;
  emeraldClubNumber: string;
}

export interface BulkUploadDetail {
  companyList?: DropDownList;
  languageList?: DropDownList;
  fileUploaded?: any | null;
}

export const InitialValue: UserDetail = {
  userTitle: { selectedText: "", selectedValue: "", items: [] },
  username: "",
  b2CObjectId: "",
  email: "",
  firstName: "",
  lastName: "",
  carClubDriver: { carClubCompanyId: "", carClubDriverId: "" },
  preferredLanguage: { selectedText: "", selectedValue: "", items: [] },
  role: { selectedText: "", selectedValue: "", items: [] },
  companyName: { selectedText: "", selectedValue: "", items: [] },
  preferredPhoneNumber: {
    selectedInternationalDiallingCode: "",
    availableInternationalDiallingCodes: [],
    number: "",
  },
  preferredPhoneNumberString: "",
  alternativePhoneNumber: {
    selectedInternationalDiallingCode: "",
    availableInternationalDiallingCodes: [],
    number: "",
  },
  alternativePhoneNumberString: "",
  lineManagerEmail: "",
  pageTitle: "",
  clientIPAddress: null,
  hostDomain: null,
  isNew: true,
  canActivateDeactivateUser: true,
  deleted: false,
  isActive: true,
  appsecUserId: "",
  isUsernameChanged: false,
  isReLoginRequired: false,
  permissions: 0,
  isBOBOToggleEnabled: false,
  enterprisePlusNumber:"",
  emeraldClubNumber:""
};

export const BulkUploadInitialValue: BulkUploadDetail = {
  companyList: { selectedText: "", selectedValue: "", items: [] },
  languageList: { selectedText: "", selectedValue: "", items: [] },
  fileUploaded: null,
};

export interface CarClubDriverContactInfo {
  lastName: string;
  firstName: string;
  email: string;
  homePhone: string;
  mobilePhone: string;
  workPhone: string;
  address: string;
  address2: string;
  city: string;
  stateOrProvince: string;
  country: string;
  postCode: string;
  borough: string;
  responseString: string;
}

export const CarClubDriverContactinfoInitialValue: CarClubDriverContactInfo = {  
  firstName: "",
  lastName: "",
  email: "",
  homePhone: "",
  mobilePhone: "",
  workPhone: "",
  address: "",
  address2: "",
  city: "",
  stateOrProvince: "",
  country: "",
  postCode: "",
  borough: "",
  responseString: "",
}
