export interface ReservationProps {
  journeyUId: string;
  origJourneyUId?: string;
  step?: number;
  addFieldsEnabled?: boolean;
  journeyDetails?: any;
  products?: any;
  isSharedAccessEnabled?: boolean;
  updateParent?: (step: number, actions: number) => void;
  handleNext?: (step: number, actions: number,enabled?:boolean) => void;
  handleCCWorkflow?: (enabled: boolean) => void;
  handleBack?: (
    step: number,
    actions: number,
    ccPwdFocused?: boolean,
    backTwoSteps?: boolean
  ) => void;
  backBtnLabel?: string;
  nextBtnLabel?: string;
  hideCCTile?: boolean;
  focusCCPwd?: boolean;
}

export enum ReservationSteps {
  JOURNEY = 0,
  RISKASSESSMENT = 1,
  PRODUCTS = 2,
  DR_DRIVERS = 14,
  ADDITIONALINFO = 3,
  RESERVATION = 4,
  BBC_RESERVATION = 16,
  DR_VEHICLES = 15,
  PAYMENT = 17,
  DETAILS = 11,
  CC_DRIVER = 10,
  VEHICLES = 5,
  REVIEW = 6,
  CONFIRM = 7,
  CC_REVIEW = 8,
  CC_CONFIRM = 9,
  SV_REQUEST = 12, 
  SV_REVIEW = 13
}

export interface WizardStep {
  id: string;
  step: number;
  name: string;
  isOptional: boolean;
  htmlId: string;
  order: number;
}

export const WizardSteps: WizardStep[] = [
  {
    id: "JourneyStep",
    step: 0,
    name: "JOURNEY",
    isOptional: false,
    htmlId: "JourneyStep",
    order: 0,
  },
  {
    id: "RiskAssessmentStep",
    step: 1,
    name: "RISKASSESSMENT",
    isOptional: false,
    htmlId: "RiskAssessmentStep",
    order: 1,
  },
  {
    id: "ProductSelectionStep",
    step: 2,
    name: "PRODUCTS",
    isOptional: false,
    htmlId: "ProductSelectionStep",
    order: 2,
  },
  {
    id: "AdditionalInfoStep",
    step: 3,
    name: "ADDITIONALINFO",
    isOptional: false,
    htmlId: "AdditionalInfoStep",
    order: 3,
  },
  {
    id: "ReservationStep",
    step: 4,
    name: "RESERVATION",
    isOptional: false,
    htmlId: "ReservationStep",
    order: 4,
  },
  {
    id: "ReservationDetailsStep",
    step: 11,
    name: "DETAILS",
    isOptional: false,
    htmlId: "ReservationDetailsStep",
    order: 11,
  },
  {
    id: "DriverDetailsCCStep",
    step: 10,
    name: "CC_DRIVER",
    isOptional: false,
    htmlId: "DriverDetailsCCStep",
    order: 10,
  },
  {
    id: "VehicleSelectionStep",
    step: 5,
    name: "VEHICLES",
    isOptional: false,
    htmlId: "VehicleSelectionStep",
    order: 5,
  },
  {
    id: "ReivewAndBookStep",
    step: 6,
    name: "REVIEW",
    isOptional: false,
    htmlId: "ReivewAndBookStep",
    order: 6,
  },
  {
    id: "ConfirmationStep",
    step: 7,
    name: "CONFIRM",
    isOptional: false,
    htmlId: "ConfirmationStep",
    order: 7,
  },
  {
    id: "ReivewCCStep",
    step: 8,
    name: "CC_REVIEW",
    isOptional: false,
    htmlId: "ReivewCCStep",
    order: 8,
  },
  {
    id: "ConfirmationCCStep",
    step: 9,
    name: "CC_CONFIRM",
    isOptional: false,
    htmlId: "ConfirmationCCStep",
    order: 9,
  },
  {
    id: "SpecialityRequestStep",
    step: 12,
    name: "SV_REQUEST",
    isOptional: false,
    htmlId: "SpecialityRequestStep",
    order: 12,
  },
  {
  id: "ReviewSpecialityRequestStep",
    step: 13,
    name: "SV_REVIEW",
    isOptional: false,
    htmlId: "ReviewSpecialityRequestStep",
    order: 13,
  },
  {
    id: "DrDriverDetailsStep",
    step: 14,
    name: "DR_DRIVERS",
    isOptional: false,
    htmlId: "DrDriverDetailsStep",
    order: 14,
  },
  {
    id: "DrVehiclesSelectionStep",
    step: 15,
    name: "DR_VEHICLES",
    isOptional: false,
    htmlId: "DrVehiclesSelectionStep",
    order: 15,
  },
  {
    id: "BBCReservationStep",
    step: 16,
    name: "Reservation",
    isOptional: false,
    htmlId: "BBCReservationStep",
    order: 16,
  },
  {
    id: "PaymentStep",
    step: 17,
    name: "Payment",
    isOptional: false,
    htmlId: "PaymentStep",
    order: 17,
  }
];

export const StepIds: { [key: string]: string } = {
  JOURNEY: "JourneyStep",
  RISKASSESSMENT: "RiskAssessmentStep",
  PRODUCTS: "ProductSelectionStep",
  ADDITIONALINFO: "AdditionalInfoStep",
  RESERVATION: "ReservationStep",
  BBC_RESERVATION: "BBCReservationStep",
  VEHICLES: "VehicleSelectionStep",
  REVIEW: "ReivewAndBookStep",
  CONFIRM: "ConfirmationStep",
  CC_REVIEW: "ReivewCCStep",
  CC_CONFIRM: "ConfirmationCCStep",
  CC_DRIVER: "DriverDetailsCCStep",
  DR_DRIVERS: "DrDriverDetailsStep",
  DR_VEHICLES: "DrVehiclesSelectionStep",
  PAYMENT: "PaymentStep"
};
