import useProgress from "./hooks/UseProgress";

export default function ProgressBar({
  animate,
  time
}: {
  animate: boolean;
  time: number;
}) {
  let progress = useProgress(animate, time);

  return (
    <div className="ProgressBar">
      <div style={{ width: `${progress * 100}%` }} />
    </div>
  );
}
