import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { useDispatch } from "react-redux";
import { clearAlertMessage } from "../components/store/actions/shared/alertActions";

const TermsOfUse = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const lng = queryParams.get("locale");
  var language = lng !== null ? lng.toString() : "en-GB";

  useEffect(() => {
    document.title = "Enterprise Rent-A-Car - Terms Of Use";
    dispatch(clearAlertMessage());
  });

  return language === "de-DE" ? (
    <div style={{ marginLeft: "1em", marginBottom: "1em" }}>
      <div style={{ marginLeft: "1em", marginBottom: "1em" }}>
        <div>
          <Button
            variant="outlined"
            color="primary"
            title={"Klicken Sie zum Drucken"}
            aria-label={"Klicken Sie zum Drucken"}
            onClick={() => {
              window.print();
            }}
          >
            {" "}
            {"Klicken Sie zum Drucken"}
          </Button>
        </div>
        <h1>NUTZUNGSBEDINGUNGEN</h1>
        <b> Enterprise Travel Direct</b> <br />
        <b> Nutzungsbedingungen für die Website oder die mobile App</b> <br />
        <b> Zuletzt aktualisiert: 2024 </b>
        <br /> <br />
        <b> EINLEITUNG </b>
        <br />
        EAN Services, LLC („Unternehmen“ oder „wir“) stellt diese Website oder
        mobile App („Site“) vorbehaltlich dieser Nutzungsbedingungen und aller
        geltenden Gesetze für Ihre Nutzung zur Verfügung. Bitte lesen Sie sich
        die Nutzungsbedingungen aufmerksam durch. Indem Sie auf die Site
        zugreifen und/oder sie nutzen, erklären Sie sich vollständig und
        bedingungslos mit diesen Nutzungsbedingungen einverstanden,
        einschließlich der verbindlichen Schiedsgerichtsbarkeit. Sollten Sie
        diesen Nutzungsbedingungen nicht zustimmen, sehen Sie von einem Besuch
        der Site und ihrer Nutzung ab. Enterprise behält sich das Recht vor, die
        Nutzungsbedingungen anzupassen. Daher empfehlen wir Ihnen, diese von
        Zeit zu Zeit erneut zu lesen. Durch die weitere Nutzung dieser Site nach
        Bekanntgabe von Änderungen der Nutzungsbedingungen drücken Sie Ihr
        Einverständnis hinsichtlich dieser Änderungen aus. Alle Änderungen
        werden sofort wirksam, wenn wir sie veröffentlichen, und gelten für die
        spätere Nutzung der Site.
        <p />
        <b>NUTZUNG DER SITE </b>
        <br />
        Enterprise unterhält diese Site für Ihre persönliche Nutzung zu
        informativen und nicht gewerblichen Zwecken. Die Nutzung dieser Site für
        andere Zwecke bedarf der ausdrücklichen vorherigen schriftlichen
        Zustimmung seitens Enterprise. Ohne Einschränkung des Vorstehenden ist
        Ihnen Folgendes untersagt: (1) diese Site für kommerzielle Zwecke zu
        nutzen, inkl. der Verbreitung, Übertragung oder Veröffentlichung der
        Site oder ihrer Inhalte, (2) die Nutzung der Site durch andere Personen
        zu beeinträchtigen, (3) den Betrieb der Site zu beeinträchtigen oder die
        damit verbundenen Server oder Netzwerke zu stören, (4) die Rechte an
        geistigem Eigentum von Enterprise zu verletzen, (5) die Site oder deren
        Inhalte zu manipulieren oder für eigene Zwecke zu entfremden, (6) eine
        Verlinkung zu Teilen der Site einzurichten oder (7) diese Site für
        illegale Zwecke zu verwenden. Wir behalten uns das Recht vor, Ihre
        Nutzung der Site jederzeit und ohne Haftung gegenüber Ihnen oder Dritten
        ohne explizite Benachrichtigung zu beenden oder einzuschränken. Wir
        können in diesem Fall Ihren Internetanbieter über Ihre Handlungen
        informieren und entsprechende rechtliche Schritte einleiten.
        <p />
        <b>ÄNDERUNGEN DER SITE </b>
        <br />
        Wir behalten uns das Recht vor, im alleinigen Ermessen Teile der Site
        jederzeit und ohne vorherige Ankündigung oder Haftung Ihnen bzw. Dritten
        gegenüber zu ändern, auszusetzen oder einzustellen. Wir behalten uns
        ferner das Recht vor, im alleinigen Ermessen bestimmte Funktionen und
        Dienste einzuschränken.
        <p />
        <b>LINKS ZU ANDEREN WEBSITES ODER MOBILEN APPS</b>
        <br />
        Die Site kann Links zu Websites oder mobilen Apps von Drittanbietern
        enthalten. Enterprise kontrolliert nicht den Inhalt, die
        Nutzungsbedingungen oder die Datenschutzrichtlinien verlinkter Websites
        oder mobiler Apps und ist nicht für diese verantwortlich, und Ihre
        Nutzung solcher Websites und mobiler Apps Dritter unterliegt den
        jeweiligen Nutzungsbedingungen und Datenschutzrichtlinien. Die
        Anbringung eines Links auf der Site bedeutet nicht, dass wir ihn
        befürworten.
        <p />
        <b>RESERVIERUNGEN UND TRANSAKTIONEN</b>
        <br />
        Jegliche über die Site übermittelten Reservierungen und Transaktionen
        bedürfen der Zustimmung von Enterprise, die in unserem alleinigen
        Ermessen liegt. Ohne Einschränkung bedeutet dies, dass Enterprise die
        Annahme von Reservierungen oder Transaktionen nach eigenem Ermessen und
        ohne Haftung Ihnen oder Dritten gegenüber verweigern oder stornieren
        kann, unabhängig davon, ob die Reservierung oder Transaktion bestätigt
        wurde oder nicht. Der Mietvertrag, der Ihre Anmietung regelt
        („Mietvertrag“), wird gesondert zwischen dem verbundenen Unternehmen von
        Enterprise, das die Mietstation betreibt („Eigentümer“), und Ihnen
        geschlossen; sofern nicht ausdrücklich unter „Check-in“ unten angegeben,
        wird er nicht über diese Site geschlossen. Alle Anmietungen unterliegen
        weiterhin der Verfügbarkeit und den geltenden Qualifikationen und
        Mietbedingungen. Darüber hinaus sind alle Reservierungsanfragen für Sie
        und den Eigentümer unverbindlich. Der Eigentümer ist nicht verpflichtet,
        reservierte Fahrzeuge verfügbar zu halten.
        <p />
        <b>COUPONS</b>
        <br />
        Enterprise stellt von Zeit zu Zeit spezielle Rabattcoupons zur
        ausschließlichen Verwendung für Mitarbeiter oder Mitglieder bestimmter
        Unternehmen bereit, mit denen Enterprise eine Vertragsbeziehung
        unterhält. Diese unternehmensspezifischen Coupons und die darauf
        angebrachten proprietären Codes sind unser Eigentum und dürfen nur von
        den Mitarbeitern oder Mitgliedern der spezifischen Unternehmen verwendet
        werden, für die die Coupons gelten. DIE UNBEFUGTE VERWENDUNG ODER
        VERVIELFÄLTIGUNG UNSERER UNTERNEHMENSSPEZIFISCHEN RABATTCOUPONS IST
        STRENGSTENS UNTERSAGT.
        <p />
        <b>CHECK-IN </b>
        <br />
        Um über die Site einchecken zu können, müssen Sie (i) uns oder einem von
        uns benannten Dritten personenbezogene Daten übermitteln, die
        insbesondere Ihren Namen, Ihre E-Mail-Adresse, Ihre Telefonnummer, Ihr
        Geburtsdatum, Informationen zu Ihrer Zahlungskarte und zu Ihrem
        Führerschein umfassen können, (ii) das Datum, die Uhrzeit, die
        Mietstation und die Fahrzeugklasse für Ihre Reservierung bestätigen und
        (iii) Schutzmaßnahmen und Zusatzprodukte annehmen oder ablehnen.
        Möglicherweise werden Sie auch aufgefordert, dem Mietvertrag
        elektronisch zuzustimmen. Vorsorglich wird angemerkt, dass Verweise auf
        die „Zusammenfassung des Mietvertrags“ oder die „Zusammenfassung“ im
        Mietvertrag auch von Ihnen bereitgestellte Informationen sowie Ihnen
        beim Check-in vorgelegte Informationen enthalten. Änderungen Ihrer
        Mietdaten zum Zeitpunkt der Abholung können dazu führen, dass Sie zu
        diesem Zeitpunkt einen neuen Mietvertrag abschließen müssen. Gemäß
        unserer nachfolgend beschriebenen globalen Datenschutzrichtlinie ziehen
        wir Dritte (insbesondere LexisNexis zur Betrugsprävention) heran, oder
        in einigen Fällen übermitteln Sie die Informationen zur Abwicklung der
        Transaktion direkt an diese Dritten. Sie nehmen ausdrücklich zur
        Kenntnis und erklären sich damit einverstanden, dass jegliche Änderungen
        an Ihrer Reservierung, insbesondere Upgrades, Schutzmaßnahmen und
        Zusatzprodukte, sowie Änderungen der Abhol- oder Rückgabedaten, -zeiten
        oder -orte zu höheren Kosten und Gebühren im Zusammenhang mit Ihrer
        Reservierung führen können. Wenn Sie den Check-in nicht über die Site
        vornehmen, müssen Sie bei Ankunft an der Mietstation einchecken.
        <p />
        <b>ZAHLUNGSAUTORISIERUNG</b>
        <br />
        Bevor Sie den Check-in abschließen, werden Ihre geschätzten Gebühren zur 
        Bestätigung angezeigt. Mit der elektronischen Zustimmung zum Mietvertrag 
        oder dem Speichern Ihrer Kartendetails ermächtigen Sie den Halter (über einen Drittanbieter)
        zur Belastung dieser Kreditkarte und/oder Debitkarte mit allen Beträgen,
        die Sie im Rahmen des Mietvertrags für Vorauszahlungen und zusätzliche 
        Genehmigungen/Kautionen oder anderweitig schulden, sowie für Zahlungen, 
        die von einem Dritten, an den die Rechnung gerichtet wurde, verweigert wurden, 
        und zwar allesamt gemäß den Bedingungen des Mietvertrags. Sie erteilen dem Halter
        zudem die Erlaubnis, diese Kreditkarte/Debitkarte erneut zu belasten, wenn der 
        erste Abbuchungsversuch fehlschlägt.
        <p />
        <b>ELEKTRONISCHE KOMMUNIKATION</b>
        <br />
        Durch die Nutzung der Site erklären Sie sich damit einverstanden,
        elektronische Mitteilungen von Enterprise zu erhalten. Sie erklären sich
        damit einverstanden, dass jede Mitteilung, Vereinbarung, Offenlegung
        oder andere Kommunikation, die wir Ihnen elektronisch zusenden, alle
        gesetzlichen Kommunikationsanforderungen erfüllt, einschließlich der
        Anforderung, dass solche Mitteilungen schriftlich erfolgen müssen.
        <p />
        <b>ELEKTRONISCHE UNTERZEICHNUNG VON DOKUMENTEN</b>
        <br />
        Durch die Nutzung der Site erklären Sie sich damit einverstanden,
        Geschäftsvorgänge elektronisch mit elektronischen Signaturen anstelle
        von Papierdokumenten durchzuführen.
        <p />
        <b>GEISTIGES EIGENTUM</b>
        <br />
        Alle urheberrechtsfähigen Texte, Grafiken, Tonaufnahmen, Downloads,
        Software, einschließlich Quell- und Binärcode, und sonstigen Materialien
        (zusammenfassend als „Inhalt“ bezeichnet), die Auswahl,
        Zusammenstellung, Anordnung und Präsentation aller Materialien sowie das
        allgemeine Erscheinungsbild der Site sind urheberrechtlich durch
        Enterprise geschützt oder wurden an Enterprise lizenziert und sind
        gesetzlich geschützt. Alle Rechte vorbehalten. Sie dürfen den Inhalt der
        Site nur für Ihren persönlichen und nicht gewerblichen Hausgebrauch von
        Ihrem Computer aus ausdrucken. Dabei wird vorausgesetzt, dass Sie
        jegliche Urheberrechtsangaben, geschützten Markenzeichen und Hinweise
        zum geistigen Eigentum im Inhalt belassen. Die Nutzung des Inhalts für
        andere Zwecke ist strengstens verboten, es sei denn, Sie verfügen über
        unsere ausdrückliche vorherige schriftliche Genehmigung. Die Site kann
        auch Inhalte aufweisen, die von Drittanbietern, wie unseren Partnern und
        Werbetreibenden, erstellt wurden. Diese Inhalte von Drittanbietern
        dürfen nur im Rahmen der ausdrücklichen Genehmigung des entsprechenden
        Eigentümers genutzt werden. Alle Anfragen bezüglich einer Erlaubnis zum
        erneuten Ausdrucken oder zur Nutzung des Inhalts sind an folgende
        Adresse zu richten: Intellectual Property Manager, Copyright Reprint
        Permission, 600 Corporate Park Drive, St. Louis, Missouri 63105 oder{" "}
        <a href="mailto:nicadmin@erac.com">nicadmin@erac.com</a>. Enterprise
        übernimmt keine Gewährleistung dafür, dass Ihre Verwendung von
        Materialien, die auf der Site angezeigt werden oder mit dieser verknüpft
        sind, keine Rechte von Drittanbietern verletzt.
        <p />
        <p>
          ENTERPRISE, das „e“-Logo, WWW.ENTERPRISE.COM, ENTERPRISE.COM, WE’LL
          PICK YOU UP, ARMS, ECARS, MONTH OR MORE, CORPORATE CLASS, EVERYDAY
          SALE sind Marken oder Dienstleistungsmarken von Enterprise, wobei dies
          keine erschöpfende Auflistung aller Marken und Dienstleistungsmarken
          von Enterprise darstellt. Mit dem ®-Symbol gekennzeichnete
          Handelsmarken und Dienstleistungsmarken sind beim US-amerikanischen
          Patent and Trademark Office (US-Patent- und Markenamt) und in
          zahlreichen Ländern eingetragen. Andere Handelsmarken und
          Dienstleistungsmarken im Eigentum von Enterprise können mit dem Symbol
          „SM“ oder „TM“ versehen sein. Unsere Handelsmarken und
          Dienstleistungsmarken dürfen ausschließlich nach vorheriger
          schriftlicher Genehmigung in Verbindung mit Produkten oder
          Dienstleistungen verwendet werden. Alle anderen Marken, Handelsmarken
          und Namen, die nicht Eigentum von Enterprise sind, aber auf der Site
          angezeigt werden, sind Eigentum ihrer jeweiligen Inhaber, die
          gegebenenfalls an Enterprise angeschlossen oder damit verbunden sind
          oder von Enterprise unterstützt werden.
        </p>
        <p>
          Enterprise hat in den USA und anderen Ländern verschiedene Patente
          erhalten und angemeldet, die sich auf unterschiedliche Aspekte dieser
          Site beziehen. Benutzer werden hiermit darauf hingewiesen, dass das
          Kopieren, Übernehmen, Verwenden oder anderweitige Nutzen von
          Techniken, Menüfunktionen und sonstigen Funktionen oder Merkmalen zur
          Haftung wegen Patentverletzung führen kann.
        </p>
        <b>ANSPRÜCHE AUS URHEBERRECHTSVERLETZUNGEN</b>
        <br />
        Wenn Sie der Meinung sind, dass Materialien auf der Site Ihre geistigen
        Eigentumsrechte verletzen, wenden Sie sich bitte an Enterprise unter
        Intellectual Property Manager, Intellectual Property Questions, 600
        Corporate Park Drive, St. Louis, Missouri 63105 oder{" "}
        <a href="mailto:nicadmin@erac.com">nicadmin@erac.com</a>. Bitte fügen
        Sie Folgendes bei: (1) eine elektronische oder physische Unterschrift
        der Person, die befugt ist, im Namen des Inhabers des Urheberrechts oder
        anderer Rechte zu handeln; (2) eine Beschreibung des urheberrechtlich
        geschützten Werks oder des sonstigen geschützten Materials, das Ihrer
        Ansicht nach verletzt wurde; (3) eine Beschreibung der Stelle, an der
        sich die Materialien auf der Site befinden; (4) Ihre Adresse,
        Telefonnummer und E-Mail-Adresse; (5) eine eidesstattliche Erklärung,
        dass Sie im guten Glauben der Auffassung sind, dass die beanstandete
        Verwendung nicht durch den Inhaber des Urheberrechts oder anderer
        Eigentumsrechte bzw. dessen Vertreter oder das Gesetz autorisiert wurde;
        und (6) Ihre eidesstattliche Erklärung, dass die Angaben in Ihrer
        Mitteilung richtig sind und dass Sie der Inhaber des Urheberrechts oder
        anderer Rechte sind oder befugt sind, im Namen des Inhabers zu handeln.
        <p />
        <b>
          DATENSCHUTZRICHTLINIE UND VERWENDUNG DER ÜBERMITTELTEN INFORMATIONEN
        </b>
        <br />
        Alle Informationen, die auf der Site übermittelt oder über die Site
        gesammelt werden, unterliegen unserer globalen Datenschutzrichtlinie
        [LINK], deren Bestimmungen in diese Nutzungsbedingungen aufgenommen
        wurden. Enterprise behält sich das Recht vor, die globale
        Datenschutzrichtlinie von Zeit zu Zeit nach eigenem Ermessen zu ändern.
        Bei einer Änderung der globalen Datenschutzrichtlinie sind die
        Mitglieder auf die in der globalen Datenschutzrichtlinie genannte Art
        und Weise zu benachrichtigen. Sie stimmen zu, dass Enterprise
        Kommentare, Informationen oder Ideen, die Sie uns in Ihrer Kommunikation
        ggf. zusenden, ohne Vergütung, Anerkennung oder Bezahlung an Sie für
        beliebige Zwecke verwenden kann, wie unter anderem das Entwickeln,
        Fertigen und Vermarkten von Produkten und Dienstleistungen sowie das
        Erstellen, Ändern oder Verbessern der Site oder anderer Produkte bzw.
        Dienstleistungen.
        <p />
        <b>PASSWORTSICHERHEIT</b>
        <br />
        Wenn Sie über einen Benutzernamen und/oder ein Passwort für den Zugriff
        auf nicht öffentliche Bereiche der Site verfügen, sind Sie allein für
        die Geheimhaltung und Verwendung des Benutzernamens und/oder Passworts
        verantwortlich. Außerdem sind Sie für alle Aktivitäten verantwortlich,
        die in Verbindung mit Ihrem Benutzernamen und/oder Passwort stattfinden.
        Sie allein sind verantwortlich für alle Transaktionen, die durch die
        Verwendung Ihres Benutzernamens und/oder Passworts auf der Site
        eingeleitet werden, sowie für alle Nachrichten, Äußerungen, Handlungen
        oder Unterlassungen, die auf der Site erfolgen. Sie erklären sich damit
        einverstanden, uns unverzüglich über jeden unbefugten Zugriff auf Ihren
        Benutzernamen und/oder Ihr Passwort und über jede andere
        Sicherheitsverletzung zu informieren.
        <p />
        <b>KEINE GEWÄHR</b>
        <br />
        Wir bemühen uns nach Kräften, die Richtigkeit und Zuverlässigkeit der
        Site zu wahren, gewährleisten jedoch nicht, dass sie stets
        unterbrechungs- oder fehlerfrei funktioniert. Wir lehnen jegliche
        Verantwortung oder Haftung für Fehler oder Auslassungen auf der Site
        oder bei Problemen in Bezug auf deren Betrieb ab. Der Zugriff auf die
        Site und deren Nutzung erfolgt auf eigenes Risiko. Ohne Einschränkung
        der vorstehenden Ausführungen WERDEN DIE SITE, IHR INHALT UND SÄMTLICHE
        DARÜBER ANGEBOTENEN PRODUKTE UND DIENSTLEISTUNGEN OHNE MÄNGELGEWÄHR UND
        SOWEIT VERFÜGBAR, OHNE GEWÄHRLEISTUNG JEGLICHER ART, WEDER AUSDRÜCKLICH
        NOCH IMPLIZIERT, INSBESONDERE DIE IMPLIZIERTE GEWÄHRLEISTUNG DER
        MARKTGÄNGIGKEIT, DER EIGNUNG FÜR EINEN BESTIMMTEN ZWECK, DES
        RECHTSANSPRUCHS UND DER NICHTVERLETZUNG VON RECHTEN ANGEBOTEN. In
        einigen Gerichtsbarkeiten ist der Ausschluss von gewissen
        Gewährleistungen nicht möglich, sodass der obige Abschnitt eventuell
        nicht zutrifft.
        <p />
        <b>HAFTUNGSBESCHRÄNKUNG</b>
        <br />
        UNTER KEINEN UMSTÄNDEN HAFTEN ENTERPRISE UND SEINE VERBUNDENEN
        UNTERNEHMEN SOWIE IHRE VERANTWORTLICHEN DIREKTOREN, FÜHRUNGSKRÄFTE,
        ANGESTELLTEN, NACHFOLGER, BEAUFTRAGTEN UND VERTRETER (zusammenfassend
        „Enterprise Holdings“) FÜR DIREKTE, INDIREKTE, BEILÄUFIGE, BESONDERE
        ODER FOLGESCHÄDEN SOWIE STRAFSCHADENERSATZFORDERUNGEN ODER ENTGANGENEN
        GEWINN, GOODWILL, ERSPARNISSE ODER NUTZUNG JEGLICHER ART IM ZUSAMMENHANG
        MIT DER DURCHFÜHRUNG, VERLETZUNG ODER KÜNDIGUNG DIESER
        NUTZUNGSBEDINGUNGEN ODER DER BEENDIGUNG DIESER NUTZUNGSBEDINGUNGEN IN
        VERBINDUNG MIT IHREM ZUGANG, IHRER NUTZUNG ODER DER UNFÄHIGKEIT ZUR
        NUTZUNG DER SITE, DEN VON DER SITE ZUR VERFÜGUNG GESTELLTEN
        INFORMATIONEN ODER DEN ÜBER DIE SITE GETÄTIGTEN TRANSAKTIONEN,
        UNABHÄNGIG DAVON, OB EIN SOLCHER SCHADEN VORHERSEHBAR WAR ODER NICHT,
        UNABHÄNGIG DAVON, OB SIE AUF DIE MÖGLICHKEIT EINES SOLCHEN SCHADENS
        HINGEWIESEN WURDEN ODER NICHT UND UNABHÄNGIG DAVON, OB EINE SOLCHE
        HAFTUNG AUF DER GRUNDLAGE EINES VERTRAGS, EINER UNERLAUBTEN HANDLUNG
        (EINSCHLIESSLICH FAHRLÄSSIGKEIT ODER VERSCHULDENSUNABHÄNGIGER HAFTUNG)
        ODER ANDERWEITIG GELTEND GEMACHT WIRD.
        <p>
          In einigen Gerichtsbarkeiten ist der Ausschluss von gewissen Schäden
          nicht möglich, sodass der obige Abschnitt eventuell nicht zutrifft.
          Sollten Teile dieses Abschnitts von offizieller Stelle für nicht
          durchsetzbar erklärt werden, ist die Haftung auf den maximalen
          gesetzlich zulässigen Umfang beschränkt.
        </p>
        <p>
          Die vorstehende Haftungsbeschränkung gilt nicht für Einwohner von New
          Jersey. In Bezug auf Einwohner von New Jersey haften Enterprise, seine
          verbundenen Unternehmen und die Direktoren, leitenden Angestellten,
          Mitarbeiter und Vertreter dieser Unternehmen nicht für Schäden, es sei
          denn, diese Schäden sind das Ergebnis unserer fahrlässigen oder
          rücksichtslosen Handlungen oder Unterlassungen; und wir sind in keinem
          Fall haftbar für indirekte, zufällige oder Folgeschäden und zahlen
          keinen Strafschadenersatz.
        </p>
        <p />
        <b>FREISTELLUNG</b>
        <br />
        Sie erklären, Enterprise Holdings von allen Ansprüchen Dritter
        freizustellen, einschließlich angemessener Anwaltsgebühren und -kosten,
        die sich direkt oder indirekt, ganz oder teilweise aus Ihrem Zugriff auf
        die Site oder deren Nutzung oder aus Ihrer Verletzung von Gesetzen oder
        Rechten Dritter ergeben.
        <p />
        <b>BEILEGUNG VON STREITIGKEITEN – OBLIGATORISCHE SCHIEDSVEREINBARUNG</b>
        <br />
        <b>
          SIE UND ENTERPRISE VERZICHTEN JEWEILS AUF DAS RECHT AUF EIN
          JURYVERFAHREN ODER AUF TEILNAHME AN EINER SAMMELKLAGE GEMÄSS DEN
          FOLGENDEN BEDINGUNGEN. SIE UND ENTERPRISE ERKLÄREN, ALLE ANSPRÜCHE,
          STREITIGKEITEN ODER KONFLIKTE JEGLICHER ART („ANSPRÜCHE“)
          GEGENEINANDER PER SCHIEDSVERFAHREN ZU SCHLICHTEN, DIE MIT DIESEN
          NUTZUNGSBEDINGUNGEN IM ZUSAMMENHANG STEHEN, INSBESONDERE ANSPRÜCHE IN
          BEZUG AUF PRODUKTE UND DIENSTLEISTUNGEN, GEBÜHREN, WERBUNG ODER
          MIETFAHRZEUGE VON ENTERPRISE. FÜR DIE ZWECKE DIESER REGELUNG ZUR
          BEILEGUNG VON STREITIGKEITEN UMFASST „SIE“ AUCH ALLE IHRE VERTRETER,
          BEGÜNSTIGTEN, BEAUFTRAGTEN ODER PERSONEN, DIE IM NAMEN DES
          VORSTEHENDEN HANDELN, UND „ENTERPRISE“ UMFASST AUCH ENTERPRISE
          HOLDINGS UND SEINE LIEFERANTEN, INSBESONDERE SEINE DIENSTLEISTER UND
          MARKETINGPARTNER. SIE UND ENTERPRISE ERKLÄREN SICH DAMIT
          EINVERSTANDEN, DASS KEINE ANSPRÜCHE ALS TEIL EINER SAMMELKLAGE, EINER
          KLAGE EINES PRIVATANWALTS ODER EINES ANDEREN REPRÄSENTATIVEN
          VERFAHRENS VERFOLGT ODER GELÖST WERDEN, DASS KEIN SCHIEDSGERICHT FÜR
          DIE ENTSCHEIDUNG VON ANSPRÜCHEN AUF EINER GRUPPENWEITEN, KOLLEKTIVEN
          ODER KONSOLIDIERTEN BASIS ZUSTÄNDIG IST UND DASS KEINE REGELN ODER
          ANDEREN VERFAHREN FÜR GRUPPENWEITE ODER KOLLEKTIVE SCHIEDSVERFAHREN
          GELTEN. Diese Bestimmung zur Beilegung von Streitigkeiten ist
          allgemein auszulegen und gilt für alle Ansprüche, die auf Verträgen,
          unerlaubten Handlungen, Gesetzen oder anderen Rechtsgrundlagen
          beruhen, sowie für alle Ansprüche, die vor oder nach Beendigung dieser
          Nutzungsbedingungen entstanden sind. Die Parteien vereinbaren jedoch,
          dass jede Partei eine Einzelklage bei einem zuständigen
          Bagatellgericht einreichen kann, sofern die Klage bei diesem Gericht
          verbleibt (mit Ausnahme einer Berufung gegen das Urteil des
          Bagatellgerichts), nur in Ihrem Namen oder gegen Sie erhoben wird und
          nicht Teil einer Sammelklage, einer Klage eines privaten Anwalts oder
          einer anderen repräsentativen oder kollektiven Klage ist. Die Parteien
          vereinbaren außerdem, dass Ansprüche gegen oder von einer dritten
          Versicherungsgesellschaft, die angeblich Versicherungsschutz für Sie
          oder einen BAB bietet, oder die Anwendung der finanziellen
          Verantwortung von Enterprise im Zusammenhang mit der Nutzung oder dem
          Betrieb eines Mietfahrzeugs von Enterprise vor ein zuständiges Gericht
          gebracht werden können.
        </b>
        <p />
        <b>VERFAHREN</b>
        <br />
        Eine Partei muss der anderen Partei eine schriftliche Streitanzeige
        („Anzeige“) zukommen lassen, in der (a) die Art und die Grundlage des
        Anspruchs und (b) die beantragte Abhilfe beschrieben werden. Die Anzeige
        an Enterprise ist zu richten an CT Corporation, 208 S LaSalle, Suite
        814, Chicago, IL 60604 („Anzeigenanschrift“). Wenn Sie und Enterprise
        den Anspruch nicht innerhalb von dreißig (30) Tagen nach Eingang der
        Anzeige beilegen, kann eine Partei ein Schiedsverfahren einleiten, indem
        sie bei der American Arbitration Association („AAA“) gemäß deren
        Schiedsgerichtsordnung für Verbraucher ein Schiedsverfahren beantragt.
        Ansprüche werden gemäß der zum Zeitpunkt der Anforderung geltenden
        Schiedsgerichtsordnung für Verbraucher der AAA in der durch diese
        Nutzungsbedingungen geänderten Fassung beigelegt. Ein einziger
        Schiedsrichter wird jedoch gemäß der Schiedsgerichtsordnung der AAA
        ausgewählt. Der Schiedsrichter führt etwaige Anhörungen per Telefon-
        oder Videokonferenz und nicht durch persönliches Erscheinen durch, es
        sei denn, der Schiedsrichter entscheidet auf Antrag von Ihnen oder dem
        Unternehmen, dass eine persönliche Anhörung angemessen ist. Alle
        persönlichen Anhörungen werden an einem Ort abgehalten, der für beide
        Parteien unter gebührender Berücksichtigung ihrer Reisefähigkeit und
        anderer relevanter Umstände angemessen ist. Wenn die Parteien sich nicht
        auf einen Ort einigen können, sollte diese Entscheidung von der AAA oder
        vom Schiedsrichter getroffen werden. Die AAA-Regeln sind online abrufbar
        unter www.adr.org. Sofern nicht gesetzlich vorgeschrieben, dürfen weder
        eine Partei noch ein Schiedsrichter ohne vorherige schriftliche
        Zustimmung beider Parteien die Existenz, den Inhalt oder die Ergebnisse
        von Streitigkeiten oder Schiedsverfahren im Rahmen dieser Vereinbarung
        offenlegen.
        <p />
        <b>BEFUGNISSE DES SCHIEDSRICHTERS</b>
        <br />
        Der Schiedsrichter ist an diese Nutzungsbedingungen, diese Bestimmung
        zur Beilegung von Streitigkeiten, den Federal Arbitration Act („FAA“)
        und die Schiedsgerichtsordnung für Verbraucher der AAA gebunden. Der
        Schiedsrichter ist nicht befugt, Ansprüche zu verbinden oder zu
        konsolidieren oder verbundene und konsolidierte Ansprüche zu beurteilen.
        Der Schiedsrichter ist ausschließlich befugt, Streitigkeiten im
        Zusammenhang mit dem Geltungsbereich, der Auslegung, der Anwendbarkeit,
        der Durchsetzbarkeit oder der Formulierung dieser Nutzungsbedingungen,
        einschließlich einer etwaigen Unwirksamkeit, beizulegen. Die Parteien
        vereinbaren, dass die Entscheidung und der Schiedsspruch des
        Schiedsrichters endgültig und bindend sind und bei jedem Gericht, das
        nach dem FAA zuständig ist, bestätigt oder angefochten werden können.
        Der Schiedsrichter kann denselben Schadenersatz und dieselben Abhilfen
        wie ein Gericht zusprechen, allerdings nur zugunsten einer einzelnen
        Partei und für deren individuellen Anspruch.
        <p />
        <b>SCHIEDSGERICHTSKOSTEN </b>
        <br />
        Sie sind für Ihren Anteil an den Schiedsgebühren (Einreichung,
        Verwaltung usw.) verantwortlich, jedoch nur bis zu der Höhe der
        Antragsgebühren, die Ihnen entstehen würden, wenn die Ansprüche bei
        Gericht eingereicht würden. Enterprise ist für alle zusätzlichen
        Schiedsgebühren verantwortlich. Sie sind für alle anderen
        Kosten/Gebühren verantwortlich, die Ihnen im Schiedsverfahren entstehen,
        z. B. Gebühren für Anwälte und Sachverständige. Sie sind nicht
        verpflichtet, Enterprise Gebühren zu erstatten, es sei denn, der
        Schiedsrichter stellt fest, dass die Substanz Ihrer Ansprüche oder der
        beantragten Abhilfen unseriös ist. Wenn der Schiedsrichter eine solche
        Feststellung trifft, gelten die AAA-Regeln für die Zahlung aller
        Gebühren, und Enterprise kann angemessene Anwaltsgebühren fordern.
        Enterprise übernimmt alle Gebühren und Kosten, zu deren Übernahme es
        gesetzlich verpflichtet ist.
        <p />
        <b>GELTENDES RECHT UND DURCHSETZUNG</b>
        <br />
        <p>
          Ungeachtet dieser Nutzungsbedingungen erfolgt diese Bestimmung zur
          Beilegung von Streitigkeiten im Rahmen einer Transaktion, die den
          zwischenstaatlichen Handel betrifft, und unterliegt dem FAA, 9 U.S.C.
          §§ 1–16. Diese Bestimmung zur Beilegung von Streitigkeiten wurde in
          Übereinstimmung mit den Gesetzen aller Staaten verfasst. Sollte jedoch
          ein Teil davon als unwirksam oder nicht durchsetzbar erachtet werden
          oder sich als nicht auf einen Anspruch anwendbar erweisen, bleibt der
          Rest der Bestimmung zur Beilegung von Streitigkeiten in vollem Umfang
          wirksam.
        </p>
        <b>ANWENDBARES RECHT</b>
        <br />
        Diese Nutzungsbedingungen unterliegen den Gesetzen des US-Bundesstaates
        Missouri, USA, ungeachtet der Grundsätze des Kollisionsrechts.
        Ungeachtet des Vorstehenden oder anderer Bestimmungen des Mietvertrags
        unterliegt der Mietvertrag den Gesetzen des Landes, in dem Sie das Auto
        abholen.
        <p />
        <p>
          Alle Klagen zur Durchsetzung dieser Nutzungsbedingungen in Bezug auf
          Ansprüche, die nicht einem verbindlichen Schiedsverfahren unterliegen,
          müssen beim Bundesgericht im Eastern District of Missouri oder bei den
          einzelstaatlichen Gerichten in St. Louis County, Missouri, eingereicht
          werden. Durch die Nutzung der Site erklären Sie sich mit der
          Zuständigkeit dieser Gerichte einverstanden. Ungeachtet
          anderslautender gesetzlicher Bestimmungen müssen alle Ansprüche oder
          Klagegründe, die Sie bezüglich der Site haben, binnen eines (1) Jahres
          nach Entstehen des Anspruchs oder Klagegrunds geltend gemacht werden.
          Diese Beschränkung gilt nicht für Einwohner von New Jersey.
        </p>
        <b>ALLGEMEINES</b>
        <br />
        Falls ein Teil in den Nutzungsbedingungen ungültig oder nicht
        durchsetzbar sein sollte, so ist dieser Teil gemäß dem anwendbaren Recht
        auszulegen und die verbleibenden Teile sind durchzusetzen. Überschriften
        gelten nur für Referenzzwecke und definieren, begrenzen oder beschreiben
        keinesfalls den Anwendungsbereich des jeweiligen Abschnitts. Eine
        Unterlassung unsererseits, diese Nutzungsbedingungen geltend zu machen,
        stellt diese oder andere Vorgaben jedoch nicht in Frage. Diese
        Nutzungsbedingungen umfassen die gesamte Vereinbarung zwischen Ihnen und
        Enterprise in Bezug auf die Nutzung der Site.
        <p />
        <b>KONTAKT</b>
        <br />
        Falls Sie Fragen zu diesen Nutzungsbedingungen haben, kontaktieren Sie
        uns unter <a href="mailto:nicadmin@erac.com">nicadmin@erac.com</a>.{" "}
        <p />
      </div>
    </div>
  ) : language === "fr-FR" ? (
    <div style={{ marginLeft: "1em", marginBottom: "1em" }}>
      <div>
        <Button
          variant="outlined"
          color="primary"
          title={"Cliquez pour imprimer cette page"}
          aria-label={"Cliquez pour imprimer cette page"}
          onClick={() => {
            window.print();
          }}
        >
          {" "}
          {"Cliquez pour imprimer cette page"}
        </Button>
      </div>
      <h1>CONDITIONS GÉNÉRALES D’UTILISATION</h1>
      <b> Enterprise Travel Direct </b>
      <br />
      <b> Dernière mise à jour : 2024</b>
      <br /> <br />
      <b>INTRODUCTION</b>
      <br />
      EAN Services, LLC (« Enterprise » ou « Nous » / « notre » / « nos ») met
      ce site Web ou cette application mobile (le « Site ») à votre disposition,
      sous réserve des présentes Conditions générales d’utilisation (les «
      Conditions d’utilisation ») et de toutes les lois et réglementations
      applicables. Lisez attentivement les présentes Conditions d’utilisation.
      En accédant à ce Site ou en l’utilisant, vous acceptez pleinement et sans
      réserve les présentes Conditions d’utilisation, ainsi que la clause
      d’arbitrage exécutoire. Si vous refusez ces Conditions d’utilisation, ne
      visitez pas et n’utilisez pas le Site. Enterprise se réserve le droit de
      réviser les présentes Conditions d’utilisation. Nous vous invitons à
      vérifier régulièrement si des modifications ont été apportées. Votre
      utilisation continue du Site après la publication de modifications
      apportées aux présentes Conditions d’utilisation vaut acceptation de votre
      part desdites modifications. Toutes les modifications entrent
      immédiatement en vigueur lorsque Nous les publions et s’appliquent à
      l’utilisation du Site par la suite.
      <p />
      <b>UTILISATION DU SITE</b>
      <br />
      Enterprise a conçu ce Site pour votre usage personnel, à titre informatif
      et non commercial uniquement. Vous n’avez le droit d’utiliser le Site à
      toute autre fin qu’après avoir obtenu l’autorisation écrite expresse
      d’Enterprise. Sans limiter ce qui précède, vous ne pouvez pas : (1)
      utiliser le Site à des fins commerciales, y compris en distribuant, en
      transmettant ou en publiant le Site ou son contenu ; (2) empêcher ou
      limiter l’utilisation du Site par d’autres utilisateurs ; (3) perturber le
      fonctionnement du Site ou empêcher ou interrompre le fonctionnement des
      serveurs ou des réseaux connectés au Site ; (4) porter atteinte aux droits
      de propriété intellectuelle d’Enterprise ; (5) associer d’une quelconque
      manière ce Site ou son contenu à une autre marque ; (6) créer un lien
      profond (« deep linking ») vers n’importe quelle partie de ce Site ; ou
      (7) utiliser ce Site à des fins illégales. Nous nous réservons le droit
      d’interrompre ou de limiter votre utilisation du Site à notre entière
      discrétion et sans préavis, avec ou sans raison et sans obligation envers
      vous ou tout tiers. Dans ce cas, Nous pourrons informer votre fournisseur
      d’accès à Internet de vos activités et engager les poursuites judiciaires
      appropriées.
      <p />
      <b>MODIFICATIONS APPORTÉES AU SITE</b>
      <br />
      Nous nous réservons le droit, à notre entière discrétion, de modifier, de
      suspendre ou d’interrompre toute partie du Site à tout moment, et ce, sans
      préavis ni responsabilité envers vous ou tout tiers. Nous nous réservons
      également le droit d’imposer des limites à certains éléments ou services à
      notre seule discrétion.
      <p />
      <b>LIENS VERS D’AUTRES SITES WEB OU APPLICATIONS MOBILES</b>
      <br />
      Le Site peut contenir des liens vers des applications mobiles ou des sites
      Web tiers. Enterprise ne contrôle pas et n’est en aucun cas responsable du
      contenu, des conditions d’utilisation ou des politiques de confidentialité
      des applications mobiles ou sites Web indiqués en lien, et votre
      utilisation de ces applications mobiles et sites Web tiers est régie par
      leurs conditions d’utilisation et politiques de confidentialité
      respectives, le cas échéant. L’inclusion de tout lien sur le Site
      n’implique pas notre approbation de celui-ci.
      <p />
      <b>RÉSERVATIONS ET TRANSACTIONS</b>
      <br />
      Toutes les réservations et transactions réalisées sur le Site sont
      soumises à l’accord d’Enterprise, à son entière discrétion. Cela signifie,
      sans limitation, qu’Enterprise peut refuser d’accepter ou peut annuler
      toute réservation ou transaction, que celle-ci ait été confirmée ou non,
      pour quelque raison que ce soit, à sa seule discrétion et sans
      responsabilité envers vous ou un tiers. Le contrat de location qui régit
      votre location (le « Contrat de location ») est conclu séparément entre la
      société affiliée d’Enterprise qui exploite l’agence de location (le «
      Propriétaire ») et vous. Sauf disposition contraire expresse de la section
      Enregistrement ci-dessous, il n’est pas conclu par l’intermédiaire de ce
      Site. Toutes les locations sont sous réserve de disponibilité et soumises
      aux qualifications et conditions de location applicables. De plus, toutes
      les demandes de réservation sont non contraignantes pour vous et le
      Propriétaire. Le Propriétaire n’a aucun devoir ni obligation de garantir
      la disponibilité des véhicules réservés.
      <p />
      <b>BONS DE RÉDUCTION</b>
      <br />
      Enterprise fournit de temps à autre des bons de réduction spécifiques à
      l’usage exclusif des employés ou des membres d’entités spécifiques avec
      lesquelles Enterprise entretient une relation contractuelle. Ces bons de
      réduction propres à une entité et les codes de propriété associés sont
      notre propriété exclusive et ne peuvent être utilisés que par les employés
      ou les membres des entités spécifiques auxquelles ces bons de réduction
      s’appliquent. L’UTILISATION OU LA DUPLICATION NON AUTORISÉE DE BONS DE
      RÉDUCTION PROPRES À UNE ENTITÉ EST STRICTEMENT INTERDITE.
      <p />
      <b>ENREGISTREMENT </b>
      <br />
      Pour vous enregistrer par le biais du Site, vous devez (i) Nous soumettre
      ou soumettre à un tiers désigné par nos soins des informations
      personnelles, notamment votre nom, votre adresse e-mail, votre numéro de
      téléphone, votre date de naissance, les informations de votre carte de
      paiement et de votre permis de conduire, (ii) confirmer la date, l’heure,
      le lieu de location et la catégorie du véhicule réservé, et (iii) accepter
      ou refuser des protections ou suppléments. Vous pourrez également être
      invité(e) à accepter le Contrat de location par voie électronique. À des
      fins de clarification, les références au « Résumé du contrat de location »
      (ou « Résumé ») figurant dans le Contrat de location incluront également
      les informations que vous avez fournies, ainsi que celles qui vous ont été
      présentées lors de l’enregistrement. Les modifications apportées à vos
      informations de location au moment du départ peuvent vous obliger à
      conclure un nouveau Contrat de location à ce moment-là. Sous réserve
      d’application de notre Politique générale de confidentialité décrite
      ci-dessous, Nous ferons appel à des tiers (y compris, sans toutefois s’y
      limiter, LexisNexis à des fins de prévention de la fraude) ou, dans
      certains cas, vous enverrez des informations directement à ces tiers dans
      le but de faciliter la transaction. Vous reconnaissez et acceptez
      expressément que toute modification apportée à votre réservation, y
      compris, sans toutefois s’y limiter, les surclassements, les protections
      et les suppléments, ainsi que les modifications apportées aux dates,
      heures ou lieux de départ ou de retour, peuvent entraîner une augmentation
      des frais associés à votre réservation. Si vous ne vous enregistrez pas
      par le biais du Site, vous devrez vous enregistrer à votre arrivée à
      l’agence de location.
      <p />
      <b>AUTORISATION DE PAIEMENT</b>
      <br />
      Avant de finaliser le processus d’enregistrement, un récapitulatif des frais estimés s’affichera.
      En acceptant par voie électronique le Contrat de location ou en enregistrant les informations 
      relatives à votre carte, vous autorisez le Propriétaire à débiter (par le biais d’un tiers) 
      cette carte de crédit et/ou de débit de tous les frais encourus en vertu du Contrat de location pour 
      les dépôts anticipés, les autorisations/dépôts supplémentaires et tout autre montant dû par vous, 
      ainsi que les paiements refusés par un tiers à qui la facturation a été adressée, conformément aux 
      dispositions du Contrat de location. Vous autorisez également le Propriétaire à procéder de nouveau 
      au prélèvement de tous les frais sur votre carte de crédit et/ou de débit en cas de refus de paiement,
      pour quelque raison que ce soit.
      <p />
      <b>COMMUNICATIONS ÉLECTRONIQUES</b>
      <br />
      En utilisant le Site, vous consentez à recevoir des communications
      électroniques et des notifications de la part d’Enterprise. Vous convenez
      que les notifications, accords, divulgations ou autres communications que
      Nous vous envoyons par voie électronique respectent les exigences de la
      loi, y compris le fait que Nous communiquions avec vous par écrit.
      <p />
      <b>SIGNATURE ÉLECTRONIQUE DE DOCUMENTS</b>
      <br />
      En utilisant le Site, vous consentez à effectuer des transactions
      commerciales par voie électronique et à utiliser la signature électronique
      en lieu et place de documents papier.
      <p />
      <b>PROPRIÉTÉ INTELLECTUELLE</b>
      <br />
      Tous les textes, images, sons, fichiers à télécharger, logiciels (y
      compris le code source et le code binaire) et autres contenus pouvant être
      protégés par des droits d’auteur (collectivement, le « Contenu »), ainsi
      que la sélection, la compilation, la mise en page et la présentation du
      contenu, outre la conception globale du Site, sont protégés par les droits
      d’auteur par Enterprise, ou concédés sous licence à Enterprise, et
      protégés par la loi. Tous droits réservés. Vous pouvez conserver une copie
      du Contenu sur votre ordinateur pour votre usage personnel et non
      commercial, à condition de ne supprimer aucun avis de droit d’auteur, de
      marque déposée ou autre avis de propriété. Toute autre utilisation du
      Contenu est strictement interdite, sauf si Nous vous avons préalablement
      accordé une autorisation écrite à cette fin. Le Site peut également
      présenter du contenu appartenant à des tiers, y compris à nos partenaires
      et annonceurs. Vous ne pouvez utiliser ce contenu de tiers que de la
      manière formellement autorisée par le propriétaire concerné. Toutes les
      demandes d’autorisation pour la reproduction ou toute autre utilisation du
      Contenu doivent être transmises à Intellectual Property Manager, Copyright
      Reprint Permission, 600 Corporate Park Drive, St. Louis, Missouri 63105,
      États-Unis ou à l’adresse{" "}
      <a href="mailto:nicadmin@erac.com">nicadmin@erac.com</a>.  Enterprise ne
      garantit pas que votre utilisation du contenu présenté sur le Site ou lié
      à celui-ci n’enfreindra pas les droits de tiers.
      <p>
        ENTERPRISE, le logo « E », WWW.ENTERPRISE.COM, ENTERPRISE.COM, WE’LL
        PICK YOU UP, ARMS, ECARS, MONTH OR MORE, CORPORATE CLASS, EVERYDAY SALE
        sont des marques déposées et de service appartenant à Enterprise (cette
        liste de marques appartenant à Enterprise n’est pas exhaustive). Les
        marques déposées et de service accompagnées du symbole « ® » sont des
        marques déposées auprès du Bureau américain des brevets et des marques
        de commerce, ainsi que dans plusieurs pays étrangers. D’autres marques
        déposées et de service détenues par Enterprise peuvent être accompagnées
        du symbole « SM » ou « TM ». Nos marques déposées et de service ne
        peuvent pas être utilisées avec tout produit ou service sans notre
        autorisation écrite préalable. Toutes les autres marques et marques
        déposées et tous les autres noms qui ne sont pas la propriété
        d’Enterprise et apparaissent sur le Site appartiennent à leurs
        propriétaires respectifs, lesquels peuvent ou non être affiliés ou
        associés à Enterprise ou encore approuvés par Enterprise.
        <p />
        Enterprise possède plusieurs demandes de brevets en cours, tant aux
        États-Unis qu’à l’étranger, couvrant différents aspects de ce Site. Nous
        déconseillons aux utilisateurs de copier, d’emprunter ou d’utiliser de
        quelque manière que ce soit les techniques, applications de menu ou
        autres aspects ou caractéristiques fonctionnels du Site, car ils
        pourraient ainsi être poursuivis pour violation de brevet.
        <p />
        <b>ALLÉGATIONS DE VIOLATION DES DROITS D’AUTEUR</b>
        <br />
        Si vous pensez qu’une partie du contenu du Site enfreint vos droits de
        propriété intellectuelle, contactez Enterprise à l’adresse Intellectual
        Property Manager, Intellectual Property Questions, 600 Corporate Park
        Drive, St. Louis, Missouri 63105, États-Unis ou à l’adresse {" "}
        <a href="mailto:nicadmin@erac.com">nicadmin@erac.com</a>. Veuillez
        inclure : (1) une signature électronique ou physique de la personne
        autorisée à agir au nom du propriétaire des droits d’auteur ou d’autres
        droits de propriété ; (2) une description de l’œuvre protégée par des
        droits d’auteur ou d’un autre contenu protégé qui, selon vous, a fait
        l’objet d’une violation ; (3) une description de l’endroit où le contenu
        se trouve sur le Site ; (4) vos adresse, numéro de téléphone et adresse
        électronique ; (5) une déclaration de votre part indiquant que
        l’utilisation litigieuse n’est pas autorisée par le propriétaire des
        droits d’auteur ou d’un autre détenteur de droits, son mandataire ou la
        loi ; et (6) une déclaration sur l’honneur indiquant que les
        renseignements figurant dans votre demande sont exacts, et attestant que
        vous êtes le propriétaire des droits d’auteur ou d’autres droits de
        propriété, ou que vous êtes autorisé à agir au nom du propriétaire.
        <p />
        <b>
          POLITIQUE DE CONFIDENTIALITÉ ET UTILISATION DES RENSEIGNEMENTS SOUMIS
        </b>
        <br />
        Les renseignements que vous nous communiquez ou que nous recueillons sur
        le Site sont soumis à notre Politique générale de confidentialité
        [LIEN], dont les conditions sont intégrées aux présentes Conditions
        d’utilisation. Enterprise se réserve le droit de modifier ponctuellement
        la Politique générale de confidentialité, à sa seule discrétion. En cas
        de modification de la Politique générale de confidentialité, Enterprise
        émettra un avis à ce sujet selon les modalités définies dans la
        Politique générale de confidentialité. Vous convenez qu’Enterprise est
        libre d’utiliser les commentaires, les renseignements ou les idées
        contenus dans les communications que vous pouvez lui envoyer, sans
        compensation, contrepartie ni paiement, et ce, à toute fin que ce soit,
        y compris, sans toutefois s’y limiter, pour le développement, la
        fabrication et la commercialisation de produits et de services ou pour
        la création, la modification ou l’amélioration du Site ou d’autres
        produits ou services.
        <p />
        <b>SÉCURITÉ DES MOTS DE PASSE</b>
        <br />
        Si vous disposez d’un nom d’utilisateur et/ou d’un mot de passe pour
        accéder aux zones non publiques du Site, vous êtes seul responsable de
        la préservation de la confidentialité et de l’utilisation de ce nom
        d’utilisateur et/ou de ce mot de passe. En outre, vous êtes responsable
        de toutes les activités en lien avec votre nom d’utilisateur et/ou votre
        mot de passe. Vous êtes seul responsable de la totalité des transactions
        initiées, messages affichés, déclarations formulées et actes ou
        omissions sur le Site découlant de l’utilisation de votre nom
        d’utilisateur et/ou mot de passe. Vous acceptez de Nous aviser
        immédiatement de tout accès non autorisé à votre nom d’utilisateur et/ou
        à votre mot de passe ou de toute autre violation de sécurité.
        <p />
        <b>ABSENCE DE GARANTIE</b>
        <br />
        Malgré tous Nos efforts pour préserver l’exactitude et la fiabilité du
        Site, Nous ne garantissons pas ni n’affirmons qu’il sera toujours
        fonctionnel ou exempt d’erreur. Nous n’assumons aucune responsabilité en
        cas d’erreurs ou d’omissions sur le Site ou pour tout problème de
        fonctionnement. Vous accédez au Site et l’utilisez à vos propres
        risques. SANS LIMITER CE QUI PRÉCÈDE, LE SITE, SON CONTENU ET TOUT
        PRODUIT OU SERVICE OFFERT PAR LE BIAIS DE CELUI-CI SONT FOURNIS « EN
        L’ÉTAT » ET « SOUS RÉSERVE DE DISPONIBILITÉ », SANS GARANTIE D’AUCUNE
        SORTE, EXPRESSE OU IMPLICITE, Y COMPRIS, SANS TOUTEFOIS S’Y LIMITER, LES
        GARANTIES IMPLICITES DE QUALITÉ MARCHANDE, D’ADÉQUATION À UN USAGE
        PARTICULIER, DE PROPRIÉTÉ ET D’ABSENCE DE CONTREFAÇON. Certains
        territoires n’autorisent pas l’exclusion de certaines garanties. Il est
        donc possible que les limitations précédentes ne s’appliquent pas à
        votre situation.
        <p />
        <b>LIMITATION DE RESPONSABILITÉ</b>
        <br />
        ENTERPRISE, SA SOCIÉTÉ MÈRE, SES FILIALES ET SOCIÉTÉS AFFILIÉES, AINSI
        QUE CHACUN DE LEURS ADMINISTRATEURS, DIRIGEANTS, ACTIONNAIRES, EMPLOYÉS,
        SUCCESSEURS, AYANTS DROIT, REPRÉSENTANTS ET AGENTS (COLLECTIVEMENT, «
        ENTERPRISE HOLDINGS »), NE SERONT EN AUCUN CAS RESPONSABLES DE TOUT
        DOMMAGE DIRECT, INDIRECT, ACCESSOIRE, SPÉCIAL, CONSÉCUTIF OU PUNITIF, NI
        DE TOUTE PERTE DE PROFITS, DE CLIENTÈLE, D’ÉCONOMIES OU D’UTILISATION,
        DE QUELQUE SORTE OU NATURE QUE CE SOIT, DÉCOULANT DE, OU EN LIEN AVEC,
        L’EXÉCUTION, LA VIOLATION OU LA DÉNONCIATION DES PRÉSENTES CONDITIONS
        D’UTILISATION, DÉCOULANT DE, OU EN LIEN AVEC, VOTRE ACCÈS AU SITE, VOTRE
        UTILISATION DE CELUI-CI OU VOTRE INCAPACITÉ À L’UTILISER, DE MÊME QUE
        TOUTE INFORMATION FOURNIE PAR LE SITE OU TOUTE TRANSACTION CONCLUE PAR
        LE BIAIS DE CE DERNIER, QUE CES PRÉJUDICES OU PERTES SOIENT PRÉVISIBLES
        OU NON, QUE VOUS AYEZ ÉTÉ INFORMÉ OU NON DE LA POSSIBILITÉ DE TELS
        PRÉJUDICES OU PERTES, ET QUE CETTE RESPONSABILITÉ SOIT INVOQUÉE OU NON
        SUR LA BASE D’UN CONTRAT, D’UN DÉLIT (Y COMPRIS LA NÉGLIGENCE OU LA
        RESPONSABILITÉ STRICTE) OU AUTRE. Certains territoires n’autorisent pas
        l’exclusion de certains dommages. Il est donc possible que les
        limitations précédentes ne s’appliquent pas à votre situation. Si une
        autorité quelconque juge qu’une partie de cette section est
        inapplicable, la responsabilité sera alors limitée dans la pleine mesure
        autorisée par la loi en vigueur. La limitation de responsabilité
        ci-dessus ne s’applique pas aux résidents du New Jersey. En ce qui
        concerne les résidents du New Jersey, Enterprise, ses sociétés liées et
        les administrateurs, dirigeants, employés et agents de chacune de ces
        sociétés ne sont pas responsables des préjudices, à moins que ces
        derniers ne résultent d’actions ou d’omissions découlant d’une
        négligence ou imprudence de notre part. En outre, Nous ne sommes en
        aucun cas responsables des dommages indirects, accessoires, spéciaux,
        consécutifs ou punitifs.
        <p />
        <b>INDEMNISATION</b>
        <br />
        Vous acceptez d’indemniser et de dégager de toute responsabilité
        Enterprise en cas de réclamation ou de poursuite de tiers, y compris des
        honoraires d’avocat, découlant, directement ou indirectement, en tout ou
        partie, de votre accès au Site ou de votre utilisation de ce dernier, ou
        encore de votre violation d’une loi quelconque ou des droits d’une
        personne.
        <p />
        <b>
          CLAUSE DE RÉSOLUTION DES LITIGES - ACCORD D’ARBITRAGE EXÉCUTOIRE
          <br />
          VOUS ET ENTERPRISE RENONCEZ AU DROIT À UN PROCÈS DEVANT JURY OU À
          PARTICIPER À UN RECOURS COLLECTIF AU TITRE DES CONDITIONS SUIVANTES.
          VOUS ET ENTERPRISE ACCEPTEZ D’ARBITRER LA TOTALITÉ DES ÉVENTUELS
          LITIGES, RÉCLAMATIONS OU CONTROVERSES, DE QUELQUE NATURE QUE CE SOIT
          (LES « RÉCLAMATIONS »), SUSCEPTIBLES D’INTERVENIR ENTRE VOUS, QUE
          CEUX-CI SOIENT CONSÉCUTIFS, OU SE RAPPORTENT DE QUELQUE MANIÈRE QUE CE
          SOIT, AUX PRÉSENTES CONDITIONS D’UTILISATION, Y COMPRIS, SANS
          TOUTEFOIS S’Y LIMITER, LES RÉCLAMATIONS RELATIVES AUX PRODUITS ET
          SERVICES D’ENTERPRISE, AUX FRAIS, AUX PUBLICITÉS OU AUX VÉHICULES DE
          LOCATION. AUX FINS DE LA PRÉSENTE CLAUSE DE RÉSOLUTION DES LITIGES, «
          VOUS » INCLUT ÉGALEMENT TOUS VOS AGENTS, BÉNÉFICIAIRES ET AYANTS
          DROIT, AINSI QUE TOUTE PERSONNE AGISSANT AU NOM DES PERSONNES
          SUSMENTIONNÉES. DE LA MÊME MANIÈRE, « ENTERPRISE » ENGLOBE ÉGALEMENT
          ENTERPRISE HOLDINGS ET SES FOURNISSEURS, Y COMPRIS, SANS TOUTEFOIS S’Y
          LIMITER, SES FOURNISSEURS DE SERVICES ET SES PARTENAIRES MARKETING.
          VOUS ET ENTERPRISE CONVENEZ QU’AUCUNE RÉCLAMATION NE SERA PORTÉE OU
          RÉSOLUE DANS LE CADRE D’UN RECOURS COLLECTIF AUPRÈS D’UN PROCUREUR
          GÉNÉRAL PRIVÉ, OU D’UNE AUTRE ACTION OU PROCÉDURE REPRÉSENTATIVE,
          QU’AUCUN TRIBUNAL ARBITRAL N’AURA COMPÉTENCE POUR STATUER SUR TOUTE
          RÉCLAMATION COLLECTIVE OU GROUPÉE, ET QU’AUCUNE RÈGLE NI AUTRE
          PROCÉDURE D’ARBITRAGE COLLECTIF NE S’APPLIQUERA. Cette Clause de
          résolution des litiges doit être interprétée dans son sens large et
          s’applique à toutes les Réclamations basées sur un contrat, un délit,
          une loi ou tout autre fondement juridique, ainsi qu’à toutes les
          Réclamations survenues avant ou après la dénonciation des présentes
          Conditions d’utilisation. Nonobstant, les parties conviennent que
          l’une ou l’autre peut intenter une action individuelle devant un
          tribunal d’instance compétent, à condition qu’une telle action (autre
          qu’un appel de cette décision) demeure devant ce tribunal, soit
          intentée en votre nom ou à votre encontre uniquement et ne s’inscrive
          pas dans un recours collectif, une procédure menée par un procureur
          général privé ou toute autre action représentative ou collective. Les
          parties conviennent en outre que des réclamations à l’encontre ou
          provenant d’une compagnie d’assurance tierce qui vous fournit
          ostensiblement une couverture ou tout AAD ou l’application de la
          responsabilité financière d’Enterprise relative à l’utilisation ou à
          l’exploitation d’un véhicule de location Enterprise peuvent être
          portées devant un tribunal compétent.
        </b>
        <p />
        <b>PROCÉDURE</b>
        <br />
        Une partie doit envoyer à l’autre partie un Avis de litige (l’« Avis »)
        par écrit décrivant (a) la nature et le fondement de la Réclamation ; et
        (b) la réparation demandée. L’Avis à Enterprise doit être envoyé à
        l’adresse : CT Corporation, 208 S LaSalle, Suite 814, Chicago, IL 60604,
        États-Unis (l’« Adresse de l’avis »). Si vous et Enterprise ne résolvez
        pas la Réclamation dans les trente (30) jours suivant la réception de
        l’Avis, une partie peut déclencher un arbitrage en déposant une demande
        d’arbitrage auprès de l’American Arbitration Association (l’« AAA »)
        conformément aux Règles d’arbitrage des consommateurs. Les Réclamations
        seront résolues conformément aux Règles d’arbitrage des consommateurs de
        l’AAA en vigueur au moment de la demande, telles que modifiées par les
        présentes Conditions d’utilisation. Cependant, un arbitre unique sera
        sélectionné conformément aux Règles d’arbitrage commercial de l’AAA. Cet
        arbitre organisera des auditions, le cas échéant, par téléconférence ou
        vidéoconférence, plutôt que par comparution personnelle, à moins qu’il
        ne détermine, à votre demande ou à celle d’Enterprise, qu’une audition
        en personne est appropriée. Toute comparution en personne sera organisée
        dans un lieu convenant raisonnablement aux deux parties, en tenant
        compte de leur capacité à se déplacer et d’autres circonstances
        pertinentes. Si les parties ne parviennent pas à s’entendre sur un lieu,
        l’AAA ou l’arbitre tranchera. Les règles de l’AAA sont consultables en
        ligne à l’adresse www.adr.org. Sauf si la loi l’exige, ni une partie ni
        un arbitre ne peuvent divulguer l’existence, le contenu ou les résultats
        d’un litige ou d’un arbitrage en vertu des présentes sans le
        consentement écrit préalable des deux parties.
        <p />
        <b>AUTORITÉ DE L’ARBITRE</b>
        <br />
        L’arbitre est lié par les présentes Conditions d’utilisation, la
        présente Clause de résolution des litiges, le Federal Arbitration Act («
        FAA ») et les Règles d’arbitrage des consommateurs de l’AAA. L’arbitre
        n’a pas le pouvoir de joindre ou de regrouper des Réclamations, ni de
        statuer sur des Réclamations jointes et groupées. L’arbitre a le pouvoir
        exclusif de résoudre tout litige relatif au champ d’application, à
        l’interprétation, à l’applicabilité, à la mise en œuvre ou la formation
        des présentes Conditions d’utilisation, y compris si un tel litige est
        jugé infondé. Les parties conviennent que la décision et la sentence de
        l’arbitre seront définitives et exécutoires et pourront être confirmées
        ou contestées devant tout tribunal compétent en vertu du FAA. L’arbitre
        peut accorder les mêmes dommages-intérêts et réparations qu’un tribunal,
        mais seulement en faveur d’une partie et pour la Réclamation
        individuelle d’une partie.
        <p />
        <b>FRAIS D’ARBITRAGE </b>
        <br />
        Vous serez responsable de votre part des frais d’arbitrage (ex. : dépôt,
        frais administratifs, etc.), mais seulement à concurrence du montant des
        frais de dépôt que vous encourriez si les Réclamations étaient portées
        devant un tribunal. Enterprise sera responsable de tous les frais
        d’arbitrage supplémentaires. Vous êtes responsable de tous les autres
        coûts/honoraires que vous engagez dans l’arbitrage, par exemple, les
        honoraires d’avocat, des témoins experts, etc. Vous ne serez pas tenu de
        rembourser de frais à Enterprise, sauf si l’arbitre conclut que la
        réparation demandée au titre de votre ou de vos Réclamations est
        injustifiée. Si l’arbitre rend une telle décision, les Règles de l’AAA
        régiront le paiement de tous les frais, et Enterprise pourra demander
        que des honoraires d’avocat lui soient remboursés dans la limite du
        raisonnable. La société Enterprise assumera la totalité des frais et
        coûts qu’elle est tenue de payer en vertu de la loi.
        <p />
        <b>DROIT APPLICABLE ET APPLICATION DE LA LOI</b>
        <br />
        Nonobstant toute disposition des présentes Conditions d’utilisation,
        cette Clause de résolution des litiges est présentée en vertu d’une
        transaction impliquant un commerce inter-États, et sera régie par le
        FAA, Code des États-Unis (USC) 9, alinéas 1 à 16. La présente Clause de
        résolution des litiges a été rédigée conformément aux lois de tous les
        États. Toutefois, si une partie de celle-ci est jugée non valide,
        inapplicable ou inadaptée à une Réclamation, le reste de la Clause de
        résolution des litiges demeure pleinement en vigueur.
        <p />
        <b>DROIT APPLICABLE</b>
        <br />
        Les présentes Conditions d’utilisation sont régies et interprétées
        conformément aux lois de l’État du Missouri, États-Unis d’Amérique, sans
        considération de conflit de lois. Nonobstant ce qui précède ou toute
        disposition du Contrat de location, le Contrat de location sera régi par
        les lois de la juridiction dans laquelle le véhicule est loué.
        <p />
        Tout recours visant à faire respecter les présentes Conditions
        d’utilisation concernant une Réclamation non soumise à un arbitrage
        exécutoire doit être intenté devant le tribunal fédéral du district est
        du Missouri ou devant les tribunaux d’État situés dans le Comté de
        Saint-Louis, dans le Missouri. En utilisant le Site, vous consentez à la
        juridiction et à la compétence de ces tribunaux. Toute Réclamation ou
        tout recours l’égard du Site doit être intenté(e) dans un délai maximal
        d’un (1) an après la survenue de ladite Réclamation ou dudit recours,
        indépendamment de toute loi contraire. Cette limitation ne s’applique
        pas aux résidents du New Jersey.
      </p>
      <b>GÉNÉRALITÉS</b>
      <br />
      Si une disposition quelconque des présentes Conditions d’utilisation est
      reconnue non valide ou non exécutoire, ladite disposition devra être
      interprétée conformément à la loi applicable et les autres dispositions
      doivent être appliquées. Les titres de sections ne sont présentés qu’à
      titre de référence et ne définissent nullement le champ d’application de
      la section concernée, pas plus qu’ils ne la limitent, l’expliquent ou la
      décrivent. La non-application de toute disposition des présentes
      Conditions d’utilisation ne saurait constituer une renonciation à cette
      disposition ou à toute autre disposition. Les présentes Conditions
      d’utilisation constituent la totalité de l’accord conclu entre vous et
      Enterprise concernant votre utilisation du Site.
      <p />
      <b>NOUS CONTACTER</b>
      <br />
      Pour toute question relative aux présentes Conditions d’utilisation, nous
      vous invitons à nous contacter à l’adresse{" "}
      <a href="mailto: nicadmin@erac.com">nicadmin@erac.com</a>
      <p />
    </div>
  ) : language === "es-ES" ? (
    <div style={{ marginLeft: "1em", marginBottom: "1em" }}>
      <div>
        <Button
          variant="outlined"
          color="primary"
          title={"Haga click aquí para imprimir esta página"}
          aria-label={"Haga click aquí para imprimir esta página"}
          onClick={() => {
            window.print();
          }}
        >
          {" "}
          {"Haga click aquí para imprimir esta página"}
        </Button>
      </div>
      <h1>TERMS AND CONDITIONS OF USE</h1>
      <b>Enterprise Travel Direct</b>
      <br />
      <b>Website or Mobile App Terms of Use</b>
      <br />
      <b> ast Updated: 2024 </b>
      <br /> <br />
      <b>INTRODUCCIÓN</b>
      <br />
      EAN Services, LLC (“Enterprise” or “We”) provides this Website or Mobile
      App (“Site”) for your use, subject to these Terms and Conditions of Use
      (“Terms of Use”) and all applicable laws and regulations. Lea
      cuidadosamente estos Términos de uso. Al acceder y/o utilizar el Sitio,
      acepta plenamente y sin condiciones quedar vinculado por estos Términos de
      uso. Si no está de acuerdo con los mismos, no visite ni utilice el Sitio.
      Alamo se reserva el derecho de modificar estos Términos de uso, por lo que
      le recomendamos consultar de manera periódica los posibles cambios. El uso
      continuado del Sitio tras la publicación de cambios en estos Términos de
      uso constituye su aceptación de dichos cambios. All changes are effective
      immediately when We post them, and apply to use of the Site thereafter.
      <p />
      <b>USO DEL SITIO</b>
      <br />
      Enterprise mantiene este Sitio para su uso personal y no comercial.
      Solamente se permite un uso del Sitio para cualquier otro propósito con
      expreso consentimiento previo por escrito de Enterprise. Sin limitación de
      lo expuesto anteriormente, el usuario no podrá: (1) utilizar el Sitio con
      fines comerciales, incluyendo la distribución, la transmisión o la
      publicación del Sitio o de cualquier parte de su contenido; (2) interferir
      con otros usuarios en la utilización del Sitio; (3) impedir el
      funcionamiento del Sitio o interferir o interrumpir los servidores o redes
      conectados al mismo; (4) interferir con los derechos de propiedad
      intelectual de Enterprise; (5) hacer referencia o indicarse como coautor
      del Sitio o de parte de su contenido; (6) establecer un vínculo profundo a
      cualquier parte del Sitio o (7) utilizar el Sitio para cualquier fin
      ilegal. Nos reservamos el derecho, a nuestra entera discreción, a
      suspender o restringir el uso de este Sitio, sin previo aviso, por
      cualquier razón o sin ella, y sin ninguna responsabilidad ante el usuario
      ni ante terceros. En tal caso, podemos informar a su proveedor de
      servicios de Internet de sus actividades y adoptar las oportunas medidas
      jurídicas.
      <p />
      <b>MODIFICACIONES DEL SITIO</b>
      <br />
      Nos reservamos el derecho, a nuestra entera discreción, a modificar,
      suspender o interrumpir cualquier parte de este Sitio en cualquier momento
      sin previo aviso o responsabilidad hacia el usuario ni hacia terceros.
      También nos reservamos el derecho, a nuestra entera discreción, a imponer
      limitaciones en ciertas funciones y servicios.
      <p />
      <b>LINKS TO OTHER WEBSITES OR MOBILE APPS</b>
      <br />
      El Sitio puede incluir vínculos a sitios web de terceros. Enterprise does
      not control and is not responsible for the content, terms of use, or
      privacy policies of any linked website or mobile app, and your use of such
      third-party websites and mobile apps is governed by their respective terms
      of use and privacy policies, as applicable. The inclusion of any link on
      the Site does not imply our endorsement of it.
      <p />
      <b>RESERVAS Y TRANSACCIONES</b>
      <br />
      Todas las solicitudes de reservas realizadas a través del Sitio están
      sujetas a la aceptación de Alamo, quedando a nuestra entera discreción.
      Without limitation, this means that Enterprise may refuse to accept or may
      cancel any reservation or transaction, whether or not the reservation or
      transaction has been confirmed, for any or no reason, in our sole
      discretion, and without liability to you or any third party. The rental
      contract that governs your rental (“Rental Contract”) is entered into
      separately between Enterprise's affiliate that operates the rental
      location (“Owner”) and you; except as specifically provided in Check-In
      below, it is not entered into through this Site. All rentals remain
      subject and subordinate to availability and any applicable qualifications
      and rental conditions. In addition, all reservation requests are
      non-binding for you and Owner. Owner does not have any duty or obligation
      to keep any reserved vehicles available.
      <p />
      <b>COUPONS</b>
      <br />
      Enterprise provides specific discount coupons from time to time for the
      exclusive use of employees or members of specific entities with which
      Enterprise has a contractual relationship. These entity-specific coupons
      and the proprietary codes affixed on them are our proprietary property and
      may only be used by the employees or members of the specific entities for
      which the coupons apply. UNAUTHORIZED USE OR DUPLICATION OF ANY OF OUR
      ENTITY-SPECIFIC DISCOUNT COUPONS IS STRICTLY PROHIBITED.
      <p />
      <b>CHECK-IN </b>
      <br />
      To check-in via the Site, you must (i) submit personal information to us
      or a third party designated by us that may include, but is not limited to,
      your name, email address, phone number, date of birth, payment card
      information, and your driver's license information, (ii) confirm the date,
      time, rental location, and vehicle class for your reservation, and (iii)
      accept or decline protections and extras. You may also be asked to
      electronically agree to the Rental Contract. For purposes of
      clarification, references to the “Rental Agreement Summary” or “Summary”
      in the Rental Contract shall also include information provided by you as
      well as information presented to you during check-in. Changes to your
      rental details at the time of pick-up may require you to enter into a new
      Rental Contract at that time. Subject to our Global Privacy Policy
      described below, we will use third parties (including, but not limited to,
      LexisNexis for purposes of fraud prevention) or in some cases you will
      submit the information directly to such third parties for the purpose of
      facilitating the transaction. You expressly acknowledge and agree that any
      changes to your reservation, including, but not limited to, upgrades,
      protections, and extras, as well as changes to pick up or drop off dates,
      times, or locations, may result in increased charges and fees associated
      with your reservation. If you do not complete check-in via the Site, you
      must check-in upon arrival at the rental location.
      <p />
      <b>PAYMENT AUTHORIZATION</b>
      <br />
      Antes de finalizar el proceso de registro, se mostrarán los cargos estimados para su confirmación. 
      Al aceptar electrónicamente el Contrato de alquiler o guardar los datos de su tarjeta,
      autoriza al Propietario (a través de un tercero) a cargar en esa tarjeta de crédito o 
      débito todos los importes adeudados en virtud del Contrato de alquiler por depósitos anticipados, 
      fianzas o depósitos incrementales y cualquier otra cantidad adeudada por usted,
      así como los pagos rechazados por un tercero a quien se haya dirigido la facturación, 
      todo de acuerdo con los términos del Contrato de alquiler. También autoriza al Propietario a 
      volver a cargar en esa tarjeta de crédito/débito cualquier gasto que se rechace por cualquier motivo.
      <p />
      <b>COMUNICACIONES ELECTRÓNICAS</b>
      <br />
      By using the Site, you consent to receiving electronic communications and
      notices from Enterprise. El usuario acepta que cualquier aviso, acuerdo,
      divulgación u otras comunicaciones que enviemos al usuario
      electrónicamente cumplirán con los requisitos legales para comunicaciones,
      incluyendo aquellas comunicaciones por escrito.
      <p />
      <b>SIGNING DOCUMENTS ELECTRONICALLY</b>
      <br />
      By using the Site, you consent to transact business electronically using
      electronic signatures in lieu of paper documents.
      <p />
      <b>PROPIEDAD INTELECTUAL</b>
      <br />
      Todos los textos, gráficos, sonidos, descargas, software y otros
      materiales sujetos a copyright (de manera colectiva, el "Contenido"), la
      selección, la recopilación, la disposición y la presentación de todos los
      materiales y el diseño general del Sitio son propiedad de Enterprise y
      están protegidos por la ley y por derechos de copyright. Todos los
      derechos reservados. El usuario puede imprimir una copia del Contenido en
      su equipo exclusivamente para su uso personal y doméstico sin fines
      comerciales siempre que no se elimine ninguna marca de copyright, marca
      comercial u otros avisos de propiedad del Contenido. Cualquier otro uso
      del Contenido está estrictamente prohibido, a menos que el usuario tenga
      nuestro permiso previo por escrito. El Sitio también puede incluir
      contenido que sea propiedad de terceros, incluyendo nuestros anunciantes.
      Puede utilizar tal contenido de terceros si tal uso está autorizado
      expresamente por el titular correspondiente. Todas las solicitudes de
      permiso para reproducir o hacer cualquier otro uso del Contenido deben
      dirigirse a Intellectual Property Manager, Copyright Reprint Permission,
      600 Corporate Park Drive, San Louis, Missouri 63105 o a la dirección {" "}
      <a href="mailto:nicadmin@erac.com">nicadmin@erac.com</a>. Enterprise no
      garantiza que el uso por parte del usuario de los materiales que se
      muestren o que estén vinculados al Sitio no infrinjan los derechos de
      terceros.
      <br />
      <p>
        ENTERPRISE, el logotipo "e", WWW.ENTERPRISE.COM, ENTERPRISE.COM, WE’LL
        PICK YOU UP, ARMS, ECARS, MONTH OR MORE, CORPORATE CLASS, EVERYDAY SALE
        se incluyen en la familia de marcas registradas y marcas de servicio
        propiedad de Enterprise (esta enumeración no es una lista completa de
        las marcas comerciales y de servicio de Enterprise). Las marcas
        registradas y las marcas de servicio designadas con el símbolo "®" son
        marcas comerciales registradas en la Oficina de Patentes y Marcas de
        Estados Unidos y en numerosos países extranjeros. Otras marcas
        registradas y marcas de servicio pertenecientes a Enterprise pueden
        aparecer designadas con los símbolos "SM" o "TM". Nuestras marcas
        registradas y marcas de servicio no pueden utilizarse en conexión con
        cualquier producto o servicio sin nuestro previo consentimiento por
        escrito. El resto de marcas registradas y nombres comerciales que no
        sean propiedad de Enterprise y que aparezcan en el Sitio son propiedad
        de sus dueños respectivos, quienes pueden o no estar afiliados,
        conectados o respaldados por Enterprise.
      </p>
      <p>
        Enterprise tiene un determinado número de solicitudes de patentes
        pendientes, tanto estadounidenses como extranjeras, que abarcan diversos
        aspectos de este Sitio, por lo que se avisa a los usuarios de que la
        copia, adopción, uso o utilización temporal de técnicas, operaciones de
        menú u otros aspectos o características funcionales podrían dar lugar a
        responsabilidad legal por infracción de patente.
      </p>
      <b>RECLAMACIONES DE INFRACCIÓN DE DERECHOS DE AUTOR</b>
      <br />
      Si el usuario cree que el material publicado en este Sitio infringe sus
      derechos de propiedad intelectual, deberá ponerse en contacto con
      Intellectual Property Manager, Intellectual Property Questions, 600
      Corporate Park Drive, San Louis, Missouri 63105 o a la dirección{" "}
      <a href="mailto:nicadmin@erac.com">nicadmin@erac.com</a>. El usuario
      deberá incluir: (1) una firma electrónica o física de la persona
      autorizada para actuar en nombre del propietario del copyright u otros
      derechos intelectuales de interés; (2) una descripción del trabajo
      protegido por copyright u otros materiales protegidos que el usuario
      afirma que se han infringido; (3) una descripción del lugar donde se
      encuentra el material en el Sitio; (4) su dirección, número de teléfono y
      dirección de correo electrónico; (5) una declaración de que el usuario
      cree de buena fe que el uso en disputa no está autorizado por el
      propietario u otros intereses de copyright, su agente o la legislación
      aplicable y (6) una declaración de que la información de su notificación
      es exacta y que el usuario es el propietario de copyright u otro interés o
      de que está autorizado para actuar en nombre del propietario.
      <p />
      <b>PRIVACY POLICY AND USE OF INFORMATION SUBMITTED</b>
      <br />
      Toda la información enviada o recogida a través del Sitio está sujeta a
      nuestra Política de Privacidad, cuyos términos se incorporan en estos
      Términos de uso. Enterprise reserves the right to amend the Global Privacy
      Policy from time to time, in its sole discretion. In the event of a change
      to the Global Privacy Policy, Enterprise shall provide notice in the
      manner set forth in the Global Privacy Policy. El usuario acepta que
      Enterprise es libre de utilizar cualquier comentario, información o ideas
      contenidas en cualquier comunicación que nos envíe sin compensación,
      reconocimiento o pago al usuario, con cualquier propósito e incluyendo,
      entre otros, el desarrollo, la fabricación y la comercialización de
      productos y servicios, y la creación, modificación o mejora de otros
      productos o servicios.
      <p />
      <b>PASSWORD SECURITY</b>
      <br />
      If you have a username and/or password for access to non-public areas of
      the Site, you are solely responsible for maintaining the confidentiality
      and use of the username and/or password. Further, you are responsible for
      all activities that occur in connection with your username and/or
      password. You alone are responsible for all transactions initiated,
      messages posted, statements made, or acts or omissions that occur within
      the Site through the use of your username and/or password. You agree to
      notify us immediately of any unauthorized access to or use of your
      username and/or password or any other breach of security.
      <p />
      <b>NO SE OFRECE GARANTÍA</b>
      <br />
      Al tiempo que realizamos los mayores esfuerzos por mantener la precisión y
      la fiabilidad del Sitio, no podemos garantizar ni afirmar su
      funcionamiento de manera ininterrumpida o que esté libre de errores. No
      asumimos responsabilidad alguna por errores u omisiones en el Sitio o por
      problemas con su funcionamiento. El acceso y uso del Sitio por parte del
      usuario serán bajo su propio riesgo. Sin limitación de lo expuesto
      anteriormente, EL SITIO, SU CONTENIDO Y CUALQUIER PRODUCTO O SERVICIO
      OFRECIDOS A TRAVÉS DEL MISMO SE SUMINISTRAN "TAL CUAL" Y "SEGÚN
      DISPONIBIDAD", SIN GARANTÍA DE NINGÚN TIPO, YA SEA EXPRESA O IMPLÍCITA,
      INCLUYENDO, ENTRE OTRAS, LAS GARANTÍAS IMPLÍCITAS DE COMERCIABILIDAD,
      IDONEIDAD PARA UN PROPÓSITO PARTICULAR, TITULARIDAD Y NO INFRACCIÓN.
      Algunas jurisdicciones no permiten la exclusión de ciertas garantías, por
      lo que la disposición anterior podría no aplicarse al usuario.
      <p />
      <b>
        LIMITACIÓN DE RESPONSABILIDAD
        <br />
        IN NO EVENT SHALL ENTERPRISE, ITS PARENT COMPANY, SUBSIDIARIES AND
        AFFILIATES, AND EACH OF THEIR RESPECTIVE DIRECTORS, OFFICERS,
        SHAREHOLDERS, EMPLOYEES, SUCCESSORS, ASSIGNS, REPRESENTATIVES AND AGENTS
        (collectively, “Enterprise Holdings”) BE LIABLE FOR ANY DIRECT,
        INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, OR
        LOST PROFITS, GOODWILL, SAVINGS, OR USE, OF ANY KIND OR NATURE ARISING
        OUT OF, OR RELATING TO THE PERFORMANCE, BREACH, OR TERMINATION OF THESE
        TERMS OF USE, ARISING OUT OF OR IN CONNECTION WITH YOUR ACCESS, USE OR
        INABILITY TO USE THE SITE, ANY INFORMATION PROVIDED BY THE SITE OR ANY
        TRANSACTION ENTERED INTO THROUGH THE SITE, WHETHER SUCH DAMAGE OR LOSS
        IS FORESEEABLE OR NOT, WHETHER YOU HAVE BEEN ADVISED OF THE POSSIBILITY
        THEREOF OR NOT, AND WHETHER SUCH LIABILITY IS ASSERTED ON THE BASIS OF
        CONTRACT, TORT (INCLUDING NEGLIGENCE OR STRICT LIABILITY), OR OTHERWISE.
        Algunas jurisdicciones no permiten la exclusión de ciertos daños, por lo
        que la disposición anterior podría no aplicarse al usuario. Si alguna
        autoridad considera que cualquier parte de esta sección no es aplicable,
        la responsabilidad estará limitada hasta el máximo grado permitido por
        la legislación aplicable. The foregoing limitation of liability is not
        applicable to residents of New Jersey. With respect to residents of New
        Jersey, Enterprise, its related companies, and each such company’s
        directors, officers, employees and agent are not liable for any damages
        unless such damages are the result of our negligent or reckless acts or
        omissions; and We are not, in any case liable for indirect, incidental,
        special, consequential or punitive damages.
      </b>
      <p />
      <b>RESPONSABILIDAD CIVIL</b>
      <br />
      El usuario se compromete a indemnizar y a mantener indemne a Enterprise y
      sus empresas vinculadas, así como a cada uno de sus respectivos
      directores, representantes, empleados y agentes indemnes frente a
      cualquier reclamación de terceros o causa de acción, incluyendo los
      honorarios razonables de abogados y costes derivados, directa o
      indirectamente, en su totalidad o en parte, de su acceso o uso del Sitio o
      de la violación por su parte de la legislación de los derechos de
      cualquier persona.
      <p />
      <b>
        DISPUTE RESOLUTION PROVISION - MANDATORY ARBITRATION AGREEMENT
        <br />
        YOU AND ENTERPRISE EACH WAIVE THE RIGHT TO A JURY TRIAL OR TO
        PARTICIPATE IN A CLASS ACTION PURSUANT TO THE FOLLOWING TERMS. YOU AND
        ENTERPRISE AGREE TO ARBITRATE ANY AND ALL CLAIMS, CONTROVERSIES OR
        DISPUTES OF ANY KIND (“CLAIMS”) AGAINST EACH OTHER ARISING OUT OF OR
        RELATING IN ANY WAY TO THESE TERMS OF USE, INCLUDING BUT NOT LIMITED TO,
        CLAIMS RELATING TO ENTERPRISE’S PRODUCTS AND SERVICES, CHARGES,
        ADVERTISINGS, OR RENTAL VEHICLES. FOR THE PURPOSES OF THIS DISPUTE
        RESOLUTION PROVISION, “YOU” ALSO INCLUDES ANY OF YOUR AGENTS,
        BENEFICIARIES, ASSIGNS, OR ANYONE ACTING ON BEHALF OF THE FOREGOING, AND
        “ENTERPRISE” ALSO INCLUDES ENTERPRISE HOLDINGS AND ITS VENDORS,
        INCLUDING BUT NOT LIMITED TO ITS SERVICE PROVIDERS AND MARKETING
        PARTNERS. YOU AND ENTERPRISE AGREE THAT NO CLAIMS WILL BE PURSUED OR
        RESOLVED AS A PART OF A CLASS ACTION, PRIVATE ATTORNEY GENERAL OR OTHER
        REPRESENTATIVE ACTION OR PROCEEDING, THAT NO ARBITRATION FORUM WILL HAVE
        JURISDICTION TO DECIDE ANY CLAIMS ON A CLASS-WIDE, COLLECTIVE, OR
        CONSOLIDATED BASIS, AND THAT NO RULES OR OTHER PROCEDURES FOR CLASS-WIDE
        OR COLLECTIVE ARBITRATION WILL APPLY. This Dispute Resolution Provision
        is to be broadly interpreted and applies to all Claims based in
        contract, tort, statute, or any other legal theory, and all Claims that
        arose prior to or after termination of these Terms of Use. However, the
        parties agree that either party may bring an individual action in a
        small claims court with valid jurisdiction, provided that the action
        remains in that court (other than any appeal of the small claims court
        ruling), is made on behalf of or against you only and is not made part
        of a class action, private attorney general action or other
        representative or collective action. The parties also agree that claims
        against or by a third-party insurance company ostensibly providing
        coverage to you or any AAD or the application of Enterprise’s financial
        responsibility relating to the use or operation of an Enterprise rental
        vehicle may be brought in a court with valid jurisdiction.
      </b>
      <p />
      <b>PROCEDURE</b>
      <br />
      A party must send a written Notice of Dispute (“Notice”) describing (a)
      the nature and basis of the Claim; and (b) the relief sought, to the other
      party. The Notice to Enterprise should be addressed to: CT Corporation,
      208 S LaSalle, Suite 814, Chicago, IL 60604 (“Notice Address”). If you and
      Enterprise do not resolve the Claim within thirty (30) days after the
      Notice is received, a party may commence an arbitration by filing a demand
      for arbitration with the American Arbitration Association (“AAA”) pursuant
      to its Consumer Arbitration Rules. Claims will be resolved pursuant to the
      AAA’s Consumer Arbitration Rules in effect at the time of the demand, as
      modified by these Terms of Use. However, a single arbitrator will be
      selected according to AAA’s Commercial Arbitration Rules. The arbitrator
      will conduct hearings, if any, by teleconference or videoconference,
      rather than by personal appearances, unless the arbitrator determines upon
      request by you or Enterprise that an in-person hearing is appropriate. Any
      in-person appearances will be held at a location which is reasonably
      convenient to both parties with due consideration of their ability to
      travel and other pertinent circumstances. If the parties are unable to
      agree on a location, such determination should be made by the AAA or by
      the arbitrator. The AAA rules are available online at www.adr.org. Except
      as required by law, neither a party nor an arbitrator may disclose the
      existence, content, or results of any dispute or arbitration hereunder
      without the prior written consent of both parties.
      <p />
      <b>ARBITRATOR’S AUTHORITY</b>
      <br />
      The arbitrator is bound by these Terms of Use, this Dispute Resolution
      Provision, the Federal Arbitration Act (“FAA”) and AAA’s Consumer
      Arbitration Rules. The arbitrator has no authority to join or consolidate
      Claims, or adjudicate joined and consolidated Claims. The arbitrator has
      exclusive authority to resolve any dispute relating to the scope,
      interpretation, applicability, enforceability or formation of these Terms
      of Use, including whether it is void. The parties agree that the
      arbitrator’s decision and award will be final and binding and may be
      confirmed or challenged in any court with jurisdiction as permitted under
      the FAA. The arbitrator can award the same damages and relief as a court,
      but only in favor of an individual party and for a party’s individual
      claim.
      <p />
      <b>ARBITRATION COSTS </b>
      <br />
      You will be responsible for your share of any arbitration fees (e.g.,
      filing, administrative, etc.), but only up to the amount of filing fees
      you would incur if the Claims were filed in court. Enterprise will be
      responsible for all additional arbitration fees. You are responsible for
      all other costs/fees that you incur in arbitration, e.g., fees for
      attorneys, expert witnesses, etc. You will not be required to reimburse
      Enterprise for any fees unless the arbitrator finds that the substance of
      your Claim(s) or the relief sought is frivolous. If the arbitrator makes
      such a finding, AAA Rules will govern the payment of all fees, and
      Enterprise may seek reasonable attorney’s fees. Enterprise will pay all
      fees and costs it is required by law to pay.
      <p />
      <b>GOVERNING LAW AND ENFORCEMENT</b>
      <br />
      Notwithstanding anything in these Terms of Use, this Dispute Resolution
      Provision is made pursuant to a transaction involving interstate commerce,
      and shall be governed by the FAA, 9 U.S.C. §§ 1-16. This Dispute
      Resolution Provision was drafted in compliance with the laws in all
      states, however, if any portion of it is deemed to be invalid or
      unenforceable or is found not to apply to a Claim, the remainder of the
      Dispute Resolution Provision remains in full force and effect.
      <p />
      <b>APPLICABLE LAW</b>
      <br />
      These Terms of Use are governed by and construed in accordance with the
      laws of the State of Missouri, United States of America, without regard to
      its conflict of law principles. Notwithstanding the foregoing or anything
      in the Rental Contract, the Rental Contract shall be governed by the laws
      of the jurisdiction in which you pick up the car.
      <p />
      <p>
        Any action to enforce these Terms of Use regarding a Claim not subject
        to binding arbitration must be brought in the federal court in the
        Eastern District of Missouri or the state courts located in St. Louis
        County, Missouri. By using the Site, you consent to the jurisdiction and
        venue of these courts. Cualquier reclamación o causa de acción que pueda
        tener el usuario con respecto al Sitio debe iniciarse dentro de un (1)
        año después de que surja la reclamación o causa de acción,
        independientemente de cualquier ley en sentido contrario. This
        limitation does not apply to residents of New Jersey.
      </p>
      <b>GENERAL</b>
      <br />
      Si alguna disposición de estos Términos de uso se considerara no válida o
      no ejecutable, dicha disposición deberá interpretarse en consonancia con
      la legislación aplicable y se aplicarán las disposiciones restantes. Los
      encabezados se proporcionan únicamente como referencia y de ninguna manera
      definen, limitan, interpretan o describen el alcance de dicha sección.
      Nuestra incapacidad para hacer cumplir cualquier disposición de estos
      Términos de uso no constituirá una renuncia de la misma ni de cualquier
      otra disposición. Estos Términos de uso representan el acuerdo completo
      entre el usuario y Enterprise en conexión con su uso del Sitio.
      <p />
      <b>CONTACTO</b>
      <br />
      CONTACTO Si el usuario tiene alguna pregunta acerca de estos Términos de
      uso, puede ponerse en contacto con nosotros en la{" "}
      <a href="mailto: nicadmin@erac.com">nicadmin@erac.com</a>.
      <p />
    </div>
  ) : language === "fr-CA" ? (
    <div style={{ marginLeft: "1em", marginBottom: "1em" }}>
      <div>
        <Button
          variant="outlined"
          color="primary"
          title={"Cliquez pour imprimer cette page"}
          aria-label={"Cliquez pour imprimer cette page"}
          onClick={() => {
            window.print();
          }}
        >
          {" "}
          {"Cliquez pour imprimer cette page"}
        </Button>
      </div>
      <h1>MODALITÉS D’UTILISATION</h1>
      <b>Enterprise Travel Direct</b> <br />
      <b> Dernière mise à jour : 2024 </b>
      <br /> <br />
      <b>INTRODUCTION</b>
      <br />
      EAN Services, LLC (« Enterprise » ou « nous ») offre ce site Web (le «
      site ») pour votre utilisation, conformément aux présentes modalités
      d’utilisation (les « modalités d'utilisation ») et à toutes les lois et
      réglementations en vigueur. Veuillez lire attentivement les présentes
      modalités d’utilisation. En ayant accès au site ou en l’utilisant, vous
      acceptez et consentez entièrement et sans condition à être lié par les
      présentes modalités d’utilisation, y compris l’arbitrage exécutoire. Si
      vous n’acceptez pas les présentes modalités, veuillez ne pas consulter ni
      utiliser le site. Enterprise se réserve le droit de modifier ces modalités
      d’utilisation; veuillez donc vérifier périodiquement si des modifications
      y ont été apportées. En continuant à utiliser le site après la publication
      de toute modification des présentes modalités d’utilisation, vous acceptez
      ces modifications. Tous les changements entrent en vigueur dès que nous
      les publions et s’appliquent à toute utilisation ultérieure du site.
      <p />
      <b>UTILISATION DU SITE</b>
      <br />
      Enterprise entretient ce site pour votre utilisation à des fins
      personnelles d’information et non commerciales. Votre utilisation du site
      à toute autre fin n’est permise qu’après avoir obtenu l’autorisation
      écrite préalable et expresse d’Enterprise à cet effet. Sans limiter la
      portée de ce qui précède, vous ne pouvez pas : (1) utiliser le site à des
      fins commerciales, y compris en distribuant, transmettant ou publiant le
      site ou son contenu; (2) nuire à l’utilisation du site par d’autres
      personnes; (3) nuire au fonctionnement du site ou nuire aux serveurs ou
      réseaux qui y sont reliés ou les perturber; (4) porter atteinte aux droits
      de propriété intellectuelle d’Enterprise; (5) encadrer ou marquer
      conjointement le site ou son contenu; (6) mettre en place des liens
      profonds vers toute partie du site; ou (7) utiliser le site à des fins
      illégales. Nous nous réservons le droit, à notre seule discrétion, de
      mettre fin à votre utilisation du site ou de la restreindre, sans préavis,
      pour une raison quelconque ou sans raison, et sans que notre
      responsabilité soit engagée envers vous ou un tiers. Dans un tel cas, nous
      pouvons informer votre fournisseur de services Internet de vos activités
      et intenter une action en justice.
      <p />
      <b>MODIFICATIONS DU SITE</b>
      <br />
      Nous nous réservons le droit, à notre seule discrétion, de modifier, de
      suspendre ou de supprimer n’importe quelle partie du site, à tout moment,
      sans préavis et sans responsabilité envers vous ou envers tout tiers. Nous
      nous réservons aussi le droit, à notre seule discrétion, d’imposer des
      limites à des fonctionnalités et services.
      <p />
      <b>LIENS VERS D’AUTRES SITES WEB OU APPLICATIONS MOBILES</b>
      <br />
      Ce site peut contenir des liens vers des sites Web ou des applications
      mobiles de tiers. Enterprise ne contrôle pas le contenu, les modalités
      d’utilisation ou les politiques de confidentialité de tout site Web ou
      application mobile liés, et n’en est pas responsable, et votre utilisation
      de ces sites Web et applications mobiles de tiers est régie par leurs
      modalités d’utilisation et politiques de confidentialité respectives, le
      cas échéant. L’inclusion d’un lien sur le site ne signifie pas que nous
      l’approuvons.
      <p />
      <b>RÉSERVATIONS ET TRANSACTIONS</b>
      <br />
      Toutes les réservations et les transactions réalisées par l’intermédiaire
      du site exigent l’acceptation par Enterprise, à son entière discrétion.
      Sans en limiter la portée, cette modalité signifie qu’Enterprise peut
      refuser ou annuler toute réservation ou transaction, que la réservation ou
      la transaction ait été confirmée ou non, avec ou sans raison, à sa seule
      discrétion, et sans responsabilité envers vous ou envers tout tiers. Le
      contrat de location qui régit votre location (le « contrat de location »)
      est conclu séparément entre la société affiliée d’Entreprise qui exploite
      la succursale de location (le « propriétaire ») et vous. Sauf dans la
      mesure expressément prévue dans l’article Enregistrement ci-dessous, il
      n’est pas conclu par l’intermédiaire du présent site. Toutes les locations
      demeurent assujetties à la disponibilité et aux qualifications et
      conditions de location applicables. De plus, toutes les demandes de
      réservation ne sont exécutoires ni pour vous ni pour le propriétaire. Le
      propriétaire n’a aucun devoir ou aucune obligation de garder libres les
      véhicules réservés.
      <p />
      <b>COUPONS</b>
      <br />
      Enterprise offre de temps en temps des coupons de réduction qui sont
      réservés à l’usage exclusif de nos employés ou des membres d’entités
      particulières avec lesquelles Enterprise entretient une relation
      contractuelle. Ces coupons propres à une entité et les codes exclusifs qui
      y sont associés sont notre propriété exclusive et ne peuvent être utilisés
      que par les employés ou les membres des entités particulières auxquels les
      coupons s’appliquent. L’UTILISATION OU LA REPRODUCTION NON AUTORISÉE DE
      CES COUPONS DE RÉDUCTION RÉSERVÉS À DES ENTITÉS DONNÉES EST STRICTEMENT
      INTERDITE.
      <p />
      <b>ENREGISTREMENT </b>
      <br />
      Pour effectuer l’enregistrement sur le site, vous devez (i) nous
      transmettre vos renseignements personnels ou les transmettre à un tiers
      que nous avons désigné, notamment votre nom, votre adresse de courriel,
      votre numéro de téléphone, votre date de naissance, les renseignements sur
      la carte de paiement et les renseignements de votre permis de conduire
      (ii) confirmer la date, l’heure, la succursale de location et la classe du
      véhicule pour votre réservation, et (iii) accepter ou refuser les
      protections et les extras. Il se peut également que l’on vous demande
      d’accepter le contrat de location par voie électronique. Pour plus de
      certitude, les mentions du « sommaire du contrat de location » ou du «
      sommaire » dans le contrat de location incluent également les
      renseignements que vous avez fournis ainsi que les renseignements qui vous
      ont été présentés au moment de l’enregistrement. Les modifications
      apportées aux détails de votre location au moment de la prise en charge
      peuvent vous obliger à signer un nouveau contrat de location à ce
      moment-là. Sous réserve de notre politique de confidentialité à l’échelle
      mondiale décrite ci-dessous, nous ferons appel à des tiers (y compris,
      mais sans s’y limiter, LexisNexis aux fins de prévention de la fraude) ou,
      dans certains cas, vous transmettrez les renseignements directement à ces
      tiers dans le but de faciliter la transaction. Vous reconnaissez et
      convenez expressément que tout changement apporté à votre réservation, y
      compris, mais sans s’y limiter, les surclassements, les protections et les
      extras, ainsi que tout changement de date de prise en charge ou de retour,
      d’heure ou de succursale, peut entraîner une augmentation des tarifs et
      des frais associés à votre réservation. Si vous ne procédez pas à
      l’enregistrement par l’intermédiaire du site, vous devez le faire à votre
      arrivée à la succursale de location.
      <p />
      <b>AUTORISATION DE PAIEMENT</b>
      <br />
      Avant la finalisation de l'enregistrement, vous aurez accès aux frais estimés 
      aux fins de confirmation. En acceptant par voie électronique le contrat de 
      location ou en enregistrant les détails de votre carte, vous autorisez le propriétaire 
      (par l'entremise d’une tierce partie) à porter à cette carte de crédit ou à cette carte 
      de débit tous les montants dus en vertu du contrat de location pour les dépôts anticipés, 
      les autorisations et les dépôts supplémentaires, tout autre montant dû par vous, ainsi que les 
      paiements refusés par une tierce partie à qui le paiement était destiné, le tout en vertu des 
      modalités du contrat de location. Vous autorisez également le propriétaire à effectuer le 
      paiement de nouveau, à l’aide de cette carte de crédit ou de débit, au cas où celui-ci aurait 
      été refusé pour quelque raison que ce soit.
      <p />
      <b>COMMUNICATIONS ÉLECTRONIQUES</b>
      <br />
      En utilisant ce site, vous consentez à recevoir des communications et des
      avis électroniques d’Enterprise. Vous convenez que les avis, ententes,
      divulgations ou autres communications envoyés par nous par voie
      électronique satisfont aux exigences de la loi, y compris le fait que nous
      communiquions avec vous par écrit.
      <p />
      <b>SIGNATURE DES DOCUMENTS PAR VOIE ÉLECTRONIQUE</b>
      <br />
      En utilisant le site, vous consentez à traiter les affaires par voie
      électronique en utilisant des signatures électroniques au lieu de
      documents papier.
      <p />
      <b>PROPRIÉTÉ INTELLECTUELLE</b>
      <br />
      L’ensemble des textes, images, sons, téléchargements, logiciels, y compris
      leur code source et binaire, ou tout autre matériel assujetti aux droits
      d’auteur (collectivement, le « contenu »), la sélection, la compilation,
      l’arrangement et la présentation de tout le matériel, ainsi que la
      conception générale du site sont protégés par le droit d’auteur
      d’Enterprise ou sont utilisés par Enterprise sous licence et sont protégés
      par la loi. Tous droits réservés. Vous pouvez imprimer un exemplaire du
      contenu sur votre ordinateur uniquement pour votre usage personnel et non
      commercial à domicile, à condition que vous n’en supprimiez aucun avis de
      propriété, notamment un avis de droit d’auteur ou de marque de commerce.
      Toute autre utilisation du contenu est strictement interdite, sauf si nous
      vous avons préalablement accordé une autorisation écrite à cet effet. Le
      site peut aussi présenter un contenu qui appartient à des tiers, y compris
      nos partenaires et nos annonceurs. Vous ne pouvez utiliser ledit contenu
      de tiers qu’en vertu d’une autorisation expresse à cet effet par le
      propriétaire concerné. Toutes les demandes de permission pour la
      reproduction ou toute autre utilisation du contenu doivent être faites à
      Intellectual Property Manager, Copyright Reprint Permission, 600 Corporate
      Park Drive, St. Louis, Missouri 63105 ou{" "}
      <a href="mailto:nicadmin@erac.com">nicadmin@erac.com</a>. Enterprise ne
      garantit pas que votre utilisation du contenu présenté sur le site ou lié
      au site n’enfreint pas les droits de tiers.
      <p />
      <p>
        ENTERPRISE, le logo « e », WWW.ENTERPRISE.COM, ENTERPRISE.COM,
        LAISSEZ-VOUS TRANSPORTER, ARMS, ECARS, MONTH OR MORE, CORPORATE CLASS,
        EVERYDAY SALE sont inclus dans la famille de marques de commerce et de
        marques de service appartenant à Enterprise (il ne s’agit pas d’une
        liste exhaustive des marques de commerce et des marques de service
        d’Enterprise). Les marques de commerce et de service désignées par les
        symboles ® ou MD sont déposées auprès du bureau des brevets et des
        marques des États-Unis et dans de nombreux pays étrangers. D’autres
        marques de commerce et de service appartenant à Enterprise peuvent être
        accompagnées du symbole « SM », « MS », « TM » ou « MC ». Nos marques de
        commerce et de service ne peuvent pas être utilisées avec tout produit
        ou service sans notre autorisation écrite préalable. Les autres marques,
        marques de commerce et noms présents sur le site qui n’appartiennent pas
        à Enterprise sont la propriété de leurs propriétaires respectifs,
        lesquels peuvent être affiliés ou associés à Enterprise ou non, ou
        encore approuvés par Enterprise ou non.
      </p>
      <p>
        Enterprise a un certain nombre de demandes de brevet en instance, aux
        États-Unis et dans d’autres pays, portant sur différents aspects du
        site, et il est déconseillé aux utilisateurs de copier, d’emprunter ou
        d’utiliser de quelque manière que ce soit les techniques, le
        fonctionnement de menus ou d’autres aspects ou caractéristiques
        fonctionnels, car ils pourraient être tenus responsables de violation de
        brevet.
      </p>
      <b>DÉCLARATIONS DE VIOLATION DU DROIT D’AUTEUR</b>
      <br />
      Si vous croyez que du contenu du site viole vos droits de propriété
      intellectuelle, veuillez communiquer avec Enterprise à : Intellectual
      Property Manager, Intellectual Property Questions, 600 Corporate Park
      Drive, St. Louis, Missouri 63105 ou{" "}
      <a href="mailto:nicadmin@erac.com">nicadmin@erac.com</a>. Ajoutez à votre
      communication : (1) une signature électronique ou physique de la personne
      autorisée à agir au nom du titulaire du droit d’auteur ou autre droit de
      propriété; (2) une description de l’œuvre protégée par le droit d’auteur
      ou autre matériel protégé pour lequel vous croyez que vos droits ont été
      enfreints; (3) une description de l’endroit où vous l’avez vu sur le site;
      (4) votre adresse, votre numéro de téléphone et votre adresse de courriel;
      (5) votre déclaration de bonne foi comme quoi l’utilisation contestée
      n’est pas autorisée par le titulaire du droit d’auteur ou autre droit de
      propriété, son délégué ou la loi; et (6) votre déclaration, sous peine de
      parjure, que les renseignements contenus dans votre avis sont exacts et
      que vous êtes bien le titulaire des droits d’auteur ou autre droit de
      propriété ou êtes autorisé à agir en son nom.
      <p />
      <b>
        POLITIQUE DE CONFIDENTIALITÉ ET UTILISATION DES RENSEIGNEMENTS TRANSMIS
      </b>
      <br />
      Tout renseignement soumis ou recueilli sur ce site est assujetti à notre
      politique de confidentialité à l’échelle mondiale [LIEN] dont les
      modalités sont intégrées à ces modalités d’utilisation. Enterprise se
      réserve le droit de modifier la politique de confidentialité à l’échelle
      mondiale de temps à autre, à son entière discrétion. En cas de
      modification de la politique de confidentialité à l’échelle mondiale, un
      avis sera envoyé de la manière prévue dans la politique de confidentialité
      à l’échelle mondiale. Vous acceptez qu’Enterprise utilise l’ensemble des
      commentaires, renseignements ou idées contenus dans tout message que vous
      pourriez nous faire parvenir, sans rémunération, avertissement ou paiement
      de notre part, dans n’importe quel but, y compris, entre autres, la
      création, la prestation et la mise en marché de produits et services ainsi
      que la création, la modification ou l’amélioration du site ou d’autres
      produits ou services.
      <p />
      <b>SÉCURITÉ DES MOTS DE PASSE</b>
      <br />
      Si vous avez un nom d’utilisateur et un mot de passe pour l’accès aux
      zones non publiques du site, vous êtes seul responsable du maintien de la
      confidentialité et de l’utilisation desdits nom d’utilisateur et mot de
      passe. De plus, vous êtes responsable de toutes les activités en lien avec
      votre nom d’utilisateur et mot de passe. Vous êtes seul responsable de
      toutes les transactions initiées, tous les messages publiés, toutes les
      déclarations faites et tous les actes ou omissions qui se produisent sur
      le site par l’utilisation de votre nom d’utilisateur et mot de passe. Vous
      acceptez de nous signaler immédiatement tout accès non autorisé à l’aide
      de votre nom d’utilisateur et mot de passe ou toute utilisation non
      autorisée de votre nom d’utilisateur et mot de passe ou de toute autre
      infraction à la sécurité.
      <p />
      <b>ABSENCE DE GARANTIE</b>
      <br />
      Nous prenons toutes les mesures raisonnables pour maintenir l’exactitude
      et la fiabilité du site, mais nous ne pouvons pas garantir qu’il
      fonctionnera toujours ou qu’il ne contiendra pas d’erreurs. Nous
      n’assumons aucune responsabilité en cas d’erreurs ou d’omissions sur le
      site ou de problèmes liés à son fonctionnement. Vous accédez au site et
      l’utilisez à vos propres risques. Sans limiter la portée de ce qui
      précède, LE SITE, SON CONTENU ET TOUT PRODUIT OU SERVICE OFFERT PAR SON
      ENTREMISE SONT FOURNIS « EN L’ÉTAT » ET « SELON LA DISPONIBILITÉ », SANS
      GARANTIE D’AUCUNE SORTE, EXPRESSE OU IMPLICITE, Y COMPRIS, MAIS SANS S’Y
      LIMITER, LES GARANTIES IMPLICITES DE QUALITÉ MARCHANDE, D’ADÉQUATION À UN
      USAGE PARTICULIER, DE TITRE ET D’ABSENCE DE CONTREFAÇON. Dans la mesure où
      certains territoires n’autorisent pas l’exclusion de certaines garanties,
      les dispositions ci-dessus peuvent ne pas s’appliquer à votre cas.
      <p />
      <b>LIMITATION DE RESPONSABILITÉ</b>
      <br />
      EN AUCUN CAS ENTERPRISE, SES SOCIÉTÉS MÈRES, SES FILIALES ET SES SOCIÉTÉS
      AFFILIÉES, AINSI QUE CHACUN DE LEURS ADMINISTRATEURS, DIRIGEANTS,
      ACTIONNAIRES, EMPLOYÉS, SUCCESSEURS, AYANTS DROIT, REPRÉSENTANTS ET AGENTS
      RESPECTIFS (COLLECTIVEMENT, « Enterprise Holdings ») NE SONT RESPONSABLES
      DE TOUT DOMMAGE DIRECT, INDIRECT, ACCESSOIRE, PARTICULIER, CONSÉCUTIF OU
      PUNITIF, OU DE TOUTE PERTE DE PROFITS, D’ACHALANDAGE, D’ÉCONOMIES OU
      D’UTILISATION, DE TOUTE NATURE OU DE TOUTE SORTE, DÉCOULANT DE
      L’EXÉCUTION, DE L’INOBSERVATION OU DE LA RÉSILIATION DES PRÉSENTES
      MODALITÉS D’UTILISATION, OU EN RAPPORT AVEC VOTRE ACCÈS, VOTRE UTILISATION
      OU VOTRE INCAPACITÉ À UTILISER LE SITE, TOUTE INFORMATION FOURNIE PAR LE
      SITE OU TOUTE TRANSACTION CONCLUE DANS LE SITE, QU’IL S’AGISSE DE DOMMAGES
      OU DE PERTES PRÉVISIBLES OU NON, QUE VOUS AYEZ ÉTÉ AVISÉ DE LA POSSIBILITÉ
      DE CES DOMMAGES OU NON, ET QUE CETTE RESPONSABILITÉ SOIT AFFIRMÉE SUR LA
      BASE DU CONTRAT, DE LA RESPONSABILITÉ DÉLICTUELLE (Y COMPRIS LA NÉGLIGENCE
      OU LA RESPONSABILITÉ STRICTE), OU AUTREMENT. Dans la mesure où certains
      territoires n’autorisent pas l’exclusion de certains dommages, les
      dispositions ci-dessus peuvent ne pas s’appliquer à votre cas. Si une
      autorité quelconque juge qu’une partie de cet article est inapplicable, la
      responsabilité sera alors limitée dans toute la mesure permise par la loi
      applicable. Les limitations de responsabilité ci-dessus ne sont pas
      applicables aux résidents du New Jersey. En ce qui concerne les résidents
      du New Jersey, Enterprise, ses sociétés liées et chacun de leurs
      administrateurs, dirigeants, employés et agents respectifs ne seront en
      aucun cas responsables de dommages, sauf si ces dommages découlent
      d’actions ou d’omissions par négligence ou imprudence de notre part et
      nous ne sommes en aucun cas responsables des dommages indirects,
      accessoires, particuliers, consécutifs ou punitifs.
      <p />
      <b>INDEMNISATION</b>
      <br />
      Vous consentez à indemniser et à dégager de toute responsabilité
      Enterprise Holdings à l’égard de toute réclamation ou cause d’action d’un
      tiers, y compris les honoraires et les dépenses raisonnables d’avocat,
      découlant, directement ou indirectement, en tout ou en partie, de votre
      accès au site ou de l’utilisation que vous en avez faite, ou de la
      violation de votre part d’une loi ou des droits de toute personne.
      <p />
      <b>
        DISPOSITION RELATIVE AU RÈGLEMENT DES DIFFÉRENDS – ENTENTE D’ARBITRAGE
        OBLIGATOIRE
        <br />
        VOUS ET ENTERPRISE RENONCEZ RESPECTIVEMENT AU DROIT À UN PROCÈS DEVANT
        JURY ET AU DROIT DE PARTICIPER À UN RECOURS COLLECTIF EN VERTU DES
        MODALITÉS ÉNONCÉES CI-APRÈS. VOUS ET ENTERPRISE ACCEPTEZ DE SOUMETTRE À
        L’ARBITRAGE TOUTE RÉCLAMATION, TOUT DIFFÉREND OU TOUT LITIGE DE QUELQUE
        NATURE QUE CE SOIT (LES « RÉCLAMATIONS ») L’UN À L’ENCONTRE DE L’AUTRE
        DÉCOULANT DE CES MODALITÉS D’UTILISATION OU S’Y RAPPORTANT, Y COMPRIS
        LES RÉCLAMATIONS RELATIVES AUX PRODUITS ET SERVICES, AUX FRAIS, À LA
        PUBLICITÉ OU AUX VÉHICULES DE LOCATION D’ENTERPRISE. AUX FINS DE LA
        PRÉSENTE DISPOSITION RELATIVE AU RÈGLEMENT DES DIFFÉRENDS, « VOUS »
        INCLUT ÉGALEMENT TOUS VOS MANDATAIRES, BÉNÉFICIAIRES OU AYANTS DROIT, OU
        QUICONQUE AGISSANT AU NOM DE CES DERNIERS, ET « ENTERPRISE » INCLUT
        ÉGALEMENT ENTERPRISE HOLDINGS ET SES FOURNISSEURS, Y COMPRIS, MAIS SANS
        S’Y LIMITER, SES FOURNISSEURS DE SERVICES ET PARTENAIRES DE MARKETING.
        VOUS ET ENTERPRISE CONVENEZ QU’AUCUNE RÉCLAMATION NE PEUT DONNER LIEU À
        UN RECOURS COLLECTIF, À UNE ACTION GÉNÉRALE PAR UN REPRÉSENTANT LÉGAL OU
        À TOUTE AUTRE ACTION OU PROCÉDURE DE GROUPE NI ÊTRE RÉSOLUE GRÂCE À CES
        DERNIERS, QU’AUCUN TRIBUNAL ARBITRAL N’A COMPÉTENCE POUR TRANCHER UNE
        RÉCLAMATION DANS UNE PROCÉDURE COLLECTIVE OU REGROUPÉE, ET QU’AUCUNE
        RÈGLE OU AUTRE PROCÉDURE RÉGISSANT LES ARBITRAGES COLLECTIFS OU DE
        GROUPE NE S’APPLIQUE. La disposition relative au règlement des
        différends doit être interprétée au sens large et s’applique à toutes
        les réclamations fondées sur un contrat, la responsabilité civile, une
        loi ou toute autre théorie de droit, ainsi qu’à toutes les réclamations
        ayant pris naissance avant ou après la résiliation de ces modalités
        d’utilisation. Les parties conviennent, cependant, que l’une ou l’autre
        des parties est en droit de porter une action devant un tribunal des
        petites créances compétent, à condition qu’elle y soit maintenue (à
        moins d’un appel de la décision du tribunal des petites créances) et
        qu’elle soit intentée uniquement en votre nom ou à votre encontre, et
        qu’elle ne le soit pas dans le cadre d’un recours collectif, d’une
        action générale par un représentant légal ou d’une autre action de
        groupe. Les parties conviennent également que les réclamations à
        l’encontre ou provenant d’une compagnie d’assurance tierce dont il est
        manifeste qu’elle vous couvre ou couvre un CAS, ou l’application de la
        responsabilité financière d’Enterprise relative à l’utilisation d’un
        véhicule de location Enterprise, peuvent être portées devant un tribunal
        ayant compétence.
      </b>
      <p />
      <b>PROCÉDURE</b>
      <br />
      Une partie doit envoyer à l’autre partie un avis de litige écrit (un «
      avis ») décrivant (a) la nature et les fondements de la réclamation; et
      (b) la mesure de redressement demandée. L’avis destiné à Enterprise doit
      être envoyé à l’adresse suivante : CT Corporation, 208 S LaSalle, Suite
      814, Chicago, IL 60604 (l’« adresse de l’avis »). Si vous et Enterprise ne
      parvenez pas à résoudre le différend dans les trente (30) jours suivant la
      réception de l’avis, l’une des parties peut déclencher la procédure
      d’arbitrage en déposant une demande d’arbitrage auprès de l’American
      Arbitration Association (« AAA »), conformément à ses règles d’arbitrage
      des consommateurs. Les réclamations sont réglées conformément aux règles
      d’arbitrage des consommateurs de l’AAA en vigueur à la date de la demande,
      comme modifiées par les présentes modalités d’utilisation. Toutefois, un
      seul arbitre sera choisi conformément aux règles d’arbitrage commercial de
      l’AAA. L’arbitre effectuera les audiences, le cas échéant, par
      téléconférence ou vidéoconférence plutôt que par comparution en personne,
      sauf s’il détermine, à votre demande ou à celle d’Enterprise, qu’une
      audience en personne est plus appropriée. Les comparutions en personne se
      déroulent à l’endroit qui convient raisonnablement aux deux parties, en
      tenant compte de leur capacité à voyager et d’autres circonstances
      pertinentes. Si les parties ne parviennent pas à s’entendre sur le lieu de
      l’audience, il sera fixé par l’AAA ou par l’arbitre. Les règles de l’AAA
      sont accessibles en ligne à l’adresse www.adr.org. À l’exception des
      obligations légales, aucune partie ni l’arbitre ne peuvent divulguer
      l’existence, le contenu ou l’issue d’un litige ou d’un arbitrage en vertu
      des présentes sans avoir obtenu le consentement écrit préalable des deux
      parties.
      <p />
      <b>AUTORITÉ DE L’ARBITRE</b>
      <br />
      <p>
        L’arbitre est lié par les présentes modalités d’utilisation, la présente
        disposition relative au règlement des différends, la loi fédérale sur
        l’arbitrage (« FAA ») et les règles d’arbitrage des consommateurs de
        l’AAA. L’arbitre n’a pas le pouvoir de réunir ou de grouper des
        réclamations ni de trancher ou juger des réclamations réunies ou
        groupées. L’arbitre a le pouvoir exclusif de trancher tout litige
        relatif à la portée, à l’interprétation, à l’applicabilité, à la force
        exécutoire ou à la formation des présentes modalités d’utilisation, y
        compris à leur nullité éventuelle. Les parties conviennent que la
        décision et l’adjudication de l’arbitre sont sans appel et exécutoires
        et peuvent être confirmées ou remises en question par tout tribunal
        compétent, conformément aux dispositions de la FAA. L’arbitre peut
        accorder les mêmes dommages-intérêts et mesures de redressement qu’un
        tribunal, mais seulement en faveur d’une partie individuelle et dans le
        cadre de la réclamation individuelle d’une partie.
      </p>
      <b>FRAIS D’ARBITRAGE </b>
      <br />
      Vous assumez votre part des frais d’arbitrage (droits de dépôt, frais
      administratifs, etc.), mais seulement à concurrence du montant des droits
      de dépôt que vous auriez engagés si la réclamation avait été portée devant
      un tribunal. Enterprise assume tous les frais d’arbitrage supplémentaires.
      Vous assumez tous les autres frais et droits que vous engagez dans le
      cadre de l’arbitrage, notamment les honoraires d’avocat, des témoins
      experts, etc. Vous n’êtes pas tenu de rembourser à Enterprise ces frais à
      moins que l’arbitre estime que votre réclamation ou la mesure de
      redressement demandée soit frivole. Si l’arbitre en vient à cette
      conclusion, les règles d’arbitrage de l’AAA régissent le paiement de tous
      les frais, et Enterprise peut réclamer la prise en charge d’honoraires
      d’avocat raisonnables. Enterprise paiera tous les frais et coûts qu’elle
      est tenue de payer en vertu de la loi.
      <p />
      <b>DROIT APPLICABLE ET MISE EN APPLICATION</b>
      <br />
      Indépendamment de tout élément des présentes modalités d’utilisation, la
      présente disposition relative au règlement des différends s’applique à
      toute transaction impliquant un commerce entre États et est régie par la
      FAA, Code des É.-U. 9 parag. 1 à 16. La présente disposition relative au
      règlement des différends a été rédigée en conformité avec les lois de tous
      les États. Toutefois, si une partie de la présente disposition relative au
      règlement des différends est réputée non valide ou inexécutoire, ou si
      elle n’est pas applicable à une réclamation, les éléments restants de la
      présente disposition relative au règlement des différends demeurent en
      vigueur et de plein effet.
      <p />
      <b>LOI APPLICABLE</b>
      <br />
      Les présentes modalités d’utilisation seront gouvernées et interprétées
      conformément aux lois de l’État du Missouri aux États-Unis d’Amérique,
      sans égard à ses principes de conflit de lois. Nonobstant ce qui précède
      ou toute autre disposition du contrat de location, le contrat de location
      est régi par les lois du territoire de la prise en charge du véhicule.
      <p />
      <p>
        Toute demande en justice visant à assurer l’application des présentes
        modalités d’utilisation concernant une réclamation qui ne peut être
        réglée par un arbitrage exécutoire doit être déposée devant le tribunal
        fédéral de l’Eastern District du Missouri ou les tribunaux étatiques du
        St. Louis County au Missouri. En utilisant ce site, vous acceptez la
        compétence de ces tribunaux. Toute réclamation ou tout recours que vous
        pourriez avoir à l’égard du site doit être intenté dans un délai d’un
        (1) an après la naissance de la réclamation ou de l’événement à
        l’origine de ce recours, indépendamment de toute loi contraire. Cette
        limitation ne s’applique pas aux résidents du New Jersey.
      </p>
      <b>GÉNÉRALITÉS</b>
      <br />
      Si une disposition de ces modalités d’utilisation est jugée non valide ou
      inexécutoire, cette disposition devra être interprétée conformément à la
      loi applicable, et les autres dispositions devront être respectées. Les
      titres ne servent qu’à des fins de référence et ne définissent, ne
      limitent, n’interprètent ou ne décrivent en aucun cas la portée des
      articles concernés. Le défaut de faire respecter une disposition des
      présentes modalités d’utilisation de notre part ne saurait constituer une
      renonciation à cette disposition ou à toute autre disposition. Ces
      modalités d’utilisation constituent l’intégralité de l’entente conclue
      entre vous et Enterprise relativement à votre utilisation du site.
      <p />
      <b>COMMUNIQUEZ AVEC NOUS</b>
      <br />
      Pour toute question concernant ces modalités d’utilisation, veuillez
      communiquer avec nous à l’adresse{" "}
      <a href="mailto: nicadmin@erac.com">nicadmin@erac.com</a>.
      <p />
    </div>
  ) : (
    <div style={{ marginLeft: "1em", marginBottom: "1em" }}>
      <div>
        <Button
          variant="outlined"
          color="primary"
          title={"Click to Print This Page"}
          aria-label={"Click to Print This Page"}
          onClick={() => {
            window.print();
          }}
        >
          {" "}
          {"Click to Print This Page"}
        </Button>
      </div>
      <h1>Terms of Use</h1>
      <b> Enterprise Travel Direct </b>
      <br />
      <b> Website or Mobile App Terms of Use</b>
      <br />
      <b> Last Updated : 2024</b>
      <br /> <br />
      <b>INTRODUCTION</b>
      <br />
      EAN Services, LLC (“Enterprise” or “We”) provides this Website or Mobile
      App (“Site”) for your use, subject to these Terms and Conditions of Use
      (“Terms of Use”) and all applicable laws and regulations. Please read
      these Terms of Use carefully. By accessing and/or using the Site, you
      fully and unconditionally accept and agree to be bound by these Terms of
      Use, including binding arbitration. If you do not agree to these Terms of
      Use, please do not visit or use the Site. Enterprise reserves the right to
      revise these Terms of Use, so please check back periodically for changes.
      Your continued use of the Site following the posting of any changes to
      these Terms of Use constitutes your acceptance of those changes. All
      changes are effective immediately when We post them, and apply to use of
      the Site thereafter.
      <p />
      <b>USE OF THE SITE</b>
      <br />
      Enterprise maintains the Site for your informational and non-commercial
      personal use. Your use of the Site for any other purpose is permissible
      only upon the express prior written consent of Enterprise. Without
      limiting the foregoing, you may not: (1) use the Site in a commercial
      manner, including by distributing, transmitting or publishing the Site or
      any of its content; (2) interfere with others’ use of the Site; (3) impair
      the Site’s operation or interfere with or disrupt the servers or networks
      connected to it; (4) interfere with Enterprise’s intellectual property
      rights; (5) frame or otherwise co-brand the Site or any of its content;
      (6) deep-link to any portion of the Site; or (7) use the Site for any
      illegal purpose. We reserve the right in our sole discretion to terminate
      or restrict your use of the Site, without notice, for any or no reason,
      and without liability to you or any third party. In such event, We may
      inform your Internet service provider of your activities and take
      appropriate legal action.
      <p />
      <b>SITE MODIFICATIONS</b>
      <br />
      We reserve the right, in our sole discretion, to modify, suspend or
      discontinue any part of the Site at any time, without notice or liability
      to you or any third party. We also reserve the right, in our sole
      discretion, to impose limits on certain features and services.
      <p />
      <b>LINKS TO OTHER WEBSITES OR MOBILE APPS</b>
      <br />
      The Site may include links to third-party websites or mobile apps.
      Enterprise does not control and is not responsible for the content, terms
      of use, or privacy policies of any linked website or mobile app, and your
      use of such third-party websites and mobile apps is governed by their
      respective terms of use and privacy policies, as applicable. The inclusion
      of any link on the Site does not imply our endorsement of it.
      <p />
      <b>RESERVATIONS AND TRANSACTIONS</b>
      <br />
      All reservations and transactions made through the Site are subject to
      Enterprise’s acceptance, which is in our sole discretion. Without
      limitation, this means that Enterprise may refuse to accept or may cancel
      any reservation or transaction, whether or not the reservation or
      transaction has been confirmed, for any or no reason, in our sole
      discretion, and without liability to you or any third party. The rental
      contract that governs your rental (“Rental Contract”) is entered into
      separately between Enterprise's affiliate that operates the rental
      location (“Owner”) and you; except as specifically provided in Check-In
      below, it is not entered into through this Site. All rentals remain
      subject and subordinate to availability and any applicable qualifications
      and rental conditions. In addition, all reservation requests are
      non-binding for you and Owner. Owner does not have any duty or obligation
      to keep any reserved vehicles available.
      <p />
      <b>COUPONS</b>
      <br />
      Enterprise provides specific discount coupons from time to time for the
      exclusive use of employees or members of specific entities with which
      Enterprise has a contractual relationship. These entity-specific coupons
      and the proprietary codes affixed on them are our proprietary property and
      may only be used by the employees or members of the specific entities for
      which the coupons apply. UNAUTHORIZED USE OR DUPLICATION OF ANY OF OUR
      ENTITY-SPECIFIC DISCOUNT COUPONS IS STRICTLY PROHIBITED.
      <p />
      <b>CHECK-IN </b>
      <br />
      To check-in via the Site, you must (i) submit personal information to us
      or a third party designated by us that may include, but is not limited to,
      your name, email address, phone number, date of birth, payment card
      information, and your driver's license information, (ii) confirm the date,
      time, rental location, and vehicle class for your reservation, and (iii)
      accept or decline protections and extras. You may also be asked to
      electronically agree to the Rental Contract. For purposes of
      clarification, references to the “Rental Agreement Summary” or “Summary”
      in the Rental Contract shall also include information provided by you as
      well as information presented to you during check-in. Changes to your
      rental details at the time of pick-up may require you to enter into a new
      Rental Contract at that time. Subject to our Global Privacy Policy
      described below, we will use third parties (including, but not limited to,
      LexisNexis for purposes of fraud prevention) or in some cases you will
      submit the information directly to such third parties for the purpose of
      facilitating the transaction. You expressly acknowledge and agree that any
      changes to your reservation, including, but not limited to, upgrades,
      protections, and extras, as well as changes to pick up or drop off dates,
      times, or locations, may result in increased charges and fees associated
      with your reservation. If you do not complete check-in via the Site, you
      must check-in upon arrival at the rental location.
      <p />
      <b>PAYMENT AUTHORIZATION</b>
      <br />
      Prior to finalizing the check-in process, your estimated charges will be 
      displayed for your confirmation. By electronically agreeing to the Rental 
      Contract or saving your card details, you authorize Owner (via a third party) to 
      charge to that credit card and/or debit card all amounts owed under the Rental 
      Contract for advance deposits, incremental authorizations / deposits, and any 
      other amounts owed by you, as well as payments refused by a third party to whom 
      billing was directed, all pursuant to the terms of the Rental Contract. 
      You also authorize Owner to re-initiate, to that credit/debit card, 
      any charge that is dishonored for any reason.
      <p />
      <b>ELECTRONIC COMMUNICATIONS</b>
      <br />
      By using the Site, you consent to receiving electronic communications and
      notices from Enterprise. You agree that any notice, agreement, disclosure
      or other communications that We send to you electronically will satisfy
      any legal communication requirements, including that such communications
      be in writing.
      <p />
      <b>SIGNING DOCUMENTS ELECTRONICALLY</b>
      <br />
      By using the Site, you consent to transact business electronically using
      electronic signatures in lieu of paper documents.
      <p />
      <b>INTELLECTUAL PROPERTY</b>
      <br />
      All copyrightable text, graphics, sound, downloads, software, including
      source and binary code, and other material (collectively, the “Content”),
      the selection, compilation, arrangement and presentation of all materials,
      and the overall design of the Site are copyrighted by Enterprise, or are
      licensed to Enterprise, and are protected by law. All rights reserved. You
      may print a copy of the Content on your computer only for your own
      personal, non-commercial home use, provided that you do not remove any
      copyright, trademark or other proprietary notices from the Content. Any
      other use of the Content is strictly prohibited, unless you have our prior
      written permission. The Site may also contain content that is owned by
      third parties, including our partners and advertisers. You may use such
      third-party content only as expressly authorized by the applicable owner.
      All requests for permission to reprint or make any other use of the
      Content should be addressed to Intellectual Property Manager, Copyright
      Reprint Permission, 600 Corporate Park Drive, St. Louis, Missouri 63105 or
      {" "}<a href="mailto:nicadmin@erac.com">nicadmin@erac.com</a>. Enterprise does
      not warrant that your use of materials displayed on or linked to the Site
      will not infringe the rights of third parties.
      <p />
      <p>
        ENTERPRISE, the “e” logo, WWW.ENTERPRISE.COM, ENTERPRISE.COM, WE’LL PICK
        YOU UP, ARMS, ECARS, MONTH OR MORE, CORPORATE CLASS, EVERYDAY SALE are
        included in the family of trademarks and service marks owned by
        Enterprise (this is not an all-inclusive list of Enterprise’s trademarks
        and service marks). Trademarks and service marks designated with the ®
        symbol are registered with the U.S. Patent and Trademark Office and with
        numerous foreign countries. Other trademarks and service marks belonging
        to Enterprise may be designated with the “SM” or “TM” symbols. Our
        trademarks and service marks may not be used in connection with any
        product or service without our prior written permission. All other
        brands, trademarks and names not owned by Enterprise that appear on the
        Site are the property of their respective owners, who may or not be
        affiliated with, connected to or endorsed by Enterprise.
      </p>
      <p>
        Enterprise has a number of pending patent applications, both US and
        foreign, covering various aspects of this Site, and users are cautioned
        from copying, adopting, using or otherwise borrowing any techniques,
        menu operations, or other functional aspects or features as doing so may
        well result in liability for patent infringement.
      </p>
      <b>CLAIMS OF COPYRIGHT INFRINGEMENT</b>
      <br />
      If you believe that materials posted on the Site violate your intellectual
      property rights, please contact Enterprise at Intellectual Property
      Manager, Intellectual Property Questions, 600 Corporate Park Drive, St.
      Louis, Missouri 63105 or{" "}
      <a href="mailto:nicadmin@erac.com">nicadmin@erac.com</a>. Please include:
      (1) an electronic or physical signature of the person authorized to act on
      behalf of the owner of the copyright or other proprietary interest; (2) a
      description of the copyrighted work or other protected material that you
      claim has been infringed upon; (3) a description of where the material is
      located on the Site; (4) your address, telephone number and e-mail
      address; (5) your statement that you have a good faith belief that the
      disputed use is not authorized by the copyright or other interest owner,
      its agent or the law; and (6) your statement, made under penalty of
      perjury, that the information in your notice is accurate and that you are
      the copyright or other interest owner or are authorized to act on the
      owner’s behalf.
      <p />
      <b>PRIVACY POLICY AND USE OF INFORMATION SUBMITTED</b>
      <br />
      Any information submitted on or collected through the Site is subject to
      our Global Privacy Policy [LINK], the terms of which are incorporated into
      these Terms of Use. Enterprise reserves the right to amend the Global
      Privacy Policy from time to time, in its sole discretion. In the event of
      a change to the Global Privacy Policy, Enterprise shall provide notice in
      the manner set forth in the Global Privacy Policy. You agree that
      Enterprise is free to use any comments, information or ideas contained in
      any communication you may send to us, without compensation,
      acknowledgement or payment to you, for any purpose whatsoever, including,
      but not limited to, developing, providing and marketing products and
      services and creating, modifying or improving the Site or other products
      or services.
      <p />
      <b>PASSWORD SECURITY</b>
      <br />
      If you have a username and/or password for access to non-public areas of
      the Site, you are solely responsible for maintaining the confidentiality
      and use of the username and/or password. Further, you are responsible for
      all activities that occur in connection with your username and/or
      password. You alone are responsible for all transactions initiated,
      messages posted, statements made, or acts or omissions that occur within
      the Site through the use of your username and/or password. You agree to
      notify us immediately of any unauthorized access to or use of your
      username and/or password or any other breach of security.
      <p />
      <b>NO WARRANTY</b>
      <br />
      While We use our reasonable efforts to maintain the accuracy and
      reliability of the Site, We do not warrant or represent that it will
      always function or be error-free. We assume no responsibility or liability
      for errors or omissions on the Site or for problems with its operation.
      Your access and use of the Site are at your own risk. Without limiting the
      foregoing, THE SITE, ITS CONTENT AND ANY PRODUCT OR SERVICE OFFERED
      THROUGH IT ARE PROVIDED “AS IS” AND “AS AVAILABLE,” WITHOUT WARRANTY OF
      ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE
      IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
      TITLE AND NON-INFRINGEMENT. Some jurisdictions do not allow the exclusion
      of certain warranties, so the above may not apply to you.
      <p />
      <b>LIMITATION OF LIABILITY</b>
      <br />
      IN NO EVENT SHALL ENTERPRISE, ITS PARENT COMPANY, SUBSIDIARIES AND
      AFFILIATES, AND EACH OF THEIR RESPECTIVE DIRECTORS, OFFICERS,
      SHAREHOLDERS, EMPLOYEES, SUCCESSORS, ASSIGNS, REPRESENTATIVES AND AGENTS
      (collectively, “Enterprise Holdings”) BE LIABLE FOR ANY DIRECT, INDIRECT,
      INCIDENTAL, SPECIAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, OR LOST PROFITS,
      GOODWILL, SAVINGS, OR USE, OF ANY KIND OR NATURE ARISING OUT OF, OR
      RELATING TO THE PERFORMANCE, BREACH, OR TERMINATION OF THESE TERMS OF USE,
      ARISING OUT OF OR IN CONNECTION WITH YOUR ACCESS, USE OR INABILITY TO USE
      THE SITE, ANY INFORMATION PROVIDED BY THE SITE OR ANY TRANSACTION ENTERED
      INTO THROUGH THE SITE, WHETHER SUCH DAMAGE OR LOSS IS FORESEEABLE OR NOT,
      WHETHER YOU HAVE BEEN ADVISED OF THE POSSIBILITY THEREOF OR NOT, AND
      WHETHER SUCH LIABILITY IS ASSERTED ON THE BASIS OF CONTRACT, TORT
      (INCLUDING NEGLIGENCE OR STRICT LIABILITY), OR OTHERWISE. Some
      jurisdictions do not allow the exclusion of certain damages, so the above
      may not apply to you. If any authority holds any portion of this section
      to be unenforceable, then liability will be limited to the fullest extent
      permitted by applicable law.
      <p />
      <p>
        The foregoing limitation of liability is not applicable to residents of
        New Jersey. With respect to residents of New Jersey, Enterprise, its
        related companies, and each such company’s directors, officers,
        employees and agent are not liable for any damages unless such damages
        are the result of our negligent or reckless acts or omissions; and We
        are not, in any case liable for indirect, incidental, special,
        consequential or punitive damages.
      </p>
      <b>INDEMNITY</b>
      <br />
      You agree to indemnify and hold Enterprise Holdings harmless from and
      against any third-party claim or cause of action, including reasonable
      attorneys’ fees and costs, arising, directly or indirectly, in whole or in
      part, out of your access or use of the Site or your violation of any law
      or the rights of any person.
      <p />
      <b>DISPUTE RESOLUTION PROVISION - MANDATORY ARBITRATION AGREEMENT</b>
      <br />
      <b>
        YOU AND ENTERPRISE EACH WAIVE THE RIGHT TO A JURY TRIAL OR TO
        PARTICIPATE IN A CLASS ACTION PURSUANT TO THE FOLLOWING TERMS. YOU AND
        ENTERPRISE AGREE TO ARBITRATE ANY AND ALL CLAIMS, CONTROVERSIES OR
        DISPUTES OF ANY KIND (“CLAIMS”) AGAINST EACH OTHER ARISING OUT OF OR
        RELATING IN ANY WAY TO THESE TERMS OF USE, INCLUDING BUT NOT LIMITED TO,
        CLAIMS RELATING TO ENTERPRISE’S PRODUCTS AND SERVICES, CHARGES,
        ADVERTISINGS, OR RENTAL VEHICLES. FOR THE PURPOSES OF THIS DISPUTE
        RESOLUTION PROVISION, “YOU” ALSO INCLUDES ANY OF YOUR AGENTS,
        BENEFICIARIES, ASSIGNS, OR ANYONE ACTING ON BEHALF OF THE FOREGOING, AND
        “ENTERPRISE” ALSO INCLUDES ENTERPRISE HOLDINGS AND ITS VENDORS,
        INCLUDING BUT NOT LIMITED TO ITS SERVICE PROVIDERS AND MARKETING
        PARTNERS. YOU AND ENTERPRISE AGREE THAT NO CLAIMS WILL BE PURSUED OR
        RESOLVED AS A PART OF A CLASS ACTION, PRIVATE ATTORNEY GENERAL OR OTHER
        REPRESENTATIVE ACTION OR PROCEEDING, THAT NO ARBITRATION FORUM WILL HAVE
        JURISDICTION TO DECIDE ANY CLAIMS ON A CLASS-WIDE, COLLECTIVE, OR
        CONSOLIDATED BASIS, AND THAT NO RULES OR OTHER PROCEDURES FOR CLASS-WIDE
        OR COLLECTIVE ARBITRATION WILL APPLY. This Dispute Resolution Provision
        is to be broadly interpreted and applies to all Claims based in
        contract, tort, statute, or any other legal theory, and all Claims that
        arose prior to or after termination of these Terms of Use. However, the
        parties agree that either party may bring an individual action in a
        small claims court with valid jurisdiction, provided that the action
        remains in that court (other than any appeal of the small claims court
        ruling), is made on behalf of or against you only and is not made part
        of a class action, private attorney general action or other
        representative or collective action. The parties also agree that claims
        against or by a third-party insurance company ostensibly providing
        coverage to you or any AAD or the application of Enterprise’s financial
        responsibility relating to the use or operation of an Enterprise rental
        vehicle may be brought in a court with valid jurisdiction.
        <p />
      </b>
      <b>PROCEDURE</b>
      <br />
      A party must send a written Notice of Dispute (“Notice”) describing (a)
      the nature and basis of the Claim; and (b) the relief sought, to the other
      party. The Notice to Enterprise should be addressed to: CT Corporation,
      208 S LaSalle, Suite 814, Chicago, IL 60604 (“Notice Address”). If you and
      Enterprise do not resolve the Claim within thirty (30) days after the
      Notice is received, a party may commence an arbitration by filing a demand
      for arbitration with the American Arbitration Association (“AAA”) pursuant
      to its Consumer Arbitration Rules. Claims will be resolved pursuant to the
      AAA’s Consumer Arbitration Rules in effect at the time of the demand, as
      modified by these Terms of Use. However, a single arbitrator will be
      selected according to AAA’s Commercial Arbitration Rules. The arbitrator
      will conduct hearings, if any, by teleconference or videoconference,
      rather than by personal appearances, unless the arbitrator determines upon
      request by you or Enterprise that an in-person hearing is appropriate. Any
      in-person appearances will be held at a location which is reasonably
      convenient to both parties with due consideration of their ability to
      travel and other pertinent circumstances. If the parties are unable to
      agree on a location, such determination should be made by the AAA or by
      the arbitrator. The AAA rules are available online at www.adr.org. Except
      as required by law, neither a party nor an arbitrator may disclose the
      existence, content, or results of any dispute or arbitration hereunder
      without the prior written consent of both parties.
      <p />
      <b>ARBITRATOR’S AUTHORITY</b>
      <br />
      The arbitrator is bound by these Terms of Use, this Dispute Resolution
      Provision, the Federal Arbitration Act (“FAA”) and AAA’s Consumer
      Arbitration Rules. The arbitrator has no authority to join or consolidate
      Claims, or adjudicate joined and consolidated Claims. The arbitrator has
      exclusive authority to resolve any dispute relating to the scope,
      interpretation, applicability, enforceability or formation of these Terms
      of Use, including whether it is void. The parties agree that the
      arbitrator’s decision and award will be final and binding and may be
      confirmed or challenged in any court with jurisdiction as permitted under
      the FAA. The arbitrator can award the same damages and relief as a court,
      but only in favor of an individual party and for a party’s individual
      claim.
      <p />
      <b>ARBITRATION COSTS </b>
      <br />
      You will be responsible for your share of any arbitration fees (e.g.,
      filing, administrative, etc.), but only up to the amount of filing fees
      you would incur if the Claims were filed in court. Enterprise will be
      responsible for all additional arbitration fees. You are responsible for
      all other costs/fees that you incur in arbitration, e.g., fees for
      attorneys, expert witnesses, etc. You will not be required to reimburse
      Enterprise for any fees unless the arbitrator finds that the substance of
      your Claim(s) or the relief sought is frivolous. If the arbitrator makes
      such a finding, AAA Rules will govern the payment of all fees, and
      Enterprise may seek reasonable attorney’s fees. Enterprise will pay all
      fees and costs it is required by law to pay.
      <p />
      <b>GOVERNING LAW AND ENFORCEMENT</b>
      <br />
      Notwithstanding anything in these Terms of Use, this Dispute Resolution
      Provision is made pursuant to a transaction involving interstate commerce,
      and shall be governed by the FAA, 9 U.S.C. §§ 1-16. This Dispute
      Resolution Provision was drafted in compliance with the laws in all
      states, however, if any portion of it is deemed to be invalid or
      unenforceable or is found not to apply to a Claim, the remainder of the
      Dispute Resolution Provision remains in full force and effect.
      <p />
      <b>APPLICABLE LAW</b>
      <br />
      These Terms of Use are governed by and construed in accordance with the
      laws of the State of Missouri, United States of America, without regard to
      its conflict of law principles. Notwithstanding the foregoing or anything
      in the Rental Contract, the Rental Contract shall be governed by the laws
      of the jurisdiction in which you pick up the car.
      <p />
      <p>
        Any action to enforce these Terms of Use regarding a Claim not subject
        to binding arbitration must be brought in the federal court in the
        Eastern District of Missouri or the state courts located in St. Louis
        County, Missouri. By using the Site, you consent to the jurisdiction and
        venue of these courts. Any claim or cause of action you may have with
        respect to the Site must be commenced within one (1) year after the
        claim or cause of action arises, regardless of any law to the contrary.
        This limitation does not apply to residents of New Jersey.
      </p>
      <b>GENERAL</b>
      <br />
      If any provision of these Terms of Use is held to be invalid or
      unenforceable, that provision must be construed consistent with applicable
      law, and the remaining provisions must be enforced. Headings are for
      reference purposes only and in no way define, limit, construe or describe
      the scope of such section. Our failure to enforce any provision of these
      Terms of Use shall not constitute a waiver of that or any other provision.
      These Terms of Use set forth the entire agreement between you and
      Enterprise in connection with your use of the Site.
      <p />
      <b>CONTACT US</b>
      <br />
      If you have any questions about these Terms of Use, please contact us at{" "}
      <a href="mailto: nicadmin@erac.com">nicadmin@erac.com</a>.
      <p />
    </div>
  );
};

export default TermsOfUse;
