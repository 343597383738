import React, { useEffect, useState } from "react";
import {
  Backdrop,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Link,
  Paper,
  TextField,
  Typography,
  Box,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from "../../helpers/axiosInstance";
import { useTranslation } from "react-i18next";
import { getEnumKeyByEnumValue } from "../../helpers/enumMapping";
import { authService } from "../../services/authService";
import {
  getcompanyCountryCurrency,
  getLocaleAmount,
} from "../../helpers/localization";
import CCProduct from "Assets/Images/CCProduct.png";
import CSProduct from "Assets/Images/CSProduct.png";
import CarClubLogo from "Assets/Images/CarClubLogo.png";
import CarShareLogo from "Assets/Images/CarShareLogo.png";
import orangeEexclamation from "Assets/Images/orange_exclamation_icon.png";
import {
  showSuccessAlertMessage,
  clearAlertMessage,
  showErrorAlertMessage,
  showInfoAlertMessage,
} from "../store/actions/shared/alertActions";
import { broadcastAnnouncement } from "../store/actions/shared/announcementActions";
import { appInsights } from "../../helpers/appInsights";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import {
  ReAuthenticateCarClub,
  reAuthenticationStatus,
} from "../../services/carClubReAuth";
import {
  CarClubDriverState,
  driverDetails,
  SearchMatchType,
} from "../../components/Reservation/steps/Driver/carClubDriverType";
import {
  carClubDriverDetails,
  resetCarClubDriverDetails,
} from "../store/actions/reservation/carclubActions";
import { GetCarClubDrivers } from "../../services/carClubDriverService";
import {
  AreStringsEqual,
  isNullOrUndefined,
} from "../../helpers/stringHelpers";
import { AppState } from "../store/reducers/rootReducer";
import { locale } from "moment";
import { ReservationProps } from "../Reservation/reservationType";
import { JourneyType } from "../../views/reservation/ReservationViewType";
import { DriverInfo } from "../Reservation/steps/JourneyStart/journeyInputType";
import { useNavigate } from "react-router-dom";
import { CCHomeProps } from "../admin/ccProfileType";
import { setCarClubMemberIdSkipLink } from "components/store/slicers/skipLinkSlice";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    "& > *": {
      margin: theme.spacing(3),
    },
    "& .MuiFormLabel-root": {
      fontSize: "0.75rem",
    },
  },
  paper: {
    padding: theme.spacing(3),
    border: "1px solid #006639",
    "&:focus": {
      border: "3px solid #006639",
    },
  },
  imageframe: {
    maxWidth: 236,
  },
  img: {
    margin: "15px",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
    "&:focus": {
      border: "3px solid #006639",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
  centerColumn: {
    "@media (min-width:360px)": {
      borderRight: "3px dotted black",
    },
  },
  customHoverFocus: {
    "&.Mui-focusVisible": { backgroundColor: "#17ED4B" },
    "&:hover": { backgroundColor: "#17ED4B" },
  },
}));

const DarkerDisabledTextField = withStyles({
  root: {
    marginRight: 8,
    "& .MuiInputBase-root.Mui-disabled": {
      color: "#767676",
    },
  },
})(TextField);

export const CarClubHome: React.FC<CCHomeProps> = (props) => {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const { t } = useTranslation();
  const locale = authService.getUserLocale();
  const companyCountryCode = authService.getUserCompanyCountryCode();

  const takoApiEnv = authService.getTakoApiEnv();
  const isCarShareReservation = String(takoApiEnv).toUpperCase().includes("CS");

  const [isCCLoggedIn, setIsCCLoggedIn] = useState(
    JSON.parse(localStorage.getItem("ccLoggedIn") ?? "false") ?? []
  );

  useEffect(() => {
    window.localStorage.setItem("ccLoggedIn", JSON.stringify(isCCLoggedIn));
  }, [isCCLoggedIn]);

  const isccReauthExpired =
    !isCCLoggedIn || authService.getUserccReauthExpired();
  const isccPersisted = isCCLoggedIn && authService.getUserccPersisted();
  const titleRef = React.useRef<HTMLDivElement>(null);
  const ccMemIdRef = React.useRef<HTMLInputElement>(null);
  const ccPwdRef = React.useRef<HTMLInputElement>(null);
  const [ccDriverId, setccDriverId] = React.useState(
    authService.getUserDriverId()
  );
  const [ccMemberId, setccMemberId] = React.useState(
    authService.getUserCarClubCompanyId()
  );
  const [isccMemIdError, setisccMemIdError] = React.useState(false);
  const [isccMemIdSaved, setisccMemIdSaved] = React.useState(
    !!authService.getUserDriverId()
  );
  const [isPasswordDisabled, setisPasswordDisabled] = React.useState(
    !isccReauthExpired && isccPersisted
  );
  const [isccMemIdEmpty, setisccMemIdEmpty] = React.useState(
    !authService.getUserDriverId()
  );
  const [ccKeepMeSignedin, setccKeepMeSignedin] = React.useState(isccPersisted);
  const [ccPwd, setCCPwd] = React.useState(isccReauthExpired ? "" : "********");
  const [isReauth, setisReauth] = React.useState(false);
  const [isccPwdError, setisccPwdError] = React.useState(false);
  const [isccPwdEmpty, setisccPwdEmpty] = React.useState(true);
  const driverInfo: DriverInfo = authService.getDriverInfo();

  const { companyInfo } = useSelector((state: AppState) => state.companyInfo);

  const isDriverValidityActivated = companyInfo?.isDriverValidityActivated;

  const delay = (ms) => new Promise((res) => setTimeout(res, ms));
  const dispatch = useDispatch();

  const ccPasswordResetLink = companyInfo?.ccPasswordResetLink;
  const csPasswordResetLink = companyInfo?.csPasswordResetLink;
  const ccEnrollmentLink = companyInfo?.ccEnrollmentLink ?? "";

  useEffect(() => {
    console.log("prodselc");
    dispatch(clearAlertMessage());
    dispatch(resetCarClubDriverDetails);
    dispatch(setCarClubMemberIdSkipLink())
    if (titleRef.current !== null) titleRef.current.focus();
    if (props.focusCCPwd && ccPwdRef.current !== null) ccPwdRef.current.focus();
  }, []);
  useEffect(() => {
    if (isPasswordDisabled) {
      setCCPwd("********");
      setisccPwdError(false);
    } else {
      setCCPwd("");
      if (isccMemIdEmpty && ccMemIdRef.current != null)
        ccMemIdRef.current.focus();
      else if (ccPwdRef.current != null) ccPwdRef.current.focus();
    }
  }, [isPasswordDisabled]);

  const PaperFocused = (productName: string) => {
    dispatch(broadcastAnnouncement(""));
  };

  let history = useNavigate();

  const HandleRedirect = () => {
    if (props.redirectTo !== "") {
      history(props.redirectTo);
    } else {
      window.location.reload();
    }
  };

  useEffect(() => {
    if (isCCLoggedIn) {
      HandleRedirect();
    }
  }, []);

  const handleCCMemberIdChange = (params) => {
    if (params.target.value.length == 0) {
      setisccMemIdError(true);
    } else {
      setisccMemIdError(false);
      setisccMemIdEmpty(false);
    }
    setccDriverId(params.target.value);
  };

  const handleCCPasswordChange = (params) => {
    if (ccPwdRef.current != null) ccPwdRef.current.focus();
    if (params.target.value.length == 0 || params.target.value.length < 4) {
      setisccPwdError(true);
    } else {
      setisccPwdError(false);
      setisccPwdEmpty(false);
    }
    setCCPwd(params.target.value);
  };

  const handleCCPasswordKeyPress = (e) => {
    if (e.keyCode === 13) {
      AuthenticateCC();
    }
  };

  const buttonFocusedAndClickedReadout = (productName: string) => {
    dispatch(broadcastAnnouncement(""));
    setTimeout(() => {
      dispatch(
        broadcastAnnouncement(t("Res.continueForProduct") + productName)
      );
    }, 200);
  };

  const ReAuthenticateCC = async () => {
    dispatch(clearAlertMessage());
    setLoading(true);
    const reAuth = (await ReAuthenticateCarClub(
      ccMemberId.concat("-", ccDriverId)
    )) as reAuthenticationStatus;

    if (reAuth.reAuthenticated) {
      authService.setUserccPersisted(ccKeepMeSignedin);
      history("/admin/profiles/myCCprofile");
    } else {
      setisReauth(true);
      setisPasswordDisabled(false);
      setLoading(false);
      if (ccPwdRef.current != null) ccPwdRef.current.focus();
    }
  };

  const AuthenticateCC = async () => {
    dispatch(clearAlertMessage());

    buttonFocusedAndClickedReadout("Car Club");

    await delay(500);

    if (isccMemIdEmpty || isccMemIdError) {
      if (ccMemIdRef.current != null) ccMemIdRef.current.focus();
      setisccMemIdError(true);
    } else if (isccPwdEmpty || isccPwdError || loading) {
      if (ccPwdRef.current != null) ccPwdRef.current.focus();
      setisccPwdError(true);
    } else {
      setLoading(true);
      const result = axiosInstance
        .post("carclub/authenticate", {
          driverId: authService
            .getUserCarClubCompanyId()
            .concat("-", ccDriverId),
          driverPassword: ccPwd,
          IsCCPersisted: ccKeepMeSignedin,
        })
        .then(async (response) => {
          if (response.data.success) {
            authService.setUserccPersisted(ccKeepMeSignedin);
            authService.setUserDriverId(ccDriverId);
            setIsCCLoggedIn(true);
            if (isccReauthExpired) {
              authService.setUserccReauthExpired(
                response.data.credentials.isReAuthCodeExpired
              );
              setisPasswordDisabled(
                !response.data.credentials.isReAuthCodeExpired
              );
            }

            console.log("CC validation successful");
            HandleRedirect();
            //save CCMemID if it's not saved in profile
            if (!isccMemIdSaved) {
              //SaveCarClubMemberID(ccDriverId);
            }
          } else {
            setIsCCLoggedIn(false);
            dispatch(clearAlertMessage());
          setLoading(false);
          if (ccPwdRef.current != null) ccPwdRef.current.focus();
            if (
              response.data.message.trim() === "ERR20039" ||
              response.data.message.trim() === "ERRCCJail"
            ) {
              dispatch(
                showErrorAlertMessage(t(response.data.message.trim()))
              );
              dispatch(
                broadcastAnnouncement(t(response.data.message.trim()))
              );
            } else if (
              response.data.message.trim() === "UnexpectedError"
            ) {
              dispatch(showErrorAlertMessage(t("UnexpectedError")));
              dispatch(broadcastAnnouncement(t("UnexpectedError")));
            } else {
              dispatch(showErrorAlertMessage(response.data.message));
              dispatch(broadcastAnnouncement(response.data.message));
            }
            window.scrollTo(0, 0);
          }
        })
        .catch((error) => {
          dispatch(clearAlertMessage());
          setLoading(false);
          appInsights.trackException({
            properties: {
              method: "AuthenticateCC",
              Component: "Product",
            },
            exception: error,
            severityLevel: SeverityLevel.Error,
          });
          if (ccPwdRef.current != null) ccPwdRef.current.focus();
         console.error("There is an error", error);

          window.scrollTo(0, 0);
        });
    }
  };

  return (
    <Grid container>
      <Grid id="CarClubHome" item xs={12} md={9}>
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress />
        </Backdrop>

        <div
          className={classes.root}
          key="ccHome" //{index}
          hidden={props.hideCCTile}
        >
          <Paper className={classes.paper} elevation={3} square>
            <Grid item xs container spacing={2}>
              <Grid container spacing={3} direction="row">
                <Grid item xs={12} sm={3}>
                  <img
                    className={classes.img}
                    alt="" //{product.productName}
                    src={isCarShareReservation ? CSProduct : CCProduct}
                  />
                </Grid>
                <Grid item xs={12} sm={6} container>
                  <Grid item xs container spacing={2}>
                    <Grid
                      item
                      style={{
                        minWidth: 100,
                        maxWidth: 200,
                        padding: 0,
                      }}
                    >
                      <Typography>
                        <img
                          style={{
                            display: "block",
                            maxWidth: "100%",
                            maxHeight: "100%",
                            marginBottom: "10px",
                          }}
                          alt="Enterprise Car Club"
                          id={"CarClubLogoImage"}
                          src={isCarShareReservation ? CarShareLogo : CarClubLogo}
                        />
                      </Typography>
                    </Grid>
                    <Grid
                      container
                      direction="column"
                      style={{ minWidth: 110 }}
                      className={classes.centerColumn}
                    >
                      <Grid container>
                        <Grid
                          item
                          xs={12}
                          md={12}
                          direction="row"
                          container
                          style={{ marginBottom: 10 }}
                        >
                          <Grid item md={4} xs={12}>
                            <Typography
                              variant="body2"
                              style={{
                                fontSize: "1.1em",
                                margin: "5px",
                                fontWeight: "bold",
                              }}
                              id="carClubMemberId"
                            >
                              {t("Res.carClubMemberId")}
                            </Typography>
                          </Grid>
                          <Grid item md={3} xs={12}>
                            <Typography
                              variant="body2"
                              style={{
                                fontSize: "1.1em",
                                fontWeight: "bold",
                                margin: "5px",
                              }}
                            >
                              {ccMemberId + "   - "}
                            </Typography>
                          </Grid>
                          <Grid item md={5} xs={12}>
                            <DarkerDisabledTextField
                              style={{
                                marginRight: 10,
                                minWidth: 90,
                                maxWidth: 120,
                              }}
                              label={t("Res.carClubMemberId")}
                              size="small"
                              id="carClubDriverIdTextBox"
                              defaultValue={ccDriverId}
                              disabled={!!isccMemIdSaved}
                              inputRef={ccMemIdRef}
                              inputProps={{
                                "aria-label": t("Res.carClubMemberId"),
                                "aria-required": true,
                              }}
                              error={isccMemIdError}
                              helperText={
                                isccMemIdError
                                  ? t("Res.carclubMemberIDValidation")
                                  : ""
                              }
                              required
                              variant="outlined"
                              //onKeyDown={keyPress}
                              onChange={handleCCMemberIdChange}
                            />
                          </Grid>
                        </Grid>

                        <Grid item xs={12} md={12} direction="row" container>
                          <Grid item md={4} xs={12}>
                            <Typography
                              variant="body2"
                              style={{
                                fontSize: "1.1em",
                                margin: "5px",
                                fontWeight: "bold",
                              }}
                            >
                              {t("Res.carClubPin")}
                            </Typography>
                          </Grid>
                          <Grid item md={3}></Grid>
                          <Grid item md={5} xs={12}>
                            <DarkerDisabledTextField
                              style={{
                                marginRight: 10,
                                minWidth: 90,
                                maxWidth: 120,
                              }}
                              id="carClubPinTextBox"
                              label={t("Res.carClubPin")}
                              type="password"
                              size="small"
                              inputRef={ccPwdRef}
                              inputProps={{
                                "aria-label": t("Res.carClubPin"),
                                "aria-required": true,
                              }}
                              {...(isccPwdError && {
                                "aria-describedby":
                                  "carClubPinTextBox-helper-text",
                              })}
                              value={ccPwd}
                              error={isccPwdError}
                              helperText={
                                isccPwdError
                                  ? t("Res.carclubPasswordValidation")
                                  : ""
                              }
                              disabled={
                                //!product.isSelectable ||
                                isPasswordDisabled
                              }
                              required
                              variant="outlined"
                              onKeyDown={(e) => {
                                handleCCPasswordKeyPress(e);
                              }}
                              onChange={handleCCPasswordChange}
                            />
                          </Grid>
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <FormControlLabel
                            id="Keep_me_signed_in"
                            control={
                              <Checkbox
                                checked={ccKeepMeSignedin}
                                id="chkKeepMeSignedIn"
                                style={{
                                  margin: "5px",
                                }}
                                //disabled={!product.isSelectable}
                                onChange={(e) => {
                                  if (isccPersisted && !isccReauthExpired)
                                    setisPasswordDisabled(!ccKeepMeSignedin);
                                  setccKeepMeSignedin(!ccKeepMeSignedin);
                                }}
                                inputProps={{
                                  "aria-label": t("Res.carClubKeepMeSignedIn"),
                                }}
                              />
                            }
                            label={t("Res.carClubKeepMeSignedIn")}
                            aria-label={t("Res.carClubKeepMeSignedIn")}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={3} container alignContent="center">
                  <Typography
                    display="inline"
                    variant="body2"
                    style={{
                      fontSize: "1.1em",
                      fontWeight: "bold",
                    }}
                  >
                    {t("Res.carClubNotMember")}
                  </Typography>
                  <Button
                    variant="contained"
                    style={{
                      textTransform: "uppercase",
                      backgroundColor: "#337AB7",
                      color: "#ffffff",
                    }}
                    disabled={ccEnrollmentLink === "" ? true : false}
                    target="_blank"
                    href={ccEnrollmentLink}
                    id="carclubregisterhyperlink"
                    aria-describedby={"CarClubLogoImage"}
                  >
                    {t("Res.registerNowText")}
                    <Box component="span" className="visuallyHidden">
                      {t("Footer.OpenNewWindow")}
                    </Box>
                  </Button>
                </Grid>
              </Grid>
              <Grid item style={{ width: "100%", paddingTop: "15px" }}>
                <>
                  <Divider
                    style={{
                      marginBottom: 20,
                      height: "0.8px",
                    }}
                  />
                  <Grid item sm container>
                    <Grid item sm={1} xs={12}>
                      <img alt="Attention" src={orangeEexclamation} />
                    </Grid>
                    <Grid item sm={11} xs={12} id="CCPasswordChangingMsg">
                      <Typography
                        display="inline"
                        variant="body2"
                        tabIndex={0}
                        style={{
                          fontSize: "1.2em",
                          fontWeight: "bold",
                        }}
                      >
                        {t("Res.pINToPasswordFormatPart1")}
                      </Typography>
                      <br/>
                      <Typography
                        display="inline"
                        variant="body2"
                        style={{ fontSize: "1.2em" }}
                      >
                        {t("Res.pINToPasswordFormatPart3")}
                      </Typography>
                      <Link
                        id="carclubresetpwhyperlink"
                        target="_blank"
                        underline="always"
                        href={isCarShareReservation ? csPasswordResetLink : ccPasswordResetLink}
                        display="inline"
                        variant="body2"
                        style={{ fontSize: "1.2em" }}
                      >
                        {t("Res.pINToPasswordFormatPart4")}
                        <Box component="span" className="visuallyHidden">
                          {t("Footer.OpenNewWindow")}
                        </Box>
                      </Link>
                    </Grid>
                  </Grid>
                  <div style={{ float: "right" }}>
                    <Button
                      variant="contained"
                      style={{ textTransform: "uppercase" }}
                      onClick={() => {
                        if (
                          !ccKeepMeSignedin ||
                          isccReauthExpired ||
                          !isccPersisted ||
                          isReauth
                        )
                          AuthenticateCC();
                        else ReAuthenticateCC();
                      }}
                      // onFocus={() => {
                      //   buttonFocusedAndClickedReadout("Car Club");
                      // }}
                      disabled={false}
                      color="primary"
                      id="carclubselecthyperlink"
                    >
                      {t("Continue")}
                    </Button>
                  </div>
                </>
              </Grid>
            </Grid>
          </Paper>
        </div>
      </Grid>
      <Grid item xs={12} md={3}></Grid>
    </Grid>
  );
};

export default CarClubHome;
