import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ReservationObject } from "components/Reservation/steps/Reservation/reservationFormType";
interface DailyRentalState {
  hasError: boolean;
  reservation?: ReservationObject | null;
  errors: string[];
}

const initialState: DailyRentalState = {
  hasError: false,
  reservation: undefined,
  errors: [],
};

export const dailyRentalSlice = createSlice({
  name: "dailyRental",
  initialState,
  reducers: {
    updateReservation: (_, action: PayloadAction<DailyRentalState>) => ({
      reservation: action.payload.reservation,
      hasError: action.payload.hasError,
      errors: action.payload.errors,
    }),

    resetReservation: () => initialState,
  },
});

export default dailyRentalSlice.reducer;
