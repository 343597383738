import React from "react";
import { Grid, Typography } from "@mui/material";
import CarClubDriverTable from "../carClubDriverTable";
import { useTranslation } from "react-i18next";
import { CarClubDriverProps } from "../carClubDriverType";
import { authService } from "services/authService";
import AdditionalDrivers from "components/shared/AdditionalDrivers/AdditionalDrivers";
import { useCarClubJourneyEndDate } from "components/store/reducers/reservation/carclubSelector";
import { CheckSharp } from "@mui/icons-material";

export const CarClubDriverMatch: React.FC<CarClubDriverProps> = ({
  handleSelectDriver,
  selectedDriver,
  isDriverValidityActivated,
  carClubDrivers,
  onDataChange,
}) => {
  const { t } = useTranslation();
  const driverInfo = authService.getDriverInfo();
  const journeyEndDate = useCarClubJourneyEndDate();
  return (
    <Grid data-testid="carClubDriverMatch" container id="MemberIdMatchedPrimary">
      {isDriverValidityActivated && (
        <Grid item xs={12} md={12}>
          <Typography id="memberIdListLabel" variant="h2" tabIndex={0}>
            {t("ReservationWizard.carClubPage.memberIdListLabel")}{" "}
            <CheckSharp
             style={{ color: "green", marginLeft: 20, verticalAlign:"bottom" }}
            />
            {
              <span
                style={{
                  color: "green",
                  marginLeft: "5px",
                }}
              >
                {t("journeyInput.validOption1")}
              </span>
            }
          </Typography>
          <br />
        </Grid>
      )}
      <CarClubDriverTable
        handleSelectDriver={handleSelectDriver}
        selectedDriver={selectedDriver}
        carClubDrivers={carClubDrivers}
        onDataChange={onDataChange}
      ></CarClubDriverTable>
      {isDriverValidityActivated && (
        <AdditionalDrivers
          primaryDriverDeclarationId={
            driverInfo?.primaryDriver?.driverDeclarationId ?? 0
          }
          reservationEndDate={journeyEndDate}
          initialDrivers={driverInfo?.additionalDriver}
          onDataChange={onDataChange}
          hideDriverDeclarationId={true}
        />
      )}
    </Grid>
    
  );
};
