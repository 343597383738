import { useEffect, useRef } from "react";
import { SlideItem } from "./models";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

function Slide({
  slideItem,
  isCurrent,
  isNext,
  isPrevious,
  takeFocus,
  seq,
}: {
  slideItem: SlideItem;
  isCurrent: boolean;
  isNext: boolean;
  isPrevious: boolean;
  takeFocus: boolean;
  seq:number;
}) {
  let ref: any = useRef();
  const { slideImage, href } = slideItem;
  useEffect(() => {
    if (isCurrent && takeFocus) {
      document.getElementById ("slideItems_"+seq)?.focus();
    }
  }, [isCurrent, takeFocus]);

  const slideClassNames = classNames({
    Previous: isPrevious,
    Next: isNext,
    Current: isCurrent,
    Slide,
  });
  const { t } = useTranslation();

  return href === undefined ? (
    <div
      ref={ref}                   
      aria-hidden={!isCurrent}
      // tabIndex={0}
      aria-label={slideItem.title}
      className={slideClassNames}
    >
      <img src={slideImage.src} alt={slideImage.alt} />
    </div>
  ) : (
   <div>
  <p className="visuallyHidden" id={"opensNewWindowMessageId"+seq} aria-hidden = {true}>{t("opensNewWindow")}</p>
    <a
      href={href?href:"javascript:void(0)"}
      ref={ref}
      aria-hidden={!isCurrent}
      tabIndex={isCurrent?0:-1}
      // tabIndex={0}
      //aria-label={slideItem.title}
      target={href?"_blank": "_self"}
      className={slideClassNames}
      aria-describedby={"opensNewWindowMessageId"+seq}
    >
      <img src={slideImage.src} alt={slideImage.alt} />
    </a>
    </div>
  );
}

export default Slide;
