import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import { Formik, Form, Field } from "formik";
import { useTranslation } from "react-i18next";
import { companyProfileType } from "./companiesType";
import {
  Box,
  FormControl,
  Backdrop,
  Button,
  Grid,
  Typography,
  Select,
  CircularProgress,
  TextField,
} from "@mui/material";
import axiosInstance from "../../../helpers/axiosInstance";
import * as Yup from "yup";
import {
  showSuccessAlertMessage,
  showErrorAlertMessage,
  clearAlertMessage,
} from "../../store/actions/shared/alertActions";
import { broadcastAnnouncement } from "../../store/actions/shared/announcementActions";
import { useDispatch } from "react-redux";
import { appInsights } from "helpers/appInsights";
import { SeverityLevel } from "@microsoft/applicationinsights-common";

const useStyles = makeStyles({
  root: {
    "& .MuiFormHelperText-root": {
      color: "#DF0000",
      fontSize: 12,
      marginLeft: "15px",
    },
    "& ::placeholder": {
      fontStyle: "italic",
      color: "#000",
      opacity: 0.95,
      whiteSpace:"pre-line", 
      position:"relative",
    },
    "& .MuiSelect-outlined.MuiSelect-outlined" : {
      paddingRight: 14,
    }
  },
  outlinedButton: {
    border: "1px solid rgba(0, 102, 57, 0.95);",
  },
  inputRoot: {
    fontSize: 30,
  },
  labelRoot: {
    fontSize: 30,
    color: "red",
    "&$labelFocused": {
      color: "purple",
    },
  },
  errorWarning: {
    color: "#DF0000",
    fontSize: 12,
    marginLeft: "15px",
  },
  backdrop: {
    zIndex: 99999,
    color: "#fff",
  },

  labelFocused: {},
});

const InitialValue: companyProfileType = {
  companyName: "",
  reasonForHireLabel: "",
  isDeleted: false,
  isNew: true,
  countries: {
    selectedValue: "",
    selectedText: "",
    items: [
      {
        disabled: false,
        group: null,
        selected: false,
        text: "",
        value: "",
      },
    ],
  },
  boboEnabled:false,
  isBOBOToggleEnabled:false,
};
export default function AddProfile(props) {
  const [loading, setLoading] = React.useState(false);
  const [
    companyProfile,
    setcompanyprofile,
  ] = React.useState<companyProfileType>(InitialValue);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const compNameRef = React.useRef<HTMLInputElement>(null);
  const titleRef = React.useRef<HTMLDivElement>(null);
  const boboOptions = [
    { id: true, text: "Yes" },
    { id: false, text: "No" }
  ];

  React.useEffect(() => {
    //dispatch(clearAlertMessage());
    GetNewCompany();
  }, [props]);

  const GetNewCompany = async () => {
    setLoading(true);
    await axiosInstance
      .get("/company/getNewCompany")
      .then((response) => {
        const newcompanyProfile = {
          companyName: "",
          reasonForHireLabel: "",
          isDeleted: false,
          isNew: true,
          countries: response.data.countries,
          isBOBOToggleEnabled:response.data.isBOBOToggleEnabled,
          isAllowAdminToAccessAllJourneys:response.data.isAllowAdminToAccessAllJourneys,
          isAllowAdminToAccessAllJourneysToggleEnabled:response.data.isAllowAdminToAccessAllJourneysToggleEnabled,
        };
        setcompanyprofile(newcompanyProfile);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        if (err.response && err.response.data.message) {
          const errorlist = err.response.data.message;
          dispatch(showErrorAlertMessage(errorlist));
          dispatch(broadcastAnnouncement(errorlist));
        } else {
          dispatch(
            showErrorAlertMessage(
              "An error has occured, please contact support team"
            )
          );
          dispatch(
            broadcastAnnouncement(
              "An error has occured, please contact support team"
            )
          );
        }
      });
  };

  function validateSelectField(value) {
    if (
      typeof value["selectedValue"] === "undefined" ||
      value["selectedValue"] === "" ||
      value["selectedValue"] === null
    )
      return false;
    else return true;
  }

  const profileValidationSchema = Yup.object().shape({
    companyName: Yup.string()
      .required(t("Companies.CompanyNameRequiredError"))
      .nullable()
      .max(60, t("Companies.CompanyNameLengthError")),
    reasonLabel: Yup.string()
      .notRequired()
      .nullable()
      .max(120, t("Companies.ReasonForHireLengthError")),
    countries: Yup.object()
      .test("len", t("Companies.CountryRequiredError"), (value) =>
        validateSelectField(value)
      )
      .required(),
      boboEnabled: Yup.boolean()
      .notRequired(),
  });

  return (
    <Box id="addProfileBox" boxShadow={3} p={3} bgcolor="background.paper">
      <Formik
        initialValues={loading ? InitialValue : companyProfile}
        validateOnChange={true}
        validateOnBlur={false}
        enableReinitialize={true}
        validationSchema={profileValidationSchema}
        onSubmit={async (values, { setFieldTouched, setSubmitting }) => {
          dispatch(clearAlertMessage());
          await axiosInstance
            .post("/company/createProfile", values)
            .then((response) => {
              setSubmitting(false);
              if (response.data.success) {
                appInsights.trackEvent({
                  name: "Add Company", properties: { companyUId : response.data.companyUId }
                });
                dispatch(
                  showSuccessAlertMessage(
                    t("Companies.CreateCompanyProfileSuccess")
                  )
                );
                dispatch(
                  broadcastAnnouncement(
                    t("Companies.CreateCompanyProfileSuccess")
                  )
                );
                setcompanyprofile(InitialValue);                
                if (titleRef.current !== null) titleRef.current.focus();
                props.action(response.data.companyUId);
              }
            })
            .catch((err) => {
              setSubmitting(false);
              appInsights.trackException({id: "Add CompanyProfile", exception: err, severityLevel: SeverityLevel.Error});
              if (err.response && err.response.data.messages) {
                const errorlist = err.response.data.messages.filter(
                  (n) => n !== ""
                );
                dispatch(showErrorAlertMessage(errorlist));
                dispatch(broadcastAnnouncement(errorlist.join(",")));
                setTimeout(() => {
                  if (err.response.data.messages.indexOf("company name")) {
                    if (compNameRef.current !== null) compNameRef.current.focus();
                  }
                }, 1000);    
              } else {
                dispatch(
                  showErrorAlertMessage("Unable to create the company.")
                );
                dispatch(
                  broadcastAnnouncement("Unable to create the company.")
                );
                if (titleRef.current !== null) titleRef.current.focus();
              } 
            });
        }}
      >
        {({
          values,
          isValid,
          dirty,
          setFieldValue,
          setFieldTouched,
          touched,
          handleChange,
          errors,
          isSubmitting,
        }) => (
          <Grid container>
            <Backdrop
              className={classes.backdrop}
              open={isSubmitting || loading}
            >
              <CircularProgress />
            </Backdrop>
            <Grid item xs={12} md={6}>
              <div
                id="addCompanyProfileHeading"
                tabIndex={-1}
                className="visuallyHidden"
              >
                {t("Companies.CompanyProfile")}
              </div>
              <Typography variant="h1" id="AddProfileTitle">
                {t("Companies.CompanyProfile")}
              </Typography>
            </Grid>

            <Grid
              container
              direction="row"
              style={{ marginTop: "1em" }}
              className={classes.root}
            >
              <Form style={{ width: "100%" }}>
                <Grid container>
                  <Grid
                    style={{
                      paddingBottom: 0,
                      paddingTop: 10,
                      marginBottom: "1em",
                    }}
                    item
                    xs={12}
                  >
                    <div ref={titleRef} tabIndex={-1} className="visuallyHidden">
                {t("Companies.Company")}
              </div>
                    <Typography style={{ fontWeight: "bold" }}>
                      {t("Companies.Company")}
                    </Typography>
                    <Field
                      name="companyName"
                      id="companyName"
                      inputRef={compNameRef}
                      fullWidth
                      style={{ paddingBottom: 0, paddingTop: 0 }}
                      inputProps={{
                        "aria-label": t("Companies.Company"),
                        "aria-required": true,
                        "data-validation" : "client-validation-error"
                      }}
                      value={values.companyName}
                      onChange= {(e) => {
                        setFieldValue("companyName", e.target.value);
                        setFieldTouched("companyName");
                      }}
                      component={TextField}
                      variant="outlined"
                      placeholder={t("Companies.CompanyPlaceholder")}
                      error={errors.companyName !== undefined ? true : false}
                      helperText={errors.companyName !== undefined  ? errors?.companyName : " "}
                    />
                  </Grid>
                  <Grid
                    style={{
                      paddingBottom: 0,
                      paddingTop: 10,
                      marginBottom: "1em",
                    }}
                    item
                    xs={12}
                  >
                    <Typography style={{ fontWeight: "bold" }}>
                      {t("Companies.ReasonForHireLabel")}
                    </Typography>
                    <Field
                      name="reasonForHireLabel"
                      id="reasonLabel"
                      fullWidth
                      style={{ paddingBottom: 0, paddingTop: 0 }}
                      inputProps={{
                        "aria-label": t("Companies.ReasonForHireLabel"),
                        "data-validation" : "client-validation-error"
                      }}
                      value={values.reasonForHireLabel}
                      onChange= {(e) => {
                        setFieldValue("reasonForHireLabel", e.target.value);
                        setFieldTouched("reasonForHireLabel");
                      }}
                      component={TextField}
                      variant="outlined"
                      // placeholder={t("Companies.ReasonForHireLabelPlaceholder")}
                      error={
                        errors.reasonForHireLabel !== undefined ? true : false
                      }
                      helperText={errors.reasonForHireLabel !== undefined  ? errors?.reasonForHireLabel : " "}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    style={{
                      paddingBottom: 0,
                      paddingTop: 10,
                      marginBottom: "1em",
                    }}
                  >
                    <FormControl id="countryList" variant="outlined" fullWidth>
                      <Typography style={{ fontWeight: "bold" }}>
                        {t("Companies.Country")}
                      </Typography>
                      <Select
                        native
                        value={values.countries?.selectedValue}
                        onChange={(opt) => {
                          let text = values.countries?.items?.find(
                            (x) => x.value === opt.target.value
                          )?.text;
                          setFieldValue("countries.selectedText", text);
                          setFieldValue(
                            "countries.selectedValue",
                            opt.target.value
                          );
                          setFieldTouched("countries");
                        }}
                        fullWidth
                        inputProps={{
                          id: "countries.selectedValue",
                          name: "countries",
                          title: "select a country",
                          "aria-label": t("Companies.Country"),
                          "data-validation" : "client-validation-error"
                        }}
                        name="countries"
                        error={errors.countries !== undefined ? true : false}
                      >
                        {values.countries?.items?.map((opt: any) => (
                          <option key={opt.value} value={opt.value}>
                            {opt.text}
                          </option>
                        ))}
                      </Select>
                      {errors.countries && touched.countries ? (
                        <span className={classes.errorWarning}>
                          {errors.countries}
                        </span>
                      ) : (
                        ""
                      )}
                    </FormControl>
                  </Grid>
                {values.isBOBOToggleEnabled && 
                  <Grid
                  item
                  xs={12}
                  style={{
                    paddingBottom: 0,
                    paddingTop: 10,
                    marginBottom: "1em",
                  }}
                  >
                    <FormControl id="boboLst" variant="outlined" fullWidth>
                      <Typography style={{ fontWeight: "bold" }}>
                        {t("Companies.Bobo")}
                      </Typography>
                      <Select
                        native
                        value={values.boboEnabled ?? false}
                        onChange={(opt) => {
                          setFieldValue("boboEnabled", opt.target.value === 'true');
                          setFieldTouched("boboEnabled");
                        }}
                        fullWidth
                        inputProps={{
                          id: "boboNewProfile.selectedValue",
                          name: "boboEnabled",
                          title: "select Book On Behalf",
                          "aria-label": t("Companies.Bobo"),
                          "data-validation" : "client-validation-error"
                        }}
                        name="boboEnabled"
                      >
                        {boboOptions.map((opt: any) => (
                          <option key={opt.id} value={opt.id}>
                            {opt.text}
                          </option>
                        ))}
                      </Select>
                      {errors.boboEnabled && touched.boboEnabled ? (
                        <span className={classes.errorWarning}>
                          {errors.boboEnabled}
                        </span>
                      ) : (
                        ""
                      )}
                    </FormControl>
                  </Grid>
                }
                  {values.isAllowAdminToAccessAllJourneysToggleEnabled && 
                    <Grid
                      item
                      xs={12}
                      style={{
                        paddingBottom: 0,
                        paddingTop: 10,
                        marginBottom: "1em",
                      }}
                    >
                      <FormControl id="caaarLst" variant="outlined" fullWidth>
                        <Typography style={{ fontWeight: "bold" }}>
                          {t("Companies.AllowAdminToAccessAllJourneys")}
                        </Typography>
                        <Select
                          native
                          value={values.isAllowAdminToAccessAllJourneys ?? false}
                          onChange={(opt) => {
                            setFieldValue("isAllowAdminToAccessAllJourneys", opt.target.value === 'true');
                            setFieldTouched("isAllowAdminToAccessAllJourneys");
                          }}
                          fullWidth
                          inputProps={{
                            id: "isAllowAdminToAccessAllJourneys.selectedValue",
                            name: "isAllowAdminToAccessAllJourneys",
                            title: "Company Admins - Access All Reservations",
                            "aria-label": t("Companies.AllowAdminToAccessAllJourneys"),
                            "data-validation" : "client-validation-error"
                          }}
                          name="isAllowAdminToAccessAllJourneys.selectedValue"
                        >
                          {boboOptions.map((opt: any) => (
                            <option key={opt.id} value={opt.id}>
                              {opt.text}
                            </option>
                          ))}
                        </Select>
                        {errors.isAllowAdminToAccessAllJourneys && touched.isAllowAdminToAccessAllJourneys? (
                          <span className={classes.errorWarning}>
                            {errors.isAllowAdminToAccessAllJourneys}
                          </span>
                        ) : (
                          ""
                        )}
                      </FormControl>
                    </Grid>
                  }
                  <Grid container justifyContent="flex-end">
                    <Button
                      id="btnSubmit"
                      color="primary"
                      variant="contained"
                      disabled={!isValid || !dirty}
                      type="submit"
                      style={{ textTransform: "none" }}
                    >
                      {t("Companies.btnSave")}
                    </Button>
                    <Button
                      classes={{ outlinedPrimary: classes.outlinedButton }}
                      id="btncancel"
                      color="primary"
                      variant="outlined"
                      type="button"
                      onClick={() => {
                        props.action("");
                      }}
                      style={{ marginLeft: "0.5em", textTransform: "none" }}
                    >
                      {t("Companies.btnCancel")}
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            </Grid>
          </Grid>
        )}
      </Formik>
    </Box>
  );
}
