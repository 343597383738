import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import TRANSLATIONS_en_GB from "./translations/en_GB/translation.json";
import TRANSLATIONS_en_CA from "./translations/en_CA/translation.json";
import TRANSLATIONS_en_US from "./translations/en_US/translation.json";
import TRANSLATIONS_de_DE from "./translations/de_DE/translation.json";
import TRANSLATIONS_es_ES from "./translations/es_ES/translation.json";
import TRANSLATIONS_fr_FR from "./translations/fr_FR/translation.json";
import TRANSLATIONS_fr_CA from "./translations/fr_CA/translation.json";
import HOME_en_GB from "./translations/en_GB/home.json";
import HOME_en_CA from "./translations/en_GB/home.json";
import HOME_en_US from "./translations/en_GB/home.json";
import HOME_de_DE from "./translations/de_DE/home.json";
import HOME_es_ES from "./translations/es_ES/home.json";
import HOME_fr_FR from "./translations/fr_FR/home.json";
import HOME_fr_CA from "./translations/fr_CA/home.json";

// https://www.i18next.com/principles/fallback

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(
    {
      resources: {
        "en-GB": {
          translation: TRANSLATIONS_en_GB,
          home: HOME_en_GB,
        },
        "en-CA": {
          translation: TRANSLATIONS_en_CA,
          home: HOME_en_CA,
        },
        "en-US": {
          translation: TRANSLATIONS_en_US,
          home: HOME_en_US,
        },
        "de-DE": {
          translation: TRANSLATIONS_de_DE,
          home: HOME_de_DE,
        },
        "es-ES": {
          translation: TRANSLATIONS_es_ES,
          home: HOME_es_ES,
        },
        "fr-FR": {
          translation: TRANSLATIONS_fr_FR,
          home: HOME_fr_FR,
        },
        "fr-CA": {
          translation: TRANSLATIONS_fr_CA,
          home: HOME_fr_CA,
        },
      },
      fallbackLng: "en-GB",
      supportedLngs: ["en-GB", "en-CA","en-US",  "de-DE", "es-ES", "fr-FR","fr-CA"],      
      interpolation: {
        escapeValue: false,
      },
      debug: false,
    },
    (err, t) => {
      if (err) return console.log("something went wrong loading", err);
    }
  );

export default i18n;
