import { Route, Navigate, RouteProps } from "react-router-dom";
import { NonAuthRoutes } from "./authTypes";
import { authService } from "services/authService";
interface Props {
  children: any;
  path: string;
  exact?: boolean;
  requiredRoles: string[];
}

const AuthRoute = ({ children, requiredRoles }: Props): JSX.Element => {
  const isAuthed = authService.isAuthenticated();
  const userRole = authService.getRole();
  const userHasRequiredRole = requiredRoles.includes(userRole);
  const message = userHasRequiredRole
    ? "Please log in to view this page"
    : "You can't be here!";

  if (isAuthed && userHasRequiredRole) return children;

  // user is not authenticated
  return (
    <Navigate
      to={{
        pathname: userHasRequiredRole
          ? NonAuthRoutes.login
          : NonAuthRoutes.unauthorised,
      }}
    />
  );
};

export default AuthRoute;
