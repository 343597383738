import React, { useState, useEffect, useCallback } from "react";
import {
  DriverDeclaration,
  DriverViewModel,
} from "components/Reservation/steps/Reservation/reservationFormType";
import { Grid, TextField } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useTranslation } from "react-i18next";
import { Field } from "formik";
import DriverDetails from "../DriverDetails/DriverDetails";
import UserAutoComplete from "controls/formik/EtdUserLookup";
import {
  DialingCodes,
  UserDetails,
} from "components/Reservation/steps/JourneyStart/journeyInputType";
import { ReservationMode } from "helpers/useReservationMode";
import { UserTitle } from "components/admin/usertype";

const BOBOSomeone: React.FC<{
  initialDriver: DriverViewModel;
  resType: ReservationMode;
  isConsentCountry?: boolean;
  acceptConsent?: boolean;
  handleConsentChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onDataChange: (userDetails: DriverViewModel, isValid: boolean) => void;
  isPhoneNumberOptional?: boolean;
  disableSearch?: boolean;
}> = ({
  initialDriver,
  resType,
  isConsentCountry,
  acceptConsent,
  onDataChange,
  handleConsentChange,
  isPhoneNumberOptional = false,
  disableSearch = false,
}) => {
  //const resType = "2";
  const driverViewModelInitialValue: DriverViewModel = {
    userTitle: { selectedValue: "", selectedText: "" },
    firstName: "",
    lastName: "",
    emailAddress: "",
    contactPhoneNumber: {
      selectedInternationalDiallingCode: "",
      number: "",
    },
    alternatePhoneNumber: {
      selectedInternationalDiallingCode: "",
      number: "",
    },
    isPrimaryDriver: false,
    savedEnterprisePlusNumber: "",
    savedEmeraldClubNumber: "",
    isConsent: true,
  };
  const userDetailsInitValue: UserDetails = {
    userId: 0,
    username: "",
    firstName: "",
    lastName: "",
    emailAddress: "",
    preferredPhoneCountry: "",
    preferredPhoneNumber: "",
    alternativePhoneCountry: "",
    alternativePhoneNumber: "",
    enterprisePlusNumber: "",
    emeraldClubNumber: "",
    isConsent: true,
  };
  const useStyles = makeStyles((theme) => ({
    gridItem: {
      verticalAlign: "center",
      padding: "10px",
      "& .MuiTypography-body2": {
        fontWeight: "bold",
        fontSize: "0.875rem",
      },
    },
    labelText: {
      color: "#000",
      fontFamily: "Arial, Roboto, Segoe UI",
      fontSize: "0.875rem",
      fontWeight: "bold",
      verticalAlign: "middle",
    },
    grayed: {
      backgroundColor: "#EDEDED",
    },
  }));

  const classes = useStyles();
  const { t } = useTranslation();
  const [driverViewModel, setDriverViewModel] = useState<DriverViewModel>(
    driverViewModelInitialValue
  );
  const [isMobile, setIsMobile] = useState(false);
  const [driverDetails, setDriverDetails] =
    useState<UserDetails>(userDetailsInitValue);
  const [titles, setTitles] = useState(null);

  const onUserDetailsChange = useCallback(
    (driverDetails: DriverViewModel, isValid: boolean) => {
      onDataChange(driverDetails, isValid);
    },
    []
  );

  const handleResize = () => {
    if (window.innerWidth < 960) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };
  const castToDriverViewModel = (
    userDetails: UserDetails,
    dialingCodes: DialingCodes,
    titles: any
  ): DriverViewModel => {
    let result: DriverViewModel = {
      userTitle: { selectedValue: "", selectedText: "", items: titles },
      firstName: userDetails.firstName,
      lastName: userDetails.lastName,
      emailAddress: userDetails.emailAddress,
      contactPhoneNumber: {
        selectedInternationalDiallingCode: userDetails.preferredPhoneCountry,
        number: userDetails.preferredPhoneNumber,
        availableInternationalDiallingCodes: dialingCodes,
      },
      alternatePhoneNumber: {
        selectedInternationalDiallingCode: userDetails.alternativePhoneCountry,
        number: userDetails.alternativePhoneNumber,
        availableInternationalDiallingCodes: dialingCodes,
      },
      isPrimaryDriver: false,
      boboId: userDetails.userId ?? 0,
      savedEnterprisePlusNumber: userDetails.enterprisePlusNumber,
      savedEmeraldClubNumber: userDetails.emeraldClubNumber,
    };
    return result;
  };

  useEffect(() => {
    console.log("initial driver: ", initialDriver);
    setTitles(initialDriver.userTitle.items);
    setDriverViewModel(initialDriver);
    window.addEventListener("resize", handleResize);
    // if(Number(initialDriver?.boboId) > 0 ){

    //   setDriverViewModel(initialDriver);
    // }
    return () => window.removeEventListener("resize", handleResize);
  }, [initialDriver]);

  return (
    <Grid container>
      <Grid container direction="row">
        <Grid item xs={12} md={3} tabIndex={0} className={classes.gridItem}>
          <label htmlFor={"driverSearch"} className={classes.labelText}>
            {t("Users.driverSearch")}
          </label>
        </Grid>
        <Grid item xs={12} md={9} className={classes.gridItem}>
          <Grid style={{ paddingBottom: 0, paddingTop: 0 }} item xs={12}>
            {disableSearch ? (
              <Field
                name="BOBOUser"
                id="boboUserTextbox"
                fullWidth
                style={{ paddingBottom: 0, paddingTop: 0 }}
                className={classes.grayed}
                inputProps={{
                  "aria-label": t("Users.firstname"),
                  "aria-required": "true",
                  "aria-describedby": "firstName",
                }}
                component={TextField}
                variant="outlined"
                disabled={true}
              />
            ) : (
              <Field
                name="BOBOUser"
                id="boboUserTextbox"
                placeholder={t("BOBOUserNameEmail")}
                noMatchText={t("journeyInput.labelNoBOBODrivers")}
                allowClearOnEscape={false}
                defaultValue={driverDetails}
                fullWidth
                isReadOnly={false}
                style={{ paddingBottom: 0, paddingTop: 0 }}
                Label={{ label: "BOBO User" }}
                inputprops={{
                  "aria-label": "BOBO User Details",
                  "aria-required": true,
                  "data-val-required": "Required",
                  "auto-complete": "false",
                }}
                component={UserAutoComplete}
                isMobileView={isMobile}
                setUserDetails={(userDetails, dialingCodes) => {
                  setDriverDetails(userDetails);
                  setDriverViewModel(
                    castToDriverViewModel(userDetails, dialingCodes, titles)
                  );
                }}
                error={false}
              />
            )}
          </Grid>
        </Grid>
        <DriverDetails
          values={driverViewModel}
          resType={resType}
          isConsentCountry={isConsentCountry}
          acceptConsent={acceptConsent}
          onConsentChange={handleConsentChange}
          onDataChange={onUserDetailsChange}
          isBOBOUser={true}
          isPhoneNumberOptional={isPhoneNumberOptional}
        />
      </Grid>
    </Grid>
  );
};

export default BOBOSomeone;
