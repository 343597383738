import React, { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "components/store/reducers/rootReducer";
import { ReservationProps } from "../../reservationType";
import {
  getLocaleCurrency,
  getcompanyCountryCurrency,
} from "helpers/localization";

import { appInsights } from "helpers/appInsights";
import { authService } from "services/authService";
import { SeverityLevel } from "@microsoft/applicationinsights-common";

import {
  Backdrop,
  Typography,
  Button,
  Box,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  TextField,
  FormControl,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import InfoIcon from "@mui/icons-material/Info";
import CarClubLogo from "Assets/Images/CarClubLogo.png";
import CarShareLogo from "Assets/Images/CarShareLogo.png";
import { clearAlertMessage } from "components/store/actions/shared/alertActions";
import { applyAdditionalInformationValidationSchema } from "../AdditionalInformation/additionalInformationForm";
import axiosInstance from "helpers/axiosInstance";
import { useTranslation } from "react-i18next";
import { Formik, Form, Field } from "formik";
import { showErrorAlertMessage } from "components/store/actions/shared/alertActions";
import { red } from "@mui/material/colors";
import { useEffect } from "react";
import {
  additionalInformationFormikFormError,
  additionalInformationFormikFormTouched,
  AdditionalInformationValidationResponse,
} from "../Reservation/reservationFormType";
import { broadcastAnnouncement } from "components/store/actions/shared/announcementActions";
import * as Yup from "yup";
import EtdDatePickerV2 from "controls/formik/EtdDatePickerV2";
import moment from "moment";
import {
  CarClubReservation,
  carClubReservationInitialValue,
} from "../CarClub/carClubFormType";
import { updateCarClubReservation } from "components/store/actions/reservation/carclubActions";
import { getIntlDateFormatForLocale } from "helpers/localization";
import {translateTako} from "helpers/takoTranslateHelper";
import { isHiddenAdditionalParameters } from "helpers/additionalParameters";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
    paddingRight: 30,
    paddingTop: 15,
    paddingBottom: 15,
    "& ::placeholder": {
      color: "#777777",
      opacity: "1",
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#909090",
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "black",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#005423",
    },
    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
      borderColor: "#E91B0C",
    },
    "& .css-suib1l-MuiTableCell-root":{
      borderBottom:"none",
    },
    "& .MuiTableCell-root": {
      verticalAlign: "top",
      borderBottom: "none",
      padding:"10px"
    },
    "& .MuiGrid-root": {
      borderBottom: "none"
    },
  },
  title: {
    fontSize: "1.4em",
    paddingRight: 10,
    paddingTop: 10,
    fontWeight: "bold",
    /* "@media (max-width:480px)": {
      fontSize: "0.7rem",
    }, */
  },
  subtitle: {
    fontSize: "1.2em",
    marginTop: 10,
    marginBottom: 10,
    paddingRight: 10,
    /* "@media (max-width:480px)": {
      fontSize: "0.6rem",
    }, */
  },
  tableHeader: {
    fontWeight: "bold",
    padding: 4,
    borderBottom: "none",
    fontSize: "1.1em",
    whiteSpace: "nowrap",
    verticalAlign: "top",
  },
  tableBody: {
    fontSize: "1.1em",
    padding: 4,
    borderBottom: "none",
    wordBreak: "break-word",
    verticalAlign: "top",
  },
  tableBodyCurrency: {
    fontSize: "1.1em",
    padding: 4,
    borderBottom: "none",
    wordBreak: "break-word",
    textAlign: "right",
    paddingRight: "10px",
    paddingLeft: "10px",
  },
  detail: {
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 15,
  },
  rootcell: {
    "& .MuiTableCell-root": {
      verticalAlign: "top",
      borderBottom: "none",
      padding:"10px"
    },
    "& .MuiGrid-root": {
      borderBottom: "none"
    },
  },
  divider: {
    backgroundColor: "#000",
    height: "0.8px",
    marginRight: 10,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  gridItem: {
    verticalAlign: "center",
    paddingBottom: "8px",
    paddingRight: "10px",
    paddingLeft: "0px",
    "& .MuiTypography-body2": {
      fontWeight: "bold",
    },
  },
  gridItemCurrency: {
    verticalAlign: "center",
    paddingBottom: "8px",
    paddingRight: "10px",
    paddingLeft: "10px",
    "& .MuiTypography-body2": {
      fontWeight: "bold",
    },
    textAlign: "right",
  },
  heading: {
    fontSize: 16,
    fontWeight: "bold",
  },
  labelText: {
    color: "#000",
    fontFamily: "Arial, Roboto, Segoe UI",
    fontSize: 14,
    fontWeight: "bold",
    verticalAlign: "middle",
  },
  errorWarning: {
    color: "#DF0000",
    fontSize: 12,
    marginLeft: "15px",
  },
  dialog: {
    borderRadius: 0,
  },
  button: {
    borderRadius: 0,
    textTransform: "none",
    padding: 5,
  },
  logout: {
    color: "#fff",
    opacity: "1",
    backgroundColor: "#dd0000",
    "&:hover": {
      backgroundColor: "#dd0000",
    },
  },
  countdown: {
    color: red[700],
  },
  dlCustomized: {
    width: "100%",
    overflow: "auto",
    //background: "#ff0",
    paddingTop: "0px",
    paddingBottom: "0px",
    marginTop: "0px",
    marginBottom: "0px",
    "@media screen and (max-width:800px)": {
      display: "grid",
      gridTemplateColumns: "min-content 1fr",
    },
  },
  dtCustomized: {
    float: "left",
    alignments: "left",
    width: "25%",
    paddingTop: "1px",
    paddingBottom: "1px",
    // wordBreak: "break-all",
    // wordWrap: "break-word",
    /* adjust the width; make sure the total of both is 100% */
    //background: "#cc0",
    paddingLeft: "0",
    margin: "0",
  },
  ddCustomized: {
    float: "left",
    width: "75%",
    paddingTop: "1px",
    paddingBottom: "1px",
    alignments: "left",
    /* adjust the width; make sure the total of both is 100% */
    //background: "#dd0",
    paddingLeft: "0",
    margin: "0",
  },
  topScrollPaper: {
    alignItems: "flex-start",
  },
  topPaperScrollBody: {
    verticalAlign: "top",
  },
}));

const formFieldsValidationSchema = Yup.object().shape({
  carClubAvailable: Yup.boolean(), // this is whatever field name i am giving it
});

const useActiveElement = () => {
  const [active, setActive] = React.useState(document.activeElement);

  const handleFocusIn = (e) => {
    if (document.activeElement?.id !== "dialogStartAgainButtonId")
      setActive(document.activeElement);
  };

  React.useEffect(() => {
    document.addEventListener("focusin", handleFocusIn);
    return () => {
      document.removeEventListener("focusin", handleFocusIn);
    };
  }, []);

  return active;
};

const ReviewCarClubForm: React.FC<ReservationProps> = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const ukDate = "DD/MM/YYYY HH:mm";
  const [loading, setLoading] = React.useState(false);
  const { reservation } = useSelector(
    (state: AppState) => state.carclub.carClubRentalReducer
  );
  const [resdata, setResData] = React.useState<CarClubReservation>(
    reservation ?? carClubReservationInitialValue
  );
  const titleRef = React.useRef<HTMLElement>(null);
  const [formValidation, setFormValidation] = React.useState(
    formFieldsValidationSchema
  );
  const formRef = React.useRef<any>(null);
  const [
    additionalInfoValidationResponse,
    setadditionalInfoValidationResponse,
  ] = React.useState<AdditionalInformationValidationResponse | null>(null);
  const [additionalInfoData, setAdditionalInfoData] = React.useState<any>();

  const { companyInfo } = useSelector(
    (state: AppState) => state.companyInfo || {}
  );
  const locale = authService.getUserLocale();
  const localeDateTimeFormat = getIntlDateFormatForLocale(locale);
  const companyCountryCode = authService.getUserCompanyCountryCode();
  const driverInfo = authService.getDriverInfo();

  const takoApiEnv = authService.getTakoApiEnv();
  const isCarShareReservation = String(takoApiEnv).toUpperCase().includes("CS");

  function applyMemoCodeSchema(
    existingformFieldsValidationSchema: any,
    isMemoCodeRequired: boolean
  ) {
    const newSchema1 = existingformFieldsValidationSchema.shape({
      memoCode: Yup.string()
        .required(t("Users.Errors.memoCodeRequired"))
        .max(25, t("Users.Errors.memoCodeMaxLength"))
        .nullable(),
    });
    const newSchema2 = existingformFieldsValidationSchema.shape({
      memoCode: Yup.string()
        .max(25, t("Users.Errors.memoCodeMaxLength"))
        .nullable(),
    });

    return isMemoCodeRequired ? newSchema1 : newSchema2;
  }

  function convertToDate(dt: any) {
    if (dt === null || dt === "") return null;
    if (dt && typeof dt === "string" && dt.includes("-")) {
      let dateParts = dt.split("-");
      return new Date(+dateParts[0], Number(dateParts[1]) - 1, +dateParts[2]);
    }
    return moment(dt).format("YYYY-MM-DD");
  }

  function handleBack() {
    if (props.handleBack) props.handleBack(0, 0);
  }

  function hasMandatoryFields() {
    var hasMandatory = false;
    if (additionalInfoData && reservation?.additionalInformation) {
      additionalInfoData?.additionalInformationFields.forEach((info, index) => {
        if (info.mandatory) hasMandatory = true;
      });
      return hasMandatory || reservation?.isMemoCodeRequired;
    } else {
      return reservation?.isMemoCodeRequired;
    }
  }

  useEffect(() => {
    var landMark = document.getElementById("main");
    landMark?.focus();
  }, [reservation]);

  React.useEffect(() => {
    dispatch(clearAlertMessage());
    console.log("CC Res received: ", reservation);
    console.log("driverInfo: ", driverInfo);

    //create dynamical Yup validation and apply it
    if (reservation) {
      setResData(reservation);
      var addi = JSON.parse(JSON.stringify(reservation.additionalInformation)); //deep copy
      setAdditionalInfoData(addi);

      var newSchema;
      if (reservation.additionalInformation) {
        newSchema = applyAdditionalInformationValidationSchema(
          reservation.additionalInformation?.additionalInformationFields,
          formValidation
        );
        setFormValidation(newSchema);
      }
      const memoSchema = applyMemoCodeSchema(
        newSchema !== undefined ? newSchema : formValidation,
        reservation.isMemoCodeRequired
      );
      setFormValidation(memoSchema);
    }
  }, []);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={resdata}
      validateOnChange={true}
      validateOnBlur={true}
      validationSchema={formValidation}
      innerRef={formRef}
      onSubmit={async (values, { setFieldTouched, setSubmitting }) => {
        console.log("submitted values: ", values);
        setadditionalInfoValidationResponse(null);
        let baseURL = "/carclub/bookreservation";
        if (props.isSharedAccessEnabled) baseURL = "/carclub/bookaareservation";

        setLoading(true);
        values = { ...values, additionalDriver: driverInfo.additionalDriver };
        await axiosInstance
          .post(baseURL, values)
          .then((res) => {
            if (res.data.success) {
              console.log("Responds", res.data.data);
              dispatch(
                updateCarClubReservation({
                  reservation: reservation,
                  reservationViewModel: res.data.data,
                  hasError: false,
                  errors: [],
                })
              );
              setLoading(false);
              if (props.handleNext) props.handleNext(0, 0);
            } else {
              setLoading(false);
              console.log("Errors", res.data.errors);
              dispatch(
                updateCarClubReservation({
                  reservation: reservation,
                  reservationViewModel: null,
                  hasError: true,
                  errors: res.data.errors,
                })
              );
              if (typeof res.data.errors === "string") {
                dispatch(showErrorAlertMessage(translateTako(res.data.errors,t)));
                dispatch(broadcastAnnouncement(translateTako(res.data.errors,t)));
              } else {
                setadditionalInfoValidationResponse(res.data);
                let errorlist: string[] = [];
                if (Object.keys(res.data.errors).length > 0) {
                  for (const [key, value] of Object.entries(res.data.errors)) {
                    if (
                      key === "unexpectedError" &&
                      Object.keys(res.data.errors).length > 1
                    )
                      // remove the exception error if there are more than 1 errors in the list
                      continue;
                    else errorlist.push(value as string);
                    //console.log(value)
                  }
                  dispatch(showErrorAlertMessage(errorlist));
                  dispatch(broadcastAnnouncement(errorlist.join(". ")));
                }
              }
              if (res.data.isTakoAPIError) {
                handleBack();
              }
              window.scrollTo(0, 0);
            }
          })
          .catch((err) => {
            setSubmitting(false);
            setLoading(false);
            console.log("err.response.data", err.response.data);
            setadditionalInfoValidationResponse(err.response.data);
            if (err.response && err.response.data.messages) {
              if (Object.keys(err.response.data.errors).length > 0) {
                for (const [key, value] of Object.entries(
                  err.response.data.errors
                )) {
                }
              }
              let errorlist = err.response.data.messages.filter(
                (n) => n !== ""
              );
              const translatedErrorlist = errorlist
                .map(function (value) {
                  return t(value);
                })
                .join("\r\n");
              dispatch(showErrorAlertMessage(translatedErrorlist));
              dispatch(broadcastAnnouncement(translatedErrorlist));
            } else {
              dispatch(
                showErrorAlertMessage("unable to save the cc journey." + err)
              );
              dispatch(
                broadcastAnnouncement("unable to save the cc journey." + err)
              );
            }

            appInsights.trackException({
              exception: err,
              properties: { method: "onSubmit", Component: "CC Reservation" },
              severityLevel: SeverityLevel.Error,
            });
          });
        window.scrollTo(0, 0);
      }}
    >
      {({
        values,
        isValid,
        dirty,
        setFieldValue,
        setFieldTouched,
        touched,
        handleChange,
        errors,
        isSubmitting,
      }) => (
        <Box data-testid="reviewCarClubForm" boxShadow={3} m={2}>
          <Backdrop className={classes.backdrop} open={loading}>
            <CircularProgress />
          </Backdrop>
          {/* <p> Car club Token expired in :{" "}{(new Date(driverAuthCodeExpiry).getTime() - currentDate.valueOf()) / 1000}
          </p>
          <p>{driverAuthCodeExpiry} </p> */}
          <Grid className={classes.root} container spacing={3}>
            <Grid container direction="row">
              <Grid
                item
                xs={12}
                md={2}
                className={classes.gridItem}
                style={{
                  minWidth: 100,
                  maxWidth: 200,
                  /* paddingLeft: 30, */
                  paddingRight: 0,
                }}
              >
                <Typography>
                  <img
                    id="carClubLogo"
                    style={{
                      display: "block",
                      margin: "0 auto",
                      marginLeft:"30px",
                      maxWidth: "80%",
                      maxHeight: "80%",
                    }}
                    alt="Enterprise Car Club, logo"
                    src={isCarShareReservation ? CarShareLogo : CarClubLogo}
                  />
                </Typography>
              </Grid>
              <Grid item xs={1} md={8} className={classes.gridItem} />
              <Grid
                item
                xs={12}
                md={2}
                className={classes.gridItem}
                style={{
                  minWidth: 100,
                  maxWidth: 200,
                  paddingLeft: 0,
                  /* paddingRight: 30, */
                }}
              >
                <Typography>
                  <img
                    style={{
                      display: "block",
                      margin: "0 auto",
                      maxWidth: "80%",
                      maxHeight: "80%",
                    }}
                    alt=""
                    src={resdata.imageUrl}
                  />
                </Typography>
              </Grid>
            </Grid>
            <Grid id="reviewCarClub" item xs={12}>
              <div style={{ paddingLeft: 20, paddingRight: 0 }}>
                <Grid container direction="row">
                  <Grid item xs={12} md={3} className={classes.gridItem}>
                    <label
                      htmlFor={"MemoCodeTextbox"}
                      className={classes.labelText}
                    >
                      {t("Res.MemoCode").concat(
                        resdata.isMemoCodeRequired ? " *" : ""
                      )}
                    </label>
                    <IconButton
                      id={"MemoCodeToolTip"}
                      size="small"
                      color="primary"
                      style={{
                        marginLeft: 0,
                        paddingBottom: 8,
                      }}
                      title={t("ReservationWizard.carClubPage.memoCodeToolTip")}
                    >
                      <InfoIcon fontSize="small" />
                    </IconButton>
                  </Grid>
                  <Grid item xs={12} md={9} className={classes.gridItem}>
                    <Fragment>
                      <Field
                        name={"memoCode"}
                        id={"MemoCodeTextbox"}
                        value={values.memoCode ?? ""}
                        fullWidth
                        style={{
                          paddingBottom: 0,
                          paddingTop: 0,
                        }}
                        inputProps={
                          resdata.isMemoCodeRequired
                            ? {
                                //"aria-label": "Memo Code",
                                "aria-required": true,
                              }
                            : {}
                        }
                        {...(errors["memoCode"] && {
                          "aria-describedby": "MemoCodeFieldError",
                        })}
                        onChange={(e) => {
                          setFieldValue("memoCode", e.target.value);
                        }}
                        onBlur={(e) => {
                          setFieldTouched("memoCode");
                        }}
                        component={TextField}
                        variant="outlined"
                        error={errors["memoCode"] !== undefined ? true : false}
                      />{" "}
                      {errors["memoCode"] ? (
                        <span
                          id={"MemoCodeFieldError"}
                          role="alert"
                          className={classes.errorWarning}
                        >
                          {errors["memoCode"].toString()}
                        </span>
                      ) : (
                        ""
                      )}
                    </Fragment>
                  </Grid>
                  {additionalInfoData &&
                    additionalInfoData?.additionalInformationFields?.map(
                      (info, index) => {
                        return(!isHiddenAdditionalParameters(info.fieldName) && 
                        <Fragment key={index}>
                          <Grid
                            item
                            xs={12}
                            md={3}
                            className={classes.gridItem}
                          >
                            <label
                              htmlFor={
                                [3, 4, 5].includes(info.fieldTypeCode)
                                  ? "AdditionalInformationField-TextTextbox-" +
                                    index
                                  : [2].includes(info.fieldTypeCode)
                                  ? "AdditionalInformationField-DropDownListComboBox-" +
                                    index
                                  : [1]
                                  ? "AdditionalInformationField-DateTextbox-" +
                                    index
                                  : "AdditionalInformationField-" + index
                              }
                              className={classes.labelText}
                              id={"AdditionalInformationFieldLabelId-" + index}
                            >
                              {info.fieldName.concat(
                                info.mandatory ? " *" : ""
                              )}
                              {"  "}
                            </label>
                            {info.helperText !== null ? (
                              <IconButton
                                id={"additionalInformationIcon" + index}
                                size="small"
                                color="primary"
                                style={{
                                  marginLeft: 0,
                                  paddingBottom: 8,
                                }}
                                title={
                                  info.mandatory
                                    ? info.helperText + " - " + info.fieldName
                                    : info.helperText
                                }
                              >
                                <InfoIcon fontSize="small" />
                              </IconButton>
                            ) : (
                              ""
                            )}
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={9}
                            className={classes.gridItem}
                          >
                            {/* renderAdditionalInformationField(info, index) */}
                            {[3, 4, 5].includes(info.fieldTypeCode) ? (
                              <Fragment>
                                <Field
                                  name={
                                    "AdditionalInformation.AdditionalInformationFields[" +
                                    index +
                                    "].FieldValue"
                                  }
                                  id={
                                    "AdditionalInformationField-TextTextbox-" +
                                    index
                                  }
                                  value={info.fieldValue ?? ""}
                                  fullWidth
                                  style={{
                                    paddingBottom: 0,
                                    paddingTop: 0,
                                  }}
                                  inputProps={
                                    info.mandatory
                                      ? {
                                          "aria-label": info.fieldName,
                                          "aria-required": true,
                                          ...((
                                            errors.additionalInformation as unknown as additionalInformationFormikFormError
                                          )?.additionalInformationFields[
                                            index
                                          ] &&
                                            (
                                              touched.additionalInformation as unknown as additionalInformationFormikFormTouched
                                            )?.additionalInformationFields[
                                              index
                                            ] && {
                                              "aria-describedby":
                                                "AdditionalInformationFieldError" +
                                                index,
                                            }),
                                        }
                                      : {}
                                  }
                                  onChange={(e) => {
                                    info.fieldValue = e.target.value;
                                    setFieldValue(
                                      "additionalInformation",
                                      additionalInfoData
                                    );
                                  }}
                                  onBlur={(e) => {
                                    setFieldTouched(
                                      "additionalInformation.additionalInformationFields[" +
                                        index +
                                        "]"
                                    );
                                  }}
                                  onKeyUp={(e) => {
                                    setFieldTouched(
                                      "additionalInformation.additionalInformationFields[" +
                                        index +
                                        "]"
                                    );
                                  }}
                                  component={TextField}
                                  variant="outlined"
                                  error={
                                    (errors?.["additionalInformation"] !==
                                      undefined &&
                                      (
                                        errors.additionalInformation as unknown as additionalInformationFormikFormError
                                      )?.additionalInformationFields[index] !==
                                        undefined &&
                                      (
                                        touched.additionalInformation as unknown as additionalInformationFormikFormTouched
                                      )?.additionalInformationFields[index]) ||
                                    (additionalInfoValidationResponse !==
                                      null &&
                                      additionalInfoValidationResponse.errors.hasOwnProperty(
                                        info.additionalInformationGid
                                      ))
                                      ? true
                                      : false
                                  }
                                  placeholder={
                                    t(
                                      "AdditionalInformation.pleaseSelectValueFor"
                                    ) + info.fieldName
                                  }
                                />
                                {(
                                  errors.additionalInformation as unknown as additionalInformationFormikFormError
                                )?.additionalInformationFields[index] &&
                                (
                                  touched.additionalInformation as unknown as additionalInformationFormikFormTouched
                                )?.additionalInformationFields[index] ? (
                                  <span
                                    id={
                                      "AdditionalInformationFieldError" + index
                                    }
                                    role="alert"
                                    className={classes.errorWarning}
                                  >
                                    {t(
                                      (
                                        errors.additionalInformation as unknown as additionalInformationFormikFormError
                                      )?.additionalInformationFields[index],
                                      {
                                        fieldName: info.fieldName,
                                        maxLength: info.maxLength
                                          ? info.maxLength
                                          : 100,
                                      }
                                    )}
                                  </span>
                                ) : (
                                  ""
                                )}
                              </Fragment>
                            ) : [2].includes(info.fieldTypeCode) ? (
                              <Fragment>
                                <FormControl variant="outlined" fullWidth>
                                  <Select
                                    name={
                                      "AdditionalInformation.AdditionalInformationFields[" +
                                      index +
                                      "].FieldValue"
                                    }
                                    id={
                                      "AdditionalInformationField-DropDownListComboBox-" +
                                      index
                                    }
                                    native
                                    value={info.fieldValue ?? ""}
                                    onChange={(e) => {
                                      info.dropDownListSelectedValue =
                                        e.target.value;
                                      info.fieldValue = e.target.value;
                                      setFieldValue(
                                        "additionalInformation",
                                        additionalInfoData
                                      );
                                    }}
                                    onBlur={(e) => {
                                      setFieldTouched(
                                        "additionalInformation.additionalInformationFields[" +
                                          index +
                                          "]"
                                      );
                                    }}
                                    inputProps={{
                                      "aria-label": info.fieldName,
                                      ...((
                                        errors.additionalInformation as unknown as additionalInformationFormikFormError
                                      )?.additionalInformationFields[index] &&
                                        (
                                          touched.additionalInformation as unknown as additionalInformationFormikFormTouched
                                        )?.additionalInformationFields[
                                          index
                                        ] && {
                                          "aria-describedby":
                                            "AdditionalInformationFieldError" +
                                            index,
                                        }),
                                    }}
                                    error={
                                      (errors?.["additionalInformation"] !==
                                        undefined &&
                                        (
                                          errors.additionalInformation as unknown as additionalInformationFormikFormError
                                        )?.additionalInformationFields[
                                          index
                                        ] !== undefined &&
                                        (
                                          touched.additionalInformation as unknown as additionalInformationFormikFormTouched
                                        )?.additionalInformationFields[
                                          index
                                        ]) ||
                                      (additionalInfoValidationResponse !==
                                        null &&
                                        additionalInfoValidationResponse.errors.hasOwnProperty(
                                          info.additionalInformationGid
                                        ))
                                        ? true
                                        : false
                                    }
                                  >
                                    <option
                                      key={
                                        "AdditionalInformationField-DropDownListComboBox-" +
                                        index +
                                        "-Emtpy"
                                      }
                                      value=""
                                    >
                                      {t("AdditionalInformation.pleaseSelect")}
                                    </option>
                                    {info.dropDownList?.map(
                                      (opt: any, optIndex) => (
                                        <option
                                          key={
                                            "AdditionalInformationField-DropDownListComboBox-" +
                                            index +
                                            "-" +
                                            optIndex
                                          }
                                          value={opt.value}
                                        >
                                          {opt.text}
                                        </option>
                                      )
                                    )}
                                  </Select>
                                  {(
                                    errors.additionalInformation as unknown as additionalInformationFormikFormError
                                  )?.additionalInformationFields[index] &&
                                  (
                                    touched.additionalInformation as unknown as additionalInformationFormikFormTouched
                                  )?.additionalInformationFields[index] ? (
                                    <span
                                      id={
                                        "AdditionalInformationFieldError" +
                                        index
                                      }
                                      role="alert"
                                      className={classes.errorWarning}
                                    >
                                      {t(
                                        (
                                          errors.additionalInformation as unknown as additionalInformationFormikFormError
                                        )?.additionalInformationFields[index],
                                        { fieldName: info.fieldName }
                                      )}
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </FormControl>
                              </Fragment>
                            ) : [1].includes(info.fieldTypeCode) ? (
                              <Fragment>
                                <Field
                                  component={EtdDatePickerV2}
                                  name={
                                    "AdditionalInformation.AdditionalInformationFields[" +
                                    index +
                                    "].FieldValue"
                                  }
                                  id={
                                    "AdditionalInformationField-DateTextbox-" +
                                    index
                                  }
                                  label={
                                    "AdditionalInformationFieldLabelId-" + index
                                  }
                                  localeDateTimeFormat={localeDateTimeFormat}
                                  isFullWidth={false}
                                  value={
                                    info.fieldValue
                                      ? convertToDate(info.fieldValue)
                                      : null
                                  }
                                  handleChange={(e) => {
                                    const format =
                                      localeDateTimeFormat.toUpperCase();
                                    let parsedDate = moment(e, format, true);

                                    if (parsedDate.isValid()) {
                                      const newValue =
                                        moment(parsedDate).format("YYYY-MM-DD");
                                      if (
                                        moment(parsedDate).isSameOrAfter(
                                          moment().add(-1, "days")
                                        )
                                      ) {
                                        info.fieldValue = newValue;
                                        info.fieldValueDateString =
                                          newValue.replace(/\D/g, "");
                                      } else {
                                        info.fieldValue = null;
                                        info.fieldValueDateString =
                                          "DateInPast";
                                      }
                                    } else {
                                      info.fieldValue = null;
                                      info.fieldValueDateString = e
                                        ? "FormatError"
                                        : "";
                                    }
                                    setFieldValue(
                                      "additionalInformation",
                                      additionalInfoData
                                    );
                                  }}
                                  handleBlur={(e) => {
                                    setFieldTouched(
                                      "additionalInformation.additionalInformationFields[" +
                                        index +
                                        "]"
                                    );
                                  }}
                                  isError={
                                    (errors?.["additionalInformation"] !==
                                      undefined &&
                                      (
                                        errors.additionalInformation as unknown as additionalInformationFormikFormError
                                      )?.additionalInformationFields[index] !==
                                        undefined) ||
                                    (additionalInfoValidationResponse !==
                                      null &&
                                      additionalInfoValidationResponse.errors.hasOwnProperty(
                                        info.additionalInformationGid
                                      ))
                                      ? true
                                      : false
                                  }
                                />
                                {(
                                  errors.additionalInformation as unknown as additionalInformationFormikFormError
                                )?.additionalInformationFields[index] /* &&
                                (
                                  touched.additionalInformation as unknown as additionalInformationFormikFormTouched
                                )?.additionalInformationFields[
                                  index
                                ] */ ? (
                                  <span
                                    id={
                                      "AdditionalInformationFieldError" + index
                                    }
                                    role="alert"
                                    className={classes.errorWarning}
                                  >
                                    {t(
                                      (
                                        errors.additionalInformation as unknown as additionalInformationFormikFormError
                                      )?.additionalInformationFields[index],
                                      { fieldName: info.fieldName }
                                    )}
                                  </span>
                                ) : (
                                  ""
                                )}
                              </Fragment>
                            ) : (
                              ""
                            )}
                          </Grid>
                        </Fragment>
                      )
                      }
                    )}
                </Grid>
                <Divider className={classes.divider} />{" "}
                <Typography
                  align="center"
                  className={classes.title}
                  variant="h1"
                >
                  {t(
                    "ReservationWizard.carClubPage.lblCCReviewReservationPageHeading"
                  )}
                </Typography>
                <Typography align="center" className={classes.subtitle}>
                  {t(
                    "ReservationWizard.carClubPage.lblCCReviewReservationPagePleaseReview"
                  )}
                </Typography>
                <Form>
                  <div style={{ flexGrow: 1 }}>
                    <Grid container direction="row">
                      <Grid container item md={8}>
                        <TableContainer>
                          <Table >
                            <TableBody>
                              <TableRow>
                                <TableCell
                                  width="25%"
                                  className={classes.tableBody}
                                >
                                  <label
                                    htmlFor={"VehicleLocationFieldLabel"}
                                    className={classes.labelText}
                                  >
                                    {t("Res.VehicleLocation").concat(":")}
                                  </label>
                                </TableCell>
                                <TableCell
                                  width="75%"
                                  className={classes.tableBody}
                                >
                                  <Typography id={"VehicleLocationFieldLabel"}>
                                    {resdata.vehicleLocation ?? ""}
                                  </Typography>
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  width="25%"
                                  className={classes.tableBody}
                                >
                                  <label
                                    id="VehicleDetailsFieldLabel"
                                    htmlFor={"VehicleDetailsFieldLabel"}
                                    className={classes.labelText}
                                  >
                                    {t("Res.VehicleDetails").concat(":")}
                                  </label>
                                </TableCell>
                                <TableCell
                                  width="75%"
                                  className={classes.tableBody}
                                >
                                  <Typography id={"vehicleDetailsField"}>
                                    {resdata.vehicleDetails ?? ""}
                                  </Typography>
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  width="25%"
                                  className={classes.tableBody}
                                >
                                  <label
                                    htmlFor={"StartDateFieldLabel"}
                                    className={classes.labelText}
                                  >
                                    {t("JourneyDetails.startDate").concat(":")}
                                  </label>
                                </TableCell>
                                <TableCell
                                  width="75%"
                                  className={classes.tableBody}
                                >
                                  <Typography id={"StartDateFieldLabel"}>
                                    {resdata?.startDateString === null
                                      ? ""
                                      : moment(
                                          resdata?.startDateString,
                                          ukDate
                                        ).format(t("DateTimeFormat"))}
                                  </Typography>
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  width="25%"
                                  className={classes.tableBody}
                                >
                                  <label
                                    htmlFor={"EndDateFieldLabel"}
                                    className={classes.labelText}
                                  >
                                    {t("JourneyDetails.endDate").concat(":")}
                                  </label>
                                </TableCell>
                                <TableCell
                                  width="75%"
                                  className={classes.tableBody}
                                >
                                  <Typography id={"EndDateFieldLabel"}>
                                    {resdata?.endDateString === null
                                      ? ""
                                      : moment(
                                          resdata?.endDateString,
                                          ukDate
                                        ).format(t("DateTimeFormat"))}
                                  </Typography>
                                </TableCell>
                              </TableRow>
                              {props.isSharedAccessEnabled && (
                                <>
                                  <TableRow>
                                    <TableCell
                                      width="25%"
                                      className={classes.tableBody}
                                    >
                                      <label
                                        htmlFor={"BookerFieldLabel"}
                                        className={classes.labelText}
                                      >
                                        {t("Res.booker").concat(":")}
                                      </label>
                                    </TableCell>
                                    <TableCell
                                      width="75%"
                                      className={classes.tableBody}
                                    >
                                      <Typography id={"BookerFieldLabel"}>
                                        {resdata?.bookerName}
                                      </Typography>
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell
                                      width="25%"
                                      className={classes.tableBody}
                                    >
                                      <label
                                        htmlFor={"DriverFieldLabel"}
                                        className={classes.labelText}
                                      >
                                        {t("Res.Driver").concat(":")}
                                      </label>
                                    </TableCell>
                                    <TableCell
                                      width="75%"
                                      className={classes.tableBody}
                                    >
                                      <Typography id={"DriverFieldLabel"}>
                                        {resdata.firstName +
                                          " " +
                                          resdata.lastName}
                                      </Typography>
                                    </TableCell>
                                  </TableRow>
                                </>
                              )}
                              {companyInfo?.isDriverValidityActivated &&
                                driverInfo?.additionalDriver?.length > 0 && (
                                  <TableRow>
                                    <TableCell
                                      width="25%"
                                      className={classes.tableHeader}
                                    >
                                      <label
                                        htmlFor={"AdditionDriversFieldLabel"}
                                        className={classes.labelText}
                                      >
                                        {t("AdditionalDriver").concat(":")}
                                      </label>
                                    </TableCell>

                                    {/* <TableCell width="25%" className={classes.tableBody}>
                      {t("AdditionalDriver")}
                    </TableCell> */}
                                    <TableCell
                                      width="75%"
                                      className={classes.tableBody}
                                    >
                                      <Typography
                                        id={"AdditionDriversFieldLabel"}
                                      >
                                        {/* {resdata?.bookerName} */}
                                        {driverInfo.additionalDriver?.map(
                                          (additionalDriver, index) => {
                                            return (
                                              <Typography key={index}>
                                                {" "}
                                                {additionalDriver.firstName +
                                                  " " +
                                                  additionalDriver.lastName}
                                                <br />{" "}
                                              </Typography>
                                            );
                                          }
                                        )}
                                      </Typography>
                                    </TableCell>

                                    {/* <TableCell id="AdditionalDrivers" className={classes.tableBody0}>
                    {reservation?.additionalDriver?.map(
                      (additionalDriver, index) => {
                        return (
                          <Typography key={index}>
                            {" "}
                            {additionalDriver.firstName +" "+additionalDriver.lastName}
                            <br />{" "}
                          </Typography>
                        );
                      })}
                  </TableCell> */}
                                  </TableRow>
                                )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                      {companyInfo?.isDriverValidityActivated && (
                        <>
                          <Grid container item md={4}>
                            <TableContainer>
                              <Table>
                                <TableBody>
                                  <TableRow>
                                    <TableCell
                                      width="40%"
                                      className={classes.tableHeader}
                                      component="th"
                                    >
                                      <label htmlFor={"EndDateFieldLabel"}>
                                        {t(
                                          "ReservationWizard.carClubPage.lblHireCost"
                                        )}
                                      </label>
                                    </TableCell>
                                    <TableCell
                                      width="60%"
                                      className={classes.tableBodyCurrency}
                                    >
                                      <Typography id={"HireCost"}>
                                        {getLocaleCurrency(
                                          resdata.reservationEstimate?.timeAmount.toString(),
                                          locale,
                                          getcompanyCountryCurrency(
                                            companyCountryCode
                                          )
                                        )}
                                      </Typography>
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell
                                      width="40%"
                                      className={classes.tableHeader}
                                      component="th"
                                    >
                                      <label htmlFor={"EndDateFieldLabel"}>
                                        {t(
                                          "ReservationWizard.carClubPage.lblBBCInsurance"
                                        )}
                                      </label>
                                    </TableCell>
                                    <TableCell
                                      width="60%"
                                      className={classes.tableBodyCurrency}
                                    >
                                      <Typography id={"BBCInsurance"}>
                                        {getLocaleCurrency(
                                          resdata.reservationEstimate?.insurance.toString(),
                                          locale,
                                          getcompanyCountryCurrency(
                                            companyCountryCode
                                          )
                                        )}
                                      </Typography>
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell
                                      width="40%"
                                      className={classes.tableHeader}
                                      component="th"
                                    >
                                      <label htmlFor={"EndDateFieldLabel"}>
                                        {t(
                                          "ReservationWizard.carClubPage.lblTransactionFee"
                                        )}
                                      </label>
                                    </TableCell>
                                    <TableCell
                                      width="60%"
                                      className={classes.tableBodyCurrency}
                                    >
                                      <Typography id={"TransactionFee"}>
                                        {getLocaleCurrency(
                                          resdata.reservationEstimate?.transactionFee.toString(),
                                          locale,
                                          getcompanyCountryCurrency(
                                            companyCountryCode
                                          )
                                        )}
                                      </Typography>
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell
                                      className={classes.tableBody}
                                      colSpan={2}
                                    >
                                      <Divider className={classes.divider} />
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell
                                      width="40%"
                                      className={classes.tableHeader}
                                      component="th"
                                    >
                                      <label htmlFor={"EndDateFieldLabel"}>
                                        {t(
                                          "ReservationWizard.carClubPage.lblTotalEstimate"
                                        )}
                                      </label>
                                    </TableCell>
                                    <TableCell
                                      width="60%"
                                      className={classes.tableBodyCurrency}
                                    >
                                      <Typography id={"TotalEstimated"}>
                                        <strong>
                                          {getLocaleCurrency(
                                            resdata.reservationEstimate?.ccEstimate.toString(),
                                            locale,
                                            getcompanyCountryCurrency(
                                              companyCountryCode
                                            )
                                          )}
                                        </strong>
                                      </Typography>
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell
                                      className={classes.tableBodyCurrency}
                                      colSpan={2}
                                    >
                                      <p
                                        style={{
                                          fontSize: "0.9em",
                                          color: "#006639",
                                        }}
                                      >
                                        {t("Res.FeeDisclaimer")}
                                      </p>
                                    </TableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </div>
                  <Grid item xs={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginTop: "1em",
                        marginRight: "0.7em",
                      }}
                    >
                      <Button
                        variant="contained"
                        id="BackToAvailabilityLink"
                        onClick={handleBack}
                      >
                        {t("Res.editReservation")}
                      </Button>
                      &nbsp; &nbsp;
                      <Button
                        variant="contained"
                        color="primary"
                        id="BookNowButton"
                        aria-label={t(
                          "ReservationWizard.carClubPage.btnBookNow"
                        )}
                        disabled={
                          !hasMandatoryFields()
                            ? !isValid
                            : !isValid ||
                              !(
                                dirty ||
                                touched.memoCode ||
                                touched.additionalInformation !== undefined
                              )
                        }
                        type="submit"
                      >
                        {t("ReservationWizard.carClubPage.btnBookNow")}
                      </Button>
                    </div>
                  </Grid>
                </Form>
              </div>
            </Grid>
          </Grid>
        </Box>
      )}
    </Formik>
  );
};

export default ReviewCarClubForm;
