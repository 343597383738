import withStyles from "@mui/styles/withStyles";

import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";

import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  gridItem: {
    verticalAlign: "center",
    padding: "10px",
    "& .MuiTypography-body2": {
      fontWeight: "bold",
      fontSize: "0.875rem",
    },
  },
  gridItemEmail: {
    verticalAlign: "center",
    wordBreak: "break-all",
    padding: "10px",
    "& .MuiTypography-body2": {
      fontWeight: "bold",
      fontSize: "0.875rem",
    },
  },
  gridItemWithMargin: {
    verticalAlign: "center",
    padding: "10px",
    margin: "10px auto 10px auto",
    "& .MuiTypography-body2": {
      fontWeight: "bold",
      fontSize: "0.875rem",
    },
  },
  heading: {
    fontSize: "1rem",
    fontWeight: "bold",
  },
  labelText: {
    color: "#000",
    fontFamily: "Arial, Roboto, Segoe UI",
    fontSize: "0.875rem",
    fontWeight: "bold",
    verticalAlign: "middle",
  },
  backdrop: {
    zIndex: 99999,
    color: "#fff",
  },
  divider: {
    margin: "10px",
    backgroundColor: "background.paper",
    height: "0.8px",
    width: "98%",
  },
  errorWarning: {
    color: "#DF0000",
    fontSize: "0.75rem",
    marginLeft: "15px",
  },
  textarea: {
    resize: "both",
  },
  focused: { outline: "none" },
  KeyboardArrow: {
    textAlign: "right",
    paddingTop: "10px",
    cursor: "pointer",
  },
  KeyboardArrowIcon: {
    color: "#006639",
  },
  KeyboardArrowText: {
    textAlign: "left",
    paddingTop: "13px",
    cursor: "pointer",
  },
  colorWarning: {
    color: "#DF0000",
  },
  colorSuccess: {
    color: "#006639",
  },
  activeButtonClass: {
    textTransform: "none",
    fontWeight: "bold",
    width: 400,
    height: 40,
    border: "2px solid #006639",
  },
  inActiveButtonClass: {
    textTransform: "none",
    width: 400,
    height: 40,
  },
  activeButtonClassSm: {
    textTransform: "none",
    fontWeight: "bold",
    width: 200,
    height: 40,
    border: "2px solid #006639",
  },
  inActiveButtonClassSm: {
    textTransform: "none",
    width: 200,
    height: 40,
  },
}));

export const AccordionSummary = withStyles({
  root: {
    //  flexDirection: "column",
    backgroundColor: "#006639",
    fontWeight: "bold",
    color: "#fff",
    border: "2px solid #006639",
    "&.Mui-expanded": {
      minHeight: "48px",
    },
    "&.Mui-focused": {
      backgroundColor: "#006639",
    },
  },

  content: {
    marginBottom: 0,
    marginTop: 0,
    padding: 0,
    minHeight: "12px",
  },
  expandIcon: {
    marginRight: 0,
    paddingTop: 0,
    color: "#fff",
  },
})(MuiAccordionSummary);

export const AccordionDetails = withStyles({
  root: {
    "& ::placeholder": {
      color: "#767676",
      opacity: "1",
    },
  },
})(MuiAccordionDetails);
