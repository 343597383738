import {
  CircularProgress,
  Button,
  DialogActions,
  DialogTitle,
  DialogContent,
  Divider,
  Typography,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import React, { useRef } from "react";

import axiosInstance from "../helpers/axiosInstance";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  clearAlertMessage,
  showSuccessAlertMessage,
  showErrorAlertMessage,
} from "components/store/actions/shared/alertActions";
import { useDispatch } from "react-redux";
import { broadcastAnnouncement } from "components/store/actions/shared/announcementActions";
import { appInsights } from "helpers/appInsights";
import { authService } from "services/authService";
import Alert from '@mui/material/Alert';

interface cancellationStatus {
  reAuthenticated: boolean;
  isCancelled: boolean;
  hasError: boolean;
  responseMessage: string;
  hasException: boolean;
  exceptionMessage: string;
}

const useStyles = makeStyles({
  root: {
    "& .MuiFormHelperText-root": {
      fontSize: 12,
      marginLeft: "15px",
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#909090",
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "black",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#005423",
    },
    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
      borderColor: "#E91B0C",
    },
    "& .MuiFormLabel-root": {
      color: "#757575",
      fontSize: "1.2em",
    },
  },
  outlinedButton: {
    border: "1px solid rgba(0, 102, 57, 0.95);",
  },
});

export const getUserCCId = () => {
  return authService.getUserCarClubId();
};

const delay = (ms) => new Promise((res) => setTimeout(res, ms));

export const ProcessCarClubAction = async (
  endpoint,
  reservationReferenceVm,
  reference,
  dispatch,
  t
) => {
  let result: cancellationStatus = {
    reAuthenticated: true,
    isCancelled: false,
    hasError: false,
    responseMessage: "",
    hasException: false,
    exceptionMessage: "",
  };
  await axiosInstance
    .post(endpoint, reservationReferenceVm) // can pass on the header here
    .then(
      async (response) => {
        if (response.data.success === true) {
          result.isCancelled = true;
          result.responseMessage = response.data.message;

          appInsights.trackEvent({
            name: "Cancelled CarClub",
            properties: { Reference: reference },
          });
        } else {
          result.hasError = true;
          result.responseMessage = response.data.message?.trim();
        }
      } //cancel  res
    )
    .catch(async (error) => {
      result.hasException = true;
      result.exceptionMessage = t("CustomErrorGenericErrorMsg");
    });

  return new Promise((resolve) => resolve(result));
};

const CarClubYesNoDialog = (props) => {
  const { t } = useTranslation();
  const yesButtonRef = useRef<HTMLButtonElement>(null);
  const classes = useStyles();
  let history = useNavigate();

  const dispatch = useDispatch();

  const ReservationReferenceViewModel = {
    JourneyUId: props.journeyUId,
    ReferenceNumber: props.reference,
    DriverId: getUserCCId(),
  };

  var actionEndPoint;
  switch (props.dialogType) {
    case "cancel":
      actionEndPoint = "carclub/cancel";
      break;
  }

  React.useEffect(() => {
    if (yesButtonRef.current != null && !props.disableAutoFocus ) yesButtonRef.current.focus();
  }, []);

  const processCCAction = async () => {
    props.action();
    props.handleLoading(true);
    let result = (await ProcessCarClubAction(
      actionEndPoint,
      ReservationReferenceViewModel,
      props.reference,
      dispatch,
      t
    )) as cancellationStatus;

    if (result.isCancelled) {
      dispatch(clearAlertMessage());

      await delay(500);
      dispatch(
        showSuccessAlertMessage(
          t("CancelCarClubReservationSuccessMessageFormat", {
            Reference: result.responseMessage,
          })
        )
      );
      dispatch(
        broadcastAnnouncement(
          t("CancelCarClubReservationSuccessMessageFormat", {
            Reference: result.responseMessage,
          })
        )
      );
      props.handleCancelJourneySuccessful();
      props.handleLoading(false);
      props.action();
      window.scrollTo(0, 0);
    } else if (result.hasError) {
      dispatch(clearAlertMessage());
      props.handleLoading(false);
      props.action();
      await delay(500);
      if (
        result.responseMessage === "ERR20040" ||
        result.responseMessage === "ERR20039" ||
        result.responseMessage === "WSUser"
      ) {
        dispatch(showErrorAlertMessage(t(result.responseMessage)));
        dispatch(broadcastAnnouncement(t(result.responseMessage)));
      } else {
        dispatch(showErrorAlertMessage(result.responseMessage));
        dispatch(broadcastAnnouncement(result.responseMessage));
      }
      window.scrollTo(0, 0);
    }

    if (result.hasException) {
      props.handleLoading(false);
      props.action();
      dispatch(clearAlertMessage());
      if (result.exceptionMessage.length > 0) {
        await delay(500);
        dispatch(showErrorAlertMessage(t("CustomErrorGenericErrorMsg")));
        dispatch(broadcastAnnouncement(t("CustomErrorGenericErrorMsg")));
        window.scrollTo(0, 0);
      }
    }
  };

  return (
    <>
      <DialogTitle data-testid="carClubYesNoDialog" id="yesno-dialog-title">
        <div>
          <Typography variant="h2"> {props.confirmMessage} </Typography>
        </div>
      </DialogTitle>
      <Divider
        style={{
          marginLeft: 7,
          marginRight: 7,
          backgroundColor: "black",
          height: "0.8px",
        }}
      />
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          id="yesbutton"
          ref={yesButtonRef}
          onClick={async () => {
            await processCCAction();
          }}
        >
          {t("Yes")}
        </Button>
        <Button
          variant="outlined"
          id="nobutton"
          onClick={props.action}
          color="primary"
        >
          {t("No")}
        </Button>
      </DialogActions>
    </>
  );
};
export default CarClubYesNoDialog;
