import {
  Backdrop,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CancelIcon from "@mui/icons-material/Cancel";
import { broadcastAnnouncement } from "components/store/actions/shared/announcementActions";
import axiosInstance from "helpers/axiosInstance";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import ImageUploading, { ImageType } from "react-images-uploading";
import { useDispatch } from "react-redux";
// import { useNavigate } from "react-router-dom";
import {
  showSuccessAlertMessage,
  showErrorAlertMessage,
  //  showInfoAlertMessage,
  clearAlertMessage,
} from "components/store/actions/shared/alertActions";

import {
  accountManangerContact,
  companyContact,
  companyContentType,
  companyLink,
  companyLogoNew,
  customerServiceContact,
} from "./companyContentType";
import { authService } from "services/authService";
// import linkDeleteIcon from "Assets/Images/LinkDeleteRedCross.png";
// import {isIE} from 'react-device-detect';
// import { debug } from "console";

//import {decode} from 'html-entities';

import { appInsights } from "helpers/appInsights";
import { SeverityLevel } from "@microsoft/applicationinsights-common";
import ReactQuill, { Quill } from "react-quill";
import 'react-quill/dist/quill.snow.css';

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormHelperText-root": {
      color: "#DF0000",
      fontSize: 12,
      marginLeft: "15px",
    },
    "& ::placeholder": {
      fontStyle: "italic",
      color: "#000",
      opacity: 0.95,
    },
    "& .cke_resizer": {
      borderRightColor: "#000000",
    },
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
  outlinedButton: {
    border: "1px solid rgba(0, 102, 57, 0.95);",
  },
  inputRoot: {
    fontSize: 30,
  },
  labelRoot: {
    fontSize: 30,
    color: "red",
    "&$labelFocused": {
      color: "purple",
    },
  },
  errorWarning: {
    color: "#DF0000",
    fontSize: 12,
    marginLeft: "15px",
  },
  backdrop: {
    zIndex: 99999,
    color: "#fff",
  },

  labelFocused: {},

  hover: {
    color: "#ed1212",
    cursor: "pointer",
  },
}));

const initAccountManangerContact: accountManangerContact = {
  fullName: "",
  emailAddress: "",
  telephone: "",
  companyUId: "",
  companyProfileContactUId: "",
  companyContactTypeUId: "",
  isNew: false,
};

const initCompanyContact: companyContact = {
  fullName: "",
  emailAddress: "",
  telephone: "",
  companyUId: "",
  companyProfileContactUId: "",
  companyContactTypeUId: "",
  isNew: false,
};

const initCustomerServiceContact: customerServiceContact = {
  fullName: "",
  emailAddress: "",
  telephone: "",
  companyUId: "",
  companyProfileContactUId: "",
  companyContactTypeUId: "",
  isNew: false,
};

const initiCompanyLogoNew: companyLogoNew = {
  contentLength: 0,
  contentType: "",
  fileName: "",
  fileFormat: "",
  base64LogoRepresentation: "",
};

const initialValue: companyContentType = {
  companyUId: "",
  companyId: 0,
  companyName: "",
  existingCompanyName: "",
  title: "",
  reasonForHireLabel: "",
  marketingMessage: "",
  marketingMessageHeader: "",
  logoFilename: "",
  companyLogoNew: initiCompanyLogoNew,
  isNewLogo: false,
  companyLogoFile: "",
  fileFormat: "",
  removeLogo: false,
  base64LogoRepresentation: "",
  isNew: false,
  isDeleted: false,
  accountManangerContact: initAccountManangerContact,
  companyContact: initCompanyContact,
  customerServiceContact: initCustomerServiceContact,
  companyLinks: [],
};

const toolbarModules = {
  toolbar: [
    ['bold', 'italic', 'underline'],
    [{ 'color': [] }, { 'background': []}, 'clean'], 
    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
    ['link']
  ]
}

export default function Content(props) {
  const { t } = useTranslation();
  const locale = authService.getUserLocale();
  const [loading, setLoading] = React.useState(false);
  const [companyContent, setCompanyContent] =
    React.useState<companyContentType>(initialValue);

  const [copiedCompanyContent, setCopiedCompanyContent] =
    React.useState<companyContentType>(initialValue);

  const classes = useStyles();
  const dispatch = useDispatch();

  const { companyUid } = props;
  const titleRef = React.useRef<HTMLDivElement>(null);
  const [base64LogoRepresentation, setBase64LogoRepresentation] =
    React.useState("");

  // const [isNewLogo,setIsNewLogo]=React.useState(false);
  const [fileFormat, setFileFormat] = React.useState("");
  const [refreshPage, setRefreshPage] = React.useState(false);
  const [logFileName, setLogoFileName] = React.useState("");
  const [removeLogo, setRemoveLogo] = React.useState(false);
  const [isLogoChanged, setIsLogoChanged] = React.useState(false);
  const [accountManagerName, setAccountManagerName] = React.useState("");
  const [companyLogoNew, setCompanyLogoNew] =
    React.useState(initiCompanyLogoNew);

  const [accountManagerEmail, setAccountManagerEmail] = React.useState("");
  const [accountManagerPhone, setAccountManagerPhone] = React.useState("");
  const [companyContactName, setCompanyContactName] = React.useState("");
  const [companyContactEmail, setCompanyContactEmail] = React.useState("");
  const [companyContactPhone, setCompanyContactPhone] = React.useState("");
  const [customerServiceContactName, setCustomerServiceContactName] =
    React.useState("");
  const [customerServiceContactEmail, setCustomerServiceContactEmail] =
    React.useState("");
  const [customerServiceContactPhone, setCustomerServiceContactPhone] =
    React.useState("");
  const [marketingMessageHeader, setMarketingMessageHeader] =
    React.useState("");
  const [marketingMessage, setMarketingMessage] = React.useState("");
  const [companyLinks, setCompanyLinks] = React.useState([] as companyLink[]);
  const btnResetPassDlgRef = useRef<HTMLButtonElement>(null);
  const [conmpanyLinkTableLength, setConmpanyLinkTableLength] =
    React.useState(0);
  const [marketingMessageLength, setMarketingMessageLength] = React.useState(0);

  const [saveButtonStatusTouched, setSaveButtonStatusTouched] =
    React.useState(false);

  const [linkTableRowCount, setLinkTableRowCount] = React.useState(100);

  const addLinkButtonRef = React.useRef<HTMLButtonElement>(null);

  var emailRegExpr = new RegExp(
    /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i
  );

  const [dimensions, setDimensions] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  React.useEffect(() => {
    setSaveButtonStatusTouched(false);
    dispatch(clearAlertMessage());
    setLoading(true);
    setImages([]);
    fetchCompanyContent(companyUid);
    
    if (titleRef.current !== null) titleRef.current.focus();
  }, [props, refreshPage]);

  React.useEffect(() => {
    setNewlyAddedLinkFocused();
  }, [companyLinks.length]);

  React.useEffect(() => {
    function handleResize() {
      var companylogo = document.getElementById(
        "imgCompanyLogo"
      ) as HTMLImageElement;
      if (companylogo) {
        //companylogo.style.maxHeight = "50px"
        setDimensions({
          height: window.innerHeight,
          width: window.innerWidth,
        });
        var ratio = 4.3;
        if (dimensions.width >= 1280) {
          companylogo.style.maxWidth = "215px";
          companylogo.style.maxHeight = "50px";
        } else if (dimensions.width < 320) {
          companylogo.style.maxWidth = "75px";
          companylogo.style.maxHeight = "15px";
        } else {
          companylogo.style.maxWidth =
            75 + Math.round(((dimensions.width - 320) * 140) / 960) + "px";
        }

        //console.log(companylogo.style.maxWidth+"|"+companylogo.style.maxHeight+"=>"+dimensions.height+";"+dimensions.width)
      }
      window.addEventListener("resize", handleResize);
    }
  }, [window.innerWidth]);

  const initImage: ImageType = {
    data_url: "",
    file: undefined,
  };

  const [images, setImages] = React.useState([initImage]);

  const allInputValid = () => {
    if (
      accountManagerName.length > 80 ||
      (accountManagerEmail !== "" &&
        !accountManagerEmail.match(emailRegExpr)) ||
      accountManagerEmail.length > 80 ||
      accountManagerPhone.length > 26 ||
      companyContactName.length > 80 ||
      (companyContactEmail !== "" &&
        !companyContactEmail.match(emailRegExpr)) ||
      companyContactEmail.length > 80 ||
      companyContactPhone.length > 26 ||
      customerServiceContactName.length > 80 ||
      (customerServiceContactEmail !== "" &&
        !customerServiceContactEmail.match(emailRegExpr)) ||
      customerServiceContactEmail.length > 80 ||
      customerServiceContactPhone.length > 26 ||
      marketingMessageHeader.length > 50
    ) {
      return false;
    } else {
      return true;
    }
  };

  const removeCompanyLink = (rowId: string) => {
    let updatedComppanLinks = companyLinks.filter((link) => link.id !== rowId);
    setCompanyLinks(updatedComppanLinks);
    setConmpanyLinkTableLength(updatedComppanLinks.length);
  };

  const isInputValid = () => {
    if (!allInputValid()) {
      setSaveButtonStatusTouched(false);
    } else {
      setSaveButtonStatusTouched(true);
    }
  };

  const getMaxCompanyLinkId = () => {
    var table = document.getElementById("companyLinkTable") as HTMLTableElement;
    var maxCompanyLinkId = 0;
    for (var i = 0; i < table.rows.length; i++) {
      if (Number(table.rows[i].id) > maxCompanyLinkId) {
        maxCompanyLinkId = Number(table.rows[i].id);
      }
    }
    return maxCompanyLinkId;
  };

  const getLinkTableLength = () => {
    var linkTableLength = 0;
    var table = document.getElementById("companyLinkTable") as HTMLTableElement;
    if (table && table.rows) {
      linkTableLength = table.rows.length;
    }

    return linkTableLength;
  };

  // for future use
  const handleCompanyLinkDescOverLength = (e) => {
    var desc_input = e.target;
    var desc = e.target.value;

    console.log(e.target.id + "=>" + desc.length);
    if (desc && desc.length > 10) {
      desc_input.className = "error";
    }
  };

  // for future use
  const handleCompanyLinkURLOverLength = (e) => {
    var url_input = e.target;
    var url = e.target.value;

    console.log(e.target.id + "=>" + url.length);
    if (url && url.length > 10) {
      url_input.className = "error";
    }
  };

  const companyLinkRow = (aLink: companyLink, index: any) => {
    return (
      <tr id={aLink.id + ""} key={index}>
        <td style={{ width: "30%" }}>
          <input
            type="text"
            id={aLink.id + "_companyLink_desc"}
            name={"CompanyLinks[" + aLink.id + "].LinkDescription"}
            //required={true}
            style={{ width: "100%" }}
            onChange={(e) => {
              handleLinkDescChange(e);
              dispatch(broadcastAnnouncement(" "));
              dispatch(clearAlertMessage());
            }}
            //  onKeyUp={(e) => {handleCompanyLinkDescOverLength(e)}}
            defaultValue={aLink.linkDescription}
            placeholder={t("Contents.companyLinkDescPlaceholder")}
            aria-label={t("Contents.companyLinkDescription")}
          />
        </td>
        <td style={{ width: "60%" }}>
          <input
            type="text"
            id={aLink.id + "_companyLink_url"}
            name={"CompanyLinks[" + aLink.id + "].LinkURL"}
            style={{ width: "100%" }}
            //required={true}
            onChange={(e) => {
              handleLinkUrlChange(e);
              dispatch(broadcastAnnouncement(" "));
              dispatch(clearAlertMessage());
            }}
            // onKeyUp={(e) => {handleCompanyLinkURLOverLength(e)}}
            defaultValue={aLink.linkURL}
            placeholder={t("Contents.companyLinkURLPlaceholder")}
            aria-label={t("Contents.companyLinkURL")}
          />
          {/* <TextField
            type="text"
            id={aLink.id + "_companyLink_desc"}
            // maxLength={50}
            required={true}
            style={{ width: "100%" }}
            onChange={(e) => {
              handleLinkDescChange(e);
              dispatch(broadcastAnnouncement(" "));
              dispatch(clearAlertMessage());
            }}
            value={aLink.linkDescription}
            placeholder={t("Contents.companyLinkDescPlaceholder")}
            aria-label={t("Contents.companyLinkDescription")}
            error={aLink.linkDescription.length > 50}
            helperText={
              aLink.linkDescription.length > 50
                ? t("Contents.linkDescWarning")
                : ""
            }
          />
        </td>
        <td style={{ width: "60%" }}>
          <TextField
            type="text"
            id={aLink.id + "_companyLink_url"}
            style={{ width: "100%" }}
            // maxLength={2000}
            required={true}
            onChange={(e) => {
              handleLinkUrlChange(e);
              dispatch(broadcastAnnouncement(" "));
              dispatch(clearAlertMessage());
            }}
            value={aLink.linkURL}
            placeholder={t("Contents.companyLinkURLPlaceholder")}
            aria-label={t("Contents.companyLinkURL")}
            error={aLink.linkURL.length > 2000}
            helperText={
              aLink.linkURL.length > 2000
                ? t("Contents.linkDescWarning")
                : ""
            }
          /> */}
        </td>
        <td style={{ width: "10%" }}>
          <IconButton
            role="img"
            id={"companyLinkDeletionButtonId_" + aLink.id}
            aria-label={t("Delete")}
            color="primary"
            title={t("Contents.deleteLink")}
            size="small"
            style={{
              marginRight: 5,
              border: "0px solid #547D66",
              color: "#d02216",
            }}
            onClick={() => handleDeleteRow(aLink.id + "")}
            // onMouseEnter={() =>  handleDeletionButtonMouseOver()}
          >
            <CancelIcon />
          </IconButton>
        </td>
      </tr>
    );
  };

  const companyLinkTable = (links: companyLink[]) => {
    return (
      <div style={{ width: "100%" }}>
        <table id="companyLinkTable" style={{ width: "100%" }}>
          <tbody>
            {links.map((aLink, index) => companyLinkRow(aLink, index))}
          </tbody>
        </table>
        <table>
          <tbody>
            <tr>
              <td>
                <IconButton
                  id="btnAddCompanyLink"
                  role="button"
                  ref={addLinkButtonRef}
                  aria-label={t("Contents.addACompanyLink")}
                  color="primary"
                  size="small"
                  style={{ border: " 1px solid #006639" }}
                  title={t("Contents.addLink")}
                  disabled={conmpanyLinkTableLength < 5 ? false : true}
                  onClick={() => {
                    handleAddRow();
                    isInputValid();
                    if (addLinkButtonRef.current !== null)
                      addLinkButtonRef.current.focus();
                  }}
                  onMouseEnter={() => handleAddButtonMouseOver()}
                >
                  <AddCircleOutlineIcon />
                </IconButton>
              </td>
              <td>
                <span
                  id="spnCompanyLinksText"
                  style={{
                    marginLeft: "2px",
                    display: conmpanyLinkTableLength !== 0 ? "none" : "block",
                  }}
                >
                  Add a company link for external users, at the bottom of the
                  page
                </span>
                <span
                  id="spnCompanyLinksText_1"
                  style={{
                    marginLeft: "2px",
                    display:
                      conmpanyLinkTableLength > 0 && conmpanyLinkTableLength < 5
                        ? "block"
                        : "none",
                  }}
                >
                  {t("Contents.addAnotherLink")}
                </span>
                <span
                  id="spnMaxReachedText"
                  style={{
                    color: "red",
                    display: conmpanyLinkTableLength < 5 ? "none" : "block",
                  }}
                >
                  {t("Contents.maximumReached")}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  // const HandleNameChange = (newvalue: string) => {
  //   return newvalue;
  // };

  // const HandleEmailChange = (newvalue: string) => {
  //   return newvalue;
  // };

  // const HandlePhoneChange = (newvalue: string) => {
  //   return newvalue;
  // };

  // const handleSubHeadingChange = (newvalue: string) => {
  //   return newvalue;
  // };

  // const handleMarketingMessageChange = (newvalue: string) => {
  //   return newvalue;
  // };

  // const handleLinkDescChange = (
  //   event: React.ChangeEvent<HTMLInputElement>,
  //   alink: companyLink
  // ) => {};

  // const handleLinkURLChange = (
  //   event: React.ChangeEvent<HTMLInputElement>,
  //   aLink: companyLink
  // ) => {};

  // const handleCompanyLinkChange = (
  //   event: React.ChangeEvent<HTMLInputElement>,
  //   Links: companyLink[],
  //   links: companyLink[]
  // ) => {};

  // const handleMarketingMessage = (newvalue: string) => {};

  // const handleMarketingMessageHeader = (newvalue: string) => {};

  // const inputHandlerEditor = (event, editor) => {
  //   setMarketingMessage(editor.getData());
  // };

  // const handleAccountManagerName = (event) => {
  //   companyContent.accountManangerContact.fullName = event.target.value || "";
  // };
  // const inputHandler = (event, editor) => {
  //   //console.log(editor.getData());
  // };

  const handleLinkDescChange = (event) => {
    // setSaveButtonStatusTouched(hasCompanyLinkChanged);
    isInputValid();
  };

  const handleLinkUrlChange = (event) => {
    // setSaveButtonStatusTouched(hasCompanyLinkChanged);
    isInputValid();
  };

  const removeTags = (str: string) => {
    if (!str) return "";
    else return str.replace(/(<([^>]+)>)/gi, "");
  };

  const handleDeletionButtonMouseOver = () => {
    dispatch(broadcastAnnouncement(""));
    setTimeout(() => {
      dispatch(broadcastAnnouncement(t("Contents.deleteCompanyLink")));
    }, 100);
  };

  const handleAddButtonMouseOver = () => {
    dispatch(broadcastAnnouncement(""));

    setTimeout(() => {
      dispatch(broadcastAnnouncement(t("Contents.addCompanyLink")));
    }, 100);
  };

  const handleDeleteRow = (rowId: string) => {
    dispatch(broadcastAnnouncement(" "));

    //removeCompanyLink(rowId);

    //companyLinkTable(companyLinks);

    //var table = document.getElementById("companyLinkTable") as HTMLTableElement;

    var row = document.getElementById(rowId) as HTMLTableRowElement;

    if (row && row.parentElement) {
      row.parentElement?.removeChild(row);

      var input_link_desc = document.getElementById(
        rowId + "_companyLink_desc"
      ) as HTMLInputElement;

      isInputValid();

      setConmpanyLinkTableLength(getLinkTableLength());

      setTimeout(() => {
        dispatch(
          broadcastAnnouncement(
            t("Contents.companyLinkIsDeleted") +
              " (desc=" +
              (input_link_desc && input_link_desc.value.trim()
                ? input_link_desc.value
                : "empty") +
              ")"
          )
        );
      }, 100);
    }
  };

  const setNewlyAddedLinkFocused = () => {
    var desc_input = document.getElementById(
      linkTableRowCount - 1 + "_companyLink_desc"
    ) as HTMLInputElement;
    if (desc_input) {
      desc_input.focus();
    }
  };

  const isConmpanyLinkIdUsed = (companyLinkId: number) => {
    var isUsed = false;
    var table = document.getElementById("companyLinkTable") as HTMLTableElement;
    for (var i = 0; i < table.rows.length; i++) {
      if (Number(table.rows[i].id) === companyLinkId) {
        isUsed = true;
      }
    }
    return isUsed;
  };

  const handleAddRow = () => {
    // var nextAvailableLinkId = Math.max(getMaxCompanyLinkId(),conmpanyLinkTableLength);

    var nextAvailableLinkId = conmpanyLinkTableLength;
    while (isConmpanyLinkIdUsed(nextAvailableLinkId)) {
      nextAvailableLinkId++;
    }

    //setLinkTableRowCount(linkTableRowCount + 1);
    var aLink = {
      linkDescription: "",
      linkURL: "",
      id: nextAvailableLinkId.toString(),
    };

    companyLinks.push(aLink);

    companyLinkTable(companyLinks);
    setConmpanyLinkTableLength(conmpanyLinkTableLength + 1);

    if (conmpanyLinkTableLength === 4) {
      setTimeout(() => {
        dispatch(
          broadcastAnnouncement(
            t("Contents.companyLinkIsAdded") +
              "  (" +
              t("Contents.maximumReached") +
              ")"
          )
        );
      }, 100);
    } else {
      setTimeout(() => {
        dispatch(broadcastAnnouncement(t("Contents.companyLinkIsAdded")));
      }, 100);

      setNewlyAddedLinkFocused();
    }
  };

  const validateCompanyLink = () => {
    var table = document.getElementById("companyLinkTable") as HTMLTableElement;

    let errors = new Set();

    for (var i = 0; i < table.rows.length; i++) {
      var rowId = parseInt(table.rows[i].id.split("_")[0]);
      var input_desc = (
        document.getElementById(rowId + "_companyLink_desc") as HTMLInputElement
      ).value;
      var input_url = (
        document.getElementById(rowId + "_companyLink_url") as HTMLInputElement
      ).value;
      input_desc = input_desc.trim();
      input_url = input_url.trim();

      if (!input_desc) {
        errors.add(t("Contents.cannotSaveDueToMissingCompanyLinkDesc"));
      }

      if (!input_url) {
        errors.add(t("Contents.cannotSaveDueToMissingCompanyLinkURL"));
      }

      if (input_desc && input_desc.length > 50) {
        errors.add(t("Contents.cannotSaveDueToOverLengthCompanyLinkDesc"));
      }

      if (input_url && input_url.length > 2000) {
        errors.add(t("Contents.cannotSaveDueToOverLengthCompanyLinkURL"));
      }
    }

    return Array.from(errors) as string[];
  };

  const hasCompanyLinkChanged = () => {
    var table = document.getElementById("companyLinkTable") as HTMLTableElement;

    if (table.rows.length !== copiedCompanyContent.companyLinks.length)
      return true;

    //var count = 0;
    for (var i = 0; i < table.rows.length; i++) {
      var rowId = parseInt(table.rows[i].id.split("_")[0]);
      var input_desc = (
        document.getElementById(rowId + "_companyLink_desc") as HTMLInputElement
      ).value;
      var input_url = (
        document.getElementById(rowId + "_companyLink_url") as HTMLInputElement
      ).value;
      if (
        copiedCompanyContent.companyLinks &&
        copiedCompanyContent.companyLinks[i] &&
        (input_desc !== copiedCompanyContent.companyLinks[i].linkDescription ||
          input_url !== copiedCompanyContent.companyLinks[i].linkURL)
      )
        return true;
    }

    return false;
  };

  const getUpdatedCompanyLink = () => {
    var table = document.getElementById("companyLinkTable") as HTMLTableElement;

    var aLinks: companyLink[] = [];

    //var count = 0;
    for (var i = 0; i < table.rows.length; i++) {
      var rowId = table.rows[i].id.split("_")[0];
      var input_desc = (
        document.getElementById(rowId + "_companyLink_desc") as HTMLInputElement
      ).value;
      var input_url = (
        document.getElementById(rowId + "_companyLink_url") as HTMLInputElement
      ).value;

      var aLink = {
        linkDescription: input_desc.trim(),
        linkURL: input_url.trim(),
        id: rowId,
      };
      aLinks.push(aLink);
    }

    return aLinks;
  };

  const handleImageChange = (imageList, addUpdateIndex) => {
    isInputValid();

    setIsLogoChanged(true);

    if (imageList.length > 1) {
      imageList.shift();
    }

    if (imageList && imageList.length === 0) {
      setRemoveLogo(true);

      const aCompanyLogoNew: companyLogoNew = {
        contentLength: 0,
        contentType: "",
        fileName: logFileName,
        fileFormat: fileFormat,
        base64LogoRepresentation: base64LogoRepresentation,
      };
      setCompanyLogoNew(aCompanyLogoNew);
    } else {
      // setIsNewLogo(true);
      setRemoveLogo(false);
      var fileLength = imageList[0].file.size;
      var fileType = imageList[0].file.type;
      var fileName = imageList[0].file.name;
      var fileFormat = imageList[0].file.type.split("image/")[1];
      var dataURL = imageList[0].data_url
        ? imageList[0].data_url.split(";base64,")[1]
        : "";

      if (fileLength > 100000) {
        dispatch(showErrorAlertMessage(t("Contents.imageFileSizeWarning")));
        dispatch(broadcastAnnouncement(t("Contents.imageFileSizeWarning")));
        imageList.length = 0;
        return;
      }

      if (
        !["gif", "jfif", "jpeg", "pjp", "pjpeg", "jpg", "png"].includes(
          fileFormat
        )
      ) {
        //errorList.push("Image file type is not type of gif,jpg or png, please selet another file.")
        dispatch(
          showErrorAlertMessage(
            "Image file type is not any kind of gif, jfif, jpeg, pjp, pjpeg, jpg, or png, please selet another file."
          )
        );
        dispatch(
          broadcastAnnouncement(
            "Image file type is not any kind of gif, jfif, jpeg, pjp, pjpeg, jpg, or png, please selet another file."
          )
        );
        imageList.length = 0;
        return;
      }

      const aCompanyLogoNew: companyLogoNew = {
        contentLength: fileLength,
        contentType: fileType,
        fileName: fileName,
        fileFormat: fileFormat,
        base64LogoRepresentation: dataURL,
      };
      setCompanyLogoNew(aCompanyLogoNew);
      setRemoveLogo(false);
    }
    setImages(imageList);
  };

  // const messageForCancelButton = () => {
  //   setRefreshPage(!refreshPage);
  // };

  const fetchCompanyContent = async (companyUId) => {
    setImages([]);
    if (companyUId !== "") {
      await axiosInstance
        .get(`/company/getCompanyContentByUId/${companyUId}`)
        .then((response) => {
          appInsights.trackEvent({
            name: "Fetch Company Content",
            properties: { companyUId: companyUid },
          });
          setCompanyContent(response.data);
          setCopiedCompanyContent(JSON.parse(JSON.stringify(response.data)));
          setBase64LogoRepresentation(response.data.base64LogoRepresentation);
          setFileFormat(response.data.fileFormat);
          setLogoFileName(response.data.logoFilename);
          setAccountManagerName(
            response.data.accountManangerContact.fullName || ""
          );
          setAccountManagerEmail(
            response.data.accountManangerContact.emailAddress || ""
          );
          setAccountManagerPhone(
            response.data.accountManangerContact.telephone || ""
          );

          setCompanyContactName(response.data.companyContact.fullName || "");
          setCompanyContactEmail(
            response.data.companyContact.emailAddress || ""
          );
          setCompanyContactPhone(response.data.companyContact.telephone || "");

          setCustomerServiceContactName(
            response.data.customerServiceContact.fullName || ""
          );
          setCustomerServiceContactEmail(
            response.data.customerServiceContact.emailAddress || ""
          );
          setCustomerServiceContactPhone(
            response.data.customerServiceContact.telephone || ""
          );

          setMarketingMessageHeader(response.data.marketingMessageHeader || "");
          setMarketingMessage(response.data.marketingMessage || "");

          setCompanyLinks(response.data.companyLinks || []);

          setConmpanyLinkTableLength(response.data.companyLinks.length);

          // setIsValidCompanyLink(true);

          setLoading(false);
          var image: ImageType = {
            data_url: response.data.base64LogoRepresentation
              ? `data:image/png;base64,` +
                response.data.base64LogoRepresentation
              : "",
            file: undefined,
          };

          if (image.dataURL) {
            images.length = 0;
          } else {
            setImages([image]);
          }
        })
        .catch((error) => {
          if (
            error.response != null &&
            error.response.status != null &&
            error.response.status === 400
          ) {
            dispatch(showErrorAlertMessage(t("UnexpectedError")));
            dispatch(broadcastAnnouncement(t("UnexpectedError")));
            console.error("There is an error", error);
          } else console.error("There is an error", error);

          appInsights.trackException({
            id: "Fetch Company Content",
            exception: error,
            severityLevel: SeverityLevel.Error,
          });
        });
    } else {
      //doing nothing here
    }
    setLoading(false);
  };

  const uploadCompanyContent = async (values) => {
    setLoading(true);
    await axiosInstance
      .post("/company/updateCompanyContent", values)
      .then((response) => {
        if (response.data.success) {
          dispatch(showSuccessAlertMessage(t("Contents.updateContentSuccess")));
          dispatch(broadcastAnnouncement(t("Contents.updateContentSuccess")));
          appInsights.trackEvent({
            name: "Upload Company Content",
            properties: { companyUId: companyUid },
          });

          if (titleRef.current !== null) titleRef.current.focus();

          isInputValid();
        }
      })
      .catch((err) => {
        console.log(err.response.data);
        if (err.response && err.response.data.messages) {
          dispatch(showErrorAlertMessage("Unable to update the contents."));
          dispatch(broadcastAnnouncement("Unable to update the contents."));

          appInsights.trackException({
            id: "Upload Company Content",
            exception: err.response.data.messages,
            severityLevel: SeverityLevel.Error,
          });
        }
      });

    setLoading(false);
  };

  const handleKeyPressForSaveButton = (e) => {
    if (e.keyCode === 13) {
      handleUpdate();
    }
  };

  // const handleCancel = () => {
  //   props.action("");
  // };

  const isNameOrPhoneInputNameValid = (value: string, maxLength: number) => {
    if (value && value !== "" && value.length > maxLength) {
      setSaveButtonStatusTouched(false);
      return false;
    } else {
      return true;
    }
  };

  const handleUpdate = () => {
    let clonedCompanyContent = companyContent;

    clonedCompanyContent.base64LogoRepresentation = "";
    clonedCompanyContent.fileFormat = "";
    clonedCompanyContent.companyLogoNew = null;
    clonedCompanyContent.companyLogoFile = "";

    clonedCompanyContent.accountManangerContact.fullName =
      accountManagerName || "";
    clonedCompanyContent.accountManangerContact.emailAddress =
      accountManagerEmail || "";
    clonedCompanyContent.accountManangerContact.telephone =
      accountManagerPhone || "";

    clonedCompanyContent.companyContact.fullName = companyContactName || "";
    clonedCompanyContent.companyContact.emailAddress =
      companyContactEmail || "";
    clonedCompanyContent.companyContact.telephone = companyContactPhone || "";

    clonedCompanyContent.customerServiceContact.fullName =
      customerServiceContactName || "";
    clonedCompanyContent.customerServiceContact.emailAddress =
      customerServiceContactEmail || "";
    clonedCompanyContent.customerServiceContact.telephone =
      customerServiceContactPhone || "";

    clonedCompanyContent.marketingMessageHeader = marketingMessageHeader || "";

    clonedCompanyContent.marketingMessage = marketingMessage || "";
    clonedCompanyContent.removeLogo = removeLogo;

    if (isLogoChanged) {
      clonedCompanyContent.companyLogoNew = companyLogoNew;
    }

    clonedCompanyContent.fileFormat = companyLogoNew.fileFormat;

    //accountManagerEmail,companyContactEmail,customerServiceContactEmail
    if (
      (accountManagerEmail !== "" &&
        !accountManagerEmail.match(emailRegExpr)) ||
      (companyContactEmail !== "" &&
        !companyContactEmail.match(emailRegExpr)) ||
      (customerServiceContactEmail !== "" &&
        !customerServiceContactEmail.match(emailRegExpr))
    ) {
      // setAllFieldsValid (false);
      dispatch(
        showErrorAlertMessage(t("Contents.cannotSaveInvalidEmailAddress"))
      );
      dispatch(
        broadcastAnnouncement(t("Contents.cannotSaveInvalidEmailAddress"))
      );

      window.scrollTo(0, 0);
      return;
    }
    if (marketingMessageLength > 1000) {
      // setAllFieldsValid (false);
      dispatch(
        showErrorAlertMessage(
          t("Contents.cannotSaveDueToTooLongMaketingMessage")
        )
      );
      dispatch(
        broadcastAnnouncement(
          t("Contents.cannotSaveDueToTooLongMaketingMessage")
        )
      );
      window.scrollTo(0, 0);
      return;
    }

    var errors = validateCompanyLink();

    if (errors && errors.length === 0) {
      //setIsValidCompanyLink(true)
      clonedCompanyContent.companyLinks = getUpdatedCompanyLink();
      setConmpanyLinkTableLength(getLinkTableLength());
      uploadCompanyContent(clonedCompanyContent);
    } else {
      //setIsValidCompanyLink(false)
      dispatch(showErrorAlertMessage(errors));
      dispatch(broadcastAnnouncement(errors.join(". ")));
      window.scrollTo(0, 0);
      return;
    }

    window.scrollTo(0, 0);
  };

  const onEditorChange = (message) => {
    var length = message ? removeTags(message).length - 1 : 0;

    setMarketingMessage(message);

    setMarketingMessageLength(length);

    if (marketingMessage !== message) {
      isInputValid();
    }

    //setSaveButtonStatusTouched()
    // setSaveButtonStatusTouched(marketingMessage!==copiedCompanyContent.marketingMessage);

    // if (length >1000) {
    //   setMarketingMessage(messsage.substring(0, 1000));
    // }else {
    //   setMarketingMessage(messsage);
    // }
  };

  appInsights.trackEvent({
    name: "Company Contents",
    properties: { companyUId: companyUid },
  });

  return (
    <div className={classes.root} id="CompanyContentTopDivId">
      <Typography id="CompanyContentMainTitleId" variant="h1">
        {t("Contents.companyContent")}
      </Typography>
      <Grid container spacing={3}>
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress />
        </Backdrop>
        <Grid item xs={12}>
          {" "}
          <fieldset>
            <legend>
              <Typography variant="h2"> {t("Contents.logo")}</Typography>
            </legend>
            <div
              id="Note1"
              style={{
                display: "flex",
                justifyContent: "left",
                alignItems: "left",
              }}
            >
              {t("Contents.logoNote1")}
            </div>
            <div
              id="Note2"
              style={{
                display: "flex",
                justifyContent: "left",
                alignItems: "left",
              }}
            >
              {t("Contents.logoNote2")}
            </div>
            <div id="logo">
              <ImageUploading
                multiple={true}
                value={images}
                onChange={handleImageChange}
                acceptType={[
                  "gif",
                  "jfif",
                  "jpeg",
                  "pjp",
                  "pjpeg",
                  "jpg",
                  "png",
                ]}
                maxNumber={2}
                dataURLKey="data_url"
              >
                {({
                  imageList,
                  onImageUpload,
                  onImageRemoveAll,
                  onImageUpdate,
                  onImageRemove,
                  isDragging,
                  dragProps,
                  // errors,
                }) => (
                  <div className="upload__image-wrapper">
                    {imageList.map((image, index) => (
                      <div
                        key={index}
                        className="image-item"
                        style={{ justifyContent: "right" }}
                      >
                        {image.dataURL !== "" ? (
                          <img
                            id="imgCompanyLogo"
                            src={image["data_url"]}
                            alt=""
                            style={{
                              maxWidth: "215px",
                              maxHeight: "50px",
                              border: 1,
                            }}
                          />
                        ) : (
                          <img
                            id="imgCompanyLogo"
                            src={image["data_url"]}
                            alt=""
                            style={{
                              display: "none",
                              maxWidth: "215px",
                              maxHeight: "50px",
                              border: 1,
                            }}
                          />
                        )}
                      </div>
                    ))}
                    <Button
                      color="primary"
                      variant="contained"
                      type="submit"
                      ref={btnResetPassDlgRef}
                      onClick={() => {
                        onImageRemoveAll();
                        isInputValid();
                      }}
                      {...dragProps}
                      style={{ textTransform: "none" }}
                    >
                      {t("Contents.remove")}
                    </Button>
                    &nbsp;
                    <Button
                      color="primary"
                      variant="contained"
                      ref={btnResetPassDlgRef}
                      onClick={() => {
                        onImageUpload();
                      }}
                      {...dragProps}
                      style={{ textTransform: "none" }}
                    >
                      {t("Contents.select")}
                    </Button>
                  </div>
                )}
              </ImageUploading>
            </div>
          </fieldset>
        </Grid>
        &nbsp;
        <Grid container spacing={3}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <Typography variant="h2">
            {" "}
            {t("Contents.contactUsInformation")}
          </Typography>
        </Grid>
        <Grid container item xs={6} direction="column">
          <fieldset>
            <legend>
              <Typography variant="h2">
                {" "}
                {t("Contents.accountManager")}
              </Typography>
            </legend>
            <div
              id="accountManagerNameTextBox-container"
              style={{
                display: "flex",
                justifyContent: "left",
                alignItems: "left",
                flexDirection: "column",
              }}
            >
              <TextField
                id="accountManagerNameTextBox"
                size="small"
                margin="dense"
                //InputLabelProps={{ shrink: false }}
                inputProps={{
                  "aria-label": t("Contents.accountManagerName"),
                  "aria-required": false,
                }}
                label={t("Contents.accountManagerName")}
                variant="outlined"
                value={accountManagerName}
                onKeyUp={(e) => {
                  isInputValid();
                }}
                onChange={(e) => {
                  setAccountManagerName(e.target.value);
                  dispatch(broadcastAnnouncement(" "));
                  dispatch(clearAlertMessage());
                  //isInputValid()
                  // setSaveButtonStatusTouched(accountManagerName!==copiedCompanyContent.accountManangerContact.fullName);
                }}
                placeholder={t("Contents.accountManagerNamePlaceHolder")}
                error={accountManagerName.length > 80}
                helperText={
                  accountManagerName.length > 80
                    ? t("Contents.accountManagerNameWarning")
                    : " "
                }
              />
              <TextField
                id="accountManagerEmailTextBox"
                size="small"
                margin="dense"
                //InputLabelProps={{ shrink: true }}
                inputProps={{
                  "aria-label": t("Contents.accountMangerEmail"),
                  "aria-required": false,
                }}
                label={t("Contents.accountMangerEmail")}
                variant="outlined"
                value={accountManagerEmail}
                onKeyUp={(e) => {
                  isInputValid();
                }}
                onChange={(e) => {
                  setAccountManagerEmail(e.target.value);
                  dispatch(broadcastAnnouncement(" "));
                  dispatch(clearAlertMessage());
                  // isInputValid()
                  //setSaveButtonStatusTouched(accountManagerEmail!==copiedCompanyContent.accountManangerContact.emailAddress);
                }}
                error={
                  (accountManagerEmail !== "" &&
                    !accountManagerEmail.match(emailRegExpr)) ||
                  accountManagerEmail.length > 80
                }
                helperText={
                  accountManagerEmail !== "" &&
                  !accountManagerEmail.match(emailRegExpr)
                    ? t("Contents.accountMangerEmailWarning1")
                    : accountManagerEmail.length > 80
                    ? t("Contents.accountMangerEmailWarning2")
                    : " "
                }
                placeholder={t("Contents.accountMangerEmailPlaceHolder")}
              />
              <TextField
                id="accountManagerPhoneTextBox"
                size="small"
                margin="dense"
                //InputLabelProps={{ shrink: true }}
                inputProps={{
                  "aria-label": t("Contents.accountMangerPhone"),
                  "aria-required": false,
                }}
                label={t("Contents.accountMangerPhone")}
                variant="outlined"
                value={accountManagerPhone}
                onKeyUp={(e) => {
                  isInputValid();
                }}
                onChange={(e) => {
                  setAccountManagerPhone(e.target.value);
                  dispatch(broadcastAnnouncement(" "));
                  dispatch(clearAlertMessage());
                  // isInputValid();
                  // setSaveButtonStatusTouched(accountManagerPhone!==copiedCompanyContent.accountManangerContact.telephone);
                }}
                error={accountManagerPhone.length > 26}
                helperText={
                  accountManagerPhone.length > 26
                    ? t("Contents.accountMangerPhoneWarning")
                    : " "
                }
                placeholder={t("Contents.accountMangerPhonePlaceHolder")}
              />
            </div>
          </fieldset>
        </Grid>
        <Grid container item xs={6} direction="column">
          <fieldset>
            <legend>
              <Typography variant="h2">
                {t("Contents.companyContact")}{" "}
              </Typography>
            </legend>
            <div
              style={{
                display: "flex",
                justifyContent: "left",
                alignItems: "left",
                flexDirection: "column",
              }}
            >
              <TextField
                id="companyContactNameTextBox"
                size="small"
                margin="dense"
                //InputLabelProps={{ shrink: true }}
                inputProps={{
                  "aria-label": t("Contents.companyContactNamePlaceHolder"),
                  "aria-required": false,
                }}
                label={t("Contents.companyContactName")}
                variant="outlined"
                value={companyContactName}
                onKeyUp={(e) => {
                  isInputValid();
                }}
                onChange={(e) => {
                  setCompanyContactName(e.target.value);
                  dispatch(broadcastAnnouncement(" "));
                  dispatch(clearAlertMessage());
                  // isInputValid();
                  // setSaveButtonStatusTouched(companyContactName!==copiedCompanyContent.companyContact.fullName);
                }}
                error={companyContactName.length > 80}
                helperText={
                  companyContactName.length > 80
                    ? t("Contents.companyContactNameWarning")
                    : " "
                }
                placeholder={t("Contents.companyContactNamePlaceHolder")}
              />
              <TextField
                id="companyContactEmailTextBox"
                size="small"
                margin="dense"
                //InputLabelProps={{ shrink: true }}
                inputProps={{
                  "aria-label": t("Contents.companyContactEmail"),
                  "aria-required": false,
                }}
                label={t("Contents.companyContactEmail")}
                variant="outlined"
                value={companyContactEmail}
                onKeyUp={(e) => {
                  isInputValid();
                }}
                onChange={(e) => {
                  setCompanyContactEmail(e.target.value);
                  dispatch(broadcastAnnouncement(" "));
                  dispatch(clearAlertMessage());
                  // isInputValid();
                  // setSaveButtonStatusTouched(companyContactEmail!==copiedCompanyContent.companyContact.emailAddress);
                }}
                placeholder={t("Contents.companyContactEmailPlaceHolder")}
                error={
                  (companyContactEmail !== "" &&
                    !companyContactEmail.match(emailRegExpr)) ||
                  companyContactEmail.length > 80
                }
                helperText={
                  companyContactEmail !== "" &&
                  !companyContactEmail.match(emailRegExpr)
                    ? t("Contents.companyContactEmailWarning1")
                    : companyContactEmail.length > 80
                    ? t("Contents.companyContactEmailWarning2")
                    : " "
                }
              />
              <TextField
                id="companyContactPhoneTextBox"
                size="small"
                margin="dense"
                //InputLabelProps={{ shrink: true }}
                inputProps={{
                  "aria-label": t("Contents.companyContactPhone"),
                  "aria-required": false,
                }}
                label={t("Contents.companyContactPhone")}
                variant="outlined"
                value={companyContactPhone}
                onKeyUp={(e) => {
                  isInputValid();
                }}
                onChange={(e) => {
                  setCompanyContactPhone(e.target.value);
                  dispatch(broadcastAnnouncement(" "));
                  dispatch(clearAlertMessage());
                  // isInputValid();
                  // setSaveButtonStatusTouched(companyContactPhone!==copiedCompanyContent.companyContact.telephone);
                }}
                error={companyContactPhone.length > 26}
                helperText={
                  companyContactPhone.length > 26
                    ? t("Contents.companyContactPhoneWarning")
                    : " "
                }
                placeholder={t("Contents.companyContactPhonePlaceHolder")}
              />
            </div>
          </fieldset>
        </Grid>
        <Grid container item xs={6} direction="column">
          <fieldset>
            <legend>
              <Typography variant="h2">
                {t("Contents.customerServiceContact")}{" "}
              </Typography>
            </legend>
            <div
              style={{
                display: "flex",
                justifyContent: "left",
                alignItems: "left",
                flexDirection: "column",
              }}
            >
              <TextField
                id="customerServiceNameTextBox"
                size="small"
                margin="dense"
                //InputLabelProps={{ shrink: true }}
                inputProps={{
                  "aria-label": t("Contents.companyContactName"),
                  "aria-required": false,
                }}
                label={t("Contents.companyContactName")}
                variant="outlined"
                value={customerServiceContactName}
                onKeyUp={(e) => {
                  isInputValid();
                }}
                onChange={(e) => {
                  setCustomerServiceContactName(e.target.value);
                  dispatch(broadcastAnnouncement(" "));
                  dispatch(clearAlertMessage());
                  isInputValid();
                  // setSaveButtonStatusTouched(customerServiceContactName!==copiedCompanyContent.customerServiceContact.fullName);
                }}
                error={customerServiceContactName.length > 80}
                helperText={
                  customerServiceContactName.length > 80
                    ? t("Contents.customerNameWarning")
                    : " "
                }
                placeholder={t("Contents.customerNamePlaceHolder")}
              />
              <TextField
                id="customerServiceEmailTextBox"
                size="small"
                margin="dense"
                //InputLabelProps={{ shrink: true }}
                inputProps={{
                  "aria-label": t("Contents.companyContactEmail"),
                  "aria-required": false,
                }}
                label={t("Contents.companyContactEmail")}
                variant="outlined"
                value={customerServiceContactEmail}
                onKeyUp={(e) => {
                  isInputValid();
                }}
                onChange={(e) => {
                  setCustomerServiceContactEmail(e.target.value);
                  dispatch(broadcastAnnouncement(" "));
                  dispatch(clearAlertMessage());
                  // isInputValid();
                  // setSaveButtonStatusTouched(customerServiceContactName!==copiedCompanyContent.customerServiceContact.emailAddress);
                }}
                error={
                  (customerServiceContactEmail !== "" &&
                    !customerServiceContactEmail.match(emailRegExpr)) ||
                  customerServiceContactEmail.length > 80
                }
                helperText={
                  customerServiceContactEmail !== "" &&
                  !customerServiceContactEmail.match(emailRegExpr)
                    ? t("Contents.customerEmailWarning1")
                    : customerServiceContactEmail.length > 80
                    ? t("Contents.customerEmailWarning2")
                    : " "
                }
                placeholder={t("Contents.customerEmailPlaceHolder")}
              />
              <TextField
                id="customerServicePhoneTextBox"
                size="small"
                margin="dense"
                //InputLabelProps={{ shrink: true }}
                inputProps={{
                  "aria-label": t("Contents.companyContactPhone"),
                  "aria-required": false,
                }}
                label={t("Contents.companyContactPhone")}
                variant="outlined"
                value={customerServiceContactPhone}
                onKeyUp={(e) => {
                  isInputValid();
                }}
                onChange={(e) => {
                  setCustomerServiceContactPhone(e.target.value);
                  dispatch(broadcastAnnouncement(" "));
                  dispatch(clearAlertMessage());
                  // isInputValid();
                  // setSaveButtonStatusTouched(customerServiceContactPhone!==copiedCompanyContent.customerServiceContact.telephone);
                }}
                placeholder={t("Contents.customerPhonePlaceHolder")}
                error={customerServiceContactPhone.length > 26}
                helperText={
                  customerServiceContactPhone.length > 26
                    ? t("Contents.customerPhoneWarning")
                    : " "
                }
              />
            </div>
          </fieldset>
        </Grid>
        <Grid container item xs={12} direction="column">
          <fieldset>
            <legend>
              <Typography variant="h2"> {t("Contents.subHeading")}</Typography>
            </legend>
            <div
              style={{
                display: "flex",
                justifyContent: "left",
                alignItems: "left",
                flexDirection: "column",
              }}
            >
              <TextField
                id="MarketingMessageHeader"
                size="small"
                margin="dense"
                //InputLabelProps={{ shrink: true }}
                inputProps={{
                  "aria-label": t("Contents.subHeading"),
                  "aria-required": false,
                }}
                label={t("Contents.subHeading")}
                variant="outlined"
                value={marketingMessageHeader}
                onKeyUp={(e) => {
                  isInputValid();
                }}
                onChange={(e) => {
                  setMarketingMessageHeader(e.target.value);
                  dispatch(broadcastAnnouncement(" "));
                  dispatch(clearAlertMessage());
                  // isInputValid();
                }}
                placeholder={t("Contents.subHeading")}
                error={marketingMessageHeader.length > 50}
                helperText={
                  marketingMessageHeader.length > 50
                    ? t("Contents.subHeadingWarning")
                    : " "
                }
              />
            </div>
          </fieldset>
        </Grid>
        <Grid container item xs={12} direction="column">
          <fieldset>
            <legend>
              <Typography variant="h2">
                {" "}
                {t("Contents.marketingMessage")}
              </Typography>
            </legend>
            <div
              style={{
                display: "flex",
                justifyContent: "left",
                alignItems: "left",
                flexDirection: "column",
              }}
            >
               <div className="text-editor">
                <ReactQuill 
                  theme="snow" 
                  value={marketingMessage} 
                  modules={toolbarModules} 
                  onChange={(e: string) => {
                    onEditorChange(e);
                    dispatch(broadcastAnnouncement(" "));
                    dispatch(clearAlertMessage());
                  }} 
                />
              </div>
              <span
                id="marketingMessageErrorSpanId"
                style={{
                  color: "red",
                  display: marketingMessageLength < 1001 ? "none" : "block",
                }}
              >
                The length of marketing message is {marketingMessageLength}{" "}
                characters, exceeds max length of 1000 characters, and cannot be
                saved.
              </span>
            </div>
          </fieldset>
        </Grid>
        <Grid container item xs={12} direction="column">
          <fieldset>
            <legend>
              <Typography variant="h2">
                {" "}
                {t("Contents.companyLinks")}
              </Typography>
            </legend>
            <div
              style={{
                display: "flex",
                justifyContent: "left",
                alignItems: "left",
                flexDirection: "column",
              }}
            >
              {companyLinkTable(companyLinks)}
            </div>
          </fieldset>
        </Grid>
      </Grid>
      <div>
        <p></p>
      </div>
      <br />
      <Grid container justifyContent="flex-end">
        <Button
          id="btnUpdate"
          color="primary"
          variant="contained"
          disabled={!saveButtonStatusTouched}
          onClick={handleUpdate}
          onKeyDown={handleKeyPressForSaveButton}
          type="submit"
          style={{ marginTop: "0.5em", textTransform: "none" }}
        >
          {t("Contents.btnSave")}
        </Button>
        <Button
          classes={{ outlinedPrimary: classes.outlinedButton }}
          id="btnCancel"
          color="primary"
          variant="outlined"
          type="button"
          onClick={() => {
            props.action("");
          }}
          style={{
            marginTop: "0.5em",
            marginLeft: "0.5em",
            textTransform: "none",
          }}
        >
          {t("Contents.btnCancel")}
        </Button>
      </Grid>
    </div>
  );
}
