import * as Yup from "yup";
export const formFieldsValidationSchema = Yup.object().shape({
  hasErrors: Yup.boolean(),
  additionalInformationFields: Yup.array().of(
    Yup.object().shape({
      additionalInformationGid: Yup.string()
        // .required("REQUIRED")
        .test(
          "datefieldtest-",
          "Users.Errors.fieldValueFormatInvalidOrInPast",
          function () {
            const ftc = this.parent.fieldTypeCode;
            if (ftc !== 1) {
              return true;
            } else {
              const c = this.parent.fieldValueDateString;
              //console.log("Date Field Value: ", c, this.parent.fieldValue);
              return c === "FormatError" || c === "DateInPast" ? false : true;
            }
          }
        )
        .test("emptytest-", "Users.Errors.fieldValueRequired", function () {
          const a = this.parent.fieldValue;

          if (!(this.parent.mandatory ?? false)) return true;
          else {
            return a ? true : false;
          }
        })
        .test("lengthtest-", "Users.Errors.fieldValueMaxLength", function () {
          const b = this.parent.fieldValue;
          const maxLength = this.parent.maxLength ?? 100;
          return (b ?? "").length > maxLength ? false : true;
        }),
    })
  ),
});



export const bookingRefInitialValue = {
  additionalInformationFields: [],
  Message: "",
  HasErrors: false,
  HasWarnings: false,
};