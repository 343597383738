import axios from "axios";
import { history } from "./history";
import { authService } from "services/authService";
import configParams from "../configParams";
const baseURL = window.location.hostname.endsWith("etd.enterprise.co.uk")
  ? configParams.API_BASE_URL
  : window.location.hostname.endsWith("etd.ehi.com")
  ? configParams.API_BASE_URL_EHI
  : (configParams.API_BASE_URL_Localhost ?? "http://localhost:3004/api/");

const axiosInstance = axios.create({
  baseURL: baseURL,
  headers: { "Content-Type": "application/json" },
});

axiosInstance.interceptors.request.use(function (config) {
  if (!authService.isTokeExpired()) {
    config.headers["Authorization"] = `Bearer ${sessionStorage.access_token}`;
    return config;
  } else {
    authService.logout();
  }
  return config;
});
axiosInstance.interceptors.response.use(
  (response) =>
    new Promise((resolve, reject) => {
      resolve(response);
    }),
  (error) => {
    if (!error.response) {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }

    if (error.response.status === 403 || error.response.status === 401) {
      if (history) {
        authService.logout();
      } else {
        authService.logout();
      }
    } else {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }
  }
);

export default axiosInstance;
