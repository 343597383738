import {
  UserTitle,
  ContactPhoneNumber,
  ReservationLoyaltyViewModel,
} from "../Reservation/reservationFormType";

export interface PrimaryDriverViewModel {
  userTitle: UserTitle;
  firstName: string;
  lastName: string;
  emailAddress: string;
  contactPhoneNumber: ContactPhoneNumber;
  alternatePhoneNumber: ContactPhoneNumber;
  isPrimaryDriver: boolean;
  boboId?: number;
  carClubId?: number;
  driverDeclarationId?: number;
  savedEnterprisePlusNumber?: string;
  savedEmeraldClubNumber?: string;
  isBOBOBooking: boolean;
  reservationDriverId?: number;
  reservationId? : number;
  isConsent: boolean;
}

export interface DriverDetailsViewModel {
  driver: PrimaryDriverViewModel;
  loyalty: ReservationLoyaltyViewModel;
  isValidDriver : boolean;
  isValidLoyalty : boolean;
}

export const driverInitialValue = {
  driver: {
    userTitle: { userTitle: "", selectedValue: "", selectedText: "" },
    firstName: "",
    lastName: "",
    emailAddress: "",
    contactPhoneNumber: {
      contactPhoneNumber: "",
      selectedInternationalDiallingCode: "",
      number: "",
    },
    alternatePhoneNumber: {
      contactPhoneNumber: "",
      selectedInternationalDiallingCode: "",
      number: "",
    },
    isPrimaryDriver: true,
    savedEnterprisePlusNumber: "",
    savedEmeraldClubNumber: "",
    isBOBOBooking: false,
    isConsent : false,
  },
  loyalty: {
    enableLoyalty: false,
    loyaltyId: "",
    loyaltyLastValidValue: "",
    loyaltyPrograms: [
      {
        disabled: false,
        group: {
          disabled: false,
          name: "",
        },
        selected: false,
        text: "",
        value: "",
      },
    ],
    selectedLoyaltyProgram: "",
    selectedLoyaltyProgramValue: "",
    savedEnterprisePlusNumber: "",
    savedEmeraldClubNumber: "",
  },
  isValidDriver: false,
  isValidLoyalty: true,
};
