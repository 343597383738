import React, { useEffect, useState, useCallback } from "react";
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import {
  Accordion,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { useTranslation } from "react-i18next";
import CarClubDriver from "./carClubDriver";
import { carClubDriverDetails } from "components/store/actions/reservation/carclubActions";
import {
  useCarClubDriverDetails,
  useCarClubDriverSearch,
  useCarClubJourneyEndDate,
} from "components/store/reducers/reservation/carclubSelector";
import { useDispatch, useSelector } from "react-redux";
import { CarClubDriverState, driverDetails } from "./carClubDriverType";
import { AppState } from "components/store/reducers/rootReducer";
import {
  getUserPermissionsStatus,
  Permissions,
} from "helpers/getUserPermissions";
import { authService } from "services/authService";
import { DriverDeclaration, resInitialValue } from "../Reservation/reservationFormType";

const useStyles = makeStyles((theme) => ({
  gridItem: {
    verticalAlign: "center",
    padding: "10px",
    "& .MuiTypography-body2": {
      fontWeight: "bold",
      fontSize: "0.875rem",
    },
  },
  heading: {
    fontSize: "1rem",
    fontWeight: "bold",
  },
  backdrop: {
    zIndex: 99999,
    color: "#fff",
  },
  focused: { outline: "none" },
}));

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "#006639",
    fontWeight: "bold",
    color: "#fff",
    border: "2px solid #006639",
    "&.Mui-expanded": {
      minHeight: "48px",
    },
    "&.Mui-focused": {
      backgroundColor: "#006639",
    },
  },
  content: {
    marginBottom: 0,
    marginTop: 0,
    padding: 0,
    minHeight: "12px",
  },
  expandIcon: {
    marginRight: 0,
    paddingTop: 0,
    color: "#fff",
  },
})(MuiAccordionSummary);

const AccordionDetails = withStyles({
  root: {
    "& ::placeholder": {
      color: "#767676",
      opacity: "1",
    },
  },
})(MuiAccordionDetails);

const CarClubDetail: React.FC<any> = (props: any) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation();
  const titleRef = React.useRef<HTMLDivElement>(null);
  const [loading, setLoading] = React.useState(false);
  const carClubDrivers: driverDetails[] = useCarClubDriverDetails();
  const carClubSearchParam = useCarClubDriverSearch();
  const journeyEndDate= useCarClubJourneyEndDate();
  const [selectedDriver, setSelectedDriver] = React.useState(
    carClubDrivers.find((x) => x.isSelected === true)?.id ?? ""
  );
  const [isContinueDisabled, setisContinueDisabled] = React.useState(
    selectedDriver === ""
  );
  const isDriverValidityActivated = useSelector(
    (state: AppState) =>
      state.companyInfo.companyInfo?.isDriverValidityActivated
  );
  const userPermissions = authService.getUserPermissions();
  const { companyInfo } = useSelector((state: AppState) => state.companyInfo);
  const isBoboEnabled =
    companyInfo?.isBoboEnabled &&
    getUserPermissionsStatus(userPermissions, Permissions.BoboEnabled);
  const continueButtonRef = React.useRef<HTMLButtonElement>(null);
  useEffect(() => {
    if (titleRef.current !== null) titleRef.current.focus();
  }, []);

  const [isValidAdditionalDrivers, setIsValidAdditionalDrivers] = useState<boolean>(true);
  const [additionalDrivers, setAdditionalDrivers] = useState<DriverDeclaration[]>(authService.getDriverInfo().additionalDriver);
  const onAdditionalDriverChange = useCallback((isValid: boolean, additionalDriver?: DriverDeclaration[]) => {
    setIsValidAdditionalDrivers(isValid);
    if(isValid && additionalDriver !== undefined ){
        setAdditionalDrivers(additionalDriver);
      }
   }, [])

  //useEffect(() => {}, [carClubSearchParam]);
  const handleSelectedDriver = () => {
    var drivers: driverDetails[] = [];
    const index = carClubDrivers.findIndex(
      (driver) => driver.id === selectedDriver
    );
    if (index > -1)
      carClubDrivers.forEach((driver) => {
        driver = JSON.parse(JSON.stringify(driver));
        if (driver.id === selectedDriver) driver.isSelected = true;
        else driver.isSelected = false;
        drivers.push(driver);
      });
    var driverDetails: CarClubDriverState = {
      drivers: drivers,
      journeyEndDate,
      searchParam: carClubSearchParam,
      hasError: false,
      errors: undefined,
    };
    props.handleSelected(selectedDriver);
    dispatch(carClubDriverDetails(driverDetails));
  };
  const handleAdditionalDrivers = () => {
    if(isValidAdditionalDrivers){
      const driverInfo = authService.getDriverInfo();
      authService.setDriverInfo({...driverInfo,additionalDriver:additionalDrivers})
    }
  }

  const handleSelectDriver = (driverId: string) => {
    console.log("handleselect", driverId);
    setSelectedDriver(driverId);
    if (driverId !== "") {
      setisContinueDisabled(false);
    } else {
      setisContinueDisabled(true);
    }
  };

  return (
    <Box data-testid="CarClubDetail"
      boxShadow={3}
      p={3}
      bgcolor="background.paper"
      style={{ width: "100%" }}>
      <Grid container>
        <div ref={titleRef} tabIndex={-1} className={classes.focused}>
          <Typography
            variant="h1"
            style={{
              marginBottom: "10px",
              marginTop: "20px",
            }}>
            {t("ReservationWizard.detailsPageTitle")}
          </Typography>
        </div>
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress />
        </Backdrop>
        <Grid item xs={12}>
         <Accordion
            style={{ width: "100%" }}
            defaultExpanded
            id="sectionDriver">
            <h2>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{color:"#FFFFFF"}}/>}
                aria-controls="panel-driver"
                id="driver"
                className={classes.heading}>
                {t("ReservationWizard.driverDetails.labelDriver")}
              </AccordionSummary>
            </h2>
            <AccordionDetails id="panel-driver">
              <Grid container>
                <Grid container direction="row">
                  <Grid item xs={12} md={12} className={classes.gridItem}>
                    <CarClubDriver
                      carclubregisterhyperlink={props.carclubregisterhyperlink}
                      selectedDriver={selectedDriver}
                      isDriverValidityActivated={
                        isDriverValidityActivated ?? false
                      }
                      handleSelectDriver={handleSelectDriver}
                      carClubDrivers={carClubDrivers}
                      isBoboEnabled={isBoboEnabled}
                      searchParam={carClubSearchParam}
                      onDataChange={onAdditionalDriverChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={9} className={classes.gridItem}></Grid>
                </Grid>
                {/* Add Additional */}
                {/* {carClubDrivers && carClubDrivers.length > 0 && (
                  <Grid
                    item
                    xs={12}
                    md={12}
                    style={{
                      display: "flex",
                      justifyContent: "right",
                      marginTop: "1em",
                    }}
                    className={classes.gridItem}>
                    <IconButton
                      id="btnAddAdditionalDriver"
                      role="button"
                      aria-label={t("journeyInputaddAdditionalDriver")}
                      color="primary"
                      size="small"
                      title={t("journeyInput.addAdditionalDriver")}
                      onClick={() => {}}>
                      <AddIcon />
                      {t("journeyInput.addAdditionalDriver")}
                    </IconButton>
                  </Grid>
                )} */}
                {carClubDrivers && carClubDrivers.length > 0 && (
                  <Grid
                    item
                    xs={12}
                    md={12}
                    className={classes.gridItem}
                    style={{ textAlign: "center" }}>
                    <Typography
                      display="inline"
                      variant="inherit"
                      tabIndex={0}
                      style={{
                        whiteSpace: "pre-line",
                      }}>
                      {t(
                        "ReservationWizard.reservationPage.labelConsentMessagePart1"
                      )}
                    </Typography>
                    <Link
                      id="privacypolicyhyperlink"
                      target="_blank"
                      href={t(
                        "ReservationWizard.reservationPage.privacyPolicyURL"
                      )}
                      display="inline"
                      variant="inherit">
                      {t(
                        "ReservationWizard.reservationPage.labelConsentMessagePart2"
                      )}
                      <Box component="span" className="visuallyHidden">
                        {t("Footer.OpenNewWindow")}
                      </Box>
                    </Link>
                    <Typography display="inline" variant="inherit">
                      {t(
                        "ReservationWizard.reservationPage.labelConsentMessagePart3"
                      )}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "1em",
          }}>
          <Button
            id="btnBack"
            color="primary"
            aria-label={t("lblBackToPreviousStep")}
            variant="contained"
            onClick={() => {
              if (props.handleBack) props.handleBack(0, 0);
            }}>
            {t("btnBack")}
          </Button>
          <Button
            id="btnSubmit"
            color="primary"
            variant="contained"
            ref={continueButtonRef}
            aria-label={t("lblContinueToNextStep")}
            disabled={isContinueDisabled || !isValidAdditionalDrivers}
            onClick={() => {
              setLoading(true);
              handleSelectedDriver();
              handleAdditionalDrivers();
              if (props.handleNext) props.handleNext(10, 0);
            }}>
            {t("btnContinue")}
          </Button>
        </Grid>
      </Grid>{" "}
    </Box>
  );
};
export default CarClubDetail;
