import { combineReducers, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CarClubDriverState } from "components/Reservation/steps/Driver/carClubDriverType";
import { CarClubReservationViewModel } from "views/reservation/ReservationViewType";
import { CarClubReservation } from "../../../../components/Reservation/steps/CarClub/carClubFormType";
interface CarClubState {
  hasError: boolean;
  reservation?: CarClubReservation | null;
  reservationViewModel?: CarClubReservationViewModel | null;
  errors: string[];
}

interface CarClubVehicleState {
  hasError: boolean;
  vehicles?: {
    availabilityList: [];
    clientCurrentDateTime: string;
    companyCountry: string;
    companyUId: string;
    driverAuthCodeExpiry: string;
    endDateTime: string;
    journeyDetails: null;
    journeyEndUnixTime: string;
    journeyStartUnixTime: string;
    pager: {
      currentPage: number;
      totalPages: number;
      startPage: number;
      endPage: number;
    };
    sliderKeyboardStep: string;
    startDateTime: string;
    uId: string;
  };
  errors: string[];
}

const initialState: CarClubState = {
  hasError: false,
  reservation: undefined,
  reservationViewModel: undefined,
  errors: [],
};

const initialDriverState: CarClubDriverState = {
  hasError: false,
  drivers: [],
  journeyEndDate: undefined,
  searchParam: undefined,
  errors: undefined,
};

const initialVehicleState: CarClubVehicleState = {
  hasError: false,
  vehicles: undefined,
  errors: [],
};

export const carClubRentalSlice = createSlice({
  name: "carclubRental",
  initialState,
  reducers: {
    updateCarClubReservation: (_, action: PayloadAction<CarClubState>) => ({
      reservation: action.payload.reservation,
      reservationViewModel: action.payload.reservationViewModel,
      hasError: action.payload.hasError,
      errors: action.payload.errors,
    }),

    resetCarClubReservation: () => initialState,
  },
});

export const carClubDriverSlice = createSlice({
  name: "carclubDriver",
  initialState: initialDriverState,
  reducers: {
    carClubDriverDetails: (_, action: PayloadAction<CarClubDriverState>) => ({
      drivers: action.payload.drivers,
      journeyEndDate: action.payload.journeyEndDate,
      searchParam: action.payload.searchParam,
      hasError: action.payload.hasError,
      errors: action.payload.errors,
    }),
    resetCarClubDriverDetails: () => initialDriverState,
  },
});

export const carClubVehicleSlice = createSlice({
  name: "carclubVehicle",
  initialState: initialVehicleState,
  reducers: {
    carClubVehicleAvailability: (
      _,
      action: PayloadAction<CarClubVehicleState>
    ) => ({
      vehicles: action.payload.vehicles,
      hasError: action.payload.hasError,
      errors: action.payload.errors,
    }),
    resetCarClubVehicleAvailability: () => initialVehicleState,
  },
});

const carClubReducer = combineReducers({
  carClubRentalReducer: carClubRentalSlice.reducer,
  carClubDriverReducer: carClubDriverSlice.reducer,
  carClubVehicleReducer: carClubVehicleSlice.reducer,
});
export default carClubReducer;
