import React, { useEffect } from "react";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  Select,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import { ReservationProps } from "../../reservationType";
import Accordion from "@mui/material/Accordion";
import useReservationMode, {
  ReservationMode,
} from "helpers/useReservationMode";
import {
  reservationValidationSchema,
  ValidateFlightNumber,
} from "./locationValidationSchema";
import {
  ReservationLocationsViewModel,
  ReservationObject,
  ReservationReasonForHireViewModel,
  resInitialValue,
  JourneyProfilerViewModel,
  ReservationLocInfoViewModel,
} from "./reservationFormType";
import { Formik, Form, Field } from "formik";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { appInsights } from "helpers/appInsights";
import { SeverityLevel } from "@microsoft/applicationinsights-common";
import EtdDatePickerV2 from "controls/formik/EtdDatePickerV2";
import EtdTimePicker from "../../../../controls/formik/EtdTimePicker";
import axiosInstance from "helpers/axiosInstance";
import { registerLocale } from "react-datepicker";
import de from "date-fns/locale/de";
import es from "date-fns/locale/es";
import fr from "date-fns/locale/fr";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "components/store/reducers/rootReducer";
import {
  updateReservation,
  resetReservation,
} from "components/store/actions/reservation/reservationActions";
import "react-datepicker/dist/react-datepicker.css";
import store from "components/store/store";
import CompactJourneyDetailsView from "../Shared/compactJourneyDetailsView";
import JourneyStartEndMethods from "./journeyStartEndMethods";
import {
  clearAlertMessage,
  showErrorAlertMessage,
  showInfoAlertMessage,
} from "components/store/actions/shared/alertActions";

import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { broadcastAnnouncement } from "components/store/actions/shared/announcementActions";
import { authService } from "services/authService";
import moment from "moment";
import {
  getDateObjectFromShortString,
  getIntlDateFormatForLocale,
} from "helpers/localization";

import { useNavigate } from "react-router-dom";
import { updateAccountLocationDetails } from "components/store/slicers/accountLocationSlice";
import { updateBookingReferenceDetails } from "components/store/slicers/bookingReferenceSlice";
import {
  useStyles,
  AccordionSummary,
  AccordionDetails,
} from "../../../shared/styles/common";
import BookingReference from "components/shared/bookingReference/BookingReference";
import { 
  isWithin12Months,
  isWithin12MonthsEditScenario,
  isWithin28Days,
  isInUSAorCanada,
  isInUSAorCanadaCountryCode
} from "helpers/creditCard";

const LocationForm: React.FC<ReservationProps> = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [resMode] = useReservationMode();
  const [loading, setLoading] = React.useState(false);

  const [loadingAccount, setLoadingAccount] = React.useState(false);
  const [journeyprofile, setjourneyProfile] =
    React.useState<JourneyProfilerViewModel>();
  const [validatingAccount, setValidatingAccount] = React.useState(false);
  const [isDatesValid, setIsDatesValid] = React.useState(true);
  const [resdata, setResData] =
    React.useState<ReservationObject>(resInitialValue);
  const [flightNumberHasError, setFlightNumberHasError] =
    React.useState<boolean>(false);

  const [bookingRefValid, setBookingRefValid] = React.useState(false);
  const [locationsValid, setLocationsValid] = React.useState(false);
  const [selectedReasonForHire, setselectedReasonForHire] =
    React.useState<ReservationReasonForHireViewModel>();
  const [additionalInfoData, setAdditionalInfoData] = React.useState<any>();
  const [endDateChangedDirectly, setEndDateChangedDirectly] =
    React.useState(false);
  const [firstTimeSdSet, setFirstTimeSdSet] = React.useState(0);
  const [locationsView, setlocationsView] =
    React.useState<ReservationLocationsViewModel>();
  const [initialLocation, setInitialLocation] =
    React.useState<ReservationLocationsViewModel>();
  // let initialLocation;
  const [startLocInfo, setStartLocInfo] =
    React.useState<ReservationLocInfoViewModel>();
  const [endLocInfo, setEndLocInfo] =
    React.useState<ReservationLocInfoViewModel>();
  const [startMethod, setStartMethod] = React.useState(false);
  const [endMethod, setEndMethod] = React.useState(false);
  const [accountChanged, setAccountChanged] = React.useState(false);
  const [startlocationCountryCode, setStartlocationCountryCode] = React.useState("");
  const previousStartLocationCountryCodeRef = React.useRef("");
  const [formValidation, setFormValidation] = React.useState(
    reservationValidationSchema
  );
  const theme = useTheme();
  const isSmXs = useMediaQuery(theme.breakpoints.down("lg"));
  const locale = authService.getUserLocale();
  const [startDate, setStartDate] = React.useState("");
  const [endDate, setEndDate] = React.useState("");
  const [startTime, setStartTime] = React.useState("0");
  const [endTime, setEndTime] = React.useState("0");
  const titleRef = React.useRef<HTMLDivElement>(null);
  registerLocale("de", de);
  registerLocale("es", es);
  registerLocale("fr", fr);
  const formRef = React.useRef<any>(null);
  let existingRes = useSelector((state: AppState) => state.dailyRental);
  let bookingReffromCache = useSelector(
    (state: AppState) => state.bookingReference
  );
  let isReservationInCache = existingRes && existingRes.reservation;

  const delay = (ms) => new Promise((res) => setTimeout(res, ms));
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [reasonForHireUpdated, setReasonForHireUpdated] = React.useState(false);
  const [isValidCrediCardAccount, setIsValidCrediCardAccount] = React.useState(true);
  const [isDirty, setIsDirty] = React.useState(false);
  const history = useNavigate();
  const localeDateTimeFormat = getIntlDateFormatForLocale(locale);

  const onChangeAdditionalField = (additionalInfo: any, isValid: boolean) => {   
    if (isValid) {
      setBookingRefValid(true);
      let addInfoObject = {
        additionalInformationFields: additionalInfo.additionalInformationFields,
        message: additionalInfo.message,
        errors: additionalInfo.errors,
        warnings: additionalInfo.warnings,
        hasErrors: additionalInfo.hasErrors,
        hasWarnings: additionalInfo.hasWarnings,
      };
      dispatch(updateBookingReferenceDetails(addInfoObject));

      if (formRef.current) {
        formRef.current.setFieldValue("additionalInformation", addInfoObject);
        formRef.current.setFieldTouched("additionalInformation", true);
      }
    } else setBookingRefValid(false);
  };

  const isValidationRequiredForAdditionalInformationFields = (addInfoFilds) => {
    let validationRequired = false;
    for (var i = 0; i < addInfoFilds.length; i++) {
      if (addInfoFilds[i].mandatory) {
        validationRequired = true;
        break;
      }
    }
    return validationRequired;
  };
  const getAdditionalInformation = async (accountid) => {  
    await axiosInstance
      .get(`reservationwizard/additioninformation/${accountid}`)
      .then((response) => {
        let addInfoReceived = response.data.data;
        console.log("Additional Info data: ", addInfoReceived);
        setAdditionalInfoData(addInfoReceived);
        setBookingRefValid(
          !isValidationRequiredForAdditionalInformationFields(
            addInfoReceived.additionalInformationFields
          )
        );
        dispatch(updateBookingReferenceDetails(addInfoReceived));

        if (formRef.current) {
          formRef.current.setFieldValue(
            "additionalInformation",
            addInfoReceived
          );
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
        appInsights.trackException({
          exception: error,
          properties: {
            method: "getAdditionalInformation",
            Component: "DR Reservation details",
          },
          severityLevel: SeverityLevel.Error,
        });
      });
  };
  function isNullOrWhitespace(input) {
    return !input || !input.trim();
  }

  const getReservationDetails = async (id, restype) => {
    setLoading(true);
    console.log("ReservationMode: ", resMode, existingRes.reservation);
    if (!isReservationInCache) {
      dispatch(clearAlertMessage());
      let url = `reservationwizard/${
        resMode === ReservationMode.Edit
          ? "getmodifydetails"
          : resMode === ReservationMode.Rebook
          ? "getrebookdetails"
          : "reservation"
      }/${id}`;
      await axiosInstance
        .get(url)
        .then((response) => {
          console.log("Res data: ", response.data.data);
          appInsights.trackTrace({
            message: "getting reservation details",
            properties: {
              reservation: response.data.data,
              Component: "Daily Rental",
              userId: authService.getUserName(),
              companyId: authService.getUserCompanyInternalId(),
            },
            severityLevel: SeverityLevel.Information,
          });
          let reservationDetails = response.data.data;

          setStartTime(
            reservationDetails.dateTime.startTime === null
              ? "12:00:00"
              : getTimeValue(reservationDetails.dateTime.startDateTime, 0)
          );
          setEndTime(
            reservationDetails.dateTime.endTime === null
              ? "12:00:00"
              : getTimeValue(reservationDetails.dateTime.endDateTime, 1)
          );

          setjourneyProfile(reservationDetails.journeyViewModel);
          if (
            resMode === ReservationMode.Edit ||
            resMode === ReservationMode.Rebook
          ) {
            setselectedReasonForHire(reservationDetails.reasonForHire);
              if (reservationDetails?.reasonForHire?.hasErrors){
                if (resMode === ReservationMode.Edit){
              dispatch(showErrorAlertMessage(reservationDetails?.reasonForHire?.errors[0]));
                } 
                else if (resMode === ReservationMode.Rebook){
                  dispatch(showErrorAlertMessage(reservationDetails?.reasonForHire?.errors[1]));
                }
              dispatch(broadcastAnnouncement(t("UnexpectedError")));
              }
            else {
              setlocationsView(reservationDetails.locations);
              console.log(
                "Setting Additional Information: ",
                reservationDetails.additionalInformation
              );
              setBookingRefValid(true);
              setAdditionalInfoData(reservationDetails.additionalInformation);
              // for edit mode the setLocationValid function is being called as a prop in JourneyStartEndMethods
              dispatch(
                updateBookingReferenceDetails(
                  reservationDetails.additionalInformation
                )
              );
            }
           

          }
          if (titleRef.current !== null) titleRef.current.focus();

          if (
            reservationDetails.warnings &&
            reservationDetails.warnings.length > 0
          ) {
            dispatch(showInfoAlertMessage(reservationDetails.warnings));
            dispatch(broadcastAnnouncement(reservationDetails.warnings));
            window.scrollTo(0, 0);
          }         
          setResData(reservationDetails);
          setLoading(false);
        })
        .catch((error) => {
          console.error("There was an error!", error);
          appInsights.trackException({
            exception: error,
            properties: {
              method: "reservationdetails",
              Component: "DR Reservation details",
            },
            severityLevel: SeverityLevel.Error,
          });
        });
    } else {
      if (formRef.current) {
        formRef.current.resetForm();
      }
      if (existingRes !== null && existingRes.reservation != null) {
        setIsDirty(false);
        setResData(existingRes?.reservation);
        setStartTime(
          getTimeValue(existingRes.reservation.dateTime.startDateTime, 0)
        );
        setEndTime(
          getTimeValue(existingRes.reservation.dateTime.endDateTime, 1)
        );
        console.log("existingRes.reservation", existingRes.reservation);
        var rfh = JSON.parse(
          JSON.stringify(existingRes.reservation.reasonForHire)
        );
        rfh.isSelectedAccountValid = true;
        setselectedReasonForHire(rfh);
        setjourneyProfile(existingRes.reservation.journeyViewModel);
        const response2 = Object.assign({}, existingRes.reservation.locations);
        setInitialLocation(response2);
        setlocationsView(existingRes.reservation.locations);
        
        setAdditionalInfoData(existingRes.reservation.additionalInformation);
        setBookingRefValid(true);
        console.log("Existing Reservation details: ", existingRes.reservation);
        await delay(500);
        setLoading(false);

        if (titleRef.current !== null) titleRef.current.focus();      
      }
    }
  };

  useEffect(() => {  
    if (titleRef.current !== null) titleRef.current.focus();

    setIsDirty(
      !(resMode === ReservationMode.Edit || resMode === ReservationMode.Rebook)
    );

    const fetchData = async () => {
      await getReservationDetails(props.journeyUId, resMode);
    };

    // call the function
    fetchData()
      // make sure to catch any error
      .catch(console.error);
  }, []);

  useEffect(() => {    
    return () => {
      dispatch(clearAlertMessage());
    };
  }, []);
  

useEffect(() => { 
  if (
    resMode === ReservationMode.New &&
    existingRes.reservation === undefined || accountChanged
  ) {
    displayAccountSpecificViews();
  }
  if (
    formRef.current &&
    selectedReasonForHire &&
    (existingRes.reservation === undefined || accountChanged)
  ) {
    setIsValidCrediCardAccount(true);
    // Update form values when selectedReasonForHire changes
    formRef.current.setFormikState((prevState) => {
      const newValues = {
        ...prevState.values,
        reasonForHire: {
          ...prevState.values.reasonForHire,
          accountUId: selectedReasonForHire.accountUId,
          accountName: selectedReasonForHire.accountName,
          accountNumber: selectedReasonForHire.accountNumber,
          isSelectedAccountValid: selectedReasonForHire.isSelectedAccountValid,
          brands: selectedReasonForHire.brands,
          isCreditCard: selectedReasonForHire.isCreditCard,
          isPayAtCounter: selectedReasonForHire.isPayAtCounter,
        },
        isAccountValidated: true,
      };
      return { ...prevState, values: newValues };
    });
    // Set the flag to true after updating the values
    setReasonForHireUpdated(true);
  }
}, [selectedReasonForHire, accountChanged]);

const areDatesEqual = (dateTime, date) => {
  if (!date) return true;
  if(!dateTime) return false; 
  const dateFromDateTime = dateTime.split("T")[0];
  return dateFromDateTime === date; 
}

useEffect(() => { 
  // Trigger the logic only when values are updated
  if (reasonForHireUpdated) {
    
    const handleAccountChangeLogic = async () => {

      
      const updatedValues = formRef.current?.values;
      let actualStartDate = updatedValues?.dateTime?.startDateTime;
      let actualEndDate = updatedValues?.dateTime?.endDateTime;
     
      if(actualStartDate!=null || startDate!=null){
        if(!areDatesEqual(actualStartDate,startDate)){
          actualStartDate = startDate+"T12:00:00";
        }
      }
      if(actualEndDate!=null || endDate!=null){
      if(!areDatesEqual(actualEndDate,endDate)){
        actualEndDate = endDate+"T12:00:00";
      }
      }
      console.log("isPayAtCounter 1st useeffect",updatedValues?.reasonForHire?.isPayAtCounter);
      if(updatedValues?.reasonForHire?.isPayAtCounter === false){
        if(updatedValues?.reasonForHire?.isCreditCard &&
          updatedValues?.isCreditCardToggleEnabled && selectedReasonForHire ){
        dispatch(clearAlertMessage());      
        let isWith28Days = true;
        if(actualStartDate && actualEndDate ){
          isWith28Days = isWithin28Days(actualStartDate, actualEndDate);
        }
        let isWith12Months = true;
        let isWith12MonthsEditScenario = true;
        if(actualStartDate && actualEndDate ){
          isWith12Months = isWithin12Months(actualEndDate);
        }
  
        if(actualStartDate && actualEndDate && resdata?.createdDateTimeUtc && resdata?.createdDateTimeUtc != ""){
          console.log("resdata?.createdDateTimeUtc",resdata?.createdDateTimeUtc)
          isWith12MonthsEditScenario = isWithin12MonthsEditScenario(actualEndDate,resdata.createdDateTimeUtc);
          console.log("isWith12MonthsEditScenario",isWith12MonthsEditScenario)
        }
          const isStartLocaiontInUSorCanada = isInUSAorCanada(updatedValues?.journeyViewModel?.startLocation?.location);
          
          if (updatedValues?.reasonForHire?.accountNumber) {
            if (updatedValues.reasonForHire.isCreditCard && additionalInfoData) { 
              setIsValidCrediCardAccount(true);  
              setIsDatesValid(true);  
              const accountHasEtdResId = await checkAcccountHasEtdResId();
              if (!accountHasEtdResId) {
                dispatch(showErrorAlertMessage(t("journeyMethod.etdResIdError")));
                window.scrollTo({ top: 0, behavior: 'smooth' });
                setIsValidCrediCardAccount(false);
              } 
              else if (resMode === ReservationMode.New) {
                if(!isWith28Days || !isWith12Months ||isStartLocaiontInUSorCanada ){
                  setIsValidCrediCardAccount(false);
                  dispatch(showErrorAlertMessage(t("journeyMethod.creditCardPayWarning")));
                  window.scrollTo({ top: 0, behavior: 'smooth' });
                }
              } 
              if(resMode === ReservationMode.Rebook){
                if(!isWith28Days){
                  setIsDatesValid(false);  
                  dispatch(showErrorAlertMessage(t("Res.rentalExceeds28Days")));
                  window.scrollTo({ top: 0, behavior: 'smooth' });
                }
                if(!isWith12Months){
                  setIsDatesValid(false);
                  dispatch(showErrorAlertMessage(t("journeyMethod.within12MonthsError")));
                  window.scrollTo({ top: 0, behavior: 'smooth' });
                }
                if(isStartLocaiontInUSorCanada){
                  setIsValidCrediCardAccount(false);
                  dispatch(showErrorAlertMessage(t("journeyMethod.creditCardPayWarning")));
                  window.scrollTo({ top: 0, behavior: 'smooth' });
                }
              }
  
              if(resMode === ReservationMode.Edit){
                if(!isWith28Days){
                  setIsDatesValid(false);  
                  dispatch(showErrorAlertMessage(t("Res.rentalExceeds28Days")));
                  window.scrollTo({ top: 0, behavior: 'smooth' });
                }
                if(!isWith12MonthsEditScenario){
                  setIsDatesValid(false);
                  dispatch(showErrorAlertMessage(t("journeyMethod.within12MonthsError")));
                  window.scrollTo({ top: 0, behavior: 'smooth' });
                }
                if(isStartLocaiontInUSorCanada){
                  setIsValidCrediCardAccount(false);
                  dispatch(showErrorAlertMessage(t("journeyMethod.creditCardPayWarning")));
                  window.scrollTo({ top: 0, behavior: 'smooth' });
                }
              }
              displayAccountSpecificViews();
            }
        }
       } 
      }
      else{
        updatedValues.reasonForHire["isCreditCard"] = false;
      }
    };
    handleAccountChangeLogic();
    setReasonForHireUpdated(false);
  }
}, [additionalInfoData]);


useEffect(()=>{
  const state = store.getState();
  if(state.alertMessage?.message?.includes(t("journeyMethod.creditCardPayWarning"))){
    setIsValidCrediCardAccount(false);
  }
})

useEffect(() => {  
  const handleDateandLocationChangeLogic = async (actualStartDate,actualEndDate) => {
  
  const isPayAtCounter= formRef?.current?.values?.reasonForHire?.isPayAtCounter;
  console.log("previousStartLocationCountryCodeRef",previousStartLocationCountryCodeRef.current);
  console.log("startLocationCountryCode",startlocationCountryCode);
  console.log("actualStartDate",actualStartDate);
  console.log("actualEndDate",actualEndDate);
  console.log("isPayAtCounter",isPayAtCounter);
  console.log("selectedReasonForHire",selectedReasonForHire)
  console.log("isPayAtCounter",isPayAtCounter)
  
  setIsDatesValid(true);
  setIsValidCrediCardAccount(true);
  dispatch(clearAlertMessage());  
  if(isPayAtCounter === false ){
    if(formRef?.current?.values?.isCreditCardToggleEnabled && formRef?.current?.values?.reasonForHire?.isCreditCard && selectedReasonForHire && startlocationCountryCode){
      let isWith12Months = true;
      let isWith12MonthsEditScenario = true;
      if(actualStartDate && actualEndDate){
        isWith12Months = isWithin12Months(actualEndDate);
      }
      let isWith28Days = true;
      if(actualStartDate && actualEndDate){
        isWith28Days = isWithin28Days(actualStartDate, actualEndDate);
      }
  
      if(actualStartDate && actualEndDate && resdata?.createdDateTimeUtc && resdata?.createdDateTimeUtc != ""){
        console.log("resdata?.createdDateTimeUtc",resdata?.createdDateTimeUtc)
        isWith12MonthsEditScenario = isWithin12MonthsEditScenario(actualEndDate,resdata.createdDateTimeUtc);
        console.log("isWith12MonthsEditScenario",isWith12MonthsEditScenario)
      }
      const isInUSAorCanada = await isInUSAorCanadaCountryCode(startlocationCountryCode);
  
     if(resMode === ReservationMode.New){
      if(!isWith12Months || !isWith28Days || isInUSAorCanada)
      {
        setIsValidCrediCardAccount(false);
        formRef.current.setFieldValue("reasonForHire.accountUId","")
        setselectedReasonForHire(undefined);
        dispatch(showErrorAlertMessage(t("journeyMethod.creditCardPayWarning")));
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    }
      if(resMode === ReservationMode.Rebook){
        if(!isWith12Months){
          setIsDatesValid(false);
          dispatch(showErrorAlertMessage(t("journeyMethod.within12MonthsError")));  
          window.scrollTo({ top: 0, behavior: 'smooth' });    
          return;
        }
          if(!isWith28Days){
            setIsDatesValid(false);
            dispatch(showErrorAlertMessage(t("Res.rentalExceeds28Days")));
            window.scrollTo({ top: 0, behavior: 'smooth' });
            return;
          }   
          if(isInUSAorCanada){
            setIsValidCrediCardAccount(false);
            dispatch(showErrorAlertMessage(t("journeyMethod.creditCardPayWarning")));
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }
      }
  
      
      if(resMode === ReservationMode.Edit){
        if(!isWith12MonthsEditScenario){
          setIsDatesValid(false);
          dispatch(showErrorAlertMessage(t("journeyMethod.within12MonthsError")));  
          window.scrollTo({ top: 0, behavior: 'smooth' });    
          return;
        }
          if(!isWith28Days){
            setIsDatesValid(false);
            dispatch(showErrorAlertMessage(t("Res.rentalExceeds28Days")));
            window.scrollTo({ top: 0, behavior: 'smooth' });
            return;
          }   
          if(isInUSAorCanada){
            setIsValidCrediCardAccount(false);
            dispatch(showErrorAlertMessage(t("journeyMethod.creditCardPayWarning")));
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }
      }
   }
  }  
  else{
    formRef.current.values.reasonForHire["isCreditCard"] = false;
  }
 
  }
  if (previousStartLocationCountryCodeRef.current !== "") {
    
    const currentStartDate = formRef?.current?.values?.dateTime?.startDateTime;
    let actualStartDate = currentStartDate;
    console.log(currentStartDate,"currentStartDate")
    console.log(startDate,"startDate")
    if(currentStartDate !=null || startDate != null){
      if(!areDatesEqual(currentStartDate,startDate)){
        actualStartDate = startDate+"T12:00:00";
      }
    }
    const currentEndDate = formRef?.current?.values?.dateTime?.endDateTime;
    console.log(currentEndDate,"currentEndDate")
    let actualEndDate = currentEndDate;
    if(currentEndDate !=null || endDate != null){
    if(!areDatesEqual(currentEndDate,endDate)){
      actualEndDate = endDate+"T12:00:00";
    }
  }
    handleDateandLocationChangeLogic(actualStartDate,actualEndDate);
  }
// Update the previous value to the current value
previousStartLocationCountryCodeRef.current = startlocationCountryCode;
//formRef.current.values.
}, [ startDate, endDate, startlocationCountryCode])

  const validateAccount = async (accountUId, callAdditionalInformationApi) => {
    dispatch(clearAlertMessage());
    if (accountUId !== "") {
      setValidatingAccount(true);
      dispatch(
        broadcastAnnouncement(t("journeyMethod.validatingAccountMessage"))
      );
      await axiosInstance
        .get(`reservation/validateaccount/${accountUId}`)
        .then((response) => {
          console.log("validated response",response.data);
          setselectedReasonForHire(response.data);
          setValidatingAccount(false);
          if (response.data.accountUId) {
            console.log("AccountId: ", response.data.accountUId);
            getAdditionalInformation(response.data.accountUId);
          }
        })
        .catch((error) => {
          var errorlist: string[] = [];
          setValidatingAccount(false);
          if (error.response && error.response.data.messages) {
            error.response.data.messages.forEach((element) => {
              if (element === "ERR20074")
                errorlist.push(t("journeyMethod." + element));
              else errorlist.push(element);
            });
            dispatch(showErrorAlertMessage(errorlist));
            dispatch(broadcastAnnouncement(errorlist.join(",")));
          }
          console.error("There was an error!", error);
          appInsights.trackException({
            exception: error,
            properties: {
              method: "validateAccount",
              Component: "DR Reservation details",
            },
            severityLevel: SeverityLevel.Error,
          });
        });
    } else {
      setselectedReasonForHire(undefined);
    }
  };

  const checkAcccountHasEtdResId = async () => {
    const additionalFieldsHasEtdResId = additionalInfoData.additionalInformationFields.some(item => item.fieldName.toLowerCase()==="etd_res_id");
    return additionalFieldsHasEtdResId;
  };


  function validateDate() {
    const startDt = moment.isDate(
      new Date(formRef.current.values.dateTime.startDateTime)
    );
    const endDt = moment.isDate(
      new Date(formRef.current.values.dateTime.endDateTime)
    );
    if (startTime !== "0" && endTime !== "0") {
      if (startDt && endDt) return true;
    }
    return false;
  }

  function convertToDate(dt: any) {
    if (dt === null || dt === "") return null;
    if (dt && typeof dt === "string" && dt.includes("-")) {
      let dateParts = dt.split("-");
      return new Date(+dateParts[0], Number(dateParts[1]) - 1, +dateParts[2]);
    }
    return moment(dt).format("YYYY-MM-DD");
  }

  const displayAccountSpecificViews = async () => {
    if (selectedReasonForHire) {
      setLoadingAccount(true);
      setStartMethod(false);
      setEndMethod(false);
      setStartLocInfo(undefined);
      setEndLocInfo(undefined);
      setLocationsValid(false);
      dispatch(broadcastAnnouncement(t("journeyMethod.loadingJourneyMethods")));
      await axiosInstance
        .get(
          `reservation/locations/` +
            resdata.locationsURL +
            `/${selectedReasonForHire?.accountUId}/${selectedReasonForHire?.brands}/${props.journeyUId}`
        )
        .then((response) => {
          const response2 = Object.assign({}, response.data);
          console.log("locationView: ", response.data);
          setInitialLocation(response2);
          setlocationsView(response.data);
          setLoadingAccount(false);
          dispatch(
            broadcastAnnouncement(t("journeyMethod.loadedJourneyMethods"))
          );
        })
        .catch((error) => {
          dispatch(showErrorAlertMessage(t("UnexpectedError")));
          dispatch(broadcastAnnouncement(t("UnexpectedError")));
          console.error("There was an error!", error);
          setLoadingAccount(false);
        });
    }
  };

  const convertArrayToDictionary = (inputArray) => {
    let dicObject = {};
    if (inputArray && inputArray.length > 0) {
      for (var i = 0; i < inputArray.length; i++) {
        dicObject[inputArray[i].brand] = inputArray[i].value;
      }
    }
    return dicObject;
  };
  const updateLocationModel = (
    startLoc: ReservationLocInfoViewModel,
    endLoc: ReservationLocInfoViewModel
  ) => {
    setLoading(true);
    setIsValidCrediCardAccount(true);
    if(formRef?.current?.values?.isCreditCardToggleEnabled
      && formRef?.current?.values?.reasonForHire?.isCreditCard 
      && selectedReasonForHire
      && formRef?.current?.values?.reasonForHire?.isPayAtCounter === false){
       if((startLoc?.PeopleSoftIds.length === 1 && startLoc?.PeopleSoftIds?.[0]?.brand === "ZL")||(endLoc?.PeopleSoftIds.length === 1 && endLoc?.PeopleSoftIds?.[0]?.brand === "ZL")){
         dispatch(clearAlertMessage());
         setIsValidCrediCardAccount(false);
         dispatch(showErrorAlertMessage(t("journeyMethod.creditCardPayWarning")));
         window.scrollTo({ top: 0, behavior: 'smooth' });
       }
   }
    let model = {
      journeyProfileId: props.journeyUId ?? "",
      accountId: selectedReasonForHire?.accountUId ?? "",
      startLocation: {
        Name: startLoc.Name ?? "",
        Latitude: startLoc.Latitude,
        Longitude: startLoc.Longitude,
        StationIds: convertArrayToDictionary(startLoc.StationIds),
        PeopleSoftIds: convertArrayToDictionary(startLoc.PeopleSoftIds),
      },
      endLocation: {
        Name: endLoc.Name,
        Latitude: endLoc.Latitude,
        Longitude: endLoc.Longitude,
        StationIds: convertArrayToDictionary(endLoc.StationIds),
        PeopleSoftIds: convertArrayToDictionary(endLoc.PeopleSoftIds),
      },
      startDateTime: moment(
        formRef.current.values.dateTime.startDateTime
      ).format("YYYY-MM-DD[T]HH:mm:SS"),
      endDateTime: moment(formRef.current.values.dateTime.endDateTime).format(
        "YYYY-MM-DD[T]HH:mm:SS"
      ),
    };
    console.log("reasonForHireAndLocationViewModel: ", model);
    setStartLocInfo(startLoc);
    setEndLocInfo(endLoc);
    console.log("Locations Update:", startLocInfo, endLocInfo);
    setLocationsValid(true);
    dispatch(updateAccountLocationDetails(model));
    setLoading(false);
  };

  const getTimeValue = (dt, fieldType) => {
    let result = "0";
    if (dt) {
      let date = new Date(dt);
      var hours = ("0" + date.getHours()).slice(-2);
      var mins = ("0" + date.getMinutes()).slice(-2);
      var secs = "00";
      result = hours + ":" + mins + ":" + secs;
    } else result = startTime;

    if (fieldType === 0) setStartTime(result);
    else setEndTime(result);
    return result;
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={resdata}
      validateOnChange={true}
      validateOnBlur={false}
      validationSchema={formValidation}
      innerRef={formRef}
      onSubmit={async (values, { setFieldTouched, setSubmitting }) => {
        console.log(
          "submitted values for locaddinfo validation (locationForm): ",
          values,
          bookingReffromCache
        );
        let model = { ...values, additionalInformation: bookingReffromCache };
        dispatch(clearAlertMessage());

        await axiosInstance
          .post("/dailyrental/validateLocAddInfo", model)
          .then((res) => {
            setSubmitting(false);
            console.log("validation result: ", res.data.data);
            if (res.data.success && res.data.data) {
              const updatedRes =
                resMode === ReservationMode.Edit
                  ? values
                  : { ...values, carClass: undefined };
              dispatch(
                updateReservation({
                  reservation: updatedRes,
                  hasError: false,
                  errors: [],
                })
              );
              if (props.handleNext) props.handleNext(0, 0);
            } else {
              console.log("Validation Errors: ", res.data.errors);
              let errorlist: string[] = [];
              if (Object.keys(res.data.errors).length > 0) {
                for (const [key, value] of Object.entries(res.data.errors)) {
                  if (
                    key === "unexpectedError" &&
                    Object.keys(res.data.errors).length > 1
                  )
                    // remove the exception error if there are more than 1 errors in the list
                    continue;
                  else errorlist.push(value as string);
                  
                }
                dispatch(showErrorAlertMessage(errorlist));
                dispatch(broadcastAnnouncement(errorlist.join(". ")));
              }
            }
          })
          .catch((err) => {
            setSubmitting(false);
            if (err.response && err.response.data.messages) {
              const translatedErrorlist = err.response.data.messages;
              dispatch(showErrorAlertMessage(translatedErrorlist));
              dispatch(broadcastAnnouncement(translatedErrorlist));
            } else {
              dispatch(
                showErrorAlertMessage(
                  "unable to validate journey details." + err
                )
              );
              dispatch(
                broadcastAnnouncement(
                  "unable to validate journey details." + err
                )
              );
            }

            appInsights.trackException({
              exception: err,
              properties: { method: "onSubmit", Component: "DR Reservation" },
              severityLevel: SeverityLevel.Error,
            });
          });
        window.scrollTo(0, 0);
      }}
    >
      {({
        values,
        isValid,
        dirty,
        setFieldValue,
        setFieldTouched,
        touched,
        handleChange,
        errors,
        isSubmitting,
      }) => 
          <Box
            boxShadow={3}
            p={3}
            bgcolor="background.paper"
            style={{ width: "100%" }}
          >
            {loading && resdata ? (
              <div style={{ textAlign: "center", fontWeight: "bold" }}>
                {t("pleaseWait")} <br />
                <CircularProgress />
              </div>
            ) : (
              <Grid container>
                <div ref={titleRef} tabIndex={-1} className={classes.focused}>
                  <Typography
                    variant="h1"
                    style={{
                      marginTop: 0,
                      marginBottom: "15px",
                    }}
                  >
                    {t("ReservationWizard.reservationPageTitle")}
                  </Typography>
                </div>
                <Backdrop className={classes.backdrop} open={validatingAccount}>
                  <Typography variant="h1">
                    {t("journeyMethod.validatingAccountMessage")}
                  </Typography>
                </Backdrop>
                {/* need to refine */}
                <Backdrop
                  className={classes.backdrop}
                  open={loading || loadingAccount || isSubmitting}
                >
                  <CircularProgress />
                </Backdrop>
                <CompactJourneyDetailsView
                  journeyUId={"00-00"}
                  journeyDetails={props.journeyDetails}
                />
                <Grid item xs={12} style={{ marginTop: 10, color: "#AB0808" }}>
                  <Form style={{ width: "100%" }}>
                    <span style={{ fontWeight: "bold", color: "#000000" }}>
                      {t("lblRequiedFields")}
                    </span>
                    {resMode === ReservationMode.Edit && (
                      <Accordion
                        style={{ width: "100%" }}
                        defaultExpanded
                        id="sectionResNumber"
                      >
                        <h2>
                          <AccordionSummary
                            expandIcon={
                              <ExpandMoreIcon sx={{ color: "#FFFFFF" }} />
                            }
                            aria-controls="panel-resnumber"
                            id="resNumber"
                            className={classes.heading}
                          >
                            {t(
                              "ReservationWizard.reservationPage.labelSectionResNumber"
                            )}
                          </AccordionSummary>
                        </h2>
                        <AccordionDetails>
                          <Grid container>
                            <Grid container direction="row">
                              <Grid
                                item
                                xs={12}
                                md={3}
                                className={classes.gridItem}
                              >
                                <label
                                  htmlFor={"resNumber"}
                                  className={classes.labelText}
                                >
                                  {t(
                                    "ReservationWizard.reservationPage.labelResNumber"
                                  )}
                                </label>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                md={9}
                                className={classes.gridItem}
                              >
                                <Field
                                  name="reservationNumber"
                                  id="reservationNumber"
                                  value={values.reservationNumberString}
                                  fullWidth
                                  style={{ paddingBottom: 0, paddingTop: 0 }}
                                  component={TextField}
                                  variant="outlined"
                                  disabled={true}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    )}
                    <Accordion
                      style={{ width: "100%" }}
                      id="sectionStartEndDates"
                      defaultExpanded
                    >
                      <h2>
                        <AccordionSummary
                          expandIcon={
                            <ExpandMoreIcon sx={{ color: "#FFFFFF" }} />
                          }
                          aria-controls="panel-startEndDates"
                          className={classes.heading}
                        >
                          {" "}
                          {t(
                            "ReservationWizard.reservationPage.labelSectionDateTime"
                          )}
                        </AccordionSummary>
                      </h2>
                      {!selectedReasonForHire?.hasErrors && (
                      <AccordionDetails id="panel-startEndDates">
                        <Grid container>
                          <Grid
                            container
                            direction="row"
                            alignContent={"space-between"}
                          >
                            <Grid
                              item
                              xs={12}
                              md={2}
                              className={classes.gridItem}
                            >
                              <label
                                htmlFor={"dateTime.startDate"}
                                className={classes.labelText}
                              >
                                {t(
                                  "ReservationWizard.reservationPage.labelStartDate"
                                )}{" "}
                                *
                              </label>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={3}
                              className={classes.gridItem}
                            >
                              <Field
                                component={EtdDatePickerV2}
                                isPastDateAllowed={true}
                                daysPastDateAllowed={1}
                                label="Start date"
                                name="startDate"
                                id="dateTime.startDateTime"
                                localeDateTimeFormat={localeDateTimeFormat}
                                value={
                                  values.dateTime.startDate
                                    ? getDateObjectFromShortString(
                                        values.dateTime.startDate
                                      )
                                    : null
                                }
                                errorMessageId="dateTime.startDateTime-helper-select"
                                handleChange={(e) => {
                                  setIsDirty(true);
                                  let newValue = e;
                                  let newEndDate: string | null;
                                  const format =
                                    localeDateTimeFormat.toUpperCase();
                                  let parsedDate = moment(newValue, format, true);
                                  let isValidTime = startTime !== "0";
                                  if (parsedDate.isValid()) {
                                    newValue =
                                      moment(parsedDate).format("YYYY-MM-DD");
                                    newEndDate = moment(parsedDate)
                                      .add(1, "days")
                                      .format("YYYY-MM-DD");
                                    // setFieldValue("dateTime.startDate", newValue);
                                  } else {
                                    newValue = null;
                                    newEndDate = null;
                                  }
                                  setStartDate(newValue);
                                  if (
                                    newValue !== null &&
                                    parsedDate.isValid() &&
                                    isValidTime
                                  ) {
                                    const hours = Number(startTime.split(":")[0]);
                                    const minutes = Number(
                                      startTime.split(":")[1]
                                    );
                                    parsedDate.set({
                                      hour: hours,
                                      minute: minutes,
                                    });
  
                                    const dtStart = moment(parsedDate).format(
                                      "YYYY-MM-DD[T]HH:mm:SS"
                                    );
                                    setFieldTouched(
                                      "dateTime.startDateTime",
                                      true
                                    );
                                    setFieldValue(
                                      "dateTime.startDateTime",
                                      dtStart
                                    );
                                  } else {
                                    setFieldTouched(
                                      "dateTime.startDateTime",
                                      true
                                    );
                                    setFieldValue("dateTime.startDateTime", null);
                                  }
                                  if (newValue !== null) {
                                    setFieldTouched("dateTime.startDate", true);
                                    setFieldValue("dateTime.startDate", newValue);
                                  } else {
                                    setFieldTouched("dateTime.startDate", true);
                                    setFieldValue("dateTime.startDate", null);
                                  }
  
                                  if (
                                    !endDateChangedDirectly &&
                                    resMode === ReservationMode.Rebook
                                  ) {
                                    setFirstTimeSdSet(firstTimeSdSet + 1);
                                    setFieldTouched("dateTime.endDate", true);
                                    setFieldValue("dateTime.endDate", newEndDate);
                                    if(newEndDate) setEndDate(newEndDate);  
                                    let dtEnd: string;
                                    if (
                                      newEndDate !== null &&
                                      parsedDate.isValid() &&
                                      isValidTime
                                    ) {
                                      const hours = Number(endTime.split(":")[0]);
                                      const minutes = Number(
                                        endTime.split(":")[1]
                                      );
                                      parsedDate.set({
                                        hour: hours,
                                        minute: minutes,
                                      });
  
                                      dtEnd = moment(parsedDate)
                                        .add(1, "days")
                                        .format("YYYY-MM-DD[T]HH:mm:SS");
  
                                      setTimeout(() => {
                                        setFieldTouched(
                                          "dateTime.endDateTime",
                                          true
                                        );
                                        setFieldValue(
                                          "dateTime.endDateTime",
                                          dtEnd
                                        );
                                      }, 50);
                                    } else {
                                      setFieldTouched(
                                        "dateTime.endDateTime",
                                        true
                                      );
                                      setFieldValue("dateTime.endDateTime", null);
                                    }
                                    setFieldValue(
                                      "dateTime.startTime",
                                      startTime === "0" ? "12:00:00" : startTime
                                    );
                                    setFieldValue(
                                      "dateTime.endTime",
                                      endTime === "0" ? "12:00:00" : endTime
                                    );
                                  }
                                  else{
                                    setTimeout(() => {
                                      setFieldTouched(
                                        "dateTime.startTime",
                                        true
                                      );
                                      setFieldValue(
                                        "dateTime.startTime",
                                        startTime === "0" ? "12:00:00" : startTime
                                      );
                                    }, 50);

                                  }
                                 
                                 
                                }}
                                handleBlur={(e) => {
                                  setFieldTouched("dateTime.startDate", true);
                                }}
                                isError={
                                  touched.dateTime?.startDateTime &&
                                  errors &&
                                  errors.dateTime?.startDate
                                    ? true
                                    : false
                                }
                              />
                              {touched.dateTime?.startDateTime &&
                              errors &&
                              errors.dateTime?.startDate ? (
                                <span
                                  id="dateTime.startDateTime-helper-select"
                                  className={classes.errorWarning}
                                >
                                  {firstTimeSdSet === 1
                                    ? ""
                                    : t(errors.dateTime?.startDate)}
                                </span>
                              ) : (
                                <span id="dateTime.startDateTime-helper-select"></span>
                              )}
                            </Grid>
                            <Grid item xs={12} md={2}></Grid>
                            <Grid
                              item
                              xs={12}
                              md={2}
                              className={classes.gridItem}
                            >
                              <label
                                htmlFor={"dateTime.startTime"}
                                className={classes.labelText}
                              >
                                {t(
                                  "ReservationWizard.reservationPage.labelStartTime"
                                )}{" "}
                                *
                              </label>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={3}
                              className={classes.gridItem}
                            >
                              <Field
                                id="dateTime.startTime"
                                name={"dateTime.startTime"}
                                fieldName={"dateTime.startTime"}
                                component={EtdTimePicker}
                                format={t("TimeFormat")}
                                defaultValue={startTime}
                                errorMessageId={
                                  "dateTime.startTime-helper-select"
                                }
                                onChange={(e) => {
                                  setIsDirty(true);
                                  let time = e.target.value;
  
                                  if (time === "0") {
                                    setFieldValue("dateTime.startDateTime", null);
                                    setFieldTouched(
                                      "dateTime.startDateTime",
                                      true
                                    );
                                  } else {
                                    let parsedDate = moment(
                                      values.dateTime.startDate,
                                      "YYYY-MM-DD",
                                      true
                                    );
                                    if (parsedDate.isValid()) {
                                      const hours = Number(time.split(":")[0]);
                                      const minutes = Number(time.split(":")[1]);
                                      parsedDate.set({
                                        hour: hours,
                                        minute: minutes,
                                      });
  
                                      setFieldValue(
                                        "dateTime.startDateTime",
                                        moment(parsedDate).format(
                                          "YYYY-MM-DD[T]HH:mm:SS"
                                        )
                                      );
                                      setFieldTouched(
                                        "dateTime.startDateTime",
                                        true
                                      );
                                    }
                                  }
                                  setFieldTouched("dateTime.startTime", true);
                                  setFieldValue("dateTime.startTime", time);
                                  setStartTime(time);
                                }}
                              />
                              {touched.dateTime?.startTime &&
                              errors &&
                              errors.dateTime?.startTime ? (
                                <span
                                  id="dateTime.startTime-helper-select"
                                  className={classes.errorWarning}
                                >
                                  {t(errors.dateTime?.startTime)}
                                </span>
                              ) : (
                                <span id="dateTime.startTime-helper-select"></span>
                              )}
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            direction="row"
                            alignContent={"space-between"}
                          >
                            <Grid
                              item
                              xs={12}
                              md={2}
                              className={classes.gridItem}
                            >
                              <label
                                htmlFor={"dateTime.endDate"}
                                className={classes.labelText}
                              >
                                {t(
                                  "ReservationWizard.reservationPage.labelEndDate"
                                )}{" "}
                                *
                              </label>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={3}
                              className={classes.gridItem}
                            >
                              <Field
                                component={EtdDatePickerV2}
                                isPastDateAllowed={true}
                                daysPastDateAllowed={1}
                                label="End date"
                                name="endDate"
                                id="dateTime.endDate"
                                localeDateTimeFormat={localeDateTimeFormat}
                                value={
                                  values.dateTime.endDate
                                    ? getDateObjectFromShortString(
                                        values.dateTime.endDate
                                      )
                                    : null
                                }
                                errorMessageId={"dateTime.endDate-helper-select"}
                                handleChange={(e) => {
                                  setEndDateChangedDirectly(true);
                                  setIsDirty(true);
                                  let newValue = e;
                                  const format =
                                    localeDateTimeFormat.toUpperCase();
                                  let parsedDate = moment(newValue, format, true);
                                  let isValidTime = endTime !== "0";
                                  if (parsedDate.isValid()) {
                                    newValue =
                                      moment(parsedDate).format("YYYY-MM-DD");
                                    //  setFieldValue("dateTime.endDate", newValue);
                                  } else {
                                    newValue = null;
                                    // setFieldValue(
                                    //   "dateTime.endDate",
                                    //   "Invalid Date"
                                    // );
                                  }
  
                                  setEndDate(newValue);
  
                                  if (
                                    newValue !== null &&
                                    parsedDate.isValid() &&
                                    isValidTime
                                  ) {
                                    const hours = Number(endTime.split(":")[0]);
                                    const minutes = Number(endTime.split(":")[1]);
                                    parsedDate.set({
                                      hour: hours,
                                      minute: minutes,
                                    });
  
                                    const dt = moment(parsedDate).format(
                                      "YYYY-MM-DD[T]HH:mm:SS"
                                    );
  
                                    setFieldTouched("dateTime.endDateTime", true);
                                    setFieldValue("dateTime.endDateTime", dt);
                                  } else {
                                    setFieldTouched("dateTime.endDateTime", true);
                                    setFieldValue("dateTime.endDateTime", null);
                                  }
  
                                  if (newValue !== null) {
                                    setFieldTouched("dateTime.endDate", true);
                                    setFieldValue("dateTime.endDate", newValue);
                                  } else {
                                    setFieldTouched("dateTime.endDate", true);
                                    setFieldValue("dateTime.endDate", null);
                                  }
                                }}
                                handleBlur={(e) => {
                                  setFieldTouched("dateTime.endDate", true);
                                }}
                                isError={
                                  touched.dateTime?.endDateTime &&
                                  errors &&
                                  errors.dateTime?.endDate
                                    ? true
                                    : false
                                }
                              />
                              {touched.dateTime?.endDateTime &&
                              errors &&
                              errors.dateTime?.endDate ? (
                                <span
                                  id="dateTime.endDate-helper-select"
                                  className={classes.errorWarning}
                                >
                                  {t(errors.dateTime?.endDate)}
                                </span>
                              ) : (
                                <span id="dateTime.endDate-helper-select"></span>
                              )}
                              {authService.getDriverInfo()
                                .isBOBOToggleEnabledAndIsDriverDeclaration &&
                              errors.dateTime?.endDate?.endsWith(
                                "msgInvalidDriver"
                              ) ? (
                                resMode === ReservationMode.Edit ? (
                                  !touched.dateTime?.endDateTime && (
                                    <span
                                      id="dateTime.endDate-helper-select"
                                      className={classes.errorWarning}
                                    >
                                      {t(
                                        "ReservationWizard.reservationPage.msgInvalidDriver"
                                      )}
                                    </span>
                                  )
                                ) : (
                                  <Grid container justifyContent="center">
                                    <Button
                                      id="btnStartAgain"
                                      color="primary"
                                      variant="contained"
                                      onClick={() => {
                                        history(
                                          "/home?id=" +
                                            resdata.journeyProduct?.uId +
                                            "&isStartAgain=1"
                                        );
                                      }}
                                      type="submit"
                                      style={{
                                        marginTop: "0.5em",
                                        textTransform: "none",
                                      }}
                                    >
                                      {t(
                                        "ReservationWizard.reservationPage.btnStartAgain"
                                      )}
                                    </Button>
                                  </Grid>
                                )
                              ) : (
                                <span></span>
                              )}
                            </Grid>
                            <Grid item xs={12} md={2}></Grid>
                            <Grid
                              item
                              xs={12}
                              md={2}
                              className={classes.gridItem}
                            >
                              <label
                                htmlFor={"dateTime.endTime"}
                                className={classes.labelText}
                              >
                                {t(
                                  "ReservationWizard.reservationPage.labelEndTime"
                                )}{" "}
                                *
                              </label>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={3}
                              className={classes.gridItem}
                            >
                              <Field
                                component={EtdTimePicker}
                                format={t("TimeFormat")}
                                label="End Time"
                                id="dateTime.endTime"
                                name="dateTime.endTime"
                                fieldName="dateTime.endTime"
                                defaultValue={endTime}
                                errorMessageId={"dateTime.endTime-helper-select"}
                                onChange={(e) => {
                                  setIsDirty(true);
                                  let time = e.target.value;
  
                                  if (time === "0") {
                                    setFieldValue("dateTime.endDateTime", null);
                                    setFieldTouched("dateTime.endDateTime", true);
                                  } else {
                                    let parsedDate = moment(
                                      values.dateTime.endDate,
                                      "YYYY-MM-DD",
                                      true
                                    );
                                    if (parsedDate.isValid()) {
                                      const hours = Number(time.split(":")[0]);
                                      const minutes = Number(time.split(":")[1]);
                                      parsedDate.set({
                                        hour: hours,
                                        minute: minutes,
                                      });
                                      setFieldValue(
                                        "dateTime.endDateTime",
                                        moment(parsedDate).format(
                                          "YYYY-MM-DD[T]HH:mm:SS"
                                        )
                                      );
                                      setFieldTouched(
                                        "dateTime.endDateTime",
                                        true
                                      );
                                    }
                                  }
                                  setFieldTouched("dateTime.endTime", true);
                                  setFieldValue("dateTime.endTime", time);
  
                                  setEndTime(time);
                                }}
                                placeholder="End time"
                              />
                              {touched.dateTime?.startTime &&
                              errors &&
                              errors.dateTime?.endTime ? (
                                <span
                                  id="dateTime.endTime-helper-select"
                                  className={classes.errorWarning}
                                >
                                  {t(errors.dateTime?.endTime)}
                                </span>
                              ) : (
                                <span id="dateTime.endTime-helper-select"></span>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                      )}
                    </Accordion>
                    <Accordion
                      style={{ width: "100%" }}
                      defaultExpanded
                      id="sectionAccounts"
                    >
                      <h2>
                        <AccordionSummary
                          expandIcon={
                            <ExpandMoreIcon sx={{ color: "#FFFFFF" }} />
                          }
                          aria-controls="panel-accounts"
                          id="accountSummary"
                          className={classes.heading}
                        >
                          {isNullOrWhitespace(
                            values.reasonForHire.reasonForHireLabel
                          )
                            ? t(
                                "ReservationWizard.reservationPage.labelSectionAccounts"
                              )
                            : values.reasonForHire.reasonForHireLabel}
                        </AccordionSummary>
                      </h2>
                      <AccordionDetails id="panel-accounts">
                        <Grid container>
                          <Grid container direction="row">
                            <Grid
                              item
                              xs={12}
                              md={3}
                              className={classes.gridItem}
                            >
                              <label
                                htmlFor={"reasonForHire"}
                                className={classes.labelText}
                              >
                                {t("Res.DisplayName")} *
                              </label>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={9}
                              className={classes.gridItem}
                            >
                              <Select
                                native
                                data-testid="select-accounts"
                                value={
                                  values.reasonForHire.accountUId
                                    ? values.reasonForHire.accountUId
                                    : ""
                                }
                                fullWidth
                                inputProps={{
                                  "data-testid": "reasonForHire",
                                  id: "reasonForHire",
                                  name: "reasonForHire",
                                  "aria-label": t("Res.DisplayName"),
                                  "aria-haspopup": "listbox",
                                }}
                                title={t("Res.DisplayName")}
                                name="reasonForHire"
                                aria-describedby={"reasonForHireError"}
                                error={
                                  errors["reasonForHire"] !== undefined &&
                                  touched.reasonForHire?.accountUId
                                    ? true
                                    : false
                                }
                                onChange={(e) => {
                                  setIsDirty(true);
                                  setAccountChanged(true);
                                  setFieldValue(
                                    "reasonForHire.accountUId",
                                    e.target.value
                                  )
                                  // setCarClassess(null);
                                  setFieldValue("carClass", null);
                                  validateAccount(e.target.value, true);
                                  
                                  if (existingRes) dispatch(resetReservation());
                                }}
                                onBlur={(e) => {
                                  setFieldTouched("reasonForHire.accountUId");
                                }}
                                disabled={
                                  resMode === ReservationMode.Edit ||
                                  resMode === ReservationMode.Rebook
                                }
                              >
                                {values.reasonForHire &&
                                  values.reasonForHire.availableReasonsForHire &&
                                  values.reasonForHire.availableReasonsForHire?.map(
                                    (opt: any) => (
                                      <option data-testid="options" key={opt.value} value={opt.value}>
                                        {opt.text}
                                      </option>
                                    )
                                  )}
                              </Select>
                              {errors.reasonForHire?.accountUId &&
                              touched.reasonForHire?.accountUId ? (
                                <span
                                  id="reasonForHireError"
                                  className={classes.errorWarning}
                                >
                                  {t(errors?.reasonForHire?.accountUId ?? "")}
                                </span>
                              ) : (
                                <span id="reasonForHireError"></span>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                    {!loadingAccount &&
                      locationsView &&
                      selectedReasonForHire && (
                        <>
                          <Field
                            component={JourneyStartEndMethods}
                            label="Journey Start"
                            name="startLocation"
                            placeholder="Journey Start"
                            locationView={locationsView}
                            startlocationCountryCode = {startlocationCountryCode}
                            reasonForHire={selectedReasonForHire}
                            setStartLocInfo={setStartLocInfo}
                            setEndLocInfo={setEndLocInfo}
                            setStartMethod={setStartMethod}
                            setEndMethod={setEndMethod}
                            startLocInfo={startLocInfo}
                            setIsDirty={setIsDirty}
                            initialLocation={initialLocation}
                            setlocationsView={setlocationsView}
                            setStartlocationCountryCode = {setStartlocationCountryCode}
                            useStartAsEndLocation={
                              journeyprofile?.useStartAsEndLocation
                            }
                            locationDestination={
                              journeyprofile?.allPoints[0].destination
                            }
                            startLocationDestination={
                              journeyprofile?.allPoints[0].destination
                            }
                            endLocationDestination={
                              journeyprofile?.allPoints[
                                journeyprofile?.allPoints.length - 1
                              ].destination
                            }
                            isExistingRes={
                              (existingRes &&
                                existingRes.reservation !== undefined &&
                                existingRes !== null) ||
                              resMode === ReservationMode.Edit ||
                              resMode === ReservationMode.Rebook
                            }
                            isReadOnly={
                              resMode === ReservationMode.Edit ||
                              resMode === ReservationMode.Rebook
                            }
                            journeyUId={props.journeyUId}
                            reservationDate={values.dateTime.startDateTime}
                            reservationStartDate={values.dateTime.startDateTime}
                            reservationEndDate={values.dateTime.endDateTime}
                            isDateChanged={validateDate()}
                            errors={errors.locations}
                            updateCarClass={updateLocationModel}
                            isLocationValid={setLocationsValid}
                            resMode={resMode}
                          />
                        </>
                      )}
                    <Accordion
                      style={{ width: "100%" }}
                      id="sectionbookingRef"
                      defaultExpanded
                    >
                      <h2>
                        <AccordionSummary
                          expandIcon={
                            <ExpandMoreIcon sx={{ color: "#FFFFFF" }} />
                          }
                          aria-controls="bookingRef"
                          id="bookingRef"
                          className={classes.heading}
                        >
                          {t(
                            "ReservationWizard.reservationPage.labelSectionBookingRef"
                          )}
                        </AccordionSummary>
                      </h2>
                      {selectedReasonForHire &&
                        selectedReasonForHire?.accountUId &&
                        additionalInfoData &&
                        additionalInfoData.additionalInformationFields.length >
                          0 && (
                          <>
                            <AccordionDetails>
                              {additionalInfoData && (
                                <div style={{ flexGrow: 1 }}>
                                  <BookingReference
                                    data={additionalInfoData}
                                    onDataChange={onChangeAdditionalField}
                                  ></BookingReference>
                                </div>
                              )}
                            </AccordionDetails>
                          </>
                        )}
                    </Accordion>
                    {selectedReasonForHire &&
                      selectedReasonForHire?.accountUId 
                      && !selectedReasonForHire?.hasErrors && (
                        <>
                          <Accordion
                            style={{ width: "100%" }}
                            id="sectionnotes"
                            defaultExpanded
                          >
                            <h2>
                              <AccordionSummary
                                expandIcon={
                                  <ExpandMoreIcon sx={{ color: "#FFFFFF" }} />
                                }
                                aria-controls="notes"
                                id="notes"
                                className={classes.heading}
                              >
                                {t(
                                  "ReservationWizard.reservationPage.labelSectionNotes"
                                )}
                              </AccordionSummary>
                            </h2>
                            <AccordionDetails>
                              <Grid container direction="row">
                                <Grid
                                  item
                                  xs={12}
                                  md={3}
                                  className={classes.gridItem}
                                >
                                  <label
                                    htmlFor={"notesViewModel.reservationNote"}
                                    className={classes.labelText}
                                  >
                                    {t(
                                      "ReservationWizard.reservationPage.labelNotes"
                                    )}
                                  </label>
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  md={9}
                                  className={classes.gridItem}
                                >
                                  <Field
                                    name="notesViewModel.reservationNote"
                                    id="notesViewModel.reservationNote"
                                    value={
                                      values.notesViewModel.reservationNote ?? ""
                                    }
                                    fullWidth
                                    style={{ paddingBottom: 0, paddingTop: 0 }}
                                    inputProps={{
                                      "aria-label": t(
                                        "Res.Notes"
                                      ),
                                      className: classes.textarea,
                                      "aria-describedby":
                                        "notesViewModel.reservationNote-helper-text",
                                    }}
                                    onChange={(e) =>
                                      setFieldValue(
                                        "notesViewModel.reservationNote",
                                        e.target.value
                                      )
                                    }
                                    component={TextField}
                                    multiline
                                    variant="outlined"
                                    error={
                                      errors["notesViewModel"] !== undefined &&
                                      errors["notesViewModel"][
                                        "reservationNote"
                                      ] !== undefined
                                        ? true
                                        : false
                                    }
                                    placeholder={t(
                                      "ReservationWizard.reservationPage.placeholderNotes"
                                    )}
                                  />
                                  {errors["notesViewModel"] &&
                                  errors["notesViewModel"]["reservationNote"] ? (
                                    <span
                                      id="notesViewModel.reservationNote-helper-text"
                                      className={classes.errorWarning}
                                    >
                                      {
                                        errors["notesViewModel"][
                                          "reservationNote"
                                        ]
                                      }
                                    </span>
                                  ) : (
                                    <span id="notesViewModel.reservationNote-helper-text"></span>
                                  )}
                                </Grid>
                                {values.locations?.startLocation
                                  ?.isAirportLocation && (
                                  <Grid
                                    item
                                    xs={12}
                                    md={3}
                                    className={classes.gridItem}
                                  >
                                    <label
                                      htmlFor={"flightNumber"}
                                      className={classes.labelText}
                                    >
                                      {t(
                                        "ReservationWizard.reservationPage.labelFlightNumber"
                                      )}
                                    </label>
                                  </Grid>
                                )}
                                {values.locations?.startLocation?.airportCity && (
                                  <Grid
                                    item
                                    xs={12}
                                    md={9}
                                    className={classes.gridItem}
                                  >
                                    <Field
                                      name="flightNumberViewModel.flightNumber"
                                      id="flightNumberViewModel.flightNumber"
                                      onChange={(e) => {
                                        setFieldValue(
                                          "flightNumberViewModel.flightNumber",
                                          e.target.value.toUpperCase()
                                        );
  
                                        setFlightNumberHasError(
                                          ValidateFlightNumber(
                                            e.target.value as string
                                          ).isInValid
                                        );
                                      }}
                                      value={
                                        values.flightNumberViewModel
                                          ?.flightNumber ?? ""
                                      }
                                      fullWidth
                                      style={{ paddingBottom: 0, paddingTop: 0 }}
                                      inputProps={{
                                        "aria-describedby": "flightNumber.Error",
                                        "aria-label": t(
                                          "ReservationWizard.reservationPage.labelFlightNumber"
                                        ),
                                      }}
                                      component={TextField}
                                      variant="outlined"
                                      placeholder={t(
                                        "ReservationWizard.reservationPage.placeholderFlightNumber"
                                      )}
                                      error={flightNumberHasError}
                                    />
                                    <span
                                      id="flightNumber.Error"
                                      className={classes.errorWarning}
                                    >
                                      {flightNumberHasError &&
                                        t(
                                          ValidateFlightNumber(
                                            values.flightNumberViewModel
                                              ?.flightNumber ?? ""
                                          ).message
                                        )}
                                    </span>
                                  </Grid>
                                )}
                              </Grid>
                            </AccordionDetails>
                          </Accordion>
                        </>
                      )}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "1em",
                      }}
                    >
                      <Button
                        id="btnBack"
                        color="primary"
                        aria-label={t("lblBackToPreviousStep")}
                        variant="contained"
                        onClick={() => {
                          dispatch(clearAlertMessage());
                          if (props.handleBack) props.handleBack(0, 0);
                        }}
                      >
                        {t("btnBack")}
                      </Button>
                      <Button
                        id="btnSubmit"
                        color="primary"
                        variant="contained"
                        aria-label={t("lblContinueToNextStep")}
                        disabled={
                          !isValid ||
                          !bookingRefValid ||
                          !locationsValid ||
                          !isValidCrediCardAccount||
                          !isDatesValid||
                          flightNumberHasError
                        }
                        type="submit"
                      >
                        {t("btnContinue")}
                      </Button>
                    </div>
                  </Form>
                </Grid>
              </Grid>
            )}
          </Box>
        }
    </Formik>
  );
};

export default LocationForm;
