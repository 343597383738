import React from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import { Theme } from "@mui/material/styles";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Grid, Typography } from "@mui/material";

interface BusyIndicatorProps {
  text?: string;
  isloading: boolean;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#006639",
    },
  })
);

const BusyIndicator: React.FC<BusyIndicatorProps> = (props) => {
  const classes = useStyles();
  const { text, isloading } = props;
  return (
    <Backdrop className={classes.backdrop} open={isloading}>
      <div
        style={{ display: "table" }}
        role="progressbar"
        aria-valuetext={text}
        aria-live="polite"
        aria-busy={isloading}
        aria-label={text}>
        <Typography variant="h2">{text}</Typography>

        <CircularProgress color="primary" />
      </div>
    </Backdrop>
  );
};

export default BusyIndicator;
