import * as React from "react";
import { useRef } from "react";
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import { useTranslation } from "react-i18next";
import { authService } from "services/authService";
import { usePrevious } from "../../controls/hooks/usePrevious";
import {
  Box,
  FormControl,
  FormControlLabel,
  Dialog,
  Backdrop,
  DialogContent,
  Checkbox,
  Button,
  Divider,
  Grid,
  IconButton,
  Typography,
  CircularProgress,
  DialogActions,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import Paper from "@mui/material/Paper";
import EditIcon from "@mui/icons-material/Edit";
import { Person } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import axiosInstance from "helpers/axiosInstance";
import { user } from "./usertype";
import { useDispatch } from "react-redux";
import {
  showSuccessAlertMessage,
  showErrorAlertMessage,
  clearAlertMessage,
} from "../../components/store/actions/shared/alertActions";
import { broadcastAnnouncement } from "../store/actions/shared/announcementActions";
import { useCallback } from "react";
import Autocomplete from '@mui/material/Autocomplete';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "##fff",
    color: theme.palette.common.black,
    fontWeight: "bold",
    height: "60px",
    padding: "12px",
  },
  body: {
    fontSize: 12,
    padding: "8px",
  },
  footer: {
    textAlign: "right",
    justifyContent: "right",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {},
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
    "& .MuiTableCell-root": {
      borderLeft: "1px solid rgba(224,224,224,1)",
    },
  },
  pagination: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
    alignItems: "right",
    padding: "0px",
    "& .Mui-focused": {
      outline: "3px solid #0066FF !important",
      outlineOffset: "3px",
    },
  },
  inactive: { color: "#666666" },
  selectRoot: {
    height: 10,
    display: "table",
  },
  select: {
    height: 6,
    //paddingTop: 0,
    //  paddingBottom:0,
    display: "table-cell",
    verticalAlign: "middle",
  },
  formControl: {
    /*  margin: 1,*/
    minWidth: 60,
  },
  selectEmpty: {
    /*  marginTop: 2,*/
  },
  buttonSize: {
    textAlign: "center",
    padding: 3,
    margin: 0,
    alignItems: "center",
  },
  root: {
    border: "1px solid rgba(0, 102, 57, 0.95);",
  },
  button: { textTransform: "none" },
  backdrop: {
    zIndex: 99999,
    color: "#fff",
  },
});

interface CompanyItem {
  disabled: boolean;
  group: null;
  selected: boolean;
  text: string;
  value: string;
}
export default function Users() {
  const initialCompany: CompanyItem = {
    disabled: false,
    group: null,
    selected: false,
    text: "Please Select a Company",
    value: "-1",
  };
  const [loading, setLoading] = React.useState(false);
  const [users, setusers] = React.useState<user[] | []>([]);
  const [username, setusername] = React.useState("");
  const [lastname, setlastname] = React.useState("");
  const [firstname, setfirstname] = React.useState("");
  const [email, setemail] = React.useState("");
  const [selectedCompanyNumber, setselectedCompanyNumber] =
    React.useState("-1");
  const [includeDeactivated, setincludeDeactivated] = React.useState(false);
  const [isAllUsersSearch, setisAllUsersSearch] = React.useState(false);
  const [isAdminUsersSearch, setisAdminUsersSearch] = React.useState(false);
  const [companyList, setcompanyList] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [totalCount, settotalCount] = React.useState(0);
  const [totalAdminCount, setTotalAdminCount] = React.useState(0);
  const [totalUserCount, setTotalUserCount] = React.useState(0);
  const [userCountforCompanyAdmin, setUserCountforCompanyAdmin] =
    React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [selectedUserforReset, setSelectedUserforReset] = React.useState("");
  const [nextPreviousPageMessage, setNextPreviousPageMessage] =
    React.useState("");
  const isEhiAdmin = authService.isEhiAdmin();
  const isCompanyAdmin = authService.isCompanyAdmin();

  const prevValues = usePrevious({ page, rowsPerPage });

  const { t } = useTranslation();
  let history = useNavigate();
  const classes = useStyles();
  const inputRef = useRef<HTMLInputElement>(null);
  const btnResetPassDlgRef = useRef<HTMLButtonElement>(null);
  const btnSearchRef = useRef<HTMLButtonElement>(null);
  const dispatch = useDispatch();
  const [val, setVal] = React.useState<CompanyItem>(initialCompany);
  const [inputValue, setInputValue] = React.useState(initialCompany.text);
  const handleChangeCompany = (event, value) => {
    setVal(value);
    setselectedCompanyNumber(value.value);
  };

  const handleRowsPerPageChangeClick = (event) => {
    setNextPreviousPageMessage("");
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const handlePageChangeClick = (event, pageno) => {
    if (pageno > page) {
      setNextPreviousPageMessage(t("Users.nextPageLoadedMessage"));
    } else {
      setNextPreviousPageMessage(t("Users.previousPageLoadedMessage"));
    }
    setPage(pageno);
  };

  const getUsers = (searchCriteria) => {
    dispatch(broadcastAnnouncement(""));
    console.log(searchCriteria);
    setLoading(true);
    axiosInstance
      .post("/identity/search", searchCriteria)
      .then((result) => {
        setusers(result.data.data);
        console.log(result.data);
        setTotalAdminCount(result.data.adminCount);
        setTotalUserCount(result.data.userCount);
        settotalCount(result.data.recordsTotal);
        setLoading(false);
        if (result.data.recordsTotal > 0) {
          if (nextPreviousPageMessage === "")
            dispatch(
              broadcastAnnouncement(
                result.data.recordsTotal.toString() +
                  " " +
                  t("Users.userResultFoundMessage")
              )
            );
          else dispatch(broadcastAnnouncement(nextPreviousPageMessage));
        } else dispatch(broadcastAnnouncement(t("Users.nodatamessage")));
      })
      .catch((err) => {
        console.log("An Error has ocured");
        setLoading(false);
      });
  };

  const getCompanies = async () => {
    const result = await axiosInstance.post("/identity/getcompanylist");
    setcompanyList(result.data.items);
  };

  const getTotalUserCount = async () => {
    await axiosInstance
      .get("/identity/usercount")
      .then((result) => {
        setUserCountforCompanyAdmin(result.data.data.totalUsers);
      })
      .catch((err) => {
        console.log("Error in getting usercount");
      });
  };
  const makeRequest = (searchMode) => {
    const searchReq = {
      Username: username,
      LastName: lastname,
      FirstName: firstname,
      EmailAddress: email,
      CompanyInternalNumber: isEhiAdmin
        ? selectedCompanyNumber
        : hasMinSearchCriteria(searchMode)
        ? authService.getUserCompanyInternalId()
        : "-1",

      PageNumber: page + 1,
      PageSize: rowsPerPage,
      IsAllUsersSearch: searchMode === 1 ? true : false,
      IsAdminUsersSearch: searchMode === 2 ? true : false,
      IncludeDeactivatedUsers: includeDeactivated,
      TotalAdminCount: totalAdminCount,
      TotalUserCount: totalUserCount,
    };

    return searchReq;
  };

  function hasMinSearchCriteria(searchMode) {
    if (searchMode === 1 || searchMode === 2) return true;
    return (
      !isEmpty(username) ||
      !isEmpty(lastname) ||
      !isEmpty(firstname) ||
      !isEmpty(email)
    );
  }

  function isEmpty(str) {
    return !str || str.length === 0;
  }

  const handleResetPasswordDialogClose = () => {
    setOpen(false);
  };

  const handleSearchClick = () => {
    setisAllUsersSearch(false);
    setisAdminUsersSearch(false);
    // settotalCount(0);
    setPage(0);
    setNextPreviousPageMessage("");

    getUsers(makeRequest(0));
  };

  const handleShowAllAdminsClick = () => {
    setNextPreviousPageMessage("");
    setisAllUsersSearch(false);
    setisAdminUsersSearch(true);
    setPage(0);
    getUsers(makeRequest(2));
  };

  const handleShowAllUsersClick = () => {
    setNextPreviousPageMessage("");
    setselectedCompanyNumber("18");
    setTotalAdminCount(0);
    setTotalUserCount(0);
    setisAllUsersSearch(true);
    setisAdminUsersSearch(false);
    setPage(0);
    getUsers(makeRequest(1));
  };

  const handleClearClick = () => {
    dispatch(broadcastAnnouncement(""));
    setNextPreviousPageMessage("");
    setusername("");
    setlastname("");
    setfirstname("");
    setemail("");
    setselectedCompanyNumber("-1");
    setisAdminUsersSearch(false);
    setisAllUsersSearch(false);
    setincludeDeactivated(false);
    setPage(0);
    settotalCount(0);
    setusers([]);
    dispatch(
      broadcastAnnouncement(
        t("Users.clearResultMessage") + " " + t("Users.nodatamessage")
      )
    );
  };

  const handleViewClick = (r) => {
    history("/admin/user/viewuser?uid=" + r.username);
  };
  function defaultLabelDisplayedRows({ from, to, count }) {
    const fm = totalCount === 0 ? 0 : page * rowsPerPage + 1;
    const tt = fm + rowsPerPage - 1;
    return `${fm}-${tt > count ? count : tt} ${t("Users.of")} ${
      count !== -1 ? count : `more than ${to}`
    }`;
  }

  const handleResetClick = async () => {
    const model = { username: selectedUserforReset };
    setOpen(false);
    setLoading(true);

    await axiosInstance
      .post("/user/resetpassword", model)
      .then((res) => {
        dispatch(showSuccessAlertMessage(t("Users.ResetPasswordNotification")));
        dispatch(broadcastAnnouncement(t("Users.ResetPasswordNotification")));

        setLoading(false);
      })
      .catch((err) => {
        dispatch(
          showErrorAlertMessage(
            "There was some problem. please contact support team"
          )
        );
        console.log(err);
        setLoading(false);
      });
  };
  const onEnterResetPasswordDialog = () => {
    if (null !== btnResetPassDlgRef.current) {
      btnResetPassDlgRef.current.focus();
    }
  };
  const checkKeyPressListener = useCallback(
    (event) => {
      if (
        event.code === "Enter" ||
        event.code === "NumpadEnter" ||
        event.keyCode === 13
      ) {
        handleSearchClick();
        if (btnSearchRef.current !== null) btnSearchRef.current.focus();
      }
    },
    [
      username,
      lastname,
      firstname,
      email,
      selectedCompanyNumber,
      includeDeactivated,
      isAllUsersSearch,
      isAdminUsersSearch,
      page,
      rowsPerPage,
    ]
  );

  React.useEffect(() => {
    document.title = `Enterprise Rent-A-Car - ${t("Users.pageTitle")}`;
    dispatch(clearAlertMessage());
    setLoading(true);

    if (authService.isCompanyAdmin()) {
      getTotalUserCount();
    } else getCompanies();
    setLoading(false);
  }, []);

  React.useEffect(() => {
    if (prevValues !== undefined) {
      if (prevValues.page !== page || prevValues.rowsPerPage !== rowsPerPage)
        getUsers(
          makeRequest(isAllUsersSearch ? 1 : isAdminUsersSearch ? 2 : 0)
        );
    }

    document.addEventListener("keydown", checkKeyPressListener);
    return () => {
      document.removeEventListener("keydown", checkKeyPressListener);
    };
  }, [checkKeyPressListener, page, rowsPerPage]);

  return (
    <Box
      id="allJourneysResultBox"
      data-testid="users"
      boxShadow={3}
      p={3}
      bgcolor="background.paper"
    >
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress />
      </Backdrop>
      <Dialog
        aria-labelledby="resetPassword-Dialog-title"
        open={open}
        role="dialog"
        TransitionProps={{
          onEnter: onEnterResetPasswordDialog
        }}>
        <DialogContent id="resetPassword-Dialog-title">
          {t("Users.ResetPasswordConfirmationFormat")} {selectedUserforReset}{" "}
          {"?"}
          <Divider
            style={{
              marginTop: 20,
              marginBottom: 10,
              backgroundColor: "black",
              height: "0.8px",
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            type="submit"
            id="okDialogButton"
            ref={btnResetPassDlgRef}
            onClick={() => handleResetClick()}
            style={{ textTransform: "none" }}
          >
            {t("Users.btnOK")}
          </Button>
          <Button
            classes={{ outlinedPrimary: classes.root }}
            color="primary"
            variant="outlined"
            id="cancelDialogButton"
            type="button"
            onClick={handleResetPasswordDialogClose}
            style={{ marginLeft: "0.5em", textTransform: "none" }}
          >
            {t("Users.btnCancel")}
          </Button>
        </DialogActions>
      </Dialog>
      <Grid container>
        <Grid item xs={12} md={12}>
          <Typography id="titleHeader" variant="h1">
            {t("Users.pageTitle")}
          </Typography>
          <br />
          {isCompanyAdmin && (
            <div>
              <span>
                {t("Users.companyUserCount", {
                  totalUserCount: userCountforCompanyAdmin,
                })}
              </span>
            </div>
          )}
        </Grid>
        <Grid container direction="row" style={{ marginTop: "1em" }}>
          <Grid item xs={12} sm={3}>
            <TextField
              id="txtusername"
              inputRef={inputRef}
              size="small"
              margin="dense"
              label={t("Users.username")}
              variant="outlined"
              value={username}
              onChange={(e) => setusername(e.target.value)}
              placeholder={t("Users.usernamePlaceholder")}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              id="txtlastname"
              size="small"
              margin="dense"
              label={t("Users.lastname")}
              variant="outlined"
              value={lastname}
              onChange={(e) => setlastname(e.target.value)}
              placeholder={t("Users.lastnamePlaceholder")}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              id="txtfirstname"
              size="small"
              margin="dense"
              label={t("Users.firstname")}
              variant="outlined"
              value={firstname}
              onChange={(e) => setfirstname(e.target.value)}
              placeholder={t("Users.firstnamePlaceholder")}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              id="txtemail"
              size="small"
              margin="dense"
              label={t("Users.emailaddress")}
              variant="outlined"
              value={email}
              onChange={(e) => setemail(e.target.value)}
              placeholder={t("Users.emailPlaceholder")}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          style={{ marginTop: "1em" }}
        >
          <Grid item xs={12} sm={6}>
            <div>
              <FormControl id="cc" variant="outlined">
                {isEhiAdmin && (
                  <Autocomplete
                    id="ddcompanies"
                    aria-label={t("Users.company")}
                    options={companyList}
                    disableClearable
                    defaultValue={initialCompany}
                    renderInput={(params) => (
                      <TextField
                        //{...input}
                        {...params}
                        label={t("Users.company")}
                        variant="outlined"
                      />
                    )}
                    getOptionLabel={(option: CompanyItem) => option.text}
                    isOptionEqualToValue={(option, value) =>
                      option.text === value.text
                    }
                    style={{ width: "250px" }}
                    value={val}
                    inputValue={inputValue}
                    onChange={handleChangeCompany}
                    onInputChange={(event, newInputValue) => {
                      setInputValue(newInputValue);
                    }}
                  />
                )}
              </FormControl>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            style={{ textAlign: "right", lineHeight: "3em" }}
          >
            <FormControlLabel
              style={{ marginTop: "1em" }}
              control={
                <Checkbox
                  checked={includeDeactivated}
                  name="chbIncludeDeactivated"
                  id="chbIncludeDeactivated"
                  color="primary"
                  onChange={(e) => setincludeDeactivated(e.target.checked)}
                  inputProps={{
                    "aria-label": t("Users.Includedeactivated"),
                  }}
                />
              }
              label={t("Users.Includedeactivated")}
              aria-label={t("Users.Includedeactivated")}
            />
            <Button
              id="btnSearch"
              type="submit"
              style={{ marginRight: "5px", textTransform: "none" }}
              variant="contained"
              color="primary"
              ref={btnSearchRef}
              onClick={handleSearchClick}
            >
              {" "}
              {t("Users.btnSearch")}{" "}
            </Button>
            <Button
              classes={{ outlinedPrimary: classes.root }}
              id="btnClearSearch"
              style={{ marginRight: "5px", textTransform: "none" }}
              variant="outlined"
              color="primary"
              onClick={handleClearClick}
            >
              {" "}
              {t("ClearAll")}{" "}
            </Button>
            <Button
              id="btnAddUser"
              style={{ textTransform: "none" }}
              variant="contained"
              color="primary"
              onClick={() => {
                history("/admin/user/adduser");
              }}
            >
              {" "}
              {t("Users.btnAddUser")}{" "}
            </Button>
          </Grid>
        </Grid>
        <Grid container direction="row" justifyContent="flex-end">
          <Grid
            item
            xs
            style={{
              textAlign: "right",
              marginTop: "1em",
              lineHeight: "3em",
            }}
          >
            <Button
              id="btnShowAllAdmins"
              style={{ marginRight: "5px", textTransform: "none" }}
              variant="contained"
              color="primary"
              onClick={handleShowAllAdminsClick}
            >
              {t("Users.btnShowAllAdmin").toString()}
            </Button>
            {!isEhiAdmin && (
              <Button
                id="btnShowAllUsers"
                style={{ textTransform: "none" }}
                variant="contained"
                color="primary"
                onClick={handleShowAllUsersClick}
              >
                {" "}
                {t("Users.btnShowAllUser").toString()}{" "}
              </Button>
            )}
            {isEhiAdmin && (
              <Button
                id="btnBulkUploadUsers"
                style={{ textTransform: "none" }}
                variant="contained"
                color="primary"
                onClick={() => history("/admin/user/bulkupload")}
              >
                {" "}
                {t("Users.btnBulkUploadUser").toString()}{" "}
              </Button>
            )}
          </Grid>
        </Grid>
        <Grid container item xs={12} md={12}>
          <Grid item xs={12} md={12}>
            <br />
            <Divider style={{ backgroundColor: "black" }} />
            <br />
          </Grid>
        </Grid>
        <Grid container item xs={12} md={12} style={{ display: "flex" }}>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="left"> </StyledTableCell>
                  <StyledTableCell align="left">
                    {t("Users.username")}{" "}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {t("Users.lastname")}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {t("Users.firstname")}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {t("Users.emailaddress")}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {t("Users.usertype")}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {t("Users.actions")}
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {totalCount === 0 ? (
                  <StyledTableRow tabIndex={0}>
                    <TableCell
                      colSpan={7}
                      style={{
                        textAlign: "center",
                        justifyContent: "center",
                        marginTop: "2em",
                      }}
                    >
                      {t("Users.nodatamessage")}
                    </TableCell>
                  </StyledTableRow>
                ) : (
                  (users as user[])?.map((row: user, index: number) => (
                    <StyledTableRow key={index} style={{ height: "30px" }}>
                      <StyledTableCell
                        style={{ width: "30px", textAlign: "center" }}
                      >
                        <Person
                          color={row.deleted ? "error" : "primary"}
                          titleAccess={
                            row.deleted
                              ? t("Users.statusInActive")
                              : t("Users.statusActive")
                          }
                        />
                      </StyledTableCell>
                      <StyledTableCell>{row.username}</StyledTableCell>
                      <StyledTableCell>{row.lastName}</StyledTableCell>
                      <StyledTableCell>{row.firstName}</StyledTableCell>
                      <StyledTableCell>{row.emailAddress}</StyledTableCell>
                      <StyledTableCell>{row.userRole}</StyledTableCell>
                      <StyledTableCell
                        style={{ width: "40px", textAlign: "center" }}
                      >
                        <Box
                          id={"ActionButtons_" + index}
                          flexDirection="row"
                          alignItems="center"
                        >
                          <div title={t("Users.tooltipEditAction")}>
                            <IconButton
                              role="img"
                              id={"editUserButton" + index}
                              aria-label={
                                t("Users.labelEditAction") + " " + row.username
                              }
                              color="primary"
                              size="small"
                              style={{ border: " 1px solid #006639" }}
                              onClick={() => handleViewClick(row)}
                            >
                              <EditIcon />
                            </IconButton>
                          </div>
                        </Box>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={7}>
                    <TablePagination
                      className={classes.pagination}
                      component="div"
                      rowsPerPage={rowsPerPage}
                      rowsPerPageOptions={[10, 20, 50, 100]}
                      count={totalCount}
                      page={page}
                      onPageChange={(e, page) => handlePageChangeClick(e, page)}
                      onRowsPerPageChange={handleRowsPerPageChangeClick}
                      labelDisplayedRows={defaultLabelDisplayedRows}
                      labelRowsPerPage={t("Users.rowsPerPage")}
                      backIconButtonProps={{
                        role: "img",
                      }}
                      nextIconButtonProps={{
                        role: "img",
                      }}
                    />
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
          {!isEhiAdmin && (
            <div style={{ marginTop: "1em" }}>
              <span>{t("Users.AllUsersAlertMessage")}</span>
            </div>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}
