export enum UserRoles {
  superAdmin = "superAdmin",
  ehiAdmin = "EnterpriseAdministrator",
  companyAdmin = "CompanyAdministrator",
  employee = "CompanyEmployee",
}

export const userRoles = {
  admins: [
    String(UserRoles.superAdmin),
    String(UserRoles.ehiAdmin),
    String(UserRoles.companyAdmin),
  ],
  ehiAdmin: [String(UserRoles.ehiAdmin)],
  companyAdmin: [String(UserRoles.companyAdmin)],
  users: [String(UserRoles.employee), String(UserRoles.companyAdmin)],
  all: [
    String(UserRoles.superAdmin),
    String(UserRoles.ehiAdmin),
    String(UserRoles.companyAdmin),
    String(UserRoles.employee),
  ],
};

export enum AuthRoutes {
  home = "/",
  users = "/users",
  companies = "/companies",
}

export enum NonAuthRoutes {
  login = "/",
  legalInformation = "/legalinformation",
  termsOfUse = "/termsofuse",
  unauthorised = "/unauthorised",
  sso = "/sso",
}
