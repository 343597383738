import Slide from "./Slide";
import withStyles from '@mui/styles/withStyles';
import makeStyles from '@mui/styles/makeStyles';
import { CarouselProperties } from "./models";
import React, { useEffect, useReducer } from "react";
import IconButton from "@mui/material/IconButton";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { initialState, reducer } from "./state";
import ProgressBar from "./ProgressBar";
import classNames from "classnames";
import "./carousel.css";
import Tooltip from "@mui/material/Tooltip";
import { useTranslation } from "react-i18next";
import { broadcastAnnouncement } from "components/store/actions/shared/announcementActions";
import { useDispatch } from "react-redux";

export const Carousel = ({
  carouselProperties,
}: {
  carouselProperties: CarouselProperties;
}) => {
  const { title, slideItems, slideDurationMilliseconds } = carouselProperties;

  const [state, dispatch] = useReducer(
    reducer,
    initialState(slideItems.length),
    (state) => state
  );

  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }))(Tooltip);
 
  const LightTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      shape:"rect",
      border:"1px solid #545452",
      // boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }))(Tooltip);

  useEffect(() => {
    if (state.isPlaying) {
      let timeout = setTimeout(() => {
        dispatch({ type: "PROGRESS" });
      }, slideDurationMilliseconds);
      return () => clearTimeout(timeout);
    }
  }, [state.currentIndex, state.isPlaying, slideDurationMilliseconds]);

  useEffect(() => {
    if (state.takeFocus) {
      dispatch({ type: "UNSET_FOCUS" });
    }
  }, [state.takeFocus]);

  const { t } = useTranslation(["translation", "home"]);
  const reduxDispatch = useDispatch();

  return (
    <section data-testid="carousel" aria-label={title} className="Carousel">
      <div
        className={classNames({
          Slides: true,
          Active: state.isPlaying,
        })}
      >
        {slideItems.map((slideItem, index) => {
          return (
            <Slide
              key={index}
              aria-hidden={true}
              slideItem={slideItem}
              isCurrent={index === state.currentIndex}
              isPrevious={
                index ===
                (state.currentIndex - 1 + state.slidesLength) %
                  state.slidesLength
              }
              isNext={index === (state.currentIndex + 1) % state.slidesLength}
              takeFocus={state.takeFocus}
              seq ={index}
            />
          );
        })}
      </div>
      <ProgressBar
        key={state.currentIndex + state.isPlaying}
        time={slideDurationMilliseconds}
        animate={state.isPlaying}
      />
      <div className="CarouselControls">
        <div className="CarouselButtons">
          <LightTooltip  placement="top-start"   
                          title={t("playPlaySlideShow").toString()}
          >
            <span>
            
              <IconButton
                aria-label={t("playPlaySlideShow").toLowerCase()}
                onClick={() => {
                  dispatch({ type: "PLAY" });
                  reduxDispatch(
                    broadcastAnnouncement(t("home:carouselPlaying"))
                  );
                }}
                disabled={state.isPlaying}
                style={{   border: "1px solid white" }}
                size="large">
                <PlayArrowIcon />
              </IconButton>
            </span>
          </LightTooltip>
          <LightTooltip placement="top-start" 
                        title={t("pauseSlideShow").toString()}
          >
            <span>
              <IconButton
                aria-label={t("pauseSlideShow").toLowerCase()}
                onClick={() => {
                  dispatch({ type: "PAUSE" });
                  reduxDispatch(
                    broadcastAnnouncement(t("home:carouselPaused"))
                  );
                }}
                disabled={!state.isPlaying}
                style={{   border: "1px solid white" }}
                size="large">
                <PauseIcon />
              </IconButton>
            </span>
          </LightTooltip>
        </div>
        <div className="CarouselIndicators">
          {slideItems.map((slide, index) => (
            <LightTooltip key={index} title={slide.title}>
              <IconButton
                aria-label={t("sildeAriaLabel1")+" "+(index+1) +" "+ t("sildeAriaLabel2")+" "+ slideItems.length}
                style={{   border: "1px solid white" }}
                onClick={() => {
                  dispatch({ type: "GOTO", index });
                }}
                size="large">
                <FiberManualRecordIcon />
              </IconButton>
            </LightTooltip>
          ))}
        </div>
      </div>
    </section>
  );
};
