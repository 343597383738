import * as Moment from "moment";
import moment from "moment";
import { extendMoment } from "moment-range";

export const minutesBetweenTwoDates = (startDate: Date, endDate: Date) =>
  moment(endDate).diff(moment(startDate), "m");

export const preprendMinutes = (date: Date, minutes: number) =>
  moment(date).subtract(minutes, "m").toDate();

export const appendMinutes = (date: Date, minutes: number) =>
  moment(date).add(minutes, "m").toDate();

export const format = (date: Date, format: string) =>
  moment(date).format(format);

export const isBetweenTwoDates = (
  startDate: Date,
  endDate: Date,
  dateToCheck: Date
) => extendMoment(Moment).range(startDate, endDate).contains(dateToCheck);
