import React, { useEffect } from "react";

import {
  Backdrop,
  Button,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";

import { appInsights } from "../../../../helpers/appInsights";
import { authService } from "../../../../services/authService";
import makeStyles from '@mui/styles/makeStyles';
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import Paper from "@mui/material/Paper";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { broadcastAnnouncement } from "components/store/actions/shared/announcementActions";
import {
  StartEndLocation,
  JourneyProfilerViaPoint,
  JourneyProfile,
} from "./journeyProfileType";
import { SeverityLevel } from "@microsoft/applicationinsights-common";
import { ReservationProps } from "../../reservationType";
import { clearAlertMessage, showErrorAlertMessage } from "components/store/actions/shared/alertActions";

import { useDispatch } from "react-redux";
import axiosInstance from "helpers/axiosInstance";
import { useTranslation } from "react-i18next";
import { isIE } from "react-device-detect";

const initStartEndLocation: StartEndLocation = {
  location: "",
  peopleSoftIds: { brand: "" },
  stationIds: { brand: "" },
  latitude: "",
  longitude: "",
  brand: "",
};

const initJourneyProfile: JourneyProfile = {
  journeyProfilerUId: "",
  journeyProfilerId: -1,
  companyUId: "",
  companyId: 0,
  companyProductUId: "",
  companyProductId: 0,
  description: "",
  startLocation: initStartEndLocation,
  endLocation: initStartEndLocation,
  startDateTime: new Date(),
  clientCurrentDateTime: new Date(),
  endDateTime: new Date(),
  returnToStartLocation: false,
  totalDistance: 0,
  totalDuration: 0,
  modeOfTransportUId: "",
  modeOfTransportId: "",
  modeOfTransport: "",
  registrationNumber: "",
  distanceUnit: 0,
  username: "",
  appsecUserId: 0,
  loggedTimestamp: new Date(),
  deleted: false,
  totalRecords: 0,
  riskAssessmentExists: true,
  isJourneyProfile: false,
  isJourneyUpdated: false,
  referenceNumber: "",
  activeTravelPolicy: false,
  isDailyRentalSingleProduct: false,
  isRebook: false,
  driverName: "",
  status: "",
  isValidJourneyAssessmentStartDate: false,
  endDistance: 0,
  endDuration: 0,
  returnToStartTotalDescription: "",
  returnToStartDistance: 0,
  returnToStartTotalDuration: 0,
  destination: "",
  carClubDriverId: "",
  journeyProfilerViaPoints: [],
  additionalInformation: "",
  isInternationalStartLocation: false,
  isMidpointsEnabled: false,
};

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormHelperText-root": {
      color: "#DF0000",
      fontSize: 12,
      marginLeft: "15px",
    },
    "& ::placeholder": {
      fontStyle: "italic",
      color: "#000",
      opacity: 0.95,
    },
    "& .cke_resizer": {
      borderRightColor: "#000000",
    },
  },
  paper: {
    padding: "6px 6px",
    textAlign: "left",
  },
  outlinedButton: {
    border: "1px solid rgba(0, 102, 57, 0.95);",
  },
  inputRoot: {
    fontSize: 30,
  },
  labelRoot: {
    fontSize: 30,
    color: "red",
    "&$labelFocused": {
      color: "purple",
    },
  },
  errorWarning: {
    color: "#DF0000",
    fontSize: 12,
    marginLeft: "15px",
  },
  backdrop: {
    zIndex: 99999,
    color: "#fff",
  },

  labelFocused: {},

  hover: {
    color: "#ed1212",
    cursor: "pointer",
  },

  endLocationDotColor: {
    "&.MuiTimelineDot-root": { backgroundColor: "#FFA500" },
  },

  oppositeContentSpace: {
    "&.MuiTimelineOppositeContent-root": {
      flex: "0",
      padding: "6px 0px",
      textAlign: "right",
      marginRight: "auto",
    },
  },
  contentSpaceForIE: {
    "&.MuiTimelineContent-root": {
      flex: "1",
      padding: "6px 40px",
    },
  },
  contentSpaceForChrome: {
    "&.MuiTimelineContent-root": {
      flex: "1",
      padding: "6px 16px",
    },
  },

  ConnectorForIE: {
    //backgroundColor: theme.palette.secondary.main,
    position: "relative",
    left: "15px",
    top: "0px",
    padding: "0px 1px",
  },
  ConnectorForChrome: {
    //backgroundColor: theme.palette.secondary.main,
    position: "relative",
    left: "0px",
    top: "0px",
    padding: "0px 1px",
  },
}));
const JourneyDetailsForm: React.FC<ReservationProps> = (props) => {
  const { journeyUId } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [journeyProfile, setJourneyProfile] =
    React.useState<JourneyProfile>(initJourneyProfile);

  const [journeyProfilerViaPoints, setJourneyProfilerViaPoints] =
    React.useState<JourneyProfilerViaPoint[]>([] as JourneyProfilerViaPoint[]);

  appInsights.trackEvent({
    name: "Journey details component",
    properties: { journeyUId: journeyUId },
  });

  useEffect(() => {}, []);

  var language = authService.getUserLocale();

  const fetchJourneyDetails = (journeyUId) => {
    if (journeyUId !== "") {
      axiosInstance
        .get(`/journeyassessment/journeyDetails/${journeyUId}`)
        .then((response) => {
          appInsights.trackEvent({
            name: "Fetch journey details ",
            properties: { journeyUId: journeyUId },
          });

          if (response.data) {
            console.log("Journey Data: ", response.data);
            setJourneyProfile(response.data);
            setJourneyProfilerViaPoints(response.data.journeyProfilerViaPoints);
          } else {
            dispatch(
              showErrorAlertMessage(
                t("No Joruney Details found for journeyUId=" + journeyUId)
              )
            );
            dispatch(
              broadcastAnnouncement(
                t("No Joruney Details found for journeyUId=" + journeyUId)
              )
            );
          }
        })
        .catch((error) => {
          if (
            error.response != null &&
            error.response.status != null &&
            error.response.status === 400
          ) {
            dispatch(showErrorAlertMessage(t("UnexpectedError")));
            dispatch(broadcastAnnouncement(t("UnexpectedError")));
            console.error("There is an error", error);
          } else console.error("There is an error", error);

          appInsights.trackException({
            id: "Fetch Journey Details",
            exception: error,
            severityLevel: SeverityLevel.Error,
          });
        });
    } else {
    }
    setLoading(false);
  };

  const [loading, setLoading] = React.useState(false);

  const timeItem = (
    aPoint: JourneyProfilerViaPoint,
    index: any,
    points: any
  ) => {
    if (index === 0) {
      return (
        <TimelineItem key={index}>
          <TimelineOppositeContent
            className={classes.oppositeContentSpace}></TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot color="primary">
              <LocationOnIcon />
            </TimelineDot>
            <TimelineConnector
              className={
                isIE ? classes.ConnectorForIE : classes.ConnectorForChrome
              }
            />
          </TimelineSeparator>
          <TimelineContent
            className={
              isIE ? classes.contentSpaceForIE : classes.contentSpaceForChrome
            }>
            <Paper
              id={"journeyStepDetailsDiv" + index}
              elevation={3}
              className={classes.paper}>
              <Typography
                id="journeyStepStartDiv"
                style={{ wordBreak: "break-all", wordWrap: "break-word" }}
                variant="body2">
                {aPoint.step}
              </Typography>
              <Typography style={{ fontSize: "0.9em" }} noWrap={true}>
                {points[index + 1].distance === 0 &&
                points[index + 1].time === 0
                  ? ""
                  : language === "en-GB" || language === "en-US"
                  ? (
                    Math.round(points[index + 1].distance * 0.621371 * 100) /
                    100
                    ).toFixed(2) +
                    " miles - " +
                    points[index + 1].time +
                    " mins"
                  : (
                      (
                        Math.round(points[index + 1].distance * 100) / 100
                      ).toFixed(2) + ""
                    ).replace(".", ",") +
                    " km - " +
                    points[index + 1].time +
                    " Min."}
              </Typography>
            </Paper>
          </TimelineContent>
        </TimelineItem>
      );
    } else if (index === points.length - 1) {
      return (
        <TimelineItem key={index}>
          <TimelineOppositeContent className={classes.oppositeContentSpace}>
            {/* <Typography variant="body2" color="textSecondary">
            {aPoint.order}
          </Typography> */}
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot className={classes.endLocationDotColor}>
              <LocationOnIcon />
            </TimelineDot>
          </TimelineSeparator>
          <TimelineContent
            className={
              isIE ? classes.contentSpaceForIE : classes.contentSpaceForChrome
            }>
            <Paper
              id={"journeyStepDetailsDiv" + index}
              elevation={3}
              className={classes.paper}>
              <Typography
                id="journeyStepEndDiv"
                style={{ wordWrap: "break-word", wordBreak: "break-all" }}
                variant="body2">
                {aPoint.step}
              </Typography>
            </Paper>
          </TimelineContent>
        </TimelineItem>
      );
    } else {
      return (
        <TimelineItem key={index}>
          <TimelineOppositeContent className={classes.oppositeContentSpace}>
            {/* <Typography variant="body2" color="textSecondary">
          {aPoint.order}
        </Typography> */}
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot color="primary" variant="outlined">
              <LocationOnIcon />
            </TimelineDot>
            <TimelineConnector
              className={
                isIE ? classes.ConnectorForIE : classes.ConnectorForChrome
              }
            />
          </TimelineSeparator>
          <TimelineContent
            className={
              isIE ? classes.contentSpaceForIE : classes.contentSpaceForChrome
            }>
            <Paper
              id={"journeyStepDetailsDiv" + index}
              elevation={3}
              className={classes.paper}>
              <Typography
                style={{ wordWrap: "break-word", wordBreak: "break-all" }}
                id={"journeyStepMidDiv_" + index}
                variant="body2">
                {aPoint.step}
              </Typography>
              <Typography style={{ fontSize: "0.9em" }} noWrap={true}>
                {language === "en-GB" || language === "en-US"
                  ? (
                      Math.round(points[index + 1].distance * 0.621371 * 100) /
                      100
                    ).toFixed(2) +
                    " miles - " +
                    points[index + 1].time +
                    " mins"
                  : (
                      (
                        Math.round(points[index + 1].distance * 100) / 100
                      ).toFixed(2) + ""
                    ).replace(".", ",") +
                    " km - " +
                    points[index + 1].time +
                    " Min."}
              </Typography>
            </Paper>
          </TimelineContent>
        </TimelineItem>
      );
    }
  };

  return (
    <div style={{ marginTop: "20px" }}>
      <Typography align="center" variant="h2" style={{ fontSize: "1.2rem" }}>
        {t("JourneyDetails.journeyDetails")}
      </Typography>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress />
      </Backdrop>
      <div>
        <Timeline position="left">
          {props.journeyDetails &&
            props.journeyDetails.journeyProfilerViaPoints.map((aPoint, index) =>
              timeItem(
                aPoint,
                index,
                props.journeyDetails.journeyProfilerViaPoints
              )
            )}
        </Timeline>
      </div>

      <Grid container justifyContent="center">
        <Button
          id="BackButton"
          color="primary"
          variant="contained"
          onClick={() => {
            dispatch(clearAlertMessage());
            if (props.handleBack) props.handleBack(0, 1);
          }}
          type="submit"
          style={{ marginTop: "0.5em", textTransform: "none" }}
          aria-label={t("JourneyDetails.btnStartAgainDesp", {
            BackButton: props.backBtnLabel,
          })}>
          {props.backBtnLabel}
        </Button>
      </Grid>
    </div>
  );
};

export default JourneyDetailsForm;
