import React from "react";
import "./announcement.css";
import { useSelector } from "react-redux";
import { AppState } from "../store/reducers/rootReducer";

export default function Announcement() {
  const { announcement } = useSelector((state: AppState) => state.announcement);
  return (
    <div
      aria-live="polite"
      aria-atomic="true"
      role="log"
      aria-relevant="additions text"
      className="visuallyHidden"
    >
      {announcement}
    </div>
  );
}
