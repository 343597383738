import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export enum ReservationMode {
  New = 0,
  Edit = 1,
  Rebook = 2,
}

const useReservationMode = (): [ReservationMode, any, any] => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);  
  const jpUid = queryParams.get("id");
  const orgjpUid = queryParams.get("oid");
  let resMode = ReservationMode.New;
  const qType = queryParams.get("type");   
    if(qType && qType.length > 0)
    {      
      switch (qType) {
      case "1":
        resMode = ReservationMode.Edit;
        break;
      case "2":
        resMode =ReservationMode.Rebook;
        break;
      default:
        resMode = ReservationMode.New;
        break;
    }
  }

 
  return [resMode, jpUid, orgjpUid];
};

export default useReservationMode;
