import React, { useEffect,  Fragment, useRef } from "react";
import { 
  additionalInformationFormikFormTouched,
} from "components/Reservation/steps/Reservation/reservationFormType";
import {
  Typography,
  Grid,
  FormControl,
  TextField, 
  Select, 
  IconButton,
} from "@mui/material";
import _ from "lodash";
import InfoIcon from "@mui/icons-material/Info";
import { isHiddenAdditionalParameters } from "helpers/additionalParameters";
import { Formik,  Field,  FieldArray } from "formik";
import makeStyles from "@mui/styles/makeStyles";
import { useTranslation } from "react-i18next";

import { ReservationAdditionalInformationViewModel } from "components/Reservation/steps/Reservation/reservationFormType";
import { additionalInformationFormikFormError } from "components/Reservation/steps/AdditionalInformation/additionalInformationType";
import { authService } from "services/authService";
import {
  convertToDate,
  getIntlDateFormatForLocale,
} from "helpers/localization";
import EtdDatePickerV2 from "controls/formik/EtdDatePickerV2";
import moment from "moment";

import {formFieldsValidationSchema, bookingRefInitialValue} from './BookingReferenceValidation';



const BookingReference: React.FC<{
  data: ReservationAdditionalInformationViewModel;
  onDataChange: (additionalInfo: any, isValid: boolean) => void;
}> = ({ data, onDataChange }) => {
  const { t } = useTranslation();
  const useStyles = makeStyles((theme) => ({
    gridItem: {
      verticalAlign: "center",
      padding: "10px",
      "& .MuiTypography-body2": {
        fontWeight: "bold",
        fontSize: "0.875rem",
      },
    },
    heading: {
      fontSize: "1rem",
      fontWeight: "bold",
    },
    labelText: {
      color: "#000",
      fontFamily: "Arial, Roboto, Segoe UI",
      fontSize: "0.875rem",
      fontWeight: "bold",
      verticalAlign: "middle",
    },
    grayed: {
      backgroundColor: "#EDEDED",
    },
    errorWarning: {
      color: "#DF0000",
      fontSize: "0.75rem",
      marginLeft: "15px",
    },
    textarea: {
      resize: "both",
    },
    focused: { outline: "none" },
    placeHolder: {
      "& ::placeholder": {
        color: "#767676",
        opacity: "1",
      },
    },
  }));
  const formBookingRef = useRef<any>(null);
  const locale = authService.getUserLocale();
  const localeDateTimeFormat = getIntlDateFormatForLocale(locale);
  const classes = useStyles();
  
  const [formData, setFormData] = React.useState<any>(
    data !== undefined && data !== null ? data : bookingRefInitialValue
  );

  const AdditionalInfoUpdated = (id: string, fieldType: number, values : any, newvalue: string | null, extravalue : string | null) => {
   
   let newAdditionalInfoFields = _.cloneDeep(values.additionalInformationFields);

    if(newAdditionalInfoFields.length > 0) {      
      for(let i = 0; i < newAdditionalInfoFields.length; i++) {
        if(newAdditionalInfoFields[i].additionalInformationGid === id) {
          newAdditionalInfoFields[i].fieldValue = newvalue;
          switch (fieldType) {
            case 2 :  newAdditionalInfoFields[i].dropDownListSelectedValue = newvalue; break;
            case 1 : newAdditionalInfoFields[i].fieldValueDateString = extravalue; break;
          }
          break;
        }
      }
      let newValues = {...values, additionalInformationFields: newAdditionalInfoFields };
      let isValid = formFieldsValidationSchema.isValidSync(newValues);
   
      onDataChange(newValues, isValid);
    }
    
    
  }
  
  useEffect(() => {
   
    setFormData(data);

  }, [data]);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={formData}
      validateOnChange={true}
      validateOnBlur={true}
      validateOnMount={true}
      validationSchema={formFieldsValidationSchema}
      innerRef={formBookingRef}
      onSubmit={() => {}}
    >
      {({
        values,
        isValid,
        dirty,
        setFieldValue,
        setFieldTouched,
        touched,
        errors,
        
      }) => (
        <>
          <FieldArray name="additionalInformationFields">
            {() =>
              values &&
              values?.additionalInformationFields?.map((info, index) => (
                
                (!isHiddenAdditionalParameters(info.fieldName) &&<Grid container direction="row" key={index}>
                  <Grid item xs={12} md={3} className={classes.gridItem}>
                    <Typography
                      variant="body2"
                      id={"AdditionalInformationFieldLabel".concat(
                        info.additionalInformationGid
                      )}
                      title={info.fieldName}
                    >
                      {info.fieldName.concat(info.mandatory ? " *" : "")}
                      {"  "}
                      {info.helperText !== null ? (
                        <IconButton
                          id={"additionalInformationIcon" + index}
                          size="small"
                          color="primary"
                          style={{
                            marginLeft: 0,
                            paddingBottom: 8,
                          }}
                          title={info.helperText}
                          //role="img"
                        >
                          <InfoIcon fontSize="small" />
                        </IconButton>
                      ) : (
                        ""
                      )}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={9} className={classes.gridItem}>
                    {[3, 4, 5].includes(info.fieldTypeCode) ? (
                      <Fragment>
                        <Field
                          name={`additionalInformationFields.${index}.additionalInformationGid`}
                          id={`AdditionalInformationField-TextTextbox-${index}`}                          
                          value={info.fieldValue ?? ""}
                          fullWidth
                          style={{
                            paddingBottom: 0,
                            paddingTop: 0,
                          }}
                          inputProps={{
                            "aria-label": info.fieldName,
                            "aria-describedby":
                              "AdditionalInformationFieldError" + index,
                            "data-testid" :`datefieldtest-${index}`
                          }}
                          onChange={(e) => {
                            setFieldValue(
                              `additionalInformationFields.${index}.fieldValue`,
                              e.target.value
                            );
                           // onDataChange(values, isValid);
                           AdditionalInfoUpdated(info.additionalInformationGid, info.fieldTypeCode,  values, e.target.value, e.target.value); 
                          }}
                          onBlur={(e) => {
                            setFieldTouched(
                              `additionalInformationFields.${index}.additionalInformationGid`
                            );
                           // onDataChange(values, isValid);
                          }}
                          onKeyUp={(e) => {
                            setFieldTouched(
                              `additionalInformationFields.${index}.additionalInformationGid`
                            );
                           // onDataChange(values, isValid);
                          }}
                          component={TextField}
                          variant="outlined"
                          error={
                            errors &&
                            errors.additionalInformationFields &&
                            errors.additionalInformationFields[index] !==
                              undefined
                              ? true
                              : false
                          }
                          placeholder={
                            locale === "de-DE"
                              ? ""
                              : t(
                                  "AdditionalInformation.pleaseSelectValueFor"
                                ) + info.fieldName
                          }
                        />
                        {errors &&
                        errors.additionalInformationFields &&
                        errors.additionalInformationFields[index] !==
                          undefined &&
                        touched &&
                        touched.additionalInformationFields &&
                        touched.additionalInformationFields[index] !==
                          undefined ? (
                          <span
                            id={
                              "AdditionalInformationField-DateTextboxError-" +
                              index
                            }
                            role="alert"
                            className={classes.errorWarning}
                          >
                            {t(
                              errors.additionalInformationFields[index]
                                .additionalInformationGid,
                              { fieldName: info.fieldName }
                            )}
                          </span>
                        ) : (
                          <span
                            id={
                              "AdditionalInformationField-DateTextboxError-" +
                              index
                            }
                          ></span>
                        )}
                      
                      </Fragment>
                    ) : [2].includes(info.fieldTypeCode) ? (
                      <Fragment>
                        <FormControl variant="outlined" fullWidth>
                          <Select
                            name={`additionalInformationFields.${index}.additionalInformationGid`}
                            id={`AdditionalInformationField-DropDownListComboBox-${index}`}
                            native
                            value={info.fieldValue ?? ""}
                            onChange={(e) => {
                              // info.dropDownListSelectedValue = e.target.value;
                              //  info.fieldValue = e.target.value;
                              setFieldValue(
                                `additionalInformationFields.${index}.fieldValue`,
                                e.target.value
                              );
                              setFieldValue(
                                `additionalInformationFields.${index}.dropDownListSelectedValue`,
                                e.target.value
                              );
                            // onDataChange(values, isValid);
                            AdditionalInfoUpdated(info.additionalInformationGid, info.fieldTypeCode,  values, e.target.value, e.target.value);   
                            }}
                            onBlur={(e) => {
                              setFieldTouched(
                                `additionalInformationFields.${index}.additionalInformationGid`
                              );
                            //  onDataChange(values, isValid);
                            }}
                            //required={info.mandatory}
                            inputProps={{
                              "aria-label": info.fieldName,
                              "aria-describedby":
                                "AdditionalInformationFieldError" + index,
                            }}
                            error={
                              errors &&
                              errors.additionalInformationFields &&
                              errors.additionalInformationFields[index] !==
                                undefined
                                ? true
                                : false
                            }
                          >
                            <option
                              key={
                                "AdditionalInformationField-DropDownListComboBox-" +
                                index +
                                "-Emtpy"
                              }
                              value=""
                            >
                              {t("AdditionalInformation.pleaseSelect")}
                            </option>
                            {info.dropDownList?.map((opt: any, optIndex) => (
                              <option
                                key={
                                  "AdditionalInformationField-DropDownListComboBox-" +
                                  index +
                                  "-" +
                                  optIndex
                                }
                                value={opt.value}
                              >
                                {opt.text}
                              </option>
                            ))}
                          </Select>
                          {(
                            errors.additionalInformation as unknown as additionalInformationFormikFormError
                          )?.additionalInformationFields[index] &&
                          (
                            touched.additionalInformation as unknown as additionalInformationFormikFormTouched
                          )?.additionalInformationFields[index] ? (
                            <span
                              id={"AdditionalInformationFieldError" + index}
                              role="alert"
                              className={classes.errorWarning}
                            >
                              {t(
                                (
                                  errors.additionalInformation as unknown as additionalInformationFormikFormError
                                )?.additionalInformationFields[index],
                                // { fieldName: info.fieldName }
                                {
                                  fieldName: info.fieldName,
                                  maxLength: info.maxLength
                                    ? info.maxLength
                                    : 100,
                                }
                              )}
                            </span>
                          ) : (
                            <span
                              id={"AdditionalInformationFieldError" + index}
                            ></span>
                          )}
                        </FormControl>
                      </Fragment>
                    ) : [1].includes(info.fieldTypeCode) ? (
                      <Fragment>
                        <Field
                          component={EtdDatePickerV2}
                          name={`additionalInformationFields.${index}.additionalInformationGid`}
                          id={`AdditionalInformationField-DateTextbox-${index}`}
                          
                          label={info.fieldName}
                          localeDateTimeFormat={
                            localeDateTimeFormat
                          }
                          isFullWidth={false}
                          language={locale}
                          value={
                            info.fieldValue
                              ? convertToDate(
                                  info.fieldValue
                                )
                              : null
                          }
                          errorMessageId={
                            "AdditionalInformationField-DateTextboxError-" +
                            index
                          }
                          handleChange={(e) => {
                            const format = localeDateTimeFormat.toUpperCase();
                            let parsedDate = moment(e, format, true);                            
                            if (parsedDate.isValid()) {
                              const newValue =
                                moment(parsedDate).format("YYYY-MM-DD");
                              if (
                                moment(parsedDate).isSameOrAfter(
                                  moment().add(-1, "days")
                                )
                              ) {
                                setFieldValue(
                                  `additionalInformationFields.${index}.fieldValue`,
                                  newValue
                                );
                                setFieldValue(
                                  `additionalInformationFields.${index}.fieldValueDateString`,
                                  newValue.replace(/\D/g, "")
                                );            
                                AdditionalInfoUpdated(info.additionalInformationGid, info.fieldTypeCode, values, newValue,  newValue.replace(/\D/g, ""));                    
                              } else {
                                setFieldValue(
                                  `additionalInformationFields.${index}.fieldValue`,
                                  null
                                );
                                setFieldValue(
                                  `additionalInformationFields.${index}.fieldValueDateString`,
                                  "DateInPast"
                                );      
                                AdditionalInfoUpdated(info.additionalInformationGid, info.fieldTypeCode,  values, null, "DateInPast");                        
                              }
                            } else {
                              setFieldValue(
                                `additionalInformationFields.${index}.fieldValue`,
                                null
                              );
                              setFieldValue(
                                `additionalInformationFields.${index}.fieldValueDateString`,
                                e ? "FormatError" : ""
                              );   
                              AdditionalInfoUpdated(info.additionalInformationGid, info.fieldTypeCode,  values, null, "FormatError");                                                   
                            }
                            
                            setFieldTouched(
                              "additionalInformation.additionalInformationFields[" +
                                index +
                                "]"
                            );
                           
                           
                          }}
                          handleBlur={(e) => {
                            setFieldTouched(
                              "additionalInformation.additionalInformationFields[" +
                                index +
                                "]"
                            );
                            
                           // onDataChange(values, isValid);
                          // AdditionalInfoUpdated(index, info.fieldName, values, e.target.value);
                          }}
                          //required={info.mandatory} use yup schema instead
                          isError={
                            errors &&
                            errors.additionalInformationFields &&
                            errors.additionalInformationFields[index] !==
                              undefined
                              ? true
                              : false
                          }
                        />
                        {errors &&
                        errors.additionalInformationFields &&
                        errors.additionalInformationFields[index] !==
                          undefined &&
                        touched &&
                        touched.additionalInformationFields &&
                        touched.additionalInformationFields[index] !==
                          undefined ? (
                          <span
                            id={
                              "AdditionalInformationField-DateTextboxError-" +
                              index
                            }
                            role="alert"
                            className={classes.errorWarning}
                          >
                            {t(
                              (
                                errors.additionalInformation as unknown as additionalInformationFormikFormError
                              )?.additionalInformationFields[index],
                              {
                                fieldName: info.fieldName,
                              }
                            )}
                          </span>
                        ) : (
                          <span
                            id={
                              "AdditionalInformationField-DateTextboxError-" +
                              index
                            }
                          ></span>
                        )}
                      </Fragment>
                    ) : (
                      ""
                    )}
                  </Grid>
                </Grid>)

              ))
            }
          </FieldArray>         
        </>
      )}
    </Formik>
  );
};

export default BookingReference;
