import React, { useState, useEffect } from "react";
import { useRef } from "react";
import FocusError from "../../controls/formik/FocusOnError";
import makeStyles from '@mui/styles/makeStyles';
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useTranslation } from "react-i18next";
import { UserDetail } from "./usertype";
import { useNavigate } from "react-router-dom";
import {
  Box,
  FormControl,
  Backdrop,
  Button,
  Grid,
  Typography,
  Select,
  CircularProgress,
  TextField,
  Checkbox,
  Autocomplete
} from "@mui/material";
import axiosInstance from "../../helpers/axiosInstance";
import {
  showSuccessAlertMessage,
  showErrorAlertMessage,
} from "../store/actions/shared/alertActions";
import { broadcastAnnouncement } from "../store/actions/shared/announcementActions";
import { useDispatch } from "react-redux";
import { authService } from "services/authService";
import { InitialValue } from "./usertype";
import { userValidationSchema } from "./userValidationSchema";
import { appInsights } from "helpers/appInsights";
import { SeverityLevel } from "@microsoft/applicationinsights-common";
import {
  Permissions,
  getUserPermissionsStatus,
  getUserPermissionsValue,
} from "helpers/getUserPermissions";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormHelperText-root": {
      color: "#DF0000",
      fontSize: 12,
      marginLeft: "15px",
      display: "none",
    },
    "& ::placeholder": {
      fontStyle: "italic",
      color: "#000",
      opacity: 0.95,
      whiteSpace: "pre-wrap",
      "@media (max-width:330px)": {
        fontSize: "0.6rem",
      },
    },
    input: {
      lineHeight: "normal",
    },
  },
  outlinedButton: {
    border: "1px solid rgba(0, 102, 57, 0.95);",
  },
  inputRoot: {
    fontSize: 30,
  },
  labelRoot: {
    fontSize: 30,
    color: "red",
    "&$labelFocused": {
      color: "purple",
    },
  },
  errorWarning: {
    color: "#DF0000",
    fontSize: 12,
    marginLeft: "15px",
  },
  backdrop: {
    zIndex: 99999,
    color: "#fff",
  },

  labelFocused: {},
}));

export default function CreateUser() {

  const initialCompany: CompanyItem = {
    disabled: false,
    group: null,
    selected: false,
    text: "Please Select a Company",
    value: "",
  }

  interface CompanyItem
{
  disabled: boolean;
  group: null;
  selected: boolean;
  text: string;
  value: string;
}

const [companyList, setCompanyList] = useState<CompanyItem[]>([]);
const [selectedCompany, setSelectedCompany] = useState<CompanyItem>(initialCompany);


  const [loading, setLoading] = useState(false);
  const [userDetail, setuserDetail] = useState<UserDetail>(InitialValue);
  let history = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const inputRef = useRef<HTMLInputElement>(null);
  const [
    isCompanyDriverValidityActivated,
    setIsCompanyDriverValidityActivated,
  ] = useState<boolean>(false);
  const [boboEnabled, setBoboEnabled] = useState<boolean>(false);

  const getCompanyProfile = async (companyUId) => {
    await axiosInstance
      .get(`/company/getCompanyProfileByUId/${companyUId}`)
      .then((response) => {
        setIsCompanyDriverValidityActivated(
          response.data?.isCompanyDriverValidityActivated ?? false
        );
        setBoboEnabled(response.data?.boboEnabled ?? false);
      })
      .catch((error) => {
        setIsCompanyDriverValidityActivated(false);
      });
  };
  const setNullValuesToEmpty = (obj) =>
    JSON.parse(JSON.stringify(obj, (k, v) => (v === null ? "" : v)));

  const getNewUserDetail = async () => {
    setLoading(true);
    await axiosInstance
      .get("/identity/create")
      .then((res) => {
        setuserDetail(setNullValuesToEmpty(res.data));

        let companyList = res.data.companyName?.items?.map(item => {
          let list: CompanyItem = {
          "disabled":item.disabled,
          "group":item.group,
          "selected": item.selected,
          "text" :item.text,
          "value": item.value,
        }
          return list
      })    
  
      setCompanyList(companyList?? []);
  
      let selectedCompany = companyList?.find(obj=>obj.value===res.data?.companyName?.selectedValue);
  
      setSelectedCompany(selectedCompany);

        if (res.data.companyName?.selectedValue)
          getCompanyProfile(res.data.companyName.selectedValue);
        /*    if (null !== inputRef.current) inputRef.current.focus();*/
        setLoading(false);
        appInsights.trackTrace({
          message: "add new user component loaded with default values",
          properties: { object: res.data, Component: "AddUser" },
          severityLevel: SeverityLevel.Information,
        });
      })
      .catch((err) => {
        setLoading(false);
        if (err.response && err.response.data.message) {
          const errorlist = err.response.data.message;
          dispatch(showErrorAlertMessage(errorlist));
          dispatch(broadcastAnnouncement(errorlist));
        } else {
          dispatch(
            showErrorAlertMessage(
              "An error has occured, please contact support team"
            )
          );
          dispatch(
            broadcastAnnouncement(
              "An error has occured, please contact support team"
            )
          );
        }
        appInsights.trackException({
          exception: err,
          properties: { method: "getNewUserDetail", Component: "AddUser" },
          severityLevel: SeverityLevel.Error,
        });
      });
  };

  const updateDriverId = async (companyId, setValue) => {
    const result = await axiosInstance.get(
      "/identity/getcarclubcompanyid/" + companyId
    );
    if (result.data !== null) {
      console.log(result.data.data);
      setValue("carClubDriver", {
        carClubCompanyId: result.data.data.toString(),
        carClubDriverId: "",
      });
    } else {
      setValue("carClubDriver", { carClubCompanyId: "", carClubDriverId: "" });
    }
  };

  const handleCancel = () => {
    history("/admin/users");
  };

  useEffect(() => {
    document.title = "Enterprise Rent-A-Car - " + t("Users.addUserPageTitle");
    getNewUserDetail();
  }, []);

  return (
    <Box data-testid="addUserBox" id="addUserBox" boxShadow={3} p={3} bgcolor="background.paper">
      <Formik
        initialValues={loading ? InitialValue : userDetail}
        validateOnChange={true}
        validateOnBlur={false}
        enableReinitialize={true}
        validationSchema={userValidationSchema}
        onSubmit={async (values, { setFieldTouched, setSubmitting }) => {
          await axiosInstance
            .post("/identity/create", values)
            .then((res) => {
              setSubmitting(false);
              dispatch(showSuccessAlertMessage(t("Users.saveUserSuccess")));
              dispatch(broadcastAnnouncement(t("Users.saveUserSuccess")));
              history(
                "/admin/user/viewuser?uid=" + values.username + "&isnew=1"
              );
            })
            .catch((err) => {
              setSubmitting(false);
              if (err.response && err.response.data.messages) {
                let errorlist = err.response.data.messages.filter(
                  (n) => n !== ""
                );
                const translatedErrorlist = errorlist.map(function (value) {
                  return t(value);
                });
                //.join("\r\n");
                dispatch(showErrorAlertMessage(translatedErrorlist));
                dispatch(broadcastAnnouncement(translatedErrorlist));
              } else {
                dispatch(showErrorAlertMessage("unable to save the user."));
                dispatch(broadcastAnnouncement("unable to save the user."));
              }

              appInsights.trackException({
                exception: err,
                properties: { method: "onSubmit", Component: "AddUser" },
                severityLevel: SeverityLevel.Error,
              });
            });
          /*   if (null !== inputRef.current) {
            inputRef.current.focus();
          }*/
          window.scrollTo(0, 0);
        }}
      >
        {({
          values,
          isValid,
          dirty,
          setFieldValue,
          setFieldTouched,
          touched,
          handleChange,
          errors,
          isSubmitting,
        }) => (
          <Grid container>
            <Backdrop
              className={classes.backdrop}
              open={isSubmitting || loading}
            >
              <CircularProgress />
            </Backdrop>
            <Grid item xs={12} md={6}>
              <Typography id="titleHeader" variant="h1">
                {t("Users.addUserPageTitle")}
              </Typography>
            </Grid>

            <Grid
              container
              direction="row"
              style={{ marginTop: "1em" }}
              className={classes.root}
            >
              <Form style={{ width: "100%" }}>
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    style={{
                      paddingBottom: 0,
                      paddingTop: 10,
                      marginBottom: "1em",
                    }}
                  >
                    <FormControl variant="outlined" fullWidth>
                      <Typography style={{ fontWeight: "bold" }}>
                        {t("Users.title")}
                      </Typography>
                      <Select
                        inputRef={inputRef}
                        native
                        value={values.userTitle.selectedValue}
                        onChange={(opt) => {
                          let text = values.userTitle.items?.find(
                            (x) => x.value === opt.target.value
                          )?.text;
                          setFieldValue("userTitle.selectedText", text);
                          setFieldValue(
                            "userTitle.selectedValue",
                            opt.target.value
                          );
                          setFieldTouched("userTitle");
                        }}
                        fullWidth
                        inputProps={{
                          id: "userTitle",
                          name: "userTitle",
                          title: "select a title",
                          "aria-label": t("Users.title"),
                          "aria-haspopup": "listbox",
                        }}
                        name="userTitle"
                        id="userTitle"
                        error={errors["userTitle"] !== undefined ? true : false}
                        aria-describedby={
                          errors.userTitle && touched.userTitle
                            ? "userTitle-helper-text"
                            : undefined
                        }
                      >
                        {values.userTitle.items?.map((opt: any) => (
                          <option key={opt.value} value={opt.value}>
                            {opt.text}
                          </option>
                        ))}
                      </Select>
                      {errors.userTitle && touched.userTitle ? (
                        <span
                          id="userTitle-helper-text"
                          className={classes.errorWarning}
                        >
                          {t(errors["userTitle"].toString())}
                        </span>
                      ) : (
                        ""
                      )}
                    </FormControl>
                  </Grid>
                  <Grid
                    style={{
                      paddingBottom: 0,
                      paddingTop: 10,
                      marginBottom: "1em",
                    }}
                    item
                    xs={12}
                  >
                    <Typography style={{ fontWeight: "bold" }}>
                      {t("Users.firstname")}
                    </Typography>
                    <Field
                      name="firstName"
                      id="firstName"
                      fullWidth
                      style={{ paddingBottom: 0, paddingTop: 0 }}
                      inputProps={{
                        "aria-label": t("Users.firstname"),
                        "aria-required": "true",
                        "aria-describedby":
                          errors.firstName && touched.firstName
                            ? "firstName-helper-text1"
                            : undefined,
                      }}
                      onChange= {(e) => {
                         setFieldValue("firstName", e.target.value);
                         setFieldTouched("firstName");
                       }}
                       onBlur= {() => {
                        setFieldTouched("firstName");
                      }}
                      component={TextField}
                      variant="outlined"
                      placeholder={t("Users.firstnamePlaceholder")}
                       error={errors["firstName"] !== undefined ? true : false}
                    />
                    <ErrorMessage name="firstName">
                      {(msg) => (
                        <span
                          id="firstName-helper-text1"
                          className={classes.errorWarning}
                        >
                          {t(msg)}
                        </span>
                      )}
                    </ErrorMessage>
                  </Grid>
                  <Grid
                    style={{
                      paddingBottom: 0,
                      paddingTop: 10,
                      marginBottom: "1em",
                    }}
                    item
                    xs={12}
                  >
                    <Typography style={{ fontWeight: "bold" }}>
                      {t("Users.lastname")}
                    </Typography>
                    <Field
                      name="lastName"
                      id="lastName"
                      fullWidth
                      style={{ paddingBottom: 0, paddingTop: 0 }}
                      inputProps={{
                        "aria-label": t("Users.lastname"),
                        "aria-required": true,
                        "aria-describedby":
                          errors.lastName && touched.lastName
                            ? "lastName-helper-text1"
                            : undefined,
                      }}
                      onChange= {(e) => {
                        setFieldValue("lastName", e.target.value);
                      setFieldTouched("lastName");
                      }}
                      onBlur= {() => {
                        setFieldTouched("lastName");
                      }}
                      component={TextField}
                      variant="outlined"
                      placeholder={t("Users.lastnamePlaceholder")}
                      error={errors["lastName"] !== undefined ? true : false}
                    />
                    <ErrorMessage name="lastName">
                      {(msg) => (
                        <span
                          id="lastName-helper-text1"
                          className={classes.errorWarning}
                        >
                          {t(msg)}
                        </span>
                      )}
                    </ErrorMessage>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{
                      paddingBottom: 0,
                      paddingTop: 10,
                      marginBottom: "1em",
                    }}
                  >
                    <Typography style={{ fontWeight: "bold" }}>
                      {t("Users.emailaddress")}
                    </Typography>
                    <Field
                      name="email"
                      id="email"
                      fullWidth
                      style={{ paddingBottom: 0, paddingTop: 0 }}
                      inputProps={{
                        "aria-label": t("Users.emailaddress"),
                        "aria-required": true,
                        "aria-describedby":
                          errors.email && touched.email
                            ? "email-helper-text1"
                            : undefined,
                      }}
                      onChange= {(e) => {
                        setFieldValue("email", e.target.value);
                        setFieldTouched("email");
                      }}
                      onBlur= {() => {
                        setFieldTouched("email");
                      }}
                      component={TextField}
                      variant="outlined"
                      placeholder={t("Users.emailPlaceholder")}
                      error={errors["email"] !== undefined ? true : false}
                    />
                    <ErrorMessage name="email">
                      {(msg) => (
                        <span
                          id="email-helper-text1"
                          className={classes.errorWarning}
                        >
                          {t(msg)}
                        </span>
                      )}
                    </ErrorMessage>
                  </Grid>
                  <Grid
                    container
                    spacing={2}
                    style={{
                      paddingBottom: 0,
                      paddingTop: 10,
                      marginBottom: "1em",
                    }}
                  >
                    <Grid
                      item
                      xs={12}
                      md={12}
                    >
                      <Typography style={{ fontWeight: "bold" }}>
                        {t("Users.preferredPhoneNumber")}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={3}
                      style={{
                        paddingBottom: 0,
                        paddingTop: 0,
                        marginBottom: "1em",
                      }}
                    >
                      <FormControl variant="outlined" fullWidth sx={{height:"100%"}}>
                        <Select
                          native
                          value={
                            values.preferredPhoneNumber
                              ?.selectedInternationalDiallingCode
                          }
                          onChange={handleChange}
                          fullWidth
                          inputProps={{
                            id: "preferredPhoneNumber.selectedInternationalDiallingCode",
                            name: "preferredPhoneNumber.selectedInternationalDiallingCode",
                            title: t("Users.countryCodeForPreferredNumber"),
                            "aria-label": t(
                              "Users.countryCodeForPreferredNumber"
                            ),
                          }}
                          name="preferredPhoneNumber.selectedInternationalDiallingCode"
                          error={
                            errors["preferredPhoneNumber"] !== undefined &&
                            errors["preferredPhoneNumber"][
                              "selectedInternationalDiallingCode"
                            ] !== undefined
                              ? true
                              : false
                          }
                          aria-describedby="preferredPhoneNumber.selectedInternationalDiallingCode-helper-text"
                        >
                          {" "}
                          <option key="" value="">
                            -
                          </option>
                          {values.preferredPhoneNumber?.availableInternationalDiallingCodes?.map(
                            (opt: any) => (
                              <option
                                key={opt.value}
                                value={opt.value}
                                disabled={opt.value === "+" ? true : false}
                              >
                                {opt.text}
                              </option>
                            )
                          )}
                        </Select>
                        {  errors["preferredPhoneNumber"] !== undefined &&
                            errors["preferredPhoneNumber"][
                              "selectedInternationalDiallingCode"
                            ] !== undefined ? (
                          <span
                            id="preferredPhoneNumber.selectedInternationalDiallingCodeError"
                            className={classes.errorWarning}
                          >
                            {t(errors["preferredPhoneNumber"][
                                        "selectedInternationalDiallingCode"
                                      ])}
                          </span>
                        ) : (
                          <span id="preferredPhoneNumber.selectedInternationalDiallingCodeError"></span>
                        )}
                      </FormControl>
                      <span
                        id="preferredPhoneNumber.selectedInternationalDiallingCode-helper-text"
                        style={{ visibility: "hidden" }}
                      ></span>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={9}
                      style={{ paddingBottom: 0, paddingTop:0, marginBottom: "1em" }}
                    >
                      <Field
                        name="preferredPhoneNumber.number"
                        id="preferredPhoneNumber.number"
                        fullWidth
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        inputProps={{
                          "aria-label": t("Users.preferredPhoneNumber"),
                          "aria-required": true,
                          "aria-describedby":
                            errors.preferredPhoneNumber &&
                            touched.preferredPhoneNumber
                              ? "preferredPhoneNumber-helper-text1"
                              : undefined,
                        }}
                        onChange= {(e) => {
                          setFieldValue("preferredPhoneNumber.number", e.target.value);
                          setFieldTouched("preferredPhoneNumber.number");
                        }}
                        component={TextField}
                        variant="outlined"
                        error={
                          errors["preferredPhoneNumber"] !== undefined &&
                          errors["preferredPhoneNumber"]["number"] !== undefined
                            ? true
                            : false
                        }
                        placeholder={t("Users.preferredPhonePlaceholder")}
                      />
                        {  errors["preferredPhoneNumber"] !== undefined &&
                            errors["preferredPhoneNumber"][
                              "number"
                            ] !== undefined ? (
                          <span
                            id="preferredPhoneNumber.number"
                            className={classes.errorWarning}
                          >
                            {t(errors["preferredPhoneNumber"][
                                        "number"
                                      ])}
                          </span>
                        ) : (
                          <span id="preferredPhoneNumber.number"></span>
                        )}
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={2}
                    style={{ paddingBottom: 0, marginBottom: "1em" }}
                  >
                    <Grid
                      item
                      xs={12}
                      md={12}
                    >
                      <Typography style={{ fontWeight: "bold" }}>
                        {t("Users.alternativePhoneNumber")}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={3}
                      style={{
                        paddingBottom: 0,
                        paddingTop: 0,
                        marginBottom: "1em",
                      }}
                    >
                      <FormControl variant="outlined" fullWidth sx={{ height:"100%"}}>
                        <Select
                          native
                          value={
                            values.alternativePhoneNumber
                              ?.selectedInternationalDiallingCode
                          }
                          onChange={handleChange}
                          fullWidth
                          inputProps={{
                            id: "alternativePhoneNumber.selectedInternationalDiallingCode",
                            name: "alternativePhoneNumber.selectedInternationalDiallingCode",
                            title: t("Users.countryCodeForAlternateNumber"),
                            "aria-label": t(
                              "Users.countryCodeForAlternateNumber"
                            ),
                          }}
                          error={
                            errors["alternativePhoneNumber"] !== undefined &&
                            errors["alternativePhoneNumber"][
                              "selectedInternationalDiallingCode"
                            ] !== undefined
                              ? true
                              : false
                          }
                          aria-describedby="alternativePhoneNumber.selectedInternationalDiallingCode-helper-text"
                          name="alternativePhoneNumber.selectedInternationalDiallingCode"
                        >
                          <option key="" value="">
                            -
                          </option>
                          {values.alternativePhoneNumber?.availableInternationalDiallingCodes?.map(
                            (opt: any) => (
                              <option
                                key={opt.value}
                                value={opt.value}
                                disabled={opt.value === "+" ? true : false}
                              >
                                {opt.text}
                              </option>
                            )
                          )}
                        </Select>
                        {  errors["alternativePhoneNumber"] !== undefined &&
                            errors["alternativePhoneNumber"][
                              "selectedInternationalDiallingCode"
                            ] !== undefined ? (
                          <span
                            id="alternativePhoneNumber.selectedInternationalDiallingCodeError"
                            className={classes.errorWarning}
                          >
                            {t(errors["alternativePhoneNumber"][
                                        "selectedInternationalDiallingCode"
                                      ])}
                          </span>
                        ) : (
                          <span id="alternativePhoneNumber.selectedInternationalDiallingCodeError"></span>
                        )}
                      </FormControl>
                      <span
                        id="alternativePhoneNumber.selectedInternationalDiallingCode-helper-text"
                        style={{ visibility: "hidden" }}
                      ></span>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={9}
                      style={{ paddingBottom: 0, paddingTop:0, marginBottom: "1em" }}
                    >
                      <Field
                        name="alternativePhoneNumber.number"
                        id="alternativePhoneNumber.number"
                        fullWidth
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        inputProps={{
                          "aria-label": t("Users.alternativePhoneNumber"),
                          "aria-required": true,
                          "aria-describedby":
                            errors.alternativePhoneNumber &&
                            touched.alternativePhoneNumber
                              ? "alternativePhoneNumber-helper-text1"
                              : undefined,
                        }}
                        onChange= {(e) => {
                          setFieldValue("alternativePhoneNumber.number", e.target.value);
                          setFieldTouched("alternativePhoneNumber.number");
                        }}
                        component={TextField}
                        variant="outlined"
                        placeholder={t("Users.alternatePhonePlaceholder")}
                        error={
                          errors["alternativePhoneNumber"] !== undefined &&
                          errors["alternativePhoneNumber"]["number"] !== undefined
                            ? true
                            : false
                        }
                      />
                        {  errors["alternativePhoneNumber"] !== undefined &&
                      errors["alternativePhoneNumber"][
                        "number"
                      ] !== undefined ? (
                    <span
                      id="alternativePhoneNumber.number"
                      className={classes.errorWarning}
                    >
                      {t(errors["alternativePhoneNumber"][
                                  "number"
                                ])}
                    </span>
                  ) : (
                    <span id="alternativePhoneNumber.number"></span>
                  )}
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={3}
                    style={{ paddingBottom: 0, marginBottom: "1em" }}
                  >
                    <Grid
                      item
                      xs={12}
                      md={12}
                    >
                      <Typography style={{ fontWeight: "bold" }}>
                        {t("ReservationWizard.reservationPage.labelLoyaltyNumbersIfApplicable")}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={4}
                      style={{ paddingLeft: 36, paddingTop: 12, paddingBottom: 0, marginBottom: "1em" }}
                    >
                      <Typography style={{ fontWeight: "bold" }}>
                        {t("Users.emeraldClubNumber")}
                      </Typography>
                      <Field
                        name="emeraldClubNumber"
                        id="emeraldClubNumber"
                        onChange={(opt) => {
                          setFieldValue(
                            "emeraldClubNumber",
                            opt.target.value.slice(0, 9).toUpperCase()
                          );
                        }}
                        fullWidth
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        inputProps={{
                          "aria-label": t("Users.emeraldClubNumber"),
                          "aria-required": true,
                          "aria-describedby":
                            errors.emeraldClubNumber && touched.emeraldClubNumber
                              ? "emeraldClubNumber-helper-text1"
                              : undefined,
                        }}
                        component={TextField}
                        variant="outlined"
                        error={errors["emeraldClubNumber"] !== undefined ? true : false}
                      />
                      <ErrorMessage name="emeraldClubNumber">
                        {(msg) => (
                          <span
                            id="emeraldClubNumber-helper-text1"
                            className={classes.errorWarning}>
                            {t(msg)}
                          </span>
                        )}
                      </ErrorMessage>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      md={4}
                      style={{ paddingLeft: 36, paddingTop: 12, paddingBottom: 0, marginBottom: "1em" }}
                    >
                      <Typography style={{ fontWeight: "bold" }}>
                        {t("Users.enterprisePlusNumber")}
                      </Typography>
                      <Field
                        name="enterprisePlusNumber"
                        id="enterprisePlusNumber"
                        onChange={(opt) => {
                          setFieldValue(
                            "enterprisePlusNumber",
                            opt.target.value.slice(0, 9).toUpperCase()
                          );
                        }}
                        fullWidth
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        inputProps={{
                          "aria-label": t("Users.enterprisePlusNumber"),
                          "aria-required": true,
                          "aria-describedby":
                            errors.enterprisePlusNumber && touched.enterprisePlusNumber
                              ? "enterprisePlusNumber-helper-text1"
                              : undefined,
                        }}
                        component={TextField}
                        variant="outlined"
                        error={errors["enterprisePlusNumber"] !== undefined ? true : false}
                      />
                      <ErrorMessage name="enterprisePlusNumber">
                        {(msg) => (
                          <span
                            id="enterprisePlusNumber-helper-text1"
                            className={classes.errorWarning}>
                            {t(msg)}
                          </span>
                        )}
                      </ErrorMessage>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{ paddingBottom: 0, marginBottom: "1em" }}
                  >
                    <Typography style={{ fontWeight: "bold" }}>
                      {t("Users.username")}
                    </Typography>
                    <Field
                      name="username"
                      id="username"
                      fullWidth
                      style={{ paddingBottom: 0, paddingTop: 0 }}
                      inputProps={{
                        "aria-label": t("Users.username"),
                        "aria-required": true,
                        "aria-describedby":
                          errors.username && touched.username
                            ? "username-helper-text1"
                            : undefined,
                      }}
                      onChange= {(e) => {
                        setFieldValue("username", e.target.value);
                        setFieldTouched("username");
                      }}
                      onBlur= {() => {
                        setFieldTouched("username");
                      }}
                      component={TextField}
                      variant="outlined"
                      placeholder={t("Users.usernamePlaceholder")}
                      error={errors["username"] !== undefined ? true : false}
                    />
                    <ErrorMessage name="username">
                      {(msg) => (
                        <span
                          id="username-helper-text1"
                          className={classes.errorWarning}
                        >
                          {t(msg)}
                        </span>
                      )}
                    </ErrorMessage>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    style={{
                      paddingBottom: 0,
                      paddingTop: 10,
                      marginBottom: "1em",
                    }}
                  >
                    <FormControl variant="outlined" fullWidth>
                      <Typography style={{ fontWeight: "bold"}}>
                        {t("Users.company")}
                      </Typography>
                      {/* <Select
                        native
                        disabled={authService.isCompanyAdmin()}
                        value={values.companyName?.selectedValue}
                        onChange={(opt) => {
                          let text = values.companyName?.items?.find(
                            (x) => x.value === opt.target.value
                          )?.text;
                          setFieldValue("companyName.selectedText", text);
                          setFieldValue("companyName.selectedValue", opt.target.value);
                          setFieldTouched("companyName");
                        }}
                        fullWidth
                        inputProps={{
                          id: "companyName.selectedValue",
                          name: "companyName",
                          title: "select a componey",
                          "aria-label": t("Users.company")
                        }}
                        id="companyName"
                        name="companyName"
                        error={errors["companyName"] !== undefined ? true : false}
                        aria-describedby={"companyName.selectedValue-helper-select"}
                        >
                        {values.role?.items?.map((opt: any) => (
                          <option key={opt.value} value={opt.value}>
                            {opt.text}
                          </option>
                        ))}
                      </Select>
                      {errors.companyName && touched.companyName ? (
                        <span id="companyName.selectedValue-helper-select" className={classes.errorWarning}>
                         {t(errors["companyName"].toString())}
                        </span>
                      ) : (
                        ""
                      )} */}

                      {/* <Select
                        native
                        disabled={authService.isCompanyAdmin()}
                        value={values.companyName?.selectedValue}
                        onChange={(opt) => {
                          let text = values.companyName?.items?.find(
                            (x) => x.value === opt.target.value
                          )?.text;
                          setFieldValue("companyName.selectedText", text);
                          setFieldValue(
                            "companyName.selectedValue",
                            opt.target.value
                          );
                          setFieldTouched("companyName");
                          updateDriverId(opt.target.value, setFieldValue);
                          getCompanyProfile(opt.target.value);
                        }}
                        onBlur= {() => {
                          setFieldTouched("companyName");
                        }}
                        fullWidth
                        inputProps={{
                          id: "companyName.selectedValue",
                          name: "companyName.selectedValue",
                          title: "select a company",
                          "aria-label": t("Users.company"),
                          "data-selectedtext": values.companyName?.selectedText,
                        }}
                        name="companyName.selectedValue"
                        id="companyName.selectedValue"
                        error={
                          errors["companyName"] !== undefined ? true : false
                        }
                        aria-describedby={
                          errors.companyName && touched.companyName
                            ? "companyName-helper-select"
                            : undefined
                        }
                      >
                        {values.companyName?.items?.map((opt: any) => (
                          <option key={opt.value} value={opt.value}>
                            {opt.text}
                          </option>
                        ))}
                      </Select> */}

                    <Autocomplete
                          fullWidth
                          disabled={authService.isCompanyAdmin()}
                          id="companyName.selectedValue"
                          aria-label={t("Users.company")}
                          options={companyList}
                          autoComplete
                          value={selectedCompany}
                          filterSelectedOptions
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              id={"companyName.selectedValue"}
                              name={"companyName.selectedValue"}
                              title={"select a company"}
                              data-selectedtext={
                                values.companyName?.selectedText
                              }
                              //label={t("Users.company")}
                              variant="outlined"
                            />
                          )}
                          getOptionLabel={(option: CompanyItem) => option.text}
                          isOptionEqualToValue={(option, value) =>
                            option.value === value.value
                          }
                          // getOptionDisabled={(option) =>
                          //   option === companyList[0]
                          // }
                          onChange={async (event, value) => {
                            
                            if (!value || value.value==="" || value.value===undefined) return;

                            let selectedCompany = companyList?.find(
                              (obj) => obj.value === value.value
                            );
                            if (selectedCompany !== undefined) {
                              setSelectedCompany(selectedCompany);
                            }

                            setFieldValue(
                              "companyName.selectedValue",
                              value.value
                            );
                            setFieldValue(
                              "companyName.selectedText",
                              value.text
                            );
                            setFieldTouched("companyName");
                            await updateDriverId(value.value, setFieldValue);
                            getCompanyProfile(value.value);
                          }}
                          onBlur={() => {
                            setFieldTouched("companyName");
                          }}
                        />
                      <ErrorMessage name="companyName">
                        {(msg) => (
                          <span
                            id="companyName-helper-select"
                            className={classes.errorWarning}
                          >
                            {t(msg)}
                          </span>
                        )}
                      </ErrorMessage>
                    </FormControl>
                  </Grid>
                  
                    <Grid
                      item
                      xs={12}
                      style={{
                        paddingBottom: 0,
                        paddingTop: 10,
                        marginBottom: "1em",
                      }}
                    >
                      <FormControl
                        id="permissionLst"
                        variant="outlined"
                        fullWidth
                        style={{
                          flexDirection:"unset",
                          flexWrap:"wrap"
                        }}
                      >
                        <Typography style={{ fontWeight: "bold",width:"100%" }}>
                          {t("Users.Permissions")}
                        </Typography>
                        {Object.entries(Permissions)
                          .filter(([key]) => {
                            return isNaN(Number(key)) && key !== "None";
                          })
                          .map(([key]) => {
                            const labelId = key + "Label";
                            const checkboxId = key + "Checkox";
                            const selectedPermission: Permissions =
                              Permissions[key];
                            const label = "Users." + key;
                            return (((values.isBOBOToggleEnabled && boboEnabled && key==="BoboEnabled") || key!=="BoboEnabled") &&
                              <Grid key={key} item xs={12} md={3}>
                                <label id={labelId}>
                                  <Field
                                    type="checkbox"
                                    name="permissions"
                                    component={Checkbox}
                                    id={checkboxId}
                                    disabled={
                                      isCompanyDriverValidityActivated
                                    }
                                    checked={
                                      isCompanyDriverValidityActivated
                                        ? false
                                        : getUserPermissionsStatus(
                                            values.permissions ?? 0,
                                            selectedPermission
                                          )
                                    }
                                    onChange={(opt) => {
                                      setFieldValue(
                                        "permissions",
                                        getUserPermissionsValue(
                                          values.permissions ?? 0,
                                          selectedPermission,
                                          opt.target.checked === true
                                        )
                                      );
                                      setFieldTouched("permissions");
                                    }}
                                  />
                                  {t(label)}
                                </label>
                              </Grid>
                            );
                          })}
                      </FormControl>
                    </Grid>
                 
                  <Grid
                    container
                    spacing={2}
                    style={{
                      paddingBottom: 0,
                      paddingTop: 10,
                      marginBottom: "1em",
                    }}
                  >
                    <Grid
                      item
                      xs={12}
                      md={12}
                    >
                      <Typography style={{ fontWeight: "bold" }}>
                        {t("Users.carClubDriver")}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      md={3}
                      style={{
                        paddingBottom: 0,
                        paddingTop: 0,
                        marginBottom: "1em",
                      }}
                    >
                      <FormControl variant="outlined" fullWidth>
                        <Field
                          disabled
                          name="carClubDriver.carClubCompanyId"
                          id="carClubDriver.carClubCompanyId"
                          fullWidth
                          style={{ paddingBottom: 0, paddingTop: 0 }}
                          inputProps={{
                            "aria-label": t("Users.carClubDriver"),
                          }}
                          onChange= {(e) => {
                            setFieldValue("carClubDriver.carClubCompanyId", e.target.value);
                            setFieldTouched("carClubDriver.carClubCompanyId");
                          }}
                          component={TextField}
                          value={values.carClubDriver?.carClubCompanyId}
                          variant="outlined"
                          placeholder={t("Users.carClubCompanyIdPlaceholder")}
                        />
                      </FormControl>
                    </Grid>

                    <Grid
                      item
                      xs={6}
                      md={9}
                      style={{ paddingBottom: 0, paddingTop:0,  marginBottom: "1em" }}
                    >
                      <Field
                        name="carClubDriver.carClubDriverId"
                        id="carClubDriver.carClubDriverId"
                        fullWidth
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        inputProps={{ "aria-label": t("Users.carClubDriver") }}
                        onChange= {(e) => {
                          setFieldValue("carClubDriver.carClubDriverId", e.target.value);
                          setFieldTouched("carClubDriver.carClubDriverId");
                        }}
                        component={TextField}
                        variant="outlined"
                        placeholder={t("Users.driverIdPlaceholder")}
                        aria-describedby={
                          "carClubDriver.carClubDriverId-helper-text"
                        }
                      />
                      <span
                        id="carClubDriver.carClubDriverId-helper-text"
                        style={{ visibility: "hidden" }}
                      ></span>
                      <ErrorMessage name="carClubDriver.carClubDriverId">
                        {(msg) => (
                          <span
                            id="carClubDriver.carClubDriverId-helper-text"
                            className={classes.errorWarning}
                          >
                            {t(msg)}
                          </span>
                        )}
                      </ErrorMessage>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{ paddingBottom: 0, marginBottom: "1em" }}
                  >
                    <Typography style={{ fontWeight: "bold" }}>
                      {t("Users.lineManageremail")}
                    </Typography>
                    <Field
                      name="lineManagerEmail"
                      id="lineManagerEmail"
                      fullWidth
                      style={{ paddingBottom: 0, paddingTop: 0 }}
                      inputProps={{
                        "aria-label": t("Users.lineManageremail"),
                        "aria-required": true,
                        "aria-describedby":
                          errors.lineManagerEmail && touched.lineManagerEmail
                            ? "lineManagerEmail-helper-text1"
                            : undefined,
                      }}
                      onChange= {(e) => {
                        setFieldValue("lineManagerEmail", e.target.value);
                        setFieldTouched("lineManagerEmail");
                      }}
                      component={TextField}
                      variant="outlined"
                      placeholder={t("Users.linemanagerEmailPlaceholder")}
                    />
                    <ErrorMessage name="lineManagerEmail">
                      {(msg) => (
                        <span
                          id="lineManagerEmail-helper-text1"
                          className={classes.errorWarning}
                        >
                          {t(msg)}
                        </span>
                      )}
                    </ErrorMessage>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{
                      paddingBottom: 0,
                      paddingTop: 10,
                      marginBottom: "1em",
                    }}
                  >
                    <FormControl
                      variant="outlined"
                      fullWidth
                      error={
                        errors && errors["preferredLanguage.selectedValue"]
                      }
                    >
                      <Typography style={{ fontWeight: "bold" }}>
                        {t("Users.preferredLanguage")}{" "}
                        {errors && errors["preferredLanguage.selectedValue"]}
                      </Typography>
                      <Select
                        native
                        value={values.preferredLanguage?.selectedValue}
                        onChange={(opt) => {
                          setFieldValue(
                            "preferredLanguage.selectedValue",
                            opt.target.value
                          );
                          // setFieldValue("preferredLanguage.selectedText", text);
                          setFieldTouched("preferredLanguage");
                        }}
                        onBlur= {() => {
                          setFieldTouched("preferredLanguage");
                        }}
                        fullWidth
                        inputProps={{
                          id: "preferredLanguage.selectedValue",
                          name: "preferredLanguage",
                          title: "select a language",
                          "aria-label": t("Users.preferredLanguage"),
                        }}
                        name="preferredLanguage"
                        error={
                          errors["preferredLanguage"] !== undefined
                            ? true
                            : false
                        }
                        aria-describedby={"preferredLanguage-helper-select"}
                      >
                        {values.preferredLanguage?.items?.map((opt: any) => (
                          <option key={opt.value} value={opt.value}>
                            {opt.text}
                          </option>
                        ))}
                      </Select>
                      {errors.preferredLanguage && touched.preferredLanguage ? (
                        <span className={classes.errorWarning}>
                          {t(errors["preferredLanguage"].toString())}
                        </span>
                      ) : (
                        ""
                      )}
                    </FormControl>
                    <span
                      id="preferredLanguage-helper-select"
                      style={{ visibility: "hidden" }}
                    ></span>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{
                      paddingBottom: 0,
                      paddingTop: 10,
                      marginBottom: "1em",
                    }}
                  >
                    <FormControl id="cc" variant="outlined" fullWidth>
                      <Typography style={{ fontWeight: "bold" }}>
                        {t("Users.role")}
                      </Typography>
                      <Select
                        native
                        value={values.role?.selectedValue}
                        onChange={(opt) => {
                          let text = values.role?.items?.find(
                            (x) => x.value === opt.target.value
                          )?.text;
                          setFieldValue("role.selectedText", text);
                          setFieldValue("role.selectedValue", opt.target.value);
                          setFieldTouched("role");
                        }}
                        onBlur= {() => {
                          setFieldTouched("role");
                        }}
                        fullWidth
                        inputProps={{
                          id: "role.selectedValue",
                          name: "role",
                          title: "select a role",
                          "aria-label": t("Users.role"),
                        }}
                        name="role"
                        error={errors["role"] !== undefined ? true : false}
                        aria-describedby={"role.selectedValue-helper-select"}
                      >
                        {values.role?.items?.map((opt: any) => (
                          <option key={opt.value} value={opt.value}>
                            {opt.text}
                          </option>
                        ))}
                      </Select>
                      <span
                        id="role.selectedValue-helper-select"
                        style={{ visibility: "hidden" }}
                      ></span>
                      {errors.role && touched.role ? (
                        <span
                          id="role.selectedValue-helper-select"
                          className={classes.errorWarning}
                        >
                          {t(errors["role"], toString())}
                        </span>
                      ) : (
                        ""
                      )}
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{
                      paddingBottom: 0,
                      paddingTop: 10,
                      marginBottom: "1em",
                    }}
                  >
                    <Typography
                      style={{
                        fontStyle: "italic",
                        fontWeight: "bold",
                        opacity: 0.9,
                      }}
                    >
                      {t("Users.roleInfoMessage")}
                    </Typography>
                  </Grid>
                  <Grid container justifyContent="flex-end">
                    <Button
                      id="btnSubmit"
                      color="primary"
                      variant="contained"
                      disabled={!isValid || !dirty}
                      type="submit"
                      style={{ textTransform: "none" }}
                    >
                      {t("Users.btnSave")}
                    </Button>
                    <Button
                      classes={{ outlinedPrimary: classes.outlinedButton }}
                      id="btnCancel"
                      color="primary"
                      variant="outlined"
                      type="button"
                      onClick={handleCancel}
                      style={{ marginLeft: "0.5em", textTransform: "none" }}
                    >
                      {t("Users.btnCancel")}
                    </Button>
                  </Grid>
                </Grid>
                <FocusError />
              </Form>
            </Grid>
          </Grid>
        )}
      </Formik>
    </Box>
  );
}
