import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../store/reducers/rootReducer";
import { clearAlertMessage } from "components/store/actions/shared/alertActions";
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import makeStyles from '@mui/styles/makeStyles';
import Collapse from "@mui/material/Collapse";
import React from "react";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { alertTypes } from "models/alertTypes.enum";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
    "@media print": {
      display: "none",
    },
    ".MuiAlert-root": {
      alignItems: "center",
    },
  },
}));

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
export default function AlertMessage() {
  const { t } = useTranslation();
  const classes = useStyles();
  const { alertMessage } = useSelector((state: AppState) => state);
  const dispatch = useDispatch();
  function handleClose() {
    dispatch(clearAlertMessage());
  }

  return (
    <div
      style={{ marginBottom: 1, paddingLeft: 7, paddingRight: 7 }}
      className={classes.root}
      id="alertMessage">
      <Collapse in={alertMessage.open}>
        <Alert
          id="errorMessageAlert"
          severity={alertMessage.severity}
          onClose={handleClose}>
          <div>
            <Typography
              id="error-message-title"
              hidden={alertMessage?.severity !== alertTypes.Error}
              variant="h2">
              {alertMessage?.message &&
              Array.isArray(alertMessage?.message) &&
              alertMessage?.message?.length > 1
                ? t("errorsFound")
                : t("errorFound")}
            </Typography>
            {Array.isArray(alertMessage.message)
              ? alertMessage.message?.map((message, index) => (
                  <div
                    id="error-message-content"
                    style={{
                      maxHeight: "80px",
                      marginBottom: "12px",
                    }}
                    key={index}>
                    {message}
                  </div>
                ))
              : alertMessage.message}
          </div>
        </Alert>
      </Collapse>
    </div>
  );
}
