import React from "react";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import axiosInstance from "../../helpers/axiosInstance";

import { appInsights } from "../../helpers/appInsights";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import makeStyles from '@mui/styles/makeStyles';

export interface EtdLocationLookup {
  location: string;
  peopleSoftId: string;
  stationId: string;
  latitude: string;
  longitude: string;
  brand: string;
  countryCode: string;
  address1: string;
  address2: string;
  address3: string;
  townOrCity: string;
  postcode: string;
  addressSearch: string;
}

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
  placeholder: {
    //fontSize: "0.85rem",
    "& ::placeholder": {
      color: "#000",
      opacity: 0.85,
      whiteSpace: "pre-wrap",
      fontSize: "0.72rem",
      wordBreak: "break-word",
      flexGrow: 1,
      flexBasis: "100%",
    },
    "@media (max-width:390px)": {
      fontSize: "0.4rem",
    },
    "@media (min-width: 391px) and (max-width:480px)": {
      fontSize: "0.6rem",
    },
    "@media (min-width: 960px) and (max-width: 960px)": {
      fontSize: "0.7rem",
    },
  },
  option: {
    // Hover
    '&[data-focus="true"]': {
      outline: "3px solid #0066FF !important",
      outlineOffset: "3px",
    },
    "& ::placeholder": {
      color: "#000",
      opacity: 0.85,
      whiteSpace: "pre-wrap",
      fontSize: "0.72rem",
      wordBreak: "break-word",
    },
  },
}));

export default function GoogleAutoComplete(props) {
  const { id, placeholder, isReadOnly, defaultValue } = props;
  const [options, setOptions] = React.useState<any[]>([]);
  const { t } = useTranslation();
  const classes = useStyles();

  React.useEffect(() => {}, []);

  const onChangeHandle = async (value) => {
    // use the changed value to make request and then use the result. Which
    console.log("Search string="+value);
    if (value && value.trim().length > 2) {
      await axiosInstance
        .get(`places/autocomplete?filter=${encodeURIComponent(value)}`)
        .then((response) => {
          console.log("Options received: ", response.data.data);
          if (response.data.data) setOptions(response.data.data);
        })
        .catch((error) => {
          console.log("Error in getting google Api data: ", value);
          setOptions([]);
          appInsights.trackException({
            exception: error,
            properties: {
              method: "autocomplete",
              Component: "AddressLookup",
            },
            severityLevel: SeverityLevel.Error,
          });
          console.log(error);
        });
    }
  };

  const getGeoLocationDetails = (addressId, locationName) => {
    let locationSearch: any;
    axiosInstance
      .get(`places/${addressId}`)
      .then((response) => {
        console.log("Selected Location: ", locationName, response.data);
        locationSearch = {
          location: locationName,
          peopleSoftId: "",
          stationId: "",
          latitude: response.data.latitude,
          longitude: response.data.longitude,
          brand: "",
          countryCode: response.data.countryCode,
          address1: response.data.address1,
          address2: response.data.address2,
          address3: response.data.address3,
          townOrCity: response.data.townOrCity,
          postcode: response.data.postcode ? response.data.postcode : "",
          addressSearch: locationName,
        };

        props.setlocationSearch(locationSearch);
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            method: "places",
            Component: "AddressLookup",
          },
          severityLevel: SeverityLevel.Error,
        });
        console.log(error);
      });
  };

  return (
    <Autocomplete
      data-testid="etdLocationLookup"
      //freeSolo
      id={id}
      classes={{
        option: classes.option,
      }}
      disableClearable
      noOptionsText={t("journeyInput.labelNoOptions")}
      filterOptions={(x) => x}
      isOptionEqualToValue={(option, value) => option.location === value.location}
      getOptionLabel={(option) =>
        option
          ? option.location
            ? option.location
            : option.destination
            ? option.destination
            : ""
          : ""
      }
      options={options}
      value={defaultValue}
      // inputValue={isReadOnly ? defaultValue.location : ""} // this is to update default value for End Location
      //loading={true}
      loadingText={t("journeyInput.labelLoading")}
      onChange={(event, newValue: any) => {
        const stationId = newValue ? newValue.stationId : "";
        if (stationId && stationId !== "") {
          getGeoLocationDetails(stationId, newValue.location);
        }
      }}
      onBlur={() => {
        if (props.onInputChange) props.onInputChange("blurred");
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          margin="none"
          variant="outlined"
          placeholder={placeholder}
          onChange={(ev) => {
            if (ev.target.value !== "" || ev.target.value !== null) {
              onChangeHandle(ev.target.value);
            }
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: null,
            disabled: isReadOnly,
            className: classes.placeholder,
            // "aria-describedby":"locationErrorMsg_"+id
          }}
          inputProps={{
            ...params.inputProps,
            autoComplete: "off",
            disabled: isReadOnly,
            // "aria-describedby":"locationErrorMsg_"+id
          }}
        />
      )}
    />
  );
}
