import * as React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { useTranslation } from "react-i18next";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  Divider,
  FormControl,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import TextField from "@mui/material/TextField";
import "./home.css";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import EditIcon from "@mui/icons-material/Edit";
import CancelIcon from "@mui/icons-material/Cancel";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import axiosInstance from "helpers/axiosInstance";
import {
  BrandType,
  DateRangeType,
  JourneyType,
  reportColumns,
} from "views/reservation/ReservationViewType";
import AccessibleTooltip from "controls/AccessibleTooltip";
import CarClubLogin from "controls/CarClubLoginDialog";
import CarClubYesNo from "controls/CarClubYesNoDialog";
import { Theme } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import InfoIcon from "@mui/icons-material/Info";
import YesNoDialog from "controls/YesNoDialog";
import {
  clearAlertMessage,
  showErrorAlertMessage,
  showInfoAlertMessage,
} from "../../components/store/actions/shared/alertActions";
import { broadcastAnnouncement } from "components/store/actions/shared/announcementActions";
import { isTablet, isMobile } from "react-device-detect";
import { authService } from "services/authService";
import { getEnumKeyByEnumValue } from "helpers/enumMapping";
import { convertKmToMiles, getLocaleAmount } from "helpers/localization";
import { appInsights } from "helpers/appInsights";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { resetReservation } from "components/store/actions/reservation/reservationActions";
import {
  ReAuthenticateCarClub,
  reAuthenticationStatus,
} from "services/carClubReAuth";
import { useState } from "react";
import { AppState } from "components/store/reducers/rootReducer";
import { getUserPermissionsStatus, Permissions } from "helpers/getUserPermissions";
import EtdDatePickerV2 from "controls/formik/EtdDatePickerV2";
import { resetDriverDetails } from "components/store/slicers/driverDetailSlice";
import { resetBookingReferenceDetails } from "components/store/slicers/bookingReferenceSlice";
import { resetAccountLocationDetails } from "components/store/slicers/accountLocationSlice";
import { isJourneyEndDateStillWithinRentalMgmtWindow } from "helpers/rentalManagement";
import { setAllJourneysSkipLink } from "components/store/slicers/skipLinkSlice";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "##fff",
    color: theme.palette.common.black,
    borderBottom: "1px solid #000",
    fontWeight: "bold",
    height: "50px",
    padding: "0px 16px",
  },
  body: {
    fontSize: 12,
    padding: "0px 16px",
  },
  footer: {
    textAlign: "right",
    justifyContent: "right",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {},
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 700,
    "& .MuiTableCell-root": {
      borderRight: "1px solid #ddd",
      color: "#000000",
      paddingBottom: "0px",
      whiteSpace: "nowrap",
    },
  },
  pagination: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
    alignItems: "right",
    padding: "0px",
    "& .Mui-focused": {
      outline: "3px solid #0066FF !important",
      outlineOffset: "3px",
    },
  },
  selectRoot: {
    height: 10,
    display: "table",
  },
  select: {
    height: 6,
    display: "table-cell",
    verticalAlign: "middle",
  },
  formControl: {
    minWidth: 60,
  },
  selectEmpty: {},
  buttonSize: {
    textAlign: "center",
    padding: 3,
    margin: 0,
    alignItems: "center",
  },
  root: {},
  errorWarning: {
    color: "#DF0000",
    fontSize: "0.75rem",
    marginLeft: "15px",
  },
  button: { textTransform: "none" },
  backdrop: {
    zIndex: 99999,
    color: "#fff",
  },
  preWrap: {
    whiteSpace: "pre-wrap",
    lineHeight: "26px",
  },
  preWrapCell: {
    whiteSpace: "pre-wrap",
    lineHeight: "20px",
  },
  outlinedButton: {
    border: "1px solid rgba(0, 102, 57, 0.95);",
  },
  labelText: {
    color: "#000",
    fontFamily: "Arial, Roboto, Segoe UI",
    fontSize: "0.80rem",
    fontWeight: "bold",
    verticalAlign: "middle",
  },
}));


const sxProps = {
  button: { marginRight: "5px", textTransform: "none" },
};
export default function MyJourneys() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [dateRangeList, setDateRangeList] = React.useState([]);
  const inMiles = authService.getisoCountryCode() === "GB" || authService.getisoCountryCode() === "US"? true : false;
  const locale = authService.getUserLocale();
  const ukDate = "DD/MM/YYYY HH:mm";
  const dateFormat = t("DateTimeFormat");
  const today = moment().format(dateFormat);
  let history = useNavigate();
  const classes = useStyles();
  const [newJourneyRows, setNewJourneyRows] = React.useState([]);
  const [newJourneyRowCount, setNewJourneyRowCount] = React.useState(0);
  const [hideDateRange, setHideDateRange] = React.useState(true);
  const [dateRangeOption, setdateRangeOption] = React.useState("-1");
  const [searchStartDate, setSearchStartDate] = React.useState("");
  const [searchEndDate, setSearchEndDate] = React.useState("");
  const [exportStartDate, setExportStartDate] = React.useState("");
  const [exportEndDate, setExportEndDate] = React.useState("");
  const [searchStartSelectedDate, setSearchStartSelectedDate] =
    React.useState<Date | null>(moment().toDate());
  const [searchEndSelectedDate, setSearchEndSelectedDate] =
    React.useState<Date | null>(moment().toDate());
  const [searchKey, setSearchKey] = React.useState("");
  const [exportKey, setExportKey] = React.useState("");
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [Loading, setLoading] = React.useState(false);
  const [isReauth, setisReauth] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [ccOpenYesNo, setOpenCcYesNo] = React.useState(false);
  const [isView, setisView] = React.useState(true);
  const [journeyUId, setjourneyUId] = React.useState("");
  const [reference, setreference] = React.useState("");
  const [productUId, setProductUId] = React.useState("");
  const titleRef = React.useRef<HTMLDivElement>(null);
  const [pageRowCount, setPageRowCount] = React.useState(1);
  const [hasReservation, sethasReservation] = React.useState(false);
  const [openYesNo, setopenYesNo] = React.useState(false);
  const [nextPreviousPageMessage, setNextPreviousPageMessage] =
    React.useState("");
  const [invalidStart, setinvalidStart] = React.useState(false);
  const [invalidEnd, setinvalidEnd] = React.useState(false);
  const userCarClubId = authService.getUserCarClubId();
  const { companyInfo } = useSelector(
    (state: AppState) => state.companyInfo || {}
  );

  const [isCCLoggedIn, setIsCCLoggedIn] = useState(
    JSON.parse(localStorage.getItem("ccLoggedIn") ?? "false") ?? []
  );
  const hasUserViewOnlyPermission = getUserPermissionsStatus( authService.getUserPermissions(),Permissions.ViewOnly);

  React.useEffect(() => {
    dispatch(setAllJourneysSkipLink())
  })
  React.useEffect(() => {
    window.localStorage.setItem("ccLoggedIn", JSON.stringify(isCCLoggedIn));
  }, [isCCLoggedIn]);

  
  React.useEffect(() => {
    dispatch(clearAlertMessage());
    const  GetDateRange = async() => {
      await axiosInstance.get("myjourney/daterangeoptions").then((response) => {
        appInsights.trackEvent({
          name: "Get date range options",
        });
        const dateOptions = response.data;
        setDateRangeList(dateOptions);
      });
    };

    const GetJourneysFromServer = async() => {
      await GetJourneys(false);
    };
    document.title = t("MyJourneysTitle");
    if (titleRef.current !== null) titleRef.current.focus();
    if (dateRangeList.length === 0) 
       GetDateRange();
    GetJourneysFromServer();
  }, [page, pageSize]);

  const ViewReservation = async (
    reference: string,
    journeyUId: string,
    productUI: string
  ) => {
    setjourneyUId(journeyUId);
    setreference(reference);
    switch (productUI.toUpperCase().toString()) {
      case JourneyType.dailyRental:
        appInsights.trackEvent({
          name: "View DR Reservation",
          properties: {
            Reference: reference,
            JourneyUId: journeyUId,
            ProductUI: productUI,
          },
        });
        history(`/reservation/ViewDR/${journeyUId}/${reference}`);
        break;
      case JourneyType.mileageReimbursement:
        appInsights.trackEvent({
          name: "View MR Reservation",
          properties: {
            Reference: reference,
            JourneyUId: journeyUId,
            ProductUI: productUI,
          },
        });
        history(`/reservation/ViewMR/${journeyUId}/${reference}`);
        break;
      case JourneyType.carClub:
        appInsights.trackEvent({
          name: "View CC Reservation",
          properties: {
            Reference: reference,
            JourneyUId: journeyUId,
            ProductUI: productUI,
          },
        });
        setisView(true);
        setisReauth(false);
        if (!isCCLoggedIn) handleOpenCCLogin();
        else {
          setLoading(true);
          const reAuth = (await ReAuthenticateCarClub(
            userCarClubId
          )) as reAuthenticationStatus;
          setLoading(false);
          if (reAuth.reAuthenticated) {
            history({
              pathname: `/reservation/ViewCC/${journeyUId}/${reference}`,
            });
          } else {
            setisReauth(true);
            handleOpenCCLogin();
          }
        }
        break;
      case JourneyType.specialityVehicle:
        appInsights.trackEvent({
          name: "View SV Reservation",
          properties: {
            Reference: reference,
            JourneyUId: journeyUId,
            ProductUI: productUI,
          },
        });
        history(`/reservation/ViewSV/${journeyUId}/${reference}`);
        break;
      default:
        appInsights.trackEvent({
          name: "View JP Reservation",
          properties: {
            Reference: reference,
            JourneyUId: journeyUId,
            ProductUI: productUI,
          },
        });
        history(`/reservation/ViewJP/${journeyUId}/${reference}`);
        break;
    }
  };

  function handleCancelJourneySuccessful() {
    GetJourneys(false);
  }

  const CancelReservation = async (
    reference: string,
    journeyUId: string,
    productUI: string,
    hasReservation: boolean
  ) => {
    if(hasUserViewOnlyPermission){
      dispatch(showErrorAlertMessage(t("ViewOnlyError")));
      dispatch(broadcastAnnouncement(t("ViewOnlyError")));
      window.scroll(0, 0);
    }else{
      setjourneyUId(journeyUId);
      sethasReservation(hasReservation);
      setreference(reference);
      setProductUId(productUI);
      appInsights.trackEvent({
        name: "Cancel Reservation",
        properties: {
          Reference: reference,
          JourneyUId: journeyUId,
          ProductUI: productUI,
          HasReservation: hasReservation,
        },
      });
      if (productUI.toUpperCase().toString() === JourneyType.carClub) {
        setisView(false);
        setisReauth(false);
        if (!isCCLoggedIn) handleOpenCCLogin();
        else {
          setLoading(true);
          const reAuth = (await ReAuthenticateCarClub(
            userCarClubId
          )) as reAuthenticationStatus;
          setLoading(false);
          if (reAuth.reAuthenticated) {
            handleCCOpenYesNo();
          } else {
            setisReauth(true);
            handleOpenCCLogin();
          }
        }
      } else {
        handleOpenYesNoDialog();
      }
    }
  };

  function handleOpenYesNoDialog() {
    setopenYesNo(true);
  }

  const handleYesNoClose = () => {
    setopenYesNo(false);
  };

  const handleLoading = (isloading) => {
    setLoading(isloading);
  };

  const handleOpenCCLogin = () => {
    setOpen(true);
  };
  const handleCCClose = () => {
    setOpen(false);
  };

  const handleCCOpenYesNo = () => {
    setOpenCcYesNo(true);
  };

  const resetAllCache = () => {
    dispatch(clearAlertMessage());
    dispatch(resetReservation());
    dispatch(resetDriverDetails());
    dispatch(resetBookingReferenceDetails());
    dispatch(resetAccountLocationDetails());
  }
  const handleCCOpenYesNoClose = () => {
    setOpenCcYesNo(false);
  };
  const editResAfterValidate = async (row: any) => {
    if(hasUserViewOnlyPermission){
      dispatch(showErrorAlertMessage(t("ViewOnlyError")));
      dispatch(broadcastAnnouncement(t("ViewOnlyError")));
      window.scroll(0, 0);
    }else{    
      resetAllCache();  
    
    const dateStr = moment(row.startDateTime, ukDate).format(
      "YYYY-MM-DD[T]HH:mm:SS"
    );
    const endDateStr = moment(row.endDateTime, ukDate).format(
      "YYYY-MM-DD[T]HH:mm:SS"
    );
    if (dateStr && endDateStr) {
      await axiosInstance
        .get(
          `reservationwizard/validatestartandenddate?startdatetime=${dateStr.toString()}&enddatetime=${endDateStr.toString()}`
        )
        .then((response) => {
          console.log("dateinpast: ", response.data, companyInfo);
          if (response.data.success && !response.data.data.isStartDateInPast)
            //before reservation starts
            history(`/reservationwizard?mode=3&id=${row.journeyUId}&type=1`);
          else if (
            response.data.success &&
            response.data.data.isStartDateInPast &&
            companyInfo?.isRentalMgmtEnabled &&
            (
              (companyInfo?.isDriverValidityActivated && !response.data.data.isEndDateInPast)
              ||
              (!companyInfo?.isDriverValidityActivated && isJourneyEndDateStillWithinRentalMgmtWindow(endDateStr))
            )
          )
          //during reservation
          history(
            `/reservation/EditDR/${row.journeyUId}/${row.referenceNumber}`
          );
          else {
            //after reservation
            axiosInstance
              .get(
                `crossdomain/getbranchdetails?stationidorpeoplesoftid=${row.startLocationId.toString()}`
              )
              .then((response) => {
                const branch = response.data.data;

                console.log("Branch details: ", branch);
                dispatch(
                  showErrorAlertMessage(
                    t("resPastStartDateMessage", {
                      resNo: row.referenceNumber,
                      branchName: branch.locationName,
                      branchContact: branch.formattedPhoneNumber,
                    })
                  )
                );
                dispatch(
                  broadcastAnnouncement(
                    t("resPastStartDateMessage", {
                      resNo: row.referenceNumber,
                      branchName: branch.locationName,
                      branchContact: branch.formattedPhoneNumber,
                    })
                  )
                );
              });
            window.scroll(0, 0);
          }
        })
        .catch((error) => {
          console.error("There was an error in validating start date!", error);
        });
    }
  }
  };
  const EditReservation = (row: any) => {
    switch (row.productUId.toUpperCase().toString()) {
      case JourneyType.dailyRental:
        editResAfterValidate(row);
        break;
      default:
        break;
    }
    appInsights.trackEvent({
      name: "Edit Reservation",
    });
  };
  const RebookReservation = (journeyID: string, productUI: string) => {
    if(hasUserViewOnlyPermission){
      dispatch(showErrorAlertMessage(t("ViewOnlyError")));
      dispatch(broadcastAnnouncement(t("ViewOnlyError")));
      window.scroll(0, 0);
    }else{
      resetAllCache();
      appInsights.trackEvent({
        name: "Rebook Reservation",
        properties: { JourneyID: journeyID, ProductUI: productUI },
      });
      if (productUI.toUpperCase().toString() === JourneyType.dailyRental)
        history(`/reservationwizard?mode=3&id=${journeyID}&type=2`);
      else history("/home?id=" + journeyID);
    }
  };
  const handleSearchKeyChange = (params) => {
    setSearchKey(params.target.value);
  };
  const handleSearchClick = () => {
    dispatch(broadcastAnnouncement(t("Search")));
    setPage(0);
    setNextPreviousPageMessage("");
    GetJourneys(false);
  };
  const handleExportClick = () => {
    dispatch(clearAlertMessage());
    dispatch(broadcastAnnouncement(t("Export")));
    GetExports(5000);
  };
  const keyPress = (e) => {
    if (e.keyCode === 13) {
      dispatch(broadcastAnnouncement(t("Search")));
      setPage(0);
      setNextPreviousPageMessage("");
      GetJourneys(false);
      if (titleRef.current !== null) titleRef.current.focus();
    }
  };
  function handleClearClick() {
    if (resetState()) {
      GetJourneys(true);
    }
  }

  function resetState() {
    setdateRangeOption("-1");
    setHideDateRange(true);
    setPage(0);
    setPageSize(10);
    setSearchKey("");
    setNewJourneyRows([]);
    setNewJourneyRowCount(0);
    setSearchStartDate("");
    setSearchEndDate("");
    setNextPreviousPageMessage("");
    return true;
  }

  function defaultLabelDisplayedRows({ from, to, count }) {
    const fm = newJourneyRowCount === 0 ? 0 : page * pageSize + 1;
    const tt = fm + pageSize - 1;
    return `${fm}-${tt > count ? count : tt} ${t("Users.of")} ${
      count !== -1 ? count : `more than ${to}`
    }`;
  }

  const handleRowsPerPageChangeClick = (event) => {
    setNextPreviousPageMessage("");
    setPageSize(event.target.value);
    setPage(0);
  };

  const handlePageChangeClick = (event, pageno) => {
    if (pageno > page) {
      setNextPreviousPageMessage(t("Users.nextPageLoadedMessage"));
    } else {
      setNextPreviousPageMessage(t("Users.previousPageLoadedMessage"));
    }
    setPage(pageno);
  };

  const GetJourneys = async (isDefault) => {
    setLoading(true);
    var pageNumber = page;
    var pagesize = pageSize;
    var querySearch = searchKey;
    var startDate = searchStartDate;
    var endDate = searchEndDate;
    if (nextPreviousPageMessage === "") pageNumber = 0;
    if (isDefault) {
      pageNumber = 0;
      pagesize = 10;
      querySearch = "";
      startDate = "";
      endDate = "";
    }
    await axiosInstance
      .post("myjourney/search", {
        pageNumber: pageNumber,
        pageSize: pagesize,
        sortOrder: "",
        querySearch: querySearch,
        startDate: startDate,
        endDate: endDate,
      })
      .then((response) => {
        setNewJourneyRows(response.data.data);
        setNewJourneyRowCount(response.data.totalRecords);
        setPageRowCount(response.data.data.length);
        setLoading(false);
        setExportKey(querySearch);
        setExportStartDate(startDate);
        setExportEndDate(endDate);
        appInsights.trackEvent({
          name: "Fetch journey data",
          properties: {
            PageNumber: pageNumber,
            PageSize: pagesize,
            SortOrder: "",
            QuerySearch: querySearch,
            StartDate: startDate,
            EndDate: endDate,
          },
        });

        if (
          querySearch == "" &&
          startDate == "" &&
          endDate == "" &&
          pageNumber == 0
        ) {
          if (response.data.totalRecords > 0) {
            dispatch(
              broadcastAnnouncement(
                t("ResultFoundMessageOnLoad", {
                  Number: response.data.totalRecords.toString(),
                })
              )
            );
          } else {
            dispatch(broadcastAnnouncement(t("EmptyTable")));
          }
        } else {
          if (response.data.totalRecords > 0) {
            if (nextPreviousPageMessage === "") {
              dispatch(
                broadcastAnnouncement(
                  response.data.totalRecords.toString() +
                    " " +
                    t("ResultFoundMessage")
                )
              );
            } else {
              dispatch(broadcastAnnouncement(nextPreviousPageMessage));
            }
          } else {
            dispatch(broadcastAnnouncement(t("EmptyTable")));
          }
        }
      })

      .catch((error) => {
        setNewJourneyRows([]);
        setNewJourneyRowCount(0);
        setPageRowCount(0);
        setLoading(false);
        console.error("There was an error!", error);
        appInsights.trackException({
          properties: {
            method: "GetJourneys",
            Component: "myJourneys",
          },
          exception: error,
          severityLevel: SeverityLevel.Error,
        });
      });
  };

  const GetExports = (maxRecordLimit: number = 3000) => {
    setLoading(true);
    var querySearch = exportKey;
    var startDate = exportStartDate;
    var endDate = exportEndDate;
    
    const result = axiosInstance
      .post("myjourney/export", {
        pageNumber: 0,
        pageSize: maxRecordLimit,
        sortOrder: "",
        querySearch: querySearch,
        startDate: startDate,
        endDate: endDate,
      })
      .then((response) => {
        console.log("querySearch=", querySearch, "report=", response.data.data);
        setLoading(false);

        if (response.data.totalRecords === 0) {
          dispatch(broadcastAnnouncement(t("EmptyTable")));
          dispatch(showInfoAlertMessage(t("EmptyTable")));
        } else if (response.data.totalRecords > maxRecordLimit) {
          dispatch(broadcastAnnouncement(t("TooManyRecords")));
          dispatch(showErrorAlertMessage(t("TooManyRecords")));
        } else {
          //add header
          var exportStr: string[] = [
            reportColumns
              .map((item) => `"${t("Reporting." + item)}"`)
              .join(","),
          ];
          //add reservation records
          response.data.data.forEach((rec) => {
            exportStr = exportStr.concat([
              reportColumns
                .map((item) => `"${getReportColumnValue(rec, item)}"`)
                .join(","),
            ]);
          });

          const element = document.createElement("a");
          const file = new Blob(
            [new Uint8Array([0xef, 0xbb, 0xbf]), exportStr.join("\r\n")],
            {
              type: "text/plain;charset=utf-8",
            }
          );
          element.href = URL.createObjectURL(file);
          let date = new Date();
          element.download = `Export_${date.valueOf()}.csv`;
          document.body.appendChild(element);
          element.click();

          dispatch(
            broadcastAnnouncement(
              response.data.totalRecords.toString() +
                " " +
                t("ResultFoundMessage")
            )
          );
        }

        appInsights.trackEvent({
          name: "Fetch export data",
          properties: {
            pageNumber: 0,
            pageSize: maxRecordLimit,
            sortOrder: "",
            querySearch: querySearch,
            startDate: startDate,
            endDate: endDate,
          },
        });
      })
      .catch((error) => {
        setLoading(false);
        console.error("There was an error!", error);
        dispatch(showErrorAlertMessage(t("UnexpectedError")));
        dispatch(broadcastAnnouncement(t("UnexpectedError")));
        appInsights.trackException({
          properties: {
            method: "GetExports",
            component: "myJourneys",
          },
          exception: error,
          severityLevel: SeverityLevel.Error,
        });
      });
  };

  const getReportColumnValue = (recObj, fieldName) => {
    let value = recObj[fieldName];
    switch (fieldName) {
      case "brand":
        return value ? BrandType[value] : "";
      case "rentalTypeName":
        return value
          ? t(
              "rentalType." +
                value.replaceAll(" ", "").charAt(0).toLowerCase() +
                value.replaceAll(" ", "").slice(1)
            )
          : "";
      /* case "referenceNumber":
        return value ? value.replace("COUNT", "") : ""; */
      case "reservationCreated":
      case "reservationModified":
      case "startDateTime":
      case "endDateTime":
        return value ? moment(value).format(dateFormat) : "";
      case "distanceTravelled":
        return value
          ? convertKmToMiles(value, locale)
              .replace(" km", "")
              .replace(" miles", "")
          : "";
      case "dayRate":
      case "weekRate":
      case "monthRate":
      case "estimateTotal":
        return value ? getLocaleAmount(value, locale) : "";
      case "status":
        return value ? getTranslatedStatus(value) : "";
      default:
        return value ?? "";
    }
  };

  const getTranslatedStatus = (currentStatus) => {
    try{
      if(currentStatus == undefined || currentStatus == null)
        return "";

      switch(currentStatus.toLowerCase()){
        case "pending":
          return t("Res.pending");
        case "open":
          return t("Res.open");
        case "confirmed":
          return t("Res.confirmed");
        case "checkedout":
          return t("Res.checkedOut");
        case "noshow":
          return t("Res.noShow");
        case "cancelled":
          return t("Res.cancelled");
        case "closed":
          return t("Res.closed");
        case "turndown":
          return t("Res.turnDown");
        case "wrapup":
          return t("Res.wrapUp");
        case "ticket":
          return t("Res.ticket");
        case "invoiced":
          return t("Res.invoiced");
        case "direct bill":
          return t("Res.directbill");
        case "card payment":
            return t("Res.cardpayment");
        default:
          return "";
      }
    } catch(err){
      console.log(err);
      return "";
    }
  };

  const searchDateFunctions = (event) => {
    // console.log(event.target.value);
    setinvalidStart(false);
    setinvalidEnd(false);
    setSearchStartSelectedDate(moment().toDate());
    setSearchEndSelectedDate(moment().toDate());
    setdateRangeOption(event.target.value);
    switch (event.target.value) {
      case "Today":
        setDateRange(today, today);
        break;
      case "Last7Days":
        setDateRange(
          moment().subtract(7, "days").format(dateFormat),
          moment().format(dateFormat)
        );
        break;
      case "Last30Days":
        setDateRange(moment().subtract(30, "days").format(dateFormat), today);
        break;
      case "ThisMonth":
        setDateRange(
          moment().startOf("month").format(dateFormat),
          moment().endOf("month").format(dateFormat)
        );
        break;
      case "LastMonth":
        setDateRange(
          moment().subtract(1, "months").startOf("month").format(dateFormat),
          moment().subtract(1, "months").endOf("month").format(dateFormat)
        );
        break;
      case "-1":
        setDateRange("", "");
        break;
      default:
        setDateRange("", "");
    }
    if (event.target.value === "CustomRange") {
      setHideDateRange(false);
      setDateRange(today, today);
    } else setHideDateRange(true);
  };
  const setDateRange = (startOfRange, endOfRange) => {
    setSearchStartDate(startOfRange);
    setSearchEndDate(endOfRange);
    appInsights.trackEvent({
      name: "Set Date Range",
      properties: { StartOfRange: startOfRange, EndOfRange: endOfRange },
    });
  };

  const handleStartDateChange = (date) => {
    setSearchStartDate(moment(date).format(dateFormat));
    setSearchStartSelectedDate(date);
    //console.log(moment(date).isAfter(moment(searchEndSelectedDate)));
    if (date == null || date.toString().includes("Invalid"))
      setinvalidStart(true);
    else setinvalidStart(false);
  };

  const handleEndDateChange = (date) => {
    if (date == null || date.toString().includes("Invalid"))
      setinvalidEnd(true);
    else setinvalidEnd(false);
    setSearchEndSelectedDate(date);
    setSearchEndDate(moment(date).format(dateFormat));
  };


  appInsights.trackEvent({
    name: "myJourneys Component",
  });

  return (
    <Box
      data-testid="myJourneysResultBox"
      id="myJourneysResultBox"
      boxShadow={3}
      p={2}
      m={1}
      mt={0}
      className={classes.root}
    >
      <Backdrop className={classes.backdrop} open={Loading}>
        <CircularProgress />
      </Backdrop>
      <Dialog
        aria-labelledby="cc-dialog-title"
        aria-describedby="cc-dialog-description"
        open={open}
        TransitionProps={{ role: "presentation" } as any}
      >
        <CarClubLogin
          id="carClubDialog"
          journeyUId={journeyUId}
          reference={reference}
          isReauth={isReauth}
          isView={isView}
          handleCancelJourneySuccessful={handleCancelJourneySuccessful}
          action={handleCCClose}
        />
      </Dialog>
      <Dialog
        aria-labelledby="cc-yesno-dialog-title"
        aria-describedby="cc-yesno-dialog-description"
        open={ccOpenYesNo}
        TransitionProps={{ role: "presentation", visibility: "hidden" } as any}
      >
        <CarClubYesNo
          id="carClubYesNoDialog"
          journeyUId={journeyUId}
          reference={reference}
          handleLoading={handleLoading}
          isReauth={isReauth}
          handleCancelJourneySuccessful={handleCancelJourneySuccessful}
          action={handleCCOpenYesNoClose}
          dialogType="cancel"
          confirmMessage={t("cancelCCResConfirmationMessage")}
        />
      </Dialog>

      <Dialog
        aria-labelledby="yesno-dialog-title"
        aria-describedby="yesno-dialog-description"
        open={openYesNo}
        TransitionProps={{ role: "presentation" } as any}
      >
        <YesNoDialog
          journeyUId={journeyUId}
          reference={reference}
          hasReservation={hasReservation}
          handleCancelJourneySuccessful={handleCancelJourneySuccessful}
          action={handleYesNoClose}
          handleLoading={handleLoading}
          productUId={productUId}
        />
      </Dialog>

      <Grid container>
        <Grid container item xs={12} md={12}>
          <div ref={titleRef} tabIndex={-1} className="visuallyHidden">
            {t("MyJourneys")}
          </div>
          <Typography id="titleHeader" variant="h1">
            {t("MyJourneys")}
          </Typography>
        </Grid>
        <Grid container item xs={12} md={12} direction="row">
          <Grid item id="searchParam-container" xs={12} md={4}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: 10,
                marginTop: 10,
              }}
            >
              <label 
                data-testid="searchKeyLabel"
                htmlFor="searchKey"
                className={classes.labelText} 
              >
                {t("Search")}
              </label>
              <IconButton
                id="infoIcon"
                size="small"
                color="primary"
                style={{
                  paddingBottom: 8,
                  marginLeft: 3,
                  height: 15,
                  width: 15,
                }}
                title={t("SearhCriteriaDesc")}
                aria-label={t("SearhCriteriaDesc")}
                //</div>role="img"
              >
                <InfoIcon fontSize="small" />
              </IconButton>
            </div>
            <TextField
              sx={{ minWidth: "220px", margin: "3px" }}
              value={searchKey}
              label={t("SearchCriteria")}
              onChange={handleSearchKeyChange}
              onKeyDown={keyPress}
              id="searchKey"
              margin="dense"
              size="small"
              variant="outlined"
            />
          </Grid>
          <Grid item id="dateRangeOptions-container" xs={12} md={2}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: 10,
                marginTop: 10,
              }}
            >
              <label 
                data-testid="selectAPeriodLabel"
                htmlFor="dateRangeInput"
                className={classes.labelText} 
              >
                  {t("selectAPeriod")}
              </label>
              {" "}
            </div>
            <FormControl id="dateRange">
              <TextField
                id="dateRangeOptions"
                variant="outlined"
                size="small"
                style={{ width: "180px", margin: 10 }}
                value={dateRangeOption}
                margin="dense"
                onChange={searchDateFunctions}
                select
                inputProps={{
                  "aria-label": t("Period"),
                  id: "dateRangeInput",
                  title: t("selectAPeriod"),
                }}
                SelectProps={{
                  native: true,
                }}
              >
                {dateRangeList?.map((option: any) => (
                  <option key={option.value} value={option.value}>
                    {t(
                      "dateRange." +
                        getEnumKeyByEnumValue(DateRangeType, option.value)
                    )}
                  </option>
                ))}
              </TextField>
            </FormControl>
          </Grid>
          <Grid item id="customDateRange" xs={12} md={6} hidden={hideDateRange}>
            <Grid
              container
              item
              xs={12}
              md={12}
              direction="row"
              style={{ marginLeft: 10, marginTop: 22 }}
            >
              <Grid item xs={12} md={5} style={{ margin: 10, marginTop: 0 }}>
                <Typography variant="h2">
                  {t("Start")}
                </Typography>
                <EtdDatePickerV2
                  id="startDatePicker"
                  errorMessageId="startDateErrorMsg"
                  isPastDateAllowed={true}
                  //localeDateTimeFormat={localeDateTimeFormat}
                  value={searchStartSelectedDate ?? null}
                  handleChange={(e) => {
                    handleStartDateChange(e);
                  }}
                  handleBlur={(e) => {}}
                  isError={invalidStart}
                  aria-describedby="startDateErrorMsg"
                />
                {invalidStart ? (
                  <span
                    className={classes.errorWarning}
                    id="startDateErrorMsg"
                  >
                    {t("InvalidDate")}
                  </span>
                ) : (
                  <span id="startDateErrorMsg"></span>
                )}
              </Grid>
              <Grid item xs={12} md={5} style={{ margin: 10, marginTop: 0 }}>
                <Typography variant="h2">
                  {t("End")}
                </Typography>
                <EtdDatePickerV2
                  id="endDatePicker"
                  errorMessageId="endDateErrorMsg"
                  isPastDateAllowed={true}
                  //localeDateTimeFormat={localeDateTimeFormat}
                  value={searchEndSelectedDate ?? null}
                  handleChange={(e) => {
                    handleEndDateChange(e);
                  }}
                  handleBlur={(e) => {}}
                  isError={invalidEnd}
                  aria-describedby="endDateErrorMsg"
                />
                {invalidEnd ? (
                  <span
                    className={classes.errorWarning}
                    id="endDateErrorMsg"
                  >
                    {t("InvalidDate")}
                  </span>
                ) : (
                  <span id="endDateErrorMsg"></span>
                )}                
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ marginLeft: 10, marginTop: 10 }} md={12}>
          <Box display="flex" flexDirection="row" flexGrow={1}>
            <Button
              id="journeyListSearchButton"
              disabled={invalidStart || invalidEnd}
              sx={sxProps.button}
              variant="contained"
              color="primary"
              onClick={handleSearchClick}
              disableElevation
            >
              {" "}
              {t("Search")}{" "}
            </Button>
            <Button
              id="clearSearchButton"
              classes={{ outlinedPrimary: classes.outlinedButton }}
              sx={sxProps.button}
              color="primary"
              variant="outlined"
              onClick={handleClearClick}
            >
              {" "}
              {t("ClearAll")}{" "}
            </Button>
            <Button
              id="journeyListExportButton"
              disabled={invalidStart || invalidEnd}
              sx={{ textTransform: "none" }}
              variant="contained"
              color="primary"
              onClick={handleExportClick}
              disableElevation
            >
              {" "}
              {t("Export")}{" "}
            </Button>
          </Box>
        </Grid>
        <Grid container item xs={12} md={12}>
          <Grid item xs={12} md={12}>
            <br />
            <Divider sx={{ backgroundColor: "black" }} />
            <br />
          </Grid>
        </Grid>

        <Grid
          container
          item
          xs={12}
          md={12}
          sx={{ display: "flex", width: "100%" }}
        >
          <TableContainer component={Paper} id="myTesttable">
            <Table className={classes.table} >
              <TableHead>
                <TableRow>
                  <StyledTableCell width="155">
                    {t("rentalType.rentalType")}
                  </StyledTableCell>
                  {isMobile || isTablet ? null : (
                    <StyledTableCell width="130">
                      <div className={classes.preWrap}>
                        <p
                          style={{
                            lineHeight: "20px",
                            fontSize: "0.98em",
                            fontWeight: "bold",
                          }}
                        >
                          {t("ReferenceNumber")}
                        </p>
                      </div>
                    </StyledTableCell>
                  )}
                  {isMobile || isTablet ? null : (
                    <StyledTableCell width="130">
                      {t("DriverName")}
                    </StyledTableCell>
                  )}
                  <StyledTableCell width="140">
                    {t("StartDateTime")}
                  </StyledTableCell>
                  <StyledTableCell width="140">
                    {t("EndDateTime")}
                  </StyledTableCell>
                  {isMobile || isTablet ? null : (
                    <StyledTableCell width="130">
                      {t("StartLocation")}
                    </StyledTableCell>
                  )}
                  {isMobile || isTablet ? null : (
                    <StyledTableCell width="90">
                      <div className={classes.preWrap}>
                        <p
                          style={{
                            lineHeight: "20px",
                            fontSize: "0.98em",
                            fontWeight: "bold",
                          }}
                        >
                          {t("Distance")} {inMiles ? t("InMiles") : t("InKMs")}
                        </p>
                      </div>
                    </StyledTableCell>
                  )}
                  <StyledTableCell width="90">{t("Status")}</StyledTableCell>
                  <StyledTableCell width="145">{t("Actions")}</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody data-testid="mytable">
                {newJourneyRowCount === 0 ? (
                  <StyledTableRow tabIndex={0}>
                    <TableCell
                      colSpan={9}
                      sx={{
                        textAlign: "center",
                        justifyContent: "center",
                        marginTop: "2em",
                      }}
                    >
                      {t("EmptyTable")}
                    </TableCell>
                  </StyledTableRow>
                ) : (
                  newJourneyRows.map((row: any, index: number) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell width="155">
                        <Typography variant={"body2"}>
                          {t(
                            "rentalType." +
                              getEnumKeyByEnumValue(JourneyType, row.productUId)
                          ) || ""}
                        </Typography>
                      </StyledTableCell>
                      {isMobile || isTablet ? null : (
                        <StyledTableCell width="130">
                          <Typography variant={"body2"}>
                            {row.referenceNumber.replace("COUNT", "") || ""}
                          </Typography>
                        </StyledTableCell>
                      )}
                      {isMobile || isTablet ? null : (
                        <StyledTableCell width="130">
                          <Typography
                            className={classes.preWrapCell}
                            variant={"body2"}
                          >
                            {row.driverName || ""}
                          </Typography>
                        </StyledTableCell>
                      )}
                      <StyledTableCell width="140">
                        <Typography variant={"body2"}>
                          {moment(row.startDateTime, ukDate).format(
                            dateFormat
                          ) || ""}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell width="140">
                        <Typography variant={"body2"}>
                          {moment(row.endDateTime, ukDate).format(dateFormat) ||
                            ""}
                        </Typography>
                      </StyledTableCell>
                      {isMobile || isTablet ? null : (
                        <StyledTableCell component="th" scope="row">
                          <div
                            className={classes.preWrapCell}
                            style={{
                              width: "130px",
                            }}
                          >
                            {row.startLocation}
                          </div>
                        </StyledTableCell>
                      )}
                      {isMobile || isTablet ? null : (
                        <StyledTableCell width="90">
                          <Typography variant={"body2"}>
                            {getLocaleAmount(row.distanceTravelled, locale) ||
                              ""}
                          </Typography>
                        </StyledTableCell>
                      )}
                      <StyledTableCell width="90">
                        <Typography variant={"body2"}>
                          {getTranslatedStatus(row.status) || ""}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell width="145">
                        <Box
                          id={
                            "ActionButtons_" +
                            row.referenceNumber.replace("COUNT", "")
                          }
                          flexDirection="row"
                          alignItems="center"
                          display="flex"
                        >
                          <AccessibleTooltip
                            text={
                              row.canView
                                ? t("ViewFormat", {
                                    Reference: row.referenceNumber.replace(
                                      "COUNT",
                                      ""
                                    ),
                                  }).toString()
                                : t("ViewDisabledFormat", {
                                    Reference: row.referenceNumber.replace(
                                      "COUNT",
                                      ""
                                    ),
                                  }).toString()
                            }
                          >
                            <span
                              role="note"
                              style={{
                                marginTop: 11,
                                marginBottom: 11,
                                display: "inline-grid",
                              }}
                            >
                              <div>
                                <IconButton
                                  id={
                                    "viewJourneyButton" +
                                    row.referenceNumber.replace("COUNT", "")
                                  }
                                  //role="img"
                                  color="primary"
                                  size="small"
                                  disabled={!row.canView}
                                  aria-label={
                                    row.canView
                                      ? t("ViewFormat", {
                                          Reference:
                                            row.referenceNumber.replace(
                                              "COUNT",
                                              ""
                                            ),
                                        })
                                      : t("ViewDisabledFormat", {
                                          Reference:
                                            row.referenceNumber.replace(
                                              "COUNT",
                                              ""
                                            ),
                                        })
                                  }
                                  onClick={() =>
                                    ViewReservation(
                                      row.referenceNumber || "",
                                      row.journeyUId || "",
                                      row.productUId
                                    )
                                  }
                                  style={
                                    row.canView
                                      ? {
                                          marginRight: "2px",
                                          border: " 1px solid #547D66",
                                          textAlign: "left",
                                          color: "#2F77C6",
                                        }
                                      : {
                                          marginRight: "2px",
                                          textAlign: "left",
                                          color: "#7e817e",
                                          border: " 1px solid #737774",
                                        }
                                  }
                                >
                                  <ArrowForwardIcon />
                                </IconButton>
                              </div>
                            </span>
                          </AccessibleTooltip>
                          <AccessibleTooltip
                            text={
                              row.canEdit
                                ? t("EditJourney", {
                                    Reference: row.referenceNumber.replace(
                                      "COUNT",
                                      ""
                                    ),
                                  }).toString()
                                : t("EditDisabled").toString()
                            }
                          >
                            <span
                              role="note"
                              style={{
                                marginTop: 11,
                                marginBottom: 11,
                                display: "inline-grid",
                              }}
                            >
                              <div>
                                <IconButton
                                  id={
                                    "editJourneyButton" +
                                    row.referenceNumber.replace("COUNT", "")
                                  }
                                  //role="img"
                                  color="primary"
                                  size="small"
                                  disabled={!row.canEdit}
                                  aria-label={t("EditJourney", {
                                    Reference: row.referenceNumber.replace(
                                      "COUNT",
                                      ""
                                    ),
                                  }).toString()}
                                  onClick={() => EditReservation(row)}
                                  style={
                                    row.canEdit
                                      ? {
                                          marginRight: "2px",
                                          border: " 1px solid #547D66",
                                          textAlign: "left",
                                          color: "#006639",
                                        }
                                      : { display: "none" }
                                  }
                                >
                                  <EditIcon />
                                </IconButton>
                              </div>
                            </span>
                          </AccessibleTooltip>
                          <AccessibleTooltip
                            text={
                              row.canCopy
                                ? t("BookAgainFormat", {
                                    Reference: row.referenceNumber.replace(
                                      "COUNT",
                                      ""
                                    ),
                                  }).toString()
                                : t("BookAgainDisabledFormat", {
                                    Reference: row.referenceNumber.replace(
                                      "COUNT",
                                      ""
                                    ),
                                  }).toString()
                            }
                          >
                            <span
                              role="note"
                              style={{
                                marginTop: 11,
                                marginBottom: 11,
                                display: "inline-grid",
                              }}
                            >
                              <div>
                                <IconButton
                                  id={
                                    "repeatJourneyButton" +
                                    row.referenceNumber.replace("COUNT", "")
                                  }
                                  //role="img"
                                  color="primary"
                                  size="small"
                                  disabled={!row.canCopy}
                                  aria-label={
                                    row.canCopy
                                      ? t("BookAgainFormat", {
                                          Reference:
                                            row.referenceNumber.replace(
                                              "COUNT",
                                              ""
                                            ),
                                        })
                                      : t("BookAgainDisabledFormat", {
                                          Reference:
                                            row.referenceNumber.replace(
                                              "COUNT",
                                              ""
                                            ),
                                        })
                                  }
                                  onClick={() =>
                                    RebookReservation(
                                      row.journeyUId || "",
                                      row.productUId
                                    )
                                  }
                                  style={
                                    row.canCopy
                                      ? {
                                          marginRight: "2px",
                                          border: "1px solid #547D66",
                                          textAlign: "left",
                                          color: "#000000",
                                        }
                                      : {
                                          marginRight: "2px",
                                          border: "1px solid #737774",
                                          textAlign: "left",
                                          color: "#7e817e",
                                        }
                                  }
                                >
                                  <RefreshIcon />
                                </IconButton>
                              </div>
                            </span>
                          </AccessibleTooltip>
                          <AccessibleTooltip
                            text={
                              row.canDelete
                                ? t("CancelFormat", {
                                    Reference: row.referenceNumber.replace(
                                      "COUNT",
                                      ""
                                    ),
                                  }).toString()
                                : t("CancelDisabledFormat", {
                                    Reference: row.referenceNumber.replace(
                                      "COUNT",
                                      ""
                                    ),
                                  }).toString()
                            }
                          >
                            <span
                              role="note"
                              style={{
                                marginTop: 11,
                                marginBottom: 11,
                                display: "inline-grid",
                              }}
                            >
                              <div>
                                <IconButton
                                  id={
                                    "cancelJourneyButton" +
                                    row.referenceNumber.replace("COUNT", "")
                                  }
                                  //role="img"
                                  color="primary"
                                  size="small"
                                  disabled={!row.canDelete}
                                  aria-label={t("CancelFormat", {
                                    Reference: row.referenceNumber.replace(
                                      "COUNT",
                                      ""
                                    ),
                                  })}
                                  onClick={async () =>
                                    await CancelReservation(
                                      row.referenceNumber || "",
                                      row.journeyUId || "",
                                      row.productUId,
                                      row.hasReservation
                                    )
                                  }
                                  style={
                                    row.canDelete
                                      ? {
                                          border: "1px solid #547D66",
                                          textAlign: "left",
                                          color: "#d02216",
                                        }
                                      : { display: "none" }
                                  }
                                >
                                  <CancelIcon />
                                </IconButton>
                              </div>
                            </span>
                          </AccessibleTooltip>
                        </Box>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={10}>
                    <TablePagination
                      className={classes.pagination}
                      component="div"
                      rowsPerPage={pageSize}
                      rowsPerPageOptions={[10, 20, 50, 100]}
                      count={newJourneyRowCount}
                      page={page}
                      onPageChange={(e, page) => handlePageChangeClick(e, page)}
                      onRowsPerPageChange={handleRowsPerPageChangeClick}
                      labelDisplayedRows={defaultLabelDisplayedRows}
                      labelRowsPerPage={t("Users.rowsPerPage")}
                    />
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Box>
  );
}
