import { appInsights } from "helpers/appInsights";
import axiosInstance from "helpers/axiosInstance";
import { SeverityLevel } from "@microsoft/applicationinsights-common";

export interface reAuthenticationStatus {
  reAuthenticated: boolean;
  hasError: boolean;
  responseMessage: string;
  hasException: boolean;
  exceptionMessage: string;
  isAccountAuthority: boolean;
}

export const ReAuthenticateCarClub = async (userCarClubId) => {
  let result: reAuthenticationStatus = {
    reAuthenticated: false,
    hasError: false,
    responseMessage: "",
    hasException: false,
    exceptionMessage: "",
    isAccountAuthority: false,
  };

  await axiosInstance
    .post(`carclub/reauthenticate/${userCarClubId}`)
    .then(async (response) => {
      if (response.data.success) {
        result.isAccountAuthority =
          response.data.credentials.isAccountAuthority;
        result.reAuthenticated = true;
      } else {
        result.hasError = true;
        result.responseMessage = response.data.message?.trim();
      }
    })
    .catch((error) => {
      appInsights.trackException({
        properties: {
          method: "ReAuthenticate CC",
          Component: "Action",
        },
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
      result.hasException = true;
      result.exceptionMessage = error;
      if (error.response.status != null) {
        // TODO
      } else console.error("There is an error", error);
    });

  return new Promise((resolve) => resolve(result));
};
