import {
  Backdrop,
  Box,
  CircularProgress,
  Dialog,
  Divider,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import Grid from "@mui/material/Grid";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import axiosInstance from "../../helpers/axiosInstance";
import {
  JourneyType,
  ReservationAdditionalInformationFieldTypeCode,
  ReservationStatusType,
} from "./ReservationViewType";
import PrintIcon from "@mui/icons-material/Print";
import moment from "moment";
import { clearAlertMessage } from "components/store/actions/shared/alertActions";
import { useDispatch } from "_testHelpers/react-redux-hooks";
import { ArrowBack } from "@mui/icons-material";
import { appInsights } from "helpers/appInsights";
import { SeverityLevel } from "@microsoft/applicationinsights-common";
import { authService } from "services/authService";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  SpecialityRequest,
  SpecialityVehicleType,
} from "components/Reservation/steps/SpecialityRequest/specialityRequestType";
import { getEnumKeyByEnumValue } from "helpers/enumMapping";
import { ReservationAdditionalInformationFieldViewModel } from "components/Reservation/steps/AdditionalInformation/additionalInformationType";
import YesNoDialog from "controls/YesNoDialog";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
    marginBottom: 20,
    paddingRight: 30,
    paddingTop: 15,
    paddingBottom: 15,
  },
  title: {
    fontSize: "1.4em",
    paddingLeft: 30,
    marginBottom: 10,
  },
  subtitle: {
    fontSize: "1.2em",
    marginTop: 10,
    marginBottom: 10,
  },
  tableHeader: {
    fontWeight: "bold",
    padding: 4,
    borderBottom: "none",
    fontSize: "1.1em",
  },
  tableBody: {
    fontSize: "1.1em",
    padding: 4,
    borderBottom: "none",
  },
  detail: {
    paddingTop: 15,
  },
  divider: {
    backgroundColor: "#000",
    height: "0.8px",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
  rootcell: {
    "& .MuiTableCell-root": {
      verticalAlign: "top",
    },
  },
}));

const ViewSV = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [resData, setResData] = useState<SpecialityRequest>();
  let history = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [openYesNo, setopenYesNo] = React.useState(false);
  const printRef = React.useRef<HTMLButtonElement>(null);
  const titleRef = React.useRef<HTMLElement>(null);
  //const [hasReservation, sethasReservation] = React.useState(false);
  const [cancelJourneySuccessful, setcancelJourneySuccessful] =
    React.useState(false);
  const [cancelDisabled, setCancelDisabled] = React.useState(true);
  const locale = authService.getUserLocale();
  const { journeyID, reference } = useParams<{
    journeyID: string;
    reference: string;
  }>();

  appInsights.trackPageView();
  useEffect(() => {
    console.log("start sv");
    document.title = t("ViewResTitle");
    dispatch(clearAlertMessage());
    setLoading(true);
    GetReservation();
    if (printRef.current != null && !props.disableAutoFocus) printRef.current.focus();
  }, []);

  const GetReservation = async () => {
    try {
      console.log("run api");
      const response = await axiosInstance.get(
        `svreservation/view/${reference}`
      );
      console.log("SV response: ", response.data.data);
      setResData(response.data.data);
      setLoading(false);
      console.log("resData", resData);
    } catch (err) {
      console.log("error occured : ", err);
      appInsights.trackException({
        exception: new Error("An exception has occured " + err),
        id: "ViewSV",
        severityLevel: SeverityLevel.Error,
      });
    }
  };

  function getBookingReference(
    input: ReservationAdditionalInformationFieldViewModel
  ) {
    var singleReference;
    switch (input.fieldTypeCode) {
      case ReservationAdditionalInformationFieldTypeCode.Date:
        singleReference =
          input.fieldName +
          " = " +
          (input.fieldValueDateString === ""
            ? ""
            : new Date(
                parseInt(input.fieldValueDateString.substr(0, 4)),
                parseInt(input.fieldValueDateString.substr(4, 2)) - 1,
                parseInt(input.fieldValueDateString.substr(6, 2))
              ).toLocaleString(locale.toString() === "en-GB" ? "en-GB" : "de-DE", {
                year: "numeric",
                month: "numeric",
                day: "numeric",
              }));
        break;
      default:
        singleReference =
          input.fieldName + " = " + (input.fieldValue ? input.fieldValue : "");
    }
    return singleReference;
  }

  function handleOpenYesNoDialog() {
    setopenYesNo(true);
  }
  const handleYesNoClose = () => {
    setopenYesNo(false);
  };
  const handleLoading = (isloading) => {
    setLoading(isloading);
  };
  function handleCancelJourneySuccessful() {
    setcancelJourneySuccessful(true);
  }
  function isReservationCancelled(
    reservationStatusUId: string | undefined
  ): boolean {
    return (
      !!reservationStatusUId &&
      getEnumKeyByEnumValue(ReservationStatusType, reservationStatusUId) ===
        "cancelled"
    );
  }

  const primaryDriver = resData?.reservation.drivers?.find(
    (x) => x.isPrimaryDriver === true
  );
  const additionalDrivers = resData?.reservation.drivers?.filter(
    (x) => !!x.isPrimaryDriver === false
  );

  return (
    <Box boxShadow={3} m={3}>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress />
      </Backdrop>
      <Grid data-testid="viewSV" className={classes.rootcell} container spacing={3}>
        <Dialog
          aria-labelledby="yesno-dialog-title"
          aria-describedby="yesno-dialog-description"
          open={openYesNo}
        >
          <YesNoDialog
            open={openYesNo}
            journeyUId={journeyID}
            reference={reference}
            hasReservation={true}
            handleCancelJourneySuccessful={handleCancelJourneySuccessful}
            action={handleYesNoClose}
            handleLoading={handleLoading}
            productUId={JourneyType.specialityVehicle}
          />
        </Dialog>
        <Grid id="titleHeader" item xs={12} md={7}>
          <Typography ref={titleRef} className={classes.title} variant="h2">
            {t("SV.svReservationDetails")}
          </Typography>
          <div style={{ paddingLeft: 30 }}>
            <Typography className={classes.subtitle} variant="h3">
              {t("SV.summaryOfYourSvRequest")}
            </Typography>
            {/* <Divider className={classes.divider} />{" "} */}
          </div>
        </Grid>
        <Grid id="actionButtons" item xs={12} md={4}>
          <Box display="flex" style={{ float: "right" }}>
            <IconButton
              id="printJourneyButton"
              size="medium"
              ref={printRef}
              aria-label={t("Res.Print")}
              title={t("Res.PrintTitle")}
              //role="img"
              onClick={() => {
                appInsights.trackEvent({
                  name: "View MR Print",
                  properties: { Reference: reference },
                });
                dispatch(clearAlertMessage());
                window.print();
              }}
              style={{
                marginRight: 5,
                border: "1px solid #547D66",
                color: "#000000",
              }}
            >
              <PrintIcon />
              {t("Print")}
            </IconButton>
            <IconButton
              id="cancelJourneyButton"
              size="medium"
              onClick={() => {
                appInsights.trackEvent({
                  name: "View SV Cancel",
                  properties: { Reference: reference },
                });
                handleOpenYesNoDialog();
              }}
              aria-label={t("CancelJourney")}
              title={t("CancelJourney")}
              disabled={cancelJourneySuccessful} //{isReservationCancelled(resData?.reservation.reservationStatusUId)}
              style={
                cancelJourneySuccessful ||
                isReservationCancelled(
                  resData?.reservation.reservationStatusUId
                )
                  ? {
                      marginRight: 5,
                      border: "1px solid #737774",
                      color: "#7e817e",
                    }
                  : {
                      marginRight: 5,
                      border: "1px solid #547D66",
                      color: "#d02216",
                    }
              }
            >
              <CancelIcon />
              {t("Cancel")}
            </IconButton>
            <IconButton
              id="backButton"
              size="medium"
              aria-label={t("Back")}
              title={t("BackTitle")}
              //role="img"
              onClick={() => {
                dispatch(clearAlertMessage());
                history(-1);
              }}
              style={{ border: "1px solid #547D66", color: "#000000" }}
            >
              <ArrowBack />
              {t("Back")}
            </IconButton>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} className={classes.root}>
          <Typography className={classes.title} variant="h3">
            {t("SV.reservationRequestDetails")}
          </Typography>
          <Box style={{ paddingLeft: 30 }}>
            <Divider
              className={classes.divider}
              style={{ marginTop: 10, marginLeft: 0, marginBottom: 20 }}
            />
            <TableContainer id="detailsTable" className={classes.detail}>
              <Table aria-label="journey table">
                <TableBody>
                  <TableRow>
                    <TableCell width="40%" className={classes.tableHeader}>
                      {t("SV.reservationRequestNumber")}
                    </TableCell>
                    <TableCell
                      id="reservationRequestNumberDiv"
                      className={classes.tableBody}
                    >
                      {resData?.reservation.reservationNumber}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell width="40%" className={classes.tableHeader}>
                      {t("SV.dateRequestSent")}
                    </TableCell>
                    <TableCell
                      id="reservationRequestSentDateDiv"
                      className={classes.tableBody}
                    >
                      {resData?.createdUtc === null
                        ? ""
                        : moment(resData?.createdUtc).format(
                            t("MomentJSDateFormat")
                          )}
                    </TableCell>
                  </TableRow>
                  {isReservationCancelled(
                    resData?.reservation.reservationStatusUId
                  ) ? (
                    <TableRow>
                      <TableCell width="40%" className={classes.tableHeader}>
                        {t("SV.dateRequestCancelled")}
                      </TableCell>
                      <TableCell
                        id="reservationRequestCanceledDateDiv"
                        className={classes.tableBody}
                      >
                        {resData?.modifiedUtc === null
                          ? ""
                          : moment(resData?.modifiedUtc).format(
                              t("MomentJSDateFormat")
                            )}
                      </TableCell>
                    </TableRow>
                  ) : (
                    ""
                  )}
                  <TableRow>
                    <TableCell className={classes.tableHeader}>
                      {t("StartEndLocation")}
                    </TableCell>
                    <TableCell
                      id="startAddressDiv"
                      className={classes.tableBody}
                    >
                      <strong>{t("Start")}:</strong>{" "}
                      {resData?.reservation.startLocation}{" "}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.tableHeader}></TableCell>
                    <TableCell id="endAddressDiv" className={classes.tableBody}>
                      <strong>{t("End")}: </strong>{" "}
                      {resData?.reservation.endLocation}{" "}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      style={{ whiteSpace: "pre-wrap" }}
                      className={classes.tableHeader}
                    >
                      {t("Res.StartEndDateTime")}
                    </TableCell>
                    <TableCell
                      id="startDateTimeDiv"
                      className={classes.tableBody}
                    >
                      <strong>{t("Start")}:</strong>
                      {resData?.reservation.startDateTime === null
                        ? ""
                        : moment(resData?.reservation.startDateTime).format(
                            t("DateTimeFormat")
                          )}{" "}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.tableBody}></TableCell>
                    <TableCell
                      id="endDateTimeDiv"
                      className={classes.tableBody}
                    >
                      <strong>{t("End")}: </strong>{" "}
                      {resData?.reservation.endDateTime === null
                        ? ""
                        : moment(resData?.reservation.endDateTime).format(
                            t("DateTimeFormat")
                          )}{" "}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell width="40%" className={classes.tableHeader}>
                      {t("Res.booker")}
                    </TableCell>
                    <TableCell
                      id="reservationRequestBookerDiv"
                      className={classes.tableBody}
                    >
                      {resData?.reservation.bookerName}
                    </TableCell>
                  </TableRow>
                  {resData?.reservation.additionalInformation != null &&
                    resData?.reservation.additionalInformation
                      .additionalInformationFields.length > 0 && (
                      <TableRow>
                        <TableCell className={classes.tableHeader}>
                          {t("Res.BookingReferences")}{" "}
                        </TableCell>
                        <TableCell
                          id="reservationBookingReferenceDiv"
                          className={classes.tableBody}
                        >
                          {resData?.reservation.additionalInformation.additionalInformationFields?.map(
                            (additionalInformation, index) => {
                              return (
                                <Typography
                                  key={"additionalInformation" + index}
                                >
                                  {" "}
                                  {getBookingReference(
                                    additionalInformation
                                  )}{" "}
                                  <br />{" "}
                                </Typography>
                              );
                            }
                          )}
                        </TableCell>
                      </TableRow>
                    )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography className={classes.title} variant="h3">
            {t("Res.Driver") + " & " + t("Res.VehicleDetails")}
          </Typography>
          <Box style={{ paddingLeft: 30 }}>
            <Divider
              className={classes.divider}
              style={{ marginTop: 10, marginLeft: 0, marginBottom: 20 }}
            />
            <TableContainer id="driverDetailsTable" className={classes.detail}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell width="40%" className={classes.tableHeader}>
                      {t("SV.labelVehicleCategory")}
                    </TableCell>
                    <TableCell
                      id="VehicleCategorydiv"
                      className={classes.tableBody}
                    >
                      {resData?.selectedSpecialityVehicleValue
                        ? t(
                            "SV.vehicleCategory." +
                              getEnumKeyByEnumValue(
                                SpecialityVehicleType,
                                resData?.selectedSpecialityVehicleValue
                              )
                          )
                        : ""}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell width="40%" className={classes.tableHeader}>
                      {t("SV.labelNotesSvType")}
                    </TableCell>
                    <TableCell
                      id="notesSvTypediv"
                      className={classes.tableBody}
                    >
                      {resData?.vehicleTypeNotes}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell width="40%" className={classes.tableHeader}>
                      {t(
                        "ReservationWizard.reservationPage.primaryDriverLabel"
                      )}
                    </TableCell>
                    <TableCell
                      id="driversNamediv"
                      className={classes.tableBody}
                    >
                      {resData?.reservation.driverName.trim()}
                    </TableCell>
                  </TableRow>
                  {additionalDrivers != null &&
                    additionalDrivers.length > 0 && (
                      <TableRow>
                        <TableCell className={classes.tableHeader}>
                          {t("AdditionalDriver")}{" "}
                        </TableCell>
                        <TableCell className={classes.tableBody}>
                          {additionalDrivers?.map((driver, index) => {
                            return (
                              <Typography key={"additionalDriver" + index}>
                                {" "}
                                {driver.firstName + " " + driver.lastName}
                                <br />{" "}
                              </Typography>
                            );
                          })}
                        </TableCell>
                      </TableRow>
                    )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
export default ViewSV;
