import React, {
  Fragment,
  useState,
  useEffect,
  useRef,
  useCallback,
} from "react";
import {
  Backdrop,
  Button,
  CircularProgress,
  Typography,
  Grid,
  FormControl,
  IconButton,
  TextField,
  Box,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  showSuccessAlertMessage,
  showErrorAlertMessage,
} from "components/store/actions/shared/alertActions";
import { broadcastAnnouncement } from "components/store/actions/shared/announcementActions";
import CheckIcon from "@mui/icons-material/Check";
import InfoIcon from "@mui/icons-material/Info";
import axiosInstance from "helpers/axiosInstance";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import makeStyles from "@mui/styles/makeStyles";
import Accordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import withStyles from "@mui/styles/withStyles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
//import classes from "*.module.css";
import {
  MileageReimbursementViewModel,
  AdditionalInformation,
  ReservationAdditionalInformationFieldViewModel,
  DropdownList,
  messageType,
  MileageReimbursementParamViewModel,
} from "../AdditionalInformation/additionalInformationType";

import { Select } from "@mui/material";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { useNavigate } from "react-router-dom";
import { authService } from "services/authService";
import { appInsights } from "helpers/appInsights";
import { SeverityLevel } from "@microsoft/applicationinsights-common";
import EtdDatePickerV2 from "controls/formik/EtdDatePickerV2";
import {
  additionalInformationFormikFormError,
  additionalInformationFormikFormTouched,
  DriverViewModel,
} from "../Reservation/reservationFormType";
import moment from "moment";
import { clearAlertMessage } from "components/store/actions/shared/alertActions";
import { getIntlDateFormatForLocale } from "helpers/localization";
import { displayBoboTabs } from "helpers/driverHelpers";
import DriverDetails from "components/shared/DriverDetails/DriverDetails";
import BOBOSomeone from "components/shared/BOBOSomeone/BOBOSomeone";
import { ReservationMode } from "helpers/useReservationMode";
import { isHiddenAdditionalParameters } from "helpers/additionalParameters";
const formFieldsValidationSchema = Yup.object().shape({
  carClubAvailable: Yup.boolean(), // this is whatever field name i am giving it
});

export function applyAdditionalInformationValidationSchema(
  additionalInformationFields: Array<any>,
  existingformFieldsValidationSchema: any
) {
  //create schema dynamically according to additionalInformationFields[]
  const formSchema = additionalInformationFields.reduce(
    (obj, item, index) => ({
      ...obj,
      ["additionalInformationFields[" + index + "]"]: Yup.object()
        .test(
          "datefieldtest-" + index,
          "Users.Errors.fieldValueFormatInvalidOrInPast",
          function () {
            const ftc =
              this.parent.additionalInformationFields !== undefined
                ? this.parent.additionalInformationFields[index].fieldTypeCode
                : "";
            if (ftc !== 1) {
              return true;
            } else {
              const c =
                this.parent.additionalInformationFields !== undefined
                  ? this.parent.additionalInformationFields[index]
                      .fieldValueDateString
                  : "";
              return c === "FormatError" || c === "DateInPast" ? false : true;
            }
          }
        )
        .test(
          "emptytest-" + index,
          "Users.Errors.fieldValueRequired",
          function () {
            const a =
              this.parent.additionalInformationFields !== undefined
                ? this.parent.additionalInformationFields[index].fieldValue
                : "";
            if (
              !(
                this.parent.additionalInformationFields[index].mandatory ??
                false
              )
            )
              return true;
            else {
              return a ? true : false;
            }
          }
        )
        .test(
          "lengthtest-" + index,
          "Users.Errors.fieldValueMaxLength",
          function () {
            const b =
              this.parent.additionalInformationFields !== undefined
                ? this.parent.additionalInformationFields[index].fieldValue
                : "";
            const maxLength = this.parent.additionalInformationFields[index]
              .maxLength
              ? this.parent.additionalInformationFields[index].maxLength
              : 100;
            return (b ?? "").length > maxLength ? false : true;
          }
        ),
    }),
    { additionalInformationFields: Yup.array() }
  );
  const newSchema = existingformFieldsValidationSchema.shape({
    additionalInformation: Yup.object(formSchema).nullable(),
  });
  console.log("newSchema", newSchema);
  return newSchema;
}

const AccordionSummary = withStyles({
  root: {
    //  flexDirection: "column",
    backgroundColor: "#006639",
    fontWeight: "bold",
    color: "#fff",
    border: "2px solid #006639",
    "&$focused": {
      color: "#006639",
    },
    "&.Mui-expanded": {
      minHeight: "48px",
    },
    "&.Mui-focused": {
      backgroundColor: "#006639",
    },
  },

  content: {
    marginBottom: 0,
    marginTop: 0,
    padding: 0,
    minHeight: "12px",
  },
  expandIcon: {
    marginRight: 0,
    paddingTop: 0,
    color: "#fff",
  },
})(MuiAccordionSummary);

const AccordionDetails = withStyles({
  root: {
    "& ::placeholder": {
      color: "#767676",
      opacity: "1",
    },
  },
})(MuiAccordionDetails);

export default function AdditionalInformationForm(props) {
  const resType = ReservationMode.Rebook;
  const mileageReimbursementViewModelInitialValue = {
    journeyUId: "",
    isMRDriverAccordionToggleEnabled: false,
    isEmailAddressMaskToggleEnabled: false,
    additionalInformation: {
      additionalInformationFields: [
        {
          additionalInformationGid: "",
          customValidate: false,
          dropDownList: [
            {
              disabled: false,
              group: "",
              selected: false,
              text: "",
              value: "",
            },
          ] as DropdownList[],
          dropDownListSelectedValue: "",
          fieldName: "",
          fieldTypeCode: 0,
          fieldValue: "",
          fieldValueDateString: "",
          helperText: "",
          includeInReservation: false,
          isBillingRef: false,
          mandatory: false,
          maxLength: "",
          sequence: 0,
          webApproved: false,
        },
      ] as ReservationAdditionalInformationFieldViewModel[],
      message: "",
      renderTime: false,
    } as AdditionalInformation,
    boboDetailsViewModel: {
      isBOBOToggleEnabled: false,
      boboEnabled: false,
      driverDeclaration: false,
      userPermissions: 0,
    },
    driverViewModel: {
      userTitle: { userTitle: "", selectedValue: "", selectedText: "" },
      firstName: "",
      lastName: "",
      emailAddress: "",
      contactPhoneNumber: {
        contactPhoneNumber: "",
        selectedInternationalDiallingCode: "",
        number: "",
      },
      alternatePhoneNumber: {
        contactPhoneNumber: "",
        selectedInternationalDiallingCode: "",
        number: "",
      },
      isPrimaryDriver: false,
      boboId: 0,
    },
  } as MileageReimbursementViewModel;

  const [loading, setLoading] = useState(false);
  const [loadingReservationDriver, SetLoadingReservationDriver] =
    useState(false);
  const [formValidation, setFormValidation] = useState(
    formFieldsValidationSchema
  );
  const [driverExpanded, setDriverExpanded] = useState(true);
  const [bookingRefExpanded, setBookingRefExpanded] = useState(false);
  const [hasAdditionalFields, setHasAdditionalFields] = useState(false);
  const [driverViewModel, setDriverViewModel] = useState<DriverViewModel>(
    mileageReimbursementViewModelInitialValue.driverViewModel
  );
  const [isValidDriverViewModel, setIsValidDriverViewModel] =
    useState<Boolean>(true);
  const [bookForMyself, setBookForMyself] = useState(true);
  const [showBoboTabs, setShowBoboTabs] = useState(true);
  const [isValidBOBOSomeone, setisValidBOBOSomeone] = useState<boolean>(true);
  const [boboSomeone, setBoboSomeone] = useState<DriverViewModel | null>(null);
  const [driverDeclaration, setDriverDeclaration] = useState(false);
  const formRef = useRef<any>(null);
  const locale = authService.getUserLocale();
  const driverInfo = authService.getDriverInfo();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const localeDateTimeFormat = getIntlDateFormatForLocale(locale);
  const theme = useTheme();
  const isSmXs = useMediaQuery(theme.breakpoints.down("lg"));

  const useStyles = makeStyles({
    root: {
      "& .MuiFormHelperText-root": {
        color: "#DF0000",
        fontSize: 12,
        marginLeft: "15px",
      },
      "& ::placeholder": {
        fontStyle: "italic",
        color: "#000",
        opacity: 0.95,
        whiteSpace: "pre-wrap",
        "@media (max-width:330px)": {
          fontSize: "0.6rem",
        },
      },
      "& .alignItemCentre": {
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
      },
      "& .topLeftZeroPadding": {
        paddingLeft: 0,
        paddingTop: 0,
      },
      "& .alignItemCentreWithH2Font": {
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
        fontVariant: "h2",
      },
      "& .themeheader": {
        borderRight: "1px solid #ddd",
        display: "flex",
      },
      "& .checkBoxBlue": {
        color: "blue",
        fontWeight: 18,
      },
      "& .RedWarningFont": {
        color: "#DF0000",
        marginTop: "15px",
        fontSize: 12,
      },
      "& .LargeTitle": {
        fontSize: 20,
        fontWeight: "bold",
      },
      "& .MidTitle": {
        fontSize: 16,
      },
      "& .NewJourneyTitle": {
        fontSize: 30,
      },
      "& .LargeTitleHR": {
        border: "none",
        //  border: none,
        height: 2,
        backgroundColor: "#111111",
      },
      "& .MidTitleHR": {
        border: "none",
        //  border: none,
        height: 1,
        backgroundColor: "#111111",
      },
      "& .MuiSvgIcon-fontSizeSmall": {
        fontSize: "1rem",
      },
    },
    cards: {
      minWidth: 275,
      marginBottom: 10,
    },

    cardContainter: {
      boxShadow: "none",
    },

    backdrop: {
      zIndex: 99999,
      color: "#fff",
    },
    outlinedButton: {
      border: "1px solid rgba(0, 102, 57, 0.95);",
    },
    txtField: {
      size: "small",
    },
    bullet: {
      display: "inline-block",
      margin: "0 2px",
      transform: "scale(0.8)",
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    table: {
      minWidth: 650,
      borderInline: "0px",
    },
    tableCell: {
      borderBottom: "0px",
      fontWeight: "bold",
    },
    tableheader: { fontWeight: "bold" },
    cardTitle: {
      fontWeight: "bold",
      // backgroundColor: '#dff0d8',
    },
    errorWarning: {
      color: "#DF0000",
      fontSize: "0.75rem",
      marginLeft: "15px",
    },
    labelText: {
      color: "#000",
      fontFamily: "Arial, Roboto, Segoe UI",
      fontSize: 14,
      fontWeight: "bold",
      verticalAlign: "middle",
    },
    focused: { outline: "none" },
    heading: {
      fontSize: "1rem",
      fontWeight: "bold",
    },
    activeButtonClass: {
      textTransform: "none",
      fontWeight: "bold",
      width: 400,
      height: 40,
      border: "2px solid #006639",
    },
    inActiveButtonClass: {
      textTransform: "none",
      width: 400,
      height: 40,
    },
    activeButtonClassSm: {
      textTransform: "none",
      fontWeight: "bold",
      width: 200,
      height: 40,
      border: "2px solid #006639",
    },
    inActiveButtonClassSm: {
      textTransform: "none",
      width: 200,
      height: 40,
    },
  });

  const classes = useStyles();
  const titleRef = useRef<HTMLDivElement>(null);
  const history = useNavigate();

  const onDriverDetailsChange = useCallback(
    (driverDetails: DriverViewModel, isValid: boolean) => {
      setIsValidDriverViewModel(isValid);
      if (isValid) {
        setDriverViewModel(driverDetails);
      }
    },
    []
  );

  const onBOBOUserChange = useCallback(
    (userDetails: DriverViewModel, isValid: boolean) => {
      setisValidBOBOSomeone(isValid);
      if (isValid) {
        setBoboSomeone(userDetails);
      }
    },
    []
  );

  const [mileageReimbursementViewModel, setMileageReimbursementViewModel] =
    useState<MileageReimbursementViewModel>(
      mileageReimbursementViewModelInitialValue
    );
  const [additionalInfoError, setAdditionalInfoError] = useState<messageType[]>(
    []
  );

  function validationErrors(id: string) {
    if (additionalInfoError.length > 0)
      return additionalInfoError.some((item) => item.code === id);
  }

  function convertToDate(dt: any) {
    if (dt === null || dt === "") return null;
    if (dt && typeof dt === "string" && dt.includes("-")) {
      let dateParts = dt.split("-");
      return new Date(+dateParts[0], Number(dateParts[1]) - 1, +dateParts[2]);
    }
    return moment(dt).format("YYYY-MM-DD");
  }

  useEffect(() => {
    dispatch(clearAlertMessage());
    if (titleRef.current !== null) titleRef.current.focus();
    getAdditionalInformation(props.journeyUId, props.origJourneyUId);
  }, []);

  const getPrimaryDriverDetails = async (
    ViewModel,
    firstName,
    lastName,
    email
  ) => {
    if (!firstName && !lastName && !email) return;
    const primaryDriverRequest = {
      FirstName: firstName,
      LastName: lastName,
      Email: email,
    };

    SetLoadingReservationDriver(true);
    var url = `reservation/reservationdriver`;
    await axiosInstance
      .post(url, primaryDriverRequest)
      .then((response) => {
        /* console.log(
          "Res data - getPrimaryDriverDetails: ",
          JSON.stringify(response?.data)
        ); */
        let reservationDriverViewModel = ViewModel.driverViewModel;
        if (response?.data) {
          let data = response.data;
          if (data.contactPhoneNumber !== null) {
            reservationDriverViewModel = {
              ...reservationDriverViewModel,
              contactPhoneNumber: { ...data.contactPhoneNumber },
            };
          }
          if (data.alternatePhoneNumber !== null) {
            reservationDriverViewModel = {
              ...reservationDriverViewModel,
              alternatePhoneNumber: { ...data.alternatePhoneNumber },
            };
          }
        }
        setMileageReimbursementViewModel({
          ...ViewModel,
          driverViewModel: reservationDriverViewModel,
        });
        if (formRef.current) {
          formRef.current.setFieldValue(
            "additionalInformation",
            ViewModel.additionalInformation
          );
          if (formRef.current.touched.additionalInformation !== undefined) {
            formRef.current.touched.additionalInformation.additionalInformationFields.fill(
              false
            );
          }
        }
        SetLoadingReservationDriver(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
        appInsights.trackException({
          exception: error,
          properties: {
            method: "getPrimaryDriverDetails",
            Component: "MR Reservation details",
          },
          severityLevel: SeverityLevel.Error,
        });
        setMileageReimbursementViewModel(ViewModel);
        SetLoadingReservationDriver(false);
      });
  };

  const getAdditionalInformation = async (id, origJourneyUId) => {
    setLoading(true);
    var userLocale = authService.getUserLocale();
    const mileageReimbursementParamViewModel: MileageReimbursementParamViewModel =
      {
        JourneyId: id,
        Locale: userLocale,
        OrigJourneyUId: origJourneyUId,
      };
    await axiosInstance
      .post(
        `journeyassessment/MileageReimbursementAdditionalInformation`,
        mileageReimbursementParamViewModel
      )
      .then((response) => {
        appInsights.trackEvent({
          name: "get additional information",
          properties: { journeyUId: id },
        });
        var tempData = response.data;

        if (
          tempData.boboDetailsViewModel.driverDeclaration &&
          props.origJourneyUId === undefined
        ) {
          setDriverDeclaration(true);
          tempData = {
            ...tempData,
            driverViewModel: {
              ...mileageReimbursementViewModelInitialValue.driverViewModel,
              firstName: driverInfo?.primaryDriver?.firstName,
              lastName: driverInfo?.primaryDriver?.lastName,
              emailAddress: driverInfo?.primaryDriver?.emailAddress,
              driverDeclarationId:
                driverInfo?.primaryDriver?.driverDeclarationId,
            },
          };
          getPrimaryDriverDetails(
            tempData,
            driverInfo?.primaryDriver?.firstName,
            driverInfo?.primaryDriver?.lastName,
            driverInfo?.primaryDriver?.emailAddress
          );
        } else {
          setMileageReimbursementViewModel(tempData);
        }
        if (
          tempData.additionalInformation?.additionalInformationFields?.length
        ) {
          setBookingRefExpanded(true);
          setHasAdditionalFields(true);
        }
        let boboTabs = displayBoboTabs(
          tempData.boboDetailsViewModel.isBOBOToggleEnabled,
          tempData.boboDetailsViewModel.driverDeclaration,
          tempData.boboDetailsViewModel.boboEnabled,
          tempData.boboDetailsViewModel.userPermissions
        );
        setShowBoboTabs(boboTabs);
        if (tempData.driverViewModel?.boboId > 0) {
          setBookForMyself(false);
        }

        var dateFieldsMap = [] as number[];

        if (
          tempData &&
          tempData.additionalInformation &&
          tempData.additionalInformation.additionalInformationFields
        ) {
          tempData.additionalInformation.additionalInformationFields.map(
            (item, i) => {
              if (item.fieldTypeCode === 1) {
                dateFieldsMap.push(i);
              }
            }
          );
          //create dynamical Yup validation and apply it
          const newSchema = applyAdditionalInformationValidationSchema(
            tempData.additionalInformation.additionalInformationFields,
            formValidation
          );
          setFormValidation(newSchema);
        }
        setDateFieldsMap(dateFieldsMap);

        //initial form prop
        if (formRef.current) {
          formRef.current.setFieldValue(
            "additionalInformation",
            tempData.additionalInformation
          );
          if (formRef.current.touched.additionalInformation !== undefined) {
            formRef.current.touched.additionalInformation.additionalInformationFields.fill(
              false
            );
          }
        }

        setLoading(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  const [dateFieldsMap, setDateFieldsMap] = useState([] as number[]);

  const resetDriverDetails = (isBookForMySelf: boolean) => {
    console.log("caled from add", formRef.current);
    const booker = authService.getBookerDetails();

    formRef.current.setFieldValue(
      "driverViewModel.userTitle.selectedValue",
      isBookForMySelf ? booker.userTitle.selectedValue : ""
    );
    formRef.current.setFieldValue(
      "driverViewModel.firstName",
      isBookForMySelf ? booker.firstName : ""
    );

    formRef.current.setFieldValue(
      "driverViewModel.lastName",
      isBookForMySelf ? booker.lastName : ""
    );

    formRef.current.setFieldValue(
      "driverViewModel.emailAddress",
      isBookForMySelf ? booker.emailAddress : ""
    );

    formRef.current.setFieldValue(
      "driverViewModel.contactPhoneNumber.selectedInternationalDiallingCode",
      isBookForMySelf
        ? booker.contactPhoneNumber.selectedInternationalDiallingCode
        : ""
    );
    formRef.current.setFieldValue(
      "driverViewModel.contactPhoneNumber.number",
      isBookForMySelf ? booker.contactPhoneNumber.number : ""
    );
    formRef.current.setFieldValue(
      "driverViewModel.alternatePhoneNumber.selectedInternationalDiallingCode",
      isBookForMySelf
        ? booker.alternatePhoneNumber.selectedInternationalDiallingCode
        : ""
    );
    formRef.current.setFieldValue(
      "driverViewModel.alternatePhoneNumber.number",
      isBookForMySelf ? booker.alternatePhoneNumber.number : ""
    );
    formRef.current.setFieldValue(
      "driverViewModel.isBOBOBooking",
      !isBookForMySelf
    );
    formRef.current.setFieldValue("driverViewModel.isPrimaryDriver", true);
    formRef.current.setFieldTouched("driverViewModel.firstName", true);
    formRef.current.setFieldTouched("driverViewModel.lastName", true);
    formRef.current.setFieldTouched("driverViewModel.emailAddress", true);
  };
  return (
    <Formik
      initialValues={mileageReimbursementViewModel}
      validateOnChange={true}
      validateOnBlur={false}
      enableReinitialize={true}
      validationSchema={formValidation}
      innerRef={formRef}
      onSubmit={async (values, { setFieldTouched, setSubmitting }) => {
        setSubmitting(false);
        setLoading(true);

        // console.log("Save additional information=", mileageReimbursementViewModel);

        let selectedDriverViewModel: DriverViewModel | null = null;
        if (bookForMyself) {
          selectedDriverViewModel = driverViewModel;
        } else {
          selectedDriverViewModel = boboSomeone;
        }
        if (
          hasAdditionalFields ||
          mileageReimbursementViewModel.isMRDriverAccordionToggleEnabled
        ) {
          await axiosInstance
            .post(
              "/journeyassessment/UpdateMileageReimbursementAdditionalInformation",
              {
                ...mileageReimbursementViewModel,
                driverViewModel: selectedDriverViewModel,
              }
            )
            .then((res) => {
              setSubmitting(false);
              setLoading(false);

              if (res.data.warnings !== "") {
                history("/home");

                setTimeout(() => {
                  dispatch(showSuccessAlertMessage(t("Res.JourneyCreated"))); // page redirect or do nothing here
                  dispatch(broadcastAnnouncement(t("Res.JourneyCreated")));
                }, 3000);
              }

              window.scrollTo(0, 0);
              if (titleRef.current !== null) titleRef.current.focus();
            })
            .catch((error) => {
              setSubmitting(false);
              setLoading(false);
              console.log("error =", error.response.data.messages);
              if (error.response) {
                if (error.response.data.messages == null) {
                  dispatch(broadcastAnnouncement(""));
                  setTimeout(() => {
                    dispatch(broadcastAnnouncement(error.response.statusText));
                  }, 100);

                  dispatch(showErrorAlertMessage(error.response.statusText));
                } else {
                  var errorlist: string[] = [];
                  var errorMsgs = error.response.data.messages.map((item) => {
                    return errorlist.push(item.message);
                  });

                  dispatch(broadcastAnnouncement(""));
                  setTimeout(() => {
                    dispatch(
                      broadcastAnnouncement(
                        "Submission failed: " + errorlist.join("  ")
                      )
                    );
                  }, 100);

                  setAdditionalInfoError(error.response.data.messages);
                  dispatch(showErrorAlertMessage(errorlist));
                }
                appInsights.trackException({
                  exception: error,
                  properties: {
                    method: "onSubmit",
                    Component: "additionalInformation",
                  },
                  severityLevel: SeverityLevel.Error,
                });
              } else if (error.request) {
                console.log("error.request", error.request);
              } else if (error.message) {
                console.log("error.message", error.message);
              }
              window.scrollTo(0, 0);
              if (titleRef.current !== null) titleRef.current.focus();
            });
        } else {
          history("/home");
        }
      }}
    >
      {({
        values,
        isValid,
        dirty,
        setFieldValue,
        setFieldTouched,
        touched,
        handleChange,
        errors,
        isSubmitting,
      }) => (
        <Grid container>
          <div ref={titleRef} tabIndex={-1} className={classes.focused}>
            <Typography
              variant="h1"
              style={{
                marginTop: "20px",
              }}
            >
              {t("AdditionalInformation.header")}
            </Typography>
          </div>
          <Backdrop
            className={classes.backdrop}
            open={isSubmitting || loading || loadingReservationDriver}
          >
            <CircularProgress />
          </Backdrop>
          <Grid item xs={12}>
            <Grid
              container
              direction="row"
              style={{ marginTop: "1em" }}
              className={classes.root}
            >
              <Form style={{ width: "100%" }}>
                <Grid container>
                  <Grid
                    style={{
                      paddingBottom: 0,
                      paddingTop: 10,
                      marginBottom: "1em",
                    }}
                    item
                    xs={12}
                  >
                    {mileageReimbursementViewModel.isMRDriverAccordionToggleEnabled && (
                      <Accordion
                        style={{ width: "100%" }}
                        id="sectionDriverDetails"
                        expanded={driverExpanded}
                        onChange={() => {
                          setDriverExpanded(!driverExpanded);
                        }}
                      >
                        <h2>
                          <AccordionSummary
                            expandIcon={
                              <ExpandMoreIcon sx={{ color: "#FFFFFF" }} />
                            }
                            aria-controls="driverdetails"
                            id="driverdetails"
                            className={classes.heading}
                          >
                            {t(
                              "ReservationWizard.reservationPage.labelSectionDriver"
                            )}
                          </AccordionSummary>
                        </h2>
                        {showBoboTabs && (
                          <Grid item md={12}>
                            <Box
                              flexDirection="row"
                              alignItems="center"
                              textAlign="center"
                              tabIndex={0}
                              style={{
                                paddingBottom: 0,
                                paddingTop: 0,
                                paddingLeft: 25,
                                paddingRight: 25,
                                // maxWidth: 20,
                                // minWidth: 10,
                              }}
                            >
                              <Button
                                id="btnBookForMyself"
                                data-testid="btnBookForMyself"
                                style={{
                                  width: "48%",
                                  height: "100%",
                                  wordBreak: "break-all",
                                  wordWrap: "break-word",
                                  paddingLeft: 0,
                                  paddingRight: 0,
                                  fontSize: "0.8em",
                                }}
                                color="primary"
                                aria-label={t(
                                  "ReservationWizard.carClubPage.btnBookForMyself"
                                )}
                                variant="outlined"
                                className={
                                  bookForMyself
                                    ? isSmXs
                                      ? classes.activeButtonClassSm
                                      : classes.activeButtonClass
                                    : isSmXs
                                    ? classes.inActiveButtonClassSm
                                    : classes.inActiveButtonClass
                                }
                                onClick={() => {
                                  setBookForMyself(true);
                                  setisValidBOBOSomeone(true);
                                }}
                                startIcon={bookForMyself && <CheckIcon />}
                                disabled={
                                  props.origJourneyUId && !bookForMyself
                                    ? true
                                    : false
                                }
                              >
                                {t(
                                  "ReservationWizard.carClubPage.btnBookForMyself"
                                )}
                              </Button>
                              <Button
                                id="btnBookForOthers"
                                data-testid="btnBookForOthers"
                                style={{
                                  width: "48%",
                                  height: "100%",
                                  wordBreak: "break-all",
                                  wordWrap: "break-word",
                                  paddingLeft: 0,
                                  paddingRight: 0,
                                  fontSize: "0.8em",
                                }}
                                color="primary"
                                variant="outlined"
                                className={
                                  bookForMyself
                                    ? isSmXs
                                      ? classes.inActiveButtonClassSm
                                      : classes.inActiveButtonClass
                                    : isSmXs
                                    ? classes.activeButtonClassSm
                                    : classes.activeButtonClass
                                }
                                aria-label={t(
                                  "ReservationWizard.carClubPage.btnBookForOthers"
                                )}
                                onClick={() => {
                                  setBookForMyself(false);
                                  resetDriverDetails(false);
                                }}
                                startIcon={!bookForMyself && <CheckIcon />}
                                disabled={
                                  props.origJourneyUId && bookForMyself
                                    ? true
                                    : false
                                }
                              >
                                {t(
                                  "ReservationWizard.carClubPage.btnBookForOthers"
                                )}
                              </Button>
                            </Box>
                          </Grid>
                        )}
                        {bookForMyself ? (
                          <AccordionDetails>
                            <DriverDetails
                              values={values.driverViewModel}
                              resType={resType}
                              onDataChange={onDriverDetailsChange}
                              isBOBOUser={
                                props.origJourneyUId || driverDeclaration
                                  ? true
                                  : false
                              }
                              isPhoneNumberOptional={true}
                              isEmailAddressMaskToggleEnabled={
                                mileageReimbursementViewModel.isEmailAddressMaskToggleEnabled &&
                                mileageReimbursementViewModel
                                  .boboDetailsViewModel.driverDeclaration
                              }
                            />
                          </AccordionDetails>
                        ) : (
                          <AccordionDetails>
                            <BOBOSomeone
                              initialDriver={values.driverViewModel}
                              resType={resType}
                              onDataChange={onBOBOUserChange}
                              isPhoneNumberOptional={true}
                            />
                          </AccordionDetails>
                        )}
                      </Accordion>
                    )}
                    {mileageReimbursementViewModel?.additionalInformation
                      ?.additionalInformationFields.length && (
                      <Accordion
                        style={{ width: "100%" }}
                        id="sectionbookingRef"
                        expanded={bookingRefExpanded}
                        onChange={() => {
                          setBookingRefExpanded(!bookingRefExpanded);
                        }}
                      >
                        <h2>
                          <AccordionSummary
                            expandIcon={
                              <ExpandMoreIcon sx={{ color: "#FFFFFF" }} />
                            }
                            aria-controls="bookingRef"
                            id="bookingRef"
                            className={classes.heading}
                          >
                            {t(
                              "ReservationWizard.reservationPage.labelSectionBookingRef"
                            )}
                          </AccordionSummary>
                        </h2>
                        <AccordionDetails>
                          <div style={{ flexGrow: 1 }}>
                            <Grid container direction="row">
                              {mileageReimbursementViewModel.additionalInformation.additionalInformationFields.map(
                                (info, index) => {
                                  return(!isHiddenAdditionalParameters(info.fieldName) && 
                                    <div style={{ width: "100%" }} key={index}>
                                      <div className="alignItemCentre">
                                        <label
                                          htmlFor={
                                            [3, 4, 5].includes(
                                              info.fieldTypeCode
                                            )
                                              ? "AdditionalInformationField-TextTextbox-" +
                                                index
                                              : [2].includes(info.fieldTypeCode)
                                              ? "AdditionalInformationField-DropDownListComboBox-" +
                                                index
                                              : [1]
                                              ? "AdditionalInformationField-DateTextbox-" +
                                                index
                                              : "AdditionalInformationField-" +
                                                index
                                          }
                                          className={classes.labelText}
                                          id={
                                            "AdditionalInformationFieldLabelId-" +
                                            index
                                          }
                                        >
                                          {info.fieldName.concat(
                                            info.mandatory ? " *" : ""
                                          )}
                                          {"  "}
                                        </label>
                                        {info.helperText !== null ? (
                                          <IconButton
                                            id={
                                              "additionalInformationIcon" +
                                              index
                                            }
                                            size="small"
                                            color="primary"
                                            style={{
                                              marginLeft: 0,
                                              paddingBottom: 8,
                                            }}
                                            title={info.helperText}
                                          >
                                            <InfoIcon fontSize="small" />
                                          </IconButton>
                                        ) : (
                                          ""
                                        )}
                                      </div>

                                      {/* {renderAdditionalInformationField(info, index)} */}
                                      {[3, 4, 5].includes(
                                        info.fieldTypeCode
                                      ) ? (
                                        <Fragment>
                                          <Field
                                            name={
                                              "AdditionalInformation.AdditionalInformationFields[" +
                                              index +
                                              "].FieldValue"
                                            }
                                            id={
                                              "AdditionalInformationField-TextTextbox-" +
                                              index
                                            }
                                            value={info.fieldValue ?? ""}
                                            fullWidth
                                            style={{
                                              paddingBottom: 0,
                                              paddingTop: 0,
                                            }}
                                            inputProps={{
                                              "aria-required": true,
                                              "aria-describedby":
                                                "AdditionalInformationFieldError" +
                                                index,
                                              /* maxLength: info.maxLength, */
                                            }}
                                            onChange={(e) => {
                                              //console.log("current validationSchema before onchange",formValidation);
                                              info.fieldValue = e.target.value;
                                              setFieldValue(
                                                "additionalInformation",
                                                mileageReimbursementViewModel.additionalInformation
                                              );
                                              //console.log("err[] after onchange",errors);
                                            }}
                                            onBlur={(e) => {
                                              setFieldTouched(
                                                "additionalInformation.additionalInformationFields[" +
                                                  index +
                                                  "]"
                                              );
                                            }}
                                            onKeyUp={(e) => {
                                              setFieldTouched(
                                                "additionalInformation.additionalInformationFields[" +
                                                  index +
                                                  "]"
                                              );
                                            }}
                                            component={TextField}
                                            //required={info.mandatory}
                                            variant="outlined"
                                            error={
                                              errors?.[
                                                "additionalInformation"
                                              ] !== undefined &&
                                              (
                                                errors.additionalInformation as unknown as additionalInformationFormikFormError
                                              )?.additionalInformationFields[
                                                index
                                              ] !== undefined
                                                ? true
                                                : false
                                            }
                                            placeholder={
                                              t(
                                                "AdditionalInformation.pleaseSelectValueFor"
                                              ) + info.fieldName
                                            }
                                          />
                                          {(
                                            errors.additionalInformation as unknown as additionalInformationFormikFormError
                                          )?.additionalInformationFields[
                                            index
                                          ] &&
                                          (
                                            touched.additionalInformation as unknown as additionalInformationFormikFormTouched
                                          )?.additionalInformationFields[
                                            index
                                          ] ? (
                                            <span
                                              id={
                                                "AdditionalInformationFieldError" +
                                                index
                                              }
                                              role="alert"
                                              className={classes.errorWarning}
                                            >
                                              {t(
                                                (
                                                  errors.additionalInformation as unknown as additionalInformationFormikFormError
                                                )?.additionalInformationFields[
                                                  index
                                                ],
                                                {
                                                  fieldName: info.fieldName,
                                                  maxLength: info.maxLength
                                                    ? info.maxLength
                                                    : 100,
                                                }
                                              )}
                                            </span>
                                          ) : (
                                            ""
                                          )}
                                        </Fragment>
                                      ) : [2].includes(info.fieldTypeCode) ? (
                                        <Fragment>
                                          <FormControl
                                            variant="outlined"
                                            fullWidth
                                          >
                                            <Select
                                              name={
                                                "AdditionalInformation.AdditionalInformationFields[" +
                                                index +
                                                "].FieldValue"
                                              }
                                              id={
                                                "AdditionalInformationField-DropDownListComboBox-" +
                                                index
                                              }
                                              native
                                              value={info.fieldValue ?? ""}
                                              onChange={(e) => {
                                                info.dropDownListSelectedValue =
                                                  typeof e.target.value ===
                                                  "string"
                                                    ? e.target.value
                                                    : undefined;
                                                info.fieldValue =
                                                  e.target.value;
                                                setFieldValue(
                                                  "additionalInformation",
                                                  mileageReimbursementViewModel.additionalInformation
                                                );
                                              }}
                                              onBlur={(e) => {
                                                setFieldTouched(
                                                  "additionalInformation.additionalInformationFields[" +
                                                    index +
                                                    "]"
                                                );
                                              }}
                                              //required={info.mandatory}
                                              inputProps={{
                                                "aria-describedby":
                                                  "AdditionalInformationFieldError" +
                                                  index,
                                              }}
                                              error={
                                                errors?.[
                                                  "additionalInformation"
                                                ] !== undefined &&
                                                (
                                                  errors.additionalInformation as unknown as additionalInformationFormikFormError
                                                )?.additionalInformationFields[
                                                  index
                                                ] !== undefined
                                                  ? true
                                                  : false
                                              }
                                            >
                                              <option
                                                key={
                                                  "AdditionalInformationField-DropDownListComboBox-" +
                                                  index +
                                                  "-Emtpy"
                                                }
                                                value=""
                                              >
                                                {t(
                                                  "AdditionalInformation.pleaseSelect"
                                                )}
                                              </option>
                                              {info.dropDownList?.map(
                                                (opt: any, optIndex) => (
                                                  <option
                                                    key={
                                                      "AdditionalInformationField-DropDownListComboBox-" +
                                                      index +
                                                      "-" +
                                                      optIndex
                                                    }
                                                    value={opt.value}
                                                  >
                                                    {opt.text}
                                                  </option>
                                                )
                                              )}
                                            </Select>
                                            {(
                                              errors.additionalInformation as unknown as additionalInformationFormikFormError
                                            )?.additionalInformationFields[
                                              index
                                            ] &&
                                            (
                                              touched.additionalInformation as unknown as additionalInformationFormikFormTouched
                                            )?.additionalInformationFields[
                                              index
                                            ] ? (
                                              <span
                                                id={
                                                  "AdditionalInformationFieldError" +
                                                  index
                                                }
                                                role="alert"
                                                className={classes.errorWarning}
                                              >
                                                {t(
                                                  (
                                                    errors.additionalInformation as unknown as additionalInformationFormikFormError
                                                  )
                                                    ?.additionalInformationFields[
                                                    index
                                                  ],
                                                  { fieldName: info.fieldName }
                                                )}
                                              </span>
                                            ) : (
                                              ""
                                            )}
                                          </FormControl>
                                        </Fragment>
                                      ) : [1].includes(info.fieldTypeCode) ? (
                                        <Fragment>
                                          <Grid item xs={2}>
                                            <Field
                                              component={EtdDatePickerV2}
                                              name={
                                                "AdditionalInformation.AdditionalInformationFields[" +
                                                index +
                                                "].FieldValue"
                                              }
                                              id={
                                                "AdditionalInformationField-DateTextbox-" +
                                                index
                                              }
                                              label={info.fieldName}
                                              localeDateTimeFormat={
                                                localeDateTimeFormat
                                              }
                                              isFullWidth={false}
                                              value={
                                                info.fieldValue
                                                  ? convertToDate(
                                                      info.fieldValue
                                                    )
                                                  : null
                                              }
                                              handleChange={(e) => {
                                                console.log("onchangeRes");
                                                const format =
                                                  localeDateTimeFormat.toUpperCase();
                                                let parsedDate = moment(
                                                  e,
                                                  format,
                                                  true
                                                );

                                                if (parsedDate.isValid()) {
                                                  const newValue =
                                                    moment(parsedDate).format(
                                                      "YYYY-MM-DD"
                                                    );
                                                  if (
                                                    moment(
                                                      parsedDate
                                                    ).isSameOrAfter(
                                                      moment().add(-1, "days")
                                                    )
                                                  ) {
                                                    info.fieldValue = newValue;
                                                    info.fieldValueDateString =
                                                      newValue.replace(
                                                        /\D/g,
                                                        ""
                                                      );
                                                  } else {
                                                    info.fieldValue = null;
                                                    info.fieldValueDateString =
                                                      "DateInPast";
                                                  }
                                                } else {
                                                  info.fieldValue = null;
                                                  info.fieldValueDateString = e
                                                    ? "FormatError"
                                                    : "";
                                                }
                                                setFieldValue(
                                                  "additionalInformation",
                                                  mileageReimbursementViewModel.additionalInformation
                                                );
                                              }}
                                              handleBlur={(e) => {
                                                console.log("onblurRes");
                                                setFieldTouched(
                                                  "additionalInformation.additionalInformationFields[" +
                                                    index +
                                                    "]"
                                                );
                                              }}
                                              isError={
                                                errors?.[
                                                  "additionalInformation"
                                                ] !== undefined &&
                                                (
                                                  errors.additionalInformation as unknown as additionalInformationFormikFormError
                                                )?.additionalInformationFields[
                                                  index
                                                ] !== undefined
                                                  ? true
                                                  : false
                                              }
                                            />
                                            {(
                                              errors.additionalInformation as unknown as additionalInformationFormikFormError
                                            )?.additionalInformationFields[
                                              index
                                            ] /* &&
                                        (
                                          touched.additionalInformation as unknown as additionalInformationFormikFormTouched
                                        )?.additionalInformationFields[
                                          index
                                        ] */ ? (
                                              <span
                                                id={
                                                  "AdditionalInformationFieldError" +
                                                  index
                                                }
                                                role="alert"
                                                className={classes.errorWarning}
                                              >
                                                {t(
                                                  (
                                                    errors.additionalInformation as unknown as additionalInformationFormikFormError
                                                  )
                                                    ?.additionalInformationFields[
                                                    index
                                                  ],
                                                  { fieldName: info.fieldName }
                                                )}
                                              </span>
                                            ) : (
                                              ""
                                            )}
                                          </Grid>
                                        </Fragment>
                                      ) : (
                                        ""
                                      )}

                                      <Grid>
                                        <br />
                                      </Grid>
                                    </div>
                                  );
                                }
                              )}
                            </Grid>
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    )}
                  </Grid>
                </Grid>
                <Grid style={{ float: "right" }}>
                  <Button
                    id="saveRiskAssessmentButton"
                    color="primary"
                    variant="contained"
                    disabled={
                      !isValid ||
                      (!isValidDriverViewModel && bookForMyself) ||
                      (!bookForMyself && !isValidBOBOSomeone)
                    }
                    type="submit"
                    style={{ marginTop: "0.5em", textTransform: "none" }}
                  >
                    {t("Submit")}
                  </Button>
                </Grid>
              </Form>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Formik>
  );
}
