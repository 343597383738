import React, {  } from "react";
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from "react-i18next";
import {
  Grid,
  Typography,
} from "@mui/material";


export const PasswordValidationRules: React.FC<any> = () => {
  const { t } = useTranslation();
  const useStyles = makeStyles((theme) => ({
    root: {
      "& .MuiFormHelperText-root": {
        color: "#DF0000",
        fontSize: 12,
        marginLeft: "15px",
      },
      "& ::placeholder": {
        fontStyle: "italic",
        color: "#000",
        opacity: 0.95,
        whiteSpace: "pre-wrap",
        "@media (max-width:330px)": {
          fontSize: "0.6rem",
        },
      },
      "& .MuiTypography-h1": {
        color: "red",
        fontSize: 14,
        marginLeft: "15px",
      },
    },
    gridItem: {
      verticalAlign: "center",
      color: "red",
      paddingLeft: 20,
      "& .MuiTypography-body2": {
        fontWeight: "bold",
        fontSize: "0.875rem",
      },
    },
    heading: {
      fontSize: "1rem",
      fontWeight: "bold",
    },
    labelText: {
      color: "#000",
      fontFamily: "Arial, Roboto, Segoe UI",
      fontSize: "0.875rem",
      fontWeight: "bold",
      verticalAlign: "middle",
    },
    errorWarning: {
      color: "#DF0000",
      fontSize: "0.75rem",
      marginLeft: "15px",
    },
    backdrop: {
      zIndex: 99999,
      color: "#fff",
    },
    textarea: {
      resize: "both",
    },
    focused: { outline: "none" },
    placeHolder: {
        "& ::placeholder": {
            color: "#767676",
            opacity: "1",
          }
    }
  }));
  const classes = useStyles();

  return (
    <Grid id="validationRules" container className={classes.placeHolder}>
      <Grid item xs={12} md={12} className={classes.gridItem}>
        <Typography tabIndex={0} id="MustIncludeHeader">
          {t("Users.Errors.pwMustInclude")}
        </Typography>
      </Grid>
      <Grid item xs={12} md={12} className={classes.gridItem}>
        <Typography tabIndex={0} id="PwMinLength">
          - {t("Users.Errors.pwMinLength", {length : 8})}
        </Typography>
      </Grid>
      <Grid item xs={12} md={12} className={classes.gridItem}>
        <Typography tabIndex={0} id="PwOneDigit">
          - {t("Users.Errors.pwOneDigit")}
        </Typography>
      </Grid>
      <Grid item xs={12} md={12} className={classes.gridItem}>
        <Typography tabIndex={0} id="PwOneSpecialCharacter">
          - {t("Users.Errors.pwOneSpecialCharacter")}
        </Typography>
      </Grid>
      <Grid item xs={12} md={12} className={classes.gridItem}>
        <Typography tabIndex={0} id="PwBothUpperLowerCase">
          - {t("Users.Errors.pwBothUpperLowerCase")}
        </Typography>
      </Grid>
      <Grid item xs={12} md={12} className={classes.gridItem}>
        &nbsp;
      </Grid>
      <Grid item xs={12} md={12} className={classes.gridItem}>
        <Typography tabIndex={0} id="CannotIncludeHeader">
          {t("Users.Errors.pwCannotInclude")}
        </Typography>
      </Grid>
      <Grid item xs={12} md={12} className={classes.gridItem}>
        <Typography tabIndex={0} id="PwNoEmail">
          - {t("Users.Errors.pwNoEmail")}
        </Typography>
      </Grid>
      <Grid item xs={12} md={12} className={classes.gridItem}>
        <Typography tabIndex={0} id="PwNoRepeat">
          - {t("Users.Errors.pwNoRepeat")}
        </Typography>
      </Grid>
      <Grid item xs={12} md={12} className={classes.gridItem}>
        <Typography tabIndex={0} id="PwNoUsername">
          - {t("Users.Errors.pwNoUsername")}
        </Typography>
      </Grid>
      <Grid item xs={12} md={12} className={classes.gridItem}>
        <Typography tabIndex={0} id="PwNoMemberID">
          - {t("Users.Errors.pwNoMemberID")}
        </Typography>
      </Grid>
      <Grid item xs={12} md={12} className={classes.gridItem}>
        <Typography tabIndex={0} id="PwNoName">
          - {t("Users.Errors.pwNoName")}
        </Typography>
      </Grid>
      <Grid item xs={12} md={12} className={classes.gridItem}>
        <Typography tabIndex={0} id="PwNoDictionaryPW">
          - {t("Users.Errors.pwNoDictionaryPW")}
        </Typography>
      </Grid>
      <Grid item xs={12} md={12} className={classes.gridItem}>
        <Typography tabIndex={0} id="PwNoPreviousPW">
          - {t("Users.Errors.pwNoPreviousPW")}
        </Typography>
      </Grid>
    </Grid>  
  );
};

export default PasswordValidationRules;