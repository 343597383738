import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  JourneyStartModel,
  myJourneyIntitalValue
} from "components/Reservation/steps/JourneyStart/journeyInputType";

export interface JourneyProfileState {
  journeyProfile:JourneyStartModel; 
  hasErrors: boolean;
  
}
const initialState: JourneyProfileState = 
{ journeyProfile : myJourneyIntitalValue,
  hasErrors : false};

export const JourneyProfileDetailSlice = createSlice({
  name: "journeyProfile",
  initialState,
  reducers: {
    updateJourneyProfileDetails: (
      state,
      action: PayloadAction<JourneyStartModel>
    ) => {
      console.log("Payload received: ", action.payload);
      state.journeyProfile = action.payload;
      
    },
    resetJourneyProfileDetails: () => initialState,
  },
});

export const { updateJourneyProfileDetails, resetJourneyProfileDetails } =
JourneyProfileDetailSlice.actions;

export default JourneyProfileDetailSlice.reducer;
