import React from "react";
import {
  Box,
  Grid,  
  Button, 
  Typography
 
} from "@mui/material";

import {  ManualCardActivityResult } from "./paymentType";
import "react-datepicker/dist/react-datepicker.css";

import { useTranslation } from "react-i18next";
import PaymentResult from "./paymentResult";
import { getCardBrand, getCardExpiry, getMaskedCardNumber } from "../../../../helpers/creditCard";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const McbpsActivityResult: React.FC<ManualCardActivityResult> = (props) => {
  const theme = useTheme();
  const IsDesktop = useMediaQuery(theme.breakpoints.up('sm'));
  const { t } = useTranslation();
  return (
   
      <Box
      boxShadow={3}
      p={3}
      bgcolor="background.paper"  
      style={{ textAlign:"center", marginLeft: (IsDesktop ? "30%" : "0%"), width: (IsDesktop ? "40%" : "100%"), verticalAlign:"middle", justifyContent:"center"}}     
    >
      {(props.errors.length > 0) && <Typography id="lblErrorMessage" variant="body2" style={{textAlign:"left", color:"#DF0000",  marginBottom:"10px"}} > {props.errors} </Typography> }
      <PaymentResult cardNumber={getMaskedCardNumber(props.data?.cardDetails.maskedCardNumber)} cardExpiry={getCardExpiry(props.data?.cardDetails.expiration.lastValidMonth.toString(), props.data?.cardDetails.expiration.year.toString())} cardBrand={getCardBrand(props.data?.cardAttributes.cardBrand.toString())} />
      
      {!props.hideEdit && <Grid item xs={12} style={{textAlign:"left", marginTop:"15px"}}>  <Button
          id="btnEditCard"
          color="inherit"
          variant="outlined"
          aria-label={t("lblContinueToNextStep")}
          onClick={() => {
           if(props.handleEdit)
             props.handleEdit();
          }}
        >
         {t("carClasses.labelBtnEdit")}
        </Button>   </Grid>
      }
      
      </Box>
   
);
};
export default McbpsActivityResult;

