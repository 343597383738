import makeStyles from '@mui/styles/makeStyles';
import { BookingTimeRangeSlider } from "components/shared/booking-time-range-slider";
import { mockBookingTimeRangeProperties } from "components/shared/booking-time-range-slider/mocking/BookingTimeRangeProperies.mock";
import { useTranslation } from "react-i18next";
import { minutesBetweenTwoDates } from "../../../shared/booking-time-range-slider/functions/DateTime.functions";
import {
  Grid,
  Paper,
  Button,
  Box,
  useTheme,
  useMediaQuery,
} from "@mui/material";

import {
  AvailabilityPeriod,
  BookingTimeRangeSliderProperties,
} from "components/shared/booking-time-range-slider/models";
import moment from "moment";

import { useSelector } from "react-redux";
import { AppState } from "components/store/reducers/rootReducer";

interface CarClubCarProps {
  label: string;
  name: string;
  carInfo: any;
  carKey: string;
  startDate: Date;
  endDate: Date;
  handleCarSelect: (car: any, index: number) => void;
  locale: string;
  autofocus?: boolean;
  disabled?: boolean;
  showEmission:boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    // padding: theme.spacing(0),
    textAlign: "center",
    color: theme.palette.text.secondary,
    display: "flex",
    flex: 1,
    height: "100%",
    overflowX: "auto",
  },
  headerLabel: {
    fontWeight: "bold",
    fontSize: "1.1em",
    color: "#000",
  },
  headerText: {
    fontSize: "1.1em",
    color: "#000",
    textAlign: "left",
  },
  responsive: {
    width: "50%",
    height: "auto",
    //  textAlign:"center",
    border: "0px red solid",
  },
  vehicleinfo: {
    textAlign: "left",
    marginLeft: "30px",
    lineHeight: 1,
    fontSize: "1.0em",
    color: "#000",
  },
}));

const CarClubCar: React.FC<CarClubCarProps> = ({
  label,
  autofocus = false,
  disabled = false,
  handleCarSelect,
  carInfo,
  startDate,
  endDate,
  carKey,
  locale,
  showEmission,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isSmXs = useMediaQuery(theme.breakpoints.down('lg'));
  const { t } = useTranslation();
  let mockProperties = mockBookingTimeRangeProperties();
  const { co2dict } = useSelector((state: AppState) => state.co2Dict);

  const isSlotAvailable = () => {
    var result = carInfo.availabilityPeriods.filter((obj) => {
      return (
        new Date(obj.startDateTime) >=
          moment(new Date(startDate)).add(-15, "m").toDate() &&
        new Date(obj.startDateTime) <
          moment(new Date(endDate)).add(15, "m").toDate() &&
        obj.isAvailable === false
      );
    });
    return result.length > 0;
  };

  const isSlotAvailableWithoutDownTime = () => {
    var result = carInfo.availabilityPeriods.filter((obj) => {
      return (
        new Date(obj.startDateTime) >= new Date(startDate) &&
        new Date(obj.startDateTime) < new Date(endDate) &&
        obj.isAvailable === false
      );
    });
    return result.length > 0;
  };

  interface VehilceUnavailableRange {
    //both dates like "2021-11-04T10:30:00"
    rangeStartDate: string;
    rangeEndDate: string;
  }

  const datesAreOnSameDay = (first: Date, second: Date) =>
    first.getFullYear() === second.getFullYear() &&
    first.getMonth() === second.getMonth() &&
    first.getDate() === second.getDate();

  const datesStringAreOnSameDay = (first: String, second: String) =>
    //both dates like "2021-11-04T10:30:00"
    first.substring(0, 10) === second.substring(0, 10);

  const getVehilceUnavailableArray = (
    originalPeriods: AvailabilityPeriod[]
  ) => {
    var result: VehilceUnavailableRange[] = [];
    var periods = originalPeriods;
    var vehilceUnavailablePeriods = periods.filter((obj) => {
      return obj.isAvailable === false;
    });
    if (vehilceUnavailablePeriods.length > 0) {
      var rangeStartDateTime;
      var rangeEndDateTime;
      var rangeFound = false;
      for (var index = 0; index <= periods.length - 2; index++) {
        if (periods[index].isAvailable === false) {
          if (!rangeFound) {
            //new range
            rangeFound = true;
            rangeStartDateTime = periods[index].startDateTime;
          }
          if (index === periods.length - 2) {
            //end last range
            rangeEndDateTime = periods[index + 1].startDateTime;
            result.push({
              rangeStartDate: rangeStartDateTime,
              rangeEndDate: rangeEndDateTime,
            });
            rangeFound = false;
            break;
          }
        } else {
          //current range ends
          if (rangeFound === true) {
            rangeEndDateTime = periods[index].startDateTime;
            result.push({
              rangeStartDate: rangeStartDateTime,
              rangeEndDate: rangeEndDateTime,
            });
            rangeFound = false;
          }
        }
      }
    }
    return result;
  };

  const getVehilceUnavailableDescription = (
    periods: VehilceUnavailableRange[]
  ) => {
    //all dates like "2021-11-04T10:30:00"
    var result = "";
    var standardDate = "0000-00-00T00:00:00";
    if (periods.length > 0) {
      //standardDate = new Date(periods[0].rangeStartDate.getFullYear(), periods[0].rangeStartDate.getMonth()-1, periods[0].rangeStartDate.getDate()-1);
      result = t("ReservationWizard.carClubPage.legendUnavailable");
      for (var index = 0; index < periods.length; index++) {
        if (
          datesStringAreOnSameDay(
            standardDate,
            periods[index].rangeStartDate
          ) &&
          datesStringAreOnSameDay(
            periods[index].rangeStartDate,
            periods[index].rangeEndDate
          )
        ) {
          //new time range
          result +=
            t("toDate", {
              date1: periods[index].rangeStartDate.substring(11, 16),
              date2: periods[index].rangeEndDate.substring(11, 16),
            }) + (index === periods.length - 1 ? "" : ",");
        } else if (
          !datesStringAreOnSameDay(
            standardDate,
            periods[index].rangeStartDate
          ) &&
          datesStringAreOnSameDay(
            periods[index].rangeStartDate,
            periods[index].rangeEndDate
          )
        ) {
          //new date
          result += t("onDate", {
            date: new Intl.DateTimeFormat(
              locale.toString() === "en-GB" ? "en-GB" : "de-DE",
              { dateStyle: "medium" }
            ).format(new Date(periods[index].rangeStartDate)),
          });
          result +=
            t("fromToDate", {
              date1: periods[index].rangeStartDate.substring(11, 16),
              date2: periods[index].rangeEndDate.substring(11, 16),
            }) + (index === periods.length - 1 ? "" : ",");
          standardDate = periods[index].rangeStartDate;
        } else if (
          datesStringAreOnSameDay(
            standardDate,
            periods[index].rangeStartDate
          ) &&
          !datesStringAreOnSameDay(
            periods[index].rangeStartDate,
            periods[index].rangeEndDate
          )
        ) {
          result +=
            t("toDate", {
              date1: periods[index].rangeStartDate.substring(11, 16),
              date2:
                new Intl.DateTimeFormat(
                  locale.toString() === "en-GB" ? "en-GB" : "de-DE",
                  { dateStyle: "medium" }
                ).format(new Date(periods[index].rangeEndDate)) +
                " " +
                periods[index].rangeEndDate.substring(11, 16),
            }) + (index === periods.length - 1 ? "" : ",");
        } else if (
          !datesStringAreOnSameDay(
            standardDate,
            periods[index].rangeStartDate
          ) &&
          !datesStringAreOnSameDay(
            periods[index].rangeStartDate,
            periods[index].rangeEndDate
          )
        ) {
          result +=
            t("fromToDate", {
              date1:
                new Intl.DateTimeFormat(
                  locale.toString() === "en-GB" ? "en-GB" : "de-DE",
                  { dateStyle: "medium" }
                ).format(new Date(periods[index].rangeStartDate)) +
                " " +
                periods[index].rangeStartDate.substring(11, 16),
              date2:
                new Intl.DateTimeFormat(
                  locale.toString() === "en-GB" ? "en-GB" : "de-DE",
                  { dateStyle: "medium" }
                ).format(new Date(periods[index].rangeEndDate)) +
                " " +
                periods[index].rangeEndDate.substring(11, 16),
            }) + (index === periods.length - 1 ? "" : ",");
          standardDate = periods[index].rangeStartDate;
        }
      }
    }
    return result;
  };

  const getMinutesPerStep = () => {
    let minutesPerStep = 15;
    return minutesPerStep;
    const totalMins = minutesBetweenTwoDates(
      new Date(startDate),
      new Date(endDate)
    );
    if (totalMins > 2 * 24 * 60)
      //greathan then 2 days
      return 12 * 60;
    //half day
    else if (totalMins > 24 * 60)
      //greathan then 1 days
      return 6 * 60;
    else return minutesPerStep;
  };
  function bookingTimeRangeProps(): BookingTimeRangeSliderProperties {
    const minutesPerStep = getMinutesPerStep();
    return {
      availabilityPeriods: carInfo.availabilityPeriods,
      startDate: new Date(startDate),
      endDate: new Date(endDate),
      index: carKey.toString(),
      config: {
        dateFormat: "D/MM HH:mm",
        minimumHireTimeMinutes: 30,
        minutesPerStep,
      },
    };
  }

  return (
    <div data-testid="carClubCar" className={classes.root}>
      <Paper className={classes.paper} elevation={3} square>
        <Grid container>
          <Grid
            container
            // justify="space-between"
            style={{ padding: "10px", background: "#e8e8e8" }}
          >
            <Grid item xs={12} md={isSlotAvailable() ? 9 : 10}>
              <div className={classes.headerText}>
                <span className={classes.headerLabel}>
                  {t("ReservationWizard.carClubPage.labelLocation")}
                </span>
                <h3
                  id={"carLocation" + carKey}
                  style={{
                    fontSize: "0.857em",
                    fontWeight: "normal",

                    marginRight: "15px",
                    display: "inline-block",
                  }}
                >
                  {carInfo.lotAddress}
                </h3>
                <span className={classes.headerLabel}>
                  {" "}
                  {t("ReservationWizard.carClubPage.labelDistance")}
                </span>
                <span
                  id={"carDistance" + carKey}
                  style={{ marginRight: "5px" }}
                >
                  {Number(carInfo.distanceFromStart).toLocaleString(locale, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}{" "}
                  {carInfo.distanceUnits}s{" "}
                </span>
              </div>
            </Grid>
            {isSlotAvailable() && (
              <Grid
                item
                id={"CCVehicleUnavailableText" + carKey}
                xs={12}
                md={1}
                style={{ margin: 0, padding: 0 }}
              >
                <span className={classes.headerText}>
                  {t("ReservationWizard.carClubPage.legendUnavailable")}
                </span>
              </Grid>
            )}
            <Grid item xs={12} md={2} style={{ margin: 0, padding: 0 }}>
              <Button
                color="primary"
                variant="contained"
                aria-describedby={"vehicle" + carKey + " carLocation" + carKey}
                id={"CCButton" + carKey}
                disabled={isSlotAvailable()}
                aria-disabled={isSlotAvailable()}
                onClick={() => handleCarSelect(carInfo, 1)}
              >
                {" "}
                {t("btnContinue")}
              </Button>
            </Grid>
          </Grid>

          <Grid container item>
            <Grid item xs={12} md={3}>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="flex-end"
                justifyContent="space-between"
                width="100%"
                height="100%"
              >
                <Box width="100%" pt={2}>
                  <h3 style={{ margin: 0, padding: 0 }}>
                    <img
                      alt={!isSlotAvailable() ? carInfo.vehicleMake : ""}
                      // title={carInfo.vehicleDetails}
                      className={classes.responsive}
                      src={carInfo.imageUrl}
                    />
                  </h3>
                </Box>
                <Box width="100%">
                  <p className={classes.vehicleinfo} id={"vehicle" + carKey}>
                    {carInfo.vehicleId} : {carInfo.vehicleMake}{" "}
                    {carInfo.vehicleModel}
                  </p>
                  <p className={classes.vehicleinfo}>
                    {carInfo.vehicleTransmissionType} {carInfo.vehicleColour}
                  </p>
                  <p
                    id={"vehicleRegNumber" + carKey}
                    className={classes.vehicleinfo}
                  >
                    {carInfo.vehicleRegNumber}
                    {showEmission &&
                    <span
                      id={"co2rate" + carKey}
                      style={{ marginLeft: "15px" }}
                    >
                      CO2**:{" "}
                      {co2dict.get(
                        (carInfo.vehicleMake + " " + carInfo.vehicleModel)
                          .toString()
                          .replace(/ /g, "_")
                      ) ?? "-"}
                    </span>
                    }
                  </p>
                </Box>
              </Box>
            </Grid>
            <Grid
              container
              direction="column"
              justifyContent="space-between"
              item
              xs={12}
              md={9}
              style={{
                marginTop: "10px",
                padding: "20px",
                zIndex: 0,
              }}
            >
              <Grid item>
               <BookingTimeRangeSlider properties={bookingTimeRangeProps()} />
              </Grid>
              <Grid item id={"vehicleUnavailableDescription" + carKey}>
                <span
                  className={classes.vehicleinfo}
                  id={"vehicleUnavailableReason" + carKey}
                >
                  {isSlotAvailable()
                    ? t(
                        "ReservationWizard.carClubPage.legendUnavailableDescription"
                      )
                    : ""}
                  {isSlotAvailable() && <br />}
                </span>
                <span
                  className={classes.vehicleinfo}
                  id={"vehicleUnavailablePeriods" + carKey}
                >
                  {getVehilceUnavailableDescription(
                    getVehilceUnavailableArray(carInfo.availabilityPeriods)
                  )}
                  {isSlotAvailable() && !isSlotAvailableWithoutDownTime() && (
                    <br />
                  )}
                </span>
                <span
                  className={classes.vehicleinfo}
                  id={"vehicleUnavailable15MinGap" + carKey}
                >
                  {isSlotAvailable() && !isSlotAvailableWithoutDownTime()
                    ? t(
                        "ReservationWizard.carClubPage.legendUnavailableDueToDownTime"
                      )
                    : ""}
                </span>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default CarClubCar;
