import {
  Backdrop,
  Box,
  CircularProgress,
  Dialog,
  Divider,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import Grid from "@mui/material/Grid";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import axiosInstance from "../../helpers/axiosInstance";
import {
  JourneyDetailsViewModel,
  JourneyItemActionViewModel,
} from "./ReservationViewType";
import CancelIcon from "@mui/icons-material/Cancel";
import RefreshIcon from "@mui/icons-material/Refresh";
import PrintIcon from "@mui/icons-material/Print";
import ArrowBack from "@mui/icons-material/ArrowBack";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import YesNoDialog from "controls/YesNoDialog";
import { clearAlertMessage } from "components/store/actions/shared/alertActions";
import { useDispatch } from "_testHelpers/react-redux-hooks";
import { appInsights } from "helpers/appInsights";
import { SeverityLevel } from "@microsoft/applicationinsights-common";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
    marginBottom: 20,
    paddingRight: 30,
    paddingTop: 15,
    paddingBottom: 15,
  },
  title: {
    fontSize: "1.4em",
    paddingLeft: 30,
    marginBottom: 10,
  },
  subtitle: {
    fontSize: "1.2em",
    marginTop: 10,
    marginBottom: 10,
  },
  tableHeader: {
    fontWeight: "bold",
    padding: 4,
    paddingLeft: 15,
    paddingRight: 15,
    borderBottom: "none",
    fontSize: "1.1em",
  },
  tableBody: {
    fontSize: "1.1em",
    padding: 4,
    paddingLeft: 15,
    paddingRight: 15,
    borderBottom: "none",
  },
  detail: {
    paddingTop: 15,
  },
  divider: {
    backgroundColor: "#000",
    height: "0.8px",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
  rootcell: {
    "& .MuiTableCell-root": {
      verticalAlign: "top",
    },
  },
}));

const ViewJP = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [openYesNo, setopenYesNo] = React.useState(false);
  const [resData, setresData] = React.useState<JourneyDetailsViewModel>();
  const [journeyActions, setjourneyActions] =
    React.useState<JourneyItemActionViewModel>();
  const { journeyID, reference } = useParams<{
    journeyID: string;
    reference: string;
  }>();
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  const printRef = React.useRef<HTMLButtonElement>(null);
  const titleRef = React.useRef<HTMLElement>(null);

  appInsights.trackPageView();
  function handleOpenYesNoDialog() {
    setopenYesNo(true);
  }
  const handleYesNoClose = () => {
    setopenYesNo(false);
  };

  useEffect(() => {
    document.title = t("ViewJourneyTitle");
    dispatch(clearAlertMessage());
    setLoading(true);
    GetJourneyActions();
    GetReservations();
    if (printRef.current != null && !props.disableAutoFocus) printRef.current.focus();
  }, []);

  const GetReservations = async () => {
    const data = await axiosInstance
      .get(`reservation/details/${journeyID}/${reference}`)
      .then((response) => {
        setresData(response.data);
      })
      .catch((error) => {
        console.error("There was an error!", error);
        appInsights.trackException({
          id: "ViewJP",
          exception: error,
          severityLevel: SeverityLevel.Error,
        });
      });
  };
  const handleLoading = (isloading) => {
    setLoading(isloading);
  };

  const GetJourneyActions = async () => {
    const journeyAction = await axiosInstance
      .get(`journeyassessment/journeyactions/${journeyID}`)
      .then((response) => {
        setjourneyActions(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  let history = useNavigate();
  const RebookReservation = () => {
    appInsights.trackEvent({
      name: "View JP Rebook",
      properties: { Reference: reference },
    });
    history("/home?id=" + journeyID);
  };
  return (
    <Box m={3}>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress />
      </Backdrop>
      <Grid data-testid="viewJP" container className={classes.rootcell} spacing={3}>
        <Dialog
          aria-labelledby="yesno-dialog-title"
          aria-describedby="yesno-dialog-description"
          open={openYesNo}
        >
          <YesNoDialog
            open={openYesNo}
            journeyUId={journeyID}
            reference={reference}
            hasReservation={journeyActions?.hasReservation}
            action={handleYesNoClose}
            handleLoading={handleLoading}
          />
        </Dialog>
        <Grid id="printtitleHeader" style={{ display: "none" }} item xs={12}>
          <Typography align="center" className={classes.title} variant="h2">
            {t("Res.ThankYouForYourJourney")}
          </Typography>
          <div style={{ paddingLeft: 30 }}>
            <Typography
              align="center"
              variant="h3"
              style={{
                paddingBottom: 20,
                color: "#d02216",
                fontSize: "1.2em",
              }}
            >
              {t("Res.PleaseSeeConfirmationDetails")}
            </Typography>
            <Divider className={classes.divider} />{" "}
          </div>
        </Grid>
        <Grid id="titleHeader" item xs={12} md={9}>
          <Typography ref={titleRef} className={classes.title} variant="h2">
            {t("Res.JourneyDetails")}
          </Typography>
          <div style={{ paddingLeft: 30 }}>
            <Typography className={classes.subtitle} variant="h3">
              {t("Res.SummaryOfYourJourney")}
            </Typography>
            <Divider className={classes.divider} />{" "}
          </div>
        </Grid>
        <Grid item xs={12} md={3} id="actionButtons">
          <Box display="flex" style={{ float: "right" }}>
            <IconButton
              id="printJourneyButton"
              size="medium"
              aria-label={t("Res.Print")}
              title={t("Res.PrintTitle")}
              //role="img"
              ref={printRef}
              onClick={() => {
                dispatch(clearAlertMessage());
                appInsights.trackEvent({
                  name: "View JP Print",
                  properties: { Reference: reference },
                });
                window.print();
              }}
              style={{
                marginRight: 5,
                border: "1px solid #547D66",
                color: "#000000",
              }}
            >
              <PrintIcon />
            </IconButton>
            <IconButton
              id="cancelJourneyButton"
              size="medium"
              onClick={() => {
                appInsights.trackEvent({
                  name: "View JP Cancel",
                  properties: { Reference: reference },
                });
                handleOpenYesNoDialog();
              }}
              aria-label={t("Cancel")}
              title={t("CancelTitle")}
              //role="img"
              style={
                journeyActions?.canDelete
                  ? {
                      marginRight: 5,
                      border: "1px solid #547D66",
                      color: "#d02216",
                    }
                  : { display: "none" }
              }
            >
              <CancelIcon />
            </IconButton>
            <IconButton
              id="repeatJourneyButton"
              size="medium"
              onClick={() => RebookReservation()}
              aria-label={t("BookAgain")}
              title={t("BookAgainTitle")}
              //role="img"
              style={
                journeyActions?.canCopy
                  ? {
                      marginRight: 5,
                      border: "1px solid #547D66",
                      color: "#000000",
                    }
                  : { display: "none" }
              }
            >
              <RefreshIcon />
            </IconButton>
            <IconButton
              id="backButton"
              size="medium"
              onClick={() => {
                dispatch(clearAlertMessage());
                history(-1);
              }}
              title={t("BackTitle")}
              aria-label={t("Back")}
              //role="img"
              style={{ border: "1px solid #547D66", color: "#000000" }}
            >
              <ArrowBack />
            </IconButton>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} className={classes.root}>
          <Typography className={classes.title} variant="h3">
            {t("Res.JourneyInformation")}
          </Typography>
          <Box style={{ paddingLeft: 30 }}>
            <Divider className={classes.divider} />
            <TableContainer id="detailsTable" className={classes.detail}>
              <Table aria-label="journey table">
                <TableBody>
                  <TableRow>
                    <TableCell width="50%" className={classes.tableHeader}>
                      {t("ReferenceNumber")}
                    </TableCell>
                    <TableCell
                      id="referenceNumberDiv"
                      className={classes.tableBody}
                    >
                      {resData?.referenceNumber}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.tableHeader}>
                      {t("Status")}
                    </TableCell>
                    <TableCell id="statusDiv" className={classes.tableBody}>
                      {resData?.status}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.tableHeader}>
                      {t("Res.StartEndAddress")}
                    </TableCell>
                    <TableCell
                      id="startAddressDiv"
                      className={classes.tableBody}
                    >
                      {" "}
                      <strong>{t("Start")}:</strong> {resData?.startAddress}{" "}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.tableHeader}></TableCell>
                    <TableCell id="endAddressDiv" className={classes.tableBody}>
                      {" "}
                      <strong>{t("End")}: </strong> {resData?.endAddress}{" "}
                    </TableCell>
                  </TableRow>
                  {resData?.midPoints != null &&
                    resData?.midPoints.length > 0 && (
                      <TableRow id="midPointsDiv">
                        <TableCell className={classes.tableHeader}>
                          {t("MidPoints")}
                        </TableCell>
                        <TableCell className={classes.tableBody}>
                          {resData?.midPoints?.map((p, index) => {
                            return (
                              <Typography key={index}>
                                {" "}
                                {p} <br />{" "}
                              </Typography>
                            );
                          })}{" "}
                        </TableCell>
                      </TableRow>
                    )}
                  <TableRow id="startAndEndTimeDiv">
                    <TableCell
                      style={{ whiteSpace: "pre-wrap" }}
                      className={classes.tableHeader}
                    >
                      {t("Res.StartEndDateTime")}
                    </TableCell>
                    <TableCell
                      id="startDateTimeDiv"
                      className={classes.tableBody}
                    >
                      {" "}
                      <strong>{t("Start")}:</strong>
                      {resData?.startDateTime === null
                        ? ""
                        : moment(resData?.startDateTime).format(
                            t("DateTimeFormat")
                          )}{" "}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.tableHeader}></TableCell>
                    <TableCell
                      id="endDateTimeDiv"
                      className={classes.tableBody}
                    >
                      {" "}
                      <strong>{t("End")}: </strong>
                      {resData?.endDateTime === null
                        ? ""
                        : moment(resData?.endDateTime).format(
                            t("DateTimeFormat")
                          )}{" "}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.tableHeader}>
                      {t("Res.ModeOfTransport")}
                    </TableCell>
                    <TableCell
                      id="modeOfTransportDiv"
                      className={classes.tableBody}
                    >
                      {" "}
                      {resData?.modeOfTransport}{" "}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography className={classes.title} variant="h3">
            {t("Res.DriverDetails")}
          </Typography>
          <Box style={{ paddingLeft: 30 }}>
            <Divider className={classes.divider} />
            <TableContainer id="driverDetailsTable" className={classes.detail}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell width="50%" className={classes.tableHeader}>
                      {" "}
                      {t("Res.Driver")}
                    </TableCell>
                    <TableCell id="driverNameDiv" className={classes.tableBody}>
                      {resData?.driver}{" "}
                    </TableCell>
                  </TableRow>
                  {resData?.registrationNumber != null &&
                    resData?.registrationNumber.trim() !== "" && (
                      <TableRow>
                        <TableCell className={classes.tableHeader}>
                          {" "}
                          {t("Res.RegistrationNumber")}
                        </TableCell>
                        <TableCell
                          id="registrationNumberDiv"
                          className={classes.tableBody}
                        >
                          {resData?.registrationNumber}{" "}
                        </TableCell>
                      </TableRow>
                    )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
export default ViewJP;
