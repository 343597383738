import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface BookingReferenceState {
  additionalInformationFields: any[];
  message: string;
  errors: string;
  warnings: string;
  hasErrors: boolean;
  hasWarnings: boolean;
}
const initialState: BookingReferenceState = {
  additionalInformationFields: [],
  message: "",
  errors: "",
  warnings: "",
  hasErrors: false,
  hasWarnings: false,
};

export const BookingReferenceSlice = createSlice({
  name: "bookingReferenceDetails",
  initialState,
  reducers: {
    updateBookingReferenceDetails: (
      state,
      action: PayloadAction<BookingReferenceState>
    ) => {
      console.log("Payload received: ", action.payload);
      state.additionalInformationFields =
        action.payload.additionalInformationFields;
      state.message = action.payload.message;
      state.errors = action.payload.errors;
      state.hasWarnings = action.payload.hasWarnings;
      state.warnings = action.payload.warnings;
      state.hasErrors = action.payload.hasErrors;
    },
    resetBookingReferenceDetails: () => initialState,
  },
});

export const { updateBookingReferenceDetails, resetBookingReferenceDetails } =
  BookingReferenceSlice.actions;

export default BookingReferenceSlice.reducer;
