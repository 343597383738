import makeStyles from '@mui/styles/makeStyles';
export default makeStyles((theme) => ({
  stepper: {
    padding: theme.spacing(0, 0, 2),
    marginLeft: "1em",
    marginRight: "1em",
    width: "100%",
  },
  stepIcon: {
    color: "#cdcdcd",
    width: 40,
    height: 40,
  },

  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
  },
  backdrop: {
    zIndex: 99999,
    color: "#fff",
  },
}));
