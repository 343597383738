import { Box, Typography } from "@mui/material";


export default function Rebook() {
    return (
        <Box boxShadow  = {3}>
     <div style={{ marginLeft: "1em", marginBottom: "1em" }}>
        <Typography variant= "h1" >Rebook Reservation</Typography>
        <div> <h1>Edit Reservation Details</h1></div>
                
    </div>
        </Box>
    )
}
