import * as Yup from "yup";

function validateSelectField(value) {
  // console.log("VALUE=", value["selectedValue"]);
  if (
    typeof value["selectedValue"] === "undefined" ||
    value["selectedValue"] === "" ||
    value["selectedValue"] === null
  )
    {return false;}
  else {
  return true;
  }
}

function validateCompany(value) {
  if (
    typeof value["selectedValue"] === "undefined" ||
    value["selectedValue"] === "" ||
    value["selectedValue"] === null
  )
    return false;
  else {
    return true;
  }
}



// const EmailRegex = ^(("".+?""@)|(([0-9a-zA-ZÄäéÖöÜüß]((\.(?!\.))|[-!#\$%&'\*\+/=\?\^`\{\}\|~\w])*)([0-9a-zA-ZÄäéÖöÜüß])@))@((\[(\d{1,3}\.){3}\d{1,3}\])|(([0-9a-zA-ZÄäéÖöÜüß]+[\-0-9a-zA-Z]*\.)+[\-a-zA-ZÄäéÖöÜüß0-9]{0,22}))$;
export const userValidationSchema = Yup.object().shape({
  userTitle: Yup.object().test(
    "Select Field",
    "Users.Errors.userTitleRequired",
    (value) => validateSelectField(value)
  ),
  username: Yup.string()
    .matches(/^[a-zA-Z0-9äöüÄÖÜß@.'*_-]*$/, "Users.Errors.userNameRequired")
    .required("Users.Errors.userNameRequired")
    .max(100, "Users.Errors.userNameMaxLength")
    .nullable(),
  b2CObjectId: Yup.string().nullable(),
  email: Yup.string()
    .required("Users.Errors.emailRequired")
    .email("Users.Errors.invalidEmail")
    .max(100, "Users.Errors.emailMaxLength")
    .nullable(),
  firstName: Yup.string()
    .required("Users.Errors.firtNameRequired")
    .max(20, "Users.Errors.firstNameMaxLength")
    .nullable(),
  lastName: Yup.string()
    .required("Users.Errors.lastNameRequired")
    .max(30, "Users.Errors.lastNameMaxLength")
    .nullable(),
  lineManagerEmail: Yup.string()
    .email("Users.Errors.invalidEmail")
    .max(100, "Users.Errors.emailMaxLength")
    .nullable(),
  preferredLanguage: Yup.object().test(
    "len",
    "Users.Errors.preferredLangRequired",
    (value) => validateSelectField(value)
  ),
  role: Yup.object()
    .test("len", "Users.Errors.roleRequired", (value) =>
      validateSelectField(value)
    )
    .required(),
  companyName: Yup.object().test(
    "len",
    "Users.Errors.companyNameRequired",
    (value) => validateSelectField(value)
  ),
  preferredPhoneNumber: Yup.object().shape({
    selectedInternationalDiallingCode: Yup.string()
      .notRequired()
      .nullable()
      .test(
        "selectedInternationalDiallingCode",
        "Users.Errors.preferredCountryCodeRequired",
        (value, number) => {
          if (!!value) {
            return value.length > 0 && value !== "+";
          }
          if(!!number.parent.number){
            return false;
          }
          return true;
        }
      ),
    number: Yup.string()
    .when(["selectedInternationalDiallingCode"], {
      is: (selectedInternationalDiallingCode) =>
        selectedInternationalDiallingCode &&
        selectedInternationalDiallingCode.length > 0 &&
        selectedInternationalDiallingCode !== "+",
      then: Yup.string()
        .notRequired()
        .nullable()
        .test(
          "number",
          "Users.Errors.preferredPhoneRequired",
          (value) => {
            let exp = /^[0-9]*$/;
            if (value) {
              return exp.test(value);
            } else return false;
          }
        )
        .min(2, "Users.Errors.preferPhoneMaxLength")
        .max(17, "Users.Errors.preferPhoneMaxLength"),
    }),
  }),
  alternativePhoneNumber: Yup.object().shape({
    selectedInternationalDiallingCode: Yup.string()
      .notRequired()
      .nullable()
      .test(
        "selectedInternationalDiallingCode",
        "Users.Errors.alternateCountryCodeRequired",
        (value, number ) => {
          if (!!value) {
            return value.length > 0 && value !== "+";
          }
          if(!!number.parent.number){
            return false;
          }
          return true;
        }
      ),
    number: Yup.string()
    .when(["selectedInternationalDiallingCode"], {
      is: (selectedInternationalDiallingCode) =>
        selectedInternationalDiallingCode &&
        selectedInternationalDiallingCode.length > 0 &&
        selectedInternationalDiallingCode !== "+",
      then: Yup.string()
        .notRequired()
        .nullable()
        .test(
          "number",
          "Users.Errors.alternatePhoneRequired",
          (value) => {
            let exp = /^[0-9]*$/;
            if (value) {
              return exp.test(value);
            } else return false;
          }
        )
        .min(2, "Users.Errors.preferPhoneMaxLength")
        .max(17, "Users.Errors.preferPhoneMaxLength"),
    }),
  }),
});

export const bulkUploadValidationSchema = Yup.object().shape({
  companyList: Yup.object().test(
    "len",
    "Users.Errors.companyNameRequired",
    (value) => validateCompany(value)
  ),
  languageList: Yup.object().test(
    "len",
    "Users.Errors.preferredLangRequired",
    (value) => validateSelectField(value)
  ),
  isFileValid: Yup.boolean().test(
    "Is True",
    "Please Choose a file",
    (value) => value === true
  ),
});
