import { createTheme, responsiveFontSizes, makeStyles, adaptV4Theme } from "@mui/material/styles";
let theme = createTheme({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: "#006639", //'#00A664',
    },
    secondary: {
      // This is green.A700 as hex.
      main: "#11cb5f",
    },
    error: {
      main: "#E91B0C",
    },
    success: {
      main: "#006639",
    },
    info: {
      main: "#116FF2",
    },
    action: {
      //disabled button colors
      disabledBackground: "#666666",
      //disabled button text (CCA Ok) & disabled elements ( not CCA OK )
      disabled: "#DEDEDE",
    },
  },
  //overrides for focus indicators
  components: {
    MuiCheckbox: {
      styleOverrides: {
        root: {
          colorSecondary: {
            "&.Mui-focusVisible": {
              outline: "3px solid #0066FF !important",
            },
            "&.Mui-disabled": {
              color: "#767676 !important",
            },
            "&.Mui-checked": {
              color: "#006639",
            },
          }
        }
      },
    },

    MuiIconButton: {
      styleOverrides: {
        root: {
          colorPrimary: {
            "&.Mui-focusVisible": {
              outline: "3px solid #0066FF !important",
              outlineOffset: "3px",
            },
            "&.Mui-disabled": {
              color: "#767676 !important",
            },
          },
        }
      },


    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          "&:focus": {
            outline: "3px solid #0066FF !important",
            outlineOffset: "3px",
          },
        },
      }
    },
    MuiLink: {
      styleOverrides: {
        root: {

          "&:focus": {
            outline: "3px solid #0066FF !important",
            outlineOffset: "3px",
          },
        },
      },
    },
    //disabled
    MuiSelect: {
      styleOverrides: {
        select: {
          "&.Mui-disabled": {
            color: "#767676",
          },
        },
      },
    },
    MuiNativeSelect: {
      styleOverrides: {
        select: {
          "&.Mui-disabled": {
            color: "#767676",
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          colorPrimary: {
            "&.Mui-disabled": {
              color: "#767676",
            },
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          "&.Mui-disabled": {
            color: "#767676",
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
      root: {
        background: "#006639",
        color: "#FFFFFF"
      }
    },
  },
  },
  typography: {
    fontFamily: "Arial, Roboto, Segoe UI",
    fontSize: 11,
  },
});

theme.typography.h1 = {
  fontSize: "1rem",
  fontWeight: "bold",
  "@media (min-width:600px)": {
    fontSize: "1rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "1.2rem",
  },
  "&:focus": {
    // color: "green",
    outline: "none",
  },
};

theme.typography.h2 = {
  fontSize: "0.7rem",
  fontWeight: "bold",
  "@media (min-width:600px)": {
    fontSize: "0.7rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "0.8rem",
  },
};

theme.typography.h3 = {
  fontSize: "0.7rem",
  "@media (min-width:600px)": {
    fontSize: "0.7rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "0.8rem",
    fontWeight: "normal",
  },
};
theme.typography.caption = {
  fontSize: "0.7rem",
  "@media (min-width:600px)": {
    fontSize: "0.7rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "0.7rem",
  },
};


theme = responsiveFontSizes(theme);
export default theme;
