import React from "react";
import { useState } from "react";
import selectedGIF from "./../../../../../Assets/Images/Selected.gif";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { getLocaleCurrency } from "helpers/localization";
import DialogTitle from "@mui/material/DialogTitle";
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { authService } from "services/authService";
import {
  Box,
  Tooltip,
  Button,
  Grid,
  Typography,
  Select,
  CircularProgress,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  TableBody,
  IconButton,
  Checkbox,
} from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import CancelIcon from "@mui/icons-material/Cancel";
import { Extra, ExtraEditingProps } from "../reservationFormType";
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  margin: {
    margin: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 60,
    height: 10,
    textAlign: "center",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },

  table: {
    "& .MuiTableCell-root": {
      borderLeft: "1px solid rgba(224,224,224,1)",
      padding: "1px",
      paddingLeft: "1px",
      paaddingRight: "1px",
      paddingBottom: "2px",
      paaddingTop: "2px",
    },
  },
  dialogCustomizedWidth: {
    "max-width": "600px",
  },
  longTextStyle: {
    wordWrap: "break-word",
    maxWidth: 1000,
  },
}));

const ExtraEditingDialog: React.FC<ExtraEditingProps> = (props) => {
  const { brandInfo, modelCode, modelDescription, mainIndex} = props;
  const locale = authService.getUserLocale();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [clickedButtonId, setClickedButtonId] = React.useState("");
  const { t } = useTranslation();

  const [includedExtras, setIncludedExtras] = React.useState(
    props.includedExtras ?? []
  );
  const [selectedExtras, setSelectedExtras] = React.useState(
    props.selectedExtras ?? []
  );

  const isMaxLimit = () => {
    return selectedExtras && selectedExtras.length >= 5;
  };

  const StyledTableRow = withStyles((theme) => ({
    root: {},
  }))(TableRow);

  const updateItem = (ext, qty) => {
    let updated = selectedExtras.filter((item) => item.id !== ext.id);
    var newcopy = JSON.parse(JSON.stringify(ext));
    newcopy.quantity = qty;
    updated.push(newcopy);
    setSelectedExtras(updated);
  };
  const removeItem = (ext) => {
    setSelectedExtras(selectedExtras.filter((item) => item.id !== ext.id));
  };
  const addItem = (ext) => {
    if (!isMaxLimit()) {
      var newcopy = JSON.parse(JSON.stringify(ext));
      let existing = [...selectedExtras];
      newcopy.quantity = 1;
      existing.push(newcopy);
      setSelectedExtras(existing);
    }
  };

  const handleClickOpen = () => {
    setSelectedExtras(props.brandInfo.selectedExtras ?? []);
    setOpen(true);
  };

  const handleClose = () => {
    // props.brandInfo.selectedExtras = selectedExtras;
    props.updateExtras(selectedExtras, brandInfo.uniqueKey);
    props.updateTotal();
    setOpen(false);
    // setIncludedExtras([]);
    document.getElementById("ExtraEditButton"+brandInfo.uniqueKey)?.focus();
  };

  const handleClearAll = () => {
    setSelectedExtras([]);
  };

  const isIncluded = (ext) => {
    let result = false;
    if (selectedExtras.find((x) => x.id === ext.id)) {
      result = true;
    }
    return result;
  };

  const extrasTable = (extras: Extra[]) => {
    return (
      <Grid container spacing={1} style={{ border: "1px solid grey" ,marginTop:"1px"}}>
        {extras && extras.length > 0 ? (
          extras.map((extra, index) => (
            <ExtraRow
              key={index}
              extra={extra}
              index={index}
              isSelected={isIncluded(extra)}
            />
          ))
        ) : (
          <span>{t("extraEdit.msgNoExtrasFound")}</span>
        )}
      </Grid>
    );
  };

  const ExtraRow = (props) => {
    const [isSelected, setIsSelected] = React.useState(props.isSelected);
    const getSelectedQty = (id) => {
      let item = selectedExtras.find((item) => item.id === id);
      if (item) return item.quantity;
      return 0;
    };

    const isMandatory = () => {
      if (includedExtras && includedExtras.length > 0) {
        return includedExtras.find((x) => x.id === props.extra.id);
      }
      return false;
    };

    const addRemoveItem = (qty) => {
      switch (isSelected) {
        case true:
          qty > 0 ? updateItem(props.extra, qty) : removeItem(props.extra);
          break;
        case false:
          qty > 0 ? updateItem(props.extra, qty) : addItem(props.extra);
          break;
      }
    };
    React.useEffect(() => {
      if (open)
      document.getElementById(clickedButtonId)?.focus();
    }, [isSelected, props]);

    return (
      <Grid container>      
        <TableContainer
        // component={Paper}
        >
          <Table className={classes.table} aria-label="Extras selection table">
            <colgroup>
              <col width="15%" />
              <col width="25%" />
              <col width="35%" />
              <col width="25%" />
            </colgroup>
            <TableBody>
              <StyledTableRow tabIndex={0}>
                <TableCell
                  style={{
                    //width: "15%",
                    textAlign: "center",
                    verticalAlign:"middle",
                    marginRight: "2px",
                    marginLeft: "10px",
                  }}>
                  <img
                    id={"img_id_" + props.index}
                    src={selectedGIF}
                    title={t("Selected")}
                    style={{
                      //border: "1px solid red",
                      textAlign: "center",
                      maxHeight: "25px",
                      maxWidth: "25px",
                      margin: 0,
                      display: isSelected ? "block" : "none",
                    }}
                    alt="Selected"
                  />
                </TableCell>
                <TableCell
                  style={{
                    //width: "30%",
                    textAlign: "center",
                    verticalAlign:"middle",
                    justifyContent: "center",
                  }}>
                  <Typography
                    tabIndex={0}
                    variant="body2"
                    style={{
                      //wordBreak: "break-all",
                      wordWrap: "break-word",
                      display: modelDescription ? "block" : "none",
                      verticalAlign: "middle",
                      textAlign: "center",
                      fontStyle: "normal",
                      fontSize: "12px",
                      color: "Black",
                    }}
                    key={props.index}
                    id={"extraDesc_" + props.index}>
                    {props.extra.description}
                  </Typography>
                </TableCell>
                <TableCell
                  style={{
                    //width: "30%",
                    textAlign: "center",
                    verticalAlign:"middle",
                    justifyContent: "center",
                  }}>
                  <Typography
                    tabIndex={0}
                    variant="body2"
                    style={{
                      display: modelDescription ? "block" : "none",
                      verticalAlign: "middle",
                      //margin: 2,
                      textAlign: "center",
                      fontStyle: "normal",
                      fontSize: "12px",
                      color: "Black",
                      //wordBreak: "break-all",
                      wordWrap: "break-word",
                    }}
                    key={props.index}
                    id={"extraUnitPrise_" + props.index}>
                    {getLocaleCurrency(
                      props.extra.unitPriceWithCurrency,
                      locale,
                      props.extra?.currency
                    )}
                    {/* {props.extra.unitPriceWithCurrency}{" "} */}
                    {props.extra.isCountable
                      ? " (" + t("Res.PerUnit") + ")"
                      : ""}
                  </Typography>
                </TableCell>
                <TableCell
                  style={{
                    //width: "25%",
                    textAlign: "left",
                    justifyContent: "left",

                  }}>
                  {!props.extra.isCountable ? ( // button
                    <Button
                      id={"selExtra_ET_" + props.index}
                      aria-label={isMandatory()
                        ? t("Select")+ " " + props.extra.description
                        : isSelected
                        ? t("extraEdit.remove")+ " " + props.extra.description 
                        : t("extraEdit.add")+ " " + props.extra.description}
                      tabIndex={0}
                      style={{
                        textTransform: "none",
                        height: "24px",
                        backgroundColor: isSelected ? "#cc0000" : "Primary",
                        width: "60px",
                      }}
                      disabled={isMandatory() ? true : false}
                      variant="contained"
                      color={"primary"}
                      onClick={(e) => {
                        addRemoveItem(0);
                        setClickedButtonId("selExtra_ET_" + props.index);

                      }}>
                      {isMandatory()
                        ? t("extraEdit.selected")
                        : isSelected
                        ? t("extraEdit.remove")
                        : t("extraEdit.add")}
                    </Button>
                  ) : (
                    // dropdown
                    <div
                      style={{
                        width: "64px",
                        textAlign: "left",
                        overflow: "hidden",
                      }}>
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                        size="small">
                        <NativeSelect
                          id={"dropdwon_" + props.index}
                          //value={state.age}
                          //onChange={handleChange}
                          style={{
                            marginTop: "-0.1em",
                            marginLeft: "1px",
                            width: "45px",
                            height: "24px",
                            paddingLeft: "5px",
                            paddingRight: "1px",
                          }}
                          name="counts"
                          className={classes.selectEmpty}
                          value={getSelectedQty(props.extra.id)}
                          onChange={(e) => {
                            addRemoveItem(e.target.value);
                            setClickedButtonId("dropdwon_" + props.index);

                          }}
                          inputProps={{ "aria-label": "counts" }}>
                          <option value={0}>0</option>
                          <option value={1}>1</option>
                          <option value={2}>2</option>
                          <option value={3}>3</option>
                        </NativeSelect>
                      </FormControl>
                    </div>
                  )}
                </TableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    );
  };

  /*  React.useEffect(() => {
    
    if (props.mandatoryExtras && props.mandatoryExtras.length > 0) {
      setIncludedExtras(props.mandatoryExtras);
    }
    //  console.log("Extras Added: ", includedExtras);
  }, []);

  React.useEffect(() => {}, [includedExtras]);*/
  return (
    <div>
      <Button
        disabled={!props.brandInfo.isSelected}
        color="primary"
        id ={"ExtraEditButton"+props.brandInfo.uniqueKey}
        style={{ marginLeft: "5px" }}
        onClick={handleClickOpen}
        aria-label={t("carClasses.btnExtrasAriaLabel", {
          carmodel: modelCode + ", " + modelDescription,
        })}>
        {t("carClasses.labelBtnEdit")}
      </Button>

      <Dialog
        open={open}
        //maxWidth={"sm"} // 'sm' || 'md' || 'lg' || 'xl'
        fullWidth={true}
        // PaperProps={{
        //   sx: {
        //     width: "50%",
        //     maxHeight: 300
        //   }
        // }}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        // classes={{ paperFullWidth: classes.dialogCustomizedWidth }}
      >
        <Grid container direction="row" justifyContent="flex-end">
            <IconButton
              tabIndex={0}
              title={t("Companies.btnCancel")}
              style={{
                display: modelDescription ? "block" : "none",
                verticalAlign: "bottom",
                margin: 2,
                textAlign: "left",
                fontStyle: "bold", 
                fontSize: "10px",
                color: "Black",
              }}
              aria-label={t("extraEdit.closeDialog")}
              onClick={handleClose}
              size="large">
            <CancelIcon color="error" />
            </IconButton>
        </Grid>
        <DialogTitle
          id="form-dialog-title"
          // style={{ height: "24px" }}
          style={{ padding: "2px 24px" }}>
          <Grid
            container
            alignItems="flex-start"
            justifyContent="flex-start"
          >
            <Grid item xs={4}>
              <Typography id="ExtraEditorTitleId" variant="h2" tabIndex={0}>
                {t("extraEdit.selectExtras") + ":"}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              {isMaxLimit() && (
                <Typography
                  id="extraCountMessage"
                  variant="body2"
                  style={{
                    fontWeight: "bold",
                    color: "darkred",
                    textAlign: "center",
                    width: "100%",
                  }}>
                  {t("extraEdit.msgMaxExtraSelect")}
                </Typography>
              )}
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent
        style={{ marginTop: "2px" }}
        //style={{ overflow: "hidden" }}
        //style={{ height: "100%" }}
        >
          <div className={classes.root}>
            {extrasTable(brandInfo.extrasOptions.extras)}
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClearAll}
            color="primary"
            aria-label={t("extraEdit.clearAll")}
            style={{
              height: "10px",
              textTransform: "none",
            }}>
            {t("extraEdit.clearAll")}
          </Button>
          <Button
            onClick={handleClose}
            aria-label={t("extraEdit.done")}
            color="primary"
            variant="contained"
            style={{ textTransform: "none", height: "24px" }}>
            {t("extraEdit.done")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ExtraEditingDialog;
