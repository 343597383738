import React from "react";
import {
  Box,
  FormControl,
  Backdrop,
  Button,
  Grid,
  Typography,
  Select,
  CircularProgress,
  IconButton,
  TextField,
} from "@mui/material";
import { Link as MuiLink } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import {
  InitialValue,
  RiskAssessmentParamViewModel,
  RiskAssessmentViewModel,
} from "../RiskAssessment/riskAssessmentType";
import { ReservationProps } from "../../reservationType";
import makeStyles from '@mui/styles/makeStyles';
import { appInsights } from "helpers/appInsights";
import axiosInstance from "helpers/axiosInstance";
import { useDispatch } from "react-redux";
import { broadcastAnnouncement } from "components/store/actions/shared/announcementActions";
import { useTranslation } from "react-i18next";
import {
  clearAlertMessage,
  showErrorAlertMessage,
} from "components/store/actions/shared/alertActions";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import FocusError from "controls/formik/FocusOnError";
import { authService } from "services/authService";
import { Label } from "@mui/icons-material";

const useStyles = makeStyles({
  root: {
    "& .MuiFormHelperText-root": {
      color: "#DF0000",
      fontSize: 12,
      marginLeft: "15px",
    },
    "& .MuiInputBase-input": {
      fontSize: "0.875rem",
    },
    "& ::placeholder": {
      fontStyle: "italic",
      color: "#000",
      opacity: 0.95,
    },
    "& .alignItemCentre": {
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",
    },
    "& .topLeftZeroPadding": {
      paddingLeft: 0,
      paddingTop: 0,
    },
    "& .alignItemCentreWithH2Font": {
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",
      fontVariant: "h2",
    },
    "& .themeheader": {
      borderRight: "1px solid #ddd",
      display: "flex",
    },
    "& .checkBoxBlue": {
      color: "blue",
      fontWeight: 18,
    },
    "& .RedWarningFont": {
      color: "#DF0000",
      marginTop: "15px",
      fontSize: 12,
    },
    "& .LargeTitle": {
      fontSize: 20,
      fontWeight: "bold",
    },
    "& .MidTitle": {
      fontSize: 16,
    },
    "& .NewJourneyTitle": {
      fontSize: 30,
    },
    "& .LargeTitleHR": {
      border: "none",
      //  border: none,
      height: 2,
      backgroundColor: "#111111",
    },
    "& .MidTitleHR": {
      border: "none",
      //  border: none,
      height: 1,
      backgroundColor: "#111111",
    },
    "& .MuiSvgIcon-fontSizeSmall": {
      fontSize: "1rem",
    },
    
  },
  outlinedButton: {
    border: "1px solid rgba(0, 102, 57, 0.95);",
  },
  inputRoot: {
    fontSize: 30,
  },
  labelRoot: {
    fontSize: 30,
    color: "red",
    "&$labelFocused": {
      color: "purple",
    },
  },
  errorWarning: {
    color: "#DF0000",
    fontSize: 12,
    marginLeft: "15px",
  },
  backdrop: {
    zIndex: 99999,
    color: "#fff",
  },

  labelFocused: {},
});

const RiskAssessmentForm: React.FC<ReservationProps> = (props) => {
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [riskAssessment, setRiskAssessment] =
    React.useState<RiskAssessmentViewModel>(InitialValue);
  const riskAssessmentNameRef = React.useRef<HTMLInputElement>(null);
  const insuranceRef = React.useRef<HTMLInputElement>(null);
  const validLicenceRef = React.useRef<HTMLInputElement>(null);
  const complyRoadTrafficRef = React.useRef<HTMLInputElement>(null);
  const titleRef = React.useRef<HTMLDivElement>(null);

  const initRiskAssessmentParamViewModel: RiskAssessmentParamViewModel = {
    JourneyProfileUId: props.journeyUId,
    OrigJourneyUId: props.origJourneyUId, //"040d575e-77dd-49bb-8e82-740ffe829797", //
  };
  const [IsInitialLoad, setIsInitialLoad] = React.useState(false);

  const [rebookInitialLoad, setRebookInitialLoad] = React.useState(false);
  const [registrationNumberValue, setregistrationNumberValue] =
    React.useState("");
  const [insuranceDropdownValue, setinsuranceDropdownValue] =
    React.useState("yes");
  const [hiddenInsuranceError, sethiddenInsuranceError] =
    React.useState("hidden");

  const [
    validLicenceForVehicleDropdownValue,
    setvalidLicenceForVehicleDropdownValue,
  ] = React.useState("yes");
  const [
    hiddenValidLicenceForVehicleError,
    sethiddenValidLicenceForVehicleError,
  ] = React.useState("hidden");

  const [complyRoadTrafficDropdownValue, setcomplyRoadTrafficDropdownValue] =
    React.useState("yes");
  const [hiddenComplyRoadTrafficError, sethiddenComplyRoadTrafficError] =
    React.useState("hidden");

  var userCompany = authService.getUserCompanyCountry(); // not company.countryCode

  const [insuranceDropdownSelected, setInsuranceDropdownSelected] =
    React.useState(false);
  const [
    validLicenceForVehicleDropdownSelected,
    setValidLicenceForVehicleDropdownSelected,
  ] = React.useState(false);
  const [
    complyRoadTrafficDropdownSelected,
    setComplyRoadTrafficDropdownSelected,
  ] = React.useState(false);

  var companyCountryCode = authService.getUserCompanyCountryCode();
  function SetValues() {
    setIsInitialLoad(false);
    setRebookInitialLoad(false);
    return true;
  }

  const riskAssessmentValidationSchema = Yup.object().shape({
    sections: Yup.array().of(
      Yup.object().shape({
        questions: Yup.array().of(
          Yup.object().shape({
            answer: Yup.string()
              .nullable()
              // .max(20, t("JourneyRiskAssessment.registrationNumberMax20"))
              .required(t("JourneyRiskAssessment.registrationNumberRequired"))
              .test("test initial value", t(" "), () => SetValues()),
          })
        ),
      })
    ),
  });

  const handleInsuranceDropdownChange = (event, data, setFieldValue) => {
    setinsuranceDropdownValue(event.target.value);
    setFieldValue("insuranceCheck", event.target.value);
    if (
      event.target.value === "undefined" ||
      event.target.value === "" ||
      event.target.value === null
    ) {
      sethiddenInsuranceError("");
      setInsuranceDropdownSelected(false);
    } else {
      sethiddenInsuranceError("hidden");
      setInsuranceDropdownSelected(true);
    }
  };

  const handleValidLicenceForVehicleDropdownChange = (
    event,
    data,
    setFieldValue
  ) => {
    setvalidLicenceForVehicleDropdownValue(event.target.value);
    setFieldValue("validLicenceForVehicle", event.target.value);
    if (
      event.target.value === "undefined" ||
      event.target.value === "" ||
      event.target.value === null
    ) {
      sethiddenValidLicenceForVehicleError("");
      setValidLicenceForVehicleDropdownSelected(false);
    } else {
      sethiddenValidLicenceForVehicleError("hidden");
      setValidLicenceForVehicleDropdownSelected(true);
    }
  };

  const handleComplyRoadTrafficDropdownChange = (
    event,
    data,
    setFieldValue
  ) => {
    setcomplyRoadTrafficDropdownValue(event.target.value);
    setFieldValue("complyRoadTrafficDropdownValue", event.target.value);
    if (
      event.target.value === "undefined" ||
      event.target.value === "" ||
      event.target.value === null
    ) {
      sethiddenComplyRoadTrafficError("");
      setComplyRoadTrafficDropdownSelected(false);
    } else {
      sethiddenComplyRoadTrafficError("hidden");
      setComplyRoadTrafficDropdownSelected(true);
    }
  };

  React.useEffect(() => {
    dispatch(clearAlertMessage());
    loadriskassessment(initRiskAssessmentParamViewModel);

    if (titleRef.current !== null) titleRef.current.focus();
  }, [props.journeyUId]);

  const loadriskassessment = async (value) => {
    setLoading(true);
    setIsInitialLoad(true);

    if (props.journeyUId !== "") {
      await axiosInstance
        .post("riskassessment/getriskassessment/", value)
        .then((response) => {
          appInsights.trackEvent({
            name: "Fetch journey Risk Assessment",
            properties: { journeyUId: props.journeyUId },
          });
          console.log("load riskassessment=", response.data);
          if (companyCountryCode === "GB"|| companyCountryCode==="IE" ) {
            response.data["sections"][0]["questions"][1]["answer"] = "yes";
            response.data["sections"][0]["questions"][2]["answer"] = "yes";
            response.data["sections"][0]["questions"][3]["answer"] = "yes";
          } //germany
          else {
            response.data["sections"][0]["questions"][1]["answer"] = "yes";
            response.data["sections"][0]["questions"][2]["answer"] = "yes";
          }
          setRiskAssessment(response.data);

          if (
            value.OrigJourneyUId === "" ||
            value.OrigJourneyUId === undefined
          ) {
            setInsuranceDropdownSelected(false);
            setValidLicenceForVehicleDropdownSelected(false);
            setComplyRoadTrafficDropdownSelected(false);
          } else {
            setRebookInitialLoad(true);

            setregistrationNumberValue(
              response.data["sections"][0]["questions"][0]["answer"]
            );
            if (companyCountryCode === "GB" || companyCountryCode==="IE" ) {
              setinsuranceDropdownValue(
                response.data["sections"][0]["questions"][1]["choices"][
                  "selectedValue"
                ]
              );
              setInsuranceDropdownSelected(true);
              setvalidLicenceForVehicleDropdownValue(
                response.data["sections"][0]["questions"][2]["choices"][
                  "selectedValue"
                ]
              );
              setValidLicenceForVehicleDropdownSelected(true);

              setcomplyRoadTrafficDropdownValue(
                response.data["sections"][0]["questions"][3]["choices"][
                  "selectedValue"
                ]
              );
              setComplyRoadTrafficDropdownSelected(true);
            } else {
              setvalidLicenceForVehicleDropdownValue(
                response.data["sections"][0]["questions"][1]["choices"][
                  "selectedValue"
                ]
              );
              setValidLicenceForVehicleDropdownSelected(true);

              setcomplyRoadTrafficDropdownValue(
                response.data["sections"][0]["questions"][2]["choices"][
                  "selectedValue"
                ]
              );
              setComplyRoadTrafficDropdownSelected(true);
            }
          }
        })
        .catch((error) => {
          if (
            error.response != null &&
            error.response.status != null &&
            error.response.status === 400
          ) {
            appInsights.trackException({
              id: "Fetch Company Risk Assessment",
              exception: error,
              severityLevel: SeverityLevel.Error,
            });

            dispatch(showErrorAlertMessage(t("UnexpectedError")));
            dispatch(broadcastAnnouncement(t("UnexpectedError")));
            console.error("There is an error", error);
          } else console.error("There is an error", error);
        });
    } else {
      //doing nothing here
    }
    setLoading(false);
  };

  return (
    <Formik
      initialValues={loading ? InitialValue : riskAssessment}
      validateOnChange={true}
      validateOnBlur={false}
      enableReinitialize={true}
      validationSchema={riskAssessmentValidationSchema}
      onSubmit={async (values, { setFieldTouched, setSubmitting }) => {
        dispatch(clearAlertMessage());

        if (companyCountryCode === "GB" || companyCountryCode==="IE") {
          riskAssessment["sections"][0]["questions"][0]["answer"] =
            riskAssessmentNameRef.current?.value;

          riskAssessment["sections"][0]["questions"][1]["answer"] =
            insuranceDropdownValue;
          riskAssessment["sections"][0]["questions"][2]["answer"] =
            validLicenceForVehicleDropdownValue;
          riskAssessment["sections"][0]["questions"][3]["answer"] =
            complyRoadTrafficDropdownValue;

          riskAssessment["sections"][0]["questions"][1]["choices"][
            "selectedValue"
          ] = insuranceDropdownValue;
          riskAssessment["sections"][0]["questions"][2]["choices"][
            "selectedValue"
          ] = validLicenceForVehicleDropdownValue;
          riskAssessment["sections"][0]["questions"][3]["choices"][
            "selectedValue"
          ] = complyRoadTrafficDropdownValue;
        } else {
          riskAssessment["sections"][0]["questions"][0]["answer"] =
            riskAssessmentNameRef.current?.value;
          riskAssessment["sections"][0]["questions"][1]["answer"] =
          validLicenceForVehicleDropdownValue;

          riskAssessment["sections"][0]["questions"][2]["answer"] =
            complyRoadTrafficDropdownValue;

          riskAssessment["sections"][0]["questions"][1]["choices"][
            "selectedValue"
          ] = validLicenceForVehicleDropdownValue;

          riskAssessment["sections"][0]["questions"][2]["choices"][
            "selectedValue"
          ] = complyRoadTrafficDropdownValue;
        }

        await axiosInstance
          .post("riskassessment/saveriskassessment/", riskAssessment)
          .then((response) => {
            setSubmitting(false);

            if (response.data.success) {
              appInsights.trackEvent({
                name: "risk assessment Updated",
                properties: { Riskassessment: response.data.RiskAssessmentUId },
              });

              if (props.handleNext) props.handleNext(1, 0);

              dispatch(
                broadcastAnnouncement(
                  t("JourneyRiskAssessment.updateJourneyRiskAssessmentSuccess")
                )
              );
            }
          })
          .catch((err) => {
            console.log("error=", err.response);
            setSubmitting(false);
            if (err.response && err.response.data.messages) {
              const errorlist = err.response.data.messages.filter(
                (n) => n !== ""
              );
              dispatch(showErrorAlertMessage(errorlist));
              dispatch(broadcastAnnouncement(errorlist.join(",")));
              if (err.response.data.messages.indexOf("company name")) {
                if (riskAssessmentNameRef.current !== null)
                  riskAssessmentNameRef.current.focus();
              }
            } else {
              dispatch(
                showErrorAlertMessage(
                  t("JourneyRiskAssessment.unableToSaveRiskAssessment")
                )
              );
              dispatch(
                broadcastAnnouncement(
                  t("JourneyRiskAssessment.unableToSaveRiskAssessment")
                )
              );
            }
            appInsights.trackException({
              id: "Update riskassessment",
              exception: err,
              severityLevel: SeverityLevel.Error,
            });
          });
      }}>
      {({
        values,
        isValid,
        dirty,
        setFieldValue,
        setFieldTouched,

        touched,
        handleChange,
        errors,
        isSubmitting,
      }) => (
        <Grid container style={{ marginTop: "20px" }}>
          <Backdrop className={classes.backdrop} open={isSubmitting || loading}>
            <CircularProgress />
          </Backdrop>

          <Grid item xs={12}>
            <Box>
              {/* <h2>
                {" "}
                Risk Assessment for {props.journeyUId} {props.step}
              </h2>
              <h2> Rebook ? {props.origJourneyUId ? " Yes" : " No"}</h2> */}

              <Grid item xs={12} md={6}>
                <Typography ref={titleRef} tabIndex={-1} variant="h1">
                  {t("JourneyRiskAssessment.riskAssessment")}
                </Typography>
              </Grid>

              <Grid
                container
                direction="row"
                style={{ marginTop: "1em" }}
                className={classes.root}>
                <Form style={{ width: "100%" }}>
                  <Grid container>
                    <Grid
                      style={{
                        paddingBottom: 0,
                        paddingTop: 10,
                        marginBottom: "1em",
                      }}
                      item
                      xs={12}>
                      <div className="alignItemCentre">
                        <label style={{ fontWeight: "bold", fontSize: 14}}>
                          {values["sections"] == null
                            ? ""
                            : values["sections"][0]["questions"][0][
                                "descriptionTranslated"
                              ]}
                        </label>
                        <IconButton
                          id="infoRegistrionNumber"
                          size="small"
                          color="primary"
                          style={{ marginLeft: 0, paddingBottom: 8 }}
                          title={
                            values["sections"] == null
                              ? ""
                              : values["sections"][0]["questions"][0]["toolTip"]
                          }
                          // aria-label={
                          //   values["sections"] == null
                          //     ? ""
                          //     : values["sections"][0]["questions"][0][
                          //         "descriptionTranslated"
                          //       ]
                          // }
                          role="img">
                          <InfoIcon fontSize="small" />
                        </IconButton>
                      </div>

                      <Field
                        inputRef={riskAssessmentNameRef}
                        name="sections[0].questions[0].answer"
                        id="sections[0].questions[0].answer"
                        fullWidth
                        style={{ paddingBottom: 0, paddingTop: 0 }}                        
                        inputProps={{
                          "aria-required": true,
                          title: values["sections"] == null
                          ? ""
                          : values["sections"][0]["questions"][0][
                              "descriptionTranslated"
                            ],
                          "data-validation": "client-validation-error",
                          ...( errors !== undefined  && { "aria-describedby":"sections[0].questions[0].answer-helper-text" } ) 
                        }}
                        value={registrationNumberValue}
                        onChange={(e) => {
                          setregistrationNumberValue(e.target.value);
                          setFieldValue(
                            "sections[0].questions[0].answer",
                            e.target.value
                          );
                        }}
                        component={TextField}
                        variant="outlined"
                        error={
                          IsInitialLoad
                            ? false
                            : riskAssessmentNameRef.current?.value ===
                                undefined ||
                              riskAssessmentNameRef.current?.value === null ||
                              riskAssessmentNameRef.current?.value === ""
                            ? true
                            : false
                        }
                      />
                      {
                        IsInitialLoad
                          ? ""
                          : riskAssessmentNameRef.current?.value ===
                              undefined ||
                            riskAssessmentNameRef.current?.value === null ||
                            riskAssessmentNameRef.current?.value === ""
                          ?   <div style={{ marginLeft: 10 }}>
                          {"    "}
                          <span
                            style={{ marginLeft: 5 }}
                            id={"registrationNumberRequired"}
                            className="RedWarningFont">
                            {" "}
                            {t("JourneyRiskAssessment.registrationNumberRequired")}
                          </span>
                        </div>
                          : ""
                        }
                    </Grid>
                    {companyCountryCode === "GB" ||  companyCountryCode==="IE" ? (
                   <div style={{ width: "100%" }}>
                    <Grid
                      item
                      xs={12}
                      style={{
                        paddingBottom: 0,
                        paddingTop: 10,
                        marginBottom: "1em",
                      }}>
                      <div className="alignItemCentre">
                      <label style={{ fontWeight: "bold", fontSize: 14}}>
                          {values["sections"] == null
                            ? ""
                            : values["sections"][0]["questions"][1][
                                "descriptionTranslated"
                              ]}
                        </label>
                        <IconButton
                          id="infoInsuranceCheck"
                          size="small"
                          color="primary"
                          style={{ marginLeft: 0, paddingBottom: 8 }}
                          title={
                            values["sections"] == null
                              ? ""
                              : values["sections"][0]["questions"][1]["toolTip"]
                          }
                          aria-label={
                            values["sections"] == null
                              ? ""
                              : values["sections"][0]["questions"][1][
                                  "descriptionTranslated"
                                ]
                          }
                          role="img">
                          <InfoIcon fontSize="small" />
                        </IconButton>
                      </div>
                      <FormControl variant="outlined" fullWidth>
                        <Select
                          inputRef={insuranceRef}
                          native
                          name="insuranceCheck"
                          id="insuranceCheck"
                          value={insuranceDropdownValue}
                          onChange={(e) =>
                            handleInsuranceDropdownChange(
                              e,
                              values,
                              setFieldValue
                            )
                          }
                          fullWidth
                          inputProps={{
                            id: "insuranceCheck",
                            name: "insuranceCheck",
                            "aria-required": hiddenInsuranceError
                              ? false
                              : true,
                            "data-validation": "client-validation-error",
                            title: values["sections"] == null
                            ? ""
                            : values["sections"][0]["questions"][1][
                                "descriptionTranslated"
                              ],
                            // "aria-label": t(
                            //   "JourneyRiskAssessment.insuranceCheck"
                            // ),
                            "aria-describedby":"driverInsuredRequired",
                          }}
                          error={
                            IsInitialLoad
                              ? false
                              : insuranceRef.current?.value === undefined ||
                                insuranceRef.current?.value === null ||
                                insuranceRef.current?.value === ""
                              ? true
                              : false
                          }>
                          {values["sections"] == null
                            ? ""
                            : values["sections"][0]["questions"][1]["choices"][
                                "items"
                              ].map((opt: any) => (
                                <option key={opt.value} value={opt.value}>
                                  {opt.text}
                                </option>
                              ))}
                        </Select>
                      </FormControl>
                      <div
                        className={hiddenInsuranceError}
                        style={{ marginLeft: 10 }}>
                        {"    "}
                        <span
                          style={{ marginLeft: 5 }}
                          id={"driverInsuredRequired"}
                          className="RedWarningFont">
                          {"    "}
                          {t("Required")}
                        </span>
                      </div>
                    </Grid>
                   
                    {/* //oldCode */}
                    
                        <Grid
                          item
                          xs={12}
                          style={{
                            paddingBottom: 0,
                            paddingTop: 10,
                            marginBottom: "1em",
                          }}>
                          <div className="alignItemCentre">
                          <label style={{ fontWeight: "bold", fontSize: 14}}>
                              {values["sections"] == null
                                ? ""
                                : values["sections"][0]["questions"][2][
                                    "descriptionTranslated"
                                  ]}
                            </label>
                            <IconButton
                              id="validLicenceForVehicleIcon"
                              size="small"
                              color="primary"
                              style={{ marginLeft: 0, paddingBottom: 8 }}
                              title={
                                values["sections"] == null
                                  ? ""
                                  : values["sections"][0]["questions"][2]["toolTip"]
                              }
                              aria-label={
                                values["sections"] == null
                                  ? ""
                                  : values["sections"][0]["questions"][2][
                                      "toolTip"
                                    ]
                              }
                              role="img">
                              <InfoIcon fontSize="small" />
                            </IconButton>
                            {(values["sections"] !=null && values["sections"][0]["questions"][2].externalLink!=null) && (
                            <MuiLink
                              id="lnkLicenceCheck"
                              color="inherit"
                              variant="caption"
                              target="_blank"
                              rel="noopener noreferrer"
                              underline="always"
                              href={
                                values["sections"] == null
                                  ? ""
                                  : values["sections"][0]["questions"][2][
                                      "externalLink"
                                    ]
                              }>                          
                              {t("JourneyRiskAssessment.licenceCheck")}
                              <Box component="span" className="visuallyHidden">
                                {t("Footer.OpenNewWindow")}
                              </Box>
                            </MuiLink>
                            )
                            }
                          </div>
                          <FormControl variant="outlined" fullWidth>
                            <Select
                              native
                              inputRef={validLicenceRef}
                              name="validLicenceForVehicle"
                              id="validLicenceForVehicle"
                              value={validLicenceForVehicleDropdownValue}
                              onChange={(e) =>
                                handleValidLicenceForVehicleDropdownChange(
                                  e,
                                  values,
                                  setFieldValue
                                )
                              }
                              fullWidth
                              inputProps={{
                                id: "validLicenceForVehicle",
                                name: "validLicenceForVehicle",
                                title: values["sections"] == null
                                ? ""
                                : values["sections"][0]["questions"][2][
                                    "descriptionTranslated"
                                  ],
                                "aria-required":
                                  hiddenValidLicenceForVehicleError
                                    ? false
                                    : true,
                                "data-validation": "client-validation-error",
                                // "aria-label": t(
                                //   "JourneyRiskAssessment.validLicenceCheck"
                                // ),
                                "aria-describedby":"driverLicenseRequired",
                              }}
                              error={
                                IsInitialLoad
                                  ? false
                                  : validLicenceRef.current?.value ===
                                      undefined ||
                                    validLicenceRef.current?.value === null ||
                                    validLicenceRef.current?.value === ""
                                  ? true
                                  : false
                              }>
                              {values["sections"] == null
                                ? ""
                                : values["sections"][0]["questions"][2][
                                    "choices"
                                  ]["items"].map((opt: any) => (
                                    <option key={opt.value} value={opt.value}>
                                      {opt.text}
                                    </option>
                                  ))}
                            </Select>
                          </FormControl>
                          <div
                            className={hiddenValidLicenceForVehicleError}
                            style={{ marginLeft: 10 }}>
                            {"    "}
                            <span
                              style={{ marginLeft: 5 }}
                              id={"driverLicenseRequired"}
                              className="RedWarningFont">
                              {" "}
                              {t("Required")}
                            </span>
                          </div>
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          style={{
                            paddingBottom: 0,
                            paddingTop: 10,
                            marginBottom: "1em",
                          }}>
                          <div className="alignItemCentre">
                          <label style={{ fontWeight: "bold", fontSize: 14}}>
                              {values["sections"] == null
                                ? ""
                                : values["sections"][0]["questions"][3][
                                    "descriptionTranslated"
                                  ]}
                            </label>
                            <IconButton
                              id="complyRoadTrafficIcon"
                              size="small"
                              color="primary"
                              style={{ marginLeft: 0, paddingBottom: 8 }}
                              title={
                                values["sections"] == null
                                  ? ""
                                  : values["sections"][0]["questions"][3]["toolTip"]
                              }
                              aria-label={
                                values["sections"] == null
                                  ? ""
                                  : values["sections"][0]["questions"][3][
                                      "descriptionTranslated"
                                    ]
                              }
                              role="img">
                              <InfoIcon fontSize="small" />
                            </IconButton>
                          </div>
                          <FormControl variant="outlined" fullWidth>
                            <Select
                              native
                              inputRef={complyRoadTrafficRef}
                              name="complyRoadTraffic"
                              id="complyRoadTraffic"
                              value={complyRoadTrafficDropdownValue}
                              onChange={(e) =>
                                handleComplyRoadTrafficDropdownChange(
                                  e,
                                  values,
                                  setFieldValue
                                )
                              }
                              fullWidth
                              inputProps={{
                                id: "complyRoadTraffic",
                                name: "complyRoadTraffic",
                                title: values["sections"] == null
                                ? ""
                                : values["sections"][0]["questions"][3][
                                    "descriptionTranslated"
                                  ],
                                "data-validation": "client-validation-error",
                                "aria-required": hiddenComplyRoadTrafficError
                                  ? false
                                  : true,
                                // "aria-label": t(
                                //   "JourneyRiskAssessment.complyRoadTrafficCheck"
                                // ),
                                "aria-describedby":"trafficRegulationRequired",
                              }}
                              error={
                                IsInitialLoad
                                  ? false
                                  : complyRoadTrafficRef.current?.value ===
                                      undefined ||
                                    complyRoadTrafficRef.current?.value ===
                                      null ||
                                    complyRoadTrafficRef.current?.value === ""
                                  ? true
                                  : false
                              }>
                              {values["sections"] == null
                                ? ""
                                : values["sections"][0]["questions"][3][
                                    "choices"
                                  ]["items"].map((opt: any) => (
                                    <option key={opt.value} value={opt.value}>
                                      {opt.text}
                                    </option>
                                  ))}
                            </Select>
                          </FormControl>
                          <div
                            className={hiddenComplyRoadTrafficError}
                            style={{ marginLeft: 10 }}>
                            {"    "}
                            <span
                              style={{ marginLeft: 5 }}
                              id={"trafficRegulationRequired"}
                              className="RedWarningFont">
                              {" "}
                              {t("Required")}
                            </span>
                          </div>
                        </Grid>
                      </div>
                    ) : (
                      //Germany,French,Spanish
                      <div style={{ width: "100%" }}>

                        
  <Grid
                          item
                          xs={12}
                          style={{
                            paddingBottom: 0,
                            paddingTop: 10,
                            marginBottom: "1em",
                          }}>
                          <div className="alignItemCentre">
                          <label style={{ fontWeight: "bold", fontSize: 14}}>
                              {values["sections"] == null
                                ? ""
                                : values["sections"][0]["questions"][1][
                                    "descriptionTranslated"
                                  ]}
                            </label>
                            <IconButton
                              id="validLicenceForVehicleIcon"
                              size="small"
                              color="primary"
                              style={{ marginLeft: 0, paddingBottom: 8 }}
                              title={
                                values["sections"] == null
                                  ? ""
                                  : values["sections"][0]["questions"][1]["toolTip"]
                              }
                              aria-label={
                                values["sections"] == null
                                  ? ""
                                  : values["sections"][0]["questions"][1][
                                      "toolTip"
                                    ]
                              }
                              role="img">
                              <InfoIcon fontSize="small" />
                            </IconButton>                          
                          </div>
                          <FormControl variant="outlined" fullWidth>
                            <Select
                              native
                              inputRef={validLicenceRef}
                              name="validLicenceForVehicle"
                              id="validLicenceForVehicle"
                              value={validLicenceForVehicleDropdownValue}
                              onChange={(e) =>
                                handleValidLicenceForVehicleDropdownChange(
                                  e,
                                  values,
                                  setFieldValue
                                )
                              }
                              fullWidth
                              inputProps={{
                                id: "validLicenceForVehicle",
                                name: "validLicenceForVehicle",
                                title: values["sections"] == null
                                ? ""
                                : values["sections"][0]["questions"][1][
                                    "descriptionTranslated"
                                  ],
                                "aria-required":
                                  hiddenValidLicenceForVehicleError
                                    ? false
                                    : true,
                                "data-validation": "client-validation-error",
                                // "aria-label": t(
                                //   "JourneyRiskAssessment.validLicenceCheck"
                                // ),
                                "aria-describedby":"driverLicenseRequired",
                              }}
                              error={
                                IsInitialLoad
                                  ? false
                                  : validLicenceRef.current?.value ===
                                      undefined ||
                                    validLicenceRef.current?.value === null ||
                                    validLicenceRef.current?.value === ""
                                  ? true
                                  : false
                              }>
                              {values["sections"] == null
                                ? ""
                                : values["sections"][0]["questions"][1][
                                    "choices"
                                  ]["items"].map((opt: any) => (
                                    <option key={opt.value} value={opt.value}>
                                      {opt.text}
                                    </option>
                                  ))}
                            </Select>
                          </FormControl>
                          <div
                            className={hiddenValidLicenceForVehicleError}
                            style={{ marginLeft: 10 }}>
                            {"    "}
                            <span
                              style={{ marginLeft: 5 }}
                              id={"driverLicenseRequired"}
                              className="RedWarningFont">
                              {" "}
                              {t("Required")}
                            </span>
                          </div>
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          style={{
                            paddingBottom: 0,
                            paddingTop: 10,
                            marginBottom: "1em",
                          }}>
                          <div className="alignItemCentre">
                          <label style={{ fontWeight: "bold", fontSize: 14}}>
                              {values["sections"] == null
                                ? ""
                                : values["sections"][0]["questions"][2][
                                    "descriptionTranslated"
                                  ]}
                            </label>
                            <IconButton
                              id="complyRoadTrafficIcon"
                              size="small"
                              color="primary"
                              style={{ marginLeft: 0, paddingBottom: 8 }}
                              title={
                                values["sections"] == null
                                  ? ""
                                  : values["sections"][0]["questions"][2]["toolTip"]
                              }
                              // aria-label={
                              //   values["sections"] == null
                              //     ? ""
                              //     : values["sections"][0]["questions"][2][
                              //         "descriptionTranslated"
                              //       ]
                              // }
                              role="img">
                              <InfoIcon fontSize="small" />
                            </IconButton>
                          </div>
                          <FormControl variant="outlined" fullWidth>
                            <Select
                              native
                              inputRef={complyRoadTrafficRef}
                              name="complyRoadTraffic"
                              id="complyRoadTraffic"
                              value={complyRoadTrafficDropdownValue}
                              onChange={(e) =>
                                handleComplyRoadTrafficDropdownChange(
                                  e,
                                  values,
                                  setFieldValue
                                )
                              }
                              fullWidth
                              inputProps={{
                                id: "complyRoadTraffic",
                                name: "complyRoadTraffic",
                                title: values["sections"] == null
                                ? ""
                                : values["sections"][0]["questions"][2][
                                    "descriptionTranslated"
                                  ],
                                "data-validation": "client-validation-error",
                                "aria-required": hiddenComplyRoadTrafficError
                                  ? false
                                  : true,
                                // "aria-label": t(
                                //   "JourneyRiskAssessment.complyRoadTrafficCheck"
                                // ),
                              }}
                              error={
                                IsInitialLoad
                                  ? false
                                  : complyRoadTrafficRef.current?.value ===
                                      undefined ||
                                    complyRoadTrafficRef.current?.value ===
                                      null ||
                                    complyRoadTrafficRef.current?.value === ""
                                  ? true
                                  : false
                              }>
                              {values["sections"] == null
                                ? ""
                                : values["sections"][0]["questions"][2][
                                    "choices"
                                  ]["items"].map((opt: any) => (
                                    <option key={opt.value} value={opt.value}>
                                      {opt.text}
                                    </option>
                                  ))}
                            </Select>
                          </FormControl>
                          <div
                            className={hiddenComplyRoadTrafficError}
                            style={{ marginLeft: 10 }}>
                            {"    "}
                            <span
                              style={{ marginLeft: 5 }}
                              id={"trafficRegulationRequired"}
                              className="RedWarningFont">
                              {" "}
                              {t("Required")}
                            </span>
                          </div>
                        </Grid>
                      </div>
                    )}

                    <Grid container>
                      <Typography variant={"body2"}>
                        <br></br>
                        {t(
                          "JourneyRiskAssessment.iConfirmRiskAssessmentHasntChanged"
                        )}
                      </Typography>
                    </Grid>
                    <Grid container justifyContent="flex-end">
                      <Button
                        id="btnUpdate"
                        color="primary"
                        variant="contained"
                        disabled={
                          rebookInitialLoad
                            ? false
                            : companyCountryCode === "GB" || companyCountryCode==="IE" 
                            ? !(
                                riskAssessmentNameRef.current?.value !== "" &&
                                riskAssessmentNameRef.current?.value !==
                                  undefined &&
                                insuranceDropdownSelected &&
                                validLicenceForVehicleDropdownSelected &&
                                complyRoadTrafficDropdownSelected
                              )
                            : !(
                                riskAssessmentNameRef.current?.value !== "" &&
                                riskAssessmentNameRef.current?.value !==
                                  undefined &&
                                  validLicenceForVehicleDropdownSelected &&
                                complyRoadTrafficDropdownSelected
                              )
                        }
                        type="submit"
                        style={{ marginTop: "0.5em", textTransform: "none" }}>
                        {t("Submit")}
                      </Button>
                    </Grid>
                  </Grid>
                  <FocusError />
                </Form>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      )}
    </Formik>
  );
};

export default RiskAssessmentForm;
