import { Dispatch } from "redux";
import {
  COMPANYINFO_FAIL,
  COMPANYINFO_LOADING,
  COMPANYINFO_SUCCESS,
  CompanyInfoDispatchTypes,
} from "./companyInfoActionTypes";

import axiosInstance from "helpers/axiosInstance";
import { authService } from "services/authService";

export const GetCompanyInfo = () => async (
  dispatch: Dispatch<CompanyInfoDispatchTypes>
) => {
  try {
    dispatch({
      type: COMPANYINFO_LOADING,
    });

    const result = await axiosInstance.get('home/companyInfo');
 
    dispatch({
      type: COMPANYINFO_SUCCESS,
      payload: result.data,
    });

  // set TakoApiEnv
  authService.setTakoApiEnv(result.data.takoApiEnv)

  } catch (e) {
    console.log("error occured : ", e);
    dispatch({
      type: COMPANYINFO_FAIL,
    });
  }
};
