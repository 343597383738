import React from "react";

import {
  Backdrop,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  TextField,
  Checkbox,
  FormControl,
  OutlinedInput,
} from "@mui/material";
import { JourneyType } from "views/reservation/ReservationViewType";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import { Theme } from "@mui/material/styles";
import makeStyles from '@mui/styles/makeStyles';
import { CardHeader } from "@mui/material";
import { Formik, Form, Field } from "formik";
import axiosInstance from "helpers/axiosInstance";
import * as Yup from "yup";

import {
  showSuccessAlertMessage,
  showErrorAlertMessage,
  showInfoAlertMessage,
} from "components/store/actions/shared/alertActions";
import { broadcastAnnouncement } from "components/store/actions/shared/announcementActions";
import { useDispatch } from "_testHelpers/react-redux-hooks";
import {
  CompanyTravelPolicies,
  CompanyTravelCard,
  CompanyTravelPolicyProduct,
} from "../companies/travelpolicyType";
import FocusError from "controls/formik/FocusOnError";
import moment from "moment";
import { appInsights } from "helpers/appInsights";
import { SeverityLevel } from "@microsoft/applicationinsights-common";
import EtdDatePickerV2 from "controls/formik/EtdDatePickerV2";
import { getIntlDateFormatForLocale } from "helpers/localization";
import { authService } from "services/authService";
import Select from "@mui/material/Select";

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}


export default function TravelPolicy(props) {
  const { companyUid } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [state, setState] = React.useState({
    activate: true,
  });
  const locale = authService.getUserLocale();
  const localeDateTimeFormat = getIntlDateFormatForLocale(locale);

  const handleActivateChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
    if (event.target.checked) {
      // if it's checked, don't hide
      setSelectedActivate(false);
    } else {
      setSelectedActivate(true);
    }
    // if it's changed and checked, then set selected date to today's date
  };

  const handleMidpointChange = (event) => {};

  // The first commit of Material-UI
 
  const handleDateChange = (date: Date | null) => {
    if (date == null || date.toString().includes("Invalid")) {
      setinvalidStart(true);
    } else if (date !== null) {
      var dt = new Date(date);
      dt.setHours(0);
      dt.setMinutes(0);
      dt.setSeconds(0);
      dt.setMilliseconds(0);

      var today = new Date();
      today.setHours(0);
      today.setMinutes(0);
      today.setSeconds(0);
      today.setMilliseconds(0);

      var isAfter = moment(dt).isSameOrAfter(moment(today));

      if (isAfter === true) {
        //selected date is greater than or equal to today
        setinvalidStart(false);
      } else {
        setinvalidStart(true);
      }

    } else {
      setinvalidStart(false);
    }
  };

  const [selectActivate, setSelectedActivate] = React.useState<boolean>();
  const [selectMidpoint, setSelectMidpoint] = React.useState<boolean>();
  const [selectDR, setSelectDR] = React.useState<boolean>();
  const [selectCC, setSelectCC] = React.useState<boolean>();
  const [selectMR, setSelectMR] = React.useState<boolean>();
  const [selectDBCC, setDBSelectCC] = React.useState<boolean>();
  const [selectDBMR, setDBSelectMR] = React.useState<boolean>();
  const titleRef = React.useRef<HTMLDivElement>(null);


  const useStyles = makeStyles({
    cards: {
      minWidth: 275,
      marginBottom: 10,
    },
    cardContainter: {
      boxShadow: "none",
    },
    backdrop: {
      zIndex: 99999,
      color: "#fff",
    },
    outlinedButton: {
      border: "1px solid rgba(0, 102, 57, 0.95);",
    },
    txtField: {
      size: "small",
    },
    bullet: {
      display: "inline-block",
      margin: "0 2px",
      transform: "scale(0.8)",
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    table: {
      minWidth: 650,
      borderInline: "0px",
    },
    tableCell: {
      borderBottom: "0px",
      fontWeight: "bold",
    },
    tableheader: { fontWeight: "bold" },
    cardTitle: {
      fontWeight: "bold",
      // backgroundColor: '#dff0d8',
    },
    errorWarning: {
      color: "#DF0000",
      fontSize: "0.75rem",
      marginLeft: "15px",
    },
  });

  const classes = useStyles();
  const [invalidStart, setinvalidStart] = React.useState(false);
  const travelPolicyInitialValue = {
    title: "",
    companyUId: "",
    existingCompanyName: "",
    products: [
      {
        uId: "",
        productId: 0,
        productUId: "",
        companyUId: "",
        name: "",
        available: false,
        offerAlways: false,
        carClubCompanyId: "",
        companyEnrollmentLink: "",
        miles: 1,
        maxDistance: 1,
        minMiles: 0,
        hours: 0,
        preferredOrderNumber: 0,
        availableProductOrderList: {
          selectedText: "",
          selectedValue: "",
          items: [],
        },
        averageHourlyRate: 0,
        mileageReimbursementRate: 0,
        preferredCarClassUId: "",
        maxDays: 0,
        availableCarClasses: { selectedText: "", selectedValue: "", items: [] },
        averageDailyRate: 0,
        enableLoyalty: false,
        displayMidpoints: false,
        takoApiEnvs: { selectedText: "", selectedValue: "", items: [] },
        takoApiEnv:"",
 
      },
    ] as CompanyTravelPolicyProduct[],
    activate: false,
    activateDate: new Date(),
    allowAdminToAccessAllJourneys: false,
  } as CompanyTravelPolicies;

  const travelPolicyCardsInitialValue = {
    activate: false,
    activateDate: new Date(),
    carClubCompanyId: "",
    carClubCompanyEnrollmentLink: "",
    carClubMinMiles: 0,
    carClubMaxDistance: 1, //searchDistance
    carClubmiles: 1, //maxMiles
    carClubHours: 0,
    carClubAverageHourlyRate: 0,
    carClubAvailable: false,
    carClubOfferAlways: false,
    carClubTakoApiEnvs:{ selectedText: "", selectedValue: "", items: [] },
    dailyRentalAvailable: false,
    dailyRentalOfferAlways: false,
    dailyRentalEnableLoyalty: false,
    dailyRentalDisplayMidpoints: false,
    dailyRentalPreferredCarClassUId: "",
    dailyRentalAverageDailyRate: 0,
    mReimbursementClubAvailable: false,
    mReimbursementClubOfferAlways: false,
    mReimbursementMileageReimbursementRate: 0,
    mReimbursementMiles: 1, //maxMiles
  } as CompanyTravelCard;

  const [travelPolicyCards, setTravelPolicyCards] =
    React.useState<CompanyTravelCard>(travelPolicyCardsInitialValue);

  const [travelPolicies, setTravelPolicies] =
    React.useState<CompanyTravelPolicies>(travelPolicyInitialValue);

  
  const [loading, setLoading] = React.useState(false);

  const getCompanyTravelPolicy = async (companyUID) => {
    setLoading(true);

    await axiosInstance
      .get(`company/getTravelPolicy/${companyUID}`)
      .then((response) => { 
        console.log("getTravelPolicy=", response.data);
        appInsights.trackEvent({
          name: "getCompanyTravelPolicy",
          properties: { companyUID: companyUID },
        });
        setTravelPolicies(response.data);
        var tempData = response.data;
        var cardItems = { ...travelPolicyCards };

        for (let index = 0; index < tempData.products.length; index++) {
          if (
            tempData.products[index].productUId.toUpperCase().toString() ==
            JourneyType.carClub
          ) {
            cardItems.carClubAvailable = tempData.products[index].available;
            cardItems.carClubCompanyEnrollmentLink =
              tempData.products[index].companyEnrollmentLink || "";
            cardItems.carClubCompanyId =
              tempData.products[index].carClubCompanyId || "";
            cardItems.carClubOfferAlways = tempData.products[index].offerAlways;
            cardItems.carClubAverageHourlyRate =
              tempData.products[index].averageHourlyRate * 1;
            cardItems.carClubHours = tempData.products[index].hours * 1;
            cardItems.carClubMaxDistance =
              tempData.products[index].maxDistance == null
                ? 1
                : tempData.products[index].maxDistance * 1;
            cardItems.carClubMinMiles = tempData.products[index].minMiles * 1;
            cardItems.carClubmiles =
              tempData.products[index].miles == null
                ? 1
                : tempData.products[index].miles * 1;
                cardItems.carClubTakoApiEnvs =  tempData.products[index].takoApiEnvs;

          } else if (
            tempData.products[index].productUId.toUpperCase().toString() ==
            JourneyType.dailyRental
          ) {
            cardItems.dailyRentalAvailable = tempData.products[index].available;
            cardItems.dailyRentalOfferAlways =
              tempData.products[index].offerAlways;
            cardItems.dailyRentalEnableLoyalty =
              tempData.products[index].enableLoyalty;
            cardItems.dailyRentalDisplayMidpoints =
              tempData.products[index].displayMidpoints;
            cardItems.dailyRentalPreferredCarClassUId =
              tempData.products[index].preferredCarClassUId || "";
            cardItems.dailyRentalAverageDailyRate =
              tempData.products[index].averageDailyRate == null
                ? 0.0
                : tempData.products[index].averageDailyRate * 1;
          } else if (
            tempData.products[index].productUId.toUpperCase().toString() ==
            JourneyType.mileageReimbursement
          ) {
            cardItems.mReimbursementClubAvailable =
              tempData.products[index].available;
            cardItems.mReimbursementClubOfferAlways =
              tempData.products[index].offerAlways;
            cardItems.mReimbursementMileageReimbursementRate =
              tempData.products[index].mileageReimbursementRate == null
                ? 0.0
                : tempData.products[index].mileageReimbursementRate * 1;
            cardItems.mReimbursementMiles =
              tempData.products[index].miles == null
                ? 1
                : tempData.products[index].miles * 1;
          }
          cardItems.activate = tempData.activate;
          cardItems.activateDate = tempData.activateDate;
        }
        setTravelPolicyCards(cardItems);
        setSelectMidpoint(cardItems.dailyRentalDisplayMidpoints);
        setSelectDR(cardItems.dailyRentalAvailable);
        setSelectMR(cardItems.mReimbursementClubAvailable);
        setSelectCC(cardItems.carClubAvailable);
        setDBSelectMR(cardItems.mReimbursementClubAvailable);
        setDBSelectCC(cardItems.carClubAvailable);

        //If not activated, hide calendar. otherwise show.
        if (tempData.activate) {
          setSelectedActivate(false); //hidden == false
        } else {
          setSelectedActivate(true);
        }

        setLoading(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
        appInsights.trackException({
          id: "getCompanyTravelPolicy",
          exception: error,
          severityLevel: SeverityLevel.Error,
        });
      });
  };
  function validateSelectField(value) {
    console.log("country value=", value["selectedValue"]);
    if (
      typeof value["selectedValue"] === "undefined" ||
      value["selectedValue"] === "" ||
      value["selectedValue"] === null
    ) return false;
    else 
    return true;

    // if (
    //   typeof value["selectedValue"] === "undefined" ||
    //   value["selectedValue"] === "-"
    // )
    //   return false;
    // else return true;
  }

  const travelPolicyValidationSchema = Yup.object().shape({
    carClubAvailable: Yup.boolean(),
    dailyRentalAvailable: Yup.boolean(),
    mReimbursementClubAvailable: Yup.boolean(),
    activate: Yup.boolean(),

    activateDate: Yup.date()
      .nullable()
      .when("activate", {
        is: true,
        then: Yup.date().required(t("please enter a date")),
        //.min(dtToday)        //must be greater or equal to today
      }),

    carClubCompanyId: Yup.string()
      .nullable()
      .when("carClubAvailable", {
        is: true,
        then: Yup.string()
          .required(t("TravelPolicy.ErrorMessage.carClubIDRequired"))
          .typeError(t("TravelPolicy.ErrorMessage.carClubIDRequired")),
      }),

    carClubMinMiles: Yup.number()

      .integer(t("TravelPolicy.ErrorMessage.integerOnly"))
      .typeError(t("TravelPolicy.ErrorMessage.numberOnly"))
      .required(t("TravelPolicy.ErrorMessage.carClubMinMilesRequired"))
      .moreThan(-1, t("TravelPolicy.ErrorMessage.moreThanZero")),
    carClubCompanyEnrollmentLink: Yup.string()
      .nullable()
      .when("carClubAvailable", {
        is: true,
        then: Yup.string()
          .required(t("TravelPolicy.ErrorMessage.carClubCompanyEnrollmentLink"))
          .typeError(
            t("TravelPolicy.ErrorMessage.carClubCompanyEnrollmentLink")
          ),
      }),

    carClubmiles: Yup.number() //maxmiles
      .integer(t("TravelPolicy.ErrorMessage.integerOnly"))
      .positive(t("TravelPolicy.ErrorMessage.positiveNumberOnly"))
      .typeError(t("TravelPolicy.ErrorMessage.numberOnly"))
      .moreThan(
        Yup.ref("carClubMinMiles"),
        t("TravelPolicy.ErrorMessage.carClubMaxLargerThanMinKilos")
      )
      .required(t("TravelPolicy.ErrorMessage.carClubMilesRequired")),
    //.moreThan(0, t("TravelPolicy.ErrorMessage.moreThanZero"))

    carClubMaxDistance: Yup.number()

      .positive(t("TravelPolicy.ErrorMessage.positiveNumberOnly"))
      .integer(t("TravelPolicy.ErrorMessage.integerOnly"))
      .typeError(t("TravelPolicy.ErrorMessage.numberOnly"))
      .required(t("TravelPolicy.ErrorMessage.carClubMaxDistanceRequired")),

    carClubHours: Yup.number()
      .typeError(t("TravelPolicy.ErrorMessage.numberOnly"))
      .integer(t("TravelPolicy.ErrorMessage.integerOnly"))
      .when("carClubAvailable", {
        is: true,
        then: Yup.number()
          .typeError(t("TravelPolicy.ErrorMessage.integerOnly"))
          //.positive(t("TravelPolicy.ErrorMessage.positiveNumberOnly"))
          .required(t("TravelPolicy.ErrorMessage.carClubHoursRequired")),
      }),

    carClubAverageHourlyRate: Yup.number()
      .typeError(t("TravelPolicy.ErrorMessage.numberOnly"))
      .when("carClubAvailable", {
        is: true,
        then: Yup.number()
          .typeError(t("TravelPolicy.ErrorMessage.numberOnly"))
          //.positive(t("TravelPolicy.ErrorMessage.positiveNumberOnly"))
          .moreThan(-0.000001, t("TravelPolicy.ErrorMessage.moreThanZero"))
          .required(
            t("TravelPolicy.ErrorMessage.carClubAverageHourlyRateRequired")
          ),
      }),

      carClubTakoApiEnvs: Yup.object().
      when("carClubAvailable", {
        is: true,
        then: Yup.object()
        .test("len",t("Car Club / CarShare Environment is required"),(value) => 
        validateSelectField(value))
        .required(),
      }),

    dailyRentalAverageDailyRate: Yup.number()
      .typeError(t("TravelPolicy.ErrorMessage.numberOnly"))
      .when("dailyRentalAvailable", {
        is: true,
        then: Yup.number()
          .typeError(t("TravelPolicy.ErrorMessage.numberOnly"))
          //.positive(t("TravelPolicy.ErrorMessage.positiveNumberOnly"))
          .moreThan(-0.000001, t("TravelPolicy.ErrorMessage.moreThanZero"))
          .required(t("TravelPolicy.ErrorMessage.dailyRentalAverageDailyRate")),
      }),

    mReimbursementMileageReimbursementRate: Yup.number()
      .typeError(t("TravelPolicy.ErrorMessage.numberOnly"))
      .when("mReimbursementClubAvailable", {
        is: true,
        then: Yup.number()
          .typeError(t("TravelPolicy.ErrorMessage.numberOnly"))
          //.positive(t("TravelPolicy.ErrorMessage.positiveNumberOnly"))
          .moreThan(-0.000001, t("TravelPolicy.ErrorMessage.moreThanZero"))
          .required(t("TravelPolicy.ErrorMessage.mileageReimbursementRate")),
      }),

    mReimbursementMiles: Yup.number()

      .typeError(t("TravelPolicy.ErrorMessage.numberOnly"))
      .positive(t("TravelPolicy.ErrorMessage.positiveNumberOnly"))
      .integer(t("TravelPolicy.ErrorMessage.integerOnly"))
      .required(t("TravelPolicy.ErrorMessage.reimbursementMiles")),
  });

  React.useEffect(() => {
    getCompanyTravelPolicy(companyUid);
  }, []);

  appInsights.trackEvent({
    name: "Travel Policy",
    properties: { companyUid: companyUid },
  });

  return (
    <Formik
      initialValues={travelPolicyCards}
      validateOnChange={true}
      validateOnBlur={false}
      enableReinitialize={true}
      validationSchema={travelPolicyValidationSchema}
      onSubmit={async (values, { setFieldTouched, setSubmitting }) => {
        setSubmitting(false);
        setLoading(true);
        var travelPolicyData = { ...travelPolicies };
        var tempData = values;

        for (let index = 0; index < travelPolicyData.products.length; index++) {
          if (
            travelPolicyData.products[index].productUId
              .toUpperCase()
              .toString() == JourneyType.carClub
          ) {
            travelPolicyData.products[index].available =
              tempData.carClubAvailable;
            travelPolicyData.products[index].companyEnrollmentLink =
              tempData.carClubCompanyEnrollmentLink;
            travelPolicyData.products[index].carClubCompanyId =
              tempData.carClubCompanyId;
            travelPolicyData.products[index].offerAlways =
              tempData.carClubOfferAlways;
            travelPolicyData.products[index].averageHourlyRate =
              tempData.carClubAverageHourlyRate;
            if (tempData.carClubAverageHourlyRate != null) {
              var num = Number(tempData.carClubAverageHourlyRate);
              var sNum = num.toFixed(2);
              travelPolicyData.products[index].averageDailyRate = +sNum;
              values.carClubAverageHourlyRate =
                travelPolicyData.products[index].averageDailyRate;
            }
            travelPolicyData.products[index].hours = tempData.carClubHours;
            travelPolicyData.products[index].maxDistance =
              tempData.carClubMaxDistance;
            travelPolicyData.products[index].minMiles =
              tempData.carClubMinMiles;
            travelPolicyData.products[index].miles = tempData.carClubmiles;
            travelPolicyData.products[index].takoApiEnvs = tempData.carClubTakoApiEnvs
            travelPolicyData.products[index].takoApiEnv = tempData.carClubTakoApiEnvs.selectedValue
          } else if (
            travelPolicyData.products[index].productUId
              .toUpperCase()
              .toString() == JourneyType.dailyRental
          ) {
            travelPolicyData.products[index].available =
              tempData.dailyRentalAvailable;
            travelPolicyData.products[index].offerAlways =
              tempData.dailyRentalOfferAlways;
            travelPolicyData.products[index].enableLoyalty =
              tempData.dailyRentalEnableLoyalty;
            travelPolicyData.products[index].displayMidpoints =
              tempData.dailyRentalDisplayMidpoints;
            travelPolicyData.products[index].preferredCarClassUId =
              tempData.dailyRentalPreferredCarClassUId;
            travelPolicyData.products[index].averageDailyRate =
              tempData.dailyRentalAverageDailyRate;
            if (tempData.dailyRentalAverageDailyRate != null) {
              var num = Number(tempData.dailyRentalAverageDailyRate);
              var sNum = num.toFixed(2);
              travelPolicyData.products[index].averageDailyRate = +sNum;
              values.dailyRentalAverageDailyRate =
                travelPolicyData.products[index].averageDailyRate;
            }
          } else if (
            travelPolicyData.products[index].productUId
              .toUpperCase()
              .toString() == JourneyType.mileageReimbursement
          ) {
            travelPolicyData.products[index].available =
              tempData.mReimbursementClubAvailable;
            travelPolicyData.products[index].offerAlways =
              tempData.mReimbursementClubOfferAlways;
            travelPolicyData.products[index].mileageReimbursementRate =
              tempData.mReimbursementMileageReimbursementRate;
            if (tempData.mReimbursementMileageReimbursementRate != null) {
              var num = Number(tempData.mReimbursementMileageReimbursementRate);
              var sNum = num.toFixed(2);
              travelPolicyData.products[index].mileageReimbursementRate = +sNum;
              values.mReimbursementMileageReimbursementRate =
                travelPolicyData.products[index].mileageReimbursementRate;
            }
            travelPolicyData.products[index].miles =
              tempData.mReimbursementMiles;
          }
        }
        travelPolicyData.activate = tempData.activate;
        travelPolicyData.activateDate = tempData.activateDate;

        // corrects summer time being passed to server
        if (tempData.activateDate != null) {
          var dtTempData = new Date(tempData.activateDate);

          var day = dtTempData.getDate();
          var mth = dtTempData.getMonth();
          var yr = dtTempData.getFullYear();
          var dateStr = yr + "-" + mth + "-" + day;

          var dt = new Date();
          dt.setUTCDate(day);
          dt.setUTCMonth(mth);
          dt.setUTCFullYear(yr);
          dt.setUTCHours(0, 0, 0, 0);
          travelPolicyData.activateDate = dt;
        }

        setTravelPolicies(travelPolicyData);

        console.log(
          "postCompanyTravelPolicy=",
          travelPolicies
        );
        await axiosInstance
          .post("/company/updateTravelPolicy", travelPolicyData)
          .then((res) => {
            setSubmitting(false);
            setLoading(false);
            getCompanyTravelPolicy(companyUid)
            //Midpoint selection scenarios(on change)
            // If DR selected, MR or CC selected, then midpoint selected
            if (selectDR) {
              if (selectMR || selectCC) {
                // if DR and one more product selected, set midpoint to true (force)
                values.dailyRentalDisplayMidpoints = true;
              } else {
                // if DR selected and no other product selected, leave it as it is
                // if DR selected and other products changed to unlesected, then set midpoint to false (force)
                if (selectDBMR || selectDBCC) {
                  values.dailyRentalDisplayMidpoints = false;
                }
              }
            } // if DR unselected, set midpoint to false (force)
            else {
              values.dailyRentalDisplayMidpoints = false;
            }

            if (res.data.warnings !== "") {
              dispatch(
                showInfoAlertMessage(
                  "Travel policy successfully Updated! " + res.data.warnings
                )
              ); // midpoint warning if any
              dispatch(
                broadcastAnnouncement(
                  "Travel policy successfully Updated!" + res.data.warnings
                )
              );
            } else {
              dispatch(
                showSuccessAlertMessage("Travel policy successfully Updated!")
              ); // page redirect or do nothing here
              dispatch(
                broadcastAnnouncement("Travel policy successfully Updated!")
              );
            }

            window.scrollTo(0, 0);
            if (titleRef.current !== null) titleRef.current.focus();

            appInsights.trackEvent({
              name: "setTravelPolicies",
              properties: { travelPolicyData: travelPolicyData },
            });
          })
          .catch((error) => {
            setSubmitting(false);
            setLoading(false);
            console.log("error =", error.response.data.messages);
            appInsights.trackException({
              id: "setTravelPolicies",
              exception: error.response.data.messages,
              severityLevel: SeverityLevel.Error,
            });
            if (error.response) {
              if (error.response.data.messages == null) {
                dispatch(showErrorAlertMessage(error.response.statusText));
                dispatch(broadcastAnnouncement(error.response.statusText));
              } else {
                dispatch(showErrorAlertMessage(error.response.data.messages));
                dispatch(broadcastAnnouncement(error.response.data.messages));
              }
            } else if (error.request) {
              console.log("error.request", error.request);
            } else if (error.message) {
              console.log("error.message", error.message);
            }
            window.scrollTo(0, 0);
            if (titleRef.current !== null) titleRef.current.focus();
          });
      }}>
      {({
        values,
        isValid,
        dirty,
        setFieldValue,
        setFieldTouched,
        touched,
        handleChange,
        errors,
        isSubmitting,
      }) => (
        <Grid item xs={12} md={12}>
          <Backdrop className={classes.backdrop} open={isSubmitting || loading}>
            <CircularProgress />
          </Backdrop>

          <Form style={{ width: "100%" }}>
            <Grid container component={Card} className={classes.cardContainter}>
              <Grid item xs={12} md={12}>
                <div ref={titleRef} tabIndex={-1} className="visuallyHidden">
                  <Typography variant="h1" id="travelPolicyHeader">
                    {t("TravelPolicy.CompanyTravelPolicyRules")}
                  </Typography>
                </div>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={12} md={12}>
                  <label>
                    <Field
                      type="checkbox"
                      name="activate"
                      component={Checkbox}
                      checked={values.activate}
                      id="activateCompanyTravelPoliciesCheckBox"
                      onChange={(e) => {
                        handleChange(e);
                        handleActivateChange(e);
                        setFieldValue("activate", e.target.checked);
                        setFieldTouched("activate");
                      }}
                    />
                    {t("TravelPolicy.Activate")}
                  </label>
                </Grid>
                <Grid item xs={12} md={12} hidden={selectActivate}>
                  <Typography variant="h2" style={{ marginLeft: 10 }}>
                    {t("Start")}
                  </Typography>                  
                </Grid>
                <Grid item xs={12} md={4}  style={{ margin: 10, marginTop: 0 }} hidden={selectActivate}>
                  <Field
                    component={EtdDatePickerV2}
                    label="Activate Date"
                    name="activateDate"
                    id="activateCompanyTravelPoliciesDateTextBox"
                    errorMessageId="activateDateErrorMsg"
                    isPastDateAllowed={true}
                    localeDateTimeFormat={localeDateTimeFormat}
                    value={values.activateDate ?? null}
                    handleChange={(e) => {
                      let newValue = e;
                      const format = localeDateTimeFormat.toUpperCase();
                      let parsedDate = moment(newValue, format, true);
                      if (parsedDate.isValid()) {
                        newValue = moment(parsedDate).format("YYYY-MM-DD");
                      } else {
                        newValue = null;
                      }
                      setFieldTouched("activateDate", true);
                      handleDateChange(newValue === null ? null : moment(e).toDate());                      
                      setFieldValue(
                        "activateDate",
                        newValue === null ? "" : moment(e).toDate()
                      );
                    }}
                    handleBlur={(e) => {
                      setFieldTouched("activateDate", false);
                    }}
                    isError={
                      touched.activateDate && errors && errors.activateDate
                        ? true
                        : false
                    }
                    aria-describedby="activateDateErrorMsg"
                  />
                  {touched.activateDate && errors && errors.activateDate ? (
                    <span
                      className={classes.errorWarning}
                      id="activateDateErrorMsg"
                    >
                      {t(errors.activateDate)}
                    </span>
                  ) : (
                    <span id="activateDateErrorMsg"></span>
                  )}
                </Grid>
              </Grid>

              <Grid container>
                <Card variant="outlined" className={classes.cards}>
                  <CardHeader
                    className={classes.cardTitle}
                    title={t("TravelPolicy.CarClub.CarClub")}
                  />
                  <Divider
                    style={{ backgroundColor: "black", fontWeight: "normal" }}
                  />
                  <CardContent>
                    <Grid container spacing={1} >
                      <Grid item xs={12} md={12}>
                        <label>
                          <Field
                            type="checkbox"
                            name="carClubAvailable"
                            component={Checkbox}
                            checked={values.carClubAvailable}
                            id="carShareTravelPolicyAvailableCheckBox"
                            onChange={(e) => {
                              handleChange(e);
                              setSelectCC(e.target.checked);
                              setFieldValue("carClubAvailable", e.target.checked);
                              setFieldTouched("carClubAvailable");
                            }}
                          />

                          {t("TravelPolicy.CarClub.AvailableProduct")}
                        </label>
                      </Grid>
                      <Grid item xs={12}>
                        <label>
                          <Field
                            type="checkbox"
                            name="carClubOfferAlways"
                            component={Checkbox}
                            checked={values.carClubOfferAlways}
                            id="carShareTravelPolicyOfferAlwaysCheckBox"
                            onChange={(e) => {
                              setFieldValue("carClubOfferAlways", e.target.checked);
                              setFieldTouched("carClubOfferAlways");
                            }}

                          />
                          {t("TravelPolicy.CarClub.OfferAlways")}
                        </label>
                      </Grid>
                      <Grid item xs={12}>
                        <TableContainer>
                          <Table className={classes.table} size="small">
                            <TableBody>
                            <TableRow>
                                <TableCell className={classes.tableCell}>
                                  <Typography className={classes.tableheader}>
                                    {t("TravelPolicy.CarClub.TakoApiEnvField")}
                                  </Typography>
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                      <FormControl id="TakoApiEnvsList" variant="outlined" fullWidth size="small">
                      <Select
                        native
                        value={values.carClubTakoApiEnvs?.selectedValue}
                        onChange={(opt) => {
                          let text = values.carClubTakoApiEnvs.items?.find(
                            (x) => x.value === opt.target.value
                          )?.text;
                          setFieldValue("carClubTakoApiEnvs.selectedText", text);
                          setFieldValue("carClubTakoApiEnvs.selectedValue",opt.target.value);
                          setFieldTouched("carClubTakoApiEnvs");
                        }}
                        fullWidth
                        input={<OutlinedInput sx={{fontSize: '0.8rem'}} />}
                        inputProps={{
                          id: "carClubTakoApiEnvs.selectedValue",
                          name: "carClubTakoApiEnvs",
                          title: "select a country",
                          "aria-label":t("TravelPolicy.CarClub.TakoApiEnvField"),
                          "data-validation": "client-validation-error",
                        }}
                        name="carClubTakoApiEnvs"
                        id="carClubTakoApiEnvs"
                         error={errors.carClubTakoApiEnvs !== undefined ? true : false}
                      >
                        {values.carClubTakoApiEnvs.items?.map((opt: any) => (
                          <option key={opt.value} value={opt.value}>
                            {opt.text}
                          </option>
                        ))}
                      </Select>
                      {errors.carClubTakoApiEnvs && touched.carClubTakoApiEnvs ? (
                        <span
                          id="carClubTakoApiEnvs-helper-select"
                          className={classes.errorWarning}
                        >
                          {errors["carClubTakoApiEnvs"]?.toString() || ""}
                        </span>
                      ) : (
                        ""
                      )}
                    </FormControl>

                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell className={classes.tableCell}>
                                  <Typography className={classes.tableheader}>
                                    {t("TravelPolicy.CarClub.CompanyID")}
                                  </Typography>
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  <Field
                                    name="carClubCompanyId"
                                    id="travelPolicy-carClub-companyID"
                                    variant="outlined"
                                    error={
                                      errors.carClubCompanyId !== undefined
                                        ? true
                                        : false
                                    }
                                    fullWidth
                                    style={{ paddingBottom: 0, paddingTop: 0 }}
                                    inputProps={{
                                      "aria-label": t(
                                        "TravelPolicy.CarClub.CompanyID"
                                      ),
                                      "aria-required": true,
                                    }}
                                    value={values.carClubCompanyId}
                                    onChange= {(e) => {
                                      setFieldValue("carClubCompanyId", e.target.value);
                                      setFieldTouched("carClubCompanyId");
                                    }}
                                    component={TextField}
                                    size="small"
                                    helperText={errors.carClubCompanyId !== undefined  ? errors?.carClubCompanyId : " "}
                                  />
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell className={classes.tableCell}>
                                  <Typography className={classes.tableheader}>
                                    {t("TravelPolicy.CarClub.EnrollmentLink")}
                                  </Typography>
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  <Field
                                    name="carClubCompanyEnrollmentLink"
                                    id="carClubCompanyEnrollmentLink"
                                    fullWidth
                                    style={{ paddingBottom: 0, paddingTop: 0 }}
                                    inputProps={{
                                      "aria-label": t(
                                        "TravelPolicy.CarClub.EnrollmentLink"
                                      ),
                                      "aria-required": true,
                                    }}
                                    variant="outlined"
                                    error={
                                      errors.carClubCompanyEnrollmentLink !==
                                      undefined
                                        ? true
                                        : false
                                    }
                                    size="small"
                                    value={values.carClubCompanyEnrollmentLink}
                                    onChange= {(e) => {
                                      setFieldValue("carClubCompanyEnrollmentLink", e.target.value);
                                      setFieldTouched("carClubCompanyEnrollmentLink");
                                    }}
                                    component={TextField}
                                  />
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell className={classes.tableCell}>
                                  <Typography className={classes.tableheader}>
                                    {t(
                                      "TravelPolicy.CarClub.MinimumQuantityOfMiles"
                                    )}
                                  </Typography>
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  <Field
                                    name="carClubMinMiles"
                                    id="carShareTravelPolicyMinimumQuantityOfMilesTextbox"
                                    variant="outlined"
                                    size="small"
                                    error={
                                      errors.carClubMinMiles !== undefined
                                        ? true
                                        : false
                                    }
                                    fullWidth
                                    style={{ paddingBottom: 0, paddingTop: 0 }}
                                    inputProps={{
                                      "aria-label": t(
                                        "TravelPolicy.CarClub.MinimumQuantityOfMiles"
                                      ),
                                      "aria-required": true,
                                    }}
                                    value={values.carClubMinMiles}
                                    onChange= {(e) => {
                                      setFieldValue("carClubMinMiles", e.target.value);
                                      setFieldTouched("carClubMinMiles");
                                    }}
                                    component={TextField}
                                    helperText={errors.carClubMinMiles !== undefined ?
                                      errors.carClubMinMiles : " "}
                                  />
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell className={classes.tableCell}>
                                  <Typography className={classes.tableheader}>
                                    {t(
                                      "TravelPolicy.CarClub.MaximumQuantityOfMiles"
                                    )}
                                  </Typography>
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  <Field
                                    name="carClubmiles"
                                    id="carShareTravelPolicyMaximumQuantityOfMilesTextbox"
                                    variant="outlined"
                                    size="small"
                                    error={
                                      errors.carClubmiles !== undefined
                                        ? true
                                        : false
                                    }
                                    fullWidth
                                    style={{ paddingBottom: 0, paddingTop: 0 }}
                                    inputProps={{
                                      "aria-label": t(
                                        "TravelPolicy.CarClub.MaximumQuantityOfMiles"
                                      ),
                                      "aria-required": true,
                                    }}
                                    value={values.carClubmiles}
                                    onChange= {(e) => {
                                      setFieldValue("carClubmiles", e.target.value);
                                      setFieldTouched("carClubmiles");
                                    }}
                                    component={TextField}
                                    helperText={errors.carClubmiles !== undefined ?
                                      errors.carClubmiles : " "}
                                  />
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell className={classes.tableCell}>
                                  <Typography className={classes.tableheader}>
                                    {t(
                                      "TravelPolicy.CarClub.MaximumSearchDistanceMiles"
                                    )}
                                  </Typography>
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  <Field
                                    name="carClubMaxDistance"
                                    id="carShareTravelPolicyMaximumVehicleDistanceTextbox"
                                    variant="outlined"
                                    size="small"
                                    error={
                                      errors.carClubMaxDistance !== undefined
                                        ? true
                                        : false
                                    }
                                    fullWidth
                                    style={{ paddingBottom: 0, paddingTop: 0 }}
                                    inputProps={{
                                      "aria-label": t(
                                        "TravelPolicy.CarClub.MaximumSearchDistanceMiles"
                                      ),
                                      "aria-required": true,
                                    }}
                                    value={values.carClubMaxDistance}
                                    onChange= {(e) => {
                                      setFieldValue("carClubMaxDistance", e.target.value);
                                      setFieldTouched("carClubMaxDistance");
                                    }}
                                    component={TextField}
                                    helperText={errors.carClubMaxDistance !== undefined ?
                                      errors.carClubMaxDistance : " "}
                                  />
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell className={classes.tableCell}>
                                  <Typography className={classes.tableheader}>
                                    {t(
                                      "TravelPolicy.CarClub.MaximumQuantityOfHours"
                                    )}
                                  </Typography>
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  <Field
                                    name="carClubHours"
                                    id="carShareTravelPolicyMaximumQuantityOfHoursTextbox"
                                    variant="outlined"
                                    size="small"
                                    error={
                                      errors.carClubHours !== undefined
                                        ? true
                                        : false
                                    }
                                    fullWidth
                                    style={{ paddingBottom: 0, paddingTop: 0 }}
                                    inputProps={{
                                      "aria-label": t(
                                        "TravelPolicy.CarClub.MaximumQuantityOfHours"
                                      ),
                                      "aria-required": true,
                                    }}
                                    value={values.carClubHours}
                                    onChange= {(e) => {
                                      setFieldValue("carClubHours", e.target.value);
                                      setFieldTouched("carClubHours");
                                    }}
                                    component={TextField}
                                    helperText={errors.carClubHours !== undefined ?
                                      errors.carClubHours : " "}
                                  />
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell className={classes.tableCell}>
                                  <Typography className={classes.tableheader}>
                                    {t(
                                      "TravelPolicy.CarClub.AverageHourlyCost"
                                    )}
                                  </Typography>
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  <Field
                                    name="carClubAverageHourlyRate"
                                    id="carShareAverageHourlyRateTextBox"
                                    variant="outlined"
                                    size="small"
                                    error={
                                      errors.carClubAverageHourlyRate !==
                                      undefined
                                        ? true
                                        : false
                                    }
                                    fullWidth
                                    style={{ paddingBottom: 0, paddingTop: 0 }}
                                    inputProps={{
                                      "aria-label": t(
                                        "TravelPolicy.CarClub.AverageHourlyCost"
                                      ),
                                      "aria-required": true,
                                    }}
                                    value={values.carClubAverageHourlyRate}
                                    onChange= {(e) => {
                                      setFieldValue("carClubAverageHourlyRate", e.target.value);
                                      setFieldTouched("carClubAverageHourlyRate");
                                    }}
                                    component={TextField}
                                    helperText={errors.carClubAverageHourlyRate !== undefined ?
                                      errors.carClubAverageHourlyRate : " "}
                                  />
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
                <Card variant="outlined" className={classes.cards}>
                  <CardHeader
                    className={classes.cardTitle}
                    title={t("TravelPolicy.DailyRental.DailyRental")}
                  />
                  <Divider
                    style={{ backgroundColor: "black", fontWeight: "normal" }}
                  />
                  <CardContent>
                    <Grid container spacing={1} >
                      <Grid item xs={12} md={12}>
                        <label>
                          <Field
                            type="checkbox"
                            name="dailyRentalAvailable"
                            component={Checkbox}
                            checked={values.dailyRentalAvailable}
                            id="dailyRentalTravelPolicyAvailableCheckBox"
                            onChange={(e) => {
                              handleChange(e);
                              setSelectDR(e.target.checked);
                              setFieldValue("dailyRentalAvailable", e.target.checked);
                              setFieldTouched("dailyRentalAvailable");
                            }}
                          />

                          {t("TravelPolicy.DailyRental.AvailableProduct")}
                        </label>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <label>
                          <Field
                            type="checkbox"
                            name="dailyRentalOfferAlways"
                            checked={values.dailyRentalOfferAlways}
                            component={Checkbox}
                            id="dailyRentalTravelPolicyOfferAlwaysCheckBox"
                            onChange={(e) => {
                              setFieldValue("dailyRentalOfferAlways", e.target.checked);
                              setFieldTouched("dailyRentalOfferAlways");
                            }}
                          />
                          {t("TravelPolicy.DailyRental.OfferAlways")}
                        </label>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <label>
                          <Field
                            type="checkbox"
                            name="dailyRentalEnableLoyalty"
                            checked={values.dailyRentalEnableLoyalty}
                            component={Checkbox}
                            onChange={(e) => {
                              setFieldValue("dailyRentalEnableLoyalty", e.target.checked);
                              setFieldTouched("dailyRentalEnableLoyalty");
                            }}
                            id="dailyRentalTravelPolicyLoyaltyCheckBox"
                          />
                          {t("TravelPolicy.DailyRental.EnableLoyalty")}
                        </label>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <label>
                          <Field
                            type="checkbox"
                            name="dailyRentalDisplayMidpoints"
                            checked={values.dailyRentalDisplayMidpoints}
                            component={Checkbox}
                            id="dailyRentalMidpointsCheckBox"
                            onChange={(e) => {
                              handleChange(e);
                              //   // handleMidpointChange(e)
                              setFieldValue("dailyRentalDisplayMidpoints", e.target.checked);
                              setFieldTouched("dailyRentalDisplayMidpoints");
                            }}
                          />
                          {t("TravelPolicy.DailyRental.DisplayMidpoints")}
                        </label>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <TableContainer>
                          <Table className={classes.table} size="small">
                            <TableBody>
                              <TableRow>
                                <TableCell className={classes.tableCell}>
                                  <Typography className={classes.tableheader}>
                                    {t(
                                      "TravelPolicy.DailyRental.AverageDailyRate"
                                    )}
                                  </Typography>
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  <Field
                                    name="dailyRentalAverageDailyRate"
                                    id="dailyRentalAverageDailyRateTextBox"
                                    variant="outlined"
                                    error={
                                      errors.dailyRentalAverageDailyRate !==
                                      undefined
                                        ? true
                                        : false
                                    }
                                    fullWidth
                                    style={{ paddingBottom: 0, paddingTop: 0 }}
                                    inputProps={{
                                      "aria-label": t(
                                        "TravelPolicy.DailyRental.AverageDailyRate"
                                      ),
                                      "aria-required": true,
                                    }}
                                    size="small"
                                    value={values.dailyRentalAverageDailyRate}
                                    onChange= {(e) => {
                                      setFieldValue("dailyRentalAverageDailyRate", e.target.value);
                                      setFieldTouched("dailyRentalAverageDailyRate");
                                    }}
                                    component={TextField}
                                    helperText={errors.dailyRentalAverageDailyRate !== undefined ?
                                      errors.dailyRentalAverageDailyRate : " "}
                                  />
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell className={classes.tableCell}>
                                  <Typography
                                    style={{
                                      fontWeight: "normal",
                                      color: "black",
                                      fontStyle: "italic",
                                    }}>
                                    {t("TravelPolicy.DailyRental.Note")}
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
                <Card variant="outlined" className={classes.cards}>
                  <CardHeader
                    className={classes.cardTitle}
                    title={t(
                      "TravelPolicy.MileageReimbursement.MileageReimbursement"
                    )}
                  />
                  <Divider
                    style={{ backgroundColor: "black", fontWeight: "normal" }}
                  />
                  <CardContent>
                    <Grid container spacing={1} >
                      <Grid item xs={12} md={12}>
                        <label>
                          <Field
                            type="checkbox"
                            name="mReimbursementClubAvailable"
                            checked={values.mReimbursementClubAvailable}
                            component={Checkbox}
                            id="mileageReimbursementTravelPolicyAvailableCheckBox"
                            onChange={(e) => {
                              handleChange(e);
                              setSelectMR(e.target.checked);
                              setFieldValue("mReimbursementClubAvailable", e.target.checked);
                              setFieldTouched("mReimbursementClubAvailable");
                            }}
                          />
                          {t(
                            "TravelPolicy.MileageReimbursement.AvailableProduct"
                          )}
                        </label>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <label>
                          <Field
                            type="checkbox"
                            name="mReimbursementClubOfferAlways"
                            checked={values.mReimbursementClubOfferAlways}
                            component={Checkbox}
                            onChange={(e) => {
                              setFieldValue("mReimbursementClubOfferAlways", e.target.checked);
                              setFieldTouched("mReimbursementClubOfferAlways");
                            }}
                            id="mileageReimbursementTravelPolicyOfferAlwaysCheckBox"
                          />
                          {t("TravelPolicy.MileageReimbursement.OfferAlways")}
                        </label>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <TableContainer>
                          <Table className={classes.table} size="small">
                            <TableBody>
                              <TableRow>
                                <TableCell className={classes.tableCell}>
                                  <Typography className={classes.tableheader}>
                                    {t(
                                      "TravelPolicy.MileageReimbursement.mileageReimbursementRate"
                                    )}
                                  </Typography>
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  <Field
                                    name="mReimbursementMileageReimbursementRate"
                                    id="mileageReimbursementTravelPolicyMileageReimbursementTextBox"
                                    variant="outlined"
                                    size="small"
                                    value={values.mReimbursementMileageReimbursementRate}
                                    onChange= {(e) => {
                                      setFieldValue("mReimbursementMileageReimbursementRate", e.target.value);
                                      setFieldTouched("mReimbursementMileageReimbursementRate");
                                    }}
                                    component={TextField}
                                    error={
                                      errors.mReimbursementMileageReimbursementRate !==
                                      undefined
                                        ? true
                                        : false
                                    }
                                    fullWidth
                                    style={{ paddingBottom: 0, paddingTop: 0 }}
                                    inputProps={{
                                      "aria-label": t(
                                        "TravelPolicy.MileageReimbursement.mileageReimbursementRate"
                                      ),
                                      "aria-required": true,
                                    }}
                                    helperText={errors.mReimbursementMileageReimbursementRate !== undefined ?
                                      errors.mReimbursementMileageReimbursementRate : " "}
                                  />
                                </TableCell>
                              </TableRow>

                              <TableRow>
                                <TableCell className={classes.tableCell}>
                                  <Typography
                                    style={{
                                      fontWeight: "normal",
                                      color: "black",
                                      fontStyle: "italic",
                                    }}>
                                    {t(
                                      "TravelPolicy.MileageReimbursement.Note"
                                    )}
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  className={classes.tableCell}></TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell className={classes.tableCell}>
                                  <Typography className={classes.tableheader}>
                                    {t(
                                      "TravelPolicy.MileageReimbursement.maximumQuantityOfMiles"
                                    )}
                                  </Typography>
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  <Field
                                    name="mReimbursementMiles"
                                    id="mileageReimbursementTravelPolicyMaximumQuantityOfMilesTextbox"
                                    variant="outlined"
                                    size="small"
                                    value={values.mReimbursementMiles}
                                    onChange= {(e) => {
                                      setFieldValue("mReimbursementMiles", e.target.value);
                                      setFieldTouched("mReimbursementMiles");
                                    }}
                                    component={TextField}
                                    error={
                                      errors.mReimbursementMiles !== undefined
                                        ? true
                                        : false
                                    }
                                    fullWidth
                                    style={{ paddingBottom: 0, paddingTop: 0 }}
                                    inputProps={{
                                      "aria-label": t(
                                        "TravelPolicy.MileageReimbursement.maximumQuantityOfMiles"
                                      ),
                                      "aria-required": true,
                                    }}
                                    helperText={errors.mReimbursementMiles !== undefined ?
                                      errors.mReimbursementMiles : " "}
                                  />
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid container justifyContent="flex-end" item xs={12} md={12}>
                <Button
                  id="btnUpdate"
                  color="primary"
                  variant="contained"
                  disabled={!isValid || !dirty}
                  type="submit"
                  style={{ marginTop: "0.5em", textTransform: "none" }}>
                  {t("Companies.btnSave")}
                </Button>

                <Button
                  classes={{ outlinedPrimary: classes.outlinedButton }}
                  id="btnCancel"
                  color="primary"
                  variant="outlined"
                  type="button"
                  onClick={() => {
                    props.action("");
                  }}
                  style={{
                    marginTop: "0.5em",
                    marginLeft: "0.5em",
                    textTransform: "none",
                  }}>
                  {t("Companies.btnCancel")}
                </Button>
              </Grid>
              <FocusError />
            </Grid>
          </Form>
        </Grid>
      )}
    </Formik>
  );
}
