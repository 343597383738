import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "components/store/reducers/rootReducer";
import axiosInstance from "helpers/axiosInstance";
import { updateCo2Dict } from "components/store/actions/reservation/co2DictActions";

// useCo2 Emmision lables are only applicable to the below countries of start location:
export const countriesToShowEmmision = ["GBR", "IRL", "DEU", "FRA","ESP"];

export const useCo2Dict = () => {
  const { co2dict } = useSelector((state: AppState) => state.co2Dict);
  const dispatch = useDispatch();

  React.useEffect(() => {
    const fetchCo2data = async () => {
      if (co2dict && co2dict.size === 0) {
        await axiosInstance
          .get(`vehicleprops/co2rates`)
          .then((response) => {
            if (response.data.success && response.data.data) {
              const co2list = response.data.data;
              console.log("Downloaded Co2Rates: ", co2list);
              let co2rates = new Map<string, string>();
              co2list.forEach((element) => {
                for (const [key, value] of Object.entries(element)) {
                  co2rates.set(key, `${value}`);
                }
              });
              dispatch(updateCo2Dict({ co2dict: co2rates }));
            }
          })
          .catch((error) => {
            console.error(
              "There was an error in validating start date!",
              error
            );
          });
      }
    };

    fetchCo2data();
  }, []);
};
