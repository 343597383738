import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import { Box } from "@mui/material";
import MarketingMessage from "../../components/home/MarketingMessage";
import MarketingCarousel from "../../components/home/MarketingCarousel";
import MyRecentJourneys from "components/home/myRecentJourney";
import { reactPlugin, appInsights } from "../../helpers/appInsights";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { authService } from "services/authService";
import { isTablet, isMobile } from "react-device-detect";

import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import JourneyInputForm from "components/Reservation/steps/JourneyStart/journeyInput";
import { useDispatch } from "react-redux";
import { setHomeSkipLink } from "components/store/slicers/skipLinkSlice";
type Props = {
  handleIsSetHomePage?: () => void;  
};
const Home = ({ handleIsSetHomePage }: Props) => {  
  const [clientMode, setClientMode] = React.useState(0);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const { t } = useTranslation();
  const dispatch = useDispatch();


  useEffect(() => {
    document.title = t("homePageTitle");

    const subscription = authService.currentUser.subscribe((x) => {
      if (x !== null && x !== undefined) {
        setClientMode(authService.isEhiAdmin() ? 1 : 2);

        appInsights.trackEvent({
          name: "Home Component",
          properties: {
            LoginType: authService.isEhiAdmin() ? "EHI admin" : "ETD User",
          },
        });
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [clientMode]);

  useEffect(() => {
    dispatch(setHomeSkipLink())

  }, []);

  return (
    <div>
      {clientMode === 0 ? (
        <>Please wait... login in progress....</>
      ) : (
        <Grid data-testid="homeComponent" container spacing={1}>
          <Grid item xs={12} md={4}>
            <JourneyInputForm
              id={queryParams.get("id")}
              isStartAgain={queryParams.get("isStartAgain")}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <Grid container spacing={3}>
              {isMobile || isTablet ? null : (
                <Grid item xs={12}>
                  <Box
                    id="marketingMessageDiv"
                    boxShadow={3}
                    m={0}
                    p={3}
                    bgcolor="background.paper"
                  >
                    <MarketingMessage />
                  </Box>
                </Grid>
              )}
              {isMobile || isTablet ? null : (
                <Grid item xs={12}>
                  <Box boxShadow={3} m={0} mt={-2}>
                    <MarketingCarousel />
                  </Box>
                </Grid>
              )}

              <Grid item xs={12}>
                {<MyRecentJourneys />}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </div>
  );
};
export default withAITracking(reactPlugin, Home);
