import { FormControl, Grid, IconButton, Select, TextField, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import React, { Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";
import InfoIcon from "@mui/icons-material/Info";
import {
  AdditionalInformationValidationResponse, AdditionalInformationViewModel,
} from "../AdditionalInformation/additionalInformationType";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import EtdDatePickerV2 from "controls/formik/EtdDatePickerV2";
import moment from "moment";
import { convertToDate, getIntlDateFormatForLocale } from "helpers/localization";
import { authService } from "services/authService";


const useStyles = makeStyles((theme) => ({
  gridItem: {
    verticalAlign: "center",
    padding: "10px",
    "& .MuiTypography-body2": {
      fontWeight: "bold",
      fontSize: "0.875rem",
    },
  },
  errorWarning: {
    color: "#DF0000",
    fontSize: "0.75rem",
    marginLeft: "15px",
  },
}));


const formFieldsValidationSchema = Yup.object().shape({
 
});

export function applyAdditionalInformationValidationSchema (
  additionalInformationFields: Array<any>,
  existingformFieldsValidationSchema: any
) {
  //create schema dynamically according to additionalInformationFields[]
  const formSchema = additionalInformationFields.reduce(
    (obj, item, index) => ({
      ...obj,
      ["additionalInformationFields[" + index + "]"]: Yup.object()        
        .test(
          "datefieldtest-" + index,
          "Users.Errors.fieldValueFormatInvalidOrInPast",
          function () {
            const ftc =
              this.parent.additionalInformationFields !== undefined
                ? this.parent.additionalInformationFields[index].fieldTypeCode
                : "";
            if (ftc !== 1){
              return true;
            }
            else{
              const c =
                this.parent.additionalInformationFields !== undefined
                  ? this.parent.additionalInformationFields[index].fieldValueDateString
                  : "";
              return c === "FormatError" || c === "DateInPast" ? false : true;
            }
          }
        )
        .test(
          "emptytest-" + index,
          "Users.Errors.fieldValueRequired",
          function () { 
            const a =
              this.parent.additionalInformationFields !== undefined
                ? this.parent.additionalInformationFields[index].fieldValue
                : "";
            if (
              !(
                (this.parent.additionalInformationFields !== undefined
                && this.parent.additionalInformationFields[index].mandatory) ??
                false
              )
            )
              return true;
            else {
              return a ? true : false;
            }
          }
        )
        .test(
          "lengthtest-" + index,
          "Users.Errors.fieldValueMaxLength",
          function () {
            const b =
              this.parent.additionalInformationFields !== undefined
                ? this.parent.additionalInformationFields[index].fieldValue
                : "";
            const maxLength = (this.parent.additionalInformationFields && this.parent.additionalInformationFields[index].maxLength) ? this.parent.additionalInformationFields[index].maxLength :100
              return (b ?? "").length > maxLength ? false : true;

          }
        ),
    }),
    { additionalInformationFields: Yup.array() }
  );
  const newSchema = existingformFieldsValidationSchema.shape({
    additionalInformation: Yup.object(formSchema).nullable(),
  });
  return newSchema;
}




const AdditionalInformationForm: React.FC<{ additionalInfo: AdditionalInformationViewModel, additionalInfoValidation , onDataChange: (additionalInfoData: AdditionalInformationViewModel, isValid:boolean) => void}> = ({
  additionalInfo, additionalInfoValidation, onDataChange
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [formValidation, setFormValidation] = React.useState(formFieldsValidationSchema);
  const [
    additionalInfoValidationResponse,
    setadditionalInfoValidationResponse,
  ] = React.useState<AdditionalInformationValidationResponse | null>(null);
  // const [
  //   additionalInfoValidationResponse, setadditionalInfoValidationResponse,
  // ] = React.useState<AdditionalInformationValidationResponse | null>({ success : false, messages : [],  errors: additionalInfoValidation});  
  const locale = authService.getUserLocale();
  const localeDateTimeFormat = getIntlDateFormatForLocale(locale);
  const additionalInfoData = additionalInfo.additionalInformation ?? null; 


  useEffect(() => {
    if (additionalInfoData !== undefined || additionalInfoData !== null) {
      const newSchema = applyAdditionalInformationValidationSchema(
        additionalInfoData.additionalInformationFields,
        formValidation
      );
      setFormValidation(newSchema);
      let isValid = newSchema.isValidSync(additionalInfo);
      onDataChange(additionalInfo, isValid);
    }
  }, []);

  useEffect(() => {   
    setadditionalInfoValidationResponse(additionalInfoValidation === null ? null : {success : false, messages : [],  errors: additionalInfoValidation}); 
    console.log("useeffect additionalInfoValidation", additionalInfoValidation); 
    if(additionalInfoValidation !== null)
      onDataChange(additionalInfoData as unknown as AdditionalInformationViewModel, false);
    
  }, [additionalInfoValidation]);

   
  return (
    <Formik
      initialValues={additionalInfo}
      validateOnChange={true}
      validateOnBlur={true}
      validateOnMount={true}
      enableReinitialize={true}
      validationSchema={formValidation}
      onSubmit={() => {}}
    >
      {({
        values,
        isValid,
        dirty,
        setFieldValue,
        setFieldTouched,
        touched,
        handleChange,
        handleBlur,
        errors,
        isSubmitting,
      }) => (
    <div style={{ flexGrow: 1 }}>
                          <Grid data-testid="additionalInformationForm" container direction="row">
                            {additionalInfoData &&
                              additionalInfoData?.additionalInformationFields?.map(
                                (info, index) => (
                                  <Fragment key={index}>
                                    <Grid
                                      item
                                      xs={12}
                                      md={3}
                                      className={classes.gridItem}>
                                      <Typography
                                        variant="body2"                                        
                                        id={"AdditionalInformationFieldLabel".concat(
                                          info.additionalInformationGid
                                        )}
                                        title={info.fieldName}>
                                        {info.fieldName.concat(info.mandatory ? " *" : "")}
                                        {"  "}
                                        {info.helperText !== null && 
                                          <IconButton
                                            id={"additionalInformationIcon" + index }
                                            size="small"
                                            color="primary"
                                            style={{
                                              marginLeft: 0,
                                              paddingBottom: 8,
                                            }}
                                            title={info.helperText}>
                                              <InfoIcon fontSize="small" />
                                          </IconButton>
                                         }
                                      </Typography>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={12}
                                      md={9}
                                      className={classes.gridItem}>
                                      {[3, 4, 5].includes(
                                        info.fieldTypeCode
                                      ) ? (
                                        <Fragment>
                                          <Field
                                            name={
                                              "AdditionalInformation.AdditionalInformationFields[" + index +"].FieldValue"
                                            }
                                            id={
                                              "AdditionalInformationField-TextTextbox-" + index
                                            }
                                            value={info.fieldValue ?? ""}
                                            fullWidth
                                            style={{
                                              paddingBottom: 0,
                                              paddingTop: 0,
                                            }}
                                            inputProps={{
                                              "aria-label": info.fieldName,
                                              "aria-required": true,
                                              "aria-describedby":"AdditionalInformationFieldError" + index,
                                            }}
                                            onChange={(e) => {                                              
                                              info.fieldValue = e.target.value;                                           
                                              let isValid = formValidation.isValidSync(additionalInfo);
                                              setFieldValue("additionalInformation.additionalInformationFields[" +index + "]", info); 
                                              onDataChange(additionalInfo, isValid);
                                            }}
                                            onBlur={(e) => {
                                              setFieldTouched("additionalInformation.additionalInformationFields[" +index + "]");
                                            }}
                                            onKeyUp={(e) => {
                                              setFieldTouched("additionalInformation.additionalInformationFields[" +index + "]");
                                            }}
                                            component={TextField}
                                            variant="outlined"
                                            error={                                              
                                               (errors.additionalInformation?.additionalInformationFields 
                                                && errors.additionalInformation?.additionalInformationFields[index] !== undefined ) ||
                                                (additionalInfoValidationResponse !== null &&
                                                additionalInfoValidationResponse.errors.hasOwnProperty(info.additionalInformationGid))
                                                ? true : false
                                             }
                                            placeholder={
                                              t(
                                                "AdditionalInformation.pleaseSelectValueFor"
                                              ) + info.fieldName
                                            }
                                          />
                                          {
                                          ((errors.additionalInformation?.additionalInformationFields 
                                            && errors.additionalInformation?.additionalInformationFields[index] !== undefined ) &&  
                                            (touched.additionalInformation?.additionalInformationFields 
                                              && touched.additionalInformation?.additionalInformationFields[index] !== undefined)) &&  
                                            <span
                                              id={"AdditionalInformationFieldError" + index}
                                              role="alert"
                                              className={classes.errorWarning}>
                                              {t(errors.additionalInformation?.additionalInformationFields[index]?.toString() ?? "none",                                      
                                                 {
                                                  fieldName: info.fieldName,
                                                  maxLength: info.maxLength ? info.maxLength : 100,
                                                }
                                               )}
                                            </span>
                                          }
                                        </Fragment>
                                      ) : 
                                      [2].includes(info.fieldTypeCode) ? (
                                        <Fragment>
                                          <FormControl
                                            variant="outlined"
                                            fullWidth>
                                            <Select
                                              name={"AdditionalInformation.AdditionalInformationFields[" +index + "].FieldValue"}
                                              id={ "AdditionalInformationField-DropDownListComboBox-" + index }
                                              native
                                              value={info.fieldValue ?? ""}
                                              onChange={(e) => {
                                                info.dropDownListSelectedValue = e.target.value as string;
                                                info.fieldValue = e.target.value;
                                                let isValid = formValidation.isValidSync(additionalInfo);
                                                setFieldValue("additionalInformation.additionalInformationFields[" +index + "]", info); 
                                                onDataChange(additionalInfo, isValid);
                                              }}
                                              onBlur={(e) => {
                                                setFieldTouched("additionalInformation.additionalInformationFields[" +index + "]");
                                              }}
                                              onKeyUp={(e) => {
                                                setFieldTouched("additionalInformation.additionalInformationFields[" +index + "]");
                                              }}
                                              inputProps={{
                                                "aria-label": info.fieldName,
                                                "aria-describedby":"AdditionalInformationFieldError" + index,
                                              }}
                                              error={
                                                (errors.additionalInformation?.additionalInformationFields 
                                                  && errors.additionalInformation?.additionalInformationFields[index] !== undefined )||
                                                 (additionalInfoValidationResponse !== null &&
                                                 additionalInfoValidationResponse.errors.hasOwnProperty(info.additionalInformationGid))
                                                 ? true : false } >
                                                  <option
                                                    key={ "AdditionalInformationField-DropDownListComboBox-" + index +"-Emtpy" }
                                                    value="">
                                                    {t( "AdditionalInformation.pleaseSelect")}
                                                  </option>
                                                  {info.dropDownList?.map((opt: any, optIndex) => (
                                                    <option
                                                      key={"AdditionalInformationField-DropDownListComboBox-" +index + "-" +optIndex }
                                                      value={opt.value}>
                                                      {opt.text}
                                                    </option>
                                                  ) )}
                                            </Select>
                                          {((errors.additionalInformation?.additionalInformationFields 
                                            && errors.additionalInformation?.additionalInformationFields[index] !== undefined ) &&  
                                            (touched.additionalInformation?.additionalInformationFields 
                                              && touched.additionalInformation?.additionalInformationFields[index] !== undefined))  &&                                           
                                              <span
                                                id={ "AdditionalInformationFieldError" + index }
                                                role="alert"
                                                className={
                                                  classes.errorWarning
                                                }>
                                                {t(errors.additionalInformation?.additionalInformationFields[index]?.toString() ?? "none",                                                
                                                    {
                                                    fieldName: info.fieldName,
                                                    maxLength: info.maxLength ? info.maxLength : 100,
                                                    }
                                                ) }
                                              </span>
                                           }
                                          </FormControl>
                                        </Fragment>
                                      ) : [1].includes(info.fieldTypeCode) ? (
                                        <Fragment>
                                          <Field
                                            component={EtdDatePickerV2}
                                            name={"AdditionalInformation.AdditionalInformationFields[" + index + "].FieldValue" }
                                            id={"AdditionalInformationField-DateTextbox-" + index}
                                            label={info.fieldName}
                                            localeDateTimeFormat={ localeDateTimeFormat }
                                            isFullWidth={false}
                                            value={info.fieldValue ? convertToDate(info.fieldValue) : null}
                                            errorMessageId={"AdditionalInformationField-DateTextboxError-" +index}                                            
                                            handleChange={(e) => {
                                              const format = localeDateTimeFormat.toUpperCase();
                                              let parsedDate = moment(
                                                e,
                                                format,
                                                true
                                              );

                                              if (parsedDate.isValid()) {
                                                const newValue =
                                                  moment(parsedDate).format(
                                                    "YYYY-MM-DD"
                                                  );
                                                if (
                                                  moment(
                                                    parsedDate
                                                  ).isSameOrAfter(
                                                    moment().add(-1, "days")
                                                  )
                                                ) {
                                                  info.fieldValue = newValue;
                                                  info.fieldValueDateString =
                                                    newValue.replace(/\D/g, "");
                                                } else {
                                                  info.fieldValue = null;
                                                  info.fieldValueDateString =
                                                    "DateInPast";
                                                }
                                              } else {
                                                info.fieldValue = null;
                                                info.fieldValueDateString = e
                                                  ? "FormatError"
                                                  : "";
                                              }
                                              let isValid = formValidation.isValidSync(additionalInfo);                                              
                                              setFieldValue("additionalInformation.additionalInformationFields[" +index + "]", info); 
                                              onDataChange(additionalInfo, isValid);
                                              setFieldTouched("additionalInformation.additionalInformationFields[" +index + "]");
                                            }}
                                            handleBlur={(e) => {
                                              setFieldTouched("additionalInformation.additionalInformationFields[" +index + "]");
                                            }}
                                            isError={
                                              (errors.additionalInformation?.additionalInformationFields 
                                                && errors.additionalInformation?.additionalInformationFields[index] !== undefined) ||
                                               (additionalInfoValidationResponse !== null &&
                                               additionalInfoValidationResponse.errors.hasOwnProperty(info.additionalInformationGid))
                                               ? true : false
                                            }
                                          />    
                                         {((errors.additionalInformation?.additionalInformationFields 
                                            && errors.additionalInformation?.additionalInformationFields[index] !== undefined ) &&  
                                            (touched.additionalInformation?.additionalInformationFields 
                                              && touched.additionalInformation?.additionalInformationFields[index] !== undefined)) &&                                           
                                              <span
                                                id={ "AdditionalInformationFieldError" + index }
                                                role="alert"
                                                className={
                                                  classes.errorWarning
                                                }>
                                                {t(
                                                    errors.additionalInformation?.additionalInformationFields[index]?.toString() ?? "none",                                                  
                                                    {
                                                    fieldName: info.fieldName,
                                                    }
                                                ) }
                                              </span>
                                          }
                                        </Fragment>
                                      ) : (
                                        ""
                                      )}
                                    </Grid>
                                  </Fragment>
                                )
                              )}
                          </Grid>
                        </div>
    )}
    </Formik>
  );
};

export default AdditionalInformationForm;
